const cities: string[] = [
	"Tokyo",
	"Jakarta",
	"Delhi",
	"Mumbai",
	"Manila",
	"Shanghai",
	"São Paulo",
	"Seoul",
	"Mexico City",
	"Guangzhou",
	"Beijing",
	"Cairo",
	"New York",
	"Kolkāta",
	"Moscow",
	"Bangkok",
	"Buenos Aires",
	"Shenzhen",
	"Dhaka",
	"Lagos",
	"Istanbul",
	"Ōsaka",
	"Karachi",
	"Bangalore",
	"Tehran",
	"Kinshasa",
	"Ho Chi Minh City",
	"Los Angeles",
	"Rio de Janeiro",
	"Nanyang",
	"Baoding",
	"Chennai",
	"Chengdu",
	"Lahore",
	"Paris",
	"London",
	"Linyi",
	"Tianjin",
	"Shijiazhuang",
	"Zhoukou",
	"Lima",
	"Hyderābād",
	"Handan",
	"Bogotá",
	"Weifang",
	"Nagoya",
	"Wuhan",
	"Heze",
	"Ganzhou",
	"Tongshan",
	"Chicago",
	"Luanda",
	"Changsha",
	"Fuyang",
	"Kuala Lumpur",
	"Jining",
	"Dongguan",
	"Jinan",
	"Foshan",
	"Hanoi",
	"Pune",
	"Chongqing",
	"Changchun",
	"Zhumadian",
	"Ningbo",
	"Cangzhou",
	"Nanjing",
	"Hefei",
	"Ahmedabad",
	"Hong Kong",
	"Zhanjiang",
	"Shaoyang",
	"Hengyang",
	"Khartoum",
	"Nantong",
	"Yancheng",
	"Nanning",
	"Xi’an",
	"Shenyang",
	"Tangshan",
	"Santiago",
	"Zhengzhou",
	"Shangqiu",
	"Yantai",
	"Riyadh",
	"Dar es Salaam",
	"Xinyang",
	"Shangrao",
	"Luoyang",
	"Bijie",
	"Quanzhou",
	"Hangzhou",
	"Miami",
	"Huanggang",
	"Maoming",
	"Kunming",
	"Nanchong",
	"Zunyi",
	"Jieyang",
	"Lu’an",
	"Yichun",
	"Madrid",
	"Changde",
	"Taizhou",
	"Liaocheng",
	"Qujing",
	"Sūrat",
	"Baghdad",
	"Qingdao",
	"Singapore",
	"Dallas",
	"Dazhou",
	"Suzhou",
	"Xiangyang",
	"Philadelphia",
	"Giza",
	"Nairobi",
	"Nangandao",
	"Ankara",
	"Tai’an",
	"Yulin",
	"Dezhou",
	"Houston",
	"Atlanta",
	"Yongzhou",
	"Rangoon",
	"Toronto",
	"Suihua",
	"Washington",
	"Qiqihar",
	"Jinhua",
	"Saint Petersburg",
	"Shantou",
	"Sydney",
	"Weinan",
	"Suqian",
	"Guadalajara",
	"Suzhou",
	"Fuzhou",
	"Zhaotong",
	"Pudong",
	"Belo Horizonte",
	"Zhangzhou",
	"Bozhou",
	"Melbourne",
	"Nanchang",
	"Xianyang",
	"Taizhou",
	"Surabaya",
	"Abidjan",
	"Huaihua",
	"Ji’an",
	"Mianyang",
	"Xiaoganzhan",
	"Shaoxing",
	"Alexandria",
	"Yuncheng",
	"Pingdingshan",
	"Huizhou",
	"Huai’an",
	"Xinpu",
	"Chenzhou",
	"Guilin",
	"Jiujiang",
	"Anqing",
	"Boston",
	"Huanglongsi",
	"Jiangmen",
	"Changzhou",
	"Barcelona",
	"Wuxi",
	"Zibo",
	"Jiaxing",
	"Dalian",
	"Hengshui",
	"Harbin",
	"Yangzhou",
	"Yibin",
	"Johannesburg",
	"Yiyang",
	"Meizhou",
	"Chifeng",
	"Casablanca",
	"Guiyang",
	"Langfang",
	"Zhangjiakou",
	"İzmir",
	"Linfen",
	"Wenzhou",
	"Monterrey",
	"Luzhou",
	"Jiangguanchi",
	"Neijiang",
	"Phoenix",
	"Yanjiang",
	"Zhaoqing",
	"Xiaoxita",
	"Guigang",
	"Xiamen",
	"Chuzhou",
	"Fuzhou",
	"Zhuzhou",
	"Amman",
	"Jeddah",
	"Loudi",
	"Deyang",
	"Qingyuan",
	"Wuhu",
	"Hechi",
	"Seattle",
	"Binzhou",
	"Yokohama",
	"Liuzhou",
	"Baojishi",
	"Guang’an",
	"Hanzhong",
	"Kabul",
	"Zaozhuang",
	"Berlin",
	"Anshan",
	"Lanzhou",
	"Chengde",
	"San Francisco",
	"Puyang",
	"Suining",
	"Jiaozuo",
	"Bengbu",
	"Montréal",
	"Detroit",
	"Baicheng",
	"Busan",
	"Algiers",
	"Qincheng",
	"Taiyuan",
	"Shiyan",
	"Lucknow",
	"Chaoyang",
	"Hechi",
	"Leshan",
	"Yulinshi",
	"Siping",
	"Zhongshan",
	"Changzhi",
	"San Diego",
	"Faisalabad",
	"Santa Cruz",
	"Qinhuangdao",
	"Bazhou",
	"Zhenjiang",
	"Ürümqi",
	"Tongliao",
	"Heyuan",
	"Tongren",
	"Qinzhou",
	"Jaipur",
	"Xinzhou",
	"Addis Ababa",
	"Meishan",
	"Brasília",
	"Mashhad",
	"Jinzhou",
	"Tieling",
	"Shaoguan",
	"Kuwait City",
	"Shanwei",
	"Minneapolis",
	"Kyiv",
	"Sanaa",
	"Quezon City",
	"Dingxi",
	"Incheon",
	"Bursa",
	"Ningde",
	"Tampa",
	"Daqing",
	"Birmingham",
	"Putian",
	"Huzhou",
	"Salvador",
	"Wuzhou",
	"Xiangtan",
	"Denver",
	"Rome",
	"La Paz",
	"Pyongyang",
	"Kano",
	"Taichung",
	"Omdurman",
	"Zigong",
	"Mudanjiang",
	"Huludao",
	"Kaohsiung",
	"Guayaquil",
	"Rizhao",
	"Manchester",
	"Cawnpore",
	"Baotou",
	"Taipei",
	"Nanping",
	"Chaozhou",
	"Longyan",
	"Ibadan",
	"Hohhot",
	"Antananarivo",
	"Longba",
	"Weihai",
	"Chattogram",
	"Santo Domingo",
	"Xuanzhou",
	"Sanming",
	"Brooklyn",
	"Yangjiang",
	"Medellín",
	"Yunfu",
	"Brisbane",
	"Baoshan",
	"Dubai",
	"Luohe",
	"Qinbaling",
	"Mirzāpur",
	"Guangyuan",
	"Cali",
	"Huangshi",
	"Daegu",
	"Fortaleza",
	"Guatemala City",
	"Yaoundé",
	"Douala",
	"Jilin",
	"Lianshan",
	"Lincang",
	"Antalya",
	"Tashkent",
	"Bandung",
	"Nāgpur",
	"Huainan",
	"Dandong",
	"Shangzhou",
	"Bekasi",
	"Ghāziābād",
	"Tijuana",
	"Jiamusi",
	"Accra",
	"Yuxi",
	"Anshun",
	"Vancouver",
	"Tangerang",
	"Konya",
	"Queens",
	"Yingkou",
	"Adana",
	"Medan",
	"Sanmenxia",
	"Indore",
	"Ma’anshan",
	"Pingliang",
	"Quzhou",
	"Baku",
	"Gaoping",
	"Huaibei",
	"Xining",
	"Harare",
	"Yan’an",
	"Havana",
	"Phnom Penh",
	"Fukuoka",
	"Mogadishu",
	"Jincheng",
	"Puning",
	"Lishui",
	"Qingyang",
	"Riverside",
	"Baltimore",
	"Haiphong",
	"Las Vegas",
	"Laibin",
	"Rawalpindi",
	"Kumasi",
	"Portland",
	"Vadodara",
	"Perth",
	"San Antonio",
	"Vishākhapatnam",
	"Gujranwala",
	"Gaziantep",
	"Fushun",
	"St. Louis",
	"Bamako",
	"Quito",
	"Minsk",
	"Pingxiang",
	"Chongzuo",
	"Şanlıurfa",
	"Kananga",
	"Peshawar",
	"Sapporo",
	"Hezhou",
	"Jixi",
	"Caracas",
	"Fuxin",
	"Aleppo",
	"Almaty",
	"Dayr az Zawr",
	"Vienna",
	"Leeds",
	"Sacramento",
	"Blantyre",
	"Tainan",
	"Zhuhai",
	"Bucharest",
	"Wuwei",
	"Bhopāl",
	"Curitiba",
	"Multan",
	"Xiping",
	"Asunción",
	"Saidu Sharif",
	"Hamburg",
	"Meru",
	"Brazzaville",
	"Orlando",
	"Mersin",
	"Kyōto",
	"Manaus",
	"Liaoyang",
	"Baiyin",
	"San Jose",
	"Warsaw",
	"Shengli",
	"Lubumbashi",
	"Haikou",
	"Damascus",
	"Shuyangzha",
	"Brussels",
	"Eşfahān",
	"Budapest",
	"Heihe",
	"Lusaka",
	"Hyderabad City",
	"Diyarbakır",
	"Chinchvad",
	"Shuozhou",
	"Cleveland",
	"Pittsburgh",
	"Patna",
	"Mosul",
	"Austin",
	"Sanzhou",
	"Mecca",
	"Conakry",
	"Kampala",
	"Ecatepec",
	"Cincinnati",
	"Makassar",
	"Yushan",
	"Ludhiāna",
	"Newcastle",
	"Depok",
	"Zhongli",
	"Kansas City",
	"Ouagadougou",
	"Davao",
	"Manhattan",
	"Semarang",
	"Yinchuan",
	"Caloocan City",
	"Novosibirsk",
	"Chengtangcun",
	"Āgra",
	"Karaj",
	"Indianapolis",
	"Jingdezhen",
	"Puebla",
	"Kalyān",
	"Madurai",
	"Benxi",
	"Jamshedpur",
	"Recife",
	"Columbus",
	"Tabrīz",
	"Maracaibo",
	"Zhangjiajie",
	"Beihai",
	"Shuangyashan",
	"Kōbe",
	"Charlotte",
	"Yucheng",
	"Changshu",
	"Lianjiang",
	"Ximeicun",
	"Jianguang",
	"Madīnat as Sādis min Uktūbar",
	"Xushan",
	"Belém",
	"Guiping",
	"Leizhou",
	"Gwangju",
	"Nāsik",
	"Porto Alegre",
	"Valencia",
	"Onitsha",
	"Virginia Beach",
	"Daejeon",
	"Munich",
	"Auckland",
	"Yekaterinburg",
	"Huangshan",
	"Huazhou",
	"Shīrāz",
	"Pizhou",
	"Palembang",
	"León de los Aldama",
	"El Kelaa des Srarhna",
	"Kharkiv",
	"Kawanakajima",
	"Yangshe",
	"Chizhou",
	"Guyuan",
	"Rui’an",
	"Birstall",
	"Muscat",
	"Hebi",
	"Bronx",
	"Gaozhou",
	"The Hague",
	"Owerri",
	"Sharjah",
	"Farīdābād",
	"Ulaanbaatar",
	"Goiânia",
	"Kayseri",
	"Yueqing",
	"Belgrade",
	"Pingdu",
	"Milan",
	"Aurangābād",
	"Yutan",
	"Wenling",
	"Milwaukee",
	"Sofia",
	"Adelaide",
	"Prague",
	"Samsun",
	"Rājkot",
	"Liangshi",
	"Khulna",
	"Córdoba",
	"Guarulhos",
	"Juárez",
	"Saitama",
	"Montevideo",
	"Mbuji-Mayi",
	"Fuqing",
	"Xintai",
	"Doha",
	"Hiroshima",
	"Meerut",
	"Yushu",
	"Rongcheng",
	"Huazhou",
	"Yangquan",
	"Haicheng",
	"Yingtan",
	"Huaiyin",
	"Wuzhong",
	"Barranquilla",
	"Jabalpur",
	"Thāne",
	"Zhangye",
	"Rucheng",
	"Nizhniy Novgorod",
	"Comayagüela",
	"Shaoyang",
	"Dhanbād",
	"Yichun",
	"Laiwu",
	"Jingling",
	"Kazan",
	"Dayan",
	"Suwon",
	"Jiangyin",
	"Yongcheng",
	"Calgary",
	"Cần Thơ",
	"Abuja",
	"Yiwu",
	"Mandalay",
	"Beidao",
	"Al Başrah",
	"Shuangshui",
	"Vila Velha",
	"Allahābād",
	"Vārānasi",
	"Chelyabinsk",
	"Mombasa",
	"Providence",
	"Qom",
	"Maiduguri",
	"Maputo",
	"Rosario",
	"Benin City",
	"Xinyu",
	"Pikine",
	"Ahvāz",
	"Dublin",
	"Jacksonville",
	"Medina",
	"Srīnagar",
	"Omsk",
	"Cilacap",
	"Bandar Lampung",
	"Samara",
	"Guankou",
	"Ulsan",
	"Dingzhou",
	"Campinas",
	"Lianyuan",
	"Rongcheng",
	"Kaiyuan",
	"Nay Pyi Taw",
	"Dakar",
	"Zhuji",
	"Kigali",
	"Zapopan",
	"Leiyang",
	"Santiago",
	"Dadukou",
	"Xiantao",
	"Amritsar",
	"Callao",
	"Alīgarh",
	"Yingchuan",
	"Tegucigalpa",
	"Ciudad Nezahualcóyotl",
	"Tripoli",
	"Rostov",
	"Nezahualcóyotl",
	"Bhiwandi",
	"Zhoushan",
	"Tbilisi",
	"Ufa",
	"Fès",
	"Sevilla",
	"Mexicali",
	"Biên Hòa",
	"Gwalior",
	"Ankang",
	"Ikare",
	"Huambo",
	"Hegang",
	"Salt Lake City",
	"Bhilai",
	"Yuyao",
	"N’Djamena",
	"Hanchuan",
	"Gongzhuling",
	"Irbid",
	"Hāora",
	"Cologne",
	"Krasnoyarsk",
	"Yicheng",
	"São Gonçalo",
	"Nashville",
	"Yerevan",
	"Rānchi",
	"Nur-Sultan",
	"Nouakchott",
	"Vereeniging",
	"Richmond",
	"São Luís",
	"San Pedro Sula",
	"Taixing",
	"Memphis",
	"Goyang",
	"Bezwāda",
	"Edmonton",
	"Mizhou",
	"Tunis",
	"Xishan",
	"Ezhou",
	"Barquisimeto",
	"Sendai",
	"Voronezh",
	"Perm",
	"Changwon",
	"Zhongwei",
	"Shouguang",
	"Bogor",
	"Raleigh",
	"Cartagena",
	"Chandīgarh",
	"Bishkek",
	"Matola",
	"Ogbomoso",
	"Ashgabat",
	"Maceió",
	"Niamey",
	"Managua",
	"Patam",
	"Tekirdağ",
	"Shubrā al Khaymah",
	"Linhai",
	"Monrovia",
	"Wafangdian",
	"Zhongxiang",
	"Odesa",
	"Shymkent",
	"New Orleans",
	"Thủ Đức",
	"Volgograd",
	"Port-au-Prince",
	"Islamabad",
	"Mysore",
	"Xinyi",
	"Kathmandu",
	"Raipur",
	"Nice",
	"Arequipa",
	"Port Harcourt",
	"Louisville",
	"Zaoyang",
	"Shuizhai",
	"Dnipro",
	"Kota",
	"Quetta",
	"Abu Dhabi",
	"Stockholm",
	"Ottawa",
	"Asmara",
	"Freetown",
	"Jerusalem",
	"Bangui",
	"Panama City",
	"Amsterdam",
	"Lomé",
	"Libreville",
	"Zagreb",
	"Dushanbe",
	"Lilongwe",
	"Cotonou",
	"Vientiane",
	"Colombo",
	"Pretoria",
	"Winnipeg",
	"Quebec City",
	"Oslo",
	"Athens",
	"Bujumbura",
	"Helsinki",
	"Skopje",
	"Chisinau",
	"Riga",
	"Copenhagen",
	"Kingston",
	"Rabat",
	"Vilnius",
	"San Salvador",
	"Djibouti",
	"Lisbon",
	"Kitchener",
	"Tallinn",
	"Cape Town",
	"Bratislava",
	"Tirana",
	"Canberra",
	"Wellington",
	"Beirut",
	"Dodoma",
	"Halifax",
	"Bissau",
	"Juba",
	"Port Moresby",
	"Yamoussoukro",
	"Victoria",
	"Maseru",
	"Nicosia",
	"Windhoek",
	"Porto-Novo",
	"Sucre",
	"San José",
	"Ljubljana",
	"Sarajevo",
	"Nassau",
	"Bloemfontein",
	"Fort-de-France",
	"Gaborone",
	"Paramaribo",
	"Dili",
	"Pristina",
	"Georgetown",
	"Malabo",
	"Gibraltar",
	"Saint-Denis",
	"Male",
	"Podgorica",
	"Manama",
	"Port Louis",
	"Willemstad",
	"New Delhi",
	"Bern",
	"Papeete",
	"Reykjavík",
	"Praia",
	"Luxembourg",
	"Sri Jayewardenepura Kotte",
	"Bridgetown",
	"Moroni",
	"Thimphu",
	"Mbabane",
	"Nouméa",
	"Honiara",
	"Suva",
	"Castries",
	"Cayenne",
	"São Tomé",
	"Port-Vila",
	"Hamilton",
	"Bandar Seri Begawan",
	"Monaco",
	"Gitega",
	"Port of Spain",
	"Apia",
	"Douglas",
	"Oranjestad",
	"Saint Helier",
	"Vaduz",
	"Banjul",
	"Mamoudzou",
	"Majuro",
	"Tarawa",
	"George Town",
	"Victoria",
	"Kingstown",
	"Saint John’s",
	"Nuku‘alofa",
	"Andorra la Vella",
	"Nuuk",
	"Belmopan",
	"Roseau",
	"Basseterre",
	"Tórshavn",
	"Pago Pago",
	"Valletta",
	"Gaza",
	"Grand Turk",
	"Palikir",
	"Funafuti",
	"Lobamba",
	"Avarua",
	"Saint George’s",
	"San Marino",
	"Al Quds",
	"Capitol Hill",
	"Basse-Terre",
	"Stanley",
	"Vatican City",
	"Alofi",
	"Hagåtña",
	"Jamestown",
	"Marigot",
	"Philipsburg",
	"Road Town",
	"Gustavia",
	"Saint-Pierre",
	"The Valley",
	"Mata-Utu",
	"Kingston",
	"Ngerulmud",
	"Tifariti",
	"Tifariti",
	"Longyearbyen",
	"Adamstown",
	"Flying Fish Cove",
	"Bareilly",
	"Quảng Hà",
	"Soacha",
	"Kitaku",
	"Jodhpur",
	"Xingcheng",
	"Dongtai",
	"Yingcheng",
	"Al Manşūrah",
	"Glasgow",
	"Chiba",
	"Luocheng",
	"Danyang",
	"Natal",
	"Zhaodong",
	"Nada",
	"Xibeijie",
	"Kirkuk",
	"Naples",
	"Wuchuan",
	"Huilong",
	"Oklahoma City",
	"Toulouse",
	"Málaga",
	"Denizli",
	"Dispur",
	"Coimbatore",
	"Guwāhāti",
	"Sŏngnam",
	"Taishan",
	"Solāpur",
	"Tangier",
	"Anqiu",
	"Feicheng",
	"Meishan",
	"Kisangani",
	"Khartoum North",
	"Aguascalientes",
	"Marrakech",
	"Donetsk",
	"Taihecun",
	"Trujillo",
	"Cebu City",
	"Taihe",
	"Bridgeport",
	"Antwerp",
	"Trichinopoly",
	"Xin’an",
	"Padang",
	"Qingzhou",
	"Buffalo",
	"Lichuan",
	"Daye",
	"Fort Worth",
	"Hengzhou",
	"Campo Grande",
	"Zhuanghe",
	"Hartford",
	"Ad Dammām",
	"Bucheon",
	"Lhasa",
	"Homs",
	"Jiaozhou",
	"Mérida",
	"Yangchun",
	"Concepción",
	"Dengtalu",
	"Hubli",
	"Abeokuta",
	"Tucson",
	"Krasnodar",
	"Natal",
	"Chihuahua",
	"Klang",
	"Turin",
	"Jos",
	"Laiyang",
	"Jalandhar",
	"Sale",
	"Marseille",
	"Kaifeng Chengguanzhen",
	"Eskişehir",
	"Gaomi",
	"Teresina",
	"Ipoh",
	"Hai’an",
	"Liverpool",
	"Zamboanga City",
	"Oran",
	"Southampton",
	"Weichanglu",
	"Pekanbaru",
	"Portsmouth",
	"Leping",
	"Kermānshāh",
	"Hailun",
	"Macheng",
	"Ilorin",
	"Omaha",
	"Yuci",
	"Saratov",
	"São Bernardo do Campo",
	"Erbil",
	"Iguaçu",
	"El Paso",
	"Denpasar",
	"Dehui",
	"Naucalpan de Juárez",
	"Bhubaneshwar",
	"Tongchuan",
	"Cheongju",
	"Warri",
	"Pointe-Noire",
	"Rongjiawan",
	"Butterworth",
	"Bhayandar",
	"San Luis Potosí",
	"Renqiu",
	"João Pessoa",
	"Toluca",
	"Honolulu",
	"Querétaro",
	"Xindi",
	"Wu’an",
	"Hermosillo",
	"Wutong",
	"Taguig City",
	"Saltillo",
	"Gaoyou",
	"Hejian",
	"Yiyang",
	"Puxi",
	"Zijinglu",
	"Trabzon",
	"Xiashi",
	"Valencia",
	"Dongyang",
	"McAllen",
	"Abaeté",
	"Palermo",
	"Johor Bahru",
	"Qingping",
	"Morelia",
	"Niigata",
	"Pasig City",
	"Morādābād",
	"Hamamatsu",
	"Culiacán",
	"Xiangxiang",
	"Malang",
	"Xingyi",
	"Chaohucun",
	"Fuyang",
	"Antipolo",
	"Lubango",
	"Nottingham",
	"Cencheng",
	"Kraków",
	"Erzurum",
	"Hempstead",
	"Al ‘Ayn",
	"Songzi",
	"Laixi",
	"Bahawalpur",
	"Zhongba",
	"Qingnian",
	"Albuquerque",
	"Kaduna",
	"Tlalnepantla",
	"Xinhualu",
	"Guangshui",
	"Frankfurt",
	"Samarinda",
	"Changhua",
	"Pietermaritzburg",
	"Hŭngnam",
	"Kolhāpur",
	"Ciudad Guayana",
	"Cúcuta",
	"Licheng",
	"Thiruvananthapuram",
	"Tyumen",
	"Cancún",
	"Zaporizhzhia",
	"Tlajomulco de Zúñiga",
	"Chengguan",
	"Kumamoto",
	"Nehe",
	"Sokoto",
	"Birmingham",
	"Zunhua",
	"Orūmīyeh",
	"Oyo",
	"Wugang",
	"Shuangqiao",
	"Rennes",
	"Sizhan",
	"Comodoro Rivadavia",
	"Langzhong",
	"Bristol",
	"Qian’an",
	"Lviv",
	"Zouping",
	"Bremen",
	"Reynosa",
	"An Najaf",
	"Sagamihara",
	"Guli",
	"Sarasota",
	"Mississauga",
	"Lingbao Chengguanzhen",
	"Okayama",
	"Anlu",
	"Wusong",
	"Dayton",
	"Changsha",
	"Enugu",
	"George Town",
	"Jaboatão",
	"Santo André",
	"Xichang",
	"Soledad",
	"Chengxiang",
	"Tolyatti",
	"Sahāranpur",
	"Warangal",
	"Edogawa",
	"Osasco",
	"Dashiqiao",
	"Latakia",
	"Rochester",
	"Fresno",
	"Banjarmasin",
	"Salem",
	"Uberlândia",
	"Shishi",
	"Grenoble",
	"Guadalupe",
	"Adachi",
	"Qianxi",
	"Aracaju",
	"Bauchi",
	"Hamilton",
	"Łódź",
	"Miluo Chengguanzhen",
	"Gaizhou",
	"Mālegaon",
	"Karbalā’",
	"Leling",
	"São José dos Campos",
	"Jianshe",
	"Acapulco de Juárez",
	"Sheffield",
	"Shizuoka",
	"Jingcheng",
	"City of Parañaque",
	"Kochi",
	"Allentown",
	"Tasikmalaya",
	"Macau",
	"Torreón",
	"Xinmin",
	"Shanhu",
	"Zhongshu",
	"Xigazê",
	"Gold Coast",
	"Cagayan de Oro",
	"Gorakhpur",
	"Pinghu",
	"Palermo",
	"Guankou",
	"Tulsa",
	"Yatou",
	"Songyang",
	"Cape Coral",
	"Ch’ŏngjin",
	"Puyang Chengguanzhen",
	"Qionghu",
	"Ribeirão Prêto",
	"Dasmariñas",
	"Wenchang",
	"Shulan",
	"Catia La Mar",
	"Sargodha",
	"Bouaké",
	"Sialkot City",
	"As Sulaymānīyah",
	"Hwasu-dong",
	"Jeonju",
	"Durango",
	"Shimoga",
	"Ansan",
	"Bulawayo",
	"Xiping",
	"Sanhe",
	"Guntūr",
	"Dali",
	"Concord",
	"Contagem",
	"Tiruppūr",
	"Ch’ŏnan",
	"Zaragoza",
	"Düsseldorf",
	"Izhevsk",
	"Guixi",
	"Sorocaba",
	"Villahermosa",
	"Petaling Jaya",
	"Tengyue",
	"Wuxue",
	"Utrecht",
	"Kikwit",
	"Colorado Springs",
	"Valenzuela",
	"Qufu",
	"Gaobeidian",
	"Ruiming",
	"Wrocław",
	"Rasht",
	"Nantes",
	"Stuttgart",
	"Al Hufūf",
	"Xinshi",
	"Cochabamba",
	"Barnaul",
	"Tripoli",
	"Jin’e",
	"Rotterdam",
	"Benghazi",
	"Kryvyi Rih",
	"Yanggok",
	"Changping",
	"Raurkela",
	"Ḩalwān",
	"Charleston",
	"Chimalhuacán",
	"Xinxing",
	"Suohe",
	"Mangalore",
	"Zhuangyuan",
	"Ulyanovsk",
	"Irkutsk",
	"Nānded",
	"Bacoor",
	"Pontianak",
	"Villa Nueva",
	"Bazhou",
	"Springfield",
	"Turpan",
	"Xingtai",
	"Meihekou",
	"Cuiabá",
	"Jurong",
	"Feira de Santana",
	"Khabarovsk",
	"Zhugang",
	"Xinji",
	"Hamhŭng",
	"Serang",
	"Sanya",
	"Buraydah",
	"Ta‘izz",
	"Montpellier",
	"San Miguel de Tucumán",
	"Yaroslavl",
	"Zhangshu",
	"Grand Rapids",
	"Vladivostok",
	"Kuantan",
	"Gothenburg",
	"Cuttack",
	"Jambi",
	"Zhuozhou",
	"Tianchang",
	"Las Piñas City",
	"Cimahi",
	"Tuxtla",
	"Balikpapan",
	"Qamdo",
	"Joinvile",
	"Durban",
	"General Santos",
	"Chānda",
	"Kagoshima",
	"Leipzig",
	"Al Ḩillah",
	"Makhachkala",
	"Sihui",
	"Mar del Plata",
	"Brampton",
	"Luocheng",
	"Pereira",
	"Mission Viejo",
	"Dortmund",
	"Chuxiong",
	"Makati City",
	"Albany",
	"Shah Alam",
	"Knoxville",
	"Essen",
	"Shangzhi",
	"Botou",
	"Bucaramanga",
	"Anyang",
	"Genoa",
	"Kuiju",
	"Tlaquepaque",
	"Puente Alto",
	"Dehra Dūn",
	"Aţ Ţā’if",
	"San Jose del Monte",
	"Bakersfield",
	"Wancheng",
	"Hachiōji",
	"Ogden",
	"Xiulin",
	"Fu’an",
	"Tomsk",
	"Kermān",
	"Kingston upon Hull",
	"Al Maḩallah al Kubrá",
	"Kumul",
	"Luofeng",
	"Lingyuan",
	"Baton Rouge",
	"Wencheng",
	"Ciudad Bolívar",
	"Durgāpur",
	"Orenburg",
	"Shenzhou",
	"Āsansol",
	"Bacolod",
	"Akron",
	"New Haven",
	"Zhenzhou",
	"Surakarta",
	"Jieshou",
	"Lanxi",
	"Zāhedān",
	"Dangyang",
	"Columbia",
	"Kemerovo",
	"Dresden",
	"Veracruz",
	"Bhāvnagar",
	"Luanzhou",
	"Novokuznetsk",
	"Nellore",
	"Chiclayo",
	"Palma",
	"Al Ḩudaydah",
	"Eslāmshahr",
	"Cabinda",
	"Amrāvati",
	"Korla",
	"Huanghua",
	"Londrina",
	"Tabūk",
	"Xingcheng",
	"Kaiyuan",
	"Valledupar",
	"Fengcheng",
	"Ajmer",
	"Tinnevelly",
	"Fuding",
	"Maturín",
	"An Nāşirīyah",
	"Al Ḩillah",
	"Ibagué",
	"Hannover",
	"Ryazan",
	"Panshi",
	"Kassala",
	"Ananindeua",
	"Chang’an",
	"Shashi",
	"Poznań",
	"Aksu",
	"Salta",
	"Astrakhan",
	"Mingguang",
	"Naberezhnyye Chelny",
	"Bīkaner",
	"Agartala",
	"Xalapa",
	"Ndola",
	"Hamadān",
	"Villavicencio",
	"Ailan Mubage",
	"Ensenada",
	"Lyon",
	"Bắc Ninh",
	"Ciudad Apodaca",
	"Santa Teresa del Tuy",
	"Maipú",
	"Penza",
	"Heroica Matamoros",
	"Port Said",
	"Yucheng",
	"Meknès",
	"Nuremberg",
	"Pohang",
	"Anda",
	"Jinghong",
	"Utsunomiya",
	"Liaoyuan",
	"Mesa",
	"Surrey",
	"Caxias do Sul",
	"Heshan",
	"Cuautitlán Izcalli",
	"Ujjain",
	"Jiaji",
	"Santa Marta",
	"Beining",
	"Hải Dương",
	"Carrefour",
	"Homyel’",
	"Leicester",
	"Yanji",
	"Yicheng",
	"Lipetsk",
	"Choloma",
	"Ulhāsnagar",
	"Encheng",
	"Muntinlupa City",
	"Linghai",
	"Aden",
	"Matsuyama",
	"Jhānsi",
	"Kitwe",
	"Kashgar",
	"Aba",
	"Palm Bay",
	"Pingtung",
	"Montería",
	"Samarkand",
	"Davangere",
	"Jammu",
	"Mazatlán",
	"Murcia",
	"Ile-Ife",
	"Kirov",
	"Qaraghandy",
	"Belas",
	"Mazatán",
	"Duisburg",
	"Mykolaiv",
	"Provo",
	"Meicheng",
	"Niterói",
	"Matsudo",
	"Sukkur",
	"Rouen",
	"Higashi-ōsaka",
	"Worcester",
	"Hongjiang",
	"Chimbote",
	"Ixtapaluca",
	"Dengtacun",
	"Zhijiang",
	"Chengjiao",
	"Beipiao",
	"Murrieta",
	"Kota Bharu",
	"Ciudad López Mateos",
	"Vinh",
	"Tultitlán de Mariano Escobedo",
	"Duyun",
	"Kandahār",
	"Nishinomiya-hama",
	"Larkana",
	"Cheboksary",
	"Yuanping",
	"Port Sudan",
	"Edinburgh",
	"Belgaum",
	"Tula",
	"Suez",
	"Shahe",
	"Yazd",
	"Serra",
	"Nazrēt",
	"Gaoping",
	"Greenville",
	"Arāk",
	"San Nicolás de los Garza",
	"Gulbarga",
	"Juiz de Fora",
	"Dunhua",
	"Brookhaven",
	"Az Zarqā’",
	"Americana",
	"Ardabīl",
	"Sylhet",
	"Wichita",
	"Toledo",
	"Kaihua",
	"Caerdydd",
	"Jāmnagar",
	"Dhūlia",
	"Nampula",
	"Gaya",
	"Florianópolis",
	"Chiniot",
	"Jiannan",
	"Ōita",
	"Wuhai",
	"Kaliningrad",
	"Nangong",
	"Staten Island",
	"San Juan",
	"Vila Velha",
	"Macapá",
	"Shekhupura",
	"Des Moines",
	"Piura",
	"Jiaojiangcun",
	"Laohekou",
	"Beian",
	"Fujin",
	"Celaya",
	"Xiaoyi",
	"Strasbourg",
	"Lanús",
	"Qingzhen",
	"Jiangshan",
	"Ba‘qūbah",
	"Tamale",
	"Gdańsk",
	"Manado",
	"Jinchang",
	"Kanazawa",
	"Calabar",
	"Long Beach",
	"Malatya",
	"Huế",
	"Cranbourne",
	"Jalgaon",
	"Port St. Lucie",
	"Mauá",
	"Fukuyama",
	"Tel Aviv-Yafo",
	"Xicheng",
	"Marikina City",
	"Pyeongtaek",
	"Kurnool",
	"City of Calamba",
	"Denton",
	"Ar Ramādī",
	"Melaka",
	"Jian’ou",
	"Huadian",
	"Taoyuan District",
	"Iloilo",
	"Kota Kinabalu",
	"Minzhu",
	"Rājshāhi",
	"Ţanţā",
	"Balashikha",
	"Udaipur",
	"Kursk",
	"Mariupol",
	"Bukavu",
	"Hsinchu",
	"Barcelona",
	"Constantine",
	"Tanbei",
	"Ado-Ekiti",
	"Batman",
	"Pasay City",
	"Madison",
	"Baisha",
	"Betim",
	"Bellary",
	"Santiago de Cuba",
	"Yongji",
	"Reno",
	"Danjiangkou",
	"Kahramanmaraş",
	"São José do Rio Prêto",
	"Andijon",
	"Harrisburg",
	"Nancy",
	"Puerto Plata",
	"Machida",
	"Ning’an",
	"Beira",
	"Little Rock",
	"San Cristóbal",
	"Zürich",
	"Zhangjiakou Shi Xuanhua Qu",
	"Sunch’ŏn",
	"Diadema",
	"Guangming",
	"Sāngli",
	"Tuticorin",
	"Herāt",
	"Kupang",
	"Jeju",
	"Bandar ‘Abbās",
	"Santos",
	"Manizales",
	"Stavropol",
	"Katsina",
	"Yogyakarta",
	"Calicut",
	"Zanjān",
	"Welkom",
	"Ulan-Ude",
	"Oakland",
	"Mazār-e Sharīf",
	"Kāshān",
	"Kenitra",
	"Uyo",
	"Maringá",
	"Khamīs Mushayţ",
	"Masan",
	"Cusco",
	"Sevastopol",
	"Mandaluyong City",
	"Porto Velho",
	"Florencio Varela",
	"Akola",
	"Yan’an Beilu",
	"Agadir",
	"Mogi das Cruzes",
	"Durham",
	"Likasi",
	"Buenaventura",
	"Laval",
	"Toyota",
	"Winston-Salem",
	"Uijeongbu",
	"Tver",
	"Elazığ",
	"Akure",
	"Hpa-An",
	"Bonita Springs",
	"Mishan",
	"Hailin",
	"Seremban",
	"Lecheng",
	"Zhengjiatun",
	"Luhansk",
	"Pencheng",
	"Magnitogorsk",
	"Takamatsu",
	"Angeles City",
	"El Obeid",
	"Dalai",
	"Xingren",
	"Kolwezi",
	"Wenlan",
	"Indio",
	"Palm Coast",
	"Arusha",
	"Fenyang",
	"Paju",
	"Oujda-Angad",
	"Mataram",
	"Chattanooga",
	"Lapu-Lapu City",
	"Jhang City",
	"Nagqu",
	"Kisumu",
	"Jayapura",
	"Montes Claros",
	"Rio Branco",
	"Toyama",
	"Fangting",
	"Spokane",
	"Sochi",
	"Bhāgalpur",
	"Ivanovo",
	"Osogbo",
	"Imus",
	"Ciudad Obregón",
	"Türkmenabat",
	"Zaria",
	"Namangan",
	"Bryansk",
	"Taiping",
	"Maracay",
	"Jiexiu",
	"Mbale",
	"Taraz",
	"Asyūţ",
	"Santa Fe",
	"Campina Grande",
	"Nagasaki",
	"Szczecin",
	"Ghulja",
	"Syracuse",
	"Carapicuíba",
	"Quilon",
	"Jundiaí",
	"Gifu",
	"Lancaster",
	"Eindhoven",
	"Sīkar",
	"Tumkūr",
	"Jiangjiafan",
	"Dera Ghazi Khan",
	"Arlington",
	"Miyazaki",
	"Stockton",
	"Bhātpāra",
	"Sandakan",
	"Hejin",
	"Thanh Hóa",
	"Muzaffarnagar",
	"Poughkeepsie",
	"Campos",
	"Metz",
	"Nha Trang",
	"Belgorod",
	"Yola",
	"Lobito",
	"Saurimo",
	"Olinda",
	"Bologna",
	"Ad Dīwānīyah",
	"Gujrat",
	"Hancheng",
	"Fuyu",
	"Karamay",
	"Kākināda",
	"Augusta",
	"Bhīlwāra",
	"Tieli",
	"Cilegon",
	"Baicheng",
	"Nizāmābād",
	"Boise",
	"Tonalá",
	"Mwanza",
	"Aqtöbe",
	"Oxnard",
	"Tétouan",
	"Okazaki",
	"Cariacica",
	"Ḩā’il",
	"Las Palmas",
	"Yidu",
	"Lianzhou",
	"Florence",
	"Scranton",
	"Brno",
	"London",
	"Modesto",
	"Kissimmee",
	"Pasto",
	"Ichinomiya",
	"Qazvīn",
	"Irapuato",
	"Novi Sad",
	"Shihezi",
	"Shache",
	"Pānihāti",
	"Huancayo",
	"Aurora",
	"Malabon",
	"Pétion-Ville",
	"Sintra",
	"Parbhani",
	"Usulután",
	"Youngstown",
	"Christchurch",
	"Hatay",
	"Iquitos",
	"Sivas",
	"Helixi",
	"A Coruña",
	"Manukau City",
	"Stoke-on-Trent",
	"Cumaná",
	"Vinnytsia",
	"Rohtak",
	"Lātūr",
	"Bello",
	"Sanandaj",
	"Nuevo Laredo",
	"Ambon",
	"Mandaue City",
	"Keelung",
	"Toyohashi",
	"La Florida",
	"Varna",
	"Lengshuijiang",
	"Anguo",
	"Rājapālaiyam",
	"Rodriguez",
	"Madan",
	"Az Zubayr",
	"Al Qaţīf",
	"Cuernavaca",
	"Nicolás Romero",
	"Huichang",
	"Vitsyebsk",
	"Nagano",
	"Bauru",
	"Yanjiao",
	"Bochum",
	"Anápolis",
	"Coventry",
	"Zalantun",
	"Tecámac",
	"Ciudad General Escobedo",
	"Wŏnsan",
	"Kocaeli",
	"Bengkulu",
	"Shuanghejiedao",
	"Surgut",
	"Bobo-Dioulasso",
	"Umuahia",
	"Gedaref",
	"Palu",
	"Santa Rosa",
	"Pokhara",
	"Mahilyow",
	"Wudalianchi",
	"Sungai Petani",
	"Nam Định",
	"Sinŭiju",
	"Vitória",
	"Hrodna",
	"Vladimir",
	"Bilbao",
	"São Vicente",
	"Cubal",
	"Yong’an",
	"Itaquaquecetuba",
	"Wuppertal",
	"Minatitlán",
	"Nizhniy Tagil",
	"Ponta Grossa",
	"Hongzhai",
	"Wakayama",
	"Pavlodar",
	"Gimpo",
	"Rahimyar Khan",
	"Nara",
	"Van",
	"Corrientes",
	"San Pedro",
	"Neiva",
	"Arkhangelsk",
	"Licheng",
	"Koshigaya",
	"Cabimas",
	"Yakeshi",
	"Baguio City",
	"Ahmadnagar",
	"Fayetteville",
	"Canoas",
	"Holguín",
	"Qoqon",
	"Anaheim",
	"Yingmen",
	"Piracicaba",
	"Bydgoszcz",
	"Antofagasta",
	"Khorramābād",
	"Rājahmundry",
	"Chita",
	"Caruaru",
	"Makiivka",
	"Chitungwiza",
	"Pensacola",
	"Victorville",
	"Tanch’ŏn",
	"Tokorozawa",
	"Sumqayıt",
	"Kūstī",
	"Al ‘Amārah",
	"Cuddapah",
	"Simferopol",
	"Plovdiv",
	"Tarlac City",
	"Ōtsu",
	"Pelotas",
	"Lancaster",
	"Greensboro",
	"Iligan",
	"East London",
	"Franca",
	"Brest",
	"Kaluga",
	"Yeosu",
	"Corpus Christi",
	"Muzaffarpur",
	"Lublin",
	"Lianran",
	"Alwar",
	"Baishan",
	"Kawagoe",
	"Farg‘ona",
	"Tamuramachi-moriyama",
	"Brahmapur",
	"Buôn Ma Thuột",
	"Biñan",
	"Semey",
	"Tarsus",
	"Alicante",
	"Randburg",
	"Tepic",
	"Jitpur",
	"Kaesŏng",
	"Beni",
	"Petrolina",
	"Chinju",
	"Tangdong",
	"Butuan",
	"Reading",
	"Uberaba",
	"Belfast",
	"Iwaki",
	"Blida",
	"Hangu",
	"Yingzhong",
	"Viña del Mar",
	"Bielefeld",
	"Cuenca",
	"Fort Wayne",
	"Lipa City",
	"Wad Medani",
	"Ribeirão das Neves",
	"Kāmārhāti",
	"Maebashi",
	"Thessaloníki",
	"Gəncə",
	"Bilāspur",
	"Santa Ana",
	"Mymensingh",
	"Al Fayyūm",
	"Flint",
	"Kendari",
	"Balıkesir",
	"Az Zaqāzīq",
	"Thái Nguyên",
	"Smolensk",
	"Asahikawa",
	"Islip",
	"Wŏnju",
	"Dahūk",
	"Batangas",
	"Mathura",
	"Barishal",
	"Bamiantong",
	"Patiāla",
	"Cainta",
	"Bonn",
	"São José dos Pinhais",
	"Vũng Tàu",
	"Markham",
	"Saugor",
	"Malmö",
	"Roodepoort",
	"Gómez Palacio",
	"Volzhskiy",
	"Taourirt",
	"Bijāpur",
	"Sukabumi",
	"Fayetteville",
	"Taytay",
	"Ulanhot",
	"Nakuru",
	"Yunzhong",
	"Al Fallūjah",
	"Kōchi",
	"Boa Vista",
	"Gwangmyeongni",
	"Bari",
	"Pucallpa",
	"Kuching",
	"Zinder",
	"Caucaia",
	"Gonder",
	"Jackson",
	"Mekele",
	"Kulti",
	"Santa Rosa",
	"Gonaïves",
	"Lansing",
	"Naha",
	"Binxian",
	"San Juan",
	"Lexington",
	"Hotan",
	"Uíge",
	"Newcastle",
	"Kurgan",
	"Kaiyuan",
	"Temara",
	"Uruapan",
	"Camagüey",
	"Ann Arbor",
	"San Salvador de Jujuy",
	"Timişoara",
	"Córdoba",
	"Al Kūt",
	"Tapachula",
	"Shāhjānpur",
	"Cherepovets",
	"San Miguelito",
	"Menongue",
	"Poltava",
	"Henderson",
	"Maroua",
	"Kaech’ŏn",
	"Asan",
	"Coatzacoalcos",
	"Huntsville",
	"Öskemen",
	"Tampico",
	"Trichūr",
	"Cluj-Napoca",
	"General Trias",
	"Cirebon",
	"Boaco",
	"Cà Mau",
	"Cabuyao",
	"Vologda",
	"Saransk",
	"Mobile",
	"Bor",
	"Münster",
	"Barddhamān",
	"Toshima",
	"Karlsruhe",
	"Kasur",
	"Yakou",
	"Orël",
	"Safi",
	"Shahr-e Qods",
	"Vitória da Conquista",
	"Ḩamāh",
	"Guarujá",
	"Catania",
	"Purnea",
	"Datang",
	"Fort Collins",
	"Port Elizabeth",
	"Alanya",
	"Asheville",
	"Santa Clarita",
	"Gorgān",
	"Quy Nhơn",
	"Sambalpur",
	"Yokkaichi",
	"Chalco",
	"Mannheim",
	"Namp’o",
	"Shahrīār",
	"Cobán",
	"Kasugai",
	"Sapele",
	"Blumenau",
	"Sariwŏn",
	"St. Catharines",
	"Matadi",
	"Niagara Falls",
	"Fīrozābād",
	"San Fernando",
	"St. Paul",
	"Vladikavkaz",
	"Yakutsk",
	"Minna",
	"Hisar",
	"Puerto La Cruz",
	"Podolsk",
	"Ciudad Victoria",
	"Ciudad del Este",
	"Vaughan",
	"Ōakashichō",
	"Pekalongan",
	"Adıyaman",
	"Vila Nova de Gaia",
	"Curepipe",
	"Cabanatuan City",
	"Paulista",
	"Oaxaca",
	"Armenia",
	"Akita",
	"San Bernardo",
	"Wollongong",
	"Brest",
	"Awka",
	"Iksan",
	"Taubaté",
	"Antioch",
	"Lakeland",
	"Sóc Trăng",
	"Āwasa",
	"Mardan",
	"Popayán",
	"Praia Grande",
	"Qianzhou",
	"Cotabato",
	"Valladolid",
	"Mossoró",
	"Coatepeque",
	"Bīdar",
	"Białystok",
	"Mérida",
	"Murmansk",
	"Ar Raqqah",
	"Afyonkarahisar",
	"Vigo",
	"Jember",
	"Bahía Blanca",
	"İskenderun",
	"Al Mubarraz",
	"Petrópolis",
	"Al Kharj",
	"Rāmpur",
	"Najrān",
	"Chernihiv",
	"Yangsan",
	"Comilla",
	"Oyster Bay",
	"Valparaíso",
	"Augsburg",
	"Mbeya",
	"Limeira",
	"Rangpur",
	"Shiliguri",
	"Aksaray",
	"Navotas",
	"Çorum",
	"Bāli",
	"Ismailia",
	"Vila Teixeira da Silva",
	"Pānīpat",
	"Delmas",
	"Batna",
	"Kunp’o",
	"Tambov",
	"Iaşi",
	"Thiès",
	"Makurdi",
	"Kherson",
	"Groznyy",
	"Hong’an",
	"Bafoussam",
	"Resistencia",
	"Braşov",
	"Kassel",
	"Juliaca",
	"Karīmnagar",
	"Morioka",
	"Sétif",
	"Trenton",
	"Kaunas",
	"Mulhouse",
	"Sekondi",
	"Lincoln",
	"Bhuj",
	"Ichalkaranji",
	"Tirupati",
	"Springfield",
	"Anchorage",
	"Punto Fijo",
	"Sincelejo",
	"Plano",
	"Irvine",
	"Camaçari",
	"Orléans",
	"Binangonan",
	"Suzano",
	"Hospet",
	"Ostrava",
	"Tacna",
	"Korhogo",
	"Constanţa",
	"Haifa",
	"Coacalco",
	"Crato",
	"Limoges",
	"Davenport",
	"Fukushima",
	"Barinas",
	"Coro",
	"Bago",
	"Fuquan",
	"Tongchuanshi",
	"Āīzawl",
	"Santa Maria",
	"Taboão da Serra",
	"Marabá",
	"Sannai",
	"Huozhou",
	"Temuco",
	"Sterlitamak",
	"Rockford",
	"Tegal",
	"Ica",
	"Newark",
	"Jining",
	"Chuncheon",
	"Malārd",
	"Pematangsiantar",
	"Long Xuyên",
	"Petrozavodsk",
	"South Bend",
	"Mingaora",
	"San Pedro Carchá",
	"Bārāsat",
	"Shreveport",
	"Sumbe",
	"Việt Trì",
	"Cherkasy",
	"Caála",
	"Wiesbaden",
	"Kostroma",
	"Round Lake Beach",
	"Gyeongsan",
	"Governador Valadares",
	"Katowice",
	"Shaowu",
	"Sfax",
	"Nizhnevartovsk",
	"Linxia Chengguanzhen",
	"Pachuca",
	"Juazeiro do Norte",
	"Dire Dawa",
	"Khmelnytskyi",
	"Owo",
	"San Cristóbal",
	"Savannah",
	"Posadas",
	"Gatineau",
	"Windsor",
	"Myrtle Beach",
	"Mbandaka",
	"Tehuacán",
	"La Guaira",
	"Kunsan",
	"Chula Vista",
	"Ratlām",
	"Yeosu",
	"Crato",
	"Derby",
	"Tsu",
	"Kafr ad Dawwār",
	"Eugene",
	"Gijón",
	"Bijiao",
	"Chiayi",
	"Palmas",
	"Craiova",
	"Sorong",
	"Majene",
	"Bukhara",
	"Aomori",
	"Thái Bình",
	"Minato",
	"Binjai",
	"Fuchū",
	"Oral",
	"Brāhmanbāria",
	"Sartā",
	"Imphāl",
	"Plymouth",
	"Santo Domingo de los Colorados",
	"Novorossiysk",
	"Santa Ana",
	"Zhytomyr",
	"Villa Altagracia",
	"Gombe",
	"Mito",
	"Espoo",
	"Drug",
	"Floridablanca",
	"Bamenda",
	"Handwāra",
	"Lucena",
	"Canton",
	"Yoshkar-Ola",
	"Parnamirim",
	"Nalchik",
	"Ichihara",
	"Aswān",
	"Salamanca",
	"İnegöl",
	"Chernivtsi",
	"Sumaré",
	"Lubbock",
	"Yanbu‘",
	"Sumy",
	"Tshikapa",
	"Anantapur",
	"San Pablo",
	"Reading",
	"Winter Haven",
	"Ipatinga",
	"Myeik",
	"Kütahya",
	"Salem",
	"Djelfa",
	"Suncheon",
	"Graz",
	"St. Petersburg",
	"Engels",
	"Kindu",
	"Oruro",
	"Dezfūl",
	"Osmaniye",
	"Lafayette",
	"Kyŏngju",
	"Dumai",
	"Gent",
	"San Lorenzo",
	"Geelong",
	"Çorlu",
	"Nawabshah",
	"Gelsenkirchen",
	"Bordeaux",
	"Mönchengladbach",
	"Volta Redonda",
	"Matala",
	"Annaba",
	"Barueri",
	"Laredo",
	"Nonthaburi",
	"Jersey City",
	"Venice",
	"Quảng Ngãi",
	"Concord",
	"Damanhūr",
	"Ciudad Benito Juárez",
	"Cascavel",
	"Hiratsuka",
	"Columbus",
	"Chandler",
	"Sakarya",
	"Tagum",
	"Fukui",
	"Sōka",
	"Kunduz",
	"Ciudad Santa Catarina",
	"Los Mochis",
	"Imperatriz",
	"Texcoco",
	"Santa Maria",
	"Mişrātah",
	"Isparta",
	"Foz do Iguaçu",
	"Verona",
	"Huayin",
	"Etāwah",
	"McKinney",
	"Playa del Carmen",
	"Scottsdale",
	"Wolverhampton",
	"Killeen",
	"Bergen",
	"Tallahassee",
	"Horlivka",
	"Antsirabe",
	"Ondo",
	"San Mateo",
	"Ấp Đa Lợi",
	"Viamão",
	"Peoria",
	"Gravataí",
	"Kediri",
	"Damaturu",
	"Tokushima",
	"Malolos",
	"La Paz",
	"Wilmington",
	"Rāichūr",
	"Mawlamyine",
	"Turmero",
	"Kuala Terengganu",
	"Daloa",
	"Puerto Princesa",
	"Moçâmedes",
	"Vitoria-Gasteiz",
	"Qarshi",
	"Montgomery",
	"Gilbert",
	"Rishon LeẔiyyon",
	"Ongole",
	"Arua",
	"Bharatpur",
	"Shinozaki",
	"Goma",
	"Rạch Giá",
	"Kanggye",
	"Taganrog",
	"Kızıltepe",
	"El Fasher",
	"Várzea Grande",
	"Las Condes",
	"Glendale",
	"Santiago del Estero",
	"Hakodate",
	"Uşak",
	"Begusarai",
	"North Las Vegas",
	"Sonīpat",
	"Los Teques",
	"Mabalacat",
	"Jinshi",
	"Chōfugaoka",
	"Osh",
	"Iwo",
	"Bata",
	"Komsomol’sk-na-Amure",
	"Bābol",
	"Galaţi",
	"Al Bayḑā’",
	"Kurmuk",
	"Manzhouli",
	"Braunschweig",
	"Rivne",
	"Gdynia",
	"Soyapango",
	"Palangkaraya",
	"Silang",
	"Al Minyā",
	"Ḩafr al Bāţin",
	"Chigasaki",
	"Būkān",
	"Aachen",
	"Kiel",
	"Sahiwal",
	"Thủ Dầu Một",
	"Novo Hamburgo",
	"Sibu",
	"Yato",
	"Paraná",
	"Yamagata",
	"Oruro",
	"Tsukuba-kenkyūgakuen-toshi",
	"Merlo",
	"Magé",
	"Syktyvkar",
	"Khimki",
	"Birāṭnagar",
	"Colombo",
	"Chemnitz",
	"Saskatoon",
	"Colón",
	"Itagüí",
	"Abertawe",
	"Jessore",
	"Beichengqu",
	"Tuy Hòa",
	"Shrīrāmpur",
	"Chesapeake",
	"Fuji",
	"Hāpur",
	"Bahir Dar",
	"Manisa",
	"Tanga",
	"Sabzevār",
	"Rāmgundam",
	"Porto",
	"Baruta",
	"Tacloban",
	"Itaboraí",
	"Myitkyina",
	"Barnstable",
	"Haeju",
	"Petaẖ Tiqwa",
	"Norfolk",
	"González Catán",
	"Sete Lagoas",
	"Tarapoto",
	"Zhangping",
	"San Juan del Río",
	"São Carlos",
	"Fremont",
	"Halle",
	"São Leopoldo",
	"Arakawa",
	"Düzce",
	"Anju",
	"Divinópolis",
	"Aarhus",
	"Kennewick",
	"Hobart",
	"Atushi",
	"Chimoio",
	"Limassol",
	"Garland",
	"Magdeburg",
	"Irving",
	"Hachimanchō",
	"Longueuil",
	"Mokpo",
	"Košice",
	"‘Ajmān",
	"Lille",
	"Banda Aceh",
	"Shenmu",
	"Le Havre",
	"Taiping",
	"P’yŏng-dong",
	"Springs",
	"Ivano-Frankivsk",
	"As Sīb",
	"Nārāyanganj",
	"Maradi",
	"Wuyishan",
	"Sato",
	"Āmol",
	"Quilmes",
	"Mirpur Khas",
	"Nizhnekamsk",
	"Visalia",
	"Al Jubayl",
	"Pathein",
	"Secunderābād",
	"Neya",
	"Chishui",
	"Centurion",
	"Atlantic City",
	"Uluberiya",
	"Shakhty",
	"Pākdasht",
	"Granada",
	"Abhā",
	"Ibb",
	"Garoua",
	"Bole",
	"Ganda",
	"Elche",
	"Najafābād",
	"Bayamo",
	"Porbandar",
	"Kamianske",
	"Borūjerd",
	"Paradise",
	"Singaraja",
	"Macaé",
	"Miri",
	"Machala",
	"Longquan",
	"Monclova",
	"Puducherry",
	"Olongapo",
	"Messina",
	"Kremenchuk",
	"Nashua",
	"Santarém",
	"Badalona",
	"Okara",
	"Arlington",
	"Talisay",
	"Hialeah",
	"Saidpur",
	"York",
	"Saga",
	"Burnaby",
	"Rancagua",
	"Dzerzhinsk",
	"Milton Keynes",
	"Marília",
	"Bratsk",
	"Jiayuguan",
	"Indaiatuba",
	"Envigado",
	"Freiburg im Breisgau",
	"Eloy Alfaro",
	"Maracanaú",
	"Ageoshimo",
	"Qarchak",
	"Neuquén",
	"Ibb",
	"Banfield",
	"Cabo Frio",
	"Singkawang",
	"Orsk",
	"Vizianagaram",
	"North Hempstead",
	"Evansville",
	"Cotia",
	"Pāli",
	"Noginsk",
	"Kropyvnytskyi",
	"Częstochowa",
	"Rondonópolis",
	"Guantánamo",
	"Krefeld",
	"Tanza",
	"Marilao",
	"Guadalupe",
	"Taitō",
	"Kolpino",
	"Burgas",
	"Puerto Vallarta",
	"Avondale",
	"Nyala",
	"Brownsville",
	"Daşoguz",
	"Türkistan",
	"Probolinggo",
	"Jacareí",
	"Gusau",
	"Buenavista",
	"Quetzaltenango",
	"Tarrasa",
	"Araraquara",
	"Angarsk",
	"Sousse",
	"Nāgercoil",
	"Heroica Nogales",
	"Apapa",
	"Nyanza",
	"Itapevi",
	"Bạc Liêu",
	"Varāmīn",
	"Padangsidempuan",
	"Karnāl",
	"Mubi",
	"Toamasina",
	"Campeche",
	"Korolëv",
	"Dourados",
	"Hunchun",
	"Tampere",
	"Minamiōzuma",
	"Blagoveshchensk",
	"Velikiy Novgorod",
	"Longjin",
	"Ashdod",
	"Carlos Manuel de Céspedes",
	"Niğde",
	"Chapecó",
	"Sandton",
	"Tanjore",
	"Staryy Oskol",
	"Itajaí",
	"Puri",
	"Cartago",
	"Ji’an Shi",
	"Soledad de Graciano Sánchez",
	"Presidente Prudente",
	"José C. Paz",
	"Ternopil",
	"Rufisque",
	"Formosa",
	"Sabadell",
	"Guéckédou",
	"Sambhal",
	"Polokwane",
	"Neyshābūr",
	"Sidon",
	"Radom",
	"Lutsk",
	"Oviedo",
	"Gulfport",
	"Sāveh",
	"La Vega",
	"Appleton",
	"Khomeynī Shahr",
	"Juazeiro",
	"Itabuna",
	"Al Khubar",
	"Islington",
	"Córdoba",
	"Ciudad Acuña",
	"Tuluá",
	"Mainz",
	"Hortolândia",
	"Bitung",
	"Naihāti",
	"Petrel",
	"Damietta",
	"Manta",
	"Butembo",
	"San Miguel",
	"San Pedro de Macorís",
	"Netanya",
	"Bremerton",
	"Alor Setar",
	"Hickory",
	"Luton",
	"Tacoma",
	"Laâyoune",
	"Aberdeen",
	"Lübeck",
	"Denov",
	"Maţraḩ",
	"Qostanay",
	"Banjarbaru",
	"Sa Đéc",
	"Norwich",
	"Gujiao",
	"Cartagena",
	"Petropavl",
	"Marg‘ilon",
	"Gangneung",
	"San Bernardino",
	"Cúa",
	"Meycauayan",
	"Vantaa",
	"As Samāwah",
	"Ormoc",
	"College Station",
	"Golmud",
	"Kalamazoo",
	"Regina",
	"Thousand Oaks",
	"La Victoria",
	"Shimla",
	"Mohammedia",
	"Babruysk",
	"Roanoke",
	"Osan",
	"Geneva",
	"Fontana",
	"Sikasso",
	"Alvorada",
	"Warnes",
	"Ciudad Madero",
	"Oberhausen",
	"El Tigre",
	"Jerez de la Frontera",
	"Jalālābād",
	"Moreno Valley",
	"Sidi Bel Abbès",
	"Limbe",
	"Kure",
	"Tumaco",
	"Padova",
	"Pamplona",
	"Arroyo Naranjo",
	"Ploieşti",
	"Criciúma",
	"Metepec",
	"Rio Grande",
	"Pskov",
	"Bila Tserkva",
	"Chungju",
	"Babylon",
	"Tunja",
	"Barrancabermeja",
	"Cachoeiro de Itapemirim",
	"Santa Cruz",
	"San-Pédro",
	"Bukit Mertajam",
	"Ijebu-Ode",
	"Cork",
	"Guarenas",
	"Taisheng",
	"Rostock",
	"Dongning",
	"Moratuwa",
	"Burutu",
	"Ich’ŏn",
	"Puerto Cabello",
	"Beersheba",
	"Nasīm Shahr",
	"North Port",
	"Almere",
	"Lubuklinggau",
	"Qinā",
	"Mary",
	"Fargo",
	"Huangyan",
	"Santa Clara",
	"Kharagpur",
	"Northcote",
	"Waitakere",
	"Monywa",
	"Dindigul",
	"Morogoro",
	"Green Bay",
	"Cidade de Nacala",
	"Talca",
	"Portoviejo",
	"Spring Valley",
	"Pingzhen",
	"Linz",
	"Trieste",
	"Phan Thiết",
	"Kalemie",
	"Biskra",
	"Kamirenjaku",
	"Ingrāj Bāzār",
	"Banī Suwayf",
	"Ellore",
	"León",
	"Mytishchi",
	"Zanzibar",
	"Jiutai",
	"Qā’em Shahr",
	"Bolu",
	"Amarillo",
	"Ziguinchor",
	"Ternate",
	"Puqi",
	"Bené Beraq",
	"Passo Fundo",
	"La Ceiba",
	"Yachiyo",
	"Coquimbo",
	"Mandi Burewala",
	"Marawi City",
	"Erfurt",
	"Portland",
	"Groningen",
	"Biysk",
	"Charleroi",
	"La Romana",
	"Mazabuka",
	"Poza Rica de Hidalgo",
	"Toruń",
	"Tanjungpinang",
	"Zhubei",
	"Djougou",
	"Luxor",
	"Tarakan",
	"Santa Barbara",
	"Gainesville",
	"Las Tunas",
	"Al Khawr",
	"Lyubertsy",
	"Matsue",
	"Sosnowiec",
	"Arica",
	"Al Khums",
	"Almería",
	"Huacho",
	"Debrecen",
	"Nandyāl",
	"Cajamarca",
	"Huntington",
	"Croydon",
	"Lashkar Gāh",
	"Haldia",
	"San Pablo de las Salinas",
	"Mai’Adua",
	"Jacobabad",
	"Tokat",
	"Qyzylorda",
	"Néma",
	"Olympia",
	"Gurgaon",
	"Guacara",
	"Liège",
	"Oulu",
	"Frisco",
	"Isidro Casanova",
	"Maīmanah",
	"Yonkers",
	"Rio Claro",
	"Norwich",
	"Az Zāwīyah",
	"Bulandshahr",
	"Kasama",
	"Puerto Cortés",
	"Kouribga",
	"Lahad Datu",
	"Bojnūrd",
	"Pagadian",
	"Fuenlabrada",
	"Banja Luka",
	"Glendale",
	"Huntington Beach",
	"Baharampur",
	"Taranto",
	"Brescia",
	"Logroño",
	"Ashino",
	"Martapura",
	"Chakradharpur",
	"Deltona",
	"Chilpancingo",
	"Naga City",
	"Richmond",
	"Holon",
	"Gemena",
	"Tocuyito",
	"Oradea",
	"Legazpi City",
	"Hedong",
	"Madhyamgram",
	"Aurora",
	"Bhiwāni",
	"Burhānpur",
	"Higashi-Hiroshima",
	"Mbanza-Ngungu",
	"Huánuco",
	"Hino",
	"Zipaquirá",
	"Béni Mellal",
	"Prokopyevsk",
	"Rajin",
	"Ghāndīnagar",
	"Eldoret",
	"Kusŏng",
	"Sūhāj",
	"Khammam",
	"Kırıkkale",
	"Parma",
	"Suzuka",
	"Brikama",
	"Acarigua",
	"Hugli",
	"Viranşehir",
	"San Luis Río Colorado",
	"Iskandar",
	"Tempe",
	"Naka",
	"Leganés",
	"Kenema",
	"La Serena",
	"Overland Park",
	"Bandar-e Būshehr",
	"Prato",
	"Cholula de Rivadabia",
	"Anseong",
	"Yuzhno-Sakhalinsk",
	"Richmond Hill",
	"Desē",
	"Gorontalo",
	"Grand Prairie",
	"Quelimane",
	"Tébessa",
	"Kumagaya",
	"Al Jahrā’",
	"Karaman",
	"La Plata",
	"San Juan Sacatepéquez",
	"Cap-Haïtien",
	"Oakville",
	"Sunrise Manor",
	"Muar",
	"Godoy Cruz",
	"Muş",
	"Hyesan",
	"Kielce",
	"Arapiraca",
	"Valencia",
	"Araçatuba",
	"Swindon",
	"Castanhal",
	"Guanare",
	"Waco",
	"Basildon",
	"Madiun",
	"Valera",
	"Mahbūbnagar",
	"Pasuruan",
	"Aydın",
	"Shibīn al Kawm",
	"Armavir",
	"Nova Friburgo",
	"Donostia",
	"Balakovo",
	"Batu",
	"Anjōmachi",
	"Salinas",
	"Mwene-Ditu",
	"Sơn Tây",
	"Ferraz de Vasconcelos",
	"Santa Bárbara d’Oeste",
	"Saddiqabad",
	"Rybinsk",
	"Chŏngju",
	"Hagen",
	"Ngaoundéré",
	"Potosí",
	"San Carlos City",
	"Pak Kret",
	"Waterbury",
	"Donghua",
	"Parakou",
	"Mutare",
	"Mataró",
	"Narsingdi",
	"Pinar del Río",
	"Clarksville",
	"Rāiganj",
	"Tachikawa",
	"Prabumulih",
	"Angra dos Reis",
	"San Francisco de Macorís",
	"Al Ḩasakah",
	"Turku",
	"Guatire",
	"Iquique",
	"Cedar Rapids",
	"Kōfu",
	"San Diego",
	"Gharyān",
	"Tottori",
	"Bournemouth",
	"Chaedŏk",
	"Jōetsu",
	"Prizren",
	"Sioux Falls",
	"Kairouan",
	"Shendi",
	"Izuo",
	"Reims",
	"Modena",
	"Ed Damazin",
	"Khŭjand",
	"El Jadid",
	"Dunhuang",
	"Huntington",
	"Mahesāna",
	"Guri",
	"Jamaame",
	"Panabo",
	"Breda",
	"Ādoni",
	"Semnān",
	"Temirtaū",
	"Basel",
	"Yei",
	"Ontario",
	"Luzhang",
	"Hagerstown",
	"Al Qāmishlī",
	"Ibirité",
	"Manzanillo",
	"Erie",
	"Teresópolis",
	"Vancouver",
	"Nampa",
	"Fardīs",
	"Fianarantsoa",
	"Banjar",
	"Severodvinsk",
	"Rzeszów",
	"Siguiri",
	"Calbayog City",
	"Bhusāval",
	"Trondheim",
	"Aqtaū",
	"Niš",
	"Araguaína",
	"Narashino",
	"Abakan",
	"Burlington",
	"Bahraigh",
	"Potsdam",
	"Bo",
	"Worthing",
	"Pinrang",
	"Guarapuava",
	"Spartanburg",
	"Brăila",
	"Gastonia",
	"Amadora",
	"Braga",
	"Fort Lauderdale",
	"Kabankalan",
	"Reggio di Calabria",
	"Tonk",
	"Berazategui",
	"Khowy",
	"Sirsa",
	"Tāngāil",
	"Jaunpur",
	"Dosquebradas",
	"Saarbrücken",
	"Santo Tomas",
	"Petropavlovsk-Kamchatskiy",
	"Sittwe",
	"Epe",
	"Phan Rang-Tháp Chàm",
	"Jaraguá do Sul",
	"Jīma",
	"Townsville",
	"Lhokseumawe",
	"Gliwice",
	"Funtua",
	"Hinthada",
	"Turbo",
	"La Rioja",
	"Çanakkale",
	"Madanapalle",
	"Palopo",
	"Ayacucho",
	"Obuase",
	"Alleppey",
	"Ipswich",
	"Boma",
	"Tiaret",
	"Edirne",
	"Longjing",
	"Split",
	"Ilhéus",
	"Lorain",
	"Barra Mansa",
	"Klerksdorp",
	"Murfreesboro",
	"High Point",
	"Hamm",
	"Tarija",
	"Al ‘Arīsh",
	"São José de Ribamar",
	"Paita",
	"Yopal",
	"Salatiga",
	"Newport News",
	"Torbalı",
	"Langsa",
	"Bade",
	"Bida",
	"Şalālah",
	"Santander",
	"Matosinhos",
	"Bīrjand",
	"Lin’an",
	"Chicoloapan",
	"Vellore",
	"Dam Dam",
	"Bejaïa",
	"Rancho Cucamonga",
	"Norilsk",
	"Baranavichy",
	"Nossa Senhora do Socorro",
	"Hemet",
	"Yangmei",
	"Ciudad Valles",
	"Kadugli",
	"Les Cayes",
	"Alcorcón",
	"Piraeus",
	"Sunderland",
	"Linhares",
	"Letpandan",
	"Zabrze",
	"Almada",
	"Ilford",
	"Plzeň",
	"Cuautla",
	"Rio Verde",
	"Santa Cruz",
	"Saint-Louis",
	"Ait Melloul",
	"Toulon",
	"Ciudad del Carmen",
	"Danbury",
	"Castellón de la Plana",
	"Peoria",
	"Oeiras",
	"Dagupan City",
	"Catumbela",
	"Ereğli",
	"Oceanside",
	"Shibirghān",
	"Chandannagar",
	"Harar",
	"Middlesbrough",
	"Tyre",
	"Koronadal",
	"Olsztyn",
	"Cuddalore",
	"Getafe",
	"Marāgheh",
	"Ōbiraki",
	"Tebingtinggi",
	"Comitán",
	"Takaoka",
	"Sīrjān",
	"Tanauan",
	"Menemen",
	"Malaybalay",
	"Elk Grove",
	"Syzran",
	"Carúpano",
	"Saint-Étienne",
	"Luziânia",
	"Lagos de Moreno",
	"Albacete",
	"Ludwigshafen",
	"León",
	"Chīrāla",
	"Titāgarh",
	"Bielsko-Biała",
	"Beppuchō",
	"General Mariano Alvarez",
	"Bytom",
	"Linjiang",
	"Hạ Long",
	"Deo",
	"Tiantoujiao",
	"Pembroke Pines",
	"Tlemcen",
	"Hitachi",
	"Shāhīn Shahr",
	"Orekhovo-Borisovo Yuzhnoye",
	"Vallejo",
	"Bertoua",
	"Moca",
	"Banyuwangi",
	"Mülheim",
	"Sacaba",
	"Carpina",
	"Reggio Emilia",
	"Banhā",
	"Barrie",
	"Krasnogorsk",
	"Kaolack",
	"Izumo",
	"Burgos",
	"Niiza",
	"Guanajuato",
	"Francisco Morato",
	"Garden Grove",
	"Volgodonsk",
	"Bago",
	"Toledo",
	"Kamensk-Ural’skiy",
	"Enterprise",
	"Kohat",
	"Cuauhtémoc",
	"Medford",
	"Loja",
	"Hamilton",
	"Ussuriysk",
	"Uvira",
	"Portmore",
	"Machilīpatnam",
	"Bordj Bou Arreridj",
	"Nukus",
	"Malāyer",
	"Timon",
	"N’Zérékoré",
	"Cary",
	"Kluang",
	"Novocherkassk",
	"Métouia",
	"Nishio",
	"Marysville",
	"Digos",
	"San Luis",
	"Puerto Montt",
	"Corona",
	"Oldenburg",
	"Medinīpur",
	"Fenglu",
	"Al Marj",
	"Sŏsan",
	"Ocala",
	"Uppsala",
	"Gondomar",
	"Santa Rita",
	"San Juan",
	"Jamālpur",
	"Poços de Caldas",
	"Fredericksburg",
	"Sobral",
	"Bāramūla",
	"Roxas City",
	"Ambato",
	"Sorsogon",
	"Higüey",
	"Tanjungbalai",
	"Hŭich’ŏn",
	"Mahābād",
	"Itapecerica da Serra",
	"São Caetano do Sul",
	"Basuo",
	"Zlatoust",
	"Rustenburg",
	"Pátra",
	"Ríohacha",
	"Catape",
	"Nador",
	"Gainesville",
	"Itu",
	"Offa",
	"Fatehpur",
	"Arar",
	"Hà Tĩnh",
	"Kandy",
	"Al Kūfah",
	"Kimberley",
	"Iwata",
	"Kismaayo",
	"Tenāli",
	"Takaoka",
	"Skikda",
	"Nantou",
	"Siirt",
	"Kuytun",
	"Osnabrück",
	"Körfez",
	"Perugia",
	"Udipi",
	"Oshawa",
	"Klaipėda",
	"Leverkusen",
	"Idlib",
	"Béchar",
	"Warrington",
	"Sitalpur",
	"Caxias",
	"Cienfuegos",
	"Proddatūr",
	"Conjeeveram",
	"Saqqez",
	"Zhanlicun",
	"Huddersfield",
	"Fukang",
	"Slough",
	"Bhisho",
	"Chillán",
	"Abū Ḩulayfah",
	"Odense",
	"Metro",
	"Ramat Gan",
	"Muridke",
	"Ipswich",
	"Ruse",
	"Myingyan",
	"Haarlem",
	"Tema",
	"Piedras Negras",
	"Itapetininga",
	"Pocheon",
	"Turgutlu",
	"Navsāri",
	"Wau",
	"Ube",
	"Gölcük",
	"Muzaffargarh",
	"Kebili",
	"Jizzax",
	"Atyraū",
	"Simao",
	"Mostar",
	"El Geneina",
	"Jinggang",
	"Bandar-e Māhshahr",
	"Manchester",
	"Anaco",
	"Sullana",
	"Godhra",
	"Zemun",
	"Jutiapa",
	"Budaun",
	"Chittoor",
	"San Martin Texmelucan de Labastida",
	"Andong",
	"Ash Shaţrah",
	"Guadalajara",
	"Rafsanjān",
	"Benoni",
	"Lubao",
	"Heidelberg",
	"Al Ghardaqah",
	"Salihli",
	"Ndalatando",
	"Apeldoorn",
	"Elektrostal",
	"Jequié",
	"Bontang",
	"Đồng Hới",
	"Krishnanagar",
	"Szeged",
	"Sherbrooke",
	"Solingen",
	"Tacheng",
	"Dhamār",
	"San Miguel",
	"José María Ezeiza",
	"Bayamón",
	"Bragança Paulista",
	"Ra’s al Khaymah",
	"Pindamonhangaba",
	"Dijon",
	"Zhengding",
	"Girón",
	"Khōst",
	"Darmstadt",
	"Saint-Marc",
	"La Laguna",
	"Arad",
	"Khanpur",
	"Taunggyi",
	"Bingöl",
	"San Nicolás de los Arroyos",
	"Huaycan",
	"Bajos de Haina",
	"Kramatorsk",
	"Livorno",
	"Shahr-e Kord",
	"Talas",
	"Ekibastuz",
	"Trece Martires City",
	"Hat Yai",
	"Chirchiq",
	"Catamarca",
	"Jijiga",
	"Champaign",
	"Alexandria",
	"George",
	"Herne",
	"Ravenna",
	"Higashimurayama",
	"Hayward",
	"Abaetetuba",
	"Ōgaki",
	"Saharsa",
	"Stara Zagora",
	"Châu Đốc",
	"Guimarães",
	"Matsuzaka",
	"Harīpur",
	"Springfield",
	"San Cristóbal",
	"Rafael Castillo",
	"Río Cuarto",
	"Bonao",
	"Villeurbanne",
	"Villanueva",
	"Gojra",
	"Teixeira de Freitas",
	"Lakewood",
	"Oxford",
	"Camarajibe",
	"Lafayette",
	"Fethiye",
	"Ŭiwang",
	"Navojoa",
	"Calama",
	"Erzincan",
	"Chaoshan",
	"Middelburg",
	"Lajes",
	"Salzburg",
	"Mandi Bahauddin",
	"Zonguldak",
	"Isiro",
	"El Eulma",
	"Jiutepec",
	"Batumi",
	"Pathānkot",
	"Nazilli",
	"Frederick",
	"Piteşti",
	"Chlef",
	"Chetumal",
	"Angers",
	"Hanam",
	"Matagalpa",
	"Vidisha",
	"Paranaguá",
	"Cẩm Phả",
	"Esmeraldas",
	"Kariya",
	"Lake Charles",
	"Odessa",
	"Mexico",
	"Cagliari",
	"Zaanstad",
	"Hitachi-Naka",
	"Nalgonda",
	"Ordu",
	"Tuscaloosa",
	"Horad Barysaw",
	"Souk Ahras",
	"Neuss",
	"Rio das Ostras",
	"Miskolc",
	"Warner Robins",
	"Bartın",
	"Palmdale",
	"Melitopol",
	"Cadiz",
	"Zango",
	"Concepcion",
	"Hollywood",
	"Bālurghāt",
	"Midland",
	"Surigao",
	"Dibrugarh",
	"Mahajanga",
	"Pikit",
	"Salavat",
	"Bandırma",
	"Leesburg",
	"Tuguegarao",
	"Gandajika",
	"Silao",
	"San Miguel",
	"Regensburg",
	"Port Arthur",
	"York",
	"Palo Negro",
	"Almetyevsk",
	"Osorno",
	"Lucapa",
	"Kafr ash Shaykh",
	"Tama",
	"Seogwipo",
	"Tochigi",
	"Moanda",
	"Barreiras",
	"Tete",
	"Mzuzu",
	"Parnaíba",
	"Fyzābād",
	"Harrow",
	"Poole",
	"Hoeryŏng",
	"Kırşehir",
	"Muskegon",
	"Sievierodonetsk",
	"Sinpo",
	"Dutse",
	"Baliuag",
	"Silchar",
	"Macon",
	"Polomolok",
	"Cairns",
	"Yilan",
	"Papantla de Olarte",
	"Kansas City",
	"Dahuaishu",
	"Ciudad de Melilla",
	"Shāntipur",
	"Golmeh",
	"Peristéri",
	"Ueda",
	"Hindupur",
	"Sunnyvale",
	"Fengyicun",
	"Baubau",
	"Mudon",
	"Taza",
	"Settat",
	"Tulancingo",
	"Erode",
	"Midsayap",
	"Gwangyang",
	"Tororo",
	"Kukichūō",
	"Gonbad-e Kāvūs",
	"Al Manāqil",
	"Miass",
	"Chinandega",
	"Nîmes",
	"Saint Albans",
	"Nakhodka",
	"Quevedo",
	"Bintulu",
	"Pomona",
	"Siem Reap",
	"Foggia",
	"Escondido",
	"Cao Lãnh",
	"Riobamba",
	"Vryheid",
	"Benguela",
	"Aş Şuwayḩirah as Sāḩil",
	"Paderborn",
	"Sayama",
	"Zamora",
	"Manzanillo",
	"Pasadena",
	"Florencio Varela",
	"Badajoz",
	"Talcahuano",
	"Patos de Minas",
	"Copiapó",
	"M’Sila",
	"Kragujevac",
	"Pointe-à-Pitre",
	"Rimini",
	"Dundee",
	"Ocumare del Tuy",
	"Komaki",
	"Valdivia",
	"Gulu",
	"Shimotoda",
	"Shāhrūd",
	"Kutaisi",
	"Kashikishi",
	"Sumbawanga",
	"Keren",
	"Odivelas",
	"Centro Habana",
	"New Bedford",
	"Jāmuria",
	"Salamanca",
	"Sariaya",
	"Potchefstroom",
	"Arnhem",
	"Concordia",
	"Abbottabad",
	"Delicias",
	"Mallawī",
	"Ağrı",
	"Amasya",
	"Lạng Sơn",
	"Iruma",
	"Kastamonu",
	"Marvdasht",
	"Marbella",
	"Kanasín",
	"Chingola",
	"Dongsheng",
	"Fairfield",
	"Mostaganem",
	"Hābra",
	"Mauli",
	"Sibiu",
	"Naperville",
	"Quilpué",
	"Yonago",
	"Dolores Hidalgo Cuna de la Independencia Nacional",
	"Kopeysk",
	"Lüleburgaz",
	"Al Ḩawīyah",
	"Bellevue",
	"Binghamton",
	"Nchelenge",
	"Negombo",
	"Ambāla",
	"Disūq",
	"Turbat",
	"Mangghystaū",
	"Malakal",
	"Bacău",
	"Elkhart",
	"Topeka",
	"Mogi Guaçu",
	"Man",
	"Joliet",
	"Pyatigorsk",
	"Clermont-Ferrand",
	"Moshi",
	"Bizerte",
	"Dar‘ā",
	"Rubtsovsk",
	"Cam Ranh",
	"Wamba",
	"Franco da Rocha",
	"Odintsovo",
	"Dadu",
	"Sagay",
	"Bălţi",
	"Misato",
	"Pleiku",
	"Araucária",
	"San Juan",
	"Mejicanos",
	"Colina",
	"Kolomna",
	"Lárisa",
	"Yima",
	"Santo Agostinho",
	"Beaumont",
	"Sinop",
	"García",
	"Baní",
	"Xintang",
	"Matanzas",
	"Ilagan",
	"David",
	"Le Mans",
	"San Justo",
	"Qūchān",
	"Berezniki",
	"Pôrto Seguro",
	"Shillong",
	"Ereğli",
	"Durrës",
	"Yalova",
	"Huelva",
	"Kusatsu",
	"Villa de Cura",
	"Kakamigahara",
	"Paterson",
	"Merced",
	"Kolār",
	"Cape Coast",
	"Al Mukallā",
	"Flores",
	"Comayagua",
	"Dongxing",
	"Barranca",
	"Khasavyurt",
	"Saguenay",
	"Tuxtepec",
	"Kumba",
	"Barrechid",
	"Bharatpur",
	"Florencia",
	"Pueblo",
	"Coimbra",
	"Ajdābiyā",
	"Tyler",
	"Ciego de Ávila",
	"San Jose",
	"Pécs",
	"Ghaznī",
	"Blackpool",
	"Nawābganj",
	"Altay",
	"El Progreso",
	"Torrance",
	"Pemba",
	"Túxpam de Rodríguez Cano",
	"Jaú",
	"Touggourt",
	"Nevşehir",
	"Lévis",
	"Bhīmavaram",
	"Bilbays",
	"Jinjiang",
	"Tottenham",
	"Aix-en-Provence",
	"Cizre",
	"Los Ángeles",
	"Gisenyi",
	"Boca Chica",
	"Yuma",
	"Telford",
	"Toledo",
	"Lichinga",
	"Ozamiz City",
	"Moundou",
	"Tetovo",
	"Nizip",
	"Maykop",
	"Bahawalnagar",
	"Fukayachō",
	"Lausanne",
	"Kamālshahr",
	"Gweru",
	"Kelowna",
	"Metairie",
	"Irákleio",
	"Mandsaur",
	"Rybnik",
	"Nong’an",
	"Inezgane",
	"Rize",
	"Vlorë",
	"Jahrom",
	"Surprise",
	"Columbia",
	"Colima",
	"Athens",
	"Roseville",
	"Thornton",
	"Khuzdar",
	"Tepatitlán de Morelos",
	"Abbotsford",
	"Mati",
	"Valle de Santiago",
	"Ruda Śląska",
	"Miramar",
	"Batu Pahat",
	"Pasadena",
	"Kovrov",
	"Teófilo Otoni",
	"Mesquite",
	"Kokubunji",
	"Shizhaobi",
	"Kumbakonam",
	"Kidapawan",
	"Capas",
	"Olathe",
	"Santa Maria",
	"Masaya",
	"Médéa",
	"Yaritagua",
	"San Jose",
	"Houma",
	"Sāmarrā’",
	"Fusagasugá",
	"Sale",
	"Al ‘Aqabah",
	"Torbat-e Ḩeydarīyeh",
	"Momostenango",
	"Ituzaingó",
	"Dawei",
	"Boulogne-Billancourt",
	"Zielona Góra",
	"Kuwana",
	"Atbara",
	"Tours",
	"Pakpattan",
	"Shizuishan",
	"Chicacole",
	"Botucatu",
	"Dhahran",
	"Pābna",
	"Cametá",
	"San Miguel de Allende",
	"Koga",
	"Coquitlam",
	"Carrollton",
	"Tân An",
	"Grand Junction",
	"Ishizaki",
	"Kökshetaū",
	"Zacatecas",
	"Barahona",
	"Pīrānshahr",
	"Tiruvannāmalai",
	"Tarragona",
	"Amiens",
	"Umtata",
	"Charleston",
	"Orange",
	"Fullerton",
	"Sancti Spíritus",
	"Barbacena",
	"Bat Yam",
	"Boca del Rio",
	"Zama",
	"Mojokerto",
	"Darwin",
	"Ingolstadt",
	"Mandya",
	"Boulogne-sur-Mer",
	"Greeley",
	"Ch’ungmu",
	"Al Khmissat",
	"Birgañj",
	"Iğdır",
	"Palhoça",
	"Lira",
	"Negage",
	"Gunungsitoli",
	"Danao",
	"Sabará",
	"Yunxian Chengguanzhen",
	"Atibaia",
	"Jyväskylä",
	"Bānkura",
	"Vila Franca de Xira",
	"Quillacollo",
	"Garanhuns",
	"Santa María Texmelucan",
	"Livingstone",
	"Toowoomba",
	"Bima",
	"Kisarazu",
	"Porto Amboim",
	"Kigoma",
	"Maia",
	"Yaizu",
	"Encarnación",
	"Carolina",
	"Marīvān",
	"Santa Tecla",
	"Inazawa",
	"Uppsala",
	"Chech’ŏn",
	"Jinotega",
	"Tiraspol",
	"Apucarana",
	"Pageralam",
	"Tizi Ouzou",
	"Giresun",
	"Termiz",
	"Zinacantepec",
	"Târgu-Mureş",
	"Las Cruces",
	"Salerno",
	"’s-Hertogenbosch",
	"Panama City",
	"Blitar",
	"Harlingen",
	"Chiang Mai",
	"Brighton",
	"Dos Hermanas",
	"Tobruk",
	"Tauranga",
	"Pyay",
	"Ramapo",
	"Cartago",
	"Santiago",
	"May Pen",
	"Urganch",
	"Zākhū",
	"Dumaguete City",
	"West Valley City",
	"Shuangcheng",
	"Andīmeshk",
	"Santa Rita",
	"Ksar El Kebir",
	"Consolacion",
	"Tabaco",
	"Nakhon Ratchasima",
	"El Oued",
	"Jāzān",
	"Ashqelon",
	"Laghouat",
	"Mörön",
	"Shahreẕā",
	"Zābol",
	"Nasugbu",
	"Hampton",
	"Araruama",
	"Arayat",
	"Calapan",
	"Trois-Rivières",
	"Urdaneta",
	"Zhangmu Touwei",
	"Milagro",
	"Heroica Guaymas",
	"Minglanilla",
	"Apatzingan de la Constitucion",
	"Batticaloa",
	"Navoiy",
	"Reẖovot",
	"Campo Largo",
	"Idfū",
	"Tando Allahyar",
	"Warren",
	"Mauldin",
	"Bloomington",
	"Enfield",
	"Apopa",
	"Ségou",
	"Coral Springs",
	"Innsbruck",
	"Ōme",
	"Battambang",
	"Jijel",
	"Hassan",
	"Pitalito",
	"Round Rock",
	"Abiko",
	"Yakima",
	"Khorramshahr",
	"Ouargla",
	"Trà Vinh",
	"Ninh Bình",
	"San Carlos",
	"Győr",
	"Ödemiş",
	"Kamëz",
	"Ferrara",
	"San Luis",
	"Ibarra",
	"Sterling Heights",
	"Stavanger",
	"Yavatmāl",
	"Kent",
	"Karabük",
	"High Wycombe",
	"Calabozo",
	"Lae",
	"Pīlibhīt",
	"Guelph",
	"Pālghāt",
	"Sogamoso",
	"Buea",
	"Nanqiaotou",
	"Ji-Paraná",
	"Rijeka",
	"Santa Cruz do Sul",
	"Spanish Town",
	"Pālakollu",
	"Dipolog",
	"Narita",
	"Surat Thani",
	"Relizane",
	"Malasiqui",
	"Ciénaga",
	"Parepare",
	"Västerås",
	"Hagonoy",
	"Torrejón de Ardoz",
	"Burlington",
	"Nueva Concepción",
	"La Trinidad",
	"Abohar",
	"Tychy",
	"Marand",
	"Quibdó",
	"Girardot",
	"Pouso Alegre",
	"Kānchrāpāra",
	"Rustavi",
	"Bellingham",
	"Itapipoca",
	"Onomichi",
	"Kislovodsk",
	"Maranguape",
	"Kozan",
	"Udon Thani",
	"Jīroft",
	"Tabora",
	"Santa Clara",
	"Vitória de Santo Antão",
	"Çayırova",
	"Sannār",
	"Lucheng",
	"Racine",
	"Greenville",
	"Zhaozhou",
	"Saïda",
	"Estelí",
	"Taldyqorghan",
	"Conselheiro Lafaiete",
	"Cambridge",
	"Calabayan",
	"Baidoa",
	"Jaramānā",
	"Fengcheng",
	"Fürth",
	"Serpukhov",
	"Bolton",
	"Tukuyu",
	"Stamford",
	"Villa Alemana",
	"Chinguar",
	"Táriba",
	"Würzburg",
	"Songnim",
	"Mansa",
	"Elizabeth",
	"Opole",
	"Novocheboksarsk",
	"Araras",
	"Petapa",
	"Romford",
	"Mīt Ghamr",
	"Puno",
	"Jaranwala",
	"Salto",
	"Newport",
	"Ahmadpur East",
	"Alagoinhas",
	"Annecy",
	"Kamina",
	"Vihari",
	"Koforidua",
	"Azua",
	"Baigou",
	"Temperley",
	"Drohobych",
	"Seto",
	"Shiyan",
	"Bataysk",
	"Whitby",
	"Mariveles",
	"Pinsk",
	"Sakākā",
	"Tumen",
	"Mỹ Tho",
	"Hāthras",
	"La Granja",
	"Divo",
	"Guasdualito",
	"Domodedovo",
	"Darnah",
	"Kadoma",
	"Salmās",
	"Port Blair",
	"Cubatão",
	"Kaspiysk",
	"Anderlecht",
	"Alīpur Duār",
	"Neftekamsk",
	"Daraga",
	"Ōmiyachō",
	"Johnson City",
	"Örebro",
	"Silay",
	"Bam",
	"Ulm",
	"Lafia",
	"Monza",
	"Tây Ninh",
	"Santana de Parnaíba",
	"Nefteyugansk",
	"Heilbronn",
	"Pakokku",
	"Payakumbuh",
	"Duitama",
	"Angren",
	"Barcarena Nova",
	"Basīrhat",
	"Miramar",
	"Ixtlahuaca",
	"Latina",
	"Guarapari",
	"Ünye",
	"Ramos Mejía",
	"Uruguaiana",
	"Navadwīp",
	"Exeter",
	"Pforzheim",
	"Lynchburg",
	"Larache",
	"Iizuka",
	"Guntakal",
	"Catabola",
	"Pangkalpinang",
	"Simi Valley",
	"Jolo",
	"Aalborg",
	"Ban Bang Pu Mai",
	"Solihull",
	"Hālīsahar",
	"Magway",
	"Shashemenē",
	"Rishra",
	"Malanje",
	"Jizhou",
	"Magelang",
	"Gashua",
	"Shchelkovo",
	"Kampong Cham",
	"Floridablanca",
	"Novomoskovsk",
	"Santa Lucía Cotzumalguapa",
	"Talavera",
	"Cam Ranh",
	"Giugliano in Campania",
	"Kumbo",
	"Cagua",
	"Jandira",
	"Amatitlán",
	"Gateshead",
	"Amherst",
	"Honchō",
	"Orizaba",
	"Ciudad de Atlixco",
	"Fort Smith",
	"Balneário de Camboriú",
	"Resende",
	"Daitōchō",
	"Arapongas",
	"Polatlı",
	"Porlamar",
	"Fugu",
	"Cádiz",
	"Kenosha",
	"Norman",
	"Gingoog",
	"Zhaxi",
	"Gorzów Wielkopolski",
	"Porac",
	"Dūmā",
	"Wolfsburg",
	"Gexianzhuang",
	"South Lyon",
	"Maastricht",
	"Leeuwarden",
	"Pervouralsk",
	"Algeciras",
	"New Mirpur",
	"Noksan",
	"Boulder",
	"Bimbo",
	"Baia Mare",
	"Savannakhet",
	"Al Ḩajar al Aswad",
	"Khrustalnyi",
	"Kadirli",
	"Brits",
	"Cherkessk",
	"Indramayu",
	"Rudnyy",
	"Bergamo",
	"Magangué",
	"Maicao",
	"Delgado",
	"San Pedro Garza García",
	"Pleven",
	"Derbent",
	"Preston",
	"Formosa",
	"Kisii",
	"Villa de Álvarez",
	"Pati",
	"Longtian",
	"Munūf",
	"Gagnoa",
	"Bocaue",
	"Abilene",
	"Punta Arenas",
	"Varginha",
	"Lehigh Acres",
	"Dąbrowa Górnicza",
	"Munch’ŏn",
	"Khénifra",
	"Hội An",
	"Alberton",
	"Lianhe",
	"Ise",
	"Jirjā",
	"San Fernando",
	"Patnos",
	"Sidi Bouzid",
	"Blackburn",
	"Barretos",
	"Machiques",
	"Ciudad Hidalgo",
	"San Fernando",
	"Jalapa",
	"Pescara",
	"Guelma",
	"Behbahān",
	"Baidyabāti",
	"Pearland",
	"Xiangcheng",
	"Mufulira",
	"Maina",
	"Kotamobagu",
	"Dharmavaram",
	"Edéa",
	"Setúbal",
	"Montreuil",
	"Ciudad Ojeda",
	"Bento Gonçalves",
	"Pedro Juan Caballero",
	"Fier",
	"Orekhovo-Zuyevo",
	"Kamalia",
	"Gyumri",
	"Hòa Bình",
	"Kashiwara",
	"Perpignan",
	"Siracusa",
	"Dorūd",
	"Puerto Barrios",
	"Ribeirão Pires",
	"Port-de-Paix",
	"Ondjiva",
	"Wuling",
	"Samandağ",
	"Tsuruoka",
	"Shuixi",
	"Upington",
	"Walthamstow",
	"Berkeley",
	"Płock",
	"Poblacion",
	"Richardson",
	"Doğubayazıt",
	"Redding",
	"Arvada",
	"East Los Angeles",
	"Valinhos",
	"Itabira",
	"Chilapa de Álvarez",
	"Cachoeirinha",
	"St. George",
	"Kot Addu",
	"Kallithéa",
	"Göttingen",
	"Pakxé",
	"Billings",
	"Carcar",
	"Santa Cruz",
	"Guelmim",
	"Darjeeling",
	"Ebetsu",
	"Yuba City",
	"Moḩammad Shahr",
	"Fujimino",
	"Sertãozinho",
	"Kuopio",
	"Poá",
	"Saint-Denis",
	"Ghardaïa",
	"Almirante Tamandaré",
	"San Carlos",
	"Ciudad Choluteca",
	"Várzea Paulista",
	"Rochester",
	"Catanduva",
	"Zahlé",
	"Guagua",
	"Gudivāda",
	"Naz̧arābād",
	"Leominster",
	"Ajax",
	"Lomas de Zamora",
	"Kingsport",
	"Butwāl",
	"Nouadhibou",
	"Xuqiaocun",
	"Duluth",
	"Dongsheng",
	"Nazran",
	"Elbląg",
	"Sopur",
	"Totonicapán",
	"Paulo Afonso",
	"Santo Tomas",
	"Guaratinguetá",
	"Huaraz",
	"Guimba",
	"Rock Hill",
	"Kasuga",
	"Gabès",
	"Gilroy",
	"Cheltenham",
	"Cambridge",
	"Nevinnomyssk",
	"Bruges",
	"Lahti",
	"Reutov",
	"Bandundu",
	"Igarassu",
	"Trento",
	"Charallave",
	"Yongqing",
	"Nowshera",
	"Dharān",
	"Akishima",
	"Birigui",
	"Bottrop",
	"Berkane",
	"Kōnosu",
	"Sugar Land",
	"Glan",
	"Linköping",
	"Votorantim",
	"Tanay",
	"Karatepe",
	"Forlì",
	"Cuautitlán",
	"Ikoma",
	"Candelaria",
	"Martínez de la Torre",
	"Yulu",
	"Tecomán",
	"Giyon",
	"Texas City",
	"Bama",
	"Bayawan",
	"Codó",
	"Iowa City",
	"Drammen",
	"Saginaw",
	"Kabwe",
	"Malita",
	"Facatativá",
	"Araguari",
	"Nobeoka",
	"Jalingo",
	"Luanshya",
	"Agadez",
	"Uzhhorod",
	"Besançon",
	"Adilābād",
	"Obninsk",
	"Uribia",
	"Piedecuesta",
	"Chico",
	"Angono",
	"Huanren",
	"San Martín",
	"Kapaklı",
	"Basingstoke",
	"Wałbrzych",
	"Maidstone",
	"Xai-Xai",
	"Reutlingen",
	"Langley",
	"Dover",
	"Rosario",
	"Temixco",
	"Kindia",
	"Narasaraopet",
	"Nyíregyháza",
	"Kyzyl",
	"Machakos",
	"Belfort",
	"Nkongsamba",
	"Ubá",
	"Guadalajara de Buga",
	"Cassongue",
	"Mendoza",
	"Clearwater",
	"Tandil",
	"Manpo",
	"Beppu",
	"Buzău",
	"Tatuí",
	"Naga",
	"Dimitrovgrad",
	"Port-Gentil",
	"Sassari",
	"Coeur d'Alene",
	"Bagé",
	"Chittaurgarh",
	"Seaside",
	"Helsingborg",
	"Coronel",
	"Independence",
	"Smithtown",
	"Argenteuil",
	"San Antonio Enchisi",
	"Boké",
	"West Jordan",
	"Phatthaya",
	"Khon Kaen",
	"Ramenskoye",
	"Kilis",
	"Shibuya",
	"Parral",
	"Qiaotou",
	"Swabi",
	"Huejutla de Reyes",
	"Sabhā",
	"Tahoua",
	"Chelmsford",
	"Shacheng",
	"Altamira",
	"Doncaster",
	"Valle de La Pascua",
	"Brandon",
	"Bloomington",
	"Nasushiobara",
	"Toliara",
	"Berdiansk",
	"Kōenchō",
	"El Monte",
	"Piraquara",
	"Niihama",
	"North Charleston",
	"Carlsbad",
	"Bānsbāria",
	"Puerto Madryn",
	"Tucuruí",
	"Sano",
	"Ariana",
	"Sloviansk",
	"Koblenz",
	"Oktyabr’skiy",
	"St. Cloud",
	"Salto",
	"Zhijiang",
	"Hatsukaichi",
	"Chorzów",
	"Las Delicias",
	"Bijeljina",
	"Lilan",
	"Temecula",
	"Bremerhaven",
	"Bet Shemesh",
	"Ciudad de la Costa",
	"Clovis",
	"Bernal",
	"Kamagaya",
	"Vālpārai",
	"Iringa",
	"Magalang",
	"Meridian",
	"Saanich",
	"Āsela",
	"Los Baños",
	"Kankan",
	"Cuito",
	"Kuşadası",
	"Joünié",
	"Colchester",
	"Recklinghausen",
	"Pul-e Khumrī",
	"Tawau",
	"Quilengues",
	"San Francisco del Rincón",
	"Curicó",
	"The Woodlands",
	"Paarl",
	"Hōfu",
	"Hikone",
	"Itatiba",
	"Rosario",
	"Caraguatatuba",
	"Bukittinggi",
	"Wythenshawe",
	"Erlangen",
	"Jendouba",
	"Sa-ch’on",
	"Ponce",
	"Kipushi",
	"Mazhang",
	"Akçakale",
	"Bergisch Gladbach",
	"City of Isabela",
	"Euriápolis",
	"Novyy Urengoy",
	"Montero",
	"Madrid",
	"Burdur",
	"Westminster",
	"Açailandia",
	"Castelar",
	"Sokodé",
	"Jaén",
	"Vicenza",
	"Costa Mesa",
	"Rotherham",
	"Hualien",
	"San Carlos de Bariloche",
	"Naic",
	"Otaru",
	"Alphen aan den Rijn",
	"Monroe",
	"Zwolle",
	"Higashiōmi",
	"Utica",
	"Santa Lucía",
	"Carora",
	"Khenchela",
	"Iriga City",
	"Champdani",
	"Umuarama",
	"Ōshū",
	"Remscheid",
	"Habikino",
	"Kamyshin",
	"Osmānābād",
	"Esteban Echeverría",
	"Dolgoprudnyy",
	"Shkodër",
	"Jena",
	"Mopti",
	"Kars",
	"Santana",
	"Olmaliq",
	"Cavite City",
	"Bou Saâda",
	"Candaba",
	"Tangjia",
	"Nikopol",
	"Ciudad Mante",
	"Pompano Beach",
	"Laoag",
	"Corumbá",
	"Zhukovskiy",
	"West Palm Beach",
	"Kristiansand",
	"Funchal",
	"Colatina",
	"Ligao",
	"Kuqa",
	"Nsukka",
	"Escuintla",
	"Hounslow",
	"Terni",
	"Norzagaray",
	"Waterloo",
	"Parintins",
	"Trier",
	"Terrebonne",
	"Namur",
	"Rochdale",
	"Murom",
	"Bedford",
	"Tondabayashichō",
	"Everett",
	"Pingquan",
	"Manzini",
	"El Centro",
	"Villa Mercedes",
	"Winterthur",
	"Jawhar",
	"Tuzla",
	"Tarnów",
	"Gafsa",
	"Santa Fe",
	"Khardah",
	"Gapan",
	"Nguru",
	"Bandar-e Anzalī",
	"Khushab",
	"Plaridel",
	"Downey",
	"Spring Hill",
	"Stockport",
	"Kecskemét",
	"Catalão",
	"Lowell",
	"Sonsonate",
	"Ahuachapán",
	"Fresnillo",
	"Centennial",
	"Fasā",
	"As Salamīyah",
	"Yessentuki",
	"Elgin",
	"Tabuk",
	"Coronel Fabriciano",
	"Kiffa",
	"Santander de Quilichao",
	"Dali",
	"Calumpit",
	"Yenangyaung",
	"Ourinhos",
	"Quíbor",
	"Iguala de la Independencia",
	"Borāzjān",
	"Richmond",
	"Socopó",
	"Shinyanga",
	"Ash Shaykh ‘Uthmān",
	"Genhe",
	"Montego Bay",
	"Bāneh",
	"Shirayamamachi",
	"Msaken",
	"Mascara",
	"Espejo",
	"Darlington",
	"Broken Arrow",
	"Milton",
	"Yŏju",
	"Xishancun",
	"Bangaon",
	"Miami Gardens",
	"Pisco",
	"Dera Ismail Khan",
	"Taitung",
	"Bend",
	"Burlington",
	"Sandachō",
	"Kandi",
	"Aguachica",
	"Apalit",
	"Baybay",
	"Labé",
	"Ariquemes",
	"Jurupa Valley",
	"Medenine",
	"Muriaé",
	"Taoyang",
	"Gualeguaychú",
	"Kasserine",
	"Béja",
	"Caen",
	"Botoşani",
	"Sandy Springs",
	"Yŏngju",
	"Gresham",
	"Uitenhage",
	"Bukoba",
	"Maxixe",
	"Soubré",
	"Lewisville",
	"Ipiales",
	"Mineshita",
	"Hillsboro",
	"Soma",
	"San Buenaventura",
	"Musoma",
	"Novoshakhtinsk",
	"Libmanan",
	"San Juan",
	"Crawley",
	"Ferizaj",
	"Ikeda",
	"St. John's",
	"Jacksonville",
	"Salford",
	"Wembley",
	"Pottstown",
	"Ādīgrat",
	"Itaúna",
	"Al Fqih Ben Çalah",
	"Moncton",
	"Tādpatri",
	"Huauchinango",
	"Yāsūj",
	"Tipitapa",
	"Alto Hospicio",
	"Jalpāiguri",
	"Dagenham",
	"Mangaldan",
	"Birnin Kebbi",
	"Samaná",
	"Šiauliai",
	"Seversk",
	"El Limón",
	"Reus",
	"Inglewood",
	"Sarh",
	"An Nuhūd",
	"San Rafael",
	"Koszalin",
	"Escuintla",
	"Tagbilaran City",
	"Tajimi",
	"Thunder Bay",
	"Pavlohrad",
	"Yevpatoriia",
	"Douliu",
	"Emmen",
	"Moortebeek",
	"Chaman",
	"Queenstown",
	"Umm Qaşr",
	"Sliven",
	"Suriāpet",
	"Kogon Shahri",
	"Ban Mangkon",
	"League City",
	"Dinalupihan",
	"Kefar Sava",
	"Alchevsk",
	"Chichicastenango",
	"Araxá",
	"Gillingham",
	"Roubaix",
	"Eau Claire",
	"Bolzano",
	"Cambé",
	"Charsadda",
	"Erdenet",
	"Turlock",
	"Temple",
	"La Piedad",
	"Chongshan",
	"Himamaylan",
	"Mansfield",
	"Mmabatho",
	"Ilebo",
	"Taungoo",
	"Daet",
	"Mao",
	"Dieppe",
	"Arzamas",
	"Morón",
	"Longjiang",
	"San Felipe",
	"Songea",
	"Artëm",
	"Erechim",
	"Noyabrsk",
	"Ilopango",
	"Nakhon Si Thammarat",
	"Petržalka",
	"Tubarão",
	"Chābahār",
	"Monastir",
	"Raba",
	"Orsha",
	"Patos",
	"Jincheng",
	"La Banda",
	"Sioux City",
	"Kemalpaşa",
	"Salisbury",
	"Vĩnh Long",
	"Passos",
	"Ballarat",
	"Davie",
	"Aihua",
	"Achinsk",
	"Daly City",
	"Dunedin",
	"Contramaestre",
	"Subotica",
	"Girona",
	"Nusaybin",
	"Lo Barnechea",
	"Paço do Lumiar",
	"Trincomalee",
	"Mang La",
	"Nova Serrana",
	"Akhmīm",
	"Azare",
	"Brovary",
	"Itumbiara",
	"Yelets",
	"Pushkino",
	"Tikrīt",
	"Robāţ Karīm",
	"Malacatán",
	"Koutiala",
	"Lerma",
	"Ciudad Río Bravo",
	"Highlands Ranch",
	"Allen",
	"Tarīm",
	"Kroonstad",
	"Mazyr",
	"Kandhkot",
	"Toride",
	"Granada",
	"Ituiutaba",
	"Kongolo",
	"Moers",
	"Liberec",
	"Yozgat",
	"Novara",
	"Subic",
	"Al Fāw",
	"West Covina",
	"Salzgitter",
	"Berdsk",
	"Sparks",
	"Waterloo",
	"Lavras",
	"Galle",
	"San Vicente de Baracaldo",
	"Lingayen",
	"Tādepallegūdem",
	"Zomba",
	"Dobrich",
	"Sergiyev Posad",
	"Konotop",
	"Wichita Falls",
	"Courbevoic",
	"Zihuatanejo",
	"Fugangcun",
	"Trindade",
	"San Ildefonso",
	"Bouskoura",
	"Inzai",
	"Khāk-e ‘Alī",
	"Maumere",
	"Samal",
	"San Mateo",
	"Villa Luzuriaga",
	"Eastbourne",
	"Worcester",
	"Caseros",
	"Fyfield",
	"Quezon",
	"Ginowan",
	"Tula de Allende",
	"Catbalogan",
	"Abengourou",
	"Lower Hutt",
	"Delft",
	"Kamianets-Podilskyi",
	"Kalyani",
	"Kilinochchi",
	"Columbia",
	"Saijō",
	"Piacenza",
	"EdDamer",
	"Norwalk",
	"Santa Rosa",
	"Isehara",
	"Dolisie",
	"Itaperuna",
	"Negapatam",
	"Pateros",
	"Wigan",
	"Tourcoing",
	"Maluñgun",
	"Siegen",
	"Dongguazhen",
	"Biak",
	"Elista",
	"Yishi",
	"Libertad",
	"Mazatenango",
	"Rialto",
	"Breves",
	"Lida",
	"Thika",
	"Manteca",
	"Bumba",
	"Guiguinto",
	"Bayugan",
	"Burzaco",
	"Sakado",
	"São Lourenço da Mata",
	"Talara",
	"Messaad",
	"Novokuybyshevsk",
	"Yilong",
	"Bergama",
	"Naujan",
	"Miryang",
	"Nyeri",
	"Mengdingjie",
	"Satu Mare",
	"Houzhuang",
	"Hildesheim",
	"Nanterre",
	"Sangju",
	"Kırklareli",
	"Hove",
	"Arden-Arcade",
	"Bhadreswar",
	"Noginsk",
	"Níkaia",
	"Hengnan",
	"Saint Helens",
	"Talisay",
	"El Cajon",
	"Bethal",
	"Mumias",
	"Burbank",
	"Longmont",
	"Lakewood",
	"Mayarí",
	"Mositai",
	"Yunfu",
	"Cawayan",
	"Dhangaḍhi̇̄",
	"Kāshmar",
	"Maramag",
	"San Felipe del Progreso",
	"Klagenfurt",
	"Delta",
	"Vilhena",
	"Chilakalūrupet",
	"Coatepec",
	"Şabrātah",
	"Chishtian",
	"Boryeong",
	"Shūshtar",
	"Jingping",
	"Santo Antônio de Jesus",
	"Teluk Intan",
	"Hemel Hempstead",
	"Assis",
	"Berisso",
	"Tizayuca",
	"Renton",
	"Ancona",
	"Vitry-sur-Seine",
	"Chatham",
	"‘Ibrī",
	"Vista",
	"Zheleznogorsk",
	"Logan",
	"Sungai Penuh",
	"Prescott Valley",
	"Trinidad",
	"Olomouc",
	"Vacaville",
	"San Luis de la Paz",
	"Aïn Oussera",
	"Itaituba",
	"Wakefield",
	"Liancheng",
	"Kousséri",
	"Oued Zem",
	"Edinburg",
	"Weifen",
	"Gütersloh",
	"Carmel",
	"Spokane Valley",
	"Oum el Bouaghi",
	"Watford",
	"San Angelo",
	"Jilotepec",
	"Salihorsk",
	"Barnsley",
	"Cottbus",
	"Bongao",
	"Kpalimé",
	"Kaiserslautern",
	"Carmona",
	"Gangtok",
	"La Crosse",
	"Ahar",
	"Kōnan",
	"Diourbel",
	"Bulan",
	"Masjed Soleymān",
	"Mairiporã",
	"Torbat-e Jām",
	"Manolo Fortich",
	"Edison",
	"Idaho Falls",
	"Morales",
	"Holland",
	"Red Deer",
	"Charlottesville",
	"Shahrisabz",
	"Longview",
	"Lincoln",
	"Navapolatsk",
	"Woodbridge",
	"Bacabal",
	"Tracy",
	"Kamloops",
	"Nanxicun",
	"Râmnicu Vâlcea",
	"Pulilan",
	"Kettering",
	"Viseu",
	"Bayeux",
	"Kohīma",
	"Mingəçevir",
	"Janakpur",
	"Chipata",
	"Ciudad General Belgrano",
	"Khanty-Mansiysk",
	"Créteil",
	"Maribor",
	"Yashio",
	"Bismarck",
	"Herẕliyya",
	"Yên Bái",
	"San Fernando",
	"Shumen",
	"Orem",
	"Half Way Tree",
	"Vị Thanh",
	"Tumbes",
	"Malindi",
	"Székesfehérvár",
	"San Joaquín",
	"Xırdalan",
	"Tenancingo",
	"Ponta Porã",
	"Wangqing",
	"Sibolga",
	"Aubervilliers",
	"Balanga",
	"Bunia",
	"Arauca",
	"La Reina",
	"Sidi Slimane",
	"Pejë",
	"Yishui",
	"Fulham",
	"Schwerin",
	"La Marsa",
	"San Francisco Solano",
	"Ziftá",
	"Colombes",
	"Çankırı",
	"East Ham",
	"Yotsukaidō",
	"České Budějovice",
	"Masbate",
	"Kakamega",
	"Río Gallegos",
	"Tataouine",
	"Fenggang",
	"Ludwigsburg",
	"Compton",
	"Errachidia",
	"Esslingen",
	"Nisshin",
	"Al Bāḩah",
	"Ōbu",
	"Sunrise",
	"Hartlepool",
	"Xiancun",
	"Naxçıvan",
	"Gjakovë",
	"Mtwara",
	"Ealing",
	"Birkenhead",
	"Guercif",
	"Quincy",
	"Ben Arous",
	"Lynn",
	"Catarman",
	"Monte Chingolo",
	"Drobeta-Turnu Severin",
	"Tultepec",
	"Haskovo",
	"Suceava",
	"Al Fujayrah",
	"Tartu",
	"Ciudad Lázaro Cárdenas",
	"Hradec Králové",
	"Şırnak",
	"South Gate",
	"Tonacatepeque",
	"Miaoli",
	"Makeni",
	"Nuneaton",
	"Itanhaém",
	"Brixton",
	"Kirkland",
	"Ústí nad Labem",
	"Levallois-Perret",
	"Brusque",
	"Santiago de Compostela",
	"Pardubice",
	"Magadan",
	"Tulcea",
	"Bender",
	"Ben Guerir",
	"Panevėžys",
	"Jaffna",
	"Bromley",
	"Queluz",
	"Bath",
	"Kargilik",
	"Pandi",
	"Oulad Teïma",
	"Edmonton",
	"Lqoliaa",
	"Hayes",
	"Lobnya",
	"Francistown",
	"Aulnay-sous-Bois",
	"Jönköping",
	"Isulan",
	"Fuengirola",
	"Umeå",
	"Glyfáda",
	"Kitanagoya",
	"Cadereyta Jiménez",
	"Babahoyo",
	"Westminster",
	"Remedios de Escalada",
	"Vanadzor",
	"Germantown",
	"Higashiyamato",
	"Catanzaro",
	"Ţarţūs",
	"Valjevo",
	"Santa Monica",
	"Poitiers",
	"Acayucan",
	"Nenjiang",
	"Gjilan",
	"Jalal-Abad",
	"Prešov",
	"Balkanabat",
	"Mechelen",
	"Mukacheve",
	"As Salţ",
	"Tangxing",
	"Vólos",
	"Versailles",
	"Viana do Castelo",
	"Woolwich",
	"Miami Beach",
	"San Leandro",
	"Sesto San Giovanni",
	"Ciudad de Ceuta",
	"Bracknell",
	"Podujevë",
	"Apizaco",
	"San Baudilio de Llobregat",
	"Coronel Oviedo",
	"Tala",
	"Carrières-sur-Seine",
	"Fuentes del Valle",
	"Ébolowa",
	"Torre del Greco",
	"San Juan de los Morros",
	"Citrus Heights",
	"State College",
	"Koudougou",
	"Dārayyā",
	"Lokossa",
	"Salina Cruz",
	"Daxincun",
	"Concepción",
	"Jalpa de Méndez",
	"Kawit",
	"Mardin",
	"Xishancun",
	"Mingxing",
	"Nakhon Sawan",
	"Nacajuca",
	"Cárdenas",
	"Loznica",
	"Edgware",
	"Tucupita",
	"Nabatîyé",
	"Ezpeleta",
	"Owariasahi",
	"Toledo",
	"Piatra Neamţ",
	"Busto Arsizio",
	"Ath Thawrah",
	"Hawthorne",
	"Avaré",
	"Maasin",
	"Kouvola",
	"Kuznetsk",
	"El Bayadh",
	"Ciudad Lerdo",
	"Issy-les-Moulineaux",
	"Como",
	"Pori",
	"Scunthorpe",
	"Yambol",
	"Louga",
	"Manfalūţ",
	"Whittier",
	"Clifton",
	"Puerto Maldonado",
	"Heṭauḍā",
	"Tacámbaro de Codallos",
	"Chaguanas",
	"Cunduacán",
	"Pazardzhik",
	"Uman",
	"Nazareth",
	"Ivanteyevka",
	"Nagaoka",
	"Osijek",
	"Cozumel",
	"Cukai",
	"Mbarara",
	"Lucerne",
	"Mitrovicë",
	"Cabo San Lucas",
	"Champigny-sur-Marne",
	"Rivera",
	"Kunitachi",
	"Ouidah",
	"Newmarket",
	"Būmahen",
	"Milpitas",
	"Pernik",
	"Clichy",
	"Phuket",
	"Ho",
	"West Bromwich",
	"Le Bardo",
	"Alhambra",
	"Žilina",
	"Esteio",
	"Reşiţa",
	"Chatham",
	"Rueil-Malmaison",
	"Casoria",
	"Antsiranana",
	"Purmerend",
	"Dudley",
	"Ban Talat Rangsit",
	"Lodwar",
	"Târgu Jiu",
	"Chaniá",
	"Zacatlán",
	"Upper Darby",
	"Prosperidad",
	"Abomey",
	"Mountain View",
	"Blagoevgrad",
	"Nabunturan",
	"Elbasan",
	"Al Muḩarraq",
	"White Rock",
	"Néa Ionía",
	"Campo Formoso",
	"Kayes",
	"Kalibo",
	"São Mateus",
	"South Shields",
	"Paisley",
	"Weston-super-Mare",
	"Goiana",
	"Bilecik",
	"Juchitán de Zaragoza",
	"Buena Park",
	"Silver Spring",
	"Palmerston North",
	"Daugavpils",
	"Cinisello Balsamo",
	"Huolu",
	"Saint-Maur-des-Fossés",
	"Hakkari",
	"Somerville",
	"Chalándri",
	"Bury",
	"Três Lagoas",
	"Gravesend",
	"Deerfield Beach",
	"Târgovişte",
	"Rubí",
	"Galway",
	"Natitingou",
	"Fnidq",
	"Paysandú",
	"Cicero",
	"Atakpamé",
	"Focşani",
	"Avilés",
	"Drancy",
	"Iguatu",
	"Alabel",
	"Le Kram",
	"Zuwārah",
	"Shancheng",
	"Sidi Qacem",
	"Chiquimula",
	"Puerto Ayacucho",
	"Lawrence",
	"Ouahigouya",
	"Mahdia",
	"Nakhon Pathom",
	"Lowestoft",
	"Pau",
	"Tejupilco",
	"Cheyenne",
	"Ubon Ratchathani",
	"Tustin",
	"Lakewood",
	"Carlisle",
	"Lisala",
	"Szombathely",
	"Banská Bystrica",
	"Pine Hills",
	"Ra‘ananna",
	"Harrogate",
	"Aveiro",
	"Tambacounda",
	"Newcastle under Lyme",
	"New Rochelle",
	"Kensington",
	"La Rochelle",
	"Areguá",
	"Lebanon",
	"East Kilbride",
	"Wa",
	"Nitra",
	"Lomas del Mirador",
	"Lelystad",
	"Maghāghah",
	"Mpanda",
	"Gladbeck",
	"Sankt Gallen",
	"Cannes",
	"Alameda",
	"Thornton Heath",
	"Watsonville",
	"Tāluqān",
	"Jinja",
	"Davis",
	"Berbérati",
	"Esbjerg",
	"Zrenjanin",
	"San Felipe",
	"Las Piedras",
	"Surt",
	"Tamazunchale",
	"Moulay Abdallah",
	"Pančevo",
	"Abéché",
	"Balagtas",
	"Palaió Fáliro",
	"Bellflower",
	"Jacona de Plancarte",
	"Ashford",
	"Bokhtar",
	"Chiryū",
	"Nek’emtē",
	"Calais",
	"Tamanrasset",
	"Ramla",
	"Montrouge",
	"Zadar",
	"Southall",
	"Guamúchil",
	"Musashimurayama",
	"Quảng Trị",
	"Antibes",
	"Mineiros",
	"Bistriţa",
	"Tissemsilt",
	"Joensuu",
	"Bella Vista",
	"Rugby",
	"Darhan",
	"Guasave",
	"Teyateyaneng",
	"Burton upon Trent",
	"Gouda",
	"Karlstad",
	"Uxbridge",
	"Zacatecoluca",
	"Baldwin Park",
	"Zlín",
	"Spijkenisse",
	"Rioverde",
	"Prey Veng",
	"Ipil",
	"Inowrocław",
	"Jinsha",
	"Crewe",
	"Matehuala",
	"Bitola",
	"San Andrés",
	"Garissa",
	"Longkoucun",
	"Manokwari",
	"Hekinan",
	"Masvingo",
	"Vincennes",
	"Yoshikawa",
	"Assab",
	"Gosport",
	"Wandsworth",
	"Cuscatancingo",
	"Salima",
	"Nabeul",
	"Virac",
	"Centreville",
	"Birobidzhan",
	"Tsurugashima",
	"Cabadbaran",
	"Kirtipur",
	"Irecê",
	"El Kef",
	"Frontera",
	"Neuilly-sur-Seine",
	"Farāh",
	"Warzat",
	"Finchley",
	"Camden",
	"Galátsi",
	"Evanston",
	"Noisy-le-Grand",
	"Skien",
	"Caguas",
	"Yawata-shimizui",
	"Debre Mark’os",
	"Dabou",
	"Şa‘dah",
	"Feltham",
	"San Antonio",
	"Lappeenranta",
	"Thohoyandou",
	"Castelldefels",
	"Torremolinos",
	"Chingford",
	"New Britain",
	"Ārba Minch’",
	"Abancay",
	"Ivry-sur-Seine",
	"Toyoake",
	"Havířov",
	"Veliko Tarnovo",
	"Inverness",
	"Wilde",
	"Pawtucket",
	"Čačak",
	"Karakax",
	"La Chorrera",
	"Washington",
	"Lauderhill",
	"Balsas",
	"Matara",
	"Benalmádena",
	"Al Qūşīyah",
	"Zaandam",
	"Szolnok",
	"Mamou",
	"Slatina",
	"Youssoufia",
	"Bitlis",
	"Ajaccio",
	"Maidenhead",
	"Kasese",
	"Phitsanulok",
	"Hatogaya-honchō",
	"Misantla",
	"Zinjibār",
	"Courcouronnes",
	"New Westminster",
	"Kumanovo",
	"Givatayim",
	"Valle Hermoso",
	"Jiquílpan de Juárez",
	"Cergy",
	"Capelle aan den IJssel",
	"Mindelo",
	"Chiang Rai",
	"Aïn Harrouda",
	"Āksum",
	"Wimbledon",
	"Sunyani",
	"Viladecáns",
	"Loughborough",
	"Abnūb",
	"Colonia del Sol",
	"Vénissieux",
	"Sagaing",
	"Wilmington",
	"Gardēz",
	"Thakhèk",
	"Nizwá",
	"Tynemouth",
	"Vushtrri",
	"Walsall",
	"Moquegua",
	"Itapeva",
	"Lynwood",
	"Jinxing",
	"Rochester",
	"Georgiyevsk",
	"Passaic",
	"Valença",
	"Castellammare di Stabia",
	"Huatusco",
	"Vratsa",
	"L’Aquila",
	"Debre Birhan",
	"Borongan",
	"Putrajaya",
	"Liepāja",
	"Acton",
	"Pantin",
	"Antony",
	"Bouira",
	"Veenendaal",
	"Mitcham",
	"San José del Guaviare",
	"Ponta Delgada",
	"Gävle",
	"Wrecsam",
	"Villa Domínico",
	"Kitamoto",
	"Afragola",
	"Şəki",
	"Zamość",
	"Adrar",
	"Waterlooville",
	"Tomigusuku",
	"Buynaksk",
	"Hämeenlinna",
	"Guaynabo",
	"Gaithersburg",
	"Union City",
	"Výronas",
	"Scarborough",
	"Caçador",
	"South San Francisco",
	"Timbaúba",
	"Curvelo",
	"Manacapuru",
	"Cabedelo",
	"Tecate",
	"Lào Cai",
	"Farnborough",
	"Vaasa",
	"Molepolole",
	"Potenza",
	"San Marcos",
	"Dimbokro",
	"Arrecife",
	"El Prat de Llobregat",
	"Katwijk",
	"Valle de Bravo",
	"Mount Vernon",
	"Amozoc",
	"Twickenham",
	"Stourbridge",
	"Fryazino",
	"Linares",
	"Streatham",
	"Barking",
	"Épinay-sur-Seine",
	"Bolgatanga",
	"Sabinas",
	"Lechang",
	"Teoloyucan",
	"Sokhumi",
	"Collado-Villalba",
	"La Seyne-sur-Mer",
	"Agía Paraskeví",
	"Redondo Beach",
	"Kraljevo",
	"Tamarac",
	"Novi Pazar",
	"Bayburt",
	"M’diq",
	"Nālūt",
	"Granollers",
	"Zacapa",
	"Tatabánya",
	"Acámbaro",
	"Troyes",
	"Prilep",
	"Trnava",
	"Los Polvorines",
	"Kardzhali",
	"Goz-Beida",
	"San Marcos",
	"Maisons-Alfort",
	"La Línea de la Concepción",
	"Masaka",
	"Singida",
	"Dewsbury",
	"Ioánnina",
	"Florence-Graham",
	"Călăraşi",
	"Forest",
	"Sarcelles",
	"Nieuwegein",
	"Altamira",
	"Widnes",
	"Schenectady",
	"Cortazar",
	"Rundu",
	"Portici",
	"Tago",
	"Songkhla",
	"Solwezi",
	"Villejuif",
	"Kitale",
	"As Suwaydā’",
	"Ouezzane",
	"Ełk",
	"Euclides da Cunha",
	"Bayonne",
	"Faro",
	"Faīẕābād",
	"Sinop",
	"Sololá",
	"Yoro",
	"Pruszków",
	"Cosamaloapan",
	"East Orange",
	"Etterbeek",
	"Nagari",
	"Inhambane",
	"Taunton",
	"Bangor",
	"Alba Iulia",
	"Pirapora",
	"Aylesbury",
	"Sombrerete",
	"Latacunga",
	"Manouba",
	"Le Blanc-Mesnil",
	"Gorno-Altaysk",
	"Bragança",
	"San Jose",
	"Hasuda",
	"São Borja",
	"Halesowen",
	"Marano di Napoli",
	"Legnano",
	"Nkhotakota",
	"Mukōchō",
	"Kyustendil",
	"Brentwood",
	"Radès",
	"Karakol",
	"Tsushima",
	"Canela",
	"Cherbourg",
	"Ruislip",
	"Bondy",
	"Anuradhapura",
	"Gabrovo",
	"Poblacion",
	"Kratie",
	"Assen",
	"Beledweyne",
	"Royal Tunbridge Wells",
	"Jette",
	"Saint-Ouen",
	"Khartsyzk",
	"Wallasey",
	"Barrow in Furness",
	"Stryi",
	"Sopron",
	"North Miami",
	"Maldonado",
	"Skokie",
	"Leskovac",
	"Santa Rosa",
	"Saraburi",
	"Rayong",
	"Ban Bang Kaeo",
	"Kolda",
	"Santarém",
	"Fontenay-sous-Bois",
	"Suresnes",
	"Fussa",
	"Napier",
	"Chornomorsk",
	"Puerto Limón",
	"Madang",
	"Lytkarino",
	"Rafael Calzada",
	"Pico Rivera",
	"Kaposvár",
	"Bayombong",
	"Montebello",
	"Bāmyān",
	"Ban Rangsit",
	"Portugalete",
	"Rovaniemi",
	"Yala",
	"Giurgiu",
	"Chambéry",
	"Samut Sakhon",
	"Bobigny",
	"Janaúba",
	"North Bergen",
	"Seinäjoki",
	"The Hammocks",
	"Ruhengeri",
	"Suileng",
	"National City",
	"Benslimane",
	"Koja",
	"Coconut Creek",
	"Bến Tre",
	"Coyhaique",
	"Gaalkacyo",
	"Hunedoara",
	"Limerick",
	"Longxing",
	"Trang",
	"Teresa",
	"Melo",
	"Oyem",
	"Lokoja",
	"Juigalpa",
	"Fountainebleau",
	"Växjö",
	"La Habra",
	"Malden",
	"Banfora",
	"San Pedro",
	"Lorient",
	"Świdnica",
	"Sidi Bennour",
	"South Whittier",
	"Zushi",
	"Temascalcingo",
	"Hereford",
	"Mbanza Kongo",
	"Veszprém",
	"Ellesmere Port",
	"Serrinha",
	"Caridad",
	"Évry",
	"Tezonapa",
	"Mislata",
	"Etchojoa",
	"West Allis",
	"Užice",
	"Taylorsville",
	"Itānagar",
	"Romita",
	"Antigua Guatemala",
	"Suharekë",
	"Klimovsk",
	"Bungoma",
	"San José Iturbide",
	"Monterey Park",
	"Mérida",
	"Hod HaSharon",
	"Puteaux",
	"Ródos",
	"Formiga",
	"Al Hoceïma",
	"Actopan",
	"Samannūd",
	"Hamura",
	"Midalt",
	"Gardena",
	"Békéscsaba",
	"Azrou",
	"Cupertino",
	"La Mesa",
	"Dzerzhinskiy",
	"Artemisa",
	"San Buenaventura",
	"Brookline",
	"Kitgum",
	"Yevlax",
	"Viedma",
	"Cerro de Pasco",
	"Kabinda",
	"Braintree",
	"Tacuarembó",
	"Clamart",
	"Liuhu",
	"Kruševac",
	"Margate",
	"Qiryat Ata",
	"Aversa",
	"Carson City",
	"Meaux",
	"Mandapeta",
	"Évora",
	"Bârlad",
	"Port Coquitlam",
	"Rosh Ha‘Ayin",
	"Middletown",
	"Bondoukou",
	"San Fernando",
	"Chelles",
	"Novohrad-Volynskyi",
	"Union",
	"Huamantla",
	"Sabaneta",
	"Sartrouville",
	"Vidin",
	"White Plains",
	"Sevran",
	"Huehuetenango",
	"Jefferson City",
	"Zalaegerszeg",
	"Hendon",
	"Arcadia",
	"Hilden",
	"Slavonski Brod",
	"Legionowo",
	"Magong",
	"Umm el Faḥm",
	"Alfortville",
	"Samut Prakan",
	"Nuevo Casas Grandes",
	"Pavia",
	"Kidderminster",
	"Lipjan",
	"Zumpango",
	"Crosby",
	"Al Mafraq",
	"Hamilton",
	"Altrincham",
	"Huntington Park",
	"Mafeteng",
	"Esplugas de Llobregat",
	"Medford",
	"Chinhoyi",
	"Gümüşhane",
	"Pithāpuram",
	"Belize City",
	"Acatzingo",
	"Maun",
	"Barri",
	"Biel/Bienne",
	"Bắc Giang",
	"Ushuaia",
	"Dunfermline",
	"Royal Leamington Spa",
	"Zalău",
	"Sfântu-Gheorghe",
	"Muğla",
	"Llanelli",
	"Tandag",
	"Phra Nakhon Si Ayutthaya",
	"Montesilvano",
	"Kranj",
	"Salamá",
	"Zouerate",
	"Mollet",
	"Trenčín",
	"Rahovec",
	"Jelgava",
	"Tamiami",
	"Vaslui",
	"Saint-Quentin",
	"Massy",
	"Kendale Lakes",
	"Castelo Branco",
	"Rio Tinto",
	"San Giorgio a Cremano",
	"Vigan",
	"Weymouth",
	"Santa Rosa de Copán",
	"Gallarate",
	"Halmstad",
	"Corbeil-Essonnes",
	"Ohrid",
	"Greenford",
	"Ponte Nova",
	"Matamoros",
	"Guliston",
	"Rowley Regis",
	"Bootle",
	"Ibiza",
	"Francisco I. Madero",
	"New Brunswick",
	"Casalnuovo di Napoli",
	"Lautoka",
	"Senhor do Bonfim",
	"Ercolano",
	"M.Ə. Rəsulzadə",
	"Fountain Valley",
	"Whangarei",
	"Villagrán",
	"Paignton",
	"Lampang",
	"Lancaster",
	"Vranje",
	"Abū Qīr",
	"Naval",
	"Ithaca",
	"Chiapa de Corzo",
	"Madīnat Ḩamad",
	"Oak Lawn",
	"Boac",
	"Padangpanjang",
	"Mikkeli",
	"Malishevë",
	"Gutao",
	"Busia",
	"Morden",
	"Catford",
	"Folkestone",
	"Berwyn",
	"Chārīkār",
	"Aloha",
	"Shiraoka",
	"Prachuap Khiri Khan",
	"Irvington",
	"Korçë",
	"Rosemead",
	"Tulcán",
	"Rosny-sous-Bois",
	"Muban Saeng Bua Thong",
	"Paramount",
	"Louangphabang",
	"Stratford",
	"Choisy-le-Roi",
	"Iganga",
	"Cagnes-sur-Mer",
	"Sakon Nakhon",
	"Sutton in Ashfield",
	"Pursat",
	"Bayonne",
	"Macclesfield",
	"Eger",
	"Nes Ẕiyyona",
	"San Vicente",
	"Cologno Monzese",
	"Santiago Teyahualco",
	"Marondera",
	"Wellingborough",
	"Scafati",
	"Moskovskiy",
	"Rho",
	"Gao",
	"Maha Sarakham",
	"Revere",
	"Barra do Corda",
	"Aspen Hill",
	"Kirkcaldy",
	"Helena",
	"Takeo",
	"Hrazdan",
	"Barnet",
	"Vejle",
	"Noisy-le-Sec",
	"Santurce-Antiguo",
	"Cumbernauld",
	"Valladolid",
	"West New York",
	"Mangochi",
	"Hoboken",
	"Mongu",
	"Myrnohrad",
	"Iwakura",
	"Takahama",
	"Boujad",
	"Oak Park",
	"Batley",
	"Jinotepe",
	"Collegno",
	"Ain El Aouda",
	"Elmshorn",
	"Floriano",
	"Bang Bua Thong",
	"Suzukawa",
	"Lamía",
	"Chake Chake",
	"Vaulx-en-Velin",
	"Lənkəran",
	"Vila Real",
	"Ocosingo",
	"Itamaraju",
	"Pen-y-Bont ar Ogwr",
	"Ramos Arizpe",
	"Teplice",
	"Ratnapura",
	"Jihlava",
	"Sombor",
	"Levittown",
	"Bodø",
	"Vryburg",
	"Eltham",
	"Surbiton",
	"Perth Amboy",
	"Ashton",
	"Qasbat Tadla",
	"Tizimín",
	"Séguéla",
	"Placentia",
	"Guozhen",
	"Stretford",
	"Evere",
	"Sar-e Pul",
	"Komotiní",
	"Camocim",
	"Cuetzalan",
	"Loreto",
	"Larnaca",
	"Capanema",
	"Gennevilliers",
	"Bodupāl",
	"Longchamps",
	"Alytus",
	"Sisophon",
	"Aliso Viejo",
	"Skenderaj",
	"Jarash",
	"Ḩarastā",
	"Puttalam",
	"Iba",
	"Welwyn Garden City",
	"Jūrmala",
	"Bagneux",
	"Gbadolite",
	"Garges-lès-Gonesse",
	"Livry-Gargan",
	"West Bridgford",
	"Trujillo",
	"Azemmour",
	"Lissone",
	"Country Club",
	"Goianésia",
	"Yeovil",
	"Beckenham",
	"Gracias",
	"Ma‘ān",
	"Cojutepeque",
	"Selibe Phikwe",
	"Villa Alsina",
	"Plainfield",
	"Invercargill",
	"Wheaton",
	"Campobasso",
	"Tlalmanalco",
	"Moyobamba",
	"Al Khārjah",
	"Kotelniki",
	"Nichelino",
	"Al Balyanā",
	"Paderno Dugnano",
	"Meudon",
	"Zaranj",
	"La Garenne-Colombes",
	"Odienné",
	"Cheshunt",
	"Nausori",
	"North Bethesda",
	"Hāgere Hiywet",
	"Cerritos",
	"Dosso",
	"Kangar",
	"Al Aaroui",
	"Figueras",
	"Lakewood",
	"Montana",
	"Huancavelica",
	"Perth",
	"Brumado",
	"Dollard-des-Ormeaux",
	"El‘ad",
	"Couva",
	"Luleå",
	"Waterford",
	"Bagnolet",
	"Ramat HaSharon",
	"Nelson",
	"Wajir",
	"San Felíu de Llobregat",
	"Mairena del Aljarafe",
	"Bloomfield",
	"San Isidro",
	"Corroios",
	"Karlovy Vary",
	"José Mármol",
	"Ayr",
	"Banbury",
	"Wokingham",
	"Moroleón",
	"Mantes-la-Jolie",
	"Cypress",
	"Hollister",
	"La Courneuve",
	"Inhumas",
	"Rovenky",
	"Middelburg",
	"Asadābād",
	"Tlapa de Comonfort",
	"Châtillon",
	"Birendranagar",
	"Krasnoznamensk",
	"Ceres",
	"Nueva Loja",
	"Puerto Escondido",
	"Noveleta",
	"Bangued",
	"Seregno",
	"Serowe",
	"Mandeville",
	"Nong Khai",
	"Salekhard",
	"Mercedes",
	"University",
	"Antelope",
	"Leiria",
	"Parras de la Fuente",
	"Emiliano Zapata",
	"Bindura",
	"Gori",
	"Bastia",
	"Isiolo",
	"La Mirada",
	"Inuma",
	"Havant",
	"Chalon-sur-Saône",
	"Torre Annunziata",
	"Leribe",
	"Nocera Inferiore",
	"Kanash",
	"Hinckley",
	"Njombe",
	"Melito di Napoli",
	"Erith",
	"Talence",
	"New Amsterdam",
	"Kerkrade",
	"North Highlands",
	"Karmiel",
	"Morley",
	"Ḩajjah",
	"Empalme",
	"Kokkola",
	"Florin",
	"Covina",
	"Bang Kruai",
	"Tubod",
	"Murzuq",
	"Ripollet",
	"San Adrián de Besós",
	"Caluire-et-Cuire",
	"Rosso",
	"Villa de Zaachila",
	"Naj‘ Ḩammādī",
	"Charenton-le-Pont",
	"Hammam-Lif",
	"Chitré",
	"Salgueiro",
	"Badulla",
	"Nagykanizsa",
	"Malakoff",
	"Welling",
	"Viti",
	"Boosaaso",
	"Izúcar de Matamoros",
	"Qiryat Ono",
	"Castleford",
	"Santiago",
	"Fareham",
	"Le Cannet",
	"Kanye",
	"Embu",
	"Choma",
	"Aweil",
	"Östersund",
	"Merthyr Tudful",
	"Trollhättan",
	"Rye",
	"Valenciennes",
	"Everett",
	"Šibenik",
	"Coatbridge",
	"Saint-Brieuc",
	"Cobija",
	"Yuchengcun",
	"Urmston",
	"Farim",
	"Daijiazhuang",
	"Slobozia",
	"Mafamude",
	"Mrirt",
	"Vilvoorde",
	"Kingswood",
	"Jerada",
	"Hammam Sousse",
	"Rozzano",
	"Gbarnga",
	"Stains",
	"Alexandria",
	"Madīnat ‘Īsá",
	"Igualada",
	"Thun",
	"Vanves",
	"Kingston upon Thames",
	"Buta",
	"Bron",
	"Targovishte",
	"Nueva Gerona",
	"Tindouf",
	"Jáltipan de Morelos",
	"Bluefields",
	"Strumica",
	"Liberia",
	"Bellinzona",
	"Pattani",
	"Artigas",
	"Arlington",
	"Pinneberg",
	"Newbury",
	"Coyotepec",
	"Oakland Park",
	"Donggangli",
	"Gagny",
	"Alcantarilla",
	"Dunaújváros",
	"Sutton",
	"Melun",
	"Bridgwater",
	"Kabale",
	"Desio",
	"Świdnik",
	"Altadena",
	"Qiryat Bialik",
	"Bishops Stortford",
	"Bois-Colombes",
	"Nogent-sur-Marne",
	"Lecherías",
	"Arras",
	"Umm al Qaywayn",
	"Rezé",
	"Duncan",
	"El Aïoun",
	"Chimaltenango",
	"Kirkby",
	"Salisbury",
	"Katsuren-haebaru",
	"Tarbes",
	"Boumerdes",
	"North Lauderdale",
	"Leidschendam",
	"Caerphilly",
	"Hackensack",
	"Caloundra",
	"Wattrelos",
	"Kampong Chhnang",
	"Cleveland Heights",
	"Fort Portal",
	"Ermezinde",
	"Veles",
	"Or Yehuda",
	"Haedo",
	"Paracho de Verduzco",
	"Štip",
	"Drogheda",
	"Trujillo Alto",
	"Concord",
	"Kalmar",
	"Playa Vicente",
	"Munro",
	"Matale",
	"Uman",
	"Grays",
	"Abingdon",
	"Nanuque",
	"San Diego de la Unión",
	"Hódmezővásárhely",
	"Ramsgate",
	"Annandale",
	"Rohnert Park",
	"Zugdidi",
	"Mityana",
	"Cacém",
	"Le Kremlin-Bicêtre",
	"Salem",
	"Ilkeston",
	"Kapan",
	"Pomigliano d’Arco",
	"Beverwijk",
	"Aldershot",
	"Cleethorpes",
	"Minas",
	"Mamburao",
	"Leighton Buzzard",
	"Alajuela",
	"North Miami Beach",
	"Franceville",
	"Garbahaarrey",
	"Perote",
	"Annemasse",
	"Saronno",
	"Whitney",
	"Freeport",
	"Juan Rodríguez Clara",
	"Dubrovnik",
	"Blyth",
	"San Bruno",
	"Quinhámel",
	"Franconville",
	"Hicksville",
	"Silistra",
	"West Babylon",
	"Saint-Martin-d’Hères",
	"Guarda",
	"Si Sa Ket",
	"Aldridge",
	"Kampot",
	"Fribourg",
	"Long Eaton",
	"La Barca",
	"Le Perreux-Sur-Marne",
	"Arnold",
	"Lovech",
	"Miercurea-Ciuc",
	"Cachan",
	"Berat",
	"Small Heath",
	"Myrhorod",
	"Ardahan",
	"Villafranca del Panadés",
	"Dock Sur",
	"Bambari",
	"Blanes",
	"Herne Bay",
	"Aziylal",
	"Tonalá",
	"Nakama",
	"Cao Bằng",
	"San José",
	"Aberdare",
	"Douai",
	"Damān",
	"Bell Gardens",
	"Cesano Maderno",
	"Razgrad",
	"Placilla de Peñuelas",
	"Kahama",
	"Amudālavalasa",
	"Lindi",
	"Savigny-sur-Orge",
	"Campbell",
	"Port Talbot",
	"Puntarenas",
	"Woonsocket",
	"Biougra",
	"Xico",
	"Narathiwat",
	"Mugnano di Napoli",
	"Sidi Yahia El Gharb",
	"Nueva Rosita",
	"Échirolles",
	"Ciampino",
	"La Paz",
	"Villa Adelina",
	"Morshansk",
	"Arzano",
	"Palayan City",
	"Lusambo",
	"Salvatierra",
	"Marcq-en-Baroeul",
	"Villarrica",
	"Zacatelco",
	"Corsico",
	"Kozáni",
	"Vredenburg",
	"Malbork",
	"Wilrijk",
	"Panaji",
	"Greenacres",
	"Le Pré-Saint-Gervais",
	"Hyde",
	"Famagusta",
	"Puerto Francisco de Orellana",
	"San Roque",
	"Ngozi",
	"Burjasot",
	"Miyoshidai",
	"Chartres",
	"Barreiros",
	"Wilkes-Barre",
	"Fleet",
	"Şəmkir",
	"Villefranche-sur-Saône",
	"Pärnu",
	"Whitley Bay",
	"Dún Dealgan",
	"Shengli",
	"Al Karnak",
	"Sayula de Alemán",
	"Bjelovar",
	"Teaneck",
	"Grugliasco",
	"Al Bayḑā’",
	"Villepinte",
	"Ez Zahra",
	"Yambio",
	"Bouar",
	"Beeston",
	"Ban Sai Ma Tai",
	"Camberley",
	"Agualva",
	"Poissy",
	"Mariano Escobedo",
	"Neuilly-sur-Marne",
	"Kaya",
	"Inongo",
	"Kanie",
	"Sainte-Geneviève-des-Bois",
	"Montclair",
	"Retalhuleu",
	"Deçan",
	"Dunstable",
	"Xaçmaz",
	"Chachoengsao",
	"Bletchley",
	"Baler",
	"Denton",
	"Surin",
	"San Gabriel",
	"Koumra",
	"North Shields",
	"Hallandale Beach",
	"Massawa",
	"Pont-y-pŵl",
	"San Juan Despí",
	"Kenton",
	"Santa Elena",
	"Pioltello",
	"Keizer",
	"Mochudi",
	"Vernier",
	"Chelsea",
	"Bridlington",
	"Sant’Antimo",
	"Sopot",
	"La Puente",
	"Celje",
	"Houilles",
	"Waipahu",
	"Conflans-Sainte-Honorine",
	"Florida",
	"Pierrefitte-sur-Seine",
	"Istog",
	"Ağcabədi",
	"Bollate",
	"L’Haÿ-les-Roses",
	"Annapolis",
	"Romblon",
	"Culver City",
	"Walkden",
	"Mambajao",
	"Durazno",
	"São Francisco do Sul",
	"Pánuco",
	"Nueva Italia de Ruiz",
	"Xam Nua",
	"Tromsø",
	"Januária",
	"Koekelberg",
	"Creil",
	"Northolt",
	"Dédougou",
	"Frankfort",
	"Armavir",
	"Ayutuxtepeque",
	"Northglenn",
	"Kavadarci",
	"Vrilíssia",
	"Miahuatlán de Porfirio Díaz",
	"Bərdə",
	"Hà Giang",
	"Châtenay-Malabry",
	"Tuyên Quang",
	"El Hajeb",
	"Karonga",
	"Palaiseau",
	"Fort Lee",
	"Casalecchio di Reno",
	"Highbury",
	"Zinacantán",
	"Premiá de Mar",
	"Ewell",
	"Tunceli",
	"Les Lilas",
	"Lake Worth",
	"Wisbech",
	"Bossangoa",
	"Klinë",
	"Tit Mellil",
	"Riacho de Santana",
	"Bucha",
	"Chorley",
	"Zaïo",
	"Athis-Mons",
	"Kendall West",
	"Falkirk",
	"Montclair",
	"Jacarèzinho",
	"Zeghanghane",
	"Hanwell",
	"Jiménez",
	"Tuxpan",
	"Choybalsan",
	"Am-Timan",
	"Tenkodogo",
	"La Presa",
	"Massamá",
	"Brugherio",
	"Macuspana",
	"Stanton",
	"Shangzhuangcun",
	"Brzeg",
	"Redcar",
	"Kikinda",
	"Tomatlán",
	"East Meadow",
	"Paphos",
	"Limbiate",
	"Ali Sabieh",
	"Mission Bend",
	"Adjumani",
	"Gisborne",
	"Spalding",
	"Mediouna",
	"Dori",
	"Yihezhuang",
	"Capitão Poço",
	"Esch-sur-Alzette",
	"Frattamaggiore",
	"Le Plessis-Robinson",
	"Egypt Lake-Leto",
	"Escuinapa",
	"Kajaani",
	"Salto del Guairá",
	"Samrong",
	"Segrate",
	"Villeneuve-Saint-Georges",
	"Richmond West",
	"Schaffhausen",
	"Valley Stream",
	"Hanover Park",
	"Tozeur",
	"Villemomble",
	"Hitchin",
	"Nanchital de Lázaro Cárdenas del Río",
	"‘Ataq",
	"Strood",
	"Soteapan",
	"Santa María Atzompa",
	"South Miami Heights",
	"Prijepolje",
	"Al Qunayţirah",
	"Chaiyaphum",
	"Salt",
	"Riccione Marina",
	"Swords",
	"Aventura",
	"Park Ridge",
	"Thonon-les-Bains",
	"Tenosique",
	"Cernusco sul Naviglio",
	"Putla Villa de Guerrero",
	"Saint-Mandé",
	"Falun",
	"Letchworth",
	"Santa Isabel",
	"Ratchaburi",
	"Wigston Magna",
	"Bezons",
	"Clichy-sous-Bois",
	"Pinner",
	"Karlskrona",
	"Ticul",
	"Angri",
	"Calpulalpan",
	"Ebebiyín",
	"Trowbridge",
	"Czeladź",
	"Rayleigh",
	"Roanne",
	"West Hollywood",
	"Earley",
	"Paso de Ovejas",
	"Agen",
	"Bicester",
	"Abasolo",
	"Paso del Macho",
	"Jordan",
	"Goba",
	"Göyçay",
	"Richfield",
	"Rutherglen",
	"Campo Maior",
	"Kearns",
	"Lincoln Park",
	"Chur",
	"Yehud",
	"Ryde",
	"Amecameca de Juárez",
	"Schiltigheim",
	"Lichfield",
	"Chatou",
	"Ecclesfield",
	"Ometepec",
	"Lauderdale Lakes",
	"Belleville",
	"Pontypridd",
	"Krong Kep",
	"Viry-Châtillon",
	"Kornwestheim",
	"Minamishiro",
	"Prestwich",
	"Kamenicë",
	"Ermont",
	"Viborg",
	"San Juan Evangelista",
	"Gostivar",
	"Chirivella",
	"Watertown Town",
	"Beja",
	"Foothill Farms",
	"Vršac",
	"Paranaíba",
	"Maassluis",
	"Romainville",
	"Elmont",
	"Brighouse",
	"Temple City",
	"Farnworth",
	"Villaricca",
	"La Asunción",
	"Cradock",
	"Stroud",
	"Darwen",
	"Chuhuiv",
	"Fresnes",
	"Montigny-le-Bretonneux",
	"Ixtapan de la Sal",
	"Aosta",
	"Tancítaro",
	"Ganshoren",
	"Dover",
	"Timbuktu",
	"Chillum",
	"Bexleyheath",
	"Les Mureaux",
	"Byumba",
	"Bell",
	"Taibao",
	"Roi Et",
	"Villanueva",
	"Talas",
	"Champotón",
	"Nelson",
	"Bragança",
	"Phatthalung",
	"Neuchâtel",
	"Huyton",
	"Sestao",
	"Santa Cruz del Quiché",
	"Westmont",
	"Luebo",
	"Bria",
	"San Donato Milanese",
	"Néa Filadélfeia",
	"Manhattan Beach",
	"Pontoise",
	"Vigneux-sur-Seine",
	"Artvin",
	"Maīdān Shahr",
	"Giv‘at Shemu’él",
	"Bussum",
	"Catemaco",
	"Villiers-sur-Marne",
	"Marijampolė",
	"San Pedro de Ycuamandiyú",
	"Dikhil",
	"Ocotal",
	"Trappes",
	"Santa Maria Capua Vetere",
	"Englewood",
	"Mechraa Bel Ksiri",
	"Kampong Thom",
	"San Salvador El Seco",
	"Dragash",
	"Naryn",
	"Beidaying",
	"Laḩij",
	"Buenaventura Lakes",
	"Oildale",
	"Pompeu",
	"Ciudad Sabinas Hidalgo",
	"Fontenay-aux-Roses",
	"Kalasin",
	"Saint Neots",
	"Villa Celina",
	"Grigny",
	"Jacmel",
	"Swakopmund",
	"Barra do Bugres",
	"Azogues",
	"Huatabampo",
	"Gentilly",
	"Escárcega",
	"Norristown",
	"Montgomery Village",
	"Swadlincote",
	"Wishaw",
	"Deal",
	"Cananea",
	"Homa Bay",
	"Aldaya",
	"Le Chesnay",
	"Bor",
	"Busia",
	"University City",
	"Borehamwood",
	"Fushë Kosovë",
	"Midvale",
	"Lens",
	"Uttaradit",
	"Golden Glades",
	"Nyköping",
	"Kakata",
	"Bresso",
	"Ventspils",
	"Chester",
	"Kampong Speu",
	"Zhaoyu",
	"Fada Ngourma",
	"Saint-Cloud",
	"Barros Blancos",
	"Salgótarján",
	"Foster City",
	"San Giuseppe Vesuviano",
	"Pontefract",
	"Beverly Hills",
	"Ciudad Sahagun",
	"Sidi Smai’il",
	"Willenhall",
	"Chumphon",
	"Masindi",
	"Thiais",
	"Glendale Heights",
	"Bilwi",
	"Hendrik-Ido-Ambacht",
	"Fair Oaks",
	"Kaçanik",
	"Long Beach",
	"Longton",
	"Plympton",
	"Sisak",
	"Birkirkara",
	"Koh Kong",
	"Paiporta",
	"Kyrenia",
	"Goussainville",
	"Juticalpa",
	"Bromsgrove",
	"Mukdahan",
	"Adrogue",
	"Vandœuvre-lès-Nancy",
	"Liévin",
	"Bulwell",
	"Périgueux",
	"Rincón de Romos",
	"Kericho",
	"Eastchester",
	"Pilar",
	"Dagestanskiye Ogni",
	"Kokhma",
	"Reddish",
	"West Little River",
	"Fair Lawn",
	"Sint-Joost-ten-Node",
	"Zumpango del Río",
	"Durango",
	"Aci Catena",
	"Huntington Station",
	"Sotteville-lès-Rouen",
	"Chkalov",
	"Un’goofaaru",
	"Harper",
	"Santiago Sacatepéquez",
	"Cramlington",
	"Krimpen aan den IJssel",
	"Ris-Orangis",
	"Ban Bang Krang",
	"San Andrés de la Barca",
	"North Providence",
	"Mažeikiai",
	"Savigny-le-Temple",
	"Soledad de Doblado",
	"Kendal",
	"Leticia",
	"Côte-Saint-Luc",
	"Perry Barr",
	"Mandera",
	"Lawndale",
	"Yverdon-les-Bains",
	"Oullins",
	"Bou Arfa",
	"Kitui",
	"Mount Lebanon",
	"Spring Valley",
	"Otumba",
	"Bangassou",
	"Cambuslang",
	"Chachapoyas",
	"Darlaston",
	"Barañáin",
	"West Ham",
	"Sannois",
	"Aix-les-Bains",
	"Les Pavillons-sous-Bois",
	"College Park",
	"Arfoud",
	"Rumbek",
	"Szekszárd",
	"Rillieux-la-Pape",
	"Boende",
	"Álamo",
	"Lambersart",
	"Eastpointe",
	"Franklin Square",
	"Yerres",
	"Uniondale",
	"Juneau",
	"Ulundi",
	"Molde",
	"Zug",
	"Kaita",
	"San Juan",
	"Cuitzeo del Porvenir",
	"Demnat",
	"Navolato",
	"Bourg-la-Reine",
	"Jérémie",
	"Rawson",
	"Garfield",
	"El Salto",
	"Giżycko",
	"Shumerlya",
	"Montfermeil",
	"Shariff Aguak",
	"Clifton",
	"Babati",
	"Wood Green",
	"Chon Buri",
	"Frome",
	"Smolyan",
	"Bègles",
	"Pallisa",
	"Dieppe",
	"Villiers-le-Bel",
	"Ipu",
	"Parkville",
	"Zaqatala",
	"Miami Lakes",
	"San Fernando",
	"İmişli",
	"Newton Abbot",
	"Péfki",
	"Contla",
	"Hertford",
	"Břevnov",
	"Villeneuve-la-Garenne",
	"Saint-Laurent-du-Var",
	"Blackrock",
	"Limeil-Brévannes",
	"Ban Bang Khu Wat",
	"Pando",
	"Ridley",
	"Menton",
	"Wete",
	"Mytilíni",
	"Udomlya",
	"Arendal",
	"Nebbi",
	"Ashington",
	"Cuilapa",
	"Desnogorsk",
	"Martorell",
	"Lomme",
	"Diffa",
	"San Pablo",
	"Rhyl",
	"Tuxpan",
	"Ungheni",
	"Guyancourt",
	"Burlingame",
	"Trípoli",
	"Čakovec",
	"Sonzacate",
	"Soissons",
	"Masnou",
	"Arcueil",
	"Longbridge",
	"Bregenz",
	"Rainham",
	"Pljevlja",
	"Catarroja",
	"Eaubonne",
	"Lobatse",
	"Mégrine",
	"Cabarroguis",
	"Yate",
	"Melton Mowbray",
	"Krabi",
	"Xalisco",
	"Carouge",
	"Avellaneda",
	"Oceanside",
	"Aïn Taoujdat",
	"Ninomiya",
	"Āsosa",
	"Tomares",
	"Nova Cruz",
	"Boscombe",
	"Kętrzyn",
	"City of Orange",
	"Qiman al ‘Arūs",
	"Salyan",
	"Barnstaple",
	"Bearsden",
	"Heredia",
	"Boscoreale",
	"Chichester",
	"Newton Mearns",
	"Jaynagar-Majilpur",
	"Geldrop",
	"Sterling",
	"Jamay",
	"Long Branch",
	"Santa Paula",
	"Banqiao",
	"Mount Hagen",
	"Chatan",
	"San Carlos",
	"Garbagnate Milanese",
	"Vicente López",
	"Bilston",
	"Mutsamudu",
	"Cahul",
	"Heemstede",
	"Los Reyes de Juárez",
	"Didcot",
	"Ojinaga",
	"Shotley Bridge",
	"Stepney",
	"Dübendorf",
	"Billericay",
	"Trujillo",
	"Moyale",
	"La Unión",
	"Treinta y Tres",
	"Stung Treng",
	"Bournville",
	"Rahway",
	"Tahla",
	"Camas",
	"Armilla",
	"Dietikon",
	"Otjiwarongo",
	"Bay Shore",
	"Leyton",
	"Tralee",
	"Newton Aycliffe",
	"Westchester",
	"Kaisarianí",
	"Walton upon Thames",
	"Eastleigh",
	"Ağdaş",
	"Doba",
	"Suisun City",
	"Arapoti",
	"Chiavari",
	"Cheadle Hulme",
	"Cardito",
	"West Falls Church",
	"Nuwara Eliya",
	"Frontera",
	"Katima Mulilo",
	"Golden Gate",
	"Hindley",
	"Mortsel",
	"Villeparisis",
	"Haverhill",
	"Bắc Kạn",
	"Bačka Palanka",
	"Brunoy",
	"Nogales",
	"Chalatenango",
	"Venustiano Carranza",
	"Northfield",
	"Burntwood",
	"Westhoughton",
	"Sèvres",
	"Oak Park",
	"Linden",
	"Rocha",
	"Ixhuatlancillo",
	"Estoril",
	"Santa Bárbara",
	"Khorugh",
	"Bussy-Saint-Georges",
	"Colonia del Sacramento",
	"Hillerød",
	"Kamphaeng Phet",
	"Tulum",
	"Saint-Gratien",
	"East Palo Alto",
	"Cenon",
	"Leiderdorp",
	"Camaligan",
	"Paracuaro",
	"Qualiano",
	"Saint-Sébastien-sur-Loire",
	"Sai Mai",
	"Port Chester",
	"Samut Songkhram",
	"Miahuatlán",
	"Sucy-en-Brie",
	"Leigh-on-Sea",
	"Penonomé",
	"Taverny",
	"Qahā",
	"Clydebank",
	"La Madeleine",
	"Hunucmá",
	"Armentières",
	"Buri Ram",
	"Manga",
	"Ölgiy",
	"Vallauris",
	"Dollis Hill",
	"Wellington",
	"Mendefera",
	"Consett",
	"Champs-Sur-Marne",
	"Droylsden",
	"Rafael Delgado",
	"Nutley",
	"Blenheim",
	"Giussano",
	"Shamokin",
	"Tapiales",
	"Kočani",
	"Vichy",
	"Rēzekne",
	"Élancourt",
	"Englewood",
	"Oegstgeest",
	"Mitú",
	"Balham",
	"Le Bouscat",
	"Harborne",
	"Shenley Brook End",
	"Burbank",
	"Bloxwich",
	"Alençon",
	"Zawyat ech Cheïkh",
	"Caxito",
	"Upminster",
	"Vevey",
	"Zaouiet Sousse",
	"Tixtla de Guerrero",
	"Winchester",
	"Popondetta",
	"Deuil-la-Barre",
	"Ouésso",
	"Littleover",
	"Fleetwood",
	"Highgate",
	"Market Harborough",
	"Missour",
	"Aldama",
	"Altepexi",
	"Mons-en-Baroeul",
	"Losino-Petrovskiy",
	"Oadby",
	"Hamar",
	"Le Grand-Quevilly",
	"Melrose",
	"Itambé",
	"Ocatlán",
	"Joinville-le-Pont",
	"Renens",
	"Erdington",
	"Drexel Hill",
	"Muggiò",
	"Ban Bang Phun",
	"Bongor",
	"Saint-Nicolas",
	"Mongo",
	"Penarth",
	"Acomb",
	"Dunleary",
	"Koulikoro",
	"Koper",
	"Alboraya",
	"Isla Vista",
	"Don Bosco",
	"Tantoyuca",
	"Portishead",
	"Kičevo",
	"Velenje",
	"Biarritz",
	"Rehoboth",
	"Mililani Town",
	"Béthune",
	"Ciudad Altamirano",
	"Caversham",
	"Kings Norton",
	"Imperial Beach",
	"Magdalena de Kino",
	"Al Ghayz̧ah",
	"Seriate",
	"Shtime",
	"Orly",
	"Hackney",
	"Bishopbriggs",
	"Piastów",
	"Bergenfield",
	"Nova Milanese",
	"Thornaby on Tees",
	"Ladera Ranch",
	"Neuilly-Plaisance",
	"Stalybridge",
	"Cerro Azul",
	"Pompei",
	"Kingswinford",
	"San Juan de Aznalfarache",
	"Easton",
	"Priboj",
	"Xoxocotla",
	"Maisons-Laffitte",
	"Siquijor",
	"Indiana",
	"Farnley",
	"Sensuntepeque",
	"Le Petit-Quevilly",
	"Sliema",
	"Cormeilles-en-Parisis",
	"Siliana",
	"Catió",
	"Reisterstown",
	"Chapala",
	"Weingarten",
	"Maywood",
	"Belmont",
	"Alfreton",
	"Montigny-lès-Cormeilles",
	"Atlatlahucan",
	"San Juan de Alicante",
	"Elgin",
	"Kościan",
	"Nola",
	"Kiryas Joel",
	"Lemon Grove",
	"Lindenhurst",
	"Carlos A. Carrillo",
	"Meoqui",
	"Hirrīyat Raznah",
	"Maracena",
	"Felixstowe",
	"Cherán",
	"Ocampo",
	"Lormont",
	"Bayanhongor",
	"West Whittier-Los Nietos",
	"Leisure City",
	"Moyo",
	"Camborne",
	"Seveso",
	"University Park",
	"Voinjama",
	"Cazones de Herrera",
	"Nakhon Phanom",
	"Atherton",
	"Meyrin",
	"Utena",
	"Stanmore",
	"Jonava",
	"Suphan Buri",
	"Failsworth",
	"Dongola",
	"Mendi",
	"Chaville",
	"Ennis",
	"Ulaangom",
	"Torcy",
	"San Juan de Vilasar",
	"Meda",
	"Whitefield",
	"Castilleja de la Cuesta",
	"Belper",
	"Oxkutzkab",
	"Rodez",
	"Texistepec",
	"Soledad",
	"Gjirokastër",
	"Malinalco",
	"Rivas",
	"Cliffside Park",
	"Belmont",
	"Bang Sao Thong",
	"Svay Rieng",
	"Bajina Bašta",
	"Heroica Ciudad de Tlaxiaco",
	"Clevedon",
	"Sainte-Thérèse",
	"Hovd",
	"Castaños",
	"Cəlilabad",
	"Litherland",
	"Chandlers Ford",
	"Amnat Charoen",
	"Fontaine",
	"Montgeron",
	"Mīt Namā",
	"Penwortham",
	"Szczytno",
	"El Tarf",
	"Huntingdon",
	"Atoyac de Álvarez",
	"Nar’yan-Mar",
	"Croix",
	"Kidsgrove",
	"Onex",
	"San Juan Zitlaltepec",
	"Cloverleaf",
	"Zwedru",
	"Decatur",
	"Tipasa",
	"Ottobrunn",
	"Kafr Shukr",
	"Ewa Gentry",
	"Laurel",
	"Guiseley",
	"Fray Bentos",
	"Morsang-sur-Orge",
	"Northwood",
	"San Lorenzo",
	"San Giovanni la Punta",
	"Longjumeau",
	"Visby",
	"Swinton",
	"El Cerrito",
	"Montmorency",
	"Aţ Ţafīlah",
	"Eysines",
	"Penistone",
	"Loos",
	"Kérkyra",
	"Holborn",
	"Ossett",
	"Dronfield",
	"Coral Terrace",
	"Sidi Lmokhtar",
	"Puyo",
	"Friern Barnet",
	"Kafr Qāsim",
	"South Pasadena",
	"Villabate",
	"Kenilworth",
	"Lambaréné",
	"Maesteg",
	"Sainte-Foy-lès-Lyon",
	"Ekeren",
	"South Bradenton",
	"Mohale’s Hoek",
	"Palm Springs",
	"Vukovar",
	"Wewak",
	"Tassin-la-Demi-Lune",
	"Sheldon",
	"Levittown",
	"Hacı Zeynalabdin",
	"Hérouville-Saint-Clair",
	"Montigny-lès-Metz",
	"Santiago Ixcuintla",
	"Sceaux",
	"Normanton",
	"Kaga Bandoro",
	"Benetúser",
	"Tbeng Meanchey",
	"Portalegre",
	"University Park",
	"Bispham",
	"Chocamán",
	"Terrytown",
	"Novo Mesto",
	"Cusano Milanino",
	"Tlacolula de Matamoros",
	"Krathum Baen",
	"Riverbank",
	"Stratton Saint Margaret",
	"March",
	"Maplewood",
	"Tequixquiac",
	"Le Plessis-Trévise",
	"Le Mée-sur-Seine",
	"Baldwin",
	"Bontoc",
	"Melrose Park",
	"Sühbaatar",
	"Palm Tree",
	"Reyes Acozac",
	"Baalbek",
	"Palmers Green",
	"Degollado",
	"Hornsey",
	"Herndon",
	"Juvisy-sur-Orge",
	"Falmouth",
	"Royton",
	"Kasamatsuchō",
	"Kanmaki",
	"General Emilio Aguinaldo",
	"Vélizy-Villacoublay",
	"Chevilly-Larue",
	"Chiconcuac",
	"Falagueira",
	"Shārūnah",
	"Rockville Centre",
	"Linslade",
	"La Celle-Saint-Cloud",
	"Lop Buri",
	"Suitland",
	"Newquay",
	"Gan Yavne",
	"Cormano",
	"Plumstead",
	"Watauga",
	"Cotija de la Paz",
	"Carlow",
	"Saint-Pol-sur-Mer",
	"São João da Madeira",
	"Ashland",
	"Fatick",
	"Garches",
	"Chesham",
	"Woodlawn",
	"Lodi",
	"Villa Sarmiento",
	"San Fernando",
	"Chanthaburi",
	"Xingangli",
	"Peekskill",
	"Mill Creek East",
	"Dongta",
	"Tysons",
	"Frattaminore",
	"Valmiera",
	"Newton in Makerfield",
	"Bailey's Crossroads",
	"Vrbas",
	"Grumo Nevano",
	"Neath",
	"Huskvarna",
	"Atar",
	"Motul",
	"Elmwood Park",
	"Oak Ridge",
	"Renfrew",
	"Dammarie-lè-Lys",
	"Senago",
	"Qalansuwa",
	"Horwich",
	"Hellemmes-Lille",
	"Owando",
	"Cottingham",
	"Southgate",
	"Guaranda",
	"Villeneuve-le-Roi",
	"Le Bourget",
	"Kibaha",
	"Mantes-la-Ville",
	"Saint-Maurice",
	"Banī Murr",
	"Chilly-Mazarin",
	"Tewkesbury",
	"Satun",
	"Saint-Michel-sur-Orge",
	"Phetchaburi",
	"Valinda",
	"Novate Milanese",
	"Kudrovo",
	"North Lynnwood",
	"Rosemont",
	"Southbourne",
	"Peterlee",
	"Kirkstall",
	"Penzance",
	"Cudahy",
	"Copiague",
	"Shoreham-by-Sea",
	"Winter Gardens",
	"Yonabaru",
	"Seaham",
	"Allschwil",
	"Alfafar",
	"Nkhata Bay",
	"Hialeah Gardens",
	"Wasquehal",
	"Sukhodilsk",
	"Coudekerque-Branche",
	"Carteret",
	"Opfikon",
	"Tena",
	"West Puente Valley",
	"Peto",
	"Biddulph",
	"Killingworth",
	"Loreto",
	"Naas",
	"Sacavém",
	"Caterham",
	"Nyon",
	"Achères",
	"Ciudad Miguel Alemán",
	"Bellshill",
	"Mosta",
	"Worcester Park",
	"Maywood",
	"Davyhulme",
	"Fleury-les-Aubrais",
	"Gröbenzell",
	"Hidalgotitlán",
	"Sunbāţ",
	"Ronchin",
	"Monsey",
	"Ogre",
	"Kilkenny",
	"Alloa",
	"Aarau",
	"West Rancho Dominguez",
	"Blue Island",
	"Lamu",
	"Biləsuvar",
	"Kayanza",
	"Monserrato",
	"Zaragoza",
	"Soisy-sous-Montmorency",
	"Trentola",
	"Sibiti",
	"Willowbrook",
	"Llandudno",
	"Saint-Cyr-l’École",
	"Zimatlán de Álvarez",
	"Savanna-la-Mar",
	"Madingou",
	"Bathgate",
	"Mzimba",
	"Sibut",
	"Nogent-sur-Oise",
	"Rothwell",
	"Goodmayes",
	"Kėdainiai",
	"Ives Estates",
	"Uliastay",
	"Burnham-on-Sea",
	"Ahfir",
	"Jēkabpils",
	"Mödling",
	"Ischia",
	"Quedgeley",
	"Kayunga",
	"Dukinfield",
	"Mulanje",
	"Landover",
	"Mecayapan",
	"Prestatyn",
	"Bafatá",
	"Dingle",
	"Phichit",
	"Saint Paul’s Bay",
	"Montereau-faut-Yonne",
	"Mouila",
	"The Crossings",
	"Tepatlaxco",
	"Saint-Fons",
	"Loei",
	"Millbrae",
	"East Barnet",
	"Oulad Fraj",
	"Molesey",
	"Mbaïki",
	"Soroca",
	"Arese",
	"Fgura",
	"Nsanje",
	"Cercola",
	"Lingolsheim",
	"Connahs Quay",
	"Truro",
	"São Filipe",
	"Fengdeng",
	"Phetchabun",
	"Sant’Antonio Abate",
	"Liversedge",
	"Bella Unión",
	"Cran-Gévrier",
	"Caacupé",
	"Antrim",
	"Fâches-Thumesnil",
	"Fortín de las Flores",
	"West Carson",
	"Mocoa",
	"Telšiai",
	"Assebroek",
	"Linlithgow",
	"Keynsham",
	"Lealman",
	"Kegalle",
	"Éragny",
	"Ghāt",
	"Hayesville",
	"Bry-sur-Marne",
	"Moreton",
	"Hillside",
	"Bedlington",
	"Reinach",
	"Al Karak",
	"Jasmine Estates",
	"Saint-Lambert",
	"Woodhouse",
	"Aldo Bonzi",
	"Westbury",
	"Hadleigh",
	"Kitagata",
	"Moulins",
	"Goroka",
	"Lennox",
	"Kew Green",
	"Roselle",
	"Sunny Isles Beach",
	"Massapequa",
	"Chamalières",
	"Calne",
	"Carmelo",
	"Acatlán de Osorio",
	"Mont-Saint-Aignan",
	"Calella",
	"North Bay Shore",
	"Nantwich",
	"Kapolei",
	"Alajuelita",
	"Nong Bua Lamphu",
	"Ashtarak",
	"Abbots Langley",
	"Obiliq",
	"Dover",
	"Hamtramck",
	"Chennevières-sur-Marne",
	"Hampton",
	"Le Pecq",
	"Tauragė",
	"Hacıqabul",
	"Ayutla de los Libres",
	"Schlieren",
	"McNair",
	"Johnstone",
	"Bozoum",
	"Mchinji",
	"Pully",
	"Patcham",
	"Dawlish",
	"Artashat",
	"Virovitica",
	"Tatahuicapan",
	"San Francisco",
	"Mickleover",
	"Redhill",
	"Vauréal",
	"Maralal",
	"Mountlake Terrace",
	"Hebburn",
	"Bonneuil-sur-Marne",
	"Caiapônia",
	"North Plainfield",
	"Le Raincy",
	"Ukmergė",
	"Santa Lucía",
	"Obock",
	"Orhei",
	"Bovisio Masciago",
	"Adliswil",
	"La Esperanza",
	"Luena",
	"Viroflay",
	"Boom",
	"Sant Just Desvern",
	"Bischheim",
	"Thalwil",
	"Horsforth",
	"Sweetwater",
	"El Astillero",
	"Hednesford",
	"Guayama",
	"Pajapan",
	"Malgrat de Mar",
	"Wolfratshausen",
	"Impfondo",
	"Quba",
	"Birstall",
	"Moston",
	"Staines-upon-Thames",
	"Gavarr",
	"Dolores",
	"Tadaoka-higashi",
	"Técpan de Galeana",
	"Écully",
	"Chemax",
	"El Rosario",
	"Wibsey",
	"Canelones",
	"North Bellmore",
	"Palisades Park",
	"Aci Castello",
	"İsmayıllı",
	"Ati",
	"El Dorado",
	"Nan",
	"Cowley",
	"Ćuprija",
	"Palau",
	"Porthcawl",
	"New Brighton",
	"Vimodrone",
	"Bingley",
	"Yasothon",
	"South El Monte",
	"Belgrave",
	"Ijevan",
	"Baguley",
	"Warminster",
	"Beinasco",
	"Ostermundigen",
	"Zaghouan",
	"West Derby",
	"Haslingden",
	"Les Clayes-sous-Bois",
	"Bubanza",
	"Columbia Heights",
	"Sunbury",
	"Stoke Gifford",
	"Nailsea",
	"Cleckheaton",
	"Somoto",
	"Senta",
	"Shirley",
	"Leagrave",
	"Lomita",
	"Cosham",
	"Enfield Lock",
	"Bayshore Gardens",
	"Ağsu",
	"Ağstafa",
	"Banī Ḩasan ash Shurūq",
	"Harwich",
	"Saffron Walden",
	"Basford",
	"Maurepas",
	"Comrat",
	"Guastatoya",
	"Kirkland",
	"Qormi",
	"Arvayheer",
	"Tyldesley",
	"Sligo",
	"Harrison",
	"Cattolica",
	"Hilsea",
	"West Chester",
	"Hakha",
	"Mubende",
	"Ulcinj",
	"Tidjikja",
	"South San Jose Hills",
	"Elmwood Park",
	"Kralingse Veer",
	"Floirac",
	"Stone",
	"Morges",
	"The Mumbles",
	"Westmount",
	"Kotsyubyns’ke",
	"Fazakerley",
	"Maldon",
	"Saynshand",
	"Wombwell",
	"Eldama Ravine",
	"West Hempstead",
	"Tilbury",
	"Ciudad de Huitzuco",
	"La Cruz",
	"Tlaxcala",
	"Larkhall",
	"Wondelgem",
	"Tecuala",
	"Inírida",
	"Biyahmū",
	"Ribeira Grande",
	"Norwood",
	"Ciudad Guadalupe Victoria",
	"Kavieng",
	"Franconia",
	"Clayton",
	"Albany",
	"La Crescenta-Montrose",
	"Telavi",
	"Faranah",
	"Phra Pradaeng",
	"Milton",
	"Santa Catarina Juquila",
	"Kidbrooke",
	"Shipley",
	"Lezhë",
	"Lons-le-Saunier",
	"Santiago Tulantepec",
	"Central Falls",
	"Whickham",
	"Rodolfo Sánchez Taboada",
	"Kapsabet",
	"Deysbrook",
	"Langley Park",
	"Mont-Royal",
	"Schofield Barracks",
	"Ciudad Hidalgo",
	"Cárdenas",
	"Barton upon Irwell",
	"Solothurn",
	"Lynbrook",
	"Lagawe",
	"Laï",
	"Bonnyrigg",
	"Budva",
	"Sơn La",
	"Arnouville-lès-Gonesse",
	"Tchibanga",
	"Ban Rawai",
	"Al Madāmūd",
	"Canovellas",
	"Macas",
	"Partick",
	"Netherton",
	"Hermosa Beach",
	"Tillabéri",
	"Seacroft",
	"Tepoztlán",
	"Brusciano",
	"Attapu",
	"Noisiel",
	"Morristown",
	"Baildon",
	"Sherrelwood",
	"Mineola",
	"Sant’Arpino",
	"Harrow Weald",
	"Evergreen Park",
	"Addlestone",
	"Saint-Jean-de-la-Ruelle",
	"Pemberton",
	"Baabda",
	"Bububu",
	"West Wickham",
	"Binningen",
	"Apaxco de Ocampo",
	"Le Vésinet",
	"Mao",
	"Saltash",
	"Montmagny",
	"Grajales",
	"Dzuunmod",
	"Wellington",
	"Bellaire",
	"Keetmanshoop",
	"Paraguarí",
	"Hendaye",
	"Hazel Grove",
	"Northenden",
	"Hinche",
	"Hūn",
	"Kimbe",
	"Saint-Leu-la-Forêt",
	"Đông Hà",
	"Puréparo de Echaíz",
	"Visaginas",
	"Marly-le-Roi",
	"Albal",
	"Mansa Konko",
	"Bou Djeniba",
	"Härnösand",
	"Tlaltetela",
	"Prestwick",
	"Point Pleasant",
	"Masamagrell",
	"Pakwach",
	"Hawthorne",
	"La Crucecita",
	"Rwamagana",
	"Hostomel",
	"Buckley",
	"Rock Ferry",
	"Əhmədli",
	"Banstead",
	"Juchique de Ferrer",
	"Siyəzən",
	"Hythe",
	"Bellwood",
	"Vicente Guerrero",
	"North Valley Stream",
	"Ptuj",
	"Leposaviq",
	"Goulmima",
	"Sabirabad",
	"Clitheroe",
	"Seabrook",
	"Bromborough",
	"Avenel",
	"Sudley",
	"Jouy-le-Moutier",
	"Tarrafal",
	"Tsetserleg",
	"Casandrino",
	"Winthrop",
	"Cerritos",
	"Carrières-sous-Poissy",
	"North Massapequa",
	"Lognes",
	"Straşeni",
	"Mésa Geitoniá",
	"Tingüindín",
	"Oteapan",
	"Beausoleil",
	"Pedreiras",
	"Huixcolotla",
	"Izamal",
	"Ermúa",
	"Bryn Mawr-Skyway",
	"Melksham",
	"Yiewsley",
	"Coquimatlán",
	"North Amityville",
	"Carugate",
	"Bidston",
	"Forfar",
	"Żabbar",
	"Brookfield",
	"Rutherford",
	"Bartica",
	"Bebington",
	"Wallisellen",
	"Merrifield",
	"Arenys de Mar",
	"Madera",
	"Idylwood",
	"Richmond",
	"Kédougou",
	"Hyattsville",
	"Pendlebury",
	"Filadelfia",
	"Dedza",
	"Gornalwood",
	"Llantrisant",
	"Iselin",
	"Sing Buri",
	"Salaspils",
	"Hessle",
	"Royston",
	"La Llagosta",
	"Prachin Buri",
	"Montargis",
	"Amblecote",
	"Saint Budeaux",
	"Sainte-Marthe-sur-le-Lac",
	"Vynnyky",
	"Tunapuna",
	"Prilly",
	"Selby",
	"Viljandi",
	"Orange Walk",
	"Torit",
	"Bois-d’Arcy",
	"Saatlı",
	"Lucé",
	"Stapleford",
	"Mountain House",
	"Akhaltsikhe",
	"Sa Kaeo",
	"Churriana de la Vega",
	"Gines",
	"Bourne",
	"Burke Centre",
	"Eppelheim",
	"Sidlice",
	"Thônex",
	"West Drayton",
	"Perivale",
	"Khaşab",
	"Sidi Zouine",
	"Guerrero Negro",
	"Kidlington",
	"Cizur Mayor",
	"Santa Rosalía",
	"Valparaíso",
	"Rosyth",
	"Ban Houayxay",
	"Kukës",
	"Takoma Park",
	"Dover",
	"Puerto Ayora",
	"Brierley Hill",
	"Swinton",
	"Little Hulton",
	"Marlow",
	"Enghien-les-Bains",
	"Cedar Mill",
	"Esquimalt",
	"Greenfield",
	"Valenton",
	"Tak",
	"Eilendorf",
	"Lys-lès-Lannoy",
	"Füzuli",
	"Phayao",
	"Haubourdin",
	"Caerfyrddin",
	"Deux-Montagnes",
	"Mehtar Lām",
	"Villa Unión",
	"Pinewood",
	"Chitipa",
	"Huitzilan",
	"Canet de Mar",
	"Kingstowne",
	"Dumont",
	"Gobabis",
	"Country Walk",
	"Longwy",
	"Manassas Park",
	"Rossington",
	"Woodmere",
	"Stanley",
	"Lowton",
	"South Houston",
	"Ringwood",
	"Sudbury",
	"Ilkley",
	"Guachochi",
	"Kamuli",
	"Juan Aldama",
	"Ovenden",
	"Childwall",
	"Ezequiel Montes",
	"Saint-Brice-sous-Forêt",
	"Verwood",
	"Balancán",
	"Rijnsburg",
	"Iheddadene",
	"Whitby",
	"Hetton le Hole",
	"Miahuatlán",
	"Marsabit",
	"Egg Buckland",
	"Illizi",
	"Avon",
	"Trstenik",
	"Hawick",
	"Wanstead",
	"Newport Pagnell",
	"Rive-de-Gier",
	"Udelnaya",
	"Tilehurst",
	"Massapequa Park",
	"Wahiawa",
	"Ma’rib",
	"Lansdale",
	"Makokou",
	"Wattignies",
	"Saint-André",
	"Hybla Valley",
	"Shenley Church End",
	"Neubiberg",
	"Montesson",
	"Calkiní",
	"Brownsville",
	"Rozenburg",
	"Chaddesden",
	"San Ignacio",
	"Mārupe",
	"East Cleveland",
	"Arbon",
	"Anzin",
	"Glassmanor",
	"Cachoeira Alta",
	"Smethwick",
	"Roosevelt",
	"Mosgiel",
	"Coseley",
	"Brackley",
	"Tempoal de Sánchez",
	"Villasanta",
	"Live Oak",
	"Armthorpe",
	"Birqāsh",
	"Astara",
	"Phrae",
	"Milngavie",
	"Zveçan",
	"Plungė",
	"Puerto Carreño",
	"Bodmin",
	"Ntungamo",
	"Clayton",
	"L’Ancienne-Lorette",
	"Redruth",
	"Walsall Wood",
	"San Nicolás de los Ranchos",
	"Tukums",
	"Mollerusa",
	"Ramonville-Saint-Agne",
	"Hollinwood",
	"Whakatane",
	"Struga",
	"Streetly",
	"Fraserburgh",
	"Glenmont",
	"Vicente Guerrero",
	"Bull Run",
	"Elesbão Veloso",
	"Pathum Thani",
	"Conisbrough",
	"Thorpe Saint Andrew",
	"Kretinga",
	"Bostonia",
	"Artesia",
	"Yauco",
	"Tabernes Blanques",
	"Blunsdon Saint Andrew",
	"Herceg Novi",
	"Evington",
	"South Orange Village",
	"Chojnów",
	"Broughty Ferry",
	"Milford Haven",
	"Bredbury",
	"New Milford",
	"Ogíjares",
	"Ojus",
	"East Riverdale",
	"Castellanza",
	"Mongat",
	"Hall in Tirol",
	"Four Corners",
	"Hillcrest Heights",
	"Roşu",
	"Little Lever",
	"Rakvere",
	"Adelphi",
	"Moussoro",
	"Hazel Park",
	"Ranong",
	"Mouvaux",
	"Stanford",
	"Baranzate",
	"Nakhon Nayok",
	"Thatto Heath",
	"Mahikeng",
	"Solaro",
	"Oulad Hamdane",
	"Radoviš",
	"Naama",
	"East San Gabriel",
	"Morangis",
	"Sedgley",
	"Santa Cruz Amilpas",
	"Minehead",
	"Xaignabouli",
	"Koulamoutou",
	"Vincent",
	"Varedo",
	"Villa Hayes",
	"San Miguel Xoxtla",
	"Wollaston",
	"Bačka Topola",
	"Broadwater",
	"Aldama",
	"Monte di Procida",
	"Mexicaltzingo",
	"Kinkala",
	"Buckingham",
	"Castle Bromwich",
	"Aylestone",
	"Cherryland",
	"Chard",
	"Fleury-Mérogis",
	"Spitalfields",
	"Bryn",
	"Căuşeni",
	"Sutton on Hull",
	"Persan",
	"Fontenay-le-Fleury",
	"Midsomer Norton",
	"Auray",
	"Šilutė",
	"Ángel R. Cabada",
	"Southwick",
	"Bijelo Polje",
	"Anadyr",
	"Longbenton",
	"Walnut Park",
	"Pelsall",
	"Maliana",
	"Ascensión",
	"Floral Park",
	"White Center",
	"Sukhothai",
	"Parkway",
	"Altay",
	"Asperg",
	"Thornbury",
	"East Rancho Dominguez",
	"South River",
	"Peshkopi",
	"Gevgelija",
	"Eggertsville",
	"Allestree",
	"Épinay-sous-Sénart",
	"Lye",
	"Brownhills",
	"North Arlington",
	"Stranraer",
	"McFarland",
	"Palmetto Estates",
	"Uthai Thani",
	"Fenton",
	"Ainaro",
	"Parlier",
	"Vaires-sur-Marne",
	"Edineţ",
	"Sedaví",
	"West University Place",
	"Palanga",
	"Knutsford",
	"Chepstow",
	"Kiboga",
	"Seïada",
	"Chilwell",
	"Corsham",
	"Voisins-le-Bretonneux",
	"Arpajon",
	"Sonoita",
	"Kürdəmir",
	"La Palma",
	"Avocado Heights",
	"La Tour-de-Peilz",
	"Pacific Grove",
	"Asbury Park",
	"Kamnik",
	"Göygöl",
	"Billère",
	"Hamworthy",
	"Saint-Max",
	"Bellmore",
	"Berkley",
	"Anenecuilco",
	"Westbury",
	"La Grange",
	"Santa María Jalapa del Marqués",
	"Devizes",
	"Radviliškis",
	"Wilkinsburg",
	"El Fuerte",
	"Fords",
	"Zubin Potok",
	"Isla Mujeres",
	"South Farmingdale",
	"La Magdalena Chichicaspa",
	"Calvizzano",
	"Campbelltown",
	"Liestal",
	"Chêne-Bougeries",
	"Wordsley",
	"Tarxien",
	"Snaresbrook",
	"Santa María Huazolotitlán",
	"Paro",
	"Broughton Astley",
	"Montévrain",
	"Ecublens",
	"Phôngsali",
	"Lambeth",
	"Cuencamé de Ceniceros",
	"Quthing",
	"West Park",
	"Kidal",
	"Wallingford",
	"Timperley",
	"Parkstone",
	"San Isidro Buen Suceso",
	"North New Hyde Park",
	"Sandridge",
	"Gwanda",
	"Olton",
	"Seaford",
	"Masallı",
	"Kenmore",
	"Fayrōz Kōh",
	"Leopold",
	"Baucau",
	"Saucillo",
	"Ocean Pointe",
	"Canegrate",
	"Woolton",
	"Le Grand-Saconnex",
	"Newport",
	"Cēsis",
	"Killamarsh",
	"Prescot",
	"Ozurgeti",
	"Jesenice",
	"Huntington",
	"Dzitbalché",
	"Huétor Vega",
	"New Cassel",
	"Caldicot",
	"Tadjourah",
	"Abergavenny",
	"Eisenstadt",
	"Libertad",
	"El Menzel",
	"Chiautla de Tapia",
	"San Pedro Jicayán",
	"Rorschach",
	"Lykóvrysi",
	"Lemon Hill",
	"Kemp Mill",
	"Saint-André-les-Vergers",
	"Öndörhaan",
	"Sironko",
	"Debar",
	"Hwlffordd",
	"Pierre",
	"Abertillery",
	"Biassono",
	"Hoyland Nether",
	"Bargoed",
	"Falls Church",
	"Weehawken",
	"Luganville",
	"Kriva Palanka",
	"Berriozar",
	"Forécariah",
	"Jaltenango",
	"Pincourt",
	"Tierra Colorada",
	"Stiring-Wendel",
	"Malvinas Argentinas",
	"Basse Santa Su",
	"Ar Rommani",
	"Baruun-Urt",
	"Haddon",
	"Villa Unión",
	"Carnoustie",
	"Port Antonio",
	"Ewa Beach",
	"Rayón",
	"Gabú",
	"Schwyz",
	"Ashby de la Zouch",
	"Tonyrefail",
	"Halawa",
	"Sigulda",
	"Wath upon Dearne",
	"Hœnheim",
	"Alsager",
	"Birsfelden",
	"Marsaskala",
	"North Fair Oaks",
	"Bacalar",
	"Muna",
	"Hambantota",
	"Cayey",
	"Haydock",
	"Neftçala",
	"Laaouama",
	"Cudworth",
	"Zefýri",
	"Qusar",
	"Villars-sur-Glâne",
	"San Pedro Ixcatlán",
	"Brislington",
	"Oulad Ayyad",
	"Shoeburyness",
	"Coalcomán de Vázquez Pallares",
	"Nuevo Progreso",
	"Stone Ridge",
	"Cowley",
	"Fairview",
	"Pôrto Esperidião",
	"Halstead",
	"Hawaiian Gardens",
	"Norridge",
	"Wealdstone",
	"Gillingham",
	"Oakham",
	"Hatch End",
	"Lemington",
	"Street",
	"Coacoatzintla",
	"Cowes",
	"Willowick",
	"Kronshagen",
	"Sjenica",
	"Ville-d’Avray",
	"Raynes Park",
	"Burslem",
	"Waimalu",
	"Chinsali",
	"Ryhope",
	"Chichihualco",
	"Bishops Cleeve",
	"Helston",
	"Ponte San Pietro",
	"Cetinje",
	"Talant",
	"Kisoro",
	"San Gregorio di Catania",
	"Franklin Park",
	"Olympia Heights",
	"Nacaome",
	"Kuressaare",
	"Altamirano",
	"Royston",
	"Lerma",
	"Chicago Ridge",
	"Kippax",
	"Bishopstoke",
	"Canatlán",
	"Collingswood",
	"Negēlē",
	"Risca",
	"Glascote",
	"Cheam",
	"Plav",
	"Saint Ann’s Bay",
	"Sveti Nikole",
	"Whitefish Bay",
	"Déville-lès-Rouen",
	"Bexley",
	"San Patricio",
	"Harper Woods",
	"Bar",
	"Las Veredas",
	"González",
	"Naifaru",
	"Princeton Meadows",
	"Highland Park",
	"Tovuz",
	"Forest Park",
	"Ang Thong",
	"Henley on Thames",
	"Shtërpcë",
	"Épinay-sur-Orge",
	"Aberaman",
	"Mīt Damsīs",
	"Torpoint",
	"Hirnyk",
	"Roselle Park",
	"San Luis de La Loma",
	"Imsida",
	"Akil",
	"Craponne",
	"Tavistock",
	"Dugny",
	"Cupar",
	"Marton",
	"Capodrise",
	"Beaumont",
	"San Carlos",
	"Stanwell",
	"Nazaret",
	"Grover Beach",
	"Ormesson-sur-Marne",
	"Brierfield",
	"Chanteloup-les-Vignes",
	"Chatteris",
	"Ifield",
	"Buckhurst Hill",
	"Newport",
	"Santa Ana",
	"Nuevo Ideal",
	"Maromme",
	"Faya",
	"Eaton Socon",
	"Domžale",
	"Negotino",
	"Gżira",
	"Horsell",
	"Whitchurch",
	"Platón Sánchez",
	"Edgewater",
	"Corinda",
	"Kumi",
	"Huntington",
	"Stocksbridge",
	"Swieqi",
	"Meliana",
	"Kilsyth",
	"Drochia",
	"Tlacotalpan",
	"Montigny-en-Gohelle",
	"Delémont",
	"Männedorf",
	"Nieuw Nickerie",
	"Mwanza",
	"San Juanito",
	"Barnoldswick",
	"Mariental",
	"Tixkokob",
	"Santa Ana",
	"Magugpo Poblacion",
	"Acanceh",
	"La Grange Park",
	"Shorewood",
	"Jarville-la-Malgrange",
	"Flixton",
	"Bocas del Toro",
	"Tetela del Volcán",
	"Steinbach am Taunus",
	"Handsworth",
	"Nova Gorica",
	"Coatesville",
	"Żejtun",
	"Kibuye",
	"Pajacuarán",
	"Hellesdon",
	"Shepton Mallet",
	"La Huacana",
	"Crosne",
	"Gaillard",
	"Inverurie",
	"Steinkjer",
	"Sugarland Run",
	"Psychikó",
	"New Carrollton",
	"San Ignacio Cohuirimpo",
	"Procida",
	"Caudebec-lès-Elbeuf",
	"Valga",
	"Ialoveni",
	"Ridgefield Park",
	"Obo",
	"Daru",
	"Lamphun",
	"Cuatro Ciénegas de Carranza",
	"Ziniaré",
	"Key Biscayne",
	"Federal Heights",
	"Cornelius",
	"Kingston",
	"Ardmore",
	"Tacotalpa",
	"University Heights",
	"Pierre-Bénite",
	"Candelaria",
	"Chai Nat",
	"Rokiškis",
	"Wilton Manors",
	"Valadares",
	"Lagamar",
	"Aţ Ţūr",
	"Blackwood",
	"Oroquieta",
	"Santa Venera",
	"Exhall",
	"Manatí",
	"Tərtər",
	"Clarkston",
	"Caernarfon",
	"Shildon",
	"Tlahualilo de Zaragoza",
	"Tinqueux",
	"Hînceşti",
	"Nairn",
	"Attard",
	"Ermoúpoli",
	"Lantana",
	"Ahuacatlán",
	"Roby",
	"Ntcheu",
	"Sîngerei",
	"Nueva Palmira",
	"L’Île-Saint-Denis",
	"Broadstone",
	"Avranches",
	"San Marzano sul Sarno",
	"Murska Sobota",
	"Pelham",
	"Cherry Creek",
	"Bradford-on-Avon",
	"Worsley",
	"Río Grande",
	"Chapeltown",
	"Marquette-lès-Lille",
	"San Sebastiano al Vesuvio",
	"Ciudad Tula",
	"Bulgan",
	"Fosses",
	"Mariehamn",
	"Taraclia",
	"Warfield",
	"Zamora",
	"Chavannes-près-Renens",
	"Croissy-sur-Seine",
	"Utley",
	"Võru",
	"Minnehaha",
	"Beyləqan",
	"Cesa",
	"Patchogue",
	"Oak Hills",
	"Allington",
	"Igny",
	"Cuapiaxtla de Madero",
	"Heybridge",
	"Qufādah",
	"Tlalixtac de Cabrera",
	"Neuville-lès-Dieppe",
	"Qalāt",
	"Batken",
	"Ayapango",
	"Sallaumines",
	"North Merrick",
	"Eybens",
	"Home Gardens",
	"Făleşti",
	"Salisbury",
	"Xicoténcatl",
	"Assomada",
	"Druskininkai",
	"Tottington",
	"Izola",
	"Floreşti",
	"Emeryville",
	"Cimişlia",
	"Choix",
	"Dinnington",
	"Tzucacab",
	"Anáhuac",
	"Portico di Caserta",
	"Barwell",
	"Khunays",
	"San Gregorio Atzompa",
	"Tekit",
	"Mpigi",
	"Greenhill",
	"Robertsport",
	"Dundo",
	"Massagno",
	"Hasbrouck Heights",
	"Larbert",
	"Bougival",
	"Clayton le Moors",
	"Dowlais",
	"Red Bank",
	"Ferney-Voltaire",
	"Saint Peters",
	"Amatlán de los Reyes",
	"Rabat",
	"Lydney",
	"South Miami",
	"Llantwit Major",
	"Widnau",
	"Rosario",
	"Gedling",
	"Đà Nẵng",
	"San José el Viejo",
	"Bures-sur-Yvette",
	"Żurrieq",
	"Clawson",
	"Berane",
	"Launceston",
	"Newman",
	"Naranja",
	"Birtley",
	"Tiptree",
	"Ndélé",
	"Woodbridge",
	"View Park-Windsor Hills",
	"Fort Liberté",
	"Škofja Loka",
	"Boxley",
	"North Wantagh",
	"Bol",
	"Epalinges",
	"Kilchberg",
	"Coahuitlán",
	"Sidney",
	"Bothell East",
	"Bihorel",
	"Elektrėnai",
	"Le Plessis-Bouchard",
	"Higuera de Zaragoza",
	"Thornhill",
	"Alotau",
	"Brandon",
	"Rafael Lucio",
	"Mountain Ash",
	"Shanhūr",
	"Casapulla",
	"Alum Rock",
	"Beauchamp",
	"Delčevo",
	"Le Portel",
	"Saron",
	"La Junta",
	"Westwood Lakes",
	"Cuauhtémoc",
	"Yeadon",
	"Wallington",
	"Sanniquellie",
	"Cam",
	"Vaucresson",
	"Waipio",
	"Qax",
	"Groslay",
	"Maili",
	"Sandiacre",
	"River Edge",
	"Yuscarán",
	"Halachó",
	"Signal Hill",
	"Ḩadībū",
	"Weißenthurm",
	"Leven",
	"Concordia",
	"Bonhill",
	"Nidau",
	"Leavesden Green",
	"Kirkwall",
	"Sant’Agnello",
	"Glastonbury",
	"Mahadipur",
	"Dunbar",
	"Narborough",
	"Bolton upon Dearne",
	"Zacapoaxtla",
	"Torre Boldone",
	"Ambilly",
	"Cullercoats",
	"Yarm",
	"Évian-les-Bains",
	"Vanimo",
	"Heer",
	"Stopsley",
	"Rye",
	"Pérenchies",
	"Reinosa",
	"Billy-Montigny",
	"Lekeitio",
	"Magas",
	"Westgate on Sea",
	"Piedmont",
	"Great Burstead",
	"Rossmoor",
	"Guttenberg",
	"Dobroeşti",
	"Summit",
	"Rezina",
	"Loikaw",
	"Newbold",
	"Ablon-sur-Seine",
	"Orange Cove",
	"San Juan Ixcaquixtla",
	"Montmeló",
	"Sundon",
	"Tataltepec de Valdés",
	"Woodchurch",
	"Coamo",
	"Biltine",
	"Tepeojuma",
	"Suffern",
	"Anenii Noi",
	"Gournay-sur-Marne",
	"Rothwell",
	"Karuzi",
	"Bedwas",
	"Stonehouse",
	"Bramley",
	"Cocorit",
	"Cross Gates",
	"Călăraşi",
	"Meythet",
	"Vinica",
	"Aucamville",
	"Ain Lehjer",
	"Pyle",
	"Probištip",
	"Ahumada",
	"La Riviera",
	"Stainforth",
	"Beni Bou Yafroun",
	"Bucksburn",
	"West Auckland",
	"Marlborough",
	"Birżebbuġa",
	"Little Ferry",
	"Gowerton",
	"Daşkəsən",
	"Shifnal",
	"Graçanicë",
	"Bela Crkva",
	"Darby",
	"Oban",
	"Kavaratti",
	"Northbrook",
	"El Higo",
	"Jõhvi",
	"Latchford",
	"Lansdowne",
	"Harworth",
	"North Lindenhurst",
	"Guadalupe Yancuictlalpan",
	"Al Maḩwīt",
	"Sainte-Adresse",
	"Ada",
	"Greenville",
	"Kantunilkín",
	"Molochnoye",
	"Monifieth",
	"Ayotoxco de Guerrero",
	"Kirundo",
	"Lincoln Village",
	"Haapsalu",
	"Gorleston-on-Sea",
	"Penryn",
	"Mellieħa",
	"Trat",
	"Richmond Heights",
	"Sarnen",
	"Rocafort",
	"Ondárroa",
	"Phangnga",
	"Jacinto City",
	"Boussy-Saint-Antoine",
	"Church",
	"Anstey",
	"Siparia",
	"Ordubad",
	"Lascano",
	"Wickersley",
	"Olaine",
	"Oulad Ben Sebbah",
	"Cromer",
	"East Whittier",
	"Butha-Buthe",
	"Mariglianella",
	"Naftalan",
	"Altdorf",
	"Wistaston",
	"Carbon-Blanc",
	"Boulder Hill",
	"La Verrière",
	"Cramond",
	"Roatán",
	"Lake Stickney",
	"Great Neck",
	"Almácera",
	"West Haverstraw",
	"Bound Brook",
	"Birao",
	"Kirkham",
	"La Ville-du-Bois",
	"Biržai",
	"Puebla de Farnals",
	"Buctzotz",
	"Radlett",
	"Citrus",
	"Nisporeni",
	"Kuldīga",
	"Fontaines-sur-Saône",
	"Vrnjačka Banja",
	"Recale",
	"Godmanchester",
	"Clowne",
	"Mojkovac",
	"Villemoisson-sur-Orge",
	"Ħamrun",
	"Muyinga",
	"Warrenpoint",
	"Coppull",
	"Crieff",
	"San Francisco del Mar",
	"Tarīn Kōṯ",
	"Laghdir",
	"Charter Oak",
	"Bahía Kino",
	"Hucknall under Huthwaite",
	"Altar",
	"Capitola",
	"Castillos",
	"Kanjiža",
	"Del Aire",
	"Tamazulapam Villa del Progreso",
	"Rovellasca",
	"Country Club",
	"Frampton Cotterell",
	"La Mulatière",
	"Timberlane",
	"Montlhéry",
	"Whetstone",
	"Thrapston",
	"Watervliet",
	"Inwood",
	"White City",
	"Bauska",
	"Celestún",
	"Huiloapan",
	"Marina del Rey",
	"Hopelchén",
	"Stonegate",
	"Duntocher",
	"West Perrine",
	"Aintree",
	"Talange",
	"East Rockaway",
	"New Hyde Park",
	"Nueva Ocotepeque",
	"Brookdale",
	"Westgate",
	"Cimitile",
	"Huningue",
	"Partington",
	"Jurbarkas",
	"Novobërdë",
	"Pájaros",
	"Makamba",
	"West Athens",
	"Saldus",
	"Oudorp",
	"Highbridge",
	"Pembroke",
	"Senglea",
	"Tshabong",
	"Thames Ditton",
	"Brechin",
	"Djambala",
	"Oberengstringen",
	"Pietà",
	"Postojna",
	"Aiea",
	"Keansburg",
	"Llantwit Fardre",
	"Maywood",
	"Barrowford",
	"Pedra Badejo",
	"Littleborough",
	"Sherburn in Elmet",
	"Kundiawa",
	"Dinas Powys",
	"Ikeja",
	"Barlassina",
	"Withernsea",
	"Wadsley",
	"Wardle",
	"Dancu",
	"Teaoraereke",
	"Bois-des-Filion",
	"Vilkaviškis",
	"Sathonay-Camp",
	"Ojo Caliente",
	"Govan",
	"Rožaje",
	"Roda del Ter",
	"Carnate",
	"Hani i Elezit",
	"Bladensburg",
	"Thyolo",
	"Vrhnika",
	"Temascal",
	"Kirriemuir",
	"Porth",
	"Tettenhall",
	"Le Port-Marly",
	"Harlescott",
	"Rîşcani",
	"Long Ditton",
	"Sawston",
	"Twyford",
	"Wood-Ridge",
	"Isola delle Femmine",
	"Ashbourne",
	"Las Tablas",
	"Brentwood",
	"Cockenzie",
	"Kasane",
	"Katakwi",
	"Locharbriggs",
	"Neulußheim",
	"Alcester",
	"Kasakh",
	"Evinayong",
	"Žabalj",
	"Woodlyn",
	"South Amboy",
	"Sabana Grande",
	"Dursley",
	"Stepps",
	"Lunca Cetăţuii",
	"Lerwick",
	"Gorle",
	"Sauce",
	"Grosuplje",
	"Stans",
	"Saltburn-by-the-Sea",
	"Billinge",
	"San Pedro Amuzgos",
	"Dangriga",
	"Bennebroek",
	"Beuvrages",
	"Guingamp",
	"Broughton",
	"Ewo",
	"Filey",
	"Manatuto",
	"Odžaci",
	"Leisure World",
	"Cove",
	"North Kensington",
	"Leonia",
	"Gizo",
	"Stansted Mountfitchet",
	"Farmingdale",
	"Grenay",
	"Plainedge",
	"Stony Brook University",
	"Bottmingen",
	"Lexden",
	"Sremski Karlovci",
	"Folsom",
	"Temozon",
	"Gleno",
	"Sauchie",
	"Budleigh Salterton",
	"Long Ashton",
	"Mokhotlong",
	"Talsi",
	"Cheddar",
	"Pontardawe",
	"Dayr al Jarnūs",
	"Corozal",
	"Dore",
	"North Petherton",
	"Egham",
	"Collingdale",
	"Siġġiewi",
	"Pontardulais",
	"Joniškis",
	"Eichwalde",
	"Frimley",
	"Leona Vicario",
	"West Miami",
	"New Square",
	"Dobele",
	"Basco",
	"Sidi Bou Saïd",
	"Glodeni",
	"Karnaukhivka",
	"Seven Corners",
	"Kočevje",
	"Bécal",
	"Great Wakering",
	"Greenbriar",
	"Paide",
	"Coral Hills",
	"Santo Domingo Este",
	"Griffithstown",
	"Aleg",
	"Lerik",
	"Littlemore",
	"Swissvale",
	"Audubon",
	"Heage",
	"Jœuf",
	"Handforth",
	"Galleywood",
	"Albiate",
	"Harleston",
	"Rolle",
	"Xochitlan Todos Santos",
	"San Antonino Castillo Velasco",
	"Basarabeasca",
	"Zərdab",
	"Tadcaster",
	"Anykščiai",
	"Great Meols",
	"Varėna",
	"Malverne",
	"Prienai",
	"Krško",
	"Amble",
	"Wotton-under-Edge",
	"Almè",
	"August",
	"IJsselmonde",
	"Paulton",
	"Stotfold",
	"Palavas-les-Flots",
	"Balloch",
	"Esbly",
	"Brokopondo",
	"Midway City",
	"Hoylake",
	"Bogota",
	"Garden City Park",
	"Grandview Heights",
	"Harwood Heights",
	"Puerto San Carlos",
	"Sorø",
	"Haledon",
	"Dormont",
	"Arlesey",
	"Alondra Park",
	"Wincanton",
	"Tayoltita",
	"Tetbury",
	"Balakən",
	"Four Corners",
	"Slovenska Bistrica",
	"Teabo",
	"Ormesby",
	"Hengoed",
	"Pont-de-Chéruy",
	"Cheviot",
	"Brynmawr",
	"Greymouth",
	"Broadview Park",
	"Magor",
	"Beočin",
	"South San Gabriel",
	"Qobustan",
	"Soroti",
	"Piste",
	"Préverenges",
	"Mount Rainier",
	"Maplewood",
	"Algodones",
	"Port Maria",
	"Guadalupe y Calvo",
	"Nangan",
	"Malmesbury",
	"North Bay Village",
	"Conshohocken",
	"Newent",
	"Bellevue",
	"Cospicua",
	"Calderitas",
	"Gerlafingen",
	"Panindícuaro",
	"Mtskheta",
	"Sokobanja",
	"Polmont",
	"Paloma Creek South",
	"Eygelshoven",
	"Panabá",
	"Elderslie",
	"Caldwell",
	"Litija",
	"Temple Hills",
	"San Juan Bautista",
	"Falmouth",
	"Riverside",
	"Maḩmūd-e Rāqī",
	"Drogenbos",
	"Koboko",
	"Glenside",
	"Bentiu",
	"Cájar",
	"Wilson",
	"Palo Alto",
	"Rio Bravo",
	"Soledad Etla",
	"Luba",
	"Sheriff Hill",
	"Guadalupe",
	"Pinchbeck",
	"Chickerell",
	"Boyes Hot Springs",
	"Champagne-au-Mont-d’Or",
	"Aračinovo",
	"Yardımlı",
	"Greifensee",
	"Fanwood",
	"Hoogkerk",
	"Holtville",
	"Yeghegnadzor",
	"Radstock",
	"East Franklin",
	"Baldwin Harbor",
	"Arafat",
	"Angostura",
	"Brežice",
	"Juncos",
	"Calumet Park",
	"Luquillo",
	"Glen Ridge",
	"Waterbeach",
	"Sen Monorom",
	"Brotton",
	"Nombre de Dios",
	"Balzan",
	"Kelmė",
	"Gurabo",
	"Leova",
	"Hatboro",
	"Ludza",
	"Tala",
	"Esperanza",
	"Ravne na Koroškem",
	"Goranboy",
	"Waikele",
	"Hillcrest",
	"Park City",
	"Oğuz",
	"Ta’ Xbiex",
	"Brayton",
	"Briceni",
	"Mongomo",
	"Ruyigi",
	"Montpelier",
	"Monaghan",
	"Ikšķile",
	"Sky Lake",
	"Frontera Corozal",
	"Cwmbach",
	"Sabana Seca",
	"Krāslava",
	"Slovenj Gradec",
	"Mobaye",
	"X-Cán",
	"Williston Park",
	"Marsa",
	"Teleneşti",
	"Hola",
	"Ādaži",
	"Naujoji Akmenė",
	"Chicxulub Puerto",
	"Dunellen",
	"Stompwijk",
	"Lapovo",
	"Glenolden",
	"Nyack",
	"Hampstead",
	"Aizkraukle",
	"Donduşeni",
	"San Lorenzo",
	"Nakasongola",
	"Ajdovščina",
	"Souderton",
	"Yabucoa",
	"Ridley Park",
	"Līvāni",
	"San Isidro",
	"Great Neck Plaza",
	"Berovo",
	"Trindade",
	"Orland Hills",
	"Gulbene",
	"Kratovo",
	"Stratford",
	"Limbaži",
	"Luqa",
	"Mahibadhoo",
	"Twin Rivers",
	"Las Piedras",
	"Bački Petrovac",
	"Madona",
	"Hewlett",
	"Criuleni",
	"Eydhafushi",
	"South Valley Stream",
	"Diekirch",
	"Sopište",
	"Šalčininkai",
	"Clifton Heights",
	"Lauderdale-by-the-Sea",
	"Notre Dame",
	"Opuwo",
	"Cedarhurst",
	"Folcroft",
	"Guadalupe",
	"Manorhaven",
	"Puerto Real",
	"Ewa Villages",
	"South Monrovia Island",
	"Contra Costa Centre",
	"Bogovinje",
	"Tuckahoe",
	"Alūksne",
	"Mae Hong Son",
	"Puerto Baquerizo Moreno",
	"Castroville",
	"West View",
	"Naples Manor",
	"Bogatić",
	"Prospect Park",
	"Ambler",
	"Forest Glen",
	"Bronxville",
	"Kingston Estates",
	"Pasvalys",
	"Sal Rei",
	"Jõgeva",
	"Cəbrayıl",
	"Ghanzi",
	"Põlva",
	"Vittoriosa",
	"Ciudad Guzmán",
	"Cameron Park",
	"Saranap",
	"Schaan",
	"Espargos",
	"Kennett Square",
	"Junik",
	"Preiļi",
	"Siteki",
	"Viqueque",
	"Lucea",
	"Rapla",
	"West Loch Estate",
	"Lakeview",
	"Binghamton University",
	"Glarus",
	"Rutana",
	"Fruitridge Pocket",
	"Bač",
	"Larchmont",
	"Bogdanci",
	"Arroyo",
	"Pleasure Point",
	"Zarasai",
	"Sežana",
	"Zagorje",
	"District Heights",
	"Naval Academy",
	"Muramvya",
	"Lielvārde",
	"Studeničani",
	"Savannah",
	"Għaxaq",
	"Penn Wynne",
	"Echternach",
	"Kupiškis",
	"Črnomelj",
	"Appenzell",
	"Idrija",
	"Norwood",
	"Jamesburg",
	"Ranillug",
	"Mayflower Village",
	"Elsmere",
	"Lija",
	"Prevalje",
	"McKees Rocks",
	"Balvi",
	"Charlemagne",
	"Prospect Park",
	"Outapi",
	"Cidra",
	"Lorengau",
	"Hrastnik",
	"Plymouth",
	"Bay Harbor Islands",
	"Tevragh Zeina",
	"White City",
	"Iecava",
	"Mamushë",
	"Fulin",
	"Mount Carmel",
	"Bellevue",
	"South Tucson",
	"Lewisburg",
	"Gospić",
	"Sisimiut",
	"Cacheu",
	"Sharon Hill",
	"Media",
	"New Brighton",
	"Kerema",
	"Surfside",
	"Rochelle Park",
	"Partesh",
	"Salavan",
	"Deer Park",
	"Kazlų Rūda",
	"Qubadlı",
	"Kruševo",
	"Belmar",
	"Caazapá",
	"Shaw Heights",
	"Palm Springs North",
	"Molėtai",
	"Thaba-Tseka",
	"Waipio Acres",
	"Qal‘ah-ye Now",
	"South Highpoint",
	"Xagħra",
	"Friendship Heights Village",
	"Kaser",
	"Añasco",
	"L’Epiphanie",
	"Kalangala",
	"Edgewater",
	"Palé",
	"Kensington",
	"Shillington",
	"Ros Comáin",
	"Albina",
	"Wangdue Phodrang",
	"Santa Isabel",
	"Pembroke",
	"Albertson",
	"Highwood",
	"Ķekava",
	"Cutler",
	"Naguabo",
	"Slovenske Konjice",
	"Twin Lakes",
	"Kanifing",
	"Broadmoor",
	"Liquiçá",
	"Smiltene",
	"Bret Harte",
	"Peñuelas",
	"Glendale",
	"Turtle Creek",
	"Nadur",
	"Carle Place",
	"Gədəbəy",
	"Kenneth City",
	"Tara Hills",
	"Skuodas",
	"South Pasadena",
	"Ilulissat",
	"Montréal-Ouest",
	"Vrapčište",
	"Salinas",
	"Žalec",
	"Mechanicville",
	"Punta Gorda",
	"Punakha",
	"Šakiai",
	"Samtse",
	"Aībak",
	"Bled",
	"Mauren",
	"Iklin",
	"Šentjur",
	"Floriana",
	"Kiambu",
	"Nieuw Amsterdam",
	"Carnikava",
	"Bolama",
	"Eschen",
	"Irig",
	"Puerto Lempira",
	"Klaksvík",
	"Kuala Belait",
	"Bururi",
	"Ta Khmau",
	"Čoka",
	"Kalkara",
	"Hưng Yên",
	"Šilalė",
	"Pakruojis",
	"Bradford",
	"Imqabba",
	"Bueng Kan",
	"Sevnica",
	"San Ġwann",
	"Valka",
	"Remich",
	"Rumphi",
	"Dravograd",
	"Valandovo",
	"Şuşa",
	"Trakai",
	"Radlje ob Dravi",
	"Šempeter pri Gorici",
	"Black River",
	"Tecax",
	"Ilirska Bistrica",
	"Ozolnieki",
	"Gudja",
	"Cerknica",
	"Marsaxlokk",
	"Laško",
	"Ruše",
	"Želino",
	"Dingli",
	"Calheta de São Miguel",
	"Bensonville",
	"Kirkop",
	"Grevenmacher",
	"Tearce",
	"Għargħur",
	"Žiri",
	"Gornja Radgona",
	"San Ġiljan",
	"Švenčionys",
	"Aizpute",
	"Wabag",
	"Xewkija",
	"Trebnje",
	"Ağdam",
	"Kärdla",
	"Bugiri",
	"Kaberamaido",
	"Piran",
	"Kalvarija",
	"Makedonski Brod",
	"Janjanbureh",
	"Cankuzo",
	"Piņķi",
	"Moroto",
	"Gambēla",
	"Baldone",
	"Massenya",
	"Imġarr",
	"Lazdijai",
	"Ankaran",
	"Grobiņa",
	"Thinadhoo",
	"Imtarfa",
	"Funadhoo",
	"Cantemir",
	"Xgħajra",
	"Alibunar",
	"Demir Kapija",
	"Kandava",
	"Aasiaat",
	"Qrendi",
	"Lenart v Slovenskih Goricah",
	"Ulbroka",
	"Mežica",
	"Groningen",
	"Pehčevo",
	"Metlika",
	"Qaqortoq",
	"Saulkrasti",
	"Louang Namtha",
	"Għajnsielem",
	"Kaabong",
	"Kulhudhuffushi",
	"Rietavas",
	"Mullingar",
	"Porto Inglês",
	"Cambridge",
	"Borovnica",
	"Safi",
	"Lethem",
	"Dhihdhoo",
	"Balaka",
	"Mabaruma",
	"Mandalgovĭ",
	"Lendava",
	"Vojnik",
	"Brvenica",
	"Kudahuvadhoo",
	"Pļaviņas",
	"Kapchorwa",
	"São Domingos",
	"Arima",
	"Novo Selo",
	"Rasdhoo",
	"Kerewan",
	"Brocēni",
	"Petrovec",
	"Kolonia",
	"Viļāni",
	"Vevčani",
	"Al Wakrah",
	"Tallaght",
	"Lipkovo",
	"Barclayville",
	"Rūjiena",
	"Triesenberg",
	"Sannat",
	"Qala",
	"Kllokot",
	"Cestos City",
	"Tofol",
	"Bundibugyo",
	"Demir Hisar",
	"Ruggell",
	"Salacgrīva",
	"Plasnica",
	"Koknese",
	"Rosoman",
	"Garoowe",
	"Mogila",
	"Fuerte Olimpo",
	"Beltinci",
	"Skrīveri",
	"Krivogaštani",
	"Banlung",
	"Phủ Lý",
	"Nida",
	"Wicklow",
	"Zrnovci",
	"Birštonas",
	"Naklo",
	"Kerċem",
	"Laçın",
	"Thulusdhoo",
	"Clonmel",
	"Wexford",
	"Auce",
	"Vasilevo",
	"Gradsko",
	"Marka",
	"Priekuļi",
	"Viligili",
	"Cidade Velha",
	"Ilūkste",
	"Roja",
	"Chiradzulu",
	"Victoria",
	"Bariadi",
	"Ķegums",
	"Onverwacht",
	"Same",
	"Hoima",
	"Geita",
	"Garkalne",
	"Vipava",
	"Vecumnieki",
	"Inčukalns",
	"Ambrolauri",
	"Radeče",
	"Kabarnet",
	"Jagodina",
	"Mukono",
	"Ormož",
	"Vuzenica",
	"Munxar",
	"Migori",
	"Žabljak",
	"Gamprin",
	"Kārsava",
	"Kerugoya",
	"Yumbe",
	"Fontana",
	"Skrunda",
	"Dagda",
	"Paola",
	"Sihanoukville",
	"Buchanan",
	"Għarb",
	"Odranci",
	"Priekule",
	"La Palma",
	"Hithadhoo",
	"Bosilovo",
	"Mālpils",
	"Vĩnh Yên",
	"Dar Naim",
	"Jaunjelgava",
	"Iten",
	"Veymandoo",
	"Šentjernej",
	"Phalombe",
	"Varakļāni",
	"Nyamira",
	"Ērgļi",
	"Machinga",
	"Totness",
	"Longford",
	"Ropaži",
	"Auki",
	"Trim",
	"Felidhoo",
	"Aioun",
	"Xuddur",
	"Kasaali",
	"Šabac",
	"Bovec",
	"Nilandhoo",
	"Kirakira",
	"Vaiņode",
	"Carrick on Shannon",
	"Viesīte",
	"Staro Nagoričane",
	"Lubāna",
	"Foammulah",
	"Bu’aale",
	"Tullamore",
	"Fomboni",
	"Rankovce",
	"Kurunegala",
	"Xocalı",
	"Oshakati",
	"Murang’a",
	"Novaci",
	"Dundaga",
	"Schellenberg",
	"Bududa",
	"Żebbuġ",
	"Santo António",
	"Konče",
	"Mērsrags",
	"Sotik Post",
	"Obleševo",
	"Jegunovce",
	"Vreed-en-Hoop",
	"Zilupe",
	"Escaldes-Engordany",
	"Gornji Milanovac",
	"Tam Kỳ",
	"Cesvaine",
	"Radovljica",
	"Rauna",
	"Viļaka",
	"Weno",
	"Taro",
	"Oranjestad",
	"Belčišta",
	"Naxxar",
	"Trbovlje",
	"Ragana",
	"Muli",
	"Ar Rayyān",
	"Wakiso",
	"Mazsalaca",
	"Ivančna Gorica",
	"Rogaška Slatina",
	"Lyantonde",
	"Aloja",
	"Aïn Temouchent",
	"Serravalle",
	"Kobarid",
	"‘Amrān",
	"Halba",
	"Andrijevica",
	"Nenagh",
	"Līgatne",
	"Aknīste",
	"Nereta",
	"Chikwawa",
	"Strenči",
	"Kasungu",
	"Labuan",
	"Lata",
	"Nīca",
	"Požega",
	"Lozovo",
	"Kocēni",
	"Picos",
	"San Lawrenz",
	"An Cabhán",
	"Dolneni",
	"Fonadhoo",
	"Žirovnica",
	"Manadhoo",
	"Ponta do Sol",
	"Krupanj",
	"Lebane",
	"Štore",
	"Jaunpiebalga",
	"Siaya",
	"Rostuša",
	"Cerklje na Gorenjskem",
	"Isale",
	"Šoštanj",
	"Điện Biên Phủ",
	"P’yŏngsŏng",
	"Pāvilosta",
	"Centar Župa",
	"Aglona",
	"Scarborough",
	"Diego Martin",
	"Vladičin Han",
	"Ape",
	"Jaunpils",
	"Knjaževac",
	"Ljubovija",
	"Smederevska Palanka",
	"Zelenikovo",
	"Čučer-Sandevo",
	"Imdina",
	"Mahonda",
	"Eenhana",
	"Mali Zvornik",
	"Point Fortin",
	"Kostanjevica na Krki",
	"Port Laoise",
	"Surdulica",
	"Požarevac",
	"Širvintos",
	"Mayuge",
	"Varaždin",
	"Zaječar",
	"Lakatoro",
	"Dowa",
	"Hvalba",
	"Castlebar",
	"Aïn Defla",
	"Riebiņi",
	"Mādabā",
	"Sélibaby",
	"Asaba",
	"Prebold",
	"Tulagi",
	"Pilsrundāle",
	"Miklavž na Dravskem Polju",
	"Aranguez",
	"Alsunga",
	"Karbinci",
	"Bangar",
	"Mozirje",
	"Rumonge",
	"Paraćin",
	"Naukšēni",
	"Bulambuli",
	"Borgo Maggiore",
	"Maracha",
	"Luwero",
	"Kapenguria",
	"Smārde",
	"Polzela",
	"The Bottom",
	"Yenagoa",
	"Namayingo",
	"Lučani",
	"Suong",
	"Għasri",
	"Gllogovc",
	"Smederevo",
	"Đồng Xoài",
	"Mila",
	"Al Buraymī",
	"Đà Lạt",
	"Luuka Town",
	"Kabugao",
	"Planken",
	"Spodnje Hoče",
	"Selnica ob Dravi",
	"Aleksinac",
	"Despotovac",
	"Jincheng",
	"Šmartno",
	"Cibitoke",
	"Medvode",
	"Kibuku",
	"Bupoto",
	"Lai Châu",
	"Neno",
	"Moravče",
	"Apac",
	"Rucava",
	"Kladovo",
	"Skopun",
	"Kanal",
	"Rugāji",
	"Sankt Pölten",
	"Gornji Petrovci",
	"Nazarje",
	"Mengeš",
	"Zreče",
	"Preddvor",
	"Rubirizi",
	"Vwawa",
	"Pirot",
	"Domagnano",
	"Vecpiebalga",
	"Busesa",
	"Poljčane",
	"Vlasotince",
	"Koani",
	"Dhuusamarreeb",
	"Šmarje",
	"Mūrmuiža",
	"Komenda",
	"Baltinava",
	"Fada",
	"Škofljica",
	"Crna Trava",
	"Brezovica",
	"Buala",
	"Buikwe",
	"Kagadi",
	"Burtnieki",
	"Muta",
	"Radenci",
	"Hvannasund",
	"Log",
	"Colonia",
	"Tišina",
	"Spodnji Duplek",
	"Bushenyi",
	"Lieģi",
	"Acquaviva",
	"Kon Tum",
	"Ash Shīḩānīyah",
	"Kaliro",
	"Ljutomer",
	"Prokuplje",
	"Batočina",
	"Afega",
	"Umm Şalāl ‘Alī",
	"Aileu",
	"Oplotnica",
	"Fiorentino",
	"Butebo",
	"Akjoujt",
	"Namutumba",
	"Debe",
	"Stalbe",
	"Star Dojran",
	"Miragoâne",
	"Lukovica",
	"Dogbo",
	"Šmartno",
	"Abakaliki",
	"Manafwa",
	"Dimitrovgrad",
	"Tērvete",
	"Šenčur",
	"Ibanda",
	"Mirna",
	"Rače",
	"Wote",
	"Mwatate",
	"Vitanje",
	"Doljevac",
	"Rukungiri",
	"Lifford",
	"Isangel",
	"Koprivnica",
	"Toftir",
	"Gombe",
	"Montegiardino",
	"Kyenjojo",
	"Porkeri",
	"‘Ajlūn",
	"Velika Plana",
	"Az̧ Z̧a‘āyin",
	"Al Jabīn",
	"Mitoma",
	"Kalungu",
	"Svilajnac",
	"Aḑ Ḑāli‘",
	"Santa Cruz",
	"Santa Luċija",
	"Majšperk",
	"Bukomansimbi",
	"Svrljig",
	"Horjul",
	"Luče",
	"Spodnja Hajdina",
	"Nelspruit",
	"Šid",
	"Negotin",
	"Kajiado",
	"Vodice",
	"Rubanda",
	"Waitangi",
	"Dapaong",
	"Pivka",
	"Braslovče",
	"Butaleja",
	"Sakété",
	"Tivat",
	"Lovrenc na Pohorju",
	"Črna na Koroškem",
	"Pesnica",
	"Serere",
	"Amuria",
	"Kenge",
	"Nsiika",
	"Bojnik",
	"Kibiito",
	"Kibingo",
	"Capellen",
	"Bukedea",
	"Dobrna",
	"Samraong",
	"Ntara",
	"Wiltz",
	"Binyin",
	"Šentilj",
	"Kaédi",
	"Sārī",
	"Vransko",
	"Šentrupert",
	"Mparo",
	"Bistrica ob Sotli",
	"Kinoni",
	"Ol Kalou",
	"Logatec",
	"Kole",
	"Tržič",
	"Żebbuġ",
	"Bohinjska Bistrica",
	"Zombo",
	"Narok",
	"Rakai",
	"Encamp",
	"Lwengo",
	"Trzin",
	"Rečica",
	"Heydərabad",
	"Gorišnica",
	"Bukwo",
	"Omuthiya",
	"Volčja Draga",
	"Kasanda",
	"Loja",
	"Dokolo",
	"Dornava",
	"Leulumoega",
	"Koronadal",
	"Topola",
	"Balzers",
	"Kamwenge",
	"Železniki",
	"Ngora",
	"Kara",
	"Dol",
	"Ig",
	"Techiman",
	"Guadalupe",
	"Alebtong",
	"Brčko",
	"Gorenja Vas",
	"Pante Macassar",
	"Cerkno",
	"Suai",
	"Lospalos",
	"Stari Trg",
	"Chiesanuova",
	"Sveti Jurij",
	"Pombas",
	"Videm",
	"Kanungu",
	"Ljubno",
	"Straža",
	"Kanoni",
	"Kokopo",
	"Sejong",
	"Ramotswa",
	"Mulifanua",
	"Šmarješke Toplice",
	"Al ‘Azīzīyah",
	"Kidričevo",
	"Semič",
	"Oyam",
	"Požega",
	"Ilinden",
	"Starše",
	"Dobrovo",
	"Turnišče",
	"Yamaguchi",
	"Gia Nghĩa",
	"Blace",
	"La Massana",
	"Tabor",
	"Saltangará",
	"Križevci",
	"Tutong",
	"Sembabule",
	"Vianden",
	"Tsirang",
	"Kakumiro",
	"Pailin",
	"Sveta Trojica v Slovenskih Goricah",
	"Nkurenkuru",
	"Sveta Ana",
	"Tolmin",
	"Rabak",
	"Princes Town",
	"Tarrafal",
	"Triesen",
	"Benedikt",
	"Cibla",
	"Sant Julià de Lòria",
	"Faetano",
	"Buyende",
	"João Teves",
	"Santana",
	"Kalaki",
	"Vecvārkava",
	"Kwale",
	"Isingiro",
	"Apatin",
	"Kyegegwa",
	"Sefwi Wiawso",
	"Mersch",
	"Ucar",
	"Dolenjske Toplice",
	"Hongseong",
	"Strendur",
	"Tuzi",
	"Veržej",
	"Zgornja Kungota",
	"Muan",
	"Črenšovci",
	"Mirna Peč",
	"Saratamata",
	"Kanchanaburi",
	"Rumuruti",
	"Arilje",
	"Kovin",
	"Butalangu",
	"Boljevac",
	"San José de las Lajas",
	"Markovci",
	"Žužemberk",
	"Phôn-Hông",
	"Kosjerić",
	"Tvøroyri",
	"Dobrova",
	"Amolatar",
	"Lelydorp",
	"Velike Lašče",
	"Puconci",
	"Morant Bay",
	"Škocjan",
	"Drabeši",
	"Ćićevac",
	"Mokronog",
	"Bakjagol",
	"Plandište",
	"Kozina",
	"Velika Polana",
	"Cankova",
	"Središče ob Dravi",
	"Lamwo",
	"Bangolo",
	"Rogašovci",
	"Qazax",
	"Varvarin",
	"Ražanj",
	"San Jose",
	"Īlām",
	"Mongar",
	"Godāwari̇̄",
	"Mionica",
	"Kurumul",
	"Aranđelovac",
	"Kula",
	"Nova Vas",
	"Apače",
	"Cerkvenjak",
	"Haa",
	"Krapina",
	"Palenga",
	"Singa",
	"Novi Bečej",
	"Bečej",
	"Kuzma",
	"Şərur",
	"Juršinci",
	"Port Loko",
	"Gornji Grad",
	"Žitorađa",
	"Videm pri Ptuju",
	"Kotido",
	"Titel",
	"Pul-e ‘Alam",
	"Cirkulane",
	"Ribnica",
	"Miren",
	"Sharan",
	"Boorama",
	"Tari",
	"Zavrč",
	"Cocieri",
	"Pader",
	"Merošina",
	"Fuglafjørður",
	"Fámjin",
	"Lufilufi",
	"Podlehnik",
	"Kotor",
	"Stara Pazova",
	"Clervaux",
	"Redange-sur-Attert",
	"Goaso",
	"Dambai",
	"Igreja",
	"Kaffrine",
	"Ribnica",
	"Zgornje Gorje",
	"Tomaž pri Ormožu",
	"Sangre Grande",
	"Gaoua",
	"Nhlangano",
	"Ordino",
	"Bosilegrad",
	"Knić",
	"Canillo",
	"Agago",
	"Fort Wellington",
	"Vitomarci",
	"Nova Sintra",
	"Şuḩār",
	"Komen",
	"Sečanj",
	"Nalerigu",
	"Divača",
	"Destrnik",
	"Ocniţa",
	"Kozje",
	"Nīlī",
	"Bulisa",
	"Phônsavan",
	"Amudat",
	"Jurovski Dol",
	"Pagėgiai",
	"Kovačica",
	"Nabilatuk",
	"Kralendijk",
	"Pakxan",
	"Dobje",
	"Şoldăneşti",
	"Samdrup Jongkhar",
	"Moravske-Toplice",
	"Podvelka",
	"Trnovska Vas",
	"Sarpang",
	"Makole",
	"Grad",
	"Trashigang",
	"Qəbələ",
	"Neves",
	"Medveđa",
	"Napak",
	"Vágur",
	"Pemagatshel",
	"Cova Figueira",
	"Žitište",
	"Sofifi",
	"Razkrižje",
	"Rustavi",
	"Čajetina",
	"Rogatec",
	"Qacha’s Nek",
	"Sremska Mitrovica",
	"Ştefan Vodă",
	"Safotulafai",
	"Ivanjica",
	"Kuacjok",
	"Sédhiou",
	"Tubmanburg",
	"Inđija",
	"Žabari",
	"Ed Daein",
	"Tabuk",
	"Osilnica",
	"Petrovac na Mlavi",
	"Pazin",
	"Savalou",
	"Mali Iđoš",
	"Ub",
	"Rača",
	"Srbobran",
	"Ljig",
	"Kiryandongo",
	"Rekovac",
	"Mamuju",
	"Hargeysa",
	"Kiruhura",
	"Leava",
	"Novi Kneževac",
	"Djibloho",
	"Tsimasham",
	"Lajkovac",
	"Madīnat ash Shamāl",
	"Bāzārak",
	"Aleksandrovac",
	"Petnjica",
	"Sodražica",
	"Samux",
	"Dobrovnik",
	"Pala",
	"Matam",
	"Abim",
	"Dalandzadgad",
	"Rio Claro",
	"Malo Crniće",
	"Kuršumlija",
	"Ntoroko",
	"Dəvəçi",
	"Raška",
	"Mislinja",
	"Golubac",
	"Norðragøta",
	"Pećinci",
	"Qıvraq",
	"Nova Crnja",
	"Podčetrtek",
	"Samamea",
	"Xai",
	"Ar Rustāq",
	"Majdanpek",
	"Danilovgrad",
	"Buka",
	"Makedonska Kamenica",
	"Satupa‘itea",
	"Otuke",
	"Ruma",
	"Asau",
	"Hrib-Loški Potok",
	"Nikšić",
	"Şūr",
	"Safotu",
	"Kəlbəcər",
	"Kobilje",
	"Vladimirci",
	"Vailoa",
	"Kranjska Gora",
	"Amuru",
	"Buabidi",
	"Gusinje",
	"Ribeira Brava",
	"Ibrā’",
	"Žetale",
	"Tutin",
	"Resen",
	"Babušnica",
	"Xocavənd",
	"Sørvágur",
	"Kaišiadorys",
	"Brus",
	"Şahbuz",
	"Eiði",
	"Massakory",
	"Šalovci",
	"Osečina",
	"Buba",
	"Al Ḩazm",
	"Nakapiripirit",
	"Barentu",
	"Veliko Gradište",
	"Pili",
	"Burco",
	"Kučevo",
	"Koceljeva",
	"Oyrarbakki",
	"Arta",
	"Temerin",
	"Vestmanna",
	"Raseiniai",
	"Gadžin Han",
	"Gaigirgordub",
	"Opovo",
	"Kyankwanzi",
	"São João dos Angolares",
	"Ntchisi",
	"Xékong",
	"Trashi Yangtse",
	"Sumba",
	"Kvívík",
	"Solčava",
	"Koné",
	"Zalingei",
	"Sandavágur",
	"Hodoš",
	"Hov",
	"Sala",
	"Nwoya",
	"Saleaula",
	"Žagubica",
	"Trgovište",
	"Sola",
	"Semera",
	"Ignalina",
	"Viðareiði",
	"Änew",
	"Masunga",
	"Kostel",
	"Xızı",
	"Trongsa",
	"Sandur",
	"El Fula",
	"Zhemgang",
	"Porto Novo",
	"Damongo",
	"Tanjung Selor",
	"Tigoa",
	"Lupane",
	"Pārūn",
	"Kolašin",
	"Fish Town",
	"Bopolu",
	"Anouvông",
	"Loango",
	"Laascaanood",
	"Húsavík",
	"Georgetown",
	"Amdjarass",
	"Lhuentse",
	"Jakar",
	"Nova Varoš",
	"Ceerigaabo",
	"Skálavík",
	"Kunoy",
	"Daga",
	"Bela Palanka",
	"Kirkja",
	"Sowa Town",
	"Zgornje Jezersko",
	"Šavnik",
	"Skúvoy",
	"Plužine",
	"Choyr",
	"Edinburgh of the Seven Seas",
	"Awbārī",
	"Gasa",
	"Idrī",
	"Jwaneng",
	"Mahdia",
	"Haymā’",
	"Unión Chocó",
	"Bardaï",
	"Al Kufrah",
	"Wé",
	"Preševo",
	"Bujanovac",
	"Kitamilo",
	"Xiongzhou",
	"Udine",
	"Kalisz",
	"Legnica",
	"Kani",
	"Debre Zeyit",
	"Xiegang",
	"Tiaong",
	"Chikusei",
	"Tayabas",
	"Andria",
	"Roquetas de Mar",
	"Arlit",
	"Fengning",
	"Deventer",
	"Urgut Shahri",
	"Boca Raton",
	"Bendigo",
	"Arezzo",
	"Sindangan",
	"Lafayette",
	"Leme",
	"Zelënodol’sk",
	"Hasilpur",
	"Włocławek",
	"Sakata",
	"Lee's Summit",
	"Tobolsk",
	"Rio Rancho",
	"South Fulton",
	"Chía",
	"Beaverton",
	"Sarapul",
	"Taxco de Alarcón",
	"Tacurong",
	"Es Senia",
	"Kontagora",
	"Lugo",
	"Khambhāt",
	"Lawrence",
	"Zárate",
	"Reyhanlı",
	"Sabaneta",
	"Saku",
	"Worcester",
	"Votkinsk",
	"Paulínia",
	"Iseyin",
	"Colón",
	"Oldham",
	"Attock Khurd",
	"Witten",
	"Hammamet",
	"Serov",
	"Las Rozas de Madrid",
	"Middletown",
	"Bafra",
	"Brantford",
	"Hanau",
	"El Seibo",
	"Lysychansk",
	"Jamundí",
	"Cesena",
	"Itacoatiara",
	"Calasiao",
	"Ukhta",
	"Tomohon",
	"Buin",
	"Barra do Piraí",
	"Sutton Coldfield",
	"Iida",
	"Kāzerūn",
	"San Tan Valley",
	"San Marcos",
	"Wujiaqu",
	"Aurangābād",
	"Sandy",
	"Vanderbijlpark",
	"Leninsk-Kuznetskiy",
	"Longquan",
	"Kelo",
	"Mezhdurechensk",
	"Cáceres",
	"Federal Way",
	"Dovzhansk",
	"Guihulñgan",
	"Kamisu",
	"Longkeng",
	"Muzaffarabad",
	"Carmen",
	"Greece",
	"Mandeville",
	"Mons",
	"Jinbi",
	"Lecce",
	"Lingtang",
	"Xiluodu",
	"Hesperia",
	"Emmiganūr",
	"Brockton",
	"Pesaro",
	"Aliağa",
	"Campo Mourão",
	"Boli",
	"Lorca",
	"Yi Xian",
	"Guaíba",
	"Lopez",
	"Bogo",
	"Sarov",
	"M’lang",
	"Kŭlob",
	"Rubio",
	"Riverview",
	"Sultan Kudarat",
	"Fishers",
	"Mianwali",
	"San Rafael",
	"Jinshan",
	"Solikamsk",
	"Nāḩiyat al Karmah",
	"Bowling Green",
	"La Spezia",
	"Barletta",
	"Valongo",
	"Avignon",
	"Biu",
	"Monkayo",
	"San José del Cabo",
	"Pará de Minas",
	"Jaworzno",
	"San Cugat del Vallés",
	"Bislig",
	"Kanuma",
	"Gera",
	"Roswell",
	"Menifee",
	"Grudziądz",
	"Plantation",
	"Dover",
	"Escalante",
	"Gatchina",
	"Michurinsk",
	"Bayan Hot",
	"Alessandria",
	"Santa Cruz Xoxocotlán",
	"Glazov",
	"Portsmouth",
	"Chino",
	"Marmaris",
	"Cape Breton",
	"Kwekwe",
	"Azumino",
	"Wangjia",
	"Edmond",
	"Hanford",
	"Lemery",
	"Dearborn",
	"Menderes",
	"Paracatu",
	"Higashi-Matsuyama",
	"Yunnanyi",
	"Voskresensk",
	"Épinal",
	"Mikhaylovsk",
	"Livonia",
	"Hadera",
	"Paniqui",
	"Trelew",
	"Iserlohn",
	"Linquan",
	"Bafang",
	"Caldas Novas",
	"Teziutlan",
	"Vineland",
	"Florence",
	"Portsmouth",
	"Slidell",
	"Lawton",
	"Wukari",
	"Bauan",
	"Samālūţ",
	"Rafaela",
	"Velikiye Luki",
	"Hanamaki Onsen",
	"Missoula",
	"Hastings",
	"Kilosa",
	"Laiyuan",
	"Sorriso",
	"Auburn",
	"San Sebastián de los Reyes",
	"Foumban",
	"Rāyachoti",
	"Sampit",
	"Lethbridge",
	"Caratinga",
	"Rapid City",
	"Yumbo",
	"Grahamstown",
	"Kitakami",
	"Acajutla",
	"Terre Haute",
	"Saint-Jean-sur-Richelieu",
	"Vaciamadrid",
	"Toms River",
	"Mosquera",
	"Suffolk",
	"Clarington",
	"Kendu Bay",
	"Blacksburg",
	"Modi‘in Makkabbim Re‘ut",
	"Düren",
	"Pickering",
	"Flensburg",
	"Mount Pleasant",
	"Mogi Mirim",
	"Weiyuan",
	"Balayan",
	"Yao",
	"Peñaflor",
	"Votuporanga",
	"Caçapava",
	"Carson",
	"Tübingen",
	"Greenburgh",
	"São Félix do Xingu",
	"Jalalpur Jattan",
	"Cáceres",
	"Santa Cruz",
	"Timóteo",
	"Maladzyechna",
	"Conroe",
	"Alafaya",
	"Chauk",
	"Imizuchō",
	"Itajubá",
	"Livermore",
	"Playas de Rosarito",
	"Saquarema",
	"Mestre",
	"Ad Dakhla",
	"Planaltina",
	"Chililabombwe",
	"Palimbang",
	"Pisa",
	"Fundación",
	"Kāvali",
	"Pistoia",
	"São João del Rei",
	"Nanaimo",
	"Ma‘arrat an Nu‘mān",
	"Mancherāl",
	"Chalchuapa",
	"Southport",
	"Kansk",
	"Kiselëvsk",
	"Zwickau",
	"Hezuo",
	"Pili",
	"Alaminos",
	"New Braunfels",
	"Ocaña",
	"Mihara",
	"Oton",
	"Luau",
	"Kadiri",
	"Norrköping",
	"Gießen",
	"Hosaina",
	"Lucca",
	"Hilversum",
	"Santa Inês",
	"Leping",
	"Serdar",
	"Cantaura",
	"Kamensk-Shakhtinskiy",
	"Fall River",
	"Grimsby",
	"Chosica",
	"Prijedor",
	"Kabacan",
	"Dunkerque",
	"Parang",
	"El Puerto de Santa María",
	"Gitarama",
	"Albany",
	"São João da Boa Vista",
	"Shwebo",
	"Candeias",
	"Qiantangcun",
	"Salaman",
	"Halifax",
	"Paranavaí",
	"Polangui",
	"Bhakkar",
	"Norwalk",
	"Dongchuan",
	"Heerlen",
	"Montelíbano",
	"San Luis",
	"O'Fallon",
	"Ootacamund",
	"Victorias",
	"La Grita",
	"Sakiet ez Zit",
	"Launceston",
	"Newton",
	"Zhob",
	"Pingyuanjie",
	"Qal‘at Bīshah",
	"Tacaná",
	"Maiquetía",
	"Ratingen",
	"Gerona",
	"Changting",
	"Leshou",
	"Sinjār",
	"Sudbury",
	"São Gonçalo do Amarante",
	"Jataí",
	"Wislane",
	"Buzuluk",
	"Cacoal",
	"Muncie",
	"Jagüey Grande",
	"Heshangtian",
	"Santa Cruz do Capibaribe",
	"Lünen",
	"Dipalpur",
	"Compostela",
	"Ceylanpınar",
	"Pergamino",
	"Anakāpalle",
	"Fukuroi",
	"Gurupi",
	"Consolación del Sur",
	"Koidu-Bulma",
	"Balamban",
	"Hamilton",
	"Paredes",
	"Brindisi",
	"Town 'n' Country",
	"Mobara",
	"Tiflet",
	"Decatur",
	"Nova Lima",
	"Heyunkeng",
	"Wulan",
	"São Cristóvão",
	"Menglang",
	"Ad Diwem",
	"Jackson",
	"Fort Myers",
	"Qaraçuxur",
	"Chiguayante",
	"Campana",
	"Dingcheng",
	"Tuncheng",
	"Gubkin",
	"Goodyear",
	"Yaofeng",
	"Aalst",
	"Kattagan",
	"Paraíso",
	"Gotenba",
	"Novotroitsk",
	"Ducheng",
	"Kharian",
	"Keffi",
	"Pariaman",
	"Dalton",
	"Santa Rosa Jauregui",
	"Ubatuba",
	"San Antonio",
	"Clarkstown",
	"Treviso",
	"Guasavito",
	"Pinamalayan",
	"Cheektowaga",
	"Shaoshanzhan",
	"Aroroy",
	"Olavarría",
	"Konstanz",
	"Bryan",
	"Longchuan",
	"Zhezqazghan",
	"Khowrāsgān",
	"Bugulma",
	"Shchëkino",
	"Hinigaran",
	"Potiskum",
	"Waukegan",
	"Pongotan",
	"Longhua",
	"Anderson",
	"Kitakōriyamachō",
	"Mijas",
	"Ushiku",
	"North Vancouver",
	"Redwood City",
	"Chiquinquirá",
	"Guanambi",
	"Torrevieja",
	"Yeysk",
	"Sekimachi",
	"Hoover",
	"Cachoeira do Sul",
	"Brossard",
	"Chita",
	"Villingen-Schwenningen",
	"Sokcho",
	"São Bento do Sul",
	"Redenção",
	"Chiclana de la Frontera",
	"Kineshma",
	"Lake Forest",
	"Camboriú",
	"Daanbantayan",
	"Napa",
	"Derry",
	"Torrente",
	"Sumenep",
	"Luancheng",
	"Jelenia Góra",
	"Walvisbaai",
	"Moriyama",
	"Junín",
	"Stockton-on-Tees",
	"Korgas",
	"Repentigny",
	"Largo",
	"Bloomington",
	"Unaí",
	"Araripina",
	"Marl",
	"Gravatá",
	"Jacobina",
	"Arujá",
	"Guider",
	"Nabua",
	"Surallah",
	"Yokotemachi",
	"Sabanalarga",
	"Johns Creek",
	"Tire",
	"Newport Beach",
	"Dmitrov",
	"Serra Talhada",
	"Navegantes",
	"Banga",
	"Mission",
	"Chigorodó",
	"El Milia",
	"Cereté",
	"El Ejido",
	"Worms",
	"Makilala",
	"Troy",
	"Madera",
	"Joplin",
	"San Fabian",
	"Zheleznogorsk",
	"Pontevedra",
	"Cianorte",
	"Ijuí",
	"Chino Hills",
	"Camiling",
	"Pinheiro",
	"Sirsilla",
	"Arona",
	"Chilliwack",
	"Binmaley",
	"Yurga",
	"Calabanga",
	"Redditch",
	"Mian Channun",
	"Santa Barbara",
	"Wutiancun",
	"Santana do Livramento",
	"Tual",
	"São Sebastião",
	"Sablayan",
	"Moju",
	"Seropédica",
	"Wuyi",
	"Marsala",
	"Bhalwal",
	"Chaykovskiy",
	"Franklin",
	"Coari",
	"Velbert",
	"Melbourne",
	"Pozzuoli",
	"Al Ḩayy",
	"Port Huron",
	"Vélez-Málaga",
	"Xiedian",
	"Nakatsu",
	"Baggao",
	"Solana",
	"Colonie",
	"Shīrvān",
	"Springfield",
	"Kongjiazhuang",
	"Bhaktapur",
	"Guinobatan",
	"Fredrikstad",
	"Cruzeiro do Sul",
	"Dapitan",
	"Ōmihachiman",
	"Muroran",
	"Calaca",
	"Kaneyama",
	"Bolinao",
	"Patrocínio",
	"Minden",
	"Itá",
	"Zephyrhills",
	"Três Rios",
	"Bekobod",
	"Maple Ridge",
	"La Louvière",
	"Grosseto",
	"St. Augustine",
	"Peterborough",
	"Funza",
	"Hengkou",
	"Pilar",
	"Varese",
	"Caldas",
	"Science City of Muñoz",
	"Macaíba",
	"Ust’-Ilimsk",
	"Flagstaff",
	"Presidencia Roque Sáenz Peña",
	"Azov",
	"Luján",
	"Agboville",
	"Campo Limpo",
	"Quixeramobim",
	"Watampone",
	"Pleasanton",
	"Xindian",
	"Calauan",
	"Shuibian",
	"Anapa",
	"Kimitsu",
	"Matão",
	"Murcia",
	"Dessau-Roßlau",
	"General Roca",
	"Atascocita",
	"Banī Mazār",
	"Kentaū",
	"Maco",
	"Westland",
	"Auburn",
	"Cranston",
	"Gobernador Gálvez",
	"Kāmāreddipet",
	"Athi River",
	"Novouralsk",
	"Shiji",
	"Yanggao",
	"Indanan",
	"Folsom",
	"Springdale",
	"Taroudannt",
	"Sefrou",
	"Warwick",
	"Passi",
	"Los Patios",
	"Tanjay",
	"Polatsk",
	"Calatrava",
	"Meybod",
	"Tepeji del Río de Ocampo",
	"Akiruno",
	"Kilifi",
	"Quixadá",
	"Farmington Hills",
	"Neumünster",
	"Alfenas",
	"Mackay",
	"Norderstedt",
	"Necochea",
	"Pāloncha",
	"Talipao",
	"Newark",
	"Williamsburg",
	"Brooklyn Park",
	"Uvinza",
	"Kottagūdem",
	"Mbalmayo",
	"Namsan",
	"Zarzis",
	"Chapadinha",
	"Andahuaylas",
	"Hattiesburg",
	"Alexandria",
	"Três Corações",
	"Manresa",
	"Jamshoro",
	"Meïganga",
	"Tall ‘Afar",
	"Balad",
	"Vila do Conde",
	"Bantayan",
	"San Carlos del Zulia",
	"Natori-shi",
	"Koktokay",
	"Capenda Camulemba",
	"Fiumicino",
	"Pátzcuaro",
	"Florence",
	"Chester",
	"Mabai",
	"Ben Gardane",
	"Jinhe",
	"Baracoa",
	"Telêmaco Borba",
	"Valdosta",
	"Plymouth",
	"Yalta",
	"Cotuí",
	"Jiangna",
	"Cabiao",
	"Manhuaçu",
	"Torres Vedras",
	"Gyōda",
	"Buckeye",
	"Georgetown",
	"Hannō",
	"Nagua",
	"Pingyi",
	"Yenakiieve",
	"Matalam",
	"Iwamizawa",
	"Kadoma",
	"Tanuku",
	"Cedar Park",
	"Klin",
	"Ozërsk",
	"Villa María",
	"The Villages",
	"Kokomo",
	"Perris",
	"Echague",
	"Huaral",
	"St. Joseph",
	"Agua Prieta",
	"Pato Branco",
	"Mooka",
	"Flower Mound",
	"Pharr",
	"Sahuayo de Morelos",
	"Francisco Beltrão",
	"Maizuru",
	"Alton",
	"Mabinay",
	"Bamberg",
	"Hengbei",
	"Loveland",
	"Bogo",
	"Subulussalam",
	"Delmenhorst",
	"Essaouira",
	"El Carmen de Bolívar",
	"Tierralta",
	"Rockhampton",
	"Alīgūdarz",
	"Dondo",
	"Boynton Beach",
	"La Dorada",
	"Tela",
	"Picos",
	"Anderson",
	"Vyborg",
	"Manbij",
	"Dias d’Ávila",
	"Valdemoro",
	"Usol’ye-Sibirskoye",
	"San Jose",
	"Jonesboro",
	"Pattoki",
	"San Antonio",
	"Kropotkin",
	"Temoaya",
	"Maitland",
	"Arjona",
	"Chengbin",
	"Parma",
	"Bor",
	"Bodhan",
	"Chinautla",
	"Layton",
	"Balqash",
	"Villa Victoria",
	"Elizabethtown",
	"Béziers",
	"Texarkana",
	"Linkou",
	"Siaton",
	"Aquiraz",
	"Villa Canales",
	"Roosendaal",
	"Numan",
	"Ciudad de Huajuapam de León",
	"Viersen",
	"Bebedouro",
	"Guildford",
	"Sint-Niklaas",
	"Karūr",
	"Armant",
	"Alegrete",
	"Bakhmut",
	"Bulacan",
	"Marburg",
	"Buhi",
	"Söke",
	"Kankakee",
	"Funing",
	"Poblacion",
	"Ende",
	"Porterville",
	"Harunabad",
	"Gelendzhik",
	"Canindé",
	"Chernogorsk",
	"Balkh",
	"Baytown",
	"Upland",
	"Siedlce",
	"Nikkō",
	"Gandía",
	"Malapatan",
	"Dongguan",
	"Ryūgasaki",
	"Al Musayyib",
	"Pyapon",
	"Caserta",
	"Mafra",
	"Camarillo",
	"Sarqan",
	"Dschang",
	"Kahror Pakka",
	"Ait Ali",
	"Tuban",
	"Balashov",
	"Wilhelmshaven",
	"Shostka",
	"Asti",
	"Jablah",
	"E’erguna",
	"Rheine",
	"Turbaco",
	"Fouchana",
	"Fancheng",
	"South Jordan",
	"Palma Soriano",
	"Macabebe",
	"Stakhanov",
	"Bais",
	"Battle Creek",
	"Ibiúna",
	"Jose Abad Santos",
	"Venado Tuerto",
	"Sangolquí",
	"Anzhero-Sudzhensk",
	"Kapalong",
	"Santo Ângelo",
	"Houmt Souk",
	"Lívingston",
	"Mysłowice",
	"Daisen",
	"Berdychiv",
	"Itapetinga",
	"Lins",
	"San Marcos",
	"Espinal",
	"Lod",
	"Troisdorf",
	"Fukuchiyama",
	"Bauang",
	"Piotrków Trybunalski",
	"Tailai",
	"San Ramon",
	"Toba Tek Singh",
	"Jabuticabal",
	"Santa Catalina",
	"Rio Largo",
	"Ovalle",
	"Kengtung",
	"Xinglong",
	"Lake Jackson",
	"Pototan",
	"Bethlehem",
	"Alcalá de Guadaira",
	"Shadrinsk",
	"Bayreuth",
	"Zhongcheng",
	"Puqiancun",
	"Wyoming",
	"Dubna",
	"Nakatsugawa",
	"Batarasa",
	"Oshkosh",
	"Sapiranga",
	"Bonāb",
	"Hammond",
	"Libon",
	"Tiznit",
	"Waldorf",
	"Pagbilao",
	"Missouri City",
	"Lüneburg",
	"Pasco",
	"Mangalagiri",
	"Kawartha Lakes",
	"Bombo",
	"Wheeling",
	"Brick",
	"Acacías",
	"Kendall",
	"Īṭahari̇̄",
	"Concórdia",
	"Robles",
	"Valle Alto",
	"Standerton",
	"Taytay",
	"Tatebayashi",
	"Idah",
	"Dorsten",
	"Carmen",
	"Gela",
	"Dĩ An",
	"Rancho Cordova",
	"Arcoverde",
	"Godē",
	"Palma",
	"Tongye",
	"Lamitan",
	"Conway",
	"Wiwilí",
	"Gary",
	"Bunbury",
	"Pilar",
	"Castrop-Rauxel",
	"Altoona",
	"Samundri",
	"Lodi",
	"Arlington Heights",
	"Darhan",
	"Nizhyn",
	"San Francisco",
	"Piraçununga",
	"Burnley",
	"Copacabana",
	"San Juan Opico",
	"Grand-Bassam",
	"Bolingbrook",
	"Rochester Hills",
	"Barili",
	"Novoaltaysk",
	"Winchester",
	"Framingham",
	"Detmold",
	"Palmeira dos Índios",
	"Tatsunochō-tominaga",
	"Gumaca",
	"Çınar",
	"Mineral’nyye Vody",
	"Kara-Balta",
	"Hilton Head Island",
	"Sundsvall",
	"Union City",
	"Ouro Prêto",
	"Yelabuga",
	"San Ramón de la Nueva Orán",
	"Troitsk",
	"Konin",
	"Owensboro",
	"Jackson",
	"San Martín",
	"Kairāna",
	"Jaen",
	"Majadahonda",
	"Riberalta",
	"Oriximiná",
	"Arnsberg",
	"Prince George",
	"Kostiantynivka",
	"Ceará-Mirim",
	"Ixmiquilpan",
	"Ubay",
	"Pavia",
	"Yurihonjō",
	"Yegoryevsk",
	"Kasama",
	"Lambunao",
	"Bawku",
	"Ragusa",
	"Chaigoubu",
	"Tagoloan",
	"Siuna",
	"Tākestān",
	"Linares",
	"Shrewsbury",
	"Cleveland",
	"Wausau",
	"Quillota",
	"Ostrowiec Świętokrzyski",
	"El Hamma",
	"Umingan",
	"Molina de Segura",
	"Anniston",
	"Lanxi",
	"Brakpan",
	"Zhuolu",
	"Mangatarem",
	"Kirovo-Chepetsk",
	"Apple Valley",
	"Lüdenscheid",
	"Vinhedo",
	"Landshut",
	"San Juan Chamelco",
	"Shibukawa",
	"Sault Ste. Marie",
	"Calauag",
	"Nepālgañj",
	"Mansfield",
	"Narra",
	"Tatvan",
	"Cuamba",
	"Farroupilha",
	"Tokār",
	"Shakargarh",
	"Tan-Tan",
	"Wenping",
	"Chapayevsk",
	"Santa Rosa de Cabal",
	"Moa",
	"Ōtawara",
	"Cremona",
	"Itapira",
	"Baghlān",
	"Kalamáta",
	"Sambrial",
	"Vsevolozhsk",
	"Montepuez",
	"San Vicente del Caguán",
	"Keshan",
	"Belovo",
	"Schaumburg",
	"Xiangjiaba",
	"Pocatello",
	"Pacatuba",
	"Crateús",
	"Woodbury",
	"Ede",
	"Aracati",
	"Argao",
	"Sotik",
	"Southfield",
	"Yafran",
	"Ellicott City",
	"Paterna",
	"Dale City",
	"Maple Grove",
	"Penafiel",
	"Pittsburg",
	"Concepción del Uruguay",
	"Belo Jardim",
	"Morgantown",
	"Brandenburg",
	"Mansfield",
	"Hammond",
	"Dothan",
	"Harrisonburg",
	"Wenatchee",
	"Goya",
	"Waukesha",
	"Rio do Sul",
	"Benidorm",
	"Tagaytay",
	"Bongabong",
	"Ishioka",
	"Casa Nova",
	"Colón",
	"Tibati",
	"Alicia",
	"Coffs Harbour",
	"Santa Isabel do Pará",
	"Redmond",
	"Kawm Umbū",
	"Bouaflé",
	"Talakag",
	"Florida",
	"Izmail",
	"Bocholt",
	"Carpi",
	"Aschaffenburg",
	"Lisburn",
	"Quartu Sant’Elena",
	"Sarnia",
	"Keighley",
	"Wood Buffalo",
	"Mérignac",
	"Sumter",
	"Redlands",
	"Bāpatla",
	"Saint-Nazaire",
	"Daphne",
	"Mārkāpur",
	"Mount Vernon",
	"Villa Tunari",
	"Shujaabad",
	"Catanauan",
	"Malaut",
	"Verkhnyaya Pyshma",
	"Placetas",
	"Gibara",
	"Yinying",
	"Cabudare",
	"Entebbe",
	"Baiquan",
	"Weston",
	"Bundaberg",
	"Corozal",
	"Indaial",
	"St. Charles",
	"Djemmal",
	"Pan’an",
	"Gaspar",
	"Cherry Hill",
	"Rittō",
	"Badvel",
	"Hujra Shah Muqim",
	"Châteauguay",
	"Novomoskovsk",
	"Kladno",
	"Zenica",
	"Janesville",
	"Kabirwala",
	"Manaoag",
	"Loulé",
	"Sherman",
	"Altamura",
	"North Richland Hills",
	"Chekhov",
	"Campo Bom",
	"Reconquista",
	"Būr Fu’ād",
	"São Sebastião do Paraíso",
	"Kovel",
	"Broomfield",
	"Guzhou",
	"Zhanggu",
	"Rongcheng",
	"Tupi",
	"Imola",
	"Smila",
	"Sipalay",
	"El Paso de Robles",
	"Bristol",
	"Rosetta",
	"Casas Adobes",
	"Walnut Creek",
	"Hato Mayor",
	"Villa del Rosario",
	"Sosúa",
	"Colmar",
	"Phú Quốc",
	"Baras",
	"Qo‘ng‘irot Shahri",
	"Az Zubaydīyah",
	"Oshnavīyeh",
	"Jorhāt",
	"Pozorrubio",
	"Saldanha",
	"Fanzhuang",
	"Shangchuankou",
	"Celle",
	"Kempten",
	"Isnā",
	"Qingquan",
	"Jarabacoa",
	"Hasselt",
	"Poinciana",
	"Garzón",
	"Santa Rosa",
	"Russas",
	"Glen Burnie",
	"Bula",
	"San Juan de los Lagos",
	"Solok",
	"Uwajima",
	"Massa",
	"Mansehra",
	"Suwałki",
	"Bamban",
	"Villanueva y Geltrú",
	"Lehi",
	"Yitiaoshan",
	"Estância",
	"Dumangas",
	"Paragominas",
	"Sanlúcar de Barrameda",
	"Gniezno",
	"Saint-Jérôme",
	"Bom Jesus da Lapa",
	"Homestead",
	"West Bend",
	"Escada",
	"Bacacay",
	"Delray Beach",
	"Ongjang",
	"Sheboygan",
	"Chisec",
	"Aqsū",
	"Planeta Rica",
	"Nova Kakhovka",
	"Korba",
	"Bafia",
	"Ko Samui",
	"Lake Elsinore",
	"Infanta",
	"Liuhe",
	"Fulda",
	"Kamsar",
	"Huishi",
	"Daytona Beach",
	"Necoclí",
	"Yanghe",
	"Minusinsk",
	"Mocuba",
	"Araranguá",
	"Cosenza",
	"Purísima de Bustos",
	"Los Lunas",
	"Mocuba",
	"Sosnovyy Bor",
	"Dinslaken",
	"Chulucanas",
	"Aalen",
	"Say’ūn",
	"Sousa",
	"Al Aḩmadī",
	"Głogów",
	"Wum",
	"Lima",
	"Mococa",
	"Decatur",
	"Rocklin",
	"Carles",
	"Kati",
	"Limay",
	"Rogers",
	"Zapotlanejo",
	"Doboj",
	"Hihyā",
	"Drummondville",
	"Stafford",
	"Lippstadt",
	"Castle Rock",
	"Otukpo",
	"Maţrūḩ",
	"Ash Shiḩr",
	"Chengjiao Chengguanzhen",
	"Tuymazy",
	"Türkmenbaşy",
	"Lodja",
	"Fernandópolis",
	"Lala Musa",
	"Oas",
	"Lala",
	"Conceição do Coité",
	"Xinhua",
	"Hanover",
	"Bossier City",
	"Dubuque",
	"Przemyśl",
	"Rockville",
	"Victoria",
	"Siasi",
	"Saratoga Springs",
	"Wanzhuang",
	"Érd",
	"Miagao",
	"Kstovo",
	"Viterbo",
	"Orani",
	"Dalaguete",
	"Trapani",
	"Tepotzotlán",
	"Tzaneen",
	"Ames",
	"Zanhuang",
	"West Des Moines",
	"Comonfort",
	"Tumauini",
	"Ban Suan",
	"Tinambac",
	"İslahiye",
	"Embu-Guaçu",
	"Bugallon",
	"Sagunto",
	"Yuquan",
	"Yorba Linda",
	"Kashima",
	"Santa Catarina Otzolotepec",
	"Bergen op Zoom",
	"Binalbagan",
	"Manaure",
	"Casper",
	"Saint John",
	"Lushar",
	"Hashima",
	"Soio",
	"Balingasag",
	"Palatine",
	"Corvallis",
	"Herford",
	"Talibon",
	"Camalig",
	"Oktyabrskiy",
	"Ankeny",
	"Koratla",
	"Sa’ada",
	"Weiyuan",
	"San Pedro Sacatepéquez",
	"Rowlett",
	"Kissidougou",
	"Guangping",
	"Lakeville",
	"Yachimata",
	"Caicó",
	"Tejen",
	"Pelileo",
	"Cawayan",
	"Don Carlos",
	"Cosmópolis",
	"San Fernando",
	"Moita",
	"Alpharetta",
	"Rio Negro",
	"Bagumbayan",
	"Aflao",
	"Longview",
	"Vidnoye",
	"Güines",
	"Pigcawayan",
	"DeKalb",
	"Pabianice",
	"Petaluma",
	"Pulivendla",
	"Kerpen",
	"Hīt",
	"San Pascual",
	"Las Heras",
	"Belogorsk",
	"Saiki",
	"Huinan",
	"Rüsselsheim",
	"Pinamungahan",
	"Ishim",
	"Caledon",
	"Gukovo",
	"Indang",
	"P’yŏngsan",
	"Urla",
	"Brookes Point",
	"Kalush",
	"Stupino",
	"Huquan",
	"Laguna Niguel",
	"Eagan",
	"Ferrol",
	"Slavyansk-na-Kubani",
	"Ilo",
	"Kenner",
	"Itaberaba",
	"Esperanza",
	"Hermosa",
	"Kungur",
	"Lençóis Paulista",
	"Sodo",
	"Oberá",
	"União dos Palmares",
	"Sindelfingen",
	"Bay City",
	"Most",
	"Seraing",
	"Venâncio Aires",
	"San Francisco El Alto",
	"Xibang",
	"Lahat",
	"Armūr",
	"Menzel Temime",
	"North Little Rock",
	"Sammamish",
	"Martil",
	"Santo Tomé",
	"Aparri",
	"Montenegro",
	"Weimar",
	"Lupon",
	"Shawnee",
	"Jupiter",
	"Old Bridge",
	"Ina",
	"Tupã",
	"Doral",
	"Pililla",
	"San Leonardo",
	"Zarechnyy",
	"Carbondale",
	"Daule",
	"Juventino Rosas",
	"Blaine",
	"Bordj Menaïel",
	"St. Albert",
	"Pavlovskiy Posad",
	"Weirton",
	"Plauen",
	"Franklin",
	"Castillejos",
	"Tulare",
	"Leszno",
	"Bourges",
	"Icó",
	"Beloretsk",
	"Jaisalmer",
	"Valence",
	"Borås",
	"Pessac",
	"Ishimbay",
	"Roxas",
	"Korosten",
	"Wellington",
	"Pflugerville",
	"Birnin Konni",
	"Palo Alto",
	"Cuyapo",
	"Middletown",
	"Neuwied",
	"Zeist",
	"Torquay",
	"Bhairāhawā",
	"Ḩalabjah",
	"Chunian",
	"Mecheria",
	"Marinilla",
	"Great Falls",
	"Esquipulas",
	"Sipocot",
	"Dormagen",
	"Caripito",
	"Michigan City",
	"Pokrovsk",
	"Santa Catarina Pinula",
	"San Mateo",
	"Svyetlahorsk",
	"Rosenheim",
	"Bucak",
	"Hornchurch",
	"Donskoy",
	"Tādepalle",
	"La Carlota",
	"Agoo",
	"Eden Prairie",
	"Port Orange",
	"Neubrandenburg",
	"Dublin",
	"Gadwāl",
	"Rafḩā",
	"Grand Forks",
	"Santo Domingo Tehuantepec",
	"Binga",
	"Alamada",
	"Noblesville",
	"Chokwé",
	"Jatani",
	"Cruz das Almas",
	"Rahat",
	"Rizal",
	"Apaseo el Alto",
	"Qinggang",
	"La Paz",
	"San Felipe",
	"Vittoria",
	"San Clemente",
	"Santiago Tianguistenco",
	"Brentwood",
	"Carmichael",
	"Lingshou",
	"Bongabon",
	"Ouricuri",
	"Irún",
	"Pomezia",
	"Sittingbourne",
	"Ciénaga de Oro",
	"Ghardimaou",
	"Crotone",
	"Sandefjord",
	"Victoria",
	"Bamei",
	"Janiuay",
	"Zvornik",
	"Aliaga",
	"Grevenbroich",
	"Asbest",
	"Póvoa de Varzim",
	"Penedo",
	"Bandar-e Genāveh",
	"Eastvale",
	"Tuburan",
	"Nawá",
	"Kangbao",
	"Benevides",
	"At Tall",
	"Chapel Hill",
	"Tomé-Açu",
	"Rosales",
	"Rocky Mount",
	"Lugano",
	"Haverhill",
	"Asahi",
	"Mauban",
	"Māhdāsht",
	"San José de Bocay",
	"Gaya",
	"Vigevano",
	"Klintsy",
	"Nagcarlan",
	"Woking",
	"Zarand",
	"Kurihara",
	"Beloit",
	"Hilongos",
	"Quimper",
	"Nankana Sahib",
	"Velika Gorica",
	"Gloucester",
	"Guiglo",
	"Atimonan",
	"Chincha Alta",
	"Palmares",
	"Goa",
	"Bay",
	"San Juan de Sabinas",
	"Wenxicun",
	"Budënnovsk",
	"Glens Falls",
	"Graaff-Reinet",
	"Aïn Sefra",
	"Saravia",
	"Tigbauan",
	"Herten",
	"Granby",
	"Carrara",
	"Shiroi",
	"Tangub",
	"Nago",
	"Roeselare",
	"Chełm",
	"Santo Antônio do Descoberto",
	"Lutayan",
	"Medicine Hat",
	"Fairbanks",
	"Volsk",
	"Springfield",
	"Bethesda",
	"Rhondda",
	"Novaya Balakhna",
	"Grande Prairie",
	"Villasis",
	"Oudtshoorn",
	"Johnstown",
	"Bannu",
	"Kapatagan",
	"Łomża",
	"Puerto Asís",
	"Igarapé-Miri",
	"West Hartford",
	"Çaldıran",
	"Balboa Heights",
	"Kasongo",
	"Dundalk",
	"Castro Valley",
	"Coon Rapids",
	"Elmira",
	"Palmela",
	"Portel",
	"Yurimaguas",
	"Deva",
	"Mankato",
	"Nkawkaw",
	"Albany",
	"Mut",
	"Cruz Alta",
	"Rossosh",
	"Palín",
	"Camaquã",
	"Kolomyia",
	"Cajicá",
	"Margate",
	"Revda",
	"Borisoglebsk",
	"Kotlas",
	"Encinitas",
	"Montélimar",
	"Runcorn",
	"Zelenogorsk",
	"Leander",
	"Shangtangcun",
	"Mobārakeh",
	"Greenwich",
	"Leninogorsk",
	"Wels",
	"Parkersburg",
	"Kayes",
	"Tendō",
	"Villa Carlos Paz",
	"Waltham",
	"Isabela",
	"Menzel Bourguiba",
	"Patikul",
	"Caltanissetta",
	"Tanjungpandan",
	"Tefé",
	"Riosucio",
	"Tarnowskie Góry",
	"Parang",
	"Morecambe",
	"Boryspil",
	"Port Charlotte",
	"Tuapse",
	"Xinzhai",
	"Palm Harbor",
	"Ocoyoacac",
	"Dajabón",
	"San Luis Obispo",
	"Figueira da Foz",
	"Senahú",
	"Nahualá",
	"Meihua",
	"Benevento",
	"Bergheim",
	"Sebring",
	"Chistopol",
	"Panzos",
	"Itoman",
	"Puerto Peñasco",
	"Council Bluffs",
	"São Gabriel",
	"Penápolis",
	"Itapecuru Mirim",
	"Ipirá",
	"Roxas",
	"Naro-Fominsk",
	"Hamilton",
	"Asker",
	"Viareggio",
	"Ferkessédougou",
	"Moore",
	"Zaraza",
	"San Francisco",
	"Casa Grande",
	"Lantapan",
	"Monessen",
	"San Antonio del Táchira",
	"Mielec",
	"Polevskoy",
	"Plato",
	"Sätbayev",
	"Piripiri",
	"Sarandí",
	"Derik",
	"Heroica Caborca",
	"Opol",
	"Ar Rastan",
	"Aisai",
	"Santo Amaro",
	"Tuao",
	"Carepa",
	"Lysva",
	"Viseu",
	"Tríkala",
	"Coroatá",
	"Buenavista",
	"Fairfield",
	"Friedrichshafen",
	"Xinmin",
	"Schwäbisch Gmünd",
	"Rubizhne",
	"St. Thomas",
	"Aurora",
	"Sibay",
	"Sawahlunto",
	"Laoang",
	"Airdrie",
	"Wanparti",
	"Guapimirim",
	"Iztapa",
	"Uzunköprü",
	"Cajazeiras",
	"Rome",
	"Garbsen",
	"Fano",
	"Millcreek",
	"Sanford",
	"Slutsk",
	"Zamora",
	"Caràzinho",
	"Vacaria",
	"Hürth",
	"Mayagüez",
	"Burnsville",
	"Acaraú",
	"Montauban",
	"Kiamba",
	"Candon",
	"Morristown",
	"Halton Hills",
	"Reston",
	"Barra do Garças",
	"Idiofa",
	"Sŏnbong",
	"Skhirate",
	"Quezon",
	"Lakewood",
	"Nahariyya",
	"Acerra",
	"Bezerros",
	"Yongyang",
	"Hamden",
	"Spring",
	"Stralsund",
	"Gadsden",
	"Chalkída",
	"Taylor",
	"Novi",
	"Marietta",
	"Villamaría",
	"Montemorelos",
	"Wesel",
	"Tartagal",
	"Bansalan",
	"Shihe",
	"Hot Springs",
	"Koga",
	"Dongcun",
	"Bełchatów",
	"Druzhkivka",
	"Soasio",
	"Santa Barbara",
	"Batatais",
	"Autlán de Navarro",
	"Calinog",
	"Savona",
	"Sibalom",
	"Woodland",
	"Kavála",
	"Matera",
	"Greifswald",
	"Kumertau",
	"Rzhev",
	"Molfetta",
	"Ponnūru",
	"Yongbei",
	"Belladère",
	"San Vicente del Raspeig",
	"Mossel Bay",
	"Bensalem",
	"Olbia",
	"Xo‘jayli Shahri",
	"Offenburg",
	"Sayanogorsk",
	"Bor",
	"Lakhdaria",
	"Langenfeld",
	"Commerce City",
	"Obando",
	"Belebey",
	"Manhiça",
	"Cristalina",
	"Labinsk",
	"Shimotsuke",
	"Lianzhuang",
	"South Hill",
	"Urus-Martan",
	"Tecpán Guatemala",
	"Suifenhe",
	"Kapchagay",
	"Vinukonda",
	"San Marcos",
	"Angat",
	"Frutal",
	"Lagoa Santa",
	"Plymouth",
	"Kribi",
	"Chajarí",
	"Maués",
	"Gubat",
	"Limoeiro do Norte",
	"Girardota",
	"Itogon",
	"Bristol",
	"Solano",
	"Porirua",
	"Jose Pañganiban",
	"Columbus",
	"Asenovgrad",
	"Hardenberg",
	"Shuya",
	"Jacundá",
	"Huajiang",
	"Comitancillo",
	"Sibulan",
	"Uriangato",
	"Alcoy",
	"Huehuetoca",
	"Bangor",
	"Lesosibirsk",
	"Zhoujiajing",
	"Jōsō",
	"São Miguel do Guamá",
	"Tezpur",
	"Morong",
	"Abuyog",
	"Saint-Hyacinthe",
	"Narasapur",
	"Maasin",
	"Pasrur",
	"Niort",
	"Agrigento",
	"Nordre Fåle",
	"Chibuto",
	"Prokhladnyy",
	"Cambanugoy",
	"Yara",
	"Esfarāyen",
	"Sayaxché",
	"Morón",
	"Greenwood",
	"Los Andes",
	"Lucas do Rio Verde",
	"Paracale",
	"Hua Hin",
	"Bartlett",
	"Bradenton",
	"Pontiac",
	"Staunton",
	"Neu-Ulm",
	"Gannan",
	"Baao",
	"El Cuá",
	"Concepción Tutuapa",
	"Meriden",
	"Aleksandrov",
	"Cachoeiras de Macacu",
	"Clay",
	"Tahara",
	"Zengcun",
	"Apex",
	"Unna",
	"Royal Oak",
	"Benton Harbor",
	"Chichibu",
	"Tianningcun",
	"Santa María Chiquimula",
	"Kattaqo’rg’on Shahri",
	"Pamplona",
	"Florida",
	"Constanza",
	"Metapán",
	"Faenza",
	"Bilhorod-Dnistrovskyi",
	"Lower Merion",
	"Xikeng",
	"Mamungan",
	"Motril",
	"St. Clair Shores",
	"Juruti",
	"Buíque",
	"Kélibia",
	"Cabatuan",
	"Sandnes",
	"Rass el Djebel",
	"Des Plaines",
	"Lac-Brome",
	"Lewiston",
	"Jovellanos",
	"Midland",
	"Timargara",
	"Mikhaylovka",
	"Boufarik",
	"Santo Domingo",
	"Izberbash",
	"Sucun",
	"Bowie",
	"Cerignola",
	"Kaş",
	"Tlaltenango de Sánchez Román",
	"Ávila",
	"Aketi",
	"Qaskeleng",
	"Palompon",
	"Felgueiras",
	"Tokoname",
	"Rotorua",
	"Göppingen",
	"Lambayeque",
	"Iskitim",
	"Guarabira",
	"Caldwell",
	"Sunshi",
	"San Pedro",
	"Moncada",
	"Ma’erkang",
	"San Rafael",
	"Moncalieri",
	"Açu",
	"San Fernando",
	"Euskirchen",
	"Sérres",
	"Jiantang",
	"Ishikari",
	"Punta Alta",
	"La Gomera",
	"Ragay",
	"Kosai",
	"Nyagan",
	"Şirvan",
	"Barneveld",
	"Huaniu",
	"Santa Cruz",
	"Hamburg",
	"Frankfurt (Oder)",
	"Masantol",
	"Richland",
	"Fredericton",
	"Taal",
	"Asingan",
	"St. Peters",
	"Wesley Chapel",
	"Tōgane",
	"Gengzhuangqiao",
	"Monte Alegre",
	"Heerhugowaard",
	"San Remigio",
	"Pula",
	"Tikhvin",
	"Castilla",
	"Pradera",
	"Roermond",
	"Hendersonville",
	"Huntersville",
	"Mirassol",
	"Southampton",
	"Xiezhou",
	"Santee",
	"Hameln",
	"Wagga Wagga",
	"Meleuz",
	"Livingston",
	"Allanmyo",
	"Malvar",
	"Aleksin",
	"António Enes",
	"Berëzovskiy",
	"Ping’an",
	"Beckley",
	"Sāmalkot",
	"Alexandroúpoli",
	"Titusville",
	"Cartersville",
	"Ypané",
	"Sidi Yahya Zaer",
	"Będzin",
	"Walla Walla",
	"Orland Park",
	"Ciudad Cuauhtémoc",
	"Qianwu",
	"Tikhoretsk",
	"Kothāpet",
	"Towson",
	"Orito",
	"Manchester",
	"Biała Podlaska",
	"Botolan",
	"Qŭnghirot",
	"Minokamo",
	"Weymouth",
	"Campo Alegre",
	"Ibitinga",
	"Mācherla",
	"Pavlovo",
	"Beauvais",
	"Jerez de García Salinas",
	"Parker",
	"Linares",
	"Palm Beach Gardens",
	"Ejmiatsin",
	"Krasnotur’insk",
	"Bozeman",
	"Kandukūr",
	"Diriamba",
	"Beni Enzar",
	"Salsk",
	"Sicuani",
	"Kojskë",
	"New Plymouth",
	"Ilog",
	"East Stroudsburg",
	"Milagros",
	"Santiago Tuxtla",
	"Nova Odessa",
	"Taunton",
	"Istaravshan",
	"Khlong Luang",
	"Midwest City",
	"Sankt Augustin",
	"Stolberg",
	"Tivoli",
	"Dāmghān",
	"Bobbili",
	"Irpin",
	"Sattenapalle",
	"Krymsk",
	"Andradina",
	"Kudamatsu",
	"San José de Ocoa",
	"Goldsboro",
	"Azzaba",
	"Ṭikāpur",
	"Foligno",
	"Vargem Grande",
	"Euless",
	"Talagante",
	"Shicun",
	"Görlitz",
	"Pau d’Alho",
	"Paços de Ferreira",
	"Eschweiler",
	"Vrindāvan",
	"Rîbniţa",
	"Alenquer",
	"Langenhagen",
	"Manfredonia",
	"Meerbusch",
	"General Pico",
	"Manhattan",
	"Irosin",
	"Matanao",
	"Shoreline",
	"Três Pontas",
	"Neryungri",
	"Moreno",
	"Tama",
	"Puerto Boyacá",
	"Jackson",
	"Kananya",
	"Jinoba-an",
	"Calatagan",
	"Chini",
	"Bagheria",
	"Piscataway",
	"Brentwood",
	"Clacton-on-Sea",
	"Orion",
	"Acambay",
	"Lake Havasu City",
	"Cuneo",
	"Waiblingen",
	"Santa Cruz Verapaz",
	"Gattaran",
	"Taquaritinga",
	"Tulunan",
	"Bertioga",
	"Smyrna",
	"Tønsberg",
	"Quezaltepeque",
	"Cui’erzhuang",
	"Santa Cruz",
	"Opava",
	"Nōgata",
	"Tāybād",
	"Trani",
	"Bianyang",
	"Santa Ana",
	"Cape Girardeau",
	"Bartolomé Masó",
	"Amealco",
	"Uson",
	"Hassa",
	"São Francisco",
	"Hohoe",
	"Belampalli",
	"Shali",
	"Araci",
	"Oosterhout",
	"Xinqing",
	"Blainville",
	"Gulariyā",
	"Registro",
	"Baganga",
	"Antratsyt",
	"Vranjak",
	"Irati",
	"Winneba",
	"Xánthi",
	"Los Amates",
	"San Fernando",
	"Longmen",
	"Zarrīn Shahr",
	"Rosario",
	"Lozova",
	"Bisceglie",
	"Khān Shaykhūn",
	"Kazanlak",
	"Placer",
	"Vorkuta",
	"Medellin",
	"Yongqing",
	"Kateríni",
	"Frýdek-Místek",
	"Parachinar",
	"Tigaon",
	"Bhīmunipatnam",
	"Hyères",
	"Canterbury",
	"Boituva",
	"Portimão",
	"Den Helder",
	"Volzhsk",
	"Changling",
	"Wangguanzhuang",
	"Hidaka",
	"Toki",
	"Grants Pass",
	"Blue Springs",
	"Jaru",
	"Xiva",
	"Gus’-Khrustal’nyy",
	"Tucano",
	"Martin",
	"Southaven",
	"Umm Ruwaba",
	"Tinley Park",
	"Apatity",
	"Azul",
	"Tauá",
	"Tanauan",
	"Diamond Bar",
	"Binalonan",
	"Pittsfield",
	"San Francisco",
	"Naviraí",
	"Eagle Pass",
	"Acará",
	"Avellino",
	"Ārān Bīdgol",
	"Limoeiro",
	"Monte Mor",
	"Jocotitlán",
	"Lenexa",
	"Lagonoy",
	"Corby",
	"Hazleton",
	"Alangalang",
	"El Banco",
	"Basey",
	"Twin Falls",
	"Brookhaven",
	"Schweinfurt",
	"Batac",
	"Villarrica",
	"Baden-Baden",
	"Novato",
	"Tigard",
	"Hoogeveen",
	"Jaguaquara",
	"San Remo",
	"Congonhas",
	"Abington",
	"Rolim de Moura",
	"Aurora",
	"Bitonto",
	"Highland",
	"San Mariano",
	"Dearborn Heights",
	"Puttūr",
	"Manapla",
	"Hattingen",
	"Bad Homburg",
	"Songlindian",
	"Pombal",
	"Grapevine",
	"Hervey Bay",
	"Sigaboy",
	"Amalāpuram",
	"Chino",
	"Fond du Lac",
	"Hacienda Heights",
	"Jasaan",
	"Punganūru",
	"Nāndod",
	"Apple Valley",
	"Qiryat Gat",
	"Tiquisate",
	"Chicopee",
	"Funato",
	"Zhushan",
	"San Luis",
	"New Corella",
	"San Gil",
	"Narbonne",
	"Tequisquiapan",
	"Cathedral City",
	"Vannes",
	"La Roche-sur-Yon",
	"Viçosa do Ceará",
	"San Cristóbal Verapaz",
	"Pôrto Ferreira",
	"Huaishu",
	"Barreirinhas",
	"Delano",
	"Bentonville",
	"Stonecrest",
	"Sakurai",
	"Dandarah",
	"Kettering",
	"Esperanza",
	"Mali",
	"Canlaon",
	"Sarpsborg",
	"Colton",
	"Pulheim",
	"Annaka",
	"Kingston",
	"Ostrołęka",
	"Xielu",
	"Monte Santo",
	"Chota",
	"Naguilian",
	"Lingen",
	"Barotac Nuevo",
	"Modica",
	"Jinchang",
	"Teramo",
	"Cuenca",
	"Bad Salzuflen",
	"Mansalay",
	"West Haven",
	"Cava de’ Tirreni",
	"Dayin",
	"St. Cloud",
	"Sertolovo",
	"Chivilcoy",
	"Cholet",
	"Chenab Nagar",
	"Canoinhas",
	"São José do Rio Pardo",
	"Kotka",
	"Shidong",
	"Normal",
	"Palmaner",
	"Qingan",
	"Paombong",
	"Itupeva",
	"Siena",
	"Milford",
	"Tarma",
	"Jalor",
	"Zarafshon Shahri",
	"Valle del Guamuez",
	"Huesca",
	"Campo Belo",
	"Vikārābād",
	"Hashtpar",
	"Xingcheng",
	"Ixhuatlán de Madero",
	"Ruteng",
	"Narva",
	"Barra",
	"Tokmok",
	"Vigia",
	"Zhigulevsk",
	"Arroyo Grande",
	"Pinto",
	"Kampen",
	"San Simon",
	"Progreso",
	"Magsaysay",
	"Minnetonka",
	"Jagtiāl",
	"Dholka",
	"Puerto Libertador",
	"Elda",
	"Santa Maria",
	"Tajumulco",
	"Wayne",
	"La Lima",
	"Liski",
	"Tuni",
	"Yucaipa",
	"Markala",
	"Svobodnyy",
	"Jaggayyapeta",
	"Salo",
	"Brunswick",
	"Bījār",
	"Williamsport",
	"Roxas",
	"Neiba",
	"Union",
	"Hagonoy",
	"Malay",
	"Nordhorn",
	"Elyria",
	"Videira",
	"Ibaan",
	"Leesburg",
	"Mount Prospect",
	"Marco de Canavezes",
	"Nebaj",
	"Krasnokamsk",
	"Upata",
	"Port Shepstone",
	"Upi",
	"Wetzlar",
	"Enerhodar",
	"Bristol",
	"Velletri",
	"Pinellas Park",
	"Frechen",
	"Tiwi",
	"Neustadt",
	"Encarnación de Díaz",
	"Bambang",
	"Anzio",
	"Acireale",
	"Zhangjiazhuang",
	"San Bartolomé",
	"Pijijiapan",
	"Bellevue",
	"West Sacramento",
	"Gudermes",
	"Yangmei",
	"Apopka",
	"Olímpia",
	"Barbosa",
	"Pryluky",
	"Xincheng",
	"Lewiston",
	"Itupiranga",
	"San Manuel",
	"Lian",
	"Santo Estêvão",
	"Porto Nacional",
	"Yaozhuangcun",
	"Capivari",
	"São Bento do Una",
	"Millcreek",
	"Grand Island",
	"Guiuan",
	"Akbou",
	"Tame",
	"Palm Desert",
	"Canguçu",
	"Karlovac",
	"Vyksa",
	"Mulanay",
	"Pangantocan",
	"Passau",
	"Leopoldina",
	"Fréjus",
	"Milford city",
	"San Severo",
	"Watertown",
	"Acopiara",
	"Sagua la Grande",
	"Little Elm",
	"Pasaje",
	"Ramon",
	"Chambersburg",
	"Peabody",
	"Wylie",
	"Manicoré",
	"Beaufort",
	"Arsenyev",
	"Torrelavega",
	"Mercedes",
	"Ahlen",
	"DeSoto",
	"Venkatagiri",
	"Sihor",
	"Burauen",
	"Lorica",
	"Tomiya",
	"Chone",
	"Kobryn",
	"Jangaon",
	"Poprad",
	"Mongaguá",
	"Edina",
	"Wolfenbüttel",
	"Krasnokamensk",
	"Tutóia",
	"Rondon do Pará",
	"União da Vitória",
	"San José Villa de Allende",
	"Minalabac",
	"Minami-Sōma",
	"Wheaton",
	"Diffun",
	"Thongwa",
	"Viana",
	"Jaén",
	"Granja",
	"Ålesund",
	"Huaiyang",
	"Pacora",
	"Horishni Plavni",
	"An Nabk",
	"Bato",
	"Alfonso",
	"Arles",
	"Mandamāri",
	"Lacey",
	"Tobias Barreto",
	"Ajuy",
	"Qabqa",
	"Caetité",
	"Summerville",
	"Itabirito",
	"Prudentópolis",
	"Vyazma",
	"San Isidro",
	"Boa Viagem",
	"Pordenone",
	"Parsippany-Troy Hills",
	"Roman",
	"Sapé",
	"Ibbenbüren",
	"Kleve",
	"Pontevedra",
	"Óbidos",
	"Civitavecchia",
	"Welland",
	"Jaguariúna",
	"Guaxupé",
	"Novovolynsk",
	"Basankusu",
	"Villareal",
	"Colmenar Viejo",
	"Molave",
	"Cruz del Eje",
	"Belorechensk",
	"Maricopa",
	"Stratford",
	"San Joaquin",
	"Abdul Hakim",
	"Wangjiazhai",
	"Ālbū Kamāl",
	"San Onofre",
	"Porto Feliz",
	"Lompoc",
	"Timashevsk",
	"Eilat",
	"Metpalli",
	"Howell",
	"Caldas da Rainha",
	"Paraíso do Tocantins",
	"Cogan",
	"Bandō",
	"Tagkawayan",
	"Gwadar",
	"Battipaglia",
	"Pila",
	"Kentwood",
	"Berriozábal",
	"Covilhã",
	"Coron",
	"Lucban",
	"Bad Kreuznach",
	"Shepparton",
	"Shimencun",
	"Levittown",
	"Dumanjog",
	"Karviná",
	"Huazangsi",
	"Carigara",
	"Tsukubamirai",
	"Xanxerê",
	"Pola de Siero",
	"Solnechnogorsk",
	"Caleta Olivia",
	"Sierra Vista",
	"Laguna",
	"Woerden",
	"Mazara del Vallo",
	"Tulsīpur",
	"Trento",
	"Chieti",
	"Rovigo",
	"Madison",
	"Smyrna",
	"Guarne",
	"Speyer",
	"North Bay",
	"Granadilla de Abona",
	"Knysna",
	"Glendora",
	"Butare",
	"El Talar de Pacheco",
	"Zhexiang",
	"Burien",
	"Grasse",
	"Scandicci",
	"Uzlovaya",
	"Ribeira do Pombal",
	"Trairi",
	"Arao",
	"Böblingen",
	"Satte",
	"Tapas",
	"Cuyotenango",
	"Montijo",
	"Jaraguá",
	"Herriman",
	"Willich",
	"Severomorsk",
	"Tadmur",
	"Misterbianco",
	"Farmington",
	"New Bern",
	"Humaitá",
	"Cheremkhovo",
	"Gummersbach",
	"Hinesville",
	"Repalle",
	"Snezhinsk",
	"Ravensburg",
	"Hassan Abdal",
	"Douyu",
	"Konibodom",
	"Mercedes",
	"Sakaidechō",
	"Zabīd",
	"Fangcun",
	"Beaumont",
	"Goslar",
	"Horsham",
	"Collierville",
	"Utrera",
	"Aurora",
	"Campos do Jordão",
	"Nazarovo",
	"West Lafayette",
	"Yūki",
	"Florissant",
	"Laval",
	"Hoffman Estates",
	"Mozhga",
	"Kirishi",
	"El Bagre",
	"Ye",
	"Queen Creek",
	"Kizlyar",
	"Fafe",
	"Banī Walīd",
	"Kannapolis",
	"Buenavista",
	"Puławy",
	"Tŭrtkŭl",
	"Beloeil",
	"Urbiztondo",
	"Longwan",
	"Houten",
	"Mahalapye",
	"Cardona",
	"Mweka",
	"Belleville",
	"Beruniy",
	"Methuen Town",
	"Santa Lucía del Camino",
	"Kulu",
	"El Salvador",
	"Yasu",
	"Baranoa",
	"Shakhtarsk",
	"La Calera",
	"Rastatt",
	"Sittard",
	"Starogard Gdański",
	"Sahuarita",
	"Lörrach",
	"Cataingan",
	"Bani",
	"Tebourba",
	"Rincón de la Victoria",
	"Qinhe",
	"Artur Nogueira",
	"Traverse City",
	"Mirabel",
	"San Ignacio",
	"Severn",
	"Nova Venécia",
	"Catalina Foothills",
	"Galveston",
	"Pozi",
	"Amora",
	"Cookeville",
	"Chinú",
	"Leith",
	"Peine",
	"Mishawaka",
	"Emden",
	"Ardea",
	"Rowland Heights",
	"Kitaotao",
	"Bullhead City",
	"Irondequoit",
	"Stillwater",
	"Cabagan",
	"Baicheng",
	"Tājūrā’",
	"Macaúbas",
	"Rivoli",
	"Puerto Berrío",
	"East Hartford",
	"Bocaiúva",
	"Huanghuajie",
	"Forney",
	"Nanjian",
	"Xiazhai",
	"São Joaquim da Barra",
	"Leon",
	"Erftstadt",
	"Estancia",
	"Alta Floresta",
	"Mantova",
	"Lindong",
	"Vargem Grande Paulista",
	"Borovichi",
	"Itararé",
	"Bhainsa",
	"Roslavl",
	"Beni Yakhlef",
	"Gogrial",
	"Shankou",
	"Miyoshi",
	"Novovyatsk",
	"Sesto Fiorentino",
	"Sardasht",
	"Buluan",
	"Albi",
	"Zaragoza",
	"Shawinigan",
	"Tepeapulco",
	"Donsol",
	"Kahemba",
	"Porvoo",
	"Abū Ḩamad",
	"Chomutov",
	"Nichinan",
	"Heidenheim",
	"Jiuzhou",
	"Azusa",
	"Upper Buchanan",
	"Nahuizalco",
	"Barobo",
	"Orangetown",
	"Buguruslan",
	"Xankəndi",
	"Ibajay",
	"Slonim",
	"Rāmhormoz",
	"Nettuno",
	"Ashburn",
	"Bloomsburg",
	"Moss",
	"Titay",
	"Bergkamen",
	"Gualán",
	"Morehead City",
	"Sesimbra",
	"Leonberg",
	"Suzaka",
	"IJmuiden",
	"Tomisato",
	"Ridder",
	"Coral Gables",
	"Guacharachi",
	"Ifakara",
	"Chioggia",
	"Enid",
	"Zyryanovsk",
	"Zongshizhuang",
	"Cimitarra",
	"Berber",
	"Tōkamachi",
	"María la Baja",
	"Liujiaxia",
	"Hikari",
	"Wofotang",
	"Oroville",
	"Évreux",
	"Özgön",
	"Adeje",
	"Wilson",
	"Okhtyrka",
	"Padre Garcia",
	"Lawrence",
	"Donglizhuang",
	"Bad Oeynhausen",
	"Villaguay",
	"Muhanga",
	"Jiashizhuang",
	"Portage",
	"Christchurch",
	"Nomimachi",
	"Albury",
	"Minot",
	"Empoli",
	"Samaniego",
	"Cwmbran",
	"Yong’an",
	"Pasacao",
	"Changyŏn",
	"Dunwoody",
	"Ad Darwa",
	"Beberibe",
	"Poway",
	"Libertador General San Martín",
	"Haverford",
	"Monopoli",
	"Magpet",
	"Agustín Codazzi",
	"Santa Rosa",
	"Prievidza",
	"Lecco",
	"San Jacinto",
	"San Benedetto del Tronto",
	"Lesnoy",
	"Carmen de Viboral",
	"Freising",
	"Palencia",
	"Troy",
	"Newark",
	"Starachowice",
	"Kalamansig",
	"La Reja",
	"Cuyahoga Falls",
	"Ascoli Piceno",
	"Lower Paxton",
	"Rheda-Wiedenbrück",
	"Downers Grove",
	"Jales",
	"Libungan",
	"Bedford",
	"Reedley",
	"Dublin",
	"Marana",
	"Kiblawan",
	"Hamtic",
	"Al Mayādīn",
	"Claveria",
	"Huaquillas",
	"Martina Franca",
	"São Gonçalo do Amarante",
	"Murray",
	"Bornheim",
	"Omitama",
	"Roswell",
	"Pascagoula",
	"Martigues",
	"Waingapu",
	"Brandon",
	"Batajnica",
	"Bom Conselho",
	"Sanare",
	"Tuckahoe",
	"Poblacion",
	"El Jem",
	"Dachau",
	"Suwa",
	"Ami",
	"Jasdan",
	"Ladysmith",
	"Kozlu",
	"Minalin",
	"Alaminos",
	"Gronau",
	"Garmsār",
	"Settimo Torinese",
	"’Aïn Azel",
	"Wakema",
	"Port Macquarie",
	"Rimouski",
	"St. Louis Park",
	"Cuxhaven",
	"Angol",
	"San Narciso",
	"Peñablanca",
	"Yarumal",
	"Namtu",
	"Rio Grande City",
	"Maţāy",
	"Draper",
	"Gavá",
	"Rumia",
	"Dongshan",
	"Harderwijk",
	"Sibonga",
	"Corato",
	"Kołobrzeg",
	"Zutphen",
	"Sanmu",
	"Palm Springs",
	"Beveren",
	"Kurganinsk",
	"Alsdorf",
	"Straubing",
	"San Pedro Mixtepec",
	"Paducah",
	"‘Akko",
	"Baião",
	"Findlay",
	"Ishigaki",
	"Dornbirn",
	"Damāvand",
	"Bel Air South",
	"Bulungu",
	"Kyle",
	"Tarnobrzeg",
	"Longtoushan Jiezi",
	"Děčín",
	"Durham",
	"Xique-Xique",
	"Campi Bisenzio",
	"Santa Catarina Ixtahuacán",
	"Monroe",
	"Rio Grande da Serra",
	"Beypazarı",
	"Zhujiacun",
	"Lincoln",
	"Pangkou",
	"Turda",
	"Ocoee",
	"Brianka",
	"Burleson",
	"Mauriti",
	"Kita",
	"Finike",
	"Donetsk",
	"Alta Gracia",
	"Shimotsuchō-kominami",
	"Farmers Branch",
	"Paterno",
	"Semara",
	"East Lansing",
	"Uniontown",
	"Yongping",
	"Kashira",
	"Shelekhov",
	"Jeffersonville",
	"Winder",
	"Wauwatosa",
	"Asturias",
	"Sison",
	"Presidente Dutra",
	"Vila Verde",
	"Ena",
	"Mengmeng",
	"Ridderkerk",
	"Skierniewice",
	"Littleton",
	"General Tinio",
	"Amulung",
	"Otradnyy",
	"Aringay",
	"Soest",
	"Tonami",
	"Conceição do Araguaia",
	"Cumberland",
	"Higashine",
	"Mapastepec",
	"Monte Carmelo",
	"San Francisco",
	"Stade",
	"Diamantina",
	"Mediaş",
	"Cedar Hill",
	"Santana do Ipanema",
	"Carrollton",
	"Impasugong",
	"Golpāyegān",
	"Charleville-Mézières",
	"Dīla",
	"Rancho Santa Margarita",
	"Jiquilisco",
	"Pakenham",
	"Lousada",
	"Brive-la-Gaillarde",
	"Santa Ignacia",
	"Masinloc",
	"Kuilsrivier",
	"Yecun",
	"Cornélio Procópio",
	"Atalaia",
	"Washington",
	"East Brunswick",
	"Luwuk",
	"Bantacan",
	"Saint-Herblain",
	"Tuba",
	"Penco",
	"Herzogenrath",
	"Ribeirão",
	"Caramoan",
	"Vyshniy Volochëk",
	"Saint-Priest",
	"Al Madrah Samā’il",
	"Al Majma‘ah",
	"Beavercreek",
	"João Pinheiro",
	"Dulag",
	"Niagara Falls",
	"Hitachi-ota",
	"Snizhne",
	"Amahai",
	"McLean",
	"Ipiaú",
	"Rieti",
	"President Roxas",
	"‘Āmūdā",
	"Isabel",
	"Dumingag",
	"San Lorenzo",
	"Chaïdári",
	"East Providence",
	"Jitaicun",
	"Kafue",
	"Kutno",
	"Livny",
	"Aznā",
	"East Honolulu",
	"Chesterfield",
	"La Vega",
	"Capão Bonito",
	"San Pedro",
	"San José Pinula",
	"Ban Plai Bua Phatthana",
	"Santa Ana Chiautempan",
	"Lohja",
	"Qingyuan",
	"San Andrés de Sotavento",
	"Sanski Most",
	"Chaparral",
	"Bothell",
	"Jobabo",
	"Villa Constitución",
	"Andes",
	"Saint-Malo",
	"West Orange",
	"Ouled Moussa",
	"Tangdukou",
	"Kingisepp",
	"Talghar",
	"Liulin",
	"Fellbach",
	"Daxiang",
	"Yabrūd",
	"Blois",
	"Tomioka",
	"Glenview",
	"Ban Bang Mae Nang",
	"Yehe",
	"Tursunzoda",
	"Ayapel",
	"Oberursel",
	"Mentor",
	"Antalaha",
	"Mabini",
	"Puertollano",
	"Landau",
	"Keller",
	"Barras",
	"Zarzal",
	"Douz",
	"Zarinsk",
	"Huatan",
	"Marhanets",
	"Schwerte",
	"Poso",
	"Urrao",
	"Tres Arroyos",
	"Aubagne",
	"Nysa",
	"Calimaya",
	"Hà Tiên",
	"Neunkirchen",
	"Qiutouzhen",
	"Danville",
	"Noboribetsu",
	"Guajará-Mirim",
	"Frosinone",
	"Pio Duran",
	"Spring Hill",
	"Al Quşayr",
	"Norala",
	"Roseville",
	"Cubulco",
	"Wangtan",
	"Roldanillo",
	"Filderstadt",
	"Siocon",
	"Fastiv",
	"Nanzhuangzhen",
	"Tocoa",
	"Yashan",
	"Valencia",
	"Nakai",
	"Agrínio",
	"Talisay",
	"Lukavac",
	"San Pascual",
	"Dongzhuosu",
	"Dülmen",
	"Shimeo",
	"Rosario",
	"Fangguan",
	"Lubny",
	"Tamagawa",
	"San Francisco de los Romo",
	"Pontevedra",
	"Berëzovskiy",
	"Jablonec nad Nisou",
	"Vercelli",
	"Mahayag",
	"Elmhurst",
	"Carcassonne",
	"São Mateus do Sul",
	"Niquelândia",
	"Hof",
	"Veldhoven",
	"Dauis",
	"Medianeira",
	"Cabreúva",
	"New Philadelphia",
	"Melle",
	"Santa Cruz do Rio Pardo",
	"Perinton",
	"Terracina",
	"Sandusky",
	"Puerto Villarroel",
	"Jocotepec",
	"Shimenzhai",
	"Eusébio",
	"Hyvinkää",
	"El Dorado Hills",
	"Cornwall",
	"Puerto Tejada",
	"Morrinhos",
	"Salina",
	"Euclid",
	"Gryazi",
	"Kingman",
	"Augusto Correa",
	"Sena Madureira",
	"Panay",
	"Fray Bartolomé de Las Casas",
	"Carmen",
	"Dendermonde",
	"Sirinhaém",
	"Albuera",
	"Kilmarnock",
	"Ayungon",
	"Berekum",
	"Tibigan",
	"Châlons-en-Champagne",
	"Río Tercero",
	"Kurayoshi",
	"Montecristi",
	"Kasaoka",
	"Torzhok",
	"Zwijndrecht",
	"Chichigalpa",
	"Gotha",
	"Anshan",
	"Capannori",
	"Tingo María",
	"Dumarao",
	"Tallbīsah",
	"Jammalamadugu",
	"Lodi",
	"Farmington",
	"Ocampo",
	"Santiago Atitlán",
	"Santo Antônio da Platina",
	"Takashima",
	"Marino",
	"Bünde",
	"Weatherford",
	"Middletown",
	"Inabanga",
	"Mairinque",
	"Roseburg",
	"Biloxi",
	"Maragogipe",
	"Wittenberg",
	"Roskilde",
	"Leavenworth",
	"Megion",
	"Salon-de-Provence",
	"Bājil",
	"Manono",
	"Xingji",
	"Hokota",
	"Pontal",
	"Aksay",
	"Fort Pierce",
	"Shima",
	"Weinheim",
	"Livramento de Nossa Senhora",
	"Barotac Viejo",
	"Winter Garden",
	"Mladá Boleslav",
	"Oro Valley",
	"Ourém",
	"Santa Rosa del Sur",
	"Erkrath",
	"Freeport City",
	"Iranduba",
	"Caiguantun",
	"Guaramirim",
	"Wao",
	"Morgan Hill",
	"Ciudad Constitución",
	"Haymana",
	"Potomac",
	"Pinehurst",
	"Chippenham",
	"Changchong",
	"Rodgau",
	"Winchester",
	"Danville",
	"Rockwall",
	"Mata de São João",
	"Pilar",
	"Dracena",
	"Xisa",
	"Salvador",
	"Stouffville",
	"Kariya",
	"Dingle",
	"Cascina",
	"Pontes e Lacerda",
	"Hokuto",
	"Katipunan",
	"Coachella",
	"Olhão",
	"Baijie",
	"Jones",
	"Malinao",
	"Salcedo",
	"Remedios",
	"São Bento",
	"New Washington",
	"Rāmeswaram",
	"Albstadt",
	"Cuautepec de Hinojosa",
	"Wake Forest",
	"Brühl",
	"Alcamo",
	"Saint-Germain-en-Laye",
	"San Pedro Ayampuc",
	"Odiongan",
	"Pearl City",
	"Beaufort West",
	"Radā‘",
	"Aliwal North",
	"Wallsend",
	"Tangjia",
	"Goianira",
	"Cerquilho Velho",
	"San Pelayo",
	"Kamen’-na-Obi",
	"Tupelo",
	"San Pedro Perulapán",
	"Nongzhangjie",
	"Carnot",
	"Châteauroux",
	"Uruará",
	"Shazhou",
	"Birāk",
	"Pinamar",
	"Georgina",
	"South Brunswick",
	"Guinayangan",
	"Alcira",
	"West Seneca",
	"Bingmei",
	"Svitlovodsk",
	"Victoriaville",
	"Ruzayevka",
	"Dębica",
	"Biella",
	"Actopan",
	"Brejo Santo",
	"Gloria",
	"Brejo da Madre de Deus",
	"Bunawan",
	"Attleboro",
	"Tank",
	"Seabra",
	"Sogod",
	"Sugito",
	"Pine Bluff",
	"Dimitrovgrad",
	"Wiener Neustadt",
	"Marion",
	"Afula",
	"Shangshan",
	"Hassi Messaoud",
	"Beiwanglizhen",
	"Evesham",
	"Liuquan",
	"Bruchsal",
	"Yaopu",
	"Middletown",
	"Grantham",
	"Xinguara",
	"Tres Valles",
	"Rabinal",
	"Buenos Aires",
	"Hilo",
	"Kimry",
	"Sète",
	"Shaḩḩāt",
	"Barra de São Francisco",
	"Shchūchīnsk",
	"Parma",
	"Sidcup",
	"Senigallia",
	"Yatağan",
	"Kaufbeuren",
	"Medemblik",
	"Pederneiras",
	"Mount Pleasant",
	"Rasskazovo",
	"Currais Novos",
	"Lapa",
	"Nadym",
	"Marau",
	"Dráma",
	"Vallenar",
	"Dunakeszi",
	"Pemangkat",
	"Volkhov",
	"Houbu",
	"Lufkin",
	"Darcheh",
	"Zhovti Vody",
	"Middleton",
	"Falkensee",
	"Paraguaçu Paulista",
	"Bexhill",
	"Chusovoy",
	"Singhanakhon",
	"Oranienburg",
	"Jisr ash Shughūr",
	"Manitowoc",
	"Nidadavole",
	"Maitum",
	"Yaoquan",
	"Taketoyo",
	"Lake Ridge",
	"Selma",
	"Manacor",
	"Talagutong",
	"Ban Pet",
	"Strongsville",
	"Louveira",
	"Seferhisar",
	"Arboletes",
	"Garça",
	"União",
	"Vernon",
	"Wallingford",
	"Paraíba do Sul",
	"Kaarst",
	"Tiberias",
	"Lal-lo",
	"Etten-Leur",
	"Vlissingen",
	"Masuda",
	"Les Sables-d’Olonne",
	"Qingfengdian",
	"Changtoushang",
	"Webster",
	"Abovyan",
	"Danville",
	"Mamanguape",
	"Petatlán",
	"Prostějov",
	"Kabasalan",
	"Bietigheim-Bissingen",
	"Bridgewater",
	"Quincy",
	"Memmingen",
	"San Juan y Martínez",
	"Lancaster",
	"Riverton",
	"The Colony",
	"Simojovel de Allende",
	"Neustadt am Rübenberge",
	"Bassano del Grappa",
	"São José de Mipibu",
	"Upper Hutt",
	"Urbandale",
	"Narvacan",
	"Tuodian",
	"Alcala",
	"Lehrte",
	"Rheden",
	"Volksrust",
	"São Benedito",
	"Plainfield",
	"Monroe",
	"Lombard",
	"Prescott",
	"Balimbing",
	"Dikili",
	"Korogwe",
	"Krasnodon",
	"Hanerik",
	"Xin’ansuo",
	"Zevenaar",
	"Eureka",
	"Addanki",
	"Panambi",
	"Igarapé",
	"Tuy",
	"Catacamas",
	"Hagi",
	"Malabang",
	"Kuybyshev",
	"Sayreville",
	"Alghero",
	"Saint-Eustache",
	"Lupao",
	"Otofuke",
	"Enfield",
	"Altamonte Springs",
	"Jinku",
	"Yartsevo",
	"San Cristóbal Totonicapán",
	"Del Rio",
	"Voluntari",
	"Kaštel Stari",
	"Libona",
	"Pantanal",
	"Comendador",
	"Xinjun",
	"Maştağa",
	"Kamen",
	"Naţanz",
	"Menghan",
	"Pinhal",
	"Zongolica",
	"Tayug",
	"Bountiful",
	"Jiaohe",
	"Himi",
	"Claveria",
	"Gravina in Puglia",
	"Khutubi",
	"Desert Hot Springs",
	"Nabire",
	"Peachtree Corners",
	"Montevista",
	"Villeta",
	"Mombaça",
	"Santa Rita do Sapucaí",
	"Buguias",
	"Haltom City",
	"Nandazhang",
	"Castro",
	"Bāniyās",
	"’Aïn Arnat",
	"Veruela",
	"Ad Dir‘īyah",
	"Vinzons",
	"Roseller Lim",
	"Southington",
	"Şān al Ḩajar al Qiblīyah",
	"Pagsanjan",
	"Worksop",
	"Ormond Beach",
	"Banisilan",
	"Sambava",
	"Taniyama-chūō",
	"Takāb",
	"Erkelenz",
	"Zhangliangcun",
	"Salto de Pirapora",
	"Paraty",
	"Basoko",
	"Cutler Bay",
	"Of",
	"Macenta",
	"Shinshiro",
	"Chefchaouene",
	"Xihuachi",
	"Adrian",
	"Nuevo México",
	"Lota",
	"Otwock",
	"Goose Creek",
	"Eṭ Ṭaiyiba",
	"Concepcion",
	"Moorhead",
	"Itaberaí",
	"Angoulême",
	"Westfield",
	"Santa Cruz",
	"Çeşme",
	"Ponte de Lima",
	"Musina",
	"Venray",
	"Birsk",
	"Noordwijk",
	"Quinte West",
	"Wismar",
	"Presidente Epitácio",
	"São Lourenço do Sul",
	"Catende",
	"Bahía Honda",
	"Istres",
	"Manchester",
	"Zhangcun",
	"Tamworth",
	"Denia",
	"Pedro Celestino Negrete",
	"Heist-op-den-Berg",
	"Makinohara",
	"Zharkent",
	"Jaro",
	"Sawākin",
	"Hueyapan de Ocampo",
	"Henrietta",
	"Tantangan",
	"Esperanza",
	"Germering",
	"Kemer",
	"Rantepao",
	"Civitanova Marche",
	"Angadanan",
	"Lábrea",
	"Bourg-en-Bresse",
	"Brea",
	"Nova Viçosa",
	"Klamath Falls",
	"Al Wajh",
	"King’s Lynn",
	"Siegburg",
	"San Lorenzo",
	"Cordon",
	"Ambatondrazaka",
	"Aguazul",
	"Shuilou",
	"Véroia",
	"Billerica",
	"Nadi",
	"Tiel",
	"Kobayashi",
	"Daram",
	"Ciechanów",
	"Přerov",
	"Nansan",
	"Visconde do Rio Branco",
	"Cumaribo",
	"Kolchugino",
	"Whanganui",
	"Jesús María",
	"São Gabriel da Cachoeira",
	"Lubao",
	"Lancaster",
	"Bihać",
	"Rosário",
	"Channelview",
	"Huizen",
	"Entre Rios",
	"Yendi",
	"Poptún",
	"Gualeguay",
	"Jatibonico",
	"Albano Laziale",
	"Dolores",
	"Nettetal",
	"Osinniki",
	"Sieradz",
	"Pilar",
	"Gifhorn",
	"Centralia",
	"Sidi Mohamed Lahmar",
	"Gallatin",
	"Atenco",
	"Miranda",
	"Tepalcatepec",
	"Zvolen",
	"Samborondón",
	"Dreieich",
	"Shenjiatun",
	"Loufan",
	"Hulin",
	"Lucena",
	"Stevens Point",
	"Nurmijärvi",
	"Orlândia",
	"Borken",
	"Burke",
	"Vawkavysk",
	"Vera Cruz",
	"Manay",
	"Brentwood",
	"Obita",
	"Amberg",
	"Eisenach",
	"Santa Quitéria",
	"North Fort Myers",
	"Temsia",
	"Egg Harbor",
	"Fianga",
	"Hwange",
	"Sansanné-Mango",
	"Avezzano",
	"Leigh",
	"Santo Antônio de Pádua",
	"Mariel",
	"Laatzen",
	"Kotabumi",
	"Bacnotan",
	"Qiryat Yam",
	"Edmonds",
	"Charlottetown",
	"Dusheng",
	"Ban Na Pa",
	"Mikołów",
	"Apache Junction",
	"Fairfield",
	"Bury Saint Edmunds",
	"Oakley",
	"El Charco",
	"Rio Negrinho",
	"Hutchinson",
	"Loreto",
	"Shebekino",
	"Santa Helena",
	"Tineghir",
	"Manjuyod",
	"Mascouche",
	"Futtsu",
	"Dayr Mawās",
	"Wangyuanqiao",
	"Cuihua",
	"West Vancouver",
	"Capão da Canoa",
	"Toboso",
	"Shepetivka",
	"Shū",
	"Alerce",
	"Greenwood",
	"Tomé",
	"Cortlandt",
	"Salaberry-de-Valleyfield",
	"Balykchy",
	"Heinsberg",
	"Uden",
	"La Orotava",
	"São Lourenço",
	"Itápolis",
	"Liantang",
	"Richmond",
	"Machang",
	"Lørenskog",
	"Linden",
	"Puyallup",
	"Santa Rita",
	"Yangcunzai",
	"Qəzyan",
	"Rājgīr",
	"Rouyn-Noranda",
	"San Donà di Piave",
	"Wancheng",
	"Köniz",
	"Imperia",
	"Homburg",
	"Remanso",
	"Beitbridge",
	"Ansbach",
	"Gay",
	"Itabaianinha",
	"Huntsville",
	"Macerata",
	"Dacun",
	"Urbana",
	"Aurich",
	"Qapshaghay",
	"Nordhausen",
	"Sbiba",
	"Beverly",
	"Safonovo",
	"Villaba",
	"Montenegro",
	"Clovis",
	"Monterotondo",
	"Amontada",
	"Cáceres",
	"Lingquan",
	"Lower Tungawan",
	"Monchegorsk",
	"Santa Maria da Boa Vista",
	"Soran",
	"Coburg",
	"Statesboro",
	"Poti",
	"Hobbs",
	"Sarāb",
	"Su-ngai Kolok",
	"Barcellona-Pozzo di Gotto",
	"Litian Gezhuang",
	"Korenovsk",
	"Qiryat Moẕqin",
	"McMinnville",
	"Castres",
	"Zagora",
	"Labangan",
	"Tuntum",
	"Schertz",
	"Tequila",
	"Nasipit",
	"Moatize",
	"Merano",
	"Shimotsuma",
	"Yinggen",
	"Matoupu",
	"Greenock",
	"Beigang",
	"Alès",
	"Xinleitou",
	"Nürtingen",
	"Spassk-Dal’niy",
	"Tādif",
	"Puerto del Rosario",
	"Tulun",
	"Uspantán",
	"Rajaori",
	"Wunstorf",
	"Pedra Branca",
	"Puerto Real",
	"Seevetal",
	"Sassuolo",
	"Oliveira",
	"Jardinópolis",
	"Bayt al Faqīh",
	"San",
	"Oviedo",
	"Nanbaishezhen",
	"Odenton",
	"Kwidzyn",
	"Chojnice",
	"Satka",
	"Grove City",
	"Santa Maria da Vitória",
	"Berkeley",
	"Sumisip",
	"Pôrto de Moz",
	"Nanbei",
	"Schwabach",
	"Mozdok",
	"Timmins",
	"Königswinter",
	"Wentzville",
	"San Jacinto",
	"Kalilangan",
	"North Brunswick",
	"Myski",
	"Santa Cruz de Los Taques",
	"La Quinta",
	"Lobo",
	"Strezhevoy",
	"Pueblo Nuevo",
	"Podilsk",
	"General Mamerto Natividad",
	"Freiberg",
	"Panchimalco",
	"Siay",
	"Sarandë",
	"Santa Rita",
	"Ust’-Kut",
	"Świnoujście",
	"Yakacık",
	"Aguilar",
	"Moises Padilla",
	"Ihnāsyā al Madīnah",
	"Alhaurín de la Torre",
	"Lokeren",
	"Herstal",
	"Yuzawa",
	"El Nido",
	"Cantù",
	"Nanmeng",
	"Weslaco",
	"Sorel-Tracy",
	"Karabulak",
	"Tuchín",
	"President Quirino",
	"Ladispoli",
	"Labason",
	"Naini Tal",
	"Yangtangxu",
	"Ayagöz",
	"Zanesville",
	"Newnan",
	"Kitaibaraki",
	"Almenara",
	"Shakopee",
	"San Jose",
	"Nowa Sól",
	"Brighton",
	"Catonsville",
	"Wijchen",
	"Gaolincun",
	"Rancho Palos Verdes",
	"Kirchheim unter Teck",
	"Verkhnyaya Salda",
	"Thionville",
	"Baichigan",
	"Mableton",
	"Erzin",
	"Andradas",
	"Charqueadas",
	"New Tecumseth",
	"Leinfelden-Echterdingen",
	"Santo Niño",
	"Mount Laurel",
	"San Gregorio de Nigua",
	"Coppell",
	"Mandaon",
	"Culasi",
	"Sovetsk",
	"Linton Hall",
	"Pananaw",
	"Antequera",
	"Girau do Ponciano",
	"Moline",
	"Matnog",
	"Tanxia",
	"El Tocuyo",
	"Aquidauana",
	"Colinas",
	"Tiegan",
	"Niquero",
	"Arauquita",
	"Penn Hills",
	"Chaves",
	"Hellevoetsluis",
	"Delaware",
	"Kovvūr",
	"Gap",
	"Anyuan",
	"Yingzhou Linchang",
	"Banaybanay",
	"Temryuk",
	"Guambog",
	"Westfield",
	"Guaduas",
	"Carranglan",
	"Järvenpää",
	"Vyshneve",
	"Quezon",
	"Basud",
	"Bensheim",
	"Bolesławiec",
	"Shelton",
	"Proper Bansud",
	"Bāft",
	"Marcianise",
	"Jeremoabo",
	"Westerville",
	"Lemgo",
	"Ciudad Barrios",
	"Mucuri",
	"Manassas",
	"Eberswalde",
	"Victoria",
	"Ciudad Darío",
	"Tonbridge",
	"Salinópolis",
	"Dois Vizinhos",
	"Pirmasens",
	"Kearny",
	"Chambas",
	"Ostfildern",
	"Maarssen",
	"Los Banos",
	"Bagua Grande",
	"Pyt’-Yakh",
	"El Tumbador",
	"Santa Elena",
	"Novozybkov",
	"Richmond",
	"Osório",
	"Compiègne",
	"Taicheng",
	"Kabuga",
	"Inhambupe",
	"Chebarkul",
	"Romny",
	"Hempfield",
	"Spanish Fork",
	"Dronten",
	"Woodstock",
	"São Miguel d’Oeste",
	"Belleville",
	"Mangaratiba",
	"Maluso",
	"Ílhavo",
	"Maplewood",
	"Ait Ourir",
	"Rio Real",
	"Leyte",
	"Uruaçu",
	"Cuito Cuanavale",
	"Maria Aurora",
	"Colomiers",
	"Schwäbisch Hall",
	"Abadan",
	"Lugoj",
	"Zoumi",
	"Pereslavl’-Zalesskiy",
	"Petrich",
	"Zhujiezhen",
	"Tomar",
	"Cateel",
	"Weißenfels",
	"Laouamra",
	"Pie de Pató",
	"Boucherville",
	"Panitan",
	"Yalutorovsk",
	"Brumadinho",
	"Arys",
	"Komono",
	"Sciacca",
	"Vahdat",
	"Channarāyapatna",
	"Pottsville",
	"Corigliano Calabro",
	"Simão Dias",
	"Buxtehude",
	"Santo Tomas",
	"Columbia",
	"La Unión",
	"Bayındır",
	"Pechora",
	"Altos",
	"Heemskerk",
	"Bartlett",
	"Curuçá",
	"Bluefield",
	"Fitchburg",
	"Orange",
	"Rovereto",
	"Braine-l’Alleud",
	"Marion",
	"Iesi",
	"Hückelhoven",
	"Huaura",
	"San Fernando de Henares",
	"Farnham",
	"Uray",
	"Maihar",
	"Halberstadt",
	"Cedar Falls",
	"Cumbal",
	"Yian",
	"Maintal",
	"Neumarkt",
	"Bình Long",
	"Pyu",
	"Buffalo Grove",
	"Jamestown",
	"Asunción Mita",
	"Woodlawn",
	"Arcos",
	"Hofheim",
	"Caraga",
	"São Manuel",
	"Aracataca",
	"Clarksburg",
	"Anglet",
	"Baksan",
	"Zainsk",
	"Royal Palm Beach",
	"South Upi",
	"Imbituba",
	"Ōzu",
	"Abucay",
	"Freital",
	"Túquerres",
	"Löhne",
	"Jipijapa",
	"Al Qā‘idah",
	"Marion",
	"Eboli",
	"Oświęcim",
	"Covington",
	"Junnar",
	"Schorndorf",
	"Santa Cruz",
	"Belaya Kalitva",
	"Coram",
	"Tietê",
	"Friendswood",
	"Arawa",
	"Águas Belas",
	"Považská Bystrica",
	"Boa Esperança",
	"Itoigawa",
	"Indian Trail",
	"Kasumigaura",
	"San Agustín Acasaguastlán",
	"Sejenane",
	"Woburn",
	"Camargo",
	"Medgidia",
	"Balabac",
	"Sarāqib",
	"Santiago Nonualco",
	"Mtsensk",
	"Tutayev",
	"Tipton",
	"Longtang",
	"Ban Bang Khu Lat",
	"Villanueva",
	"The Acreage",
	"Pénjamo",
	"Bom Jardim",
	"Tucumã",
	"Oroqen Zizhiqi",
	"Hangu",
	"Liloy",
	"Raub",
	"Holyoke",
	"Amursk",
	"Nueva Valencia",
	"Glenrothes",
	"Tucuran",
	"Maddaloni",
	"South Valley",
	"Altagracia de Orituco",
	"Draguignan",
	"Fondi",
	"Santa Cruz",
	"Payabon",
	"Isfara",
	"Llanera",
	"Plasencia",
	"Otavalo",
	"Zhongbai",
	"Anda",
	"Ettlingen",
	"Alashankou",
	"Jiaozishan",
	"Tarauacá",
	"Ouro Branco",
	"Jayrūd",
	"Bacolor",
	"Porto Alexandre",
	"Torrington",
	"Laurel",
	"Cedar City",
	"Fasano",
	"Marlboro",
	"Halle",
	"Hinatuan",
	"Voghera",
	"Bañga",
	"Rauma",
	"Völklingen",
	"Cabatuan",
	"Villa de San Diego de Ubaté",
	"Acevedo",
	"Xinlizhuang",
	"Curitibanos",
	"Yecapixtla",
	"Écija",
	"Mambusao",
	"Michalovce",
	"Esperantina",
	"Lloret de Mar",
	"Weibo",
	"Ouro Preto d’Oeste",
	"Matías Romero",
	"Würselen",
	"Crystal Lake",
	"Calexico",
	"Lake Oswego",
	"Várzea da Palma",
	"Amherst",
	"Kurobeshin",
	"Villamontes",
	"Apsheronsk",
	"Buchholz in der Nordheide",
	"Líbano",
	"Dolores",
	"Mibu",
	"Muskogee",
	"Bosconia",
	"Steyr",
	"Balaoan",
	"Romeoville",
	"Plant City",
	"Kibawe",
	"Mettmann",
	"Città di Castello",
	"Santo Antônio",
	"Iturama",
	"Rosário do Sul",
	"Solan",
	"Santo Tomas",
	"Novodvinsk",
	"Manalapan",
	"Pagalungan",
	"New Berlin",
	"Schio",
	"La Chaux-de-Fonds",
	"Sama",
	"Socorro",
	"Modugno",
	"Narón",
	"Araquari",
	"Tampakan",
	"Santa Eulalia del Río",
	"Roy",
	"Security-Widefield",
	"Villa del Carbón",
	"Oneşti",
	"Takikawa",
	"Dupnitsa",
	"Marlborough",
	"Milton",
	"Stendal",
	"Novo Horizonte",
	"Afuá",
	"Sanjiang",
	"San Giuliano Milanese",
	"Hillsborough",
	"Trofa",
	"Polanco",
	"Tagudin",
	"Tianchang",
	"Pokrov",
	"Yangfang",
	"Andover",
	"President Manuel Acuña Roxas",
	"Borlänge",
	"Issaquah",
	"San Juan del Cesar",
	"Benjamin Constant",
	"Ahaus",
	"Presidente Venceslau",
	"Niutuo",
	"Carini",
	"Ninove",
	"Sayansk",
	"Essex",
	"Szigetszentmiklós",
	"Parral",
	"Vendrell",
	"Tlalixcoyan",
	"Tabogon",
	"Plaridel",
	"Eccles",
	"Draa el Mizan",
	"San Miguel",
	"Apan",
	"Socorro",
	"Palmas",
	"São Francisco do Conde",
	"Lasam",
	"Bartlesville",
	"Borba",
	"Niederkassel",
	"Tarazá",
	"Northampton",
	"Higashimatsushima",
	"Volodymyr-Volynskyi",
	"Ilmenau",
	"Hatfield",
	"Bulalacao",
	"Lancaster",
	"Streamwood",
	"Germantown",
	"Great Yarmouth",
	"Sidi Bibi",
	"Neu Isenburg",
	"Yelizovo",
	"El Viejo",
	"Langen",
	"Carol Stream",
	"Sungandiancun",
	"Salinas",
	"Asheboro",
	"Kalispell",
	"Cayambe",
	"Arecibo",
	"Xiwanzi",
	"Baikonur",
	"Dwārka",
	"Changchunpu",
	"Ōmagari",
	"São Mateus do Maranhão",
	"Lynnwood",
	"Siguatepeque",
	"Mooresville",
	"Monreale",
	"Kachkanar",
	"Al Mālikīyah",
	"Brookfield",
	"Landgraaf",
	"Alcala",
	"Nuevitas",
	"Mobo",
	"Shijiazhuangnan",
	"San Dionisio",
	"Joué-lés-Tours",
	"Igarapé-Açu",
	"Crestview",
	"Horizon West",
	"Toumodi",
	"Inashiki",
	"Kurchatov",
	"Pirna",
	"Caivano",
	"Rtishchevo",
	"Pala Oua",
	"Clinton",
	"Ancud",
	"Moses Lake",
	"Souma",
	"Vasylkiv",
	"Dingras",
	"Mira",
	"Umi",
	"Gonzaga",
	"Hitachiomiya",
	"Tagbina",
	"Tshela",
	"Malalag",
	"Dubno",
	"Galeana",
	"New Castle",
	"Olive Branch",
	"Bitterfeld",
	"Enterprise",
	"Dom Pedrito",
	"Bautzen",
	"Los Palacios y Villafranca",
	"Sakuragawa",
	"La Libertad",
	"Sun City",
	"Melchor Ocampo",
	"Mondragon",
	"Skelmersdale",
	"Fürstenfeldbruck",
	"Balcarce",
	"Mission",
	"Pervomaisk",
	"Winslow",
	"Groton",
	"Bukama",
	"Agoncillo",
	"Tuusula",
	"Promissão",
	"Fāraskūr",
	"Pedro II",
	"Dabola",
	"Gujō",
	"Cajibío",
	"Los Palacios",
	"Warren",
	"Duncanville",
	"São Fidélis",
	"Kirkkonummi",
	"Dzhankoi",
	"Sibaté",
	"Naga",
	"Nové Zámky",
	"Mechelen-aan-de-Maas",
	"Segovia",
	"Maayon",
	"Alimodian",
	"Amadeo",
	"Siniloan",
	"La Unión",
	"Hurst",
	"Clermont",
	"Mariinsk",
	"Wheeling",
	"Bayan",
	"Guariba",
	"Malilipot",
	"Vallehermoso",
	"Krasnoufimsk",
	"Żary",
	"Xiaguanying",
	"Valladolid",
	"São Gabriel",
	"Abinsk",
	"Dubbo",
	"Darāw",
	"San Felipe Orizatlán",
	"Lanyi",
	"Uryupinsk",
	"Jarosław",
	"Monan",
	"Veghel",
	"Kardítsa",
	"Khalkhāl",
	"Pacifica",
	"Maddela",
	"Aliança",
	"Siyang",
	"Caibarién",
	"Konakovo",
	"Lebanon",
	"Itiúba",
	"Falāvarjān",
	"Américo Brasiliense",
	"Sassandra",
	"Várzea Alegre",
	"Talacogon",
	"Ventanas",
	"Rizal",
	"Hanumānnagar",
	"Taysan",
	"Land O' Lakes",
	"Venlo",
	"Maimbung",
	"San Jacinto de Buena Fe",
	"Bodocó",
	"Yanqi",
	"Caltagirone",
	"Kaka",
	"Ibara",
	"Eagle Mountain",
	"Usinsk",
	"Sharypovo",
	"Moncada",
	"Atamyrat",
	"Alekseyevka",
	"Villagarcía de Arosa",
	"Bol’shoy Kamen’",
	"Ciudad Manuel Doblado",
	"Katsuragi",
	"Reynoldsburg",
	"Sabang",
	"Pathanāmthitta",
	"Krasnyy Sulin",
	"Lyudinovo",
	"Oxford",
	"Rosenberg",
	"Pitangueiras",
	"Martinez",
	"Zavolzhye",
	"Mapandan",
	"Balud",
	"Rio Pardo",
	"Cerveteri",
	"Pleasant Grove",
	"Badian",
	"Bonito",
	"Margosatubig",
	"Dengtangcun",
	"Backnang",
	"Severna Park",
	"Xinpo",
	"Teijlingen",
	"Houghton le Spring",
	"Taounate",
	"Izobil’nyy",
	"Huber Heights",
	"Xinqiao",
	"Vaudreuil-Dorion",
	"Goes",
	"Sampués",
	"Maroantsetra",
	"Capelinha",
	"Boryslav",
	"Geleen",
	"Yamen",
	"Las Navas",
	"Yuzhnouralsk",
	"Spoleto",
	"Yajalón",
	"Güira de Melena",
	"Aristóbulo del Valle",
	"Mullaittivu",
	"Formia",
	"Mixquiahuala de Juarez",
	"Menomonee Falls",
	"Hajīn",
	"Bathurst",
	"Granbury",
	"Clarin",
	"Waxahachie",
	"Kamp-Lintfort",
	"Valrico",
	"Sighetu Marmaţiei",
	"Argun",
	"Constitución",
	"Greven",
	"Kapiri Mposhi",
	"Madridejos",
	"Maragondon",
	"Amarante do Maranhão",
	"Santiago",
	"Sanchahe",
	"Pentecoste",
	"Česká Lípa",
	"Marratxi",
	"Shakhtīnsk",
	"Papenburg",
	"Akhtubinsk",
	"Amargosa",
	"Cabugao",
	"Ipueiras",
	"Spišská Nová Ves",
	"Dibulla",
	"Lluchmayor",
	"Stirling",
	"San Juan",
	"Emirdağ",
	"Guaraciaba do Norte",
	"Al Qaryatayn",
	"Kasibu",
	"Ma‘arratmişrīn",
	"Holly Springs",
	"Malmesbury",
	"Butler",
	"Mieres",
	"Juanjuí",
	"Pimenta Bueno",
	"Dajiecun",
	"Geraldton",
	"Pamplona",
	"Lampa",
	"Albertville",
	"Aboisso",
	"Haugesund",
	"Uchaly",
	"Tunduma",
	"Sunbury",
	"London",
	"Réo",
	"Wesseling",
	"São Gonçalo dos Campos",
	"Kahrīzak",
	"Granadero Baigorria",
	"Kalinkavichy",
	"San Nicolas",
	"Porteirinha",
	"Ossining",
	"Chieri",
	"Igrejinha",
	"Nowra",
	"Cottage Grove",
	"Daiyue",
	"Kailua",
	"Beckum",
	"Jelilyüzi",
	"Königs Wusterhausen",
	"Frolovo",
	"Alapayevsk",
	"Pojuca",
	"El Bordo",
	"St. Marys",
	"Guaratuba",
	"Shiji",
	"Tosno",
	"Santa Cruz",
	"Chrzanów",
	"Fermo",
	"Dalnegorsk",
	"Itarema",
	"Fengguangcun",
	"Lilio",
	"Nandaime",
	"Goryachiy Klyuch",
	"Malgobek",
	"Afogados da Ingazeira",
	"Mechanicsville",
	"Bouznika",
	"San Blas",
	"Compostela",
	"Santa Rosa de Osos",
	"Mölndal",
	"Risalpur Cantonment",
	"Cleburne",
	"Shaxi",
	"Yirga ‘Alem",
	"Warendorf",
	"Bacong",
	"Holubivske",
	"Yugorsk",
	"Oswego",
	"Shrewsbury",
	"Melgar",
	"Patnongon",
	"Baco",
	"Lierre",
	"Nossa Senhora da Glória",
	"Oleiros",
	"Aritao",
	"Navarre",
	"Lębork",
	"Don Benito",
	"Petroşani",
	"Mol",
	"Montluçon",
	"Koryazhma",
	"Oregon City",
	"Poblacion",
	"Araçuaí",
	"Maşyāf",
	"Mabuhay",
	"Winter Springs",
	"Tibú",
	"Morondava",
	"Parkland",
	"Daying",
	"Kizilyurt",
	"Pahrump",
	"Tunzi",
	"Carpentersville",
	"Penfield",
	"Mangai",
	"Irbit",
	"Frankston",
	"Cisterna di Latina",
	"Wildomar",
	"Greenfield",
	"Suhl",
	"Licata",
	"Tudela",
	"Maasin",
	"Braintree",
	"Protvino",
	"Silves",
	"Rezh",
	"Rock Island",
	"Cheltenham",
	"Kannan",
	"Olot",
	"Baishi Airikecun",
	"Akto",
	"Yasynuvata",
	"Rapu-Rapu",
	"Anzhou",
	"Água Preta",
	"Beslan",
	"Erding",
	"Evans",
	"Haverstraw",
	"José de Freitas",
	"Zuojiawu",
	"Qulsary",
	"Partizansk",
	"Elixku",
	"Kampene",
	"Tsubata",
	"West Fargo",
	"Kyshtym",
	"Lesozavodsk",
	"Boston",
	"Mount Juliet",
	"Aourir",
	"San Juan",
	"San Andres",
	"Oakton",
	"Georgetown",
	"Portage",
	"Netishyn",
	"Dabutou",
	"Aguelmous",
	"Caimito",
	"Owasso",
	"Commack",
	"Xindian",
	"Santaluz",
	"Sokol",
	"Cassino",
	"Dagua",
	"Chimboy Shahri",
	"Foley",
	"Villajoyosa",
	"Komatsushimachō",
	"Bangar",
	"Beiya",
	"Buug",
	"Sayula",
	"Vác",
	"Basilisa",
	"Mannar",
	"Billingham",
	"Gorinchem",
	"Yejituo",
	"Torres Novas",
	"Kanada",
	"Qalādizay",
	"New Albany",
	"Pamplona",
	"Rossano",
	"Lajedo",
	"Benton",
	"Zhaoqiao",
	"Nabas",
	"Voi",
	"Wangtuan",
	"La Macarena",
	"Köneürgench",
	"Nuoro",
	"Jamindan",
	"Puerto Galera",
	"Esplanada",
	"Kambove",
	"Danihe",
	"Esik",
	"Oras",
	"Morro do Chapéu",
	"Airdrie",
	"Gramado",
	"Brant",
	"Esquel",
	"Meridian",
	"Brejo",
	"Manucan",
	"Clifton Park",
	"Pinerolo",
	"Torre-Pacheco",
	"Andújar",
	"Zhetisay",
	"Belalcázar",
	"Kinel",
	"Luna",
	"Bulicun",
	"Kodaikānal",
	"Haomen",
	"Lakeshore",
	"Porsgrunn",
	"Dar Chabanne",
	"Campos Novos",
	"Masasi",
	"Kehl",
	"Innisfil",
	"Barra Bonita",
	"Francavilla Fontana",
	"Agudos",
	"Mangalia",
	"Bettendorf",
	"Yorktown",
	"São Paulo de Olivença",
	"Dunedin",
	"Hilliard",
	"Merritt Island",
	"Warrnambool",
	"Emsdetten",
	"Kaniama",
	"Nueve de Julio",
	"São João da Barra",
	"Phenix City",
	"Sambir",
	"Addison",
	"Mühlhausen",
	"Selçuk",
	"Camamu",
	"Coesfeld",
	"Sonsón",
	"Roseville",
	"Castricum",
	"Careiro",
	"Tambulig",
	"Dama",
	"Ma‘alot Tarshīḥā",
	"Sankt Ingbert",
	"Zhmerynka",
	"Nyaungdon",
	"Udhampur",
	"Jasło",
	"Kerava",
	"Isla de Maipo",
	"Morohongō",
	"Oakville",
	"Nejapa",
	"Tucker",
	"Queanbeyan",
	"Moorpark",
	"Rende",
	"San Andres",
	"San Pablo",
	"Oeiras",
	"Monrovia",
	"Oak Creek",
	"Tomelloso",
	"San Bernardo del Viento",
	"Rifu",
	"La Ciotat",
	"Brighton",
	"La Libertad",
	"Claremont",
	"Pingshang",
	"Oswego",
	"Post Falls",
	"Smarhon’",
	"Mankayan",
	"Peachtree City",
	"Palapye",
	"Alicia",
	"Lumba-a-Bayabao",
	"Bantay",
	"Aţ Ţurrah",
	"Ban Mueang Na Tai",
	"Batobato",
	"Araguatins",
	"Augusta",
	"Zacatepec",
	"Jyväskylän Maalaiskunta",
	"Avdiivka",
	"Magsaysay",
	"Natick",
	"French Valley",
	"Somma Vesuviana",
	"Farafenni",
	"Miranda de Ebro",
	"Portsmouth",
	"Canicattì",
	"Tuttlingen",
	"Virú",
	"Cento",
	"Galloway",
	"Shawnee",
	"Hellendoorn",
	"Sertânia",
	"Adrano",
	"Conegliano",
	"Onteniente",
	"Real",
	"Ewing",
	"Gillette",
	"Enrile",
	"Limburg",
	"Tooele",
	"Guayaramerín",
	"Porta Westfalica",
	"Itapicuru",
	"Fraiburgo",
	"Salinas",
	"Miyajima",
	"Accrington",
	"Zvishavane",
	"Rongwo",
	"Velasco Ibarra",
	"Zhentang",
	"Trumbull",
	"Boyarka",
	"Prattville",
	"Kamenka",
	"Chortoq",
	"Belluno",
	"Woodburn",
	"Kakhovka",
	"Chernyakhovsk",
	"Prince Albert",
	"Samal",
	"Calumet City",
	"Praya",
	"San Juan Capistrano",
	"Vyazniki",
	"Toretsk",
	"Apodi",
	"Colinas do Tocantins",
	"Mengdong",
	"Sion",
	"Kumo",
	"Além Paraíba",
	"Águilas",
	"Xonobod",
	"Ẕefat",
	"Chancay",
	"Karak",
	"Ingelheim",
	"Estreito",
	"Imzouren",
	"Guacarí",
	"Tallkalakh",
	"Pak Chong",
	"Dayr Ḩāfir",
	"Borbon",
	"Andover",
	"Franklin",
	"Marialva",
	"Usuki",
	"Cooper City",
	"Dinas",
	"Burriana",
	"Leyland",
	"Kundian",
	"Laur",
	"Svetlograd",
	"Teruel",
	"Santiago de Tolú",
	"Guane",
	"Caluya",
	"Timbío",
	"El Mirage",
	"Villenave-d’Ornon",
	"San Fernando",
	"San Nicolas",
	"Meadow Woods",
	"Turiaçu",
	"Woodley",
	"Sinsheim",
	"LaGrange",
	"Cambrils",
	"Třebíč",
	"Cieszyn",
	"La Vergne",
	"Tayasan",
	"Guilderland",
	"Middletown",
	"Mount Pleasant",
	"Bagabag",
	"Carrollwood",
	"Boyarka",
	"Junction City",
	"Inver Grove Heights",
	"Gürpınar",
	"Cegléd",
	"Câmara de Lobos",
	"Pennsauken",
	"Kilimli",
	"Kuala Kapuas",
	"Santo Domingo",
	"Uster",
	"San Sebastián de Yalí",
	"Cha-am",
	"Lökbatan",
	"Schoten",
	"La Troncal",
	"Bougouni",
	"Mörfelden-Walldorf",
	"Minami-Bōsō",
	"Lixingcun",
	"Saint-Chamond",
	"Dietzenbach",
	"Pôrto União",
	"Kraśnik",
	"Tocancipá",
	"José Bonifácio",
	"Gigante",
	"Yangambi",
	"Toritama",
	"Meppen",
	"Guamá Abajo",
	"Douzhuang",
	"Quimbaya",
	"Monroe",
	"Savonlinna",
	"Randallstown",
	"Yayladağı",
	"Nanzhiqiu",
	"Lanciano",
	"Cutral-Có",
	"Matinhos",
	"Lage",
	"Vichuga",
	"Santana do Paraíso",
	"Shenjiabang",
	"Gahanna",
	"Kahoku",
	"Cartagena del Chairá",
	"Saint-Benoît",
	"Aïne Draham",
	"Tago",
	"Kamata",
	"San Miguel Ixtahuacán",
	"Sanford",
	"Riviera Beach",
	"Tamuín",
	"Ikot Abasi",
	"Armenia",
	"Saint-Raphaël",
	"Pilar",
	"Antiguo Cuscatlán",
	"Riachão do Jacuípe",
	"Wildwood",
	"Pilão Arcado",
	"Paracuru",
	"Angra do Heroísmo",
	"Cloppenburg",
	"Millerovo",
	"La Palma",
	"Sogod",
	"Osimo",
	"Akouda",
	"Cieza",
	"Buldon",
	"Upper Arlington",
	"Dagami",
	"Yarumal",
	"Saarlouis",
	"Langford Station",
	"Auxerre",
	"Ōzu",
	"Bradford West Gwillimbury",
	"Aurora",
	"Radebeul",
	"Auburn",
	"Yellandu",
	"Bad Vilbel",
	"Bou Salem",
	"Plainfield",
	"Luga",
	"Tecuci",
	"Crema",
	"Giddalūr",
	"Colwyn Bay",
	"Cansanção",
	"Voznesensk",
	"Fonseca",
	"Massapê",
	"Gorizia",
	"Tall Salḩab",
	"Siltepec",
	"Ieper",
	"Wermelskirchen",
	"Oak Harbor",
	"Yarīm",
	"Mirnyy",
	"Winsen",
	"Curaçá",
	"João Câmara",
	"Venaria Reale",
	"Yefremov",
	"Olney",
	"Třinec",
	"Umarkot",
	"Kōṯah-ye ‘As̲h̲rō",
	"Wodonga",
	"Gorna Oryahovitsa",
	"Gasan",
	"Sabanagrande",
	"Nova Olinda do Norte",
	"Tamra",
	"San Vicente de Chucurí",
	"Campbell River",
	"Datteln",
	"Aborlan",
	"Adamantina",
	"Ubajara",
	"Chelmsford",
	"Azazga",
	"Matou",
	"Champerico",
	"San Luis de Sincé",
	"Iyo",
	"Puerto López",
	"New City",
	"Kempen",
	"Bochil",
	"Qadsayyā",
	"Valencia",
	"Casilda",
	"Formigine",
	"Owings Mills",
	"Seelze",
	"Raxruhá",
	"Palauig",
	"Bagamoyo",
	"Jaguariaíva",
	"Acaxochitlán",
	"Siayan",
	"Zaventem",
	"Blagoveshchensk",
	"Campoalegre",
	"Curralinho",
	"Canaman",
	"Korkino",
	"Baja",
	"Leer",
	"La Porte",
	"Armação dos Búzios",
	"Odorheiu Secuiesc",
	"Meyzieu",
	"Piuí",
	"Itamarandiba",
	"Bethlehem",
	"Freehold",
	"Aguaí",
	"Aznakayevo",
	"Loudaya",
	"Pola",
	"Victoria",
	"Nevers",
	"Barra do Choça",
	"São Raimundo Nonato",
	"Mâcon",
	"Feijó",
	"Sint-Pieters-Leeuw",
	"Brunswick",
	"East Point",
	"Tôrres",
	"DeLand",
	"Tanguá",
	"Magsaysay",
	"Cumberland",
	"San Antonio",
	"Iława",
	"Martinez",
	"Pleasant Hill",
	"Angamāli",
	"Diaowo",
	"Orangevale",
	"Aksay",
	"Guying",
	"Ringsaker",
	"Merrillville",
	"Dzierżoniów",
	"Stow",
	"Yangquan",
	"San Luis",
	"Sagnay",
	"Shingū",
	"Calhoun",
	"Ban Ang Sila",
	"Sarpol-e Z̄ahāb",
	"Crailsheim",
	"Leawood",
	"Gutalac",
	"Zelenokumsk",
	"Abashiri",
	"São Luís do Quitunde",
	"Shangzhen",
	"Merseburg",
	"Phra Phutthabat",
	"Menlo Park",
	"Haguenau",
	"Zweibrücken",
	"Canguaretama",
	"Tábor",
	"Biharamulo",
	"Sibuco",
	"Sun Prairie",
	"Dakota Ridge",
	"IJsselstein",
	"Bugojno",
	"Jaguaribe",
	"Pandan",
	"Coventry",
	"Cachoeira",
	"Dueñas",
	"Arcata",
	"Esposende",
	"Humenné",
	"Chacabuco",
	"Guanhães",
	"Shrīrangapattana",
	"Parnarama",
	"Balingen",
	"Capela",
	"Ballesteros",
	"Hemer",
	"São Luís Gonzaga",
	"Cañete",
	"Guimbal",
	"Barsinghausen",
	"Glastonbury",
	"Lockport",
	"Skhira",
	"Tabango",
	"Kópavogur",
	"Centenario",
	"Zhangzhengqiao",
	"Mandaguari",
	"Wedel",
	"Albany",
	"Cícero Dantas",
	"Mnasra",
	"Cabrobó",
	"Zimapan",
	"Pullman",
	"Songcaozhen",
	"Banes",
	"Gandara",
	"Gerāsh",
	"Palapag",
	"Vassouras",
	"Râmnicu Sărat",
	"Youwangjie",
	"Padre Bernardo",
	"Imbatug",
	"Exmouth",
	"Boardman",
	"Taiobeiras",
	"Xincheng",
	"Ahrensburg",
	"Nacogdoches",
	"Merauke",
	"Kearney",
	"Vestavia Hills",
	"Sual",
	"Casale Monferrato",
	"North Ridgeville",
	"Steinfurt",
	"Windsor",
	"Socorro",
	"Vibo Valentia",
	"Randolph",
	"Mogpog",
	"Cambrai",
	"Marignane",
	"Basista",
	"Missão Velha",
	"Gladstone",
	"Câmpina",
	"Gulkevichi",
	"Znojmo",
	"Bogorodsk",
	"Businga",
	"Komárno",
	"Villena",
	"Inca",
	"Datang",
	"Shāhīn Dezh",
	"Redmond",
	"Caldono",
	"Valuyki",
	"Quinchía",
	"Firestone",
	"Cravinhos",
	"San Enrique",
	"Goshen",
	"Goiatuba",
	"Vitrolles",
	"Carmel",
	"Panglao",
	"Butte",
	"Russellville",
	"Dartmouth",
	"Nowy Targ",
	"São Luís de Montes Belos",
	"Grass Valley",
	"Menen",
	"Paşcani",
	"Peyziwat",
	"Termoli",
	"Geldern",
	"Viernheim",
	"Parkland",
	"Pomerode",
	"Romans-sur-Isère",
	"Wickford",
	"Binche",
	"Uonuma",
	"Dimona",
	"San Agustín",
	"Mascalucia",
	"Cabanglasan",
	"Cishan",
	"Bariri",
	"Goch",
	"Piombino",
	"São José do Egito",
	"Six-Fours-les-Plages",
	"Jagna",
	"Atiquizaya",
	"Stuhr",
	"Franklin",
	"Castelfranco Veneto",
	"San Antonio de los Baños",
	"Kennesaw",
	"Almeirim",
	"Victoria Falls",
	"Spruce Grove",
	"San Juan Nepomuceno",
	"Zhaitangcun",
	"Almendralejo",
	"Majagual",
	"Adelanto",
	"Irvine",
	"Sneek",
	"São José do Belmonte",
	"Ken Caryl",
	"Carlsbad",
	"Braço do Norte",
	"Eirunepé",
	"Deggendorf",
	"Domingos Martins",
	"University Place",
	"Yinchengpu",
	"Baltiysk",
	"Douglasville",
	"Salisbury",
	"Kadingilan",
	"Olutanga",
	"Oulad Zemam",
	"Furmanov",
	"Laramie",
	"San Luis",
	"Tynaarlo",
	"Nizhneudinsk",
	"Lake Stevens",
	"Valparaiso",
	"Moose Jaw",
	"Custódia",
	"Fairborn",
	"Reghin",
	"Ban Pak Phun",
	"Camiri",
	"Santa Pola",
	"Estero",
	"Mason",
	"Uelzen",
	"Miguel Alves",
	"Tekes",
	"Ouro Fino",
	"Penha",
	"Bell Ville",
	"Cottonwood Heights",
	"Kaneohe",
	"Sonoma",
	"Luuq",
	"Sulop",
	"Geraardsbergen",
	"Ronda",
	"Villafranca di Verona",
	"Bugasong",
	"Dej",
	"Capoocan",
	"Léo",
	"Poço Redondo",
	"Mella",
	"Galesburg",
	"Jishi",
	"Penticton",
	"Gīmbī",
	"Gurupá",
	"Didouche Mourad",
	"San Marcelino",
	"Todos Santos Cuchumatán",
	"Xiadian",
	"Belen",
	"Chilecito",
	"Teykovo",
	"Sukhoy Log",
	"Vyatskiye Polyany",
	"Puerto Rico",
	"Mosonmagyaróvár",
	"Warsaw",
	"San Antero",
	"Caçapava do Sul",
	"Balasan",
	"Monção",
	"Cantel",
	"Bayog",
	"Knokke-Heist",
	"Allacapan",
	"Fatehpur Sīkri",
	"Korschenbroich",
	"Korsakov",
	"Lianmuqin Kancun",
	"San Antonio",
	"Ibaté",
	"Lebowakgomo",
	"São Domingos do Maranhão",
	"Dois Irmãos",
	"Mildura",
	"Asse",
	"San Dimas",
	"Sakaiminato",
	"Corinto",
	"Batouri",
	"Rheinfelden (Baden)",
	"Ayvacık",
	"São Bento",
	"Zhitiqara",
	"Tortosa",
	"Ciudad Tecún Umán",
	"Shangcaiyuan",
	"João Alfredo",
	"Dana Point",
	"San Javier",
	"Iperó",
	"Panjakent",
	"Languyan",
	"Port Moody",
	"Riverhead",
	"Castro",
	"Touros",
	"Falls",
	"Slobodskoy",
	"Shaliuhe",
	"Midlothian",
	"Malangas",
	"Newark",
	"Santa Maria",
	"Alamogordo",
	"Bôca do Acre",
	"Kasulu",
	"Leganes",
	"Alabaster",
	"Pikesville",
	"Hlukhiv",
	"Qarqan",
	"Deer Park",
	"Dumfries",
	"Năvodari",
	"Derry",
	"Aranda de Duero",
	"Shōbara",
	"Nokia",
	"Woodridge",
	"Baturité",
	"Abbiategrasso",
	"Kolín",
	"Kohtla-Järve",
	"Biberach",
	"Southport",
	"Bridgeton",
	"Conceição de Jacuípe",
	"Sitangkai",
	"Greer",
	"Matalom",
	"Tayshet",
	"Umaria",
	"Tavda",
	"La Virginia",
	"Cuenca",
	"Tobias Fornier",
	"Lexington",
	"Police",
	"Ourilândia do Norte",
	"Zgorzelec",
	"Oldenzaal",
	"Tynda",
	"Huangzhuang",
	"Poconé",
	"Springville",
	"Obukhiv",
	"Espinho",
	"Columbio",
	"Canavieiras",
	"Saratoga Springs",
	"Levice",
	"Pambujan",
	"Temascalapa",
	"Pivijay",
	"Shatura",
	"Camacan",
	"Bragado",
	"Copperas Cove",
	"Epsom",
	"Fraijanes",
	"San Quintin",
	"Casiguran",
	"Castelfranco Emilia",
	"Příbram",
	"Khvāf",
	"San Lazzaro di Savena",
	"Jaguarari",
	"Floresta",
	"Fucheng",
	"Bou Ahmed",
	"Correntina",
	"Motherwell",
	"Lucera",
	"Catubig",
	"American Fork",
	"Shimotoba",
	"Franklin",
	"Bad Nauheim",
	"Sanarate",
	"Munai",
	"Andover",
	"Matthews",
	"Kamaishi",
	"Barugo",
	"De Aar",
	"Medina",
	"Rio das Pedras",
	"Laojiezi",
	"Djenné",
	"Massafra",
	"Carangola",
	"Arteijo",
	"Mashiki",
	"San Luis",
	"Wakabadai",
	"Plaisir",
	"Puebloviejo",
	"Mushie",
	"Timimoun",
	"Liptovský Mikuláš",
	"Sarıkaya",
	"Numancia",
	"Woodstock",
	"Maragogi",
	"Forbe Oroya",
	"Bardejov",
	"Marantao",
	"Descalvado",
	"Itzehoe",
	"Bautista",
	"Florence",
	"Glossop",
	"Jülich",
	"Leamington",
	"Arroyomolinos",
	"Lampertheim",
	"Jalajala",
	"Imbituva",
	"Esperança",
	"Chernushka",
	"Santa Ana",
	"Mataquescuintla",
	"Winsford",
	"Barão de Cocais",
	"Bernburg",
	"Dellys",
	"Northbrook",
	"Baraguá",
	"Gandu",
	"Mazarrón",
	"Ōiso",
	"Wernigerode",
	"Ózd",
	"Trëkhgornyy",
	"Vicência",
	"Forchheim",
	"St. Charles",
	"Arbaoua",
	"Coatepec Harinas",
	"Rosmalen",
	"Claveria",
	"Kuji",
	"Dao",
	"Santa Cruz das Palmeiras",
	"Oeiras do Pará",
	"Banate",
	"Fairmont",
	"Ţafas",
	"Puerto Escondido",
	"Manises",
	"Naumburg",
	"Baraawe",
	"Şāfītā",
	"Presidente Figueiredo",
	"Kaarina",
	"São Miguel Arcanjo",
	"Ylöjärvi",
	"Vechta",
	"Bocas de Satinga",
	"Nazaré da Mata",
	"Payshamba Shahri",
	"Altenburg",
	"Pombal",
	"Baiyan",
	"Silvia",
	"Nurlat",
	"Três Marias",
	"Pomian",
	"Espigão D’Oeste",
	"Yuzhne",
	"Bacarra",
	"Camaiore",
	"Concón",
	"Abulug",
	"Favara",
	"Dobryanka",
	"Ridgecrest",
	"Cururupu",
	"El Difícil",
	"Lower Makefield",
	"Initao",
	"Mateur",
	"Pitanga",
	"East Kelowna",
	"Câmpulung",
	"Aalsmeer",
	"Gödöllő",
	"Paratinga",
	"Redcliff",
	"Totana",
	"Ostrogozhsk",
	"Salina",
	"Deurne",
	"Mioveni",
	"Inzá",
	"Aloguinsan",
	"Lawrence",
	"Castro-Urdiales",
	"São Joaquim de Bicas",
	"Guindulman",
	"Juban",
	"Massillon",
	"Spanaway",
	"Kulebaki",
	"Rio Branco do Sul",
	"Diplahan",
	"Bonifacio",
	"Réthymno",
	"Grodzisk Mazowiecki",
	"Starkville",
	"Yorii",
	"Slantsy",
	"Yankou",
	"I-n-Salah",
	"Louvain-la-Neuve",
	"Milazzo",
	"Osvaldo Cruz",
	"Warminster",
	"La Calera",
	"Beixinzhuang",
	"Hoyerswerda",
	"Staryy Beyneu",
	"Nova Russas",
	"Maputsoe",
	"Châtellerault",
	"Plottier",
	"Landecy",
	"Clarence",
	"Batan",
	"Brasília de Minas",
	"Olintepeque",
	"Harker Heights",
	"Tawaramoto",
	"Sarno",
	"Mohyliv-Podilskyi",
	"Zentsujichó",
	"Cachoeira Paulista",
	"Georgsmarienhütte",
	"Elk Grove Village",
	"Zaojiao",
	"Zangang",
	"San Pedro de Urabá",
	"Fürstenwalde",
	"Kaysville",
	"Guararapes",
	"Achim",
	"Draa Ben Khedda",
	"Southlake",
	"Ferry Pass",
	"Kristianstad",
	"Savage",
	"Salug",
	"Menglie",
	"Des Moines",
	"Ferreñafe",
	"Bethel Park",
	"San Andrés Itzapa",
	"São José da Tapera",
	"Mayantoc",
	"Santo Domingo Suchitepéquez",
	"Lupi Viejo",
	"Capalonga",
	"Ra’s Ghārib",
	"Curanilahue",
	"Dreux",
	"Princeton",
	"Warwick",
	"Mangūr",
	"Ribeira Grande",
	"Ilhabela",
	"Dumalinao",
	"Shimizuchō",
	"Koupéla",
	"Laoac East",
	"Jaguaruana",
	"Nagato",
	"Vintar",
	"Capulhuac",
	"Dania Beach",
	"Kangasala",
	"Luuk",
	"Mendez-Nuñez",
	"Āreka",
	"Bandeirantes",
	"Oer-Erkenschwick",
	"Cheb",
	"Lonneker",
	"Bowling Green",
	"Tuzantán",
	"Wevelgem",
	"Springfield",
	"Igbaras",
	"Lower Macungie",
	"Primorsko-Akhtarsk",
	"Itatiaia",
	"Xangda",
	"Pedana",
	"Grottaglie",
	"Mirnyy",
	"Novovoronezh",
	"Laranjeiras do Sul",
	"San Manuel",
	"Ōdachō-ōda",
	"East Lake",
	"Whitstable",
	"Uglich",
	"Dongxianpo",
	"Douar Oulad Hssine",
	"Coos Bay",
	"Smithfield",
	"Vall de Uxó",
	"Delbrück",
	"Sechura",
	"Rome",
	"Palmeira",
	"San Luis",
	"Quintero",
	"Yaguarón",
	"Colíder",
	"Jāsim",
	"Balimbing",
	"Trinidad",
	"Clearfield",
	"Serdobsk",
	"Zapala",
	"Jiming",
	"Liberty",
	"Midsalip",
	"Donggou",
	"Shentang",
	"Naguilian",
	"Alba",
	"Partinico",
	"Macau",
	"Milaor",
	"Remedios",
	"Pinukpuk",
	"Asilah",
	"Tongkou",
	"Cabaiguán",
	"El Golea",
	"Oristano",
	"Pekin",
	"Westlake",
	"Badoc",
	"Bahharet Oulad Ayyad",
	"Herrenberg",
	"San Remigio",
	"Dar Ould Zidouh",
	"Nilka",
	"Chillicothe",
	"Jaral del Progreso",
	"Pativilca",
	"La Verne",
	"Prairieville",
	"Paipa",
	"Namegata",
	"Komagane",
	"Santa Rita",
	"Placerville",
	"Ivaiporã",
	"Bel Air North",
	"Shahedian",
	"Nanyuki",
	"Guachavés",
	"San Isidro",
	"Sakhnīn",
	"Turicato",
	"Montebelluna",
	"Grajaú",
	"Sasaguri",
	"El Oro de Hidalgo",
	"I‘zāz",
	"Manlius",
	"Ruy Barbosa",
	"Katagami",
	"Touba",
	"Dandenong",
	"San Pedro de Ribas",
	"Redan",
	"Martinsville",
	"Gevelsberg",
	"Val-d’Or",
	"Boghni",
	"Pueblo West",
	"Chajul",
	"Buzovna",
	"Avola",
	"Radnor",
	"Owen Sound",
	"Payao",
	"Velikiy Ustyug",
	"Gubbio",
	"San Giuliano Terme",
	"Chum Phae",
	"San Roque",
	"Temascaltepec de González",
	"Kyzyl-Kyya",
	"Kalingalan Caluang",
	"Kasimov",
	"Kuroishi",
	"Haan",
	"Sitionuevo",
	"Kulat",
	"Radolfzell am Bodensee",
	"Kotovsk",
	"Weil am Rhein",
	"Níjar",
	"Balyqshy",
	"San Pedro Sacatepéquez",
	"Jesús María",
	"Fallbrook",
	"Dongmaying",
	"Žepče",
	"Bedworth",
	"Łowicz",
	"Ibaiti",
	"Nueva Imperial",
	"Tecozautla",
	"Willingboro",
	"Jacareacanga",
	"Exu",
	"Castelvetrano",
	"Santa Bárbara",
	"Spring Valley",
	"Tanmen",
	"Morro Agudo",
	"Ayabe",
	"Espinosa",
	"Kreuztal",
	"Cantilan",
	"Frederico Westphalen",
	"Waterloo",
	"San Andrés Xecul",
	"Yawatahama-shi",
	"Mae Sot",
	"Carballo",
	"Nardò",
	"Oudenaarde",
	"Lingig",
	"Fengrenxu",
	"Hopkinsville",
	"Mocajuba",
	"Zima",
	"Ponta de Pedras",
	"Kosonsoy",
	"Făgăraş",
	"Ban Chang",
	"Calubian",
	"Balungao",
	"Iporá",
	"Huangxicun",
	"Puerto Wilches",
	"Verbania",
	"Rochester",
	"Lavras da Mangabeira",
	"Maubeuge",
	"South Riding",
	"Saint-Médard-en-Jalles",
	"Minas Novas",
	"Marks",
	"Rheinberg",
	"Kostopil",
	"Santa Fé do Sul",
	"Deinze",
	"Centereach",
	"Neira",
	"Savé",
	"Geesthacht",
	"Milledgeville",
	"Mograne",
	"Parambu",
	"Mateus Leme",
	"Stratford",
	"Wanghong Yidui",
	"Yingyangcun",
	"Santa María La Pila",
	"Syracuse",
	"Netivot",
	"Kaminokawa",
	"Bielsk Podlaski",
	"Araçoiaba da Serra",
	"Barrinha",
	"Trebinje",
	"Tiquipaya",
	"Sherwood",
	"Posse",
	"Valkenswaard",
	"Alacuás",
	"Wenxian Chengguanzhen",
	"Halden",
	"Quesada",
	"Lloydminster",
	"Simunul",
	"Lumbang",
	"Garner",
	"Guamo",
	"Key West",
	"Coria del Río",
	"Caracal",
	"Cauquenes",
	"Pointe-Claire",
	"Moalboal",
	"Kudymkar",
	"Dracut",
	"Skellefteå",
	"Abra de Ilog",
	"Ganderkesee",
	"Novo Cruzeiro",
	"Petersburg",
	"Al Ḩusaynīyah",
	"North Olmsted",
	"Kahului",
	"Ostuni",
	"Kandalaksha",
	"Bagre",
	"Manduria",
	"Alice Springs",
	"Yaita",
	"Duanshan",
	"Wheat Ridge",
	"Fruit Cove",
	"Conceição da Barra",
	"Rosignano Marittimo",
	"Tracuateua",
	"Cibolo",
	"Buurhakaba",
	"Marovoay",
	"Calape",
	"Barra dos Coqueiros",
	"Bauko",
	"Pindaré-Mirim",
	"Taozhuangcun",
	"Vitória do Mearim",
	"Uran",
	"Biritiba-Mirim",
	"Alliance",
	"Balatan",
	"Circasia",
	"San Vicente",
	"Ipubi",
	"Łuków",
	"Boxtel",
	"Banning",
	"Warwick",
	"Burgess Hill",
	"Getafe",
	"Laguna Hills",
	"Central Islip",
	"Marigliano",
	"Lagos",
	"Bogoroditsk",
	"Lushnjë",
	"Athens",
	"Princeton",
	"Yingshouyingzi",
	"Weyhe",
	"Flores da Cunha",
	"Werl",
	"Culleredo",
	"Loughton",
	"Jimalalud",
	"Orillia",
	"Baixo Guandu",
	"Minaçu",
	"Ciudad Vieja",
	"Diemen",
	"Tokmak",
	"Magsingal",
	"Chitral",
	"Paraparaumu",
	"Atotonilco de Tula",
	"Cândido Mota",
	"Ski",
	"Masaki",
	"Afonso Cláudio",
	"Falmouth",
	"Bramsche",
	"Orcutt",
	"Santa Maria",
	"Portão",
	"Lemery",
	"Naugatuck",
	"Eldersburg",
	"Río Grande",
	"Tewksbury",
	"Itabela",
	"Muritiba",
	"Jacaré",
	"Columbus",
	"Bochnia",
	"Schönebeck",
	"Tijucas",
	"Soledade",
	"Rio Pardo de Minas",
	"Mundelein",
	"Sapa Sapa",
	"Bielawa",
	"Fair Oaks",
	"Matões",
	"San Andrés del Rabanedo",
	"Dyurtyuli",
	"Harpenden",
	"Santa Vitória do Palmar",
	"Mocímboa da Praia",
	"Huamachuco",
	"Nartkala",
	"Əmircan",
	"Rexburg",
	"Burgdorf",
	"Needham",
	"Rostov",
	"Ambanja",
	"Einbeck",
	"Trindade",
	"Sombrio",
	"Neuruppin",
	"Mossendjo",
	"Fabriano",
	"Nogales",
	"Bela Cruz",
	"Nicholasville",
	"Amatenango de la Frontera",
	"Goleta",
	"Opelika",
	"Laylá",
	"Newburgh",
	"Alma",
	"Algonquin",
	"Săcele",
	"Marrero",
	"Radcliffe",
	"Arcos de la Frontera",
	"Cranberry",
	"Gragnano",
	"Aiken",
	"Cicero",
	"Písek",
	"Unterschleißheim",
	"North Andover",
	"Lawrenceville",
	"Lohmar",
	"Winter Park",
	"Alcázar de San Juan",
	"Novoyavorovskoye",
	"Alegre",
	"Artëmovskiy",
	"Lake Magdalene",
	"Santa Maria",
	"Carshalton",
	"Arraial do Cabo",
	"Fitchburg",
	"Glória do Goitá",
	"Hajdúböszörmény",
	"San Roque",
	"Granger",
	"Qahderījān",
	"Guaíra",
	"Tekeli",
	"Wooster",
	"Catmon",
	"Synelnykove",
	"Treviglio",
	"Amagá",
	"Fountain",
	"Sandanski",
	"Orangeville",
	"Simbahan",
	"Emmen",
	"Nanwucun",
	"Pau dos Ferros",
	"Pápa",
	"Fort Erie",
	"Puerto de la Cruz",
	"Xinzhaidian",
	"Polillo",
	"Juquitiba",
	"Duanzhuang",
	"Torrijos",
	"Brooklyn Center",
	"Mallig",
	"Mława",
	"Barreirinha",
	"Banamba",
	"Draveil",
	"Mercedes",
	"Thung Song",
	"South Kingstown",
	"Piedra Blanca",
	"Victoria",
	"Kerrville",
	"Wuyuan",
	"San Jacinto",
	"Rapallo",
	"Vienne",
	"Barstow",
	"Ross",
	"Ennepetal",
	"Motomiya",
	"Ypacaraí",
	"Mehdya",
	"Chimākurti",
	"Riesa",
	"Mount Gambier",
	"Kirov",
	"Blagodarnyy",
	"Leusden",
	"Rio Preto da Eva",
	"Joaçaba",
	"G’ijduvon Shahri",
	"Canton",
	"Andernach",
	"Villa Donato Guerra",
	"Ob",
	"Bela Vista de Goiás",
	"Feteşti",
	"Sevenoaks",
	"Mataas Na Kahoy",
	"Auburn",
	"Osterholz-Scharmbeck",
	"Sibagat",
	"Tskhinvali",
	"Sibutu",
	"Crown Point",
	"Talusan",
	"Xinpo",
	"Slavgorod",
	"Windsor",
	"Kórinthos",
	"Itaporanga d’Ajuda",
	"Lakeside",
	"Taunusstein",
	"Deptford",
	"Sigma",
	"North Huntingdon",
	"Gloucester",
	"Ust’-Dzheguta",
	"Damulog",
	"Lalla Mimouna",
	"Horki",
	"Salou",
	"Hirakawachō",
	"Togitsu",
	"Iguape",
	"Télimélé",
	"Huanta",
	"Gurnee",
	"Carlos Barbosa",
	"Orangeburg",
	"Mont-de-Marsan",
	"Buguey",
	"Carmo do Paranaíba",
	"Galdácano",
	"Tactic",
	"Vinces",
	"Shetang",
	"Holladay",
	"Fuquay-Varina",
	"Chimichagua",
	"Valdepeñas",
	"Chamblee",
	"Kondopoga",
	"Comiso",
	"Dimataling",
	"Carney",
	"Xunjiansi",
	"Peru",
	"Kibiti",
	"Décines-Charpieu",
	"Cihuatlán",
	"Tsuruno",
	"Sergio Osmeña Sr",
	"Rushden",
	"Temse",
	"Kombissiri",
	"Augustów",
	"Upper Merion",
	"Cornelius",
	"Gorodets",
	"Matsubushi",
	"Trutnov",
	"North Tonawanda",
	"Talayan",
	"Sue",
	"Gaggenau",
	"Newington",
	"Catolé do Rocha",
	"Buenavista",
	"Sitges",
	"Bergen",
	"Los Gatos",
	"Mozhaysk",
	"Shangpa",
	"Moanda",
	"Bad Hersfeld",
	"Saint-Étienne-du-Rouvray",
	"Peñaranda",
	"South Lake Tahoe",
	"LaSalle",
	"Friedberg",
	"Clinton",
	"Merida",
	"Caramoran",
	"Northfleet",
	"Saratoga",
	"Hinunangan",
	"Kalgoorlie",
	"Tizi Gheniff",
	"Paraipaba",
	"Bra",
	"Planadas",
	"Gamu",
	"Brodnica",
	"Tabio",
	"Lismore",
	"L’Arbaa Naït Irathen",
	"Puente-Genil",
	"Guotang",
	"Sainte-Julie",
	"Shibancun",
	"Barbosa",
	"Tacuba",
	"Los Altos",
	"Pervomaiskyi",
	"Ballwin",
	"Barira",
	"Atascadero",
	"Claveria",
	"Barra Velha",
	"Selargius",
	"Alubijid",
	"North Royalton",
	"Budaörs",
	"Villa Ángela",
	"Schwedt (Oder)",
	"Heesch",
	"Saalfeld",
	"Meschede",
	"President Roxas",
	"Somerset",
	"Villa Regina",
	"Werve",
	"Siraha",
	"Neuburg",
	"Campo Alegre de Lourdes",
	"Canosa di Puglia",
	"Morong",
	"Degeh Bur",
	"Casma",
	"Devarkonda",
	"Nan’ao",
	"Merzig",
	"Asipovichy",
	"Bourèm Guindou",
	"Kelkheim (Taunus)",
	"Evaz",
	"Pantabangan",
	"Mavinga",
	"Leduc",
	"Seguin",
	"Arlon",
	"Santana do Acaraú",
	"Waltrop",
	"Tönisvorst",
	"Hampden",
	"Carinhanha",
	"Svendborg",
	"Giannitsá",
	"Arari",
	"Fortuna Foothills",
	"Fresno",
	"Cambuí",
	"Schwelm",
	"Milford Mill",
	"Cannock",
	"Pasadena",
	"Casa Branca",
	"San Rafael",
	"Souk et Tnine Jorf el Mellah",
	"Calintaan",
	"Stockbridge",
	"Tameslouht",
	"East Liverpool",
	"Triunfo",
	"Leonding",
	"Aral",
	"Madison Heights",
	"Gyöngyös",
	"Vaihingen an der Enz",
	"Kınık",
	"Capim",
	"Zhangguzhuang",
	"Oosterend",
	"Kabalo",
	"Prainha",
	"Purificación",
	"Livingston",
	"Mahón",
	"Williston",
	"Villa Gesell",
	"Labasa",
	"Askøy",
	"Dedovsk",
	"Heywood",
	"Shiyeli",
	"Matanog",
	"Bretten",
	"Crevillente",
	"Dipaculao",
	"Redondela",
	"Pontedera",
	"Mandalī",
	"Misilmeri",
	"Eagle",
	"Igarapava",
	"Parabiago",
	"Dzyarzhynsk",
	"Flandes",
	"Alushta",
	"Benito Soliven",
	"Rancharia",
	"Brecht",
	"Spinea",
	"Alquízar",
	"Campo Magro",
	"Friedberg",
	"Candijay",
	"Mangdongshan",
	"Maryville",
	"Atbasar",
	"Dumalag",
	"Mashan",
	"Rietberg",
	"Brunssum",
	"Xinmin",
	"General Nakar",
	"Piraju",
	"Asha",
	"Bosanska Krupa",
	"Anajás",
	"Walnut",
	"Toksun",
	"Shuangtian",
	"Lianga",
	"North Cowichan",
	"Mamaroneck",
	"Rendsburg",
	"Garden City",
	"Texarkana",
	"Güstrow",
	"Kent",
	"Landsberg",
	"Rota",
	"Rhennouch",
	"Santa Fe",
	"North Chicago",
	"Greenville",
	"Oelde",
	"Yangshuling",
	"Duluth",
	"Aberdeen",
	"Königsbrunn",
	"Winona",
	"Rotterdam",
	"Ivisan",
	"O'Fallon",
	"Drexel Heights",
	"Ukiah",
	"Atwater",
	"Bourgoin-Jallieu",
	"Maracanã",
	"Sanford",
	"Ja‘ār",
	"Nazaré",
	"Highland Park",
	"Westfield",
	"Hermiston",
	"Orchard Park",
	"Riihimäki",
	"Orlová",
	"Sint-Michielsgestel",
	"Fritissa",
	"Culemborg",
	"Desenzano del Garda",
	"Tredyffrin",
	"Xiaobazi",
	"Campello",
	"Ans",
	"Likino-Dulevo",
	"Timizart",
	"Tandubas",
	"Poggibonsi",
	"Rösrath",
	"Ibimirim",
	"Kavajë",
	"Gyula",
	"Kiskunfélegyháza",
	"Pittsford",
	"Capim Grosso",
	"Iguaba Grande",
	"Kostomuksha",
	"Ra’s al ‘Ayn",
	"Sopó",
	"Benhao",
	"Central",
	"Quivicán",
	"Dolores",
	"Şūrān",
	"Masiu",
	"East Lake-Orient Park",
	"Winnenden",
	"Bogdanovich",
	"Matouying",
	"Hattersheim",
	"Novaya Usman’",
	"Chicago Heights",
	"Boxmeer",
	"Divnogorsk",
	"Mondragone",
	"Orange",
	"Johnston",
	"Northeim",
	"Norwood",
	"Khmilnyk",
	"Limoeiro do Ajuru",
	"Capitán Bermúdez",
	"Namaacha",
	"East Fishkill",
	"Tubao",
	"Bühl",
	"Glenville",
	"Dasol",
	"Anadia",
	"Truskavets",
	"Duzhuang",
	"Redenção",
	"Carmagnola",
	"Moñitos",
	"Cañada de Gómez",
	"Shiqiao",
	"Chortkiv",
	"Balindong",
	"Pālpā",
	"Cyangugu",
	"Tabuleiro do Norte",
	"Prado",
	"Salem",
	"Vernon",
	"Tudela",
	"Newberg",
	"Viacha",
	"Aipe",
	"Voorhees",
	"Cottica",
	"Santa Quitéria do Maranhão",
	"Timbiras",
	"Ciying",
	"Örnsköldsvik",
	"Taquaritinga do Norte",
	"Bontoc",
	"Lewes",
	"Agde",
	"North Attleborough",
	"Aleysk",
	"Ituberá",
	"Washington",
	"Sant’Anastasia",
	"Balete",
	"Gubkinskiy",
	"Primero de Enero",
	"Oak Ridge",
	"La Concordia",
	"San Cristóbal",
	"Gonglang",
	"Cheshire",
	"Beverley",
	"Extremoz",
	"Crofton",
	"Vinaroz",
	"Springe",
	"Samokov",
	"Madīnat Zāyid",
	"Ban Phai",
	"Athens",
	"Chambly",
	"Pasuquin",
	"Winterswijk",
	"Old Harbour",
	"Laplace",
	"Nouna",
	"Benavente",
	"Mirandópolis",
	"Itaperuçu",
	"Goio-Erê",
	"Carpentras",
	"La Dorada",
	"Schwandorf",
	"Alatri",
	"Gagarin",
	"Tocache Nuevo",
	"San Javier",
	"Cervia",
	"New Panamao",
	"SeaTac",
	"Jauja",
	"Riosucio",
	"Staraya Russa",
	"Tanglou",
	"Langedijk",
	"Cajati",
	"Dongwang",
	"Nentón",
	"Salay",
	"Lavezares",
	"Höxter",
	"Xaxim",
	"Luboń",
	"La Calera",
	"Caetés",
	"Raytown",
	"Paragould",
	"Rosa Zarate",
	"Passira",
	"Graham",
	"Vihti",
	"Anilao",
	"Hanawa",
	"Krotoszyn",
	"Yemanzhelinsk",
	"Candelaria",
	"Southgate",
	"Caicedonia",
	"Calafell",
	"Meißen",
	"Barabinsk",
	"Pucón",
	"West Warwick",
	"Harrison",
	"Pulupandan",
	"Niles",
	"Fernandina Beach",
	"Siraway",
	"Chahe",
	"Kroměříž",
	"Timaru",
	"Mount Olive",
	"Big Spring",
	"Petrovsk",
	"Heshancun",
	"Balakliia",
	"Cláudio",
	"Zhonghechang",
	"Hafnarfjörður",
	"Quakers Hill",
	"Toqsu",
	"Chiredzi",
	"Stanford le Hope",
	"Borzya",
	"Éibar",
	"Novyi Rozdil",
	"Milford",
	"Okotoks",
	"Campos Gerais",
	"Bella Vista",
	"Pabellón de Arteaga",
	"Windsor",
	"Ogawa",
	"Laoaoba",
	"Chichaoua",
	"Herentals",
	"Milton",
	"Shangluhu",
	"Oyabe",
	"Morrisville",
	"Balzar",
	"Harelbeke",
	"Zhongtai",
	"Greenville",
	"Santa Rita de Cássia",
	"Buriti",
	"Heerenveen",
	"Bad Zwischenahn",
	"Qarataū",
	"Lacey",
	"Pires do Rio",
	"Zeitz",
	"Leichlingen",
	"Henstedt-Ulzburg",
	"San Salvador",
	"Zhailuo",
	"Cecina",
	"Taza",
	"Yangiyer",
	"Casselberry",
	"San Narciso",
	"Hatonuevo",
	"Propriá",
	"Wellesley",
	"East Haven",
	"Plattsburgh",
	"Alagoa Grande",
	"Vittorio Veneto",
	"Naranjito",
	"Novi Ligure",
	"Tucurú",
	"Griesheim",
	"Palotina",
	"Wasco",
	"Emmendingen",
	"Novo Oriente",
	"Qincun",
	"Douar Oulad Aj-jabri",
	"South Laurel",
	"Saint Bernard",
	"Fleming Island",
	"Licab",
	"Byaroza",
	"Belle Glade",
	"Aioi",
	"Kumalarang",
	"San Carlos",
	"Manticao",
	"Stoughton",
	"Lake in the Hills",
	"Witney",
	"Kartaly",
	"Agawam",
	"Pokhvistnevo",
	"Isnos",
	"Datu Paglas",
	"Baishaling",
	"Dubăsari",
	"Biłgoraj",
	"Wallkill",
	"Shirley",
	"Nzega",
	"Naranjal",
	"Lučenec",
	"Burton",
	"Oulad Yaïch",
	"Izu",
	"Chili",
	"Geneva",
	"Baradero",
	"Giarre",
	"Tha Yang",
	"San Miguel",
	"Khust",
	"Oulad Hammou",
	"Gusev",
	"Reinbek",
	"Sept-Îles",
	"Triggiano",
	"Schererville",
	"Independence",
	"Gorlice",
	"West Springfield",
	"Villareal",
	"Venezuela",
	"Wegberg",
	"Assisi",
	"Geislingen an der Steige",
	"Austintown",
	"Marapanim",
	"Villa Allende",
	"Tournefeuille",
	"Coyaima",
	"Líšeň",
	"Santo Domingo",
	"San Jose",
	"Baunatal",
	"Burlington",
	"Mpika",
	"McCandless",
	"Bom Jesus",
	"Las Nieves",
	"Serra Negra",
	"Carbonia",
	"Mogliano Veneto",
	"New Iberia",
	"Silvassa",
	"Northampton",
	"Guararema",
	"Luís Correia",
	"Raseborg",
	"Jávea",
	"Esztergom",
	"Manaquiri",
	"Ciro Redondo",
	"Macalelon",
	"Huanghuajing",
	"Jeffrey’s Bay",
	"Pershotravensk",
	"Lillehammer",
	"Warin Chamrap",
	"San Giovanni in Persiceto",
	"Gates",
	"Gaotan",
	"Sighişoara",
	"Vineyard",
	"Las Rosas",
	"Toli",
	"Bermejo",
	"Brétigny-sur-Orge",
	"Bacoli",
	"Conceição das Alagoas",
	"Luruaco",
	"Zionsville",
	"Pedras de Fogo",
	"Vestal",
	"Libacao",
	"Pilar do Sul",
	"Blankenfelde",
	"Guamal",
	"Mollendo",
	"Short Pump",
	"Wetter (Ruhr)",
	"Sexmoan",
	"Grimma",
	"Kevelaer",
	"Belpasso",
	"Ajka",
	"Kłodzko",
	"Lumberton",
	"Leimen",
	"Alga",
	"Safājā",
	"Hiji",
	"Perry Hall",
	"Pitangui",
	"Dar Bel Hamri",
	"Obama",
	"Zacoalco de Torres",
	"Aberdeen",
	"Bad Neuenahr-Ahrweiler",
	"Villa Luvianos",
	"Santa Cruz Cabrália",
	"Bardaskan",
	"Benicia",
	"Erice",
	"Oakleaf Plantation",
	"La Paz",
	"Jacksonville",
	"Čapljina",
	"Rockledge",
	"Navahrudak",
	"Miranda",
	"San Miniato",
	"Agno",
	"Saugus",
	"Henderson",
	"Honchō",
	"Rapperswil-Jona",
	"Kurchaloy",
	"Oktyabrsk",
	"Qujingpu",
	"Guma",
	"Majayjay",
	"Centre Wellington",
	"Bo‘ka",
	"Monterey",
	"Shuiding",
	"Langdu",
	"Arqalyq",
	"Converse",
	"Paracelis",
	"Khrestivka",
	"San Felipe Jalapa de Díaz",
	"Granite City",
	"Llallagua",
	"Oppegård",
	"Buritizeiro",
	"Iguig",
	"Yangyuhe",
	"Tainai",
	"Gaoua",
	"Nallıhan",
	"Bien Unido",
	"Tara",
	"Hazar",
	"Khulm",
	"Oslob",
	"Xochistlahuaca",
	"Lepe",
	"Algemesí",
	"Pantao-Ragat",
	"Yukon",
	"Kushva",
	"Villazón",
	"Pontal do Paraná",
	"Ballina",
	"Taree",
	"Sorochinsk",
	"Babatngon",
	"Kakuda",
	"Sonora",
	"Kuaidamao",
	"Shaler",
	"Dauin",
	"Prata",
	"Gameleira",
	"Branford",
	"Westport",
	"Kasangulu",
	"Uchturpan",
	"Baesweiler",
	"Chíos",
	"Camiri",
	"Jimenez",
	"Illkirch-Graffenstaden",
	"Kriens",
	"Imatra",
	"Coromandel",
	"Kholmsk",
	"Mehlville",
	"Ternivka",
	"Niuchangqiao",
	"Mirassol d’Oeste",
	"Wisconsin Rapids",
	"Nochistlán de Mejía",
	"Baisuo",
	"San Martín de los Andes",
	"Aglipay",
	"Dembī Dolo",
	"Dahmani",
	"Piešťany",
	"Galt",
	"Mocímboa",
	"Hutto",
	"Sundern",
	"Jaguarão",
	"Bixby",
	"Hobart",
	"West Windsor",
	"Benicarló",
	"Tlalpujahua de Rayón",
	"Oakdale",
	"Sabaneta",
	"Sunbury",
	"Valente",
	"Moulay Bousselham",
	"Shilan",
	"Summerlin South",
	"Bolobo",
	"Melgaço",
	"Piraí",
	"Andenne",
	"Carauari",
	"Belén de Umbría",
	"Hādīshahr",
	"Topki",
	"Zamboanguita",
	"Vyshhorod",
	"Salinas Victoria",
	"Atlautla",
	"Cocal",
	"Porto Sant’Elpidio",
	"Sueca",
	"Zolotonosha",
	"Ormskirk",
	"Ōuda-yamaguchi",
	"Aloran",
	"Zaltbommel",
	"Lufeng",
	"Soignies",
	"Fūman",
	"New Smyrna Beach",
	"Bellaa",
	"Mahaplag",
	"Tualatin",
	"Tehuipango",
	"Forest Hills",
	"Fridley",
	"Maloyaroslavets",
	"Newtown",
	"Iguaí",
	"Zakopane",
	"East Chicago",
	"Ofaqim",
	"Viñales",
	"Kirkwood",
	"Ozumba",
	"San Martín Hidalgo",
	"Sumilao",
	"Mechernich",
	"Lagoa Vermelha",
	"Arnstadt",
	"Littlehampton",
	"Havran",
	"Sanger",
	"Gioia del Colle",
	"Mananjary",
	"Jarrow",
	"Mirano",
	"Kongsberg",
	"Itako",
	"Lainate",
	"Nova Prata",
	"Boca da Mata",
	"Morgan City",
	"Curtea de Argeş",
	"São José do Norte",
	"Ramsey",
	"Zongdi",
	"Ruston",
	"Jeffersontown",
	"Glen Ellyn",
	"Zarechnyy",
	"Longtan",
	"Supía",
	"Argelia",
	"Kapellen",
	"Narat",
	"Morón de la Frontera",
	"Bandipura",
	"San Juan Guichicovi",
	"Bom Jardim",
	"Mantena",
	"Makato",
	"Borşa",
	"Cañon City",
	"Ibotirama",
	"Lichtenburg",
	"Gojō",
	"Wangtang",
	"Juatuba",
	"Cuartero",
	"Monticello",
	"Itacaré",
	"Lebrija",
	"Oktyabrsk",
	"Burlington",
	"Ashtabula",
	"Santa Perpetua de Moguda",
	"Marco",
	"Wiesloch",
	"Temescal Valley",
	"Orosháza",
	"Xima",
	"Bridgewater",
	"Água Azul do Norte",
	"Mint Hill",
	"Jarinu",
	"Roşiori de Vede",
	"Dupax Del Norte",
	"Ponot",
	"Panauti",
	"Danvers",
	"Tupanatinga",
	"São Miguel do Iguaçu",
	"Stratford-upon-Avon",
	"Tortona",
	"Milton",
	"Molíns de Rey",
	"Tongyangdao",
	"Spring",
	"Whitehall",
	"Piracuruca",
	"Lapão",
	"Jászberény",
	"Neptune",
	"Neckarsulm",
	"Niimi",
	"Ciudad Bolívar",
	"Miguel Calmon",
	"Haiyang",
	"Almazora",
	"Buenavista",
	"Geilenkirchen",
	"Bidur",
	"İpsala",
	"Santa Rita do Passa Quatro",
	"Placer",
	"Ashford",
	"Gawler",
	"Statesville",
	"Kathu",
	"Repelón",
	"Verkhniy Ufaley",
	"Terlizzi",
	"Gloversville",
	"Miramas",
	"Rambouillet",
	"Chillán Viejo",
	"Campos Sales",
	"Gladstone",
	"Pilar",
	"Deer Park",
	"Sola",
	"Bo’ao",
	"Kedu",
	"Reina Mercedes Viejo",
	"East Grinstead",
	"Longdian",
	"Fremont",
	"Queensbury",
	"Laranjal Paulista",
	"Rheinbach",
	"Garfield Heights",
	"Maryborough",
	"Seneca",
	"Barcelos",
	"Vimercate",
	"Overath",
	"Mabini",
	"Zempoala",
	"Florence",
	"Thatcham",
	"Bayonet Point",
	"Klosterneuburg",
	"Zhongshan",
	"Bakhchysarai",
	"Lansing",
	"Drimmelen",
	"San Luis",
	"Aschersleben",
	"Huaquechula",
	"Millville",
	"Monroeville",
	"Tall Rif‘at",
	"Forquilhinha",
	"Saint-Constant",
	"Valencia",
	"Badiangan",
	"St. Helens",
	"Ban Phonla Krang",
	"Stafford",
	"Lapuyan",
	"Pitogo",
	"Lunel",
	"Orvault",
	"Sibinal",
	"Castro Alves",
	"Ixchiguán",
	"Atitalaquia",
	"Vícar",
	"Enna",
	"Grimsby",
	"Teutônia",
	"Bergerac",
	"La Oliva",
	"Tepehuacán de Guerrero",
	"Albignasego",
	"Puerto Colombia",
	"New Windsor",
	"Gonesse",
	"Heiligenhaus",
	"Aalten",
	"El Alia",
	"Cangas",
	"Conchal",
	"Pombos",
	"San Giovanni Rotondo",
	"Santana",
	"Hénin-Beaumont",
	"Santo Niño",
	"Horn Lake",
	"San Juan de Urabá",
	"Itapissuma",
	"Candelaria",
	"Pereyaslav-Khmel’nyts’kyy",
	"Sebeş",
	"Chivasso",
	"Estarreja",
	"East Windsor",
	"Miracema",
	"Prior Lake",
	"Jardim",
	"Caboolture",
	"Santa Eugenia",
	"Scicli",
	"Huntley",
	"Niscemi",
	"Galatina",
	"Cavaillon",
	"Kolambugan",
	"Qingshan",
	"Maple Valley",
	"San Agustín Tlaxiaca",
	"Vilyeyka",
	"Mundo Novo",
	"Xalatlaco",
	"Fidenza",
	"Willebroek",
	"Porto da Folha",
	"Oak Forest",
	"Schloß Holte-Stukenbrock",
	"Mayskiy",
	"Pemberton",
	"Glen Cove",
	"Ermelo",
	"Liqizhuang",
	"Gradignan",
	"Werkendam",
	"Wangen im Allgäu",
	"Szentes",
	"São Joaquim",
	"Sikonge",
	"Sens",
	"Kiskunhalas",
	"Saumur",
	"Ubatã",
	"Ksebia",
	"Shoreview",
	"Assèmini",
	"Windsor",
	"Sterling",
	"Poona-Piagapo",
	"Garchitorena",
	"Montbéliard",
	"Hennigsdorf",
	"Dodge City",
	"Ronse",
	"Unisan",
	"Tlaxcoapan",
	"Sol’-Iletsk",
	"San Pedro del Pinatar",
	"Colleyville",
	"Wilmette",
	"Plum",
	"Bernards",
	"Mount Pleasant",
	"Libenge",
	"Sabanilla",
	"Belo Oriente",
	"Falconara Marittima",
	"Pāmidi",
	"Itamaracá",
	"Amatepec",
	"Laranjeiras",
	"Ružomberok",
	"McHenry",
	"Lohne",
	"Kazincbarcika",
	"Waterville",
	"Bagac",
	"Mesagne",
	"Szentendre",
	"Jiangdi",
	"Wałcz",
	"Quarrata",
	"Norfolk",
	"Zuitou",
	"Shaker Heights",
	"Citrus Park",
	"Boquim",
	"Tarui",
	"Teltow",
	"Marcos Juárez",
	"New Milford",
	"Erie",
	"Brownsburg",
	"Sanharó",
	"Wakefield",
	"São Sebastião da Boa Vista",
	"Mainit",
	"Chaska",
	"Aripuanã",
	"Strausberg",
	"Ilchester",
	"Shelby",
	"Noicattaro",
	"Karpinsk",
	"Uyuni",
	"Taquari",
	"Lauf",
	"Maryland Heights",
	"Kamyshlov",
	"Magna",
	"Pontecagnano",
	"Xenia",
	"Putignano",
	"Allen Park",
	"Chuimatan",
	"Khānābād",
	"Balabagan",
	"Mason City",
	"Hancun",
	"New Lenox",
	"Batalha",
	"Jaltenco",
	"Pınarbaşı",
	"Hanahan",
	"Cártama",
	"Karasuk",
	"Iglesias",
	"Padada",
	"Hamminkeln",
	"Lier",
	"Dongen",
	"Tibiao",
	"Burdeos",
	"Kinston",
	"Searcy",
	"Flémalle-Haute",
	"Fern Down",
	"Boisbriand",
	"Brzozów",
	"Miyanaga",
	"Majagua",
	"Cândido Sales",
	"Pacasmayo",
	"Tamorot",
	"Shangxiao",
	"Olindina",
	"Ipixuna",
	"New London",
	"Jinju",
	"Lamego",
	"Nossa Senhora das Dores",
	"West Islip",
	"Reserva",
	"Ramon Magsaysay",
	"Bafoulabé",
	"Sofiyivs’ka Borshchahivka",
	"Sabaa Aiyoun",
	"Solânea",
	"Rijssen",
	"Fortul",
	"San Juan",
	"Monreal",
	"West Chicago",
	"Dyatkovo",
	"Nepomuceno",
	"Sliedrecht",
	"Goianinha",
	"Santa Josefa",
	"Novoaleksandrovsk",
	"Saint-Dizier",
	"Santa Helena",
	"Codajás",
	"Los Córdobas",
	"Santeramo in Colle",
	"Obertshausen",
	"Francavilla al Mare",
	"Semiluki",
	"Springettsbury",
	"McDonough",
	"Mariano Comense",
	"Chuanliaocun",
	"Morales",
	"Montreux",
	"La Garde",
	"Piracaia",
	"General Luna",
	"Ragan Sur",
	"San Isidro",
	"Narasannapeta",
	"Polysayevo",
	"West Linn",
	"Weiterstadt",
	"Basay",
	"Cortland",
	"Lemoore",
	"Alvin",
	"Bushey",
	"Corrente",
	"Kauswagan",
	"Kirzhach",
	"Ocean",
	"Brasiléia",
	"Tubize",
	"Kiruna",
	"Nova Zagora",
	"Yuanyangzhen",
	"Hohen Neuendorf",
	"Huşi",
	"Waynesboro",
	"Krasnoarmeysk",
	"Betong",
	"Nova Esperança",
	"Talisay",
	"Sosnogorsk",
	"Segezha",
	"San Giovanni Lupatoto",
	"Marshalltown",
	"Lebanon",
	"Planalto",
	"Wappinger",
	"Lower Providence",
	"Thomasville",
	"Unterhaching",
	"Maçka",
	"Goiás",
	"Temple Terrace",
	"Lafayette",
	"Wassenaar",
	"Coulsdon",
	"Veranópolis",
	"Coração de Jesus",
	"Fălticeni",
	"Az Zabadānī",
	"Dalnerechensk",
	"San Benito Abad",
	"Norco",
	"Pirapòzinho",
	"Immokalee",
	"San Alberto",
	"Kirovsk",
	"Khā̃dbāri̇̄",
	"Bolkhov",
	"Aş Şanamayn",
	"Shalqar",
	"Hermanus",
	"Dois Córregos",
	"Mühlacker",
	"Severouralsk",
	"San Antonio del Sur",
	"Santa Margarita",
	"Congleton",
	"Cesenatico",
	"La Unión",
	"Valdagno",
	"Traralgon",
	"Heppenheim",
	"Vernon Hills",
	"Zhaicun",
	"São João Nepomuceno",
	"Pearl",
	"Paracuellos de Jarama",
	"São Bernardo",
	"Bethany",
	"Vila Rica",
	"Senador Pompeu",
	"Tabira",
	"Nordenham",
	"Marquette",
	"Horsham",
	"Zirndorf",
	"Olteniţa",
	"Iesolo",
	"Sedalia",
	"Bessemer",
	"Oisterwijk",
	"Balboa",
	"Plainview",
	"Macrohon",
	"Migdal Ha‘Emeq",
	"Butzbach",
	"Selm",
	"Pati do Alferes",
	"Caririaçu",
	"Oldbury",
	"Ocuilan de Arteaga",
	"Beuningen",
	"Liushuquan",
	"Aït Faska",
	"Canarana",
	"Sangerhausen",
	"New Milton",
	"Vignola",
	"Batavia",
	"Topoľčany",
	"Kirovsk",
	"Garden City",
	"Ehingen an der Donau",
	"West Milford",
	"Lilancheng",
	"Pensilvania",
	"Chanhassen",
	"Santo Tomás",
	"Aurillac",
	"Jarocin",
	"Ilha Solteira",
	"Daventry",
	"Sacramento",
	"Palanas",
	"Conversano",
	"Dimasalang",
	"Bourdoud",
	"Šumperk",
	"Termini Imerese",
	"Arzignano",
	"Cabot",
	"Bad Honnef am Rhein",
	"Pukekohe East",
	"Ludwigsfelde",
	"Shuanghe",
	"Kalfou",
	"Cuart de Poblet",
	"Vicksburg",
	"Bangzha",
	"Karoi",
	"Bingen am Rhein",
	"Itaíba",
	"Sora",
	"Kluczbork",
	"Lauaan",
	"Upper Dublin",
	"Leon Postigo",
	"Alitagtag",
	"Dengjiazhuang",
	"Schleswig",
	"Ayorou",
	"Tarragona",
	"Farafangana",
	"Brawley",
	"Bouknadel",
	"Geretsried",
	"Neenah",
	"Perevalsk",
	"Boureït",
	"Snoqualmie",
	"Quipapá",
	"Montemor-o-Velho",
	"Labytnangi",
	"Buesaco",
	"Troy",
	"San Pablo",
	"Hercules",
	"Mahwah",
	"Scandiano",
	"Semīrom",
	"Lake City",
	"Astorga",
	"Bishop Auckland",
	"Magarao",
	"Colotenango",
	"Paramus",
	"Lindau",
	"Elefsína",
	"Okeechobee",
	"Lanaken",
	"Jardim",
	"Vierzon",
	"Paz de Ariporo",
	"Almuñécar",
	"Khashuri",
	"Malyn",
	"Sasovo",
	"North Kingstown",
	"Dongfeng",
	"Świecie",
	"Vsetín",
	"San Lucas Sacatepéquez",
	"La Teste-de-Buch",
	"Baleno",
	"Conde",
	"Xishan",
	"Haoping",
	"Wilmslow",
	"Tavira",
	"Yuanchang",
	"Conception Bay South",
	"Pfaffenhofen",
	"Castel Volturno",
	"Independência",
	"Kulmbach",
	"São Sebastião do Caí",
	"Lice",
	"Cujubim",
	"Iaçu",
	"Puerto Varas",
	"Muchamiel",
	"Uherské Hradiště",
	"Guaraí",
	"Aracoiaba",
	"Wetteren",
	"Wethersfield",
	"Muscatine",
	"Palmar de Varela",
	"Novopavlovsk",
	"Boone",
	"Itapuranga",
	"Moscow",
	"Oum Hadjer",
	"Toktogul",
	"Tacaratu",
	"Santiago Papasquiaro",
	"Sebastian",
	"Northport",
	"Helmstedt",
	"Mizdah",
	"San Manuel",
	"Saint-Bruno-de-Montarville",
	"Queimadas",
	"Natchez",
	"Zacualtipán",
	"Sankt Wendel",
	"Conner",
	"Winchester",
	"Odemira",
	"Novelda",
	"Aparecida do Taboado",
	"Zarraga",
	"Twentynine Palms",
	"Roseto degli Abruzzi",
	"Sapian",
	"Itaí",
	"Holt",
	"Stein",
	"Zavodoukovsk",
	"Traun",
	"Duiven",
	"Puerto Píritu",
	"East St. Louis",
	"Sachse",
	"Montgomery",
	"Tinipuka",
	"Renedo",
	"Pandami",
	"Libourne",
	"Gaoya",
	"Nocera Superiore",
	"Sinait",
	"Friedrichsdorf",
	"South Ubian",
	"Montichiari",
	"Tamparan",
	"Diapaga",
	"Achern",
	"Saintes",
	"Jumilla",
	"Paris",
	"Haines City",
	"Blagnac",
	"Allen",
	"Salamína",
	"Cave Spring",
	"Santo Antônio do Monte",
	"Quezon",
	"Correggio",
	"Caluquembe",
	"Istmina",
	"Magdalena",
	"Kimovsk",
	"Merrimack",
	"Superior",
	"Karlovo",
	"Maozhou",
	"Marmara Ereğlisi",
	"Encruzilhada do Sul",
	"Pantelimon",
	"West Odessa",
	"Álamos",
	"San Juan Ixcoy",
	"Medina",
	"Conway",
	"Santa Ana",
	"Dolores",
	"Barberton",
	"Española",
	"Koko",
	"Armidale",
	"Yinajia",
	"San Vicente de Cañete",
	"Cuajinicuilapa",
	"Newport",
	"Ōmachi",
	"Londonderry",
	"Erandio",
	"Malaṅgawā",
	"Dix Hills",
	"Villanueva de la Serena",
	"Wetzikon",
	"Rock Springs",
	"Santa Lucia",
	"San Martín",
	"Wągrowiec",
	"Beersel",
	"South Windsor",
	"Mercer Island",
	"Mola di Bari",
	"Lübbecke",
	"Heber",
	"White Bear Lake",
	"San Lucas Tolimán",
	"Kunisakimachi-tsurugawa",
	"Lagoa Grande",
	"Nova Soure",
	"Roth",
	"Chester-le-Street",
	"Santa Lucia La Reforma",
	"Verl",
	"Cochrane",
	"Laurel",
	"Marshfield",
	"Monte Cristi",
	"Bagno a Ripoli",
	"Yany Kapu",
	"Holbrook",
	"Broadstairs",
	"Huejotzingo",
	"Kireyevsk",
	"Nechí",
	"Hasköy",
	"Velingrad",
	"Tabuelan",
	"Bay Point",
	"Surgidero de Batabanó",
	"Mansfield",
	"Pereira Barreto",
	"Woodstock",
	"Nanpingcun",
	"Firmat",
	"Lochearn",
	"Groß-Gerau",
	"Zittau",
	"Umbaúba",
	"Santa Rosa de Viterbo",
	"Green",
	"Oulad Said",
	"Felipe Carrillo Puerto",
	"Wall",
	"Saky",
	"Pfungstadt",
	"Saint-Ouen-l’Aumône",
	"Aimorés",
	"Carandaí",
	"Ishii",
	"Rinteln",
	"Carahue",
	"Cuerámaro",
	"Panelas",
	"Golborne",
	"Étampes",
	"Thetford Mines",
	"Lamzoudia",
	"Alhaurín el Grande",
	"Owatonna",
	"Ditzingen",
	"Tepetlaoxtoc",
	"Adra",
	"Pooler",
	"Lennestadt",
	"Exeter",
	"Haiwei",
	"Balabanovo",
	"Haisyn",
	"Datu Piang",
	"Yolombó",
	"Bom Jesus",
	"Siófok",
	"Madison",
	"Nesher",
	"Sarrat",
	"Lancaster",
	"Otradnoye",
	"Oliva",
	"Veenoord",
	"Lanling",
	"Piraí do Sul",
	"Rottweil",
	"Austin",
	"Mata Grande",
	"Fengruncun",
	"Lockport",
	"Oulad Tayeb",
	"Ciempozuelos",
	"Puebla de Vallbona",
	"Poro",
	"Franklin",
	"Wiehl",
	"Courtenay",
	"Olesa de Montserrat",
	"São Geraldo do Araguaia",
	"Vynohradiv",
	"Gosforth",
	"South Salt Lake",
	"Plettenberg",
	"Legnago",
	"Tarpon Springs",
	"Edgewood",
	"Soure",
	"Busselton",
	"Rudolstadt",
	"Tougué",
	"Dingalan",
	"Santa Maria",
	"Staßfurt",
	"Bluffton",
	"Sevierville",
	"Forest Grove",
	"Paete",
	"Yanyan",
	"Randolph",
	"Solano",
	"Bugho",
	"Sun City West",
	"Thiene",
	"Luzilândia",
	"Cambará",
	"Yanaul",
	"South Portland",
	"Ruskin",
	"Denison",
	"Maaseik",
	"Farmington",
	"Brilon",
	"Oud-Beijerland",
	"Eidsvoll",
	"Del Gallego",
	"Ain Aicha",
	"Piagapo",
	"Alta Floresta D’Oeste",
	"Samtredia",
	"Tanabi",
	"Skawina",
	"Keystone",
	"Moon",
	"Korostyshiv",
	"Dalmine",
	"Workington",
	"Jequitinhonha",
	"Witham",
	"Sandoná",
	"Vistahermosa",
	"Laon",
	"Tamboril",
	"Markkleeberg",
	"San José",
	"La Unión",
	"Inta",
	"Capão do Leão",
	"Ruvo di Puglia",
	"Yamagata",
	"Wumaying",
	"Lamut",
	"Ituporanga",
	"Ozëry",
	"San Pablo",
	"La Valette-du-Var",
	"Tupaciguara",
	"Albergaria-a-Velha",
	"Taupo",
	"Sevlievo",
	"Harsewinkel",
	"Cuijk",
	"Meckenheim",
	"Bom Jesus",
	"Şaydnāyā",
	"Areia Branca",
	"Claremore",
	"Pinheiral",
	"Whitehaven",
	"Homewood",
	"Muret",
	"Formosa do Rio Preto",
	"São João da Ponte",
	"Chiromo",
	"Mengdan",
	"Ibiá",
	"La Blanca",
	"Magog",
	"Hudson",
	"Mednogorsk",
	"Pandag",
	"Tabina",
	"Horb am Neckar",
	"Chocontá",
	"Farmington",
	"Westerlo",
	"Salamanca",
	"Columbine",
	"Zheleznovodsk",
	"Pinal de Amoles",
	"Canyon Lake",
	"Lopez Jaena",
	"Oulad Barhil",
	"Kingsville",
	"Kochubeyevskoye",
	"Forest City",
	"Cabangan",
	"Merelbeke",
	"Salem",
	"Baar",
	"Bainbridge Island",
	"Norden",
	"Salzkotten",
	"Petershagen",
	"Dedham",
	"Khed Brahma",
	"Caledonia",
	"Kamo",
	"Elói Mendes",
	"De Witt",
	"Cajuru",
	"Champlin",
	"El Ghiate",
	"Sprockhövel",
	"Lom",
	"Thetford",
	"Kariba",
	"Frederikshavn",
	"Camalaniugan",
	"Xiaolongtan",
	"Safsaf",
	"Kościerzyna",
	"Anastácio",
	"Sastamala",
	"Apiaí",
	"Edwardsville",
	"Pinillos",
	"Genzano di Roma",
	"Rochefort",
	"Fairland",
	"Bad Oldesloe",
	"Morozovsk",
	"Elk River",
	"Tōno",
	"Onda",
	"Idstein",
	"Rosemount",
	"San Isidro",
	"Portogruaro",
	"Sezze",
	"Fountain Hills",
	"Espera Feliz",
	"Droitwich",
	"Otuzco",
	"Cambre",
	"Paoay",
	"Agrestina",
	"Shaqlāwah",
	"Santa María Tonameca",
	"Raahe",
	"Tarangnan",
	"Socorro",
	"Icatu",
	"Quissamã",
	"Nedre Eiker",
	"Great Sankey",
	"Charentsavan",
	"Delitzsch",
	"Atkarsk",
	"Sovetskaya Gavan’",
	"Villarrobledo",
	"Belvidere",
	"Ereymentaū",
	"Yuzhang",
	"Riverside",
	"Reading",
	"Middle River",
	"Walpole",
	"Muskego",
	"Jaleshwar",
	"Wolfsberg",
	"Hazelwood",
	"Scottsbluff",
	"Tubay",
	"Pitsea",
	"Dalupo",
	"Santo Tomás",
	"Saguiaran",
	"Uauá",
	"Derry",
	"Matriz de Camarajibe",
	"Krems an der Donau",
	"Whitehorse",
	"Białogard",
	"Doctor Mora",
	"Sakai",
	"Fresno",
	"Rockaway",
	"Turnu Măgurele",
	"Ridgewood",
	"Wujiaying",
	"Piritiba",
	"Cruz",
	"Santa Maria do Pará",
	"Slavutych",
	"Yucca Valley",
	"Ridgefield",
	"Torrelodones",
	"Iraquara",
	"Branson",
	"Sultepec",
	"Dainyor",
	"Seaford",
	"Uvarovo",
	"Sandomierz",
	"Caransebeş",
	"Ma‘bar",
	"Valparaíso",
	"Eisenhüttenstadt",
	"Zwevegem",
	"Balch Springs",
	"Woolwich",
	"Übach-Palenberg",
	"Warstein",
	"Magenta",
	"Ronnenberg",
	"Santa Gertrudes",
	"Espelkamp",
	"Timberwood Park",
	"Olpe",
	"De Pere",
	"Ribas do Rio Pardo",
	"Piranhas",
	"Socastee",
	"Lagindingan",
	"Susquehanna",
	"Rishton",
	"Schmallenberg",
	"Fajardo",
	"Marysville",
	"San Martín Sacatepéquez",
	"Madaoua",
	"São João dos Patos",
	"Derby",
	"Zarautz",
	"Trebišov",
	"Manito",
	"Litoměřice",
	"Bétera",
	"Plymstock",
	"Shitan",
	"Vertou",
	"Valls",
	"Wilsonville",
	"Echemmaia Est",
	"Wil",
	"Sharya",
	"Ampatuan",
	"Douar Olad. Salem",
	"Ottaviano",
	"Ouaoula",
	"Clayton",
	"Brookings",
	"Pavlovsk",
	"Liangwu",
	"Evesham",
	"Cabricán",
	"Aquila",
	"Leoben",
	"North Laurel",
	"Břeclav",
	"Montevarchi",
	"Sierra Bullones",
	"Walker",
	"Colón",
	"Wyandotte",
	"Santa Rosa",
	"Grandview",
	"Dongxiaozhai",
	"Forest Lake",
	"Igreja Nova",
	"Mabini",
	"Middleborough",
	"Chesapeake Beach",
	"Altavas",
	"Palm City",
	"Hudson",
	"Pão de Açúcar",
	"Kulachi",
	"Zhuchangba",
	"Isernhagen-Süd",
	"Aytos",
	"Pangil",
	"Lincoln",
	"Simsbury",
	"Calahorra",
	"Oga",
	"Swatara",
	"Raisio",
	"Český Těšín",
	"Hodonín",
	"Villanueva",
	"Clarksburg",
	"Ziketan",
	"Lüdinghausen",
	"Kakonko",
	"Buy",
	"Tauramena",
	"South Elgin",
	"Şamaxı",
	"Algeciras",
	"Auburn Hills",
	"Čadca",
	"Juayúa",
	"Ipameri",
	"Bela Vista",
	"San Enrique",
	"Öhringen",
	"Larvik",
	"Giulianova",
	"Vizela",
	"Dorohoi",
	"Canindé de São Francisco",
	"Chebba",
	"North Tustin",
	"Igaraçu do Tietê",
	"Ontario",
	"Takhli",
	"Albenga",
	"Arad",
	"Ardmore",
	"Quirino",
	"Hastings",
	"Burlington",
	"Shangtianba",
	"Tanglin",
	"Talisayan",
	"Itabaiana",
	"Las Cabras",
	"Hannoversch Münden",
	"Heiloo",
	"Roissy-en-Brie",
	"Álvares Machado",
	"Sanchez-Mira",
	"Norton Shores",
	"Kernersville",
	"Rickmansworth",
	"Windham",
	"Tairan Camp",
	"Khajurāho",
	"Meiningen",
	"Aurora",
	"Yayas de Viajama",
	"Easton",
	"Sugar Hill",
	"Hoensbroek",
	"Attendorn",
	"San Borja",
	"Pueblo Bello",
	"Freha",
	"Craíbas",
	"Emporia",
	"Nasukarasuyama",
	"Urbano Santos",
	"Mekla",
	"Sulmona",
	"Infanta",
	"Zernograd",
	"Canhotinho",
	"Prosper",
	"La Huerta",
	"Pirajuí",
	"Valdivia",
	"Gyál",
	"El Retorno",
	"Maguing",
	"Cedro",
	"Rădăuţi",
	"Fossano",
	"Dubnica nad Váhom",
	"Almansa",
	"Vernon",
	"Itaporanga",
	"Kaniv",
	"Syke",
	"Waldshut-Tiengen",
	"Watsa",
	"Almonte",
	"San Agustín Loxicha",
	"Waldkraiburg",
	"Rathenow",
	"Palmetto Bay",
	"Guabiruba",
	"Svishtov",
	"Clarence-Rockland",
	"Somerset",
	"Mahdīshahr",
	"Limbach-Oberfrohna",
	"Copertino",
	"Avon Lake",
	"Pýrgos",
	"Forquilha",
	"Malitbog",
	"Neftekumsk",
	"Xinxing",
	"Tamilisan",
	"Lathrop",
	"Loma Linda",
	"Natividad",
	"Pura",
	"Putten",
	"Homer Glen",
	"Tifton",
	"Senftenberg",
	"Tocopilla",
	"Dinuba",
	"East Hempfield",
	"Poblacion",
	"Bad Soden am Taunus",
	"Bloomingdale",
	"Stroitel",
	"Amarapura",
	"Selydove",
	"Westmont",
	"Clinton",
	"São Jerônimo",
	"Salvaterra",
	"Vulcan",
	"Porto de Mós",
	"Hujiachi",
	"Opelousas",
	"Kotelnich",
	"Camas",
	"Orbassano",
	"Semënov",
	"Harvey",
	"Medford",
	"Trotwood",
	"West Memphis",
	"Cupira",
	"Qiaomaichuan",
	"Collinsville",
	"Longonjo",
	"Garcia Hernandez",
	"Mequon",
	"Joaquim Gomes",
	"Ellensburg",
	"Rodniki",
	"Nittedal",
	"Marple",
	"Ottumwa",
	"Chantilly",
	"Puerto Guzmán",
	"Santo Antônio",
	"Asino",
	"Al Quşayr",
	"Shelbyville",
	"Buturlinovka",
	"Casiguran",
	"Goulburn",
	"Pedra Azul",
	"Westford",
	"La Montañita",
	"Orchards",
	"Newport",
	"Goirle",
	"San Miguel",
	"Nerópolis",
	"Dole",
	"Nueva Paz",
	"Inhapim",
	"Douar ’Ayn Dfali",
	"Rimavská Sobota",
	"Calpe",
	"Novo Aripuanã",
	"Donmatías",
	"Saginaw",
	"Cogua",
	"Alfter",
	"Saint-Laurent-du-Maroni",
	"São Lourenço d’Oeste",
	"Manage",
	"Port Angeles",
	"Marsella",
	"Brandon",
	"Biancavilla",
	"Santiago",
	"Sun City Center",
	"Sarangani",
	"Inkster",
	"Buenavista",
	"Wednesfield",
	"Thomasville",
	"Dazhuang",
	"Ponca City",
	"Cataño",
	"Gazojak",
	"Tecolutla",
	"Solsona",
	"Oiapoque",
	"Camillus",
	"West Melbourne",
	"Warrington",
	"Feriana",
	"Faribault",
	"Springfield",
	"San Benito",
	"Fort Washington",
	"Diest",
	"Ibirapitanga",
	"Pietrasanta",
	"Lincolnton",
	"Halfmoon",
	"Bieruń Stary",
	"West Springfield",
	"Simpsonville",
	"Ivrea",
	"Setti Fatma",
	"Krychaw",
	"Varel",
	"Uchquduq Shahri",
	"Scotch Plains",
	"San Marcos",
	"Waverly",
	"San Nicolas",
	"Huilongping",
	"Skoura",
	"Rugeley",
	"Angatuba",
	"Salqīn",
	"Guaiuba",
	"Lihe",
	"Diu",
	"Baiheqiao",
	"Schijndel",
	"Stjørdal",
	"Grootfontein",
	"Gauripur",
	"Ashton in Makerfield",
	"Rioblanco",
	"Quedlinburg",
	"Fishkill",
	"Fort Saskatchewan",
	"Okemos",
	"São José da Laje",
	"Upper Macungie",
	"Suonan",
	"Ibicaraí",
	"Al Jawf",
	"Castiglione delle Stiviere",
	"Sattahip",
	"Zolochiv",
	"Beni Amrane",
	"Formby",
	"Upper Moreland",
	"Monte Sião",
	"Sonneberg",
	"Döbeln",
	"Amstetten",
	"Burgos",
	"Koilkuntla",
	"Vaterstetten",
	"Ridgeland",
	"North Platte",
	"As Sulayyil",
	"Borne",
	"Charles Town",
	"Freeport",
	"Waukee",
	"Mina",
	"Husum",
	"Montecchio Maggiore",
	"Mentana",
	"Tupanciretã",
	"Selvazzano Dentro",
	"Rio Tinto",
	"Cachoeira do Arari",
	"Noto",
	"Talugtug",
	"Bayang",
	"Litvínov",
	"Ban Phru",
	"Ventimiglia",
	"Bognor Regis",
	"Zawyat an Nwaçer",
	"São Miguel do Guaporé",
	"Tayga",
	"Três Passos",
	"South Plainfield",
	"Konstancin-Jeziorna",
	"Wadsworth",
	"Dapa",
	"Quatro Barras",
	"Kourou",
	"Horley",
	"Puerto San José",
	"Ocara",
	"Mairena del Alcor",
	"Piracanjuba",
	"Nava",
	"Grande-Synthe",
	"Bormujos",
	"Tumwater",
	"Fairfax",
	"Cranford",
	"Hoh Ereg",
	"Piçarras",
	"Candler-McAfee",
	"East Gwillimbury",
	"Venice",
	"Calw",
	"Lanester",
	"Três Coroas",
	"Kelīshād va Sūdarjān",
	"Hajdúszoboszló",
	"Macaparana",
	"El Zulia",
	"Harmanli",
	"Jawor",
	"Wright",
	"Aldeias Altas",
	"Wajimazakimachi",
	"Hollola",
	"Kalach-na-Donu",
	"Anchieta",
	"São Gonçalo do Sapucaí",
	"Laje",
	"Mansfield",
	"La Maná",
	"Haren",
	"Ibi",
	"Bogotol",
	"Maldegem",
	"Carás",
	"Lincolnia",
	"Três de Maio",
	"Capoterra",
	"Nanxingguo",
	"Sokolov",
	"Corinto",
	"Westchase",
	"Poço Verde",
	"Upper Providence",
	"Dongsheng",
	"Edgewater",
	"Tlacolula",
	"Bambuí",
	"North Potomac",
	"Pelham",
	"Honda",
	"Si Racha",
	"Corsicana",
	"Monte Aprazível",
	"Seal Beach",
	"Comapa",
	"Pagudpud",
	"Combs-la-Ville",
	"San Miguel Ocotenco",
	"Ciudad Serdán",
	"Fort Dodge",
	"Bad Mergentheim",
	"Mirandela",
	"Jurh",
	"Qifţ",
	"Starnberg",
	"Oostkamp",
	"Dunkirk",
	"Khenichet-sur Ouerrha",
	"Douar Ouled Ayad",
	"Zemst",
	"Nyūzen",
	"Zeya",
	"Bazhajiemicun",
	"Carambeí",
	"Calabasas",
	"Chalmette",
	"Bobon",
	"Raul Soares",
	"Los Barrios",
	"Lustenau",
	"Berehove",
	"North Augusta",
	"Palm River-Clair Mel",
	"Stepanavan",
	"Alexânia",
	"Chrudim",
	"Oldebroek",
	"Ostrów Mazowiecka",
	"Ayuquitan",
	"Jucás",
	"Kafr Sa‘d",
	"El Maknassi",
	"Montgomery",
	"Bedburg",
	"Mirandola",
	"Mount Pocono",
	"Olopa",
	"San José",
	"Tugaya",
	"Abbeville",
	"São Sepé",
	"Makouda",
	"Brody",
	"Quartier Militaire",
	"Caraí",
	"São Miguel",
	"Dumaran",
	"Lincoln",
	"Nový Jičín",
	"Garopaba",
	"Santa Lucía Utatlán",
	"Loon op Zand",
	"Pitogo",
	"Dancagan",
	"Marple",
	"Farragut",
	"Mosbach",
	"Korbach",
	"Belmonte",
	"Kuvandyk",
	"Hachimantai",
	"Kalayaan",
	"Jenks",
	"Lucena",
	"Piat",
	"Kayapa",
	"Ceccano",
	"João Lisboa",
	"Maracás",
	"Luninyets",
	"Fria",
	"Oulad Hassoune",
	"Gueznaia",
	"Herdecke",
	"Ayancık",
	"Kalawit",
	"Palatka",
	"Tatarsk",
	"Tamandaré",
	"Salzwedel",
	"Pamplona",
	"Coronado",
	"Ayt Mohamed",
	"Lubartów",
	"Rio Formoso",
	"Alicia",
	"North Haven",
	"São João do Paraíso",
	"Khromtaū",
	"Gamay",
	"San Juan Cotzocón",
	"Freudenstadt",
	"Nagykőrös",
	"Bauta",
	"Centerville",
	"Maracaju",
	"Kreuzlingen",
	"Taraka",
	"Juchitepec",
	"Lupeni",
	"Ladário",
	"Santo Antônio do Içá",
	"Sredneuralsk",
	"Van Buren",
	"Greeneville",
	"Tata",
	"Waycross",
	"Koziatyn",
	"Partizánske",
	"Inajá",
	"Soledad Atzompa",
	"Jüchen",
	"Bulle",
	"Poggiomarino",
	"Épernay",
	"Carletonville",
	"Half Moon Bay",
	"Krnov",
	"Hingham",
	"Santo Amaro da Imperatriz",
	"Vigonza",
	"Zheleznogorsk-Ilimskiy",
	"Dillenburg",
	"Gitega",
	"Belton",
	"Qianmotou",
	"Lentini",
	"Bāglung",
	"Fucecchio",
	"Jacksonville Beach",
	"Coronel Suárez",
	"Acton",
	"Sehnde",
	"Havlíčkŭv Brod",
	"Herzogenaurach",
	"Ilion",
	"Kulgam",
	"Káto Polemídia",
	"Macia",
	"Sobradinho",
	"Vilaseca de Solcina",
	"Seymour",
	"Oakdale",
	"Ipatovo",
	"Bronnitsy",
	"Severobaykalsk",
	"Zărneşti",
	"Xiaping",
	"Liria",
	"Gelnhausen",
	"Unecha",
	"Caculé",
	"Madisonville",
	"Canalete",
	"Alcalá",
	"Romulus",
	"Kefar Yona",
	"Wallenhorst",
	"San Ignacio de Velasco",
	"Balete",
	"El Paso",
	"Alegria",
	"Bni Frassen",
	"Icod de los Vinos",
	"Troyan",
	"Floridia",
	"Hisor",
	"Leland",
	"Brodósqui",
	"Laguna de Duero",
	"Ternate",
	"Elektrogorsk",
	"Ahualulco de Mercado",
	"Cessnock",
	"Rolling Meadows",
	"Mégara",
	"Bondo",
	"Davlekanovo",
	"Nijlen",
	"Andoany",
	"Wekiwa Springs",
	"Jieshang",
	"Northdale",
	"Fort Hood",
	"Benbrook",
	"Nagtipunan",
	"Medeiros Neto",
	"Limbuhan",
	"Tirat Karmel",
	"Arbroath",
	"Zion",
	"San Rafael del Sur",
	"Lensk",
	"Watertown",
	"Frontignan",
	"Sederot",
	"Montrose",
	"Neihuzhai",
	"Columbus",
	"Conceição de Macabu",
	"San Felíu de Guixols",
	"Labrador",
	"Frascati",
	"Brotas",
	"Ortona",
	"Moncada",
	"Plainview",
	"Riehen",
	"Ruwa",
	"Veinticinco de Mayo",
	"Auburn",
	"Teorama",
	"Arnold",
	"Cordeirópolis",
	"Avon",
	"Tanki Leendert",
	"Horgen",
	"Peritoró",
	"San Felipe",
	"Ortigueira",
	"San Fernando",
	"Santo Tomas",
	"Achkhoy-Martan",
	"San Lorenzo de Esmeraldas",
	"Godalming",
	"Wilmington",
	"Tobatí",
	"Loves Park",
	"Canton",
	"Avellaneda",
	"Dunajská Streda",
	"Caniço",
	"Rawtenstall",
	"Ban Bang Rin",
	"La Prairie",
	"Ponedera",
	"San Fernando",
	"Palmeiras de Goiás",
	"Thorigny-sur-Marne",
	"Yarmouth",
	"Vero Beach South",
	"Pitogo",
	"Lermontov",
	"Malinao",
	"Medford",
	"Vilnohirsk",
	"Netphen",
	"Alcobaça",
	"Oliveira do Bairro",
	"Puchheim",
	"Jiucangzhou",
	"Calverley",
	"Qaţanā",
	"Sheybān",
	"São João de Pirabas",
	"Itaocara",
	"Shiling",
	"Qiryat Mal’akhi",
	"Lisle",
	"Riachão das Neves",
	"Jabonga",
	"Oud-Zuilen",
	"Sevan",
	"El Tejar",
	"Qiryat Shemona",
	"Eschborn",
	"Bacolod",
	"Tambaú",
	"Koło",
	"Tototlán",
	"East Retford",
	"Senador Guiomard",
	"Loanda",
	"Vranov nad Topľou",
	"Nakashunbetsu",
	"Devonport",
	"Bom Jesus dos Perdões",
	"Tecumseh",
	"Maibog",
	"Itambacuri",
	"Greenbelt",
	"Stoneham",
	"Nevyansk",
	"Senden",
	"Itaporã",
	"Bassar",
	"Tadmaït",
	"Warburg",
	"Taquarituba",
	"Altea",
	"Molodohvardiisk",
	"Kolpashevo",
	"Linstead",
	"Areia",
	"Abreus",
	"Granite Bay",
	"Fokino",
	"Pezinok",
	"Câmpia Turzii",
	"Walsrode",
	"Puerto Concordia",
	"Mandelieu-la-Napoule",
	"Assaré",
	"Tamiahua",
	"Abu",
	"Gersthofen",
	"Kilkís",
	"Dickinson",
	"Madalum",
	"Balen",
	"Puenteareas",
	"Bulusan",
	"Komló",
	"Esparraguera",
	"Mandaguaçu",
	"Oxford",
	"Tashtagol",
	"Tupiza",
	"Ganassi",
	"Lengir",
	"Capinzal",
	"Kenmore",
	"Sīwah",
	"Allison Park",
	"Farmington",
	"Nanyangcun",
	"Kilwinning",
	"Gusinoozërsk",
	"Orleães",
	"Wandlitz",
	"San Miguel Chicaj",
	"Kapfenberg",
	"Wulongpu",
	"Strakonice",
	"Mercato San Severino",
	"Cuilapan de Guerrero",
	"Gainsborough",
	"Wichian Buri",
	"Achí",
	"Calbiga",
	"Whyalla",
	"Princesa Isabel",
	"Grajewo",
	"Amoucha",
	"Gyêgu",
	"Hastings",
	"Montgomery",
	"Encantado",
	"Curumaní",
	"Guanajay",
	"Oyonnax",
	"Orobó",
	"Huaibaijie",
	"Aiud",
	"Conil de la Frontera",
	"Makīnsk",
	"Quaraí",
	"Plainsboro",
	"Yeadon",
	"Yuli",
	"Yako",
	"Vale de Cambra",
	"Yanshanbu",
	"Pirenópolis",
	"Rockingham",
	"Krasnouralsk",
	"Zedelgem",
	"Krasnoarmeysk",
	"Greenfield",
	"Taxisco",
	"West Goshen",
	"Auburn",
	"Frederickson",
	"Beixingzhuang",
	"Zvenigorod",
	"Steenwijk",
	"Karlsfeld",
	"Tighadouine",
	"Willoughby",
	"Dabeiba",
	"Amaraji",
	"Wädenswil",
	"Guadalupe",
	"Fāyīd",
	"Sidi Ifni",
	"Conselheiro Pena",
	"San Agustin",
	"Manghit",
	"Mustang",
	"Palma di Montechiaro",
	"Mount Pearl Park",
	"Roxbury",
	"La Democracia",
	"Znamianka",
	"Haywards Heath",
	"Wertheim",
	"Masterton",
	"Cullera",
	"Überlingen",
	"Maying",
	"Neusäß",
	"Leutkirch im Allgäu",
	"Patterson",
	"Eckernförde",
	"Manhumirim",
	"Tervuren",
	"Isilkul",
	"Gallup",
	"Mutuípe",
	"Zacualpa",
	"Radcliff",
	"Baroy",
	"Thebes",
	"Westerstede",
	"Buriti Bravo",
	"Trezzano sul Naviglio",
	"Patacamaya",
	"Prieto Diaz",
	"Crystal",
	"Olivet",
	"Arcos de Valdevez",
	"Kalat",
	"San Raimundo",
	"Guryevsk",
	"Lengerich",
	"Belton",
	"Nazca",
	"Allen",
	"Weilheim",
	"Forbach",
	"Glauchau",
	"Catigbian",
	"Montilla",
	"Działdowo",
	"Frauenfeld",
	"Catamayo",
	"San Cataldo",
	"Tocantinópolis",
	"Oteiza",
	"Pace",
	"Naqādah",
	"Barbasa",
	"Coín",
	"Rastede",
	"Tunuyán",
	"Losser",
	"Naciria",
	"Karachayevsk",
	"Saint-Genis-Laval",
	"Ataco",
	"Shaomi",
	"Marshall",
	"Cartagena",
	"Alnif",
	"Selouane",
	"Haar",
	"Laguna Beach",
	"Goldasht",
	"Webster Groves",
	"Acworth",
	"Le Creusot",
	"Røyken",
	"Cinco Saltos",
	"Griffin",
	"Santa Fe de Antioquia",
	"Itaparica",
	"Curchorem",
	"Vreden",
	"Botevgrad",
	"Morton Grove",
	"Conceição da Feira",
	"Caibiran",
	"Lysander",
	"Keene",
	"Padre Burgos",
	"Velsk",
	"Marina",
	"Oliveira dos Brejinhos",
	"Solon",
	"Rixensart",
	"Sarzana",
	"Valašské Meziříčí",
	"Svetlyy",
	"Petrila",
	"Bad Kissingen",
	"San Agustin",
	"Massarosa",
	"Kaltenkirchen",
	"San Miguel de Papasquiaro",
	"Barnegat",
	"New Brighton",
	"Iracemápolis",
	"Mandan",
	"Kalachinsk",
	"Buxton",
	"Ladyzhyn",
	"Carmo do Cajuru",
	"Buritis",
	"Donaueschingen",
	"Morrinhos",
	"Buenavista",
	"Winchester",
	"Amalfi",
	"Manzanares",
	"Salcedo",
	"Manosque",
	"Erval d’Oeste",
	"Seynod",
	"Frameries",
	"Staraya Kupavna",
	"Beek en Donk",
	"Lefkáda",
	"Barbate de Franco",
	"Šaľa",
	"Tubungan",
	"Pittsburg",
	"Elizabeth City",
	"Harriman",
	"Greenfield",
	"Mondovì",
	"Torre del Mar",
	"Zhengtun",
	"Magdalena",
	"Uenohara",
	"Souk Tlet El Gharb",
	"Doña Remedios Trinidad",
	"Oytal",
	"Metzingen",
	"Sondrio",
	"Fairhope",
	"Cottage Lake",
	"Machesney Park",
	"Wettingen",
	"Pachino",
	"Braidwood",
	"Lochristi",
	"Hurricane",
	"Sant’Arcangelo di Romagna",
	"Onondaga",
	"Laupheim",
	"Sagbayan",
	"Pogradec",
	"Omagh",
	"Lajas",
	"Dunaharaszti",
	"Annecy-le-Vieux",
	"Nagold",
	"Flores",
	"Wareham",
	"Baliguian",
	"Pudsey",
	"Barakī Barak",
	"Trussville",
	"Goleniów",
	"Pilar de la Horadada",
	"Karben",
	"Waynesboro",
	"Yahualica de González Gallo",
	"Feira Grande",
	"Cuyo",
	"Tecali",
	"Lempäälä",
	"Stadthagen",
	"Huangzhai",
	"Darton",
	"Burg",
	"Makó",
	"Pushkar",
	"Huautla",
	"Paraopeba",
	"Sardinata",
	"Edewecht",
	"Puente Nacional",
	"Dawmat al Jandal",
	"Burlington",
	"Buftea",
	"Johnston",
	"Porto Torres",
	"Corcoran",
	"Meadville",
	"Paese",
	"Ariano Irpino",
	"Martellago",
	"Bélabo",
	"Kadiria",
	"Carrascal",
	"Wenceslau Guimarães",
	"Caravelas",
	"Happy Valley",
	"Ginosa",
	"Friesoythe",
	"El Progreso",
	"East Peoria",
	"Westerly",
	"Agropoli",
	"Heide",
	"Bedford",
	"Santo Antônio de Posse",
	"Hopewell",
	"Mutatá",
	"Fort Walton Beach",
	"Radevormwald",
	"Nixa",
	"Caba",
	"Nadvirna",
	"Olanchito",
	"Flörsheim",
	"Socorro",
	"Khadyzhensk",
	"Hythe",
	"Santa Ana Nextlalpan",
	"Gaoguzhuang",
	"Clinton",
	"Apolda",
	"Zhedao",
	"Munster",
	"Christiansburg",
	"Gorgonzola",
	"Anthem",
	"Roselle",
	"Saint-Louis",
	"Amizmiz",
	"Halluin",
	"Garden City",
	"El Colegio",
	"Kopřivnice",
	"Priego de Córdoba",
	"Târnăveni",
	"Gardelegen",
	"La Jagua de Ibirico",
	"Castelnau-le-Lez",
	"Dianópolis",
	"Looc",
	"Santa Teresita",
	"Schwetzingen",
	"Klatovy",
	"Barra da Estiva",
	"Warrensburg",
	"São Vicente Ferrer",
	"Hockenheim",
	"Daxin",
	"Kihei",
	"Union City",
	"Casal di Principe",
	"Acatlán",
	"Zeewolde",
	"Borgomanero",
	"Lebu",
	"Mendeleyevsk",
	"Cornaredo",
	"Chankou",
	"Zhucaoying",
	"La Cruz",
	"Fougères",
	"Corinto",
	"Florida Ridge",
	"Ar Ruţbah",
	"Takahata",
	"Bubong",
	"Yahualica",
	"Demba",
	"Silver Firs",
	"Alvarado",
	"Patian",
	"Presidente Médici",
	"Colorado",
	"Dolton",
	"Natagaima",
	"Duncan",
	"Chaumont",
	"Ramain",
	"Irauçuba",
	"Palestrina",
	"Brent",
	"Bacchus Marsh",
	"Murayama",
	"Camaná",
	"Lyman",
	"Villeneuve-sur-Lot",
	"San Andrés Villa Seca",
	"El Doncello",
	"Northfield",
	"Nikolskoye",
	"Tepexi de Rodríguez",
	"Gata",
	"Pingtan",
	"Highland",
	"Nieuwerkerk aan den IJssel",
	"Las Torres de Cotillas",
	"Binnish",
	"Musselburgh",
	"Ḩārim",
	"Sequim",
	"Djibo",
	"Lyndhurst",
	"Prairie Village",
	"Djinet",
	"Herent",
	"Atuntaqui",
	"Kuna",
	"Coralville",
	"Waltham Abbey",
	"Saint-Jean-de-Braye",
	"Popeşti-Leordeni",
	"São Miguel do Araguaia",
	"Spremberg",
	"Fort Mill",
	"Caracaraí",
	"Bad Harzburg",
	"Mandirituba",
	"Terenos",
	"Ouardenine",
	"Bressanone",
	"Morris",
	"Niskayuna",
	"Comacchio",
	"Moineşti",
	"Waldkirch",
	"Hazebrouck",
	"Rose Hill",
	"Ban Piang Luang",
	"Lihuzhuang",
	"Eséka",
	"Caojiachuan",
	"San Rafael del Norte",
	"Auch",
	"Millau",
	"Hudson",
	"Geertruidenberg",
	"Lutz",
	"Chapa de Mota",
	"Alhama de Murcia",
	"Taylors",
	"Ramos",
	"Roanoke Rapids",
	"Guilford",
	"Vernon",
	"Afourar",
	"Vega Baja",
	"Tangalan",
	"Castrovillari",
	"Raymore",
	"Ensley",
	"Fremont",
	"Colleferro",
	"Mukwonago",
	"Diamantino",
	"Laventille",
	"Bavly",
	"Watertown",
	"East Patchogue",
	"Qarazhal",
	"Porto Belo",
	"Kitzingen",
	"Lebanon",
	"Qapqal",
	"Pickerington",
	"Binidayan",
	"Lexington",
	"Eislingen",
	"Silvania",
	"Arvin",
	"Cantanhede",
	"Bristol",
	"Schroeder",
	"Beni Douala",
	"Büdingen",
	"Kommunar",
	"Southold",
	"Palmital",
	"Honmachi",
	"Łęczna",
	"Raritan",
	"Manlleu",
	"Luofa",
	"Świedbodzin",
	"Pailitas",
	"Una",
	"Cortona",
	"Lino Lakes",
	"Augusta",
	"Woensdrecht",
	"Aljaraque",
	"Busuanga",
	"Milford",
	"Cunha",
	"Shelbyville",
	"Corinth",
	"Sidi Rahal",
	"Kontich",
	"Yaojiafen",
	"Timbiquí",
	"Naples",
	"Bataguaçu",
	"Maghull",
	"Pantar",
	"Ust’-Katav",
	"Maple Heights",
	"Vänersborg",
	"Tiquisio",
	"Semikarakorsk",
	"Łaziska Górne",
	"Peshtera",
	"Eppingen",
	"Taminango",
	"Lugus",
	"Gotse Delchev",
	"Hlohovec",
	"Póvoa de Lanhoso",
	"Unity",
	"Jalpan",
	"Pio XII",
	"Kozmodemyansk",
	"Chester",
	"Gardner",
	"San Bonifacio",
	"Vilyuchinsk",
	"Carutapera",
	"Kemi",
	"Omutninsk",
	"Monte Azul",
	"Sonthofen",
	"Kotovo",
	"Anicuns",
	"Colônia Leopoldina",
	"Puerto Suárez",
	"Axapusco",
	"Vereshchagino",
	"Union Hill-Novelty Hill",
	"Elsdorf",
	"Xanten",
	"Rolleston",
	"Seligenstadt",
	"Peters",
	"Kotli",
	"Coreaú",
	"Isernia",
	"Dumbarton",
	"Nøtterøy",
	"Siloam Springs",
	"Moguer",
	"Thenia",
	"Iisalmi",
	"Cajidiocan",
	"Opoczno",
	"Reigate",
	"Palafrugell",
	"Pedernales",
	"Trinidad",
	"East Hampton",
	"Banaue",
	"Wieliczka",
	"Guía de Isora",
	"Tongzhou",
	"Tornio",
	"Osterode",
	"Gif-sur-Yvette",
	"Piqua",
	"Amherstburg",
	"Chełmno",
	"Hallein",
	"Poortugaal",
	"Fish Hawk",
	"Yoqne‘am ‘Illit",
	"El Ksiba",
	"Zorgo",
	"Port Hueneme",
	"Caxambu",
	"Lagonglong",
	"Lakeside",
	"Gympie",
	"Chã Grande",
	"Tolosa",
	"Candoni",
	"São José da Coroa Grande",
	"Azambuja",
	"Casablanca",
	"Summit",
	"Halewood",
	"Secaucus",
	"Potters Bar",
	"Uruçuca",
	"Colle di Val d’Elsa",
	"Golden Valley",
	"Olho d’Água das Flores",
	"Ginebra",
	"Calamba",
	"Gros Islet",
	"Codlea",
	"Couëron",
	"Fukuyoshi",
	"Alcântara",
	"Mount Vernon",
	"Mashiko",
	"Lisieux",
	"Imi-n-Tanout",
	"Bañolas",
	"Tidili Masfiywat",
	"Ilsede",
	"Brockville",
	"Santa Maria das Barreiras",
	"Ocotlán de Morelos",
	"Bloomington",
	"Piazza Armerina",
	"Maigo",
	"Camanducaia",
	"Camp Springs",
	"Siilinjärvi",
	"New Hartford",
	"‘Adrā",
	"Manari",
	"Itajuípe",
	"Isla-Cristina",
	"San Martín de las Pirámides",
	"Bornem",
	"Geneva",
	"Cavinti",
	"São Francisco de Paula",
	"Burgos",
	"Puerto Triunfo",
	"San Bernardo",
	"Rancho San Diego",
	"Silva Jardim",
	"Itaiópolis",
	"Collingwood",
	"Alcalá la Real",
	"Quickborn",
	"Monsummano",
	"Zundert",
	"Itapecerica",
	"Coronel Vivida",
	"Bloomingdale",
	"Pergine Valsugana",
	"Saint Austell",
	"Corciano",
	"Viga",
	"Palo del Colle",
	"Bilohorodka",
	"Miguelópolis",
	"Watertown",
	"Kurikka",
	"São Marcos",
	"Darien",
	"Germersheim",
	"Gökçebey",
	"Dax",
	"Shafter",
	"Eastmont",
	"Wallington",
	"Lincoln",
	"Stadtallendorf",
	"Katy",
	"Brownwood",
	"Chernogolovka",
	"Versmold",
	"Savigliano",
	"Bato",
	"Del City",
	"Woodlesford",
	"Kobilo",
	"Eyl",
	"Almoradí",
	"Zerbst",
	"Montecatini Terme",
	"Batad",
	"Senaki",
	"Caaporã",
	"San Jacinto",
	"Smithfield",
	"Beaune",
	"São Felipe",
	"Cassilândia",
	"Bad Rappenau",
	"Nova Brasilândia d’Oeste",
	"Follonica",
	"Wülfrath",
	"Jacksonville",
	"Les Pennes-Mirabeau",
	"Yenice",
	"Entroncamento",
	"Silverdale",
	"Allendale",
	"Kant",
	"Nefta",
	"Aichach",
	"Denain",
	"Crisópolis",
	"Kirkintilloch",
	"Uruçuí",
	"Paraguaçu",
	"Lower",
	"San Nicolás",
	"Lindlar",
	"Nueva Granada",
	"Tamgrout",
	"La Resolana",
	"San Justo",
	"Leerdam",
	"Darien",
	"Hoogstraten",
	"Ozoir-la-Ferrière",
	"Perrysburg",
	"Zhoucun",
	"Coswig",
	"Sondershausen",
	"Scugog",
	"Galeras",
	"Lewistown",
	"Richmond",
	"Allauch",
	"Nang Rong",
	"Piddig",
	"Tong’anyi",
	"Anagni",
	"Argenta",
	"Acatic",
	"Parelhas",
	"Eeklo",
	"Gardanne",
	"Hajnówka",
	"MacArthur",
	"Grottaferrata",
	"La Porte",
	"Perdões",
	"Geseke",
	"Gherla",
	"Clarksville",
	"Dayr Abū Ḩinnis",
	"New Castle",
	"Buritirama",
	"Jämsä",
	"Murree",
	"Kingsville",
	"Gujan-Mestras",
	"Monte Santo de Minas",
	"Áno Sýros",
	"Porto Franco",
	"Barro",
	"Leek",
	"Romano di Lombardia",
	"Cajueiro",
	"Kara-Suu",
	"Baie-Comeau",
	"Qia’erbagecun",
	"Harsefeld",
	"Santa Bárbara",
	"Deán Funes",
	"Villagarzón",
	"Anini-y",
	"Paraibano",
	"Bellview",
	"Vecsés",
	"Picasent",
	"Lagoa do Itaenga",
	"Livadeiá",
	"Biddeford",
	"Suzzara",
	"Moniquirá",
	"Wang Nam Yen",
	"Juli",
	"Somers",
	"Sarreguemines",
	"Ryazhsk",
	"Rosolini",
	"Villa Park",
	"Magallanes",
	"El Paujíl",
	"Almaguer",
	"Fernley",
	"Xintian",
	"Essex",
	"Prudnik",
	"Schramberg",
	"Khotkovo",
	"Elkridge",
	"Kutná Hora",
	"Jindřichŭv Hradec",
	"Naawan",
	"Palatka",
	"Antsohihy",
	"Tarko-Sale",
	"Sint-Katelijne-Waver",
	"Traunreut",
	"Mukilteo",
	"Cugir",
	"Urussanga",
	"East Pennsboro",
	"Atoka",
	"Prichard",
	"Le Ray",
	"Orós",
	"Waghäusel",
	"Soltau",
	"Hays",
	"Tubbergen",
	"Oulad Embarek",
	"Tizi-n-Bechar",
	"Groß-Umstadt",
	"Pájara",
	"Sanhe",
	"Los Vilos",
	"Nerja",
	"Sestu",
	"Recanati",
	"Birmingham",
	"Paradise",
	"Sessa Aurunca",
	"Pompéia",
	"Marogong",
	"Montville",
	"Easley",
	"La Unión",
	"Brambleton",
	"Gryfino",
	"Kaltan",
	"Totoró",
	"Samboan",
	"Palmer",
	"Shisui",
	"Jandaia do Sul",
	"Hindang",
	"Los Llanos de Aridane",
	"Lyskovo",
	"Varadero",
	"Belovodskoe",
	"Youganning",
	"Barcelona",
	"Asni",
	"Ramona",
	"Carei",
	"Bananeiras",
	"Owosso",
	"King of Prussia",
	"Elverum",
	"DeBary",
	"Araripe",
	"Garforth",
	"Eustis",
	"Tanhaçu",
	"South Euclid",
	"Ādēt",
	"South Holland",
	"Jiangjiadong",
	"Pushchino",
	"Okahandja",
	"Palm Valley",
	"Bülach",
	"Ludlow",
	"Nerekhta",
	"Carmo do Rio Claro",
	"Paraisópolis",
	"Givors",
	"Ashland",
	"Sapulpa",
	"Santa María Chilchotla",
	"Manuel Tames",
	"Alfena",
	"Bonito",
	"Duarte",
	"Aguadas",
	"Rio Maior",
	"Marietta",
	"Lucélia",
	"Great Linford",
	"Griffith",
	"Brezno",
	"Ayamonte",
	"Battle Ground",
	"Ferentino",
	"Stephenville",
	"Monte Alegre de Minas",
	"Itororó",
	"Amsterdam",
	"Montecristo",
	"Sandhurst",
	"Oxford",
	"Reichenbach/Vogtland",
	"Baixa Grande",
	"Sadao",
	"Park Forest",
	"San Salvo",
	"Evans",
	"Alexandria",
	"Schenefeld",
	"Hancha",
	"Fort Atkinson",
	"Hazel Dell",
	"Varkaus",
	"Bastos",
	"Carrboro",
	"Baclayon",
	"Seria",
	"Agan",
	"Bardstown",
	"East Ridge",
	"Wipperfürth",
	"Elko",
	"Uxbridge",
	"Covington",
	"Florencia",
	"Suaza",
	"Bologoye",
	"Cabucgayan",
	"Gaffney",
	"Quezon",
	"Xinnongcun",
	"Alcudia",
	"Praia da Vitória",
	"Boralday",
	"Sun Valley",
	"Komatipoort",
	"Tigbao",
	"Barroso",
	"Anping",
	"West Deptford",
	"Sidney",
	"Bonney Lake",
	"Urucurituba",
	"Taperoá",
	"Parabcan",
	"Ecclesall",
	"Fröndenberg",
	"Harstad",
	"Villapinzón",
	"El Calafate",
	"Dickinson",
	"Martha Lake",
	"Cournon-d’Auvergne",
	"Little Egg Harbor",
	"Bad Schwartau",
	"Faversham",
	"Vikhorevka",
	"Welench’ītī",
	"Bothell West",
	"Bruchköbel",
	"Jangy-Nookat",
	"Fredonia",
	"Werdau",
	"Stange",
	"Trecate",
	"Pakil",
	"Aspe",
	"Pindobaçu",
	"Arroio do Meio",
	"Imi n’Oulaoun",
	"Arnold",
	"Campestre",
	"Can-Avid",
	"Vyškov",
	"La Colonia Tovar",
	"Essa",
	"Santo Anastácio",
	"Günzburg",
	"Ballenger Creek",
	"Bahía de Caráquez",
	"Sotouboua",
	"Crowborough",
	"Toguchin",
	"Ripley",
	"Steinhagen",
	"Chascomús",
	"Elektrougli",
	"Saka",
	"El Retén",
	"Rolla",
	"Sysert",
	"West Pensacola",
	"Harvey",
	"Winschoten",
	"Johnstown",
	"Sokal",
	"Grand Island",
	"Candiac",
	"Gallipoli",
	"Zhangjiazhuang",
	"Bni Rzine",
	"Medina",
	"Abano Terme",
	"Cockeysville",
	"Žďár nad Sázavou",
	"Oro-Medonte",
	"Jefferson",
	"Pleasant Prairie",
	"Mirador",
	"Palma del Río",
	"Voiron",
	"Mitry-Mory",
	"Liubotyn",
	"Shelbyville",
	"Senguio",
	"Bugalagrande",
	"Bloomfield",
	"Tepezalá",
	"Gigaquit",
	"Dudinka",
	"Mühldorf",
	"Nurota",
	"Comodoro",
	"Hualqui",
	"Lambari",
	"Castel San Pietro Terme",
	"Roshal",
	"Caoayan",
	"Varennes",
	"St. Andrews",
	"Milwaukie",
	"Rivalta di Torino",
	"Maribojoc",
	"Challans",
	"Madre de Deus",
	"Butiá",
	"Bussolengo",
	"Pedra",
	"Guará",
	"Bryant",
	"Mae Sai",
	"Nizhniy Lomov",
	"Urk",
	"Purranque",
	"Bohumín",
	"Luckenwalde",
	"Guipos",
	"Enger",
	"Sêrro",
	"Dillingen",
	"Siquinalá",
	"Salmon Creek",
	"Hyde Park",
	"Cruzeiro do Oeste",
	"Hernani",
	"Kill Devil Hills",
	"Oderzo",
	"Araçoiaba",
	"North Salt Lake",
	"Apollo Beach",
	"Valença do Piauí",
	"Traunstein",
	"’Aïn el Hammam",
	"Herborn",
	"Al Brouj",
	"Comala",
	"Bombinhas",
	"Gauting",
	"Vico Equense",
	"Ararat",
	"Osa",
	"Kurovskoye",
	"Catarina",
	"Kremenets",
	"Cornelia",
	"Schifferstadt",
	"Suwanee",
	"New Hope",
	"Ubaitaba",
	"Gerlingen",
	"Carmen",
	"Wade Hampton",
	"Wuustwezel",
	"Green Valley",
	"Mill Creek",
	"Samacá",
	"Pallazzolo sull’Oglio",
	"Ovejas",
	"Pico Truncado",
	"Yangping",
	"Universal City",
	"Mössingen",
	"Sotomayor",
	"Kirkby in Ashfield",
	"Buinsk",
	"Montclair",
	"Gamboma",
	"López",
	"Lorton",
	"Bourg-lès-Valence",
	"Kuusankoski",
	"Clemmons",
	"Strathroy-Caradoc",
	"Güimar",
	"Olenegorsk",
	"Valkeakoski",
	"Viesca",
	"Hexiang",
	"Rosamond",
	"Bear",
	"Horten",
	"Boussu",
	"Blieskastel",
	"Dyersburg",
	"Blaj",
	"Haßloch",
	"Manor",
	"Puzol",
	"Oneida",
	"Wachtberg",
	"Aomar",
	"Hanmayingcun",
	"Ceres",
	"Santa Fe",
	"Hunters Creek",
	"Holiday",
	"Louisville",
	"Feltre",
	"Pacatu",
	"Ubaíra",
	"Lebedinovka",
	"Giovinazzo",
	"Stamford",
	"Dixon",
	"Allende",
	"Ino",
	"San Jose",
	"Pontassieve",
	"Scobinţi",
	"Mabitac",
	"Lastra a Signa",
	"Carcagente",
	"Lazi",
	"Dugulubgey",
	"Murray",
	"Pozzallo",
	"Inopacan",
	"Golden",
	"Nidderau",
	"Illapel",
	"Bad Salzungen",
	"Blansko",
	"Acquaviva delle Fonti",
	"Nova Granada",
	"Olean",
	"Chachahuantla",
	"Hoddesdon",
	"Dolyna",
	"Na Klang",
	"Clifton",
	"Senica",
	"Fonte Boa",
	"Belém de São Francisco",
	"Elvas",
	"Tala Yfassene",
	"Melena del Sur",
	"Grayslake",
	"Mäntsälä",
	"Tielt",
	"Pita",
	"Okha",
	"Ronda",
	"Riacho das Almas",
	"Piratini",
	"Cranendonck",
	"Ménaka",
	"Andirá",
	"South Milwaukee",
	"Shimogamo",
	"Sidi Chiker",
	"Cittadella",
	"Miracema do Tocantins",
	"Clarin",
	"Pertuis",
	"Pandan",
	"Gardner",
	"Nové Mesto nad Váhom",
	"Arhavi",
	"Senden",
	"Duderstadt",
	"Hailsham",
	"Nördlingen",
	"Sabaudia",
	"Havelock",
	"São João do Piauí",
	"Wasaga Beach",
	"Neufahrn bei Freising",
	"Lake Ronkonkoma",
	"Cirencester",
	"Chapulhuacán",
	"Lebanon",
	"Vazante",
	"Ban Tha Pha",
	"Batī",
	"East Moline",
	"Ås",
	"Baião",
	"Miandrivazo",
	"Gjøvik",
	"Korntal-Münchingen",
	"Floreşti",
	"Norwalk",
	"Beroun",
	"Bobrov",
	"Itanhém",
	"Schortens",
	"Kawayan",
	"Goris",
	"Pak Phanang",
	"Mar’’ina Horka",
	"Cachoeirinha",
	"Yorkville",
	"Archena",
	"Mbala",
	"Kawambwa",
	"New Glasgow",
	"Sidi Jaber",
	"Logansport",
	"Linamon",
	"Douar Lamrabih",
	"Station des Essais M.V.A.",
	"San Felipe",
	"Chodzież",
	"Aldan",
	"Muzambinho",
	"Hiddenhausen",
	"Barehra",
	"Casarano",
	"Nikolsk",
	"Veroli",
	"Dublin",
	"Donggou",
	"North Ogden",
	"Aïn Cheggag",
	"Bibai",
	"Gioia Tauro",
	"Stadtlohn",
	"Meinerzhagen",
	"Baden",
	"Qagan Us",
	"Arsk",
	"Timaná",
	"San Mauro Torinese",
	"Sunchales",
	"Cambridge",
	"Itapiúna",
	"Miracatu",
	"Hem",
	"Medina del Campo",
	"Altônia",
	"Gaeta",
	"Wilmot",
	"Acri",
	"Mundra",
	"Colonia General Felipe Ángeles",
	"Laboulaye",
	"Tyrnyauz",
	"San Pablo Tacachico",
	"Pleszew",
	"Lynn Haven",
	"Ferguson",
	"Khvānsār",
	"Arlington",
	"Nova Xavantina",
	"Brunswick",
	"Sacile",
	"Cinfães",
	"Água Branca",
	"Willmar",
	"Alice",
	"Seydi",
	"South Ockendon",
	"Swansboro",
	"Marblehead",
	"Murphy",
	"Gostyń",
	"São Joaquim do Monte",
	"Lannion",
	"Bad Waldsee",
	"Üshtöbe",
	"Carquefou",
	"Tobe",
	"Takanabe",
	"Culpeper",
	"San Lorenzo",
	"Ocós",
	"Berlin",
	"Ozark",
	"Cagwait",
	"Puerto Quito",
	"Lugait",
	"Montalto Uffugo",
	"Rancheria Payau",
	"American Canyon",
	"Gaz",
	"Annaberg-Buchholz",
	"Papillion",
	"Baza",
	"Loja",
	"Kampong Thum",
	"Rhenen",
	"Piove di Sacco",
	"Tenjo",
	"Schwanewede",
	"Ely",
	"Belūr",
	"Moorestown",
	"Ibirubá",
	"The Dalles",
	"Karnobat",
	"Edam",
	"Adel",
	"Ostrov",
	"Bladel",
	"Jaboticatubas",
	"Cabra",
	"Requena",
	"Essex",
	"Villefontaine",
	"Uetze",
	"Porriño",
	"La Estrada",
	"Svilengrad",
	"Barre",
	"Kubinka",
	"Wittmund",
	"Cañas",
	"Hatvan",
	"Amherst Center",
	"Reggane",
	"Concordia",
	"Melzo",
	"Piñan",
	"Crest Hill",
	"Zülpich",
	"Bequimão",
	"Punta Gorda",
	"Mayluu-Suu",
	"Teculután",
	"Kloten",
	"Wilnsdorf",
	"Neabsco",
	"Mělník",
	"Newton",
	"Khowrzūq",
	"Poplar Bluff",
	"Ennis",
	"Coolbaugh",
	"Oberkirch",
	"Cuité",
	"Jaguaruna",
	"Tukwila",
	"Marneuli",
	"Ogden",
	"Sátiro Dias",
	"Donauwörth",
	"Greiz",
	"São José do Rio Preto",
	"Tsotsin-Yurt",
	"Hörstel",
	"Rumoi",
	"Prunedale",
	"Orvieto",
	"Santo Antônio do Sudoeste",
	"Jirkov",
	"Jdour",
	"Albertville",
	"Mirfield",
	"Leinefelde",
	"Aragarças",
	"Payson",
	"Villa Hidalgo",
	"Lichtenfels",
	"Mountain Brook",
	"Sergach",
	"Dingolfing",
	"Padre Paraíso",
	"Porto Real do Colégio",
	"Glassboro",
	"Pedro Carbo",
	"Simití",
	"Sandwich",
	"Neustrelitz",
	"Ciudad Melchor de Mencos",
	"Rovira",
	"Bouguenais",
	"Náchod",
	"Brandýs nad Labem-Stará Boleslav",
	"Diguapo",
	"New Canaan",
	"Hakui",
	"Ashland",
	"Minturno",
	"La Paz",
	"El Viso del Alcor",
	"Sbeitla",
	"Lalín",
	"Kęty",
	"Shelton",
	"San Guillermo",
	"Torgau",
	"Shenandoah",
	"Araçariguama",
	"Mougins",
	"Buckingham",
	"Major Isidoro",
	"Tuburan",
	"Acqui Terme",
	"Cherry Hill",
	"Rosas",
	"Henderson",
	"Viadana",
	"Eastwood",
	"Cruz Grande",
	"Linda",
	"Yeniseysk",
	"Skegness",
	"Holzminden",
	"Muhlenberg",
	"Calatayud",
	"Agoura Hills",
	"Goole",
	"Cahors",
	"East Hemet",
	"Celorico de Basto",
	"Zubia",
	"Libertyville",
	"Dračevo",
	"Durango",
	"Fót",
	"Le Puy-en-Velay",
	"Sapang Dalaga",
	"Bad Krozingen",
	"Snina",
	"Pikalëvo",
	"East Northport",
	"Llorente",
	"Ivoti",
	"Vriezenveen",
	"Wisch",
	"Kotelnikovo",
	"Bichena",
	"Culion",
	"Abrīsham",
	"Howard",
	"Irlam",
	"São Luís Gonzaga do Maranhão",
	"Monmouth",
	"Ypsilanti",
	"Toprakkale",
	"Rocky Hill",
	"Mokena",
	"Fort St. John",
	"Ebbw Vale",
	"Wildeshausen",
	"Sesheke",
	"Saint-Dié-des-Vosges",
	"Pleasantville",
	"L’Isle-sur-la-Sorgue",
	"Affton",
	"Millburn",
	"Metztitlán",
	"Haka",
	"Bartow",
	"Guding",
	"Mithi",
	"Scarborough",
	"Ashland",
	"Mead Valley",
	"Telgte",
	"Jiangjiehe",
	"Miamisburg",
	"Lancing",
	"Yuancun",
	"Qorasuv",
	"Araruna",
	"Grafton",
	"Merrick",
	"Schilde",
	"’Ali Ben Sliman",
	"Naranjos",
	"Mairi",
	"Charleston",
	"Germantown",
	"Uetersen",
	"Giengen an der Brenz",
	"La Chapelle-sur-Erdre",
	"Aquidabã",
	"Icapuí",
	"Anagé",
	"Guaymango",
	"Itarantim",
	"Taquarana",
	"Guapiaçu",
	"Escanaba",
	"Northwich",
	"Selden",
	"Bayou Cane",
	"Cartaya",
	"Mililani Mauka",
	"Jericoacoara",
	"Glinde",
	"Kwinana",
	"Kasongo-Lunda",
	"Zverevo",
	"Cassano d’Adda",
	"Tegelen",
	"Snellville",
	"Shirahama",
	"Hidrolândia",
	"Zhaodianzi",
	"Moaña",
	"Junqueirópolis",
	"Zhosaly",
	"Zwijndrecht",
	"Kūysinjaq",
	"Santiago Texacuangos",
	"Sulphur",
	"L’Assomption",
	"Alagir",
	"Shakhunya",
	"Kürten",
	"South St. Paul",
	"Oregon",
	"Ban Tha Kham",
	"Tarhzirt",
	"Burnie",
	"Bachi-Yurt",
	"Ipanema",
	"Caridade",
	"Kyakhta",
	"Carira",
	"Middleton",
	"Ferndale",
	"Casamassima",
	"Wetherby",
	"Canto do Buriti",
	"Yeppoon",
	"Forest Park",
	"Dęblin",
	"Haya",
	"El Mansouria",
	"El Arenal",
	"La Cañada Flintridge",
	"Hauppauge",
	"Al Mijlad",
	"Zemio",
	"Pugo",
	"Alpinópolis",
	"Törökszentmiklós",
	"Neviges",
	"Luisiana",
	"Ennigerloh",
	"Safford",
	"Rocky River",
	"São José de Piranhas",
	"Had Zraqtane",
	"Ceglie Messapico",
	"Usman",
	"Concarneau",
	"Rubiataba",
	"Wenden",
	"Mpwapwa",
	"Heusenstamm",
	"Uruburetama",
	"Aït Tamlil",
	"El Reno",
	"Lajinha",
	"Umirim",
	"Saco",
	"Fairwood",
	"Greenwood",
	"West St. Paul",
	"Xiaqiaotou",
	"Rovato",
	"Liuliang",
	"Salsomaggiore Terme",
	"Blankenburg",
	"Cardedeu",
	"Spennymoor",
	"Imouzzer Kandar",
	"Äänekoski",
	"Volketswil",
	"Mapai",
	"Os",
	"Schopfheim",
	"Pradópolis",
	"Valley",
	"Orinda",
	"Helena",
	"Henichesk",
	"Comapa",
	"Cullman",
	"Schmalkalden",
	"Eschwege",
	"Senec",
	"Ban Na San",
	"Alamo",
	"Signa",
	"Sand Springs",
	"Brandsen",
	"Spoltore",
	"Nizhnyaya Tura",
	"Tiverton",
	"Hamina",
	"Vernal",
	"Callosa de Segura",
	"Castilho",
	"Laurinburg",
	"Saint-Lazare",
	"Hudson",
	"Chestermere",
	"Painesville",
	"Poperinge",
	"Madalena",
	"Sherwood",
	"Santa Vitória",
	"Lake Zurich",
	"Ba",
	"El Castillo de La Concepción",
	"Comăneşti",
	"Gubakha",
	"Utebo",
	"Castellana Grotte",
	"Ariccia",
	"Amorebieta",
	"Volokolamsk",
	"Arcore",
	"Puerto Armuelles",
	"Waldbröl",
	"Huanímaro",
	"General Martín Miguel de Güemes",
	"Riachão do Dantas",
	"Upper Allen",
	"Saint-Lô",
	"Hazlet",
	"Bressuire",
	"Rosedale",
	"Silla",
	"Ottawa",
	"Lerdo de Tejada",
	"Sil-li",
	"Itigi",
	"Pescia",
	"Barrhead",
	"Bourne",
	"Chipiona",
	"Cognac",
	"Kitatajima",
	"Ochtrup",
	"Buşrá ash Shām",
	"Huntsville",
	"Sulzbach-Rosenberg",
	"Guantiankan",
	"Meadowbrook",
	"Sarstedt",
	"Liuguang",
	"Corner Brook",
	"Casa de Oro-Mount Helix",
	"Xiaba",
	"Monroe",
	"Paulistana",
	"Sélestat",
	"Zaouïa Aït Ishak",
	"Lake Shore",
	"Mölln",
	"Parma Heights",
	"Puerto El Triunfo",
	"Nyandoma",
	"Karcag",
	"Nottuln",
	"South Whitehall",
	"Maḩmūdābād Nemūneh",
	"Old Jamestown",
	"Simonésia",
	"Monroe",
	"Pichidegua",
	"Selma",
	"Makakilo",
	"Karuizawa",
	"Helden",
	"Tahlequah",
	"Jnane Bouih",
	"Cajari",
	"Brockport",
	"Calvillo",
	"Carate Brianza",
	"Atok",
	"Itatinga",
	"Campina Verde",
	"Kleppe",
	"Arbaoun",
	"Luna",
	"Marsberg",
	"Norton",
	"Casalgrande",
	"Rockport",
	"Tradate",
	"Upper St. Clair",
	"Regente Feijó",
	"Ekazhevo",
	"Várpalota",
	"Río Segundo",
	"Oschersleben",
	"Hongshui",
	"Poção de Pedras",
	"Mesquite",
	"Agryz",
	"Rybnoye",
	"Tash-Kömür",
	"Polonuevo",
	"Front Royal",
	"Eupen",
	"San José Ojetenam",
	"Babanūsah",
	"Kufstein",
	"Chopinzinho",
	"West Mifflin",
	"Doujing",
	"Springfield",
	"Ciriè",
	"Urumita",
	"Jakobstad",
	"Anguillara Sabazia",
	"Rhar el Melah",
	"Somerton",
	"Nakło nad Notecią",
	"Blythe",
	"Southbury",
	"Borda da Mata",
	"Dharmsāla",
	"Žiar nad Hronom",
	"Pôrto Grande",
	"Yuanhucun",
	"Cugnaux",
	"Oulad Salmane",
	"Riverview",
	"Bethel",
	"Sandbach",
	"Yahotyn",
	"Wangaratta",
	"Momchilgrad",
	"Malabuyoc",
	"Barreira",
	"Capoeiras",
	"Weil der Stadt",
	"Espiritu",
	"Ait Bousarane",
	"Lloydminster",
	"Burghausen",
	"Keszthely",
	"Horizon City",
	"Levin",
	"Montgomery",
	"Tirmitine",
	"Presidente Olegário",
	"El Arahal",
	"Rāwah",
	"Ban Bueng",
	"Stillwater",
	"Cherepanovo",
	"Eloy",
	"Artik",
	"Utinga",
	"Sint-Gillis-Waas",
	"Joliette",
	"Atasū",
	"Nesoddtangen",
	"Hechingen",
	"Tolentino",
	"Rožňava",
	"Adria",
	"Meerssen",
	"Riom",
	"Bruges",
	"Diepenbeek",
	"Brigham City",
	"Plaisance-du-Touch",
	"Yershov",
	"Hemmingen",
	"Heinola",
	"Capela do Alto",
	"Gilching",
	"Murrysville",
	"Júlio de Castilhos",
	"Candelaria",
	"Jasien",
	"Pidhorodne",
	"Abaré",
	"Morros",
	"Brainerd",
	"Rio Rico",
	"Engelskirchen",
	"Rhede",
	"Mazagão",
	"Yellowknife",
	"Ibicoara",
	"Hoxut",
	"Haslett",
	"Haiger",
	"Töle Bī",
	"São Gabriel",
	"Stowmarket",
	"Xihuangcun",
	"Tullahoma",
	"Baldwin",
	"San Miguel",
	"Monkseaton",
	"Rolante",
	"Tapalpa",
	"Terek",
	"Tipo-Tipo",
	"Someren",
	"Monroe",
	"Aradíppou",
	"Beloyarskiy",
	"Norak",
	"Anna",
	"Kos",
	"Borna",
	"Bad Aibling",
	"Seesen",
	"Danville",
	"Szigethalom",
	"Amatán",
	"Bückeburg",
	"Tadian",
	"Romsey",
	"Rio Verde de Mato Grosso",
	"Bad Berleburg",
	"Capinota",
	"Squamish",
	"Zossen",
	"South Burlington",
	"Kovylkino",
	"Kempston",
	"Traiskirchen",
	"Reriutaba",
	"Lichtenvoorde",
	"Regensdorf",
	"North Liberty",
	"Lake Butler",
	"Vincennes",
	"Heishanzuicun",
	"Ardon",
	"Huércal-Overa",
	"Echt",
	"Gōdo",
	"Kantharalak",
	"Sienna Plantation",
	"Heishuikeng",
	"Holtsville",
	"Kapangan",
	"East Dereham",
	"Mayen",
	"Magitang",
	"Abbeville",
	"Vila Real de Santo António",
	"Tettnang",
	"Lodeynoye Pole",
	"Fécamp",
	"Bourbonnais",
	"Starodub",
	"Motru",
	"Sipoo",
	"Royan",
	"Müllheim",
	"Ramsbottom",
	"Syosset",
	"Zola Predosa",
	"Łańcut",
	"Matteson",
	"Papanduva",
	"Rivière-du-Loup",
	"Lake Forest",
	"Pariquera-Açu",
	"Aguinaldo",
	"Quixeré",
	"Salgado",
	"Chiari",
	"Cobourg",
	"Kolbermoor",
	"Krasnystaw",
	"Tangbian",
	"Dalanzadgad",
	"Venceslau Brás",
	"Vellmar",
	"Angleton",
	"Ituaçu",
	"Lower Allen",
	"Scorzè",
	"Bad Tölz",
	"Três Barras",
	"Pułtusk",
	"Dorchester",
	"Dagohoy",
	"Mingjiujie",
	"Rayevskiy",
	"Cabildo",
	"Castaic",
	"Kamiichi",
	"Sengés",
	"Bad Pyrmont",
	"Cocos",
	"Canby",
	"Ferguson",
	"Montemurlo",
	"Águas Formosas",
	"Balta",
	"Juquiá",
	"Wendell",
	"Marchena",
	"San Vito dei Normanni",
	"Wittlich",
	"Monte Azul Paulista",
	"Schwalmtal",
	"Paranapanema",
	"Ampère",
	"Buerarema",
	"Terzigno",
	"Iiyama",
	"Izra",
	"Frankfort",
	"Mukaiengaru",
	"Laconia",
	"Haldensleben",
	"Sorgues",
	"Atarfe",
	"Bergneustadt",
	"Butig",
	"Lieto",
	"Bracciano",
	"Tibagi",
	"Narni",
	"San Antonio Sacatepéquez",
	"Silverthorne",
	"Alhandra",
	"Altamont",
	"Aci Sant’Antonio",
	"Zerong",
	"Sidi Redouane",
	"Ayaviri",
	"Eersel",
	"Kralupy nad Vltavou",
	"Varzelândia",
	"Korsholm",
	"Ban Mae Hia Nai",
	"Monção",
	"Sheridan",
	"Kirovgrad",
	"Soure",
	"Lebedyan",
	"Durant",
	"Horizontina",
	"Agliana",
	"Sylvania",
	"San Miguel",
	"Santa Marinella",
	"Veresegyház",
	"Dolynska",
	"Lebach",
	"Bakal",
	"Groesbeek",
	"Kronberg",
	"Corning",
	"Anori",
	"Arbutus",
	"Püttlingen",
	"Corbetta",
	"Milton",
	"Pfullingen",
	"Iati",
	"White Oak",
	"Lgov",
	"Žatec",
	"São Francisco de Assis",
	"Cranbrook",
	"Lede",
	"Aroeiras",
	"Kabūdarāhang",
	"Sömmerda",
	"Pinole",
	"Dolný Kubín",
	"Erlanger",
	"Noci",
	"Druten",
	"Abdulino",
	"Ware",
	"Bandar-e Kong",
	"Jamiltepec",
	"Dillingen",
	"Altoona",
	"Bethany",
	"Uzhur",
	"Moreni",
	"Buruanga",
	"La Unión",
	"Lumphat",
	"Mount Eliza",
	"Albolote",
	"Port Alfred",
	"Carlisle",
	"Antas",
	"Whitpain",
	"Huehuetla",
	"El Quetzal",
	"Valderrama",
	"Capanema",
	"Valenza",
	"Bredene",
	"Vera",
	"Maracaçumé",
	"Palmi",
	"Ait Ben Daoudi",
	"Staveley",
	"Aporá",
	"Stevenson Ranch",
	"Karia Ba Mohamed",
	"Dadeldhurā",
	"Highland",
	"Liberal",
	"Ibirama",
	"Sebt Gzoula",
	"Tazishan",
	"Cameron Park",
	"Pastavy",
	"Tak Bai",
	"Bad Driburg",
	"Camacupa",
	"Santiago",
	"La Ligua",
	"Dupax del Sur",
	"Camocim de São Félix",
	"Halstenbek",
	"Castelfidardo",
	"Jussara",
	"Prenzlau",
	"Pinecrest",
	"Brie-Comte-Robert",
	"Santa Teresita",
	"Horseheads",
	"Pirkkala",
	"Goyty",
	"Cerqueira César",
	"Lower Southampton",
	"Vence",
	"Santa Ana Jilotzingo",
	"Magsaysay",
	"Klaeng",
	"Shemonaīkha",
	"Nikolayevsk-na-Amure",
	"Oberasbach",
	"Bruz",
	"Moissy-Cramayel",
	"Londerzeel",
	"Lunéville",
	"Tiffin",
	"Ashkezar",
	"Sudbury",
	"Amares",
	"Mount Airy",
	"Concord",
	"Beaconsfield",
	"Lumbatan",
	"Ixtlahuacán del Río",
	"Broadview Heights",
	"Buriti dos Lopes",
	"Târgu Neamţ",
	"Gostynin",
	"Sint-Genesius-Rode",
	"Palma Campania",
	"Baena",
	"Silvânia",
	"Ban Na Yang",
	"Luna",
	"Guernica y Luno",
	"Mumbwa",
	"Xinhua",
	"Dormentes",
	"Toulal",
	"Carmo",
	"Tlalnelhuayocan",
	"Shimomura",
	"Guaranésia",
	"Radeberg",
	"Valenzano",
	"Westbrook",
	"Matipó",
	"Cuquío",
	"Komárom",
	"Wervik",
	"Préveza",
	"Cabaceiras do Paraguaçu",
	"Saugerties",
	"Umburanas",
	"Rawa Mazowiecka",
	"Alzey",
	"Zuidhorn",
	"Akureyri",
	"Springwater",
	"Holden",
	"Nova Petrópolis",
	"Pruzhany",
	"Caowotan",
	"Proletarsk",
	"Bánovce nad Bebravou",
	"Haukipudas",
	"Red Bluff",
	"Villaquilambre",
	"Bronte",
	"Arcadia",
	"Westborough",
	"Dankov",
	"Göd",
	"Onega",
	"Ghedi",
	"Louviers",
	"Puerto Natales",
	"Porciúncula",
	"Warwick",
	"White Oak",
	"Time",
	"Caconde",
	"Shimanovsk",
	"La Nucía",
	"Fox Crossing",
	"Ervália",
	"McKeesport",
	"Beni Zouli",
	"Taft",
	"Mill Hill",
	"Salcajá",
	"Las Terrenas",
	"La Crau",
	"Eitorf",
	"Gyömrő",
	"Ribera",
	"Atlapexco",
	"Limerick",
	"Motomachi",
	"La Eliana",
	"Bideford",
	"Qitai",
	"Dorval",
	"Stroud",
	"Jiaoxiyakou",
	"Montceau-les-Mines",
	"Murray Bridge",
	"Antonina",
	"Lubang",
	"Kalynivka",
	"Appley Bridge",
	"Troy",
	"Naantali",
	"Onalaska",
	"Castel Maggiore",
	"Kapay",
	"Pindoretama",
	"Rutigliano",
	"Waterford",
	"Ipaba",
	"Uwchlan",
	"Lexington",
	"Barão do Grajaú",
	"Moosburg",
	"Avion",
	"Springboro",
	"Poulton le Fylde",
	"Terra Santa",
	"Whitehall",
	"Sartell",
	"Tadó",
	"Narangba",
	"Contamana",
	"Itatira",
	"Polohy",
	"Badger",
	"Paks",
	"Chapada dos Guimarães",
	"Cofradía",
	"Louny",
	"Five Corners",
	"Landa de Matamoros",
	"Mo i Rana",
	"Contenda",
	"McAlester",
	"Hardinxveld-Giessendam",
	"Nelidovo",
	"Stord",
	"Laukaa",
	"Hala",
	"Conceição",
	"Drunen",
	"Százhalombatta",
	"Sestri Levante",
	"Mount Isa",
	"La Follette",
	"Vélez",
	"Alfeld",
	"Aberystwyth",
	"Le Pontet",
	"Fushë-Krujë",
	"Muttenz",
	"Benicasim",
	"Piatykhatky",
	"Terrell",
	"Heanor",
	"Tocaima",
	"Meylan",
	"Kara-Köl",
	"Kalach",
	"San Lorenzo de El Escorial",
	"Natchitoches",
	"Oulmes",
	"Târgu Secuiesc",
	"Twinsburg",
	"Taufkirchen",
	"Central Point",
	"Bemmel",
	"Esperanza",
	"Crimmitschau",
	"Seminole",
	"Burscheid",
	"Kilbirnie",
	"Dzüünharaa",
	"Montville",
	"Coldwater",
	"Gossau",
	"Pôrto Acre",
	"Owego",
	"Starobilsk",
	"Bareggio",
	"Marsciano",
	"Batalha",
	"Peterhead",
	"West Manchester",
	"Sortavala",
	"Victoria",
	"Lessines",
	"Oirschot",
	"Siderno Marina",
	"Malacacheta",
	"Thorold",
	"Ban Mai",
	"Santiago de María",
	"Palmeiras",
	"Costa Marques",
	"St. John",
	"Novyy Oskol",
	"Osny",
	"Renningen",
	"Cabusao",
	"Engenheiro Coelho",
	"Alzenau in Unterfranken",
	"Piossasco",
	"Middelburg",
	"Neópolis",
	"Quitandinha",
	"Rawmarsh",
	"Grandview",
	"Huércal de Almería",
	"Kitcharao",
	"Marshfield",
	"Emba",
	"Stekene",
	"Vitré",
	"Ibititá",
	"Konz",
	"Farias Brito",
	"Cruz Machado",
	"Tepetlixpa",
	"Pirapemas",
	"Rufino",
	"Longjia",
	"Villa de Leyva",
	"Grafton",
	"Camrose",
	"Schneverdingen",
	"Orodara",
	"Rājmahal",
	"Villa Verde",
	"Marktoberdorf",
	"Domodossola",
	"Jbabra",
	"Cimarron Hills",
	"East Lyme",
	"Wassenberg",
	"Miyatoko",
	"Scituate",
	"Sparta",
	"Cariús",
	"Venturosa",
	"Cafarnaum",
	"Bad Reichenhall",
	"Alsip",
	"Toflea",
	"Avon",
	"Homewood",
	"Orăştie",
	"Laives",
	"Picuí",
	"Surovikino",
	"Or ‘Aqiva",
	"Lora del Río",
	"Sanpaicun",
	"Auerbach",
	"Ibipeba",
	"Port Isabel",
	"Bacuri",
	"Liuma",
	"Ban Thum",
	"Vovchansk",
	"Marinette",
	"Bergeijk",
	"Capua",
	"Vlotho",
	"Jaguaripe",
	"Boa Vista do Tupim",
	"Kavalerovo",
	"Bay City",
	"Filomeno Mata",
	"Bad Dürkheim",
	"Hochheim am Main",
	"Balş",
	"Ansonia",
	"Weißenburg",
	"Chouafaa",
	"Bremervörde",
	"West Whiteland",
	"Malnate",
	"Bagnols-sur-Cèze",
	"São João dos Poleiros",
	"Deerfield",
	"South Frontenac",
	"Augustinópolis",
	"Guanzhai",
	"Maple Shade",
	"Tamri",
	"Limay",
	"Westminster",
	"Kulu",
	"Melegnano",
	"Nytva",
	"Porto Real",
	"Olten",
	"Creve Coeur",
	"Cluses",
	"Vanino",
	"Águas de Lindóia",
	"Carnaíba",
	"Brand",
	"Tata",
	"Jiabong",
	"Hoppegarten",
	"Tatsuno",
	"Skadovsk",
	"Shaogang",
	"Orestiáda",
	"Berea",
	"Olho d’Água das Cunhãs",
	"Firminy",
	"Cocoa",
	"Moultrie",
	"Timoktene",
	"Berkhampstead",
	"Unquillo",
	"Budrio",
	"Houghton Regis",
	"Bönen",
	"Forest Park",
	"Marihatag",
	"Sinacaban",
	"Varjota",
	"Pitt Meadows",
	"Zagarolo",
	"Kristiansund",
	"Priozërsk",
	"Santa Cruz Zenzontepec",
	"Anacortes",
	"Moloacán",
	"Lahnstein",
	"Sidi Allal Tazi",
	"Matagob",
	"Asunción Nochixtlán",
	"Shaoyu",
	"Heusweiler",
	"Borgo San Lorenzo",
	"Międzyrzecz",
	"Aberdeen",
	"Tha Bo",
	"Sam Phran",
	"Cordenons",
	"Fátima",
	"Bramhall",
	"San Blas Atempa",
	"Campo de la Cruz",
	"Rancho Mirage",
	"Sharon",
	"Kherāmeh",
	"Littau",
	"Garuva",
	"Siqueira Campos",
	"Plácido de Castro",
	"Kostrzyn nad Odrą",
	"Dniprorudne",
	"Gibsonton",
	"Del Carmen",
	"Taió",
	"Viradouro",
	"Lynden",
	"Somma Lombardo",
	"Savignano sul Rubicone",
	"Elst",
	"Swellendam",
	"Beloozërskiy",
	"Foum el Anser",
	"Guadix",
	"Gautier",
	"Otrokovice",
	"Nara",
	"Mayfield Heights",
	"Piatã",
	"Breaux Bridge",
	"Tonantins",
	"Arroio Grande",
	"Itapaci",
	"Almondbury",
	"Taft",
	"Nerviano",
	"Torredembarra",
	"Hopkins",
	"Békés",
	"Albino",
	"Villorba",
	"Kobyłka",
	"Wilton",
	"Lamrasla",
	"Tingloy",
	"Defiance",
	"Gheorgheni",
	"Monor",
	"Itapororoca",
	"Madalag",
	"Yur’yev-Pol’skiy",
	"Korsun-Shevchenkivskyi",
	"Valguarnera Caropepe",
	"Stonington",
	"San José Tenango",
	"Amvrosiivka",
	"Svatove",
	"Campos Belos",
	"Panagyurishte",
	"Iron Mountain",
	"Durleşti",
	"Schwechat",
	"Washington",
	"Formoso do Araguaia",
	"Golcar",
	"Berea",
	"Bodegraven",
	"Altus",
	"San Celoni",
	"Belo Campo",
	"Harinākunda",
	"Oroszlány",
	"Kadaň",
	"Santa Maria di Sala",
	"Uvalde",
	"Nunuñgan",
	"Banting",
	"Serra Dourada",
	"Garwolin",
	"Brushy Creek",
	"Concepción de Ataco",
	"Aït Bouchta",
	"Acatlán",
	"Cassano al Ionio",
	"Brook Park",
	"Yoichi",
	"Pembroke",
	"Novopavlovka",
	"Braniewo",
	"Piúma",
	"Middle",
	"Memuro-minami",
	"Wallingford Center",
	"Großenhain",
	"Santander",
	"Cariré",
	"Qishe",
	"Sunnyside",
	"Pinabacdao",
	"Lagoa da Canoa",
	"Middelharnis",
	"Sørum",
	"Serrita",
	"Garching bei München",
	"Bet She’an",
	"Fulwood",
	"Dobrush",
	"Schwalmstadt",
	"Jucurutu",
	"Boissy-Saint-Léger",
	"Shaying",
	"Towamencin",
	"Klaukkala",
	"Forst (Lausitz)",
	"World Golf Village",
	"Sycamore",
	"Franklin Farm",
	"Charata",
	"Vertentes",
	"Oyama",
	"Avon",
	"San Gabriel",
	"Sikhio",
	"Chowchilla",
	"Felanitx",
	"Ronkonkoma",
	"Polignano a Mare",
	"Lagoa do Carro",
	"Port Colborne",
	"Pocinhos",
	"Maltby",
	"Lobos",
	"Slyudyanka",
	"Torrox",
	"Buckhaven",
	"Newburyport",
	"Kosvik",
	"Colina",
	"Schkeuditz",
	"Caldas de Montbuy",
	"Huitán",
	"Condeúba",
	"Hampton",
	"Hessisch Oldendorf",
	"Malimono",
	"Xochiatipan de Castillo",
	"Montornés del Vallés",
	"Bouarouss",
	"Banabuiú",
	"Luz",
	"Drăgăşani",
	"Isfana",
	"Werdohl",
	"Kilgore",
	"Kishi",
	"Wörth am Rhein",
	"Gangwuzhen",
	"Băicoi",
	"Sapucaia",
	"Nigrán",
	"Bekalta",
	"Radford",
	"Ikniwn",
	"São Vicente Férrer",
	"Quispamsis",
	"Texistepeque",
	"Palestine",
	"Whitestown",
	"Crawfordsville",
	"Springfield",
	"Púchov",
	"San Kamphaeng",
	"Ploemeur",
	"Arinos",
	"Grangemouth",
	"Boerne",
	"Rikuzen-Takata",
	"Pieksämäki",
	"Lake Placid",
	"Carvin",
	"Tarifa",
	"Łask",
	"Dombóvár",
	"Diadi",
	"Alpignano",
	"Wemmel",
	"Pastrana",
	"Mŭynoq",
	"Rhaude",
	"Rio Maria",
	"St. Michael",
	"East Goshen",
	"Īlām",
	"Mont-Saint-Hilaire",
	"Maumelle",
	"Tremedal",
	"Windham",
	"Santo Antônio do Leverger",
	"Lavāsān",
	"Bad Segeberg",
	"Parchim",
	"Benavente",
	"Itacarambi",
	"Monthey",
	"Hranice",
	"Niles",
	"Pratteln",
	"Avtury",
	"Sint-Oedenrode",
	"Beruri",
	"Central",
	"Vestby",
	"Somerset",
	"Morro da Fumaça",
	"Menomonie",
	"Croatá",
	"Trenton",
	"Bathurst",
	"Cuautitlán",
	"São Caetano de Odivelas",
	"Southeast",
	"Tapauá",
	"San Jose",
	"Paraibuna",
	"Calenzano",
	"Bexbach",
	"Saint-Augustin-de-Desmaures",
	"Wheatfield",
	"Solonópole",
	"Rizal",
	"Feyẕābād",
	"Matelândia",
	"Atalaia do Norte",
	"Lagoa Formosa",
	"Rutland",
	"Bloemhof",
	"Marcon",
	"Maranello",
	"Coronda",
	"Ghouazi",
	"Sona",
	"Imperial",
	"Orange",
	"Matigou",
	"Mengjiacun",
	"Otsego",
	"Madison",
	"Madeley",
	"Pescantina",
	"Madison",
	"St. Matthews",
	"Alton",
	"Cudahy",
	"Dīzīcheh",
	"Round Lake",
	"General Juan Madariaga",
	"Quanzhang",
	"Oak Bay",
	"Heidenau",
	"Piaçabuçu",
	"Freilassing",
	"Oxon Hill",
	"Pastos Bons",
	"Manchester",
	"Malacky",
	"Rypin",
	"Beeville",
	"Sebaste",
	"Cary",
	"Tublay",
	"North Wilkesboro",
	"Freudenberg",
	"Yupiltepeque",
	"Goffstown",
	"Duero",
	"North Aurora",
	"Hijuelas",
	"Lithia Springs",
	"Tall ‘Aran",
	"Northallerton",
	"Sorrento",
	"Bon Air",
	"Mirangaba",
	"Almolonga",
	"Bensenville",
	"Balilihan",
	"Sinzig",
	"Eastlake",
	"Beaver Dam",
	"Muḩradah",
	"Kelsterbach",
	"Nauen",
	"Qal‘ah-ye Zāl",
	"Itaberá",
	"Rio Pomba",
	"Marcos",
	"Safety Harbor",
	"Mattoon",
	"Port-de-Bouc",
	"Neftegorsk",
	"Canapi",
	"Colne",
	"Ginsheim-Gustavsburg",
	"Requena",
	"General Deheza",
	"Waalre",
	"Macomb",
	"Munguía",
	"North Druid Hills",
	"Baronissi",
	"Danao",
	"West Columbia",
	"Arco",
	"Oborniki",
	"Mirante do Paranapanema",
	"Regeneração",
	"Fayetteville",
	"Messias",
	"Mosbrough",
	"Sunland Park",
	"Sabana de Torres",
	"Ribat Al Khayr",
	"Châteauneuf-les-Martigues",
	"Puurs",
	"Kharabali",
	"Isola Capo Rizzuto",
	"Międzyrzec Podlaski",
	"Estelle",
	"Antenor Navarro",
	"Pô",
	"Melfi",
	"Ibirataia",
	"Manzanares",
	"San Carlos Park",
	"Pelaya",
	"Daimiel",
	"San Teodoro",
	"Policoro",
	"Illertissen",
	"Al Qbab",
	"Rockland",
	"Gaoniang",
	"Kreuzau",
	"Frankenberg",
	"Zachary",
	"Kingsland",
	"Zapotlán del Rey",
	"Marco Island",
	"L’Isle-d’Abeau",
	"Huntington",
	"Unión de San Antonio",
	"Juazeirinho",
	"Ingleside",
	"Humacao",
	"Whitemarsh",
	"Tricase",
	"Weilerswist",
	"Buchen in Odenwald",
	"Marmande",
	"Guapiara",
	"Monselice",
	"Conceição do Mato Dentro",
	"Ustka",
	"Wantagh",
	"Kirsanov",
	"Sibutao",
	"Golitsyno",
	"Lenoir",
	"Yaguachi Nuevo",
	"Złotoryja",
	"Anadia",
	"Concord",
	"Easton",
	"Ommen",
	"Nguigmi",
	"Galich",
	"New Castle",
	"Serafina Corêa",
	"Yuanquan",
	"Upton",
	"Porto San Giorgio",
	"San Bartolo Tutotepec",
	"Perry",
	"Saint Ives",
	"Siruma",
	"Hannibal",
	"Holzwickede",
	"Gikongoro",
	"Douar Oulad Mbarek",
	"Jacupiranga",
	"Jaguaretama",
	"Menasha",
	"Pontalina",
	"Oak Grove",
	"Rizal",
	"Schwarzenbek",
	"Scarsdale",
	"Alton",
	"Braunstone",
	"Brenham",
	"Ocean Springs",
	"Dallas",
	"Woodcrest",
	"Libjo",
	"Wadgassen",
	"Carlentini",
	"White Settlement",
	"High Blantyre",
	"Cesson-Sévigné",
	"Kukmor",
	"Nazaré Paulista",
	"Schwalbach",
	"Rio Claro",
	"Salgar",
	"Cêrro Azul",
	"Kongsvinger",
	"Mitake",
	"Five Forks",
	"Belterra",
	"Knightdale",
	"Orta Nova",
	"Forio",
	"Shenandoah",
	"Ada",
	"Salonta",
	"Broken Hill",
	"Miaojiaping",
	"Madamba",
	"Sobinka",
	"Orsay",
	"Longjia",
	"Naarden",
	"Zhongguyue",
	"Crossville",
	"Yutz",
	"Las Heras",
	"Yanggezhuang",
	"Sleaford",
	"Bad Säckingen",
	"São Benedito do Rio Preto",
	"Oerlinghausen",
	"Sapeaçu",
	"Pontarlier",
	"Beltsville",
	"Hlybokaye",
	"Sangenjo",
	"Pirapora do Bom Jesus",
	"San Felipe",
	"Não-Me-Toque",
	"Laç",
	"Latsia",
	"Shangjing",
	"Bedford",
	"Santa Elena",
	"Colonia",
	"Outat Oulad Al Haj",
	"Ruhango",
	"Grenchen",
	"Guisborough",
	"Portchester",
	"Presidente Getúlio",
	"Moraga",
	"Beni Fouda",
	"Balaguer",
	"Minquan",
	"Zaslawye",
	"Cairu",
	"San Agustín Chahal",
	"Ban Song",
	"Cássia",
	"Pouso Redondo",
	"Midway",
	"Zongo",
	"Banora Point",
	"Steubenville",
	"Dingjiagouxiang",
	"Glen Parva",
	"Fiorano Modenese",
	"Newmarket",
	"Tavares",
	"Duijiang",
	"Ngara",
	"Concord",
	"El Roble",
	"Belgrade",
	"Sierre",
	"Cipó",
	"Popovo",
	"Ban Pong",
	"Lousã",
	"Thornton",
	"Foxborough",
	"Bilar",
	"Tanque Novo",
	"Laatatra",
	"Lackawanna",
	"Tizi Rached",
	"Melville",
	"Verdun",
	"Boqueirão",
	"Ashland",
	"Wailuku",
	"Buckhall",
	"El Dorado",
	"Salmon Arm",
	"Alcochete",
	"South Hadley",
	"Ashton",
	"Tlahuelilpan",
	"Fléron",
	"Bailén",
	"Preganziol",
	"Port Alberni",
	"Pisticci",
	"Verneuil-sur-Seine",
	"Santos Reyes Nopala",
	"Novo Airão",
	"Ribeirão Branco",
	"Ban Phe",
	"Greene",
	"Pianoro",
	"Sipacapa",
	"Nerang",
	"Valle Vista",
	"Bitam",
	"Qal’at Mgouna",
	"Aït Majdane",
	"San Alejo",
	"Albert Lea",
	"Polyarnyy",
	"Madison",
	"São Paulo do Potengi",
	"Maitland",
	"Osuna",
	"Guidan Roumdji",
	"Água Branca",
	"Gretna",
	"Inza",
	"Hatfield",
	"Arauco",
	"Totutla",
	"Bendorf",
	"Tárrega",
	"Milanówek",
	"Sìnnai",
	"Chedaopo",
	"Hinsdale",
	"Rocca di Papa",
	"Aïn Zaouïa",
	"Tougan",
	"Seara",
	"Santa Fe Springs",
	"Bílina",
	"Magalhães de Almeida",
	"Bad Münder am Deister",
	"Franklin Park",
	"Bethpage",
	"La Primavera",
	"Awlouz",
	"East Massapequa",
	"Belém",
	"Plainville",
	"Amarante",
	"Same",
	"Scordia",
	"Filadélfia",
	"Brignoles",
	"Catriel",
	"Wendlingen am Neckar",
	"Blooming Grove",
	"Tabernes de Valldigna",
	"Zaysan",
	"Kirksville",
	"Nipomo",
	"Nova Era",
	"Hopkinton",
	"Afrânio",
	"As Suqaylibīyah",
	"San Giovanni Valdarno",
	"Centerville",
	"Bad Langensalza",
	"Plymouth",
	"Gorham",
	"Rubano",
	"Raunheim",
	"Neerpelt",
	"Butner",
	"Cortes",
	"Boulsa",
	"Feilding",
	"Sahel",
	"Poyo",
	"Salem",
	"Thale",
	"Mayorga",
	"Marktredwitz",
	"Sanary-sur-Mer",
	"Chirpan",
	"Tillmans Corner",
	"Sedeh Lanjān",
	"Tabarka",
	"Fanipal’",
	"Peçanha",
	"La Garriga",
	"Alotenango",
	"Upper Grand Lagoon",
	"Haslemere",
	"Anoka",
	"Ismaning",
	"Remagen",
	"Höganäs",
	"Terra Roxa d’Oeste",
	"Castelfiorentino",
	"Bobingen",
	"Băileşti",
	"Miramichi",
	"Laurel",
	"Adelfia",
	"Amesbury",
	"Plast",
	"Dar Chaifat",
	"Agourai",
	"Trossingen",
	"Windlesham",
	"Marion",
	"Karukh",
	"Tallmadge",
	"Cafelândia",
	"Nanuet",
	"Negrar",
	"Balma",
	"Maryland City",
	"Ketama",
	"Son en Breugel",
	"Niagara-on-the-Lake",
	"Shorewood",
	"Krasnokumskoye",
	"Vero Beach",
	"Ziracuaretiro",
	"Berga",
	"Clemson",
	"Pinili",
	"Sulzbach",
	"Forssa",
	"Karachev",
	"Pomáz",
	"Spanish Lake",
	"Kadogawa",
	"Suvorov",
	"Lewes",
	"Sant’Elpidio a Mare",
	"Lara",
	"Concorezzo",
	"Pavullo nel Frignano",
	"São Raimundo das Mangabeiras",
	"Lake Mary",
	"Vechelde",
	"Jovellar",
	"Horsham",
	"Guaranda",
	"Calafat",
	"Volgorechensk",
	"Lakeport",
	"Sigmaringen",
	"Malhada",
	"Saint-Lin--Laurentides",
	"Mori",
	"Knik-Fairview",
	"Galaz",
	"Maruim",
	"Martinsburg",
	"El Rosal",
	"Bolhrad",
	"Montemor-o-Novo",
	"Tinton Falls",
	"Pedra Preta",
	"Freienbach",
	"Flora",
	"Conchas",
	"Puerto Aysén",
	"Stowbtsy",
	"Capela",
	"Nidda",
	"Doylestown",
	"Jenison",
	"Veinticinco de Mayo",
	"Cadoneghe",
	"Tapiramutá",
	"Dijiasuoxiang",
	"La Algaba",
	"Los Alcázares",
	"Gescher",
	"Raychikhinsk",
	"Hidrolândia",
	"Suesca",
	"Montalvânia",
	"Redland",
	"Bad Münstereifel",
	"Eldorado",
	"Gennep",
	"Springfield",
	"Mayoyao",
	"Nizhnyaya Salda",
	"Burlington",
	"Godfrey",
	"Fairview",
	"Pendleton",
	"Kafr Zaytā",
	"Beaumont",
	"Zandvoort",
	"Betulia",
	"Taylor",
	"Rattaphum",
	"Eutin",
	"Santo Antônio do Amparo",
	"São Domingos do Prata",
	"Pecan Grove",
	"Heilbad Heiligenstadt",
	"Biggleswade",
	"Milla’ab",
	"Ripon",
	"Dazhangzi",
	"Nederland",
	"Colonial Heights",
	"Horn-Bad Meinberg",
	"Stockerau",
	"Ayacucho",
	"Wohlen",
	"Morriston",
	"Porto Empedocle",
	"Portsmouth",
	"Stafford",
	"Stockach",
	"Krasnoslobodsk",
	"Bermeo",
	"Willimantic",
	"G‘uzor",
	"Guryevsk",
	"Mount Barker",
	"Wittenberge",
	"Hatfield",
	"Saviano",
	"Souto Soares",
	"Ban Thung Tam Sao",
	"Shumikha",
	"Gussago",
	"Harborcreek",
	"Silver Spring",
	"Finnentrop",
	"Barbastro",
	"Schrobenhausen",
	"Ocean Acres",
	"Tapes",
	"San Casciano in Val di Pesa",
	"Burgdorf",
	"Łęczyca",
	"Tumbao",
	"Pisaflores",
	"Faxinal",
	"Urandi",
	"Guben",
	"Handlová",
	"La Marque",
	"Svalyava",
	"Peso da Régua",
	"Königstein im Taunus",
	"Lattes",
	"Sawankhalok",
	"Cosautlán",
	"Carmópolis",
	"Braunau am Inn",
	"Altena",
	"Colchester",
	"Heckmondwike",
	"Baliangao",
	"Heysham",
	"Pôrto Murtinho",
	"Aubange",
	"Versailles",
	"Morris",
	"Campagna",
	"Kamenz",
	"Zarumilla",
	"Damme",
	"Lemont",
	"Vera",
	"Gainesville",
	"Cervantes",
	"Bucay",
	"Kangaba",
	"Sayre",
	"Carovigno",
	"Giffnock",
	"Bad Wildungen",
	"Palanan",
	"Podporozhye",
	"East Finchley",
	"Chertsey",
	"Rojales",
	"Navalmoral de la Mata",
	"Domont",
	"Monsenhor Tabosa",
	"Portland",
	"Bom Sucesso",
	"Clevelândia",
	"Baymak",
	"Lindenwold",
	"Eggenstein-Leopoldshafen",
	"Middlesex Centre",
	"Pozoblanco",
	"Oswestry",
	"San Jorge",
	"Alcoy",
	"Saint-Égrève",
	"Calnali",
	"Cesário Lange",
	"Xintangcun",
	"Almeria",
	"Clive",
	"Cumaru",
	"Mohács",
	"Ribeirópolis",
	"Oneonta",
	"Santa Magdalena",
	"Inverness",
	"Shippensburg",
	"Ambarès-et-Lagrave",
	"Killingly",
	"Sudipen",
	"Penzberg",
	"Pampa",
	"Stockelsdorf",
	"Nümbrecht",
	"Kempele",
	"Dour",
	"Cajolá",
	"Jičín",
	"Marienberg",
	"McKinleyville",
	"Ferndale",
	"Kozienice",
	"Condeixa-a-Nova",
	"Fuente-Álamo de Murcia",
	"South Ogden",
	"Elbeuf",
	"Araçagi",
	"Santana do Cariri",
	"Balmazújváros",
	"Itirapina",
	"Hayange",
	"Stony Plain",
	"Poing",
	"Sedan",
	"Petawawa",
	"Albemarle",
	"Canudos",
	"Urucuia",
	"Brzesko",
	"Adustina",
	"Saluzzo",
	"Coalinga",
	"General Luna",
	"North Canton",
	"Saran",
	"Saint-Cyr-sur-Loire",
	"Konstantinovsk",
	"Itaquitinga",
	"La Vista",
	"Elias Fausto",
	"Saint-Pierre-des-Corps",
	"Urucará",
	"Benešov",
	"Ashwaubenon",
	"Freiberg am Neckar",
	"Gubin",
	"Dickson",
	"Kusa",
	"Codru",
	"North Adams",
	"Torre Maggiore",
	"Capistrano",
	"Corbélia",
	"Sidi Yakoub",
	"Ignacio de la Llave",
	"Waxhaw",
	"San Juan del Sur",
	"Kežmarok",
	"Lam Luk Ka",
	"Ajacuba",
	"Saint Andrews",
	"Castellaneta",
	"Manlin",
	"Xinlong",
	"Zvenyhorodka",
	"Sidi Daoud",
	"North Babylon",
	"Piñas",
	"Long’e",
	"Kiangan",
	"Svitavy",
	"Künzell",
	"Palestina de los Altos",
	"Xixinzhuangzhen",
	"Bargteheide",
	"Kronach",
	"Dabas",
	"Staphorst",
	"Bethlehem",
	"Biliran",
	"Eppelborn",
	"Rotselaar",
	"Wādī Ḩalfā’",
	"Landerneau",
	"Bastrop",
	"San Giovanni in Fiore",
	"Abelardo Luz",
	"Cham",
	"Ammon",
	"Tagounite",
	"Rizal",
	"Catarman",
	"Pelham",
	"Tijucas do Sul",
	"Bellingham",
	"Knemis Dades",
	"Cestas",
	"Tamallalt",
	"Resplendor",
	"Nova Canaã",
	"Yelm",
	"Yabuki",
	"Toijala",
	"Xinying",
	"Giruá",
	"Carmópolis de Minas",
	"Tôlan̈aro",
	"Macatuba",
	"Bombon",
	"Babenhausen",
	"Schwarzenberg",
	"Fundão",
	"Martinsicuro",
	"Marabut",
	"Gavrilov-Yam",
	"Zafra",
	"Parole",
	"Ban Cho Ho",
	"Mauguio",
	"Menzelinsk",
	"Oulad Amrane",
	"Filiaşi",
	"Água Fria",
	"Itapiranga",
	"Preetz",
	"Glenvar Heights",
	"Grottammare",
	"Pokrov",
	"Mangueirinha",
	"Turinsk",
	"Palos Hills",
	"Selwyn",
	"Talsint",
	"Annonay",
	"Povorino",
	"Rosário Oeste",
	"Seltso",
	"Vedelago",
	"Khed",
	"Mula",
	"Sint-Kruis",
	"Crixás",
	"Brixham",
	"Diepholz",
	"Camano",
	"Dongjiangshui",
	"Loay",
	"Retiro",
	"Liuchuan",
	"Cernavodă",
	"Santa María",
	"Valencia",
	"Dacaozhuang",
	"Streator",
	"Bytów",
	"Samarate",
	"Wyckoff",
	"Brookfield",
	"Lentate sul Seveso",
	"Ihosy",
	"Liuguoju",
	"San Juan Lalana",
	"Peñamiller",
	"Ambriz",
	"Aiuaba",
	"Steffisburg",
	"Realeza",
	"Seeheim-Jugenheim",
	"East Lampeter",
	"Osowa",
	"Guadalupe Victoria",
	"Buffalo",
	"Lincoln",
	"Ashmyany",
	"Bagnacavallo",
	"Al Kilābīyah",
	"Spárti",
	"Sylacauga",
	"Oconomowoc",
	"Giaveno",
	"Azeffoun",
	"Jilotepec",
	"Grójec",
	"Saint-Julien-en-Genevois",
	"Tourlaville",
	"Webster",
	"Fort Hunt",
	"Mátészalka",
	"Loyalist",
	"Phoenixville",
	"Batuco",
	"Krapkowice",
	"Riemst",
	"San Jacinto Amilpas",
	"Vatutine",
	"Pran Buri",
	"Terra Rica",
	"Brunico",
	"América Dourada",
	"Mountain Home",
	"Neratovice",
	"San José de Chiquitos",
	"Didam",
	"Asten",
	"Palagonia",
	"Fındıklı",
	"Americus",
	"Cubellas",
	"Republic",
	"Iziaslav",
	"Reinheim",
	"Santa Cecília",
	"Kelheim",
	"Upper Chichester",
	"Severínia",
	"Agrate Brianza",
	"Río Bueno",
	"Holzkirchen",
	"Dehāqān",
	"Lemay",
	"Ratba",
	"Zhukovka",
	"Manilva",
	"Alvorada D’Oeste",
	"Buda",
	"Tomas Oppus",
	"Rakovski",
	"Katsuura",
	"Shahbā",
	"Famy",
	"São Pedro do Sul",
	"Guareí",
	"Alfajayucan",
	"Cutlerville",
	"Marbach am Neckar",
	"Chalchihuitán",
	"Port Lincoln",
	"Hajdúnánás",
	"Alto Santo",
	"Calverton",
	"Gainesville",
	"Karratha",
	"Heumen",
	"Janakkala",
	"Poço Fundo",
	"’Aïn Abessa",
	"Leinì",
	"Stará Ľubovňa",
	"Southbridge",
	"Wilton",
	"Bonito",
	"Hohenems",
	"Tichi",
	"Cagdianao",
	"Nova Resende",
	"Myrtle Grove",
	"Bürstadt",
	"Medicina",
	"Sin-le-Noble",
	"Montagu",
	"Nova Ipixuna",
	"Lake St. Louis",
	"Midland",
	"Bruckmühl",
	"Este",
	"Seagoville",
	"Colwood",
	"Bella Vista",
	"Guadarrama",
	"Chinampa de Gorostiza",
	"Westervoort",
	"Tejuçuoca",
	"Crescent City",
	"Ouaouzgane",
	"Colle Salvetti",
	"Escoublac",
	"Locarno",
	"Vallegrande",
	"Lille",
	"Saalfelden am Steinernen Meer",
	"Ubrique",
	"Strzegom",
	"Câmpulung Moldovenesc",
	"Sidi Lamine",
	"Saint-Maximin-la-Sainte-Baume",
	"Picayune",
	"Taguatinga",
	"Iglino",
	"Pindaí",
	"Fuying",
	"Itariri",
	"Rosedale",
	"North Myrtle Beach",
	"Ostrov",
	"Gunzenhausen",
	"Wayne",
	"Central Saanich",
	"Campanha",
	"Tocantins",
	"Vendôme",
	"Bemidji",
	"Sidi el Mokhfi",
	"Sumé",
	"Calayan",
	"Chestnuthill",
	"Sudak",
	"Kushima",
	"Lonigo",
	"Goodlettsville",
	"Pajapita",
	"Kauhava",
	"Mikumi",
	"Říčany",
	"Carnaubal",
	"Großostheim",
	"Ham Lake",
	"El Piñón",
	"Cabeceiras de Basto",
	"San Rafael",
	"Aue",
	"Liangyi",
	"Sada",
	"Fairburn",
	"Pollensa",
	"Kaiwen",
	"Ceadîr-Lunga",
	"Bristol",
	"Sainte-Catherine",
	"Neston",
	"Lake Wales",
	"Sallanches",
	"Potirendaba",
	"Brasilândia",
	"Agudo",
	"Port Hope",
	"Cerea",
	"Gitagum",
	"Caravaggio",
	"Anamorós",
	"Machelen",
	"Ponte de Sôr",
	"Singleton",
	"Novomichurinsk",
	"Douar Tabouda",
	"New Port Richey",
	"Upper Saucon",
	"Saint-Basile-le-Grand",
	"Morombe",
	"Truckee",
	"Chorfa",
	"Anda",
	"Sidi Ettiji",
	"Uherský Brod",
	"Wimborne Minster",
	"Leopoldshöhe",
	"Sunagawa",
	"Zirara",
	"Capurso",
	"Zumarraga",
	"Cerro Maggiore",
	"Lindsay",
	"Illingen",
	"Hennebont",
	"Seacombe",
	"Louth",
	"Vammala",
	"Ansfelden",
	"Ibicuí",
	"Pochëp",
	"Ibitiara",
	"Salesópolis",
	"Aston",
	"Chełmża",
	"North Decatur",
	"Wentang",
	"Rožnov pod Radhoštěm",
	"Zequ",
	"Cohoes",
	"Beek",
	"Auburn",
	"Monesiglio",
	"Todi",
	"Zeulenroda",
	"Reggello",
	"Federación",
	"Añisoc",
	"McComb",
	"Highland Village",
	"Noale",
	"Jinji",
	"Codogno",
	"Miyazu",
	"Raposos",
	"Athens",
	"Dilijan",
	"Auburndale",
	"Hünfeld",
	"Jiblah",
	"Poté",
	"Tagana-an",
	"Carapebus",
	"Santomera",
	"Illzach",
	"Mifune",
	"Ad Dīs ash Sharqīyah",
	"Santa María Petapa",
	"Banbridge",
	"Denby Dale",
	"Tinoc",
	"Kovdor",
	"Lanquín",
	"Scott",
	"Vesoul",
	"Alcantara",
	"Zapotlán de Juárez",
	"Corabia",
	"Gryfice",
	"Wolcott",
	"Montbrison",
	"Al Laţāminah",
	"Umbertide",
	"El Segundo",
	"Holmdel",
	"Denville",
	"Xinyuan",
	"Mercedes",
	"Swift Current",
	"Pardés H̱anna Karkur",
	"Macetown",
	"Libertad",
	"Centre de Flacq",
	"Olecko",
	"Norcross",
	"Burrillville",
	"Buritama",
	"Kurtamysh",
	"Jutaí",
	"Villeneuve-Loubet",
	"Tredegar",
	"Northbridge",
	"Batavia",
	"Edmundston",
	"Chinde",
	"Morganton",
	"Harrisburg",
	"Weybridge",
	"Sainte-Luce-sur-Loire",
	"Swansea",
	"Keflavík",
	"Besana in Brianza",
	"Berezan",
	"Quarteira",
	"Delran",
	"Langenthal",
	"West Hempfield",
	"Uchkeken",
	"Bayat",
	"Ban Lam Narai",
	"Murraysville",
	"Pedregulho",
	"Donaldsonville",
	"Solvang",
	"Charqueada",
	"Los Osos",
	"Kisvárda",
	"Mapiri",
	"Horodok",
	"Garðabær",
	"Saint-Amand-les-Eaux",
	"Hövelhof",
	"Huissen",
	"Ivanava",
	"Russell",
	"Tururu",
	"Groveton",
	"Washington",
	"Espartinas",
	"Bady Bassitt",
	"Seymour",
	"Longchang",
	"Changji",
	"Kirchlengern",
	"Yaypan",
	"Zhongzai",
	"Regenstauf",
	"Live Oak",
	"Rakovník",
	"Wyke",
	"Geddes",
	"Karema",
	"Lock Haven",
	"Colón",
	"Loimaa",
	"Vienna",
	"Boljoon",
	"Country Club Hills",
	"Durham",
	"Streetsboro",
	"Kirchhain",
	"Jasper",
	"Eden",
	"Pearl River",
	"Harenkarspel",
	"Digne-les-Bains",
	"Yunshan",
	"Châteaurenard",
	"Kozelsk",
	"Morretes",
	"Silvi Paese",
	"Ḩammām al ‘Alīl",
	"Douglas",
	"Bovolone",
	"Slaný",
	"Valmontone",
	"Bilohirsk",
	"Crowley",
	"Itaeté",
	"Highland Springs",
	"Anorí",
	"Brackenheim",
	"Civita Castellana",
	"Harlingen",
	"Passa Quatro",
	"Taohongpozhen",
	"North Grenville",
	"Ispica",
	"Bruntál",
	"Verrières-le-Buisson",
	"Francheville",
	"Sertanópolis",
	"Hayes",
	"Eibergen",
	"Franklin",
	"Bordj Mokhtar",
	"Cinco Ranch",
	"Sava",
	"San Cristóbal Cucho",
	"Vlijmen",
	"Wolf Trap",
	"Abington",
	"Lymington",
	"Chickasha",
	"Finsterwalde",
	"Pindorama",
	"Las Cabezas de San Juan",
	"Verkhivtseve",
	"Curacautín",
	"Przeworsk",
	"Lumbayanague",
	"Al Ḩībah",
	"Uckfield",
	"Portachuelo",
	"Kings Park",
	"Groveland",
	"Tirhassaline",
	"Manturovo",
	"Bangor",
	"Piera",
	"Cinnaminson",
	"Bituruna",
	"La Falda",
	"Mount Pleasant",
	"Collipulli",
	"Herisau",
	"Aveiro",
	"Bad Lippspringe",
	"Shiraoi",
	"River Falls",
	"Hernando",
	"Copparo",
	"Acarape",
	"Baipingshan",
	"Carcarañá",
	"Wurzen",
	"São Pedro do Sul",
	"Ripon",
	"Weißwasser/Oberlausitz",
	"Penicuik",
	"Divriği",
	"Monroe",
	"Codroipo",
	"Montivilliers",
	"Hude",
	"Petersberg",
	"Penn",
	"Brakel",
	"Bang Phae",
	"Kierspe",
	"Assaí",
	"Saguday",
	"Rio Linda",
	"Bluffdale",
	"Dieburg",
	"Otacílio Costa",
	"Rygge",
	"Pécel",
	"Ōarai",
	"East Greenbush",
	"Storrs",
	"Santa Fe",
	"Tapejara",
	"Havelock North",
	"Orimattila",
	"Yorkton",
	"Schmelz",
	"Burley",
	"Aurora",
	"Donna",
	"Ustroń",
	"Michelstadt",
	"Palagiano",
	"Straelen",
	"Shahriston",
	"Aranyaprathet",
	"Douar Azla",
	"Loxstedt",
	"Tarquinia",
	"Schiffweiler",
	"Ostashkov",
	"Queensbury",
	"Ponsacco",
	"Red Wing",
	"Les Herbiers",
	"Adjud",
	"Halver",
	"Walldorf",
	"Fallon",
	"Lyss",
	"Chamgardān",
	"Novoanninskiy",
	"Guérande",
	"Itaú de Minas",
	"Fairview Heights",
	"Celina",
	"Timezgana",
	"Bikou",
	"Ospitaletto",
	"Guardamar del Segura",
	"’Ayn Bni Mathar",
	"Gun Barrel City",
	"Thérmi",
	"Chaona",
	"Kabo",
	"Rumilly",
	"Arzúa",
	"Château-Thierry",
	"North Whitehall",
	"Caçu",
	"Morton",
	"Port Jervis",
	"Red Hill",
	"Vila Bela da Santíssima Trindade",
	"Petrovsk-Zabaykal’skiy",
	"Loboc",
	"Kaukauna",
	"San Antonio Oeste",
	"Mezőkövesd",
	"Adwick le Street",
	"Addison",
	"Fort Thomas",
	"Erwitte",
	"Mebane",
	"Guanagazapa",
	"Capinópolis",
	"Zegzel",
	"Mount Holly",
	"Alegria",
	"Landen",
	"Conyers",
	"Nanzuo",
	"Clausthal-Zellerfeld",
	"Bruck an der Mur",
	"Xiaozhengzhuang",
	"Nonantola",
	"Igaporã",
	"Centerton",
	"Yaojia",
	"Sered’",
	"East Longmeadow",
	"Perchtoldsdorf",
	"Leixlip",
	"Bad Wörishofen",
	"Merate",
	"Stuart",
	"Sikeston",
	"Pianezza",
	"Sulphur Springs",
	"Araputanga",
	"Breaza",
	"San Pedro de Lloc",
	"Santa Fe",
	"Galliate",
	"Ouadhia",
	"Ait Yaazem",
	"Poxoréo",
	"Bouchabel",
	"San Cesareo",
	"San Gabriel",
	"Boulder City",
	"Buadiposo-Buntong",
	"Centralia",
	"Palmitos",
	"Puerto Pimentel",
	"Masmouda",
	"Pocking",
	"Altinópolis",
	"Dixon",
	"Chai Prakan",
	"Horqueta",
	"Getúlio Vargas",
	"Mujuí dos Campos",
	"Barra de Santo Antônio",
	"Bar",
	"Troutdale",
	"Zevenbergen",
	"Paulino Neves",
	"Iguatemi",
	"Wiefelstede",
	"Babayurt",
	"Huarmey",
	"Pelhřimov",
	"San Tomas",
	"Quixelô",
	"As Sukhnah",
	"Iraci",
	"Ellington",
	"Rylsk",
	"Conwy",
	"Mount Clemens",
	"Fairview Park",
	"Discovery Bay",
	"Evans",
	"Sukth",
	"Ukrainka",
	"Potenza Picena",
	"Bni Tajjit",
	"Wardenburg",
	"Corupá",
	"Ginatilan",
	"Bni Quolla",
	"Stallings",
	"Wendelstein",
	"Cambridge",
	"Joaquim Nabuco",
	"Sandhausen",
	"Mezőtúr",
	"Minster",
	"Baléyara",
	"Taucha",
	"Lübbenau/Spreewald",
	"Frankfort",
	"Westwood",
	"Chenôve",
	"Barrington",
	"Hongsi",
	"Ebersbach an der Fils",
	"Chinggil",
	"Arteche",
	"Csongrád",
	"Westchester",
	"Altopascio",
	"Kodinsk",
	"Tracadie",
	"Alsfeld",
	"Whittlesey",
	"Dalachi",
	"Center Point",
	"Canyon",
	"West Haven",
	"Coulommiers",
	"Washougal",
	"Degtyarsk",
	"Aquitania",
	"Sassenheim",
	"Mulundo",
	"Saint-Avertin",
	"Ilave",
	"Bridgeview",
	"Telfs",
	"Khat Azakane",
	"Nyahanga",
	"Troy",
	"Horodok",
	"Nagasu",
	"Certaldo",
	"Fiano Romano",
	"Skhour Rehamna",
	"Boudjima",
	"Frogn",
	"Lebanon",
	"Stäfa",
	"Candói",
	"L’Oulja",
	"Griffith",
	"Villa Rica",
	"Santa Cruz de la Palma",
	"Schlüchtern",
	"Paluan",
	"Atescatempa",
	"Azzano Decimo",
	"Sansepolcro",
	"Bassum",
	"Fairhaven",
	"Tizi-n-Tleta",
	"Cortes",
	"Weener",
	"Alma",
	"Dianga",
	"Bragadiru",
	"Fort Leonard Wood",
	"Chislehurst",
	"Kasli",
	"Guamaré",
	"Long Beach",
	"Villers-lès-Nancy",
	"Shchigry",
	"Lady Lake",
	"Todmorden",
	"Aberdeen",
	"Welby",
	"Middletown",
	"Indianola",
	"Washington",
	"Piancó",
	"Ban Dung",
	"Bracebridge",
	"Paglat",
	"Seven Pagodas",
	"Mennecy",
	"Kamyzyak",
	"Caetano",
	"Fartura",
	"Yaransk",
	"Barysh",
	"Phelan",
	"Issoire",
	"Bastogne",
	"Chivolo",
	"Santa María de Jesús",
	"Lakeway",
	"Alcañiz",
	"Ghomrassen",
	"Knaresborough",
	"Dyer",
	"Toul",
	"Archer Lodge",
	"Niceville",
	"Bocşa",
	"Blanquefort",
	"Middletown",
	"Trélazé",
	"Haddada",
	"Calbuco",
	"Füssen",
	"Brüggen",
	"Clearlake",
	"Dehaq",
	"Thundersley",
	"Marghita",
	"Fillmore",
	"Yorito",
	"Talitsa",
	"Rescaldina",
	"San Julián",
	"Hood River",
	"Plavsk",
	"Bellevue",
	"Pamiers",
	"Xixucun",
	"Spanish Springs",
	"Bikin",
	"Maardu",
	"Aramil",
	"Boskoop",
	"Aïn Mediouna",
	"Port Glasgow",
	"Volpiano",
	"San Martino Buon Albergo",
	"Luzhou",
	"Bois-Guillaume",
	"Westport",
	"Comarapa",
	"Tabatinga",
	"Dinan",
	"New Haven",
	"Oyten",
	"Mottola",
	"Eilenburg",
	"Sertã",
	"Chiconquiaco",
	"Monte Plata",
	"Cardonal",
	"New River",
	"Sayville",
	"Patos",
	"Malta",
	"Dvůr Králové nad Labem",
	"Warwick",
	"Alabat",
	"Beaucaire",
	"Readington",
	"Tamazouzt",
	"Millbrook",
	"Sidi Allal el Bahraoui",
	"Grão Mogol",
	"Carthage",
	"Greater Napanee",
	"Breisach am Rhein",
	"Skvyra",
	"Yuvileine",
	"Ras El Oued",
	"Ivdel",
	"West Lampeter",
	"Puquio",
	"Prospect Heights",
	"King City",
	"Opa-locka",
	"Dilasag",
	"Markranstädt",
	"Pataskala",
	"Hedongcun",
	"Katav-Ivanovsk",
	"Indiaroba",
	"Mozarlândia",
	"Casalpusterlengo",
	"Leso",
	"Galatone",
	"Rumst",
	"Baeza",
	"Tillsonburg",
	"Sunset",
	"Machang",
	"Wadern",
	"Saint-Avold",
	"Tenango de Doria",
	"Ince-in-Makerfield",
	"Restinga Sêca",
	"Tsukumiura",
	"Colchester",
	"Neustadt in Holstein",
	"Grandville",
	"Jussara",
	"Ixhuatlán del Sureste",
	"Hibbing",
	"Sarasota Springs",
	"Barrocas",
	"Laguna Woods",
	"Derzhavīnsk",
	"Hope Mills",
	"Kumano",
	"Ibateguara",
	"El Estor",
	"Chenlu",
	"Surbo",
	"Jataúba",
	"Sierra Vista Southeast",
	"Nymburk",
	"Templin",
	"Wilmington Island",
	"Upper Gwynedd",
	"Kalininsk",
	"Lewiston",
	"Easthampton",
	"Steinbach",
	"Bad Neustadt",
	"Papagaios",
	"Patton",
	"Biritinga",
	"Lumbreras",
	"Naivasha",
	"Großenkneten",
	"Humble",
	"Mamadysh",
	"Königslutter am Elm",
	"Echuca",
	"Taurianova",
	"Lagoa Real",
	"Ovruch",
	"Carterton",
	"Payson",
	"Bredasdorp",
	"Duxbury",
	"Port Washington",
	"Yasnyy",
	"Putyvl",
	"Nova Ponte",
	"Shahr-e Jadīd-e Hashtgerd",
	"Sopelana",
	"São João Evangelista",
	"Macedo de Cavaleiros",
	"Bad Essen",
	"Pereiro",
	"Castenaso",
	"Circleville",
	"Hautmont",
	"Longmeadow",
	"Ulladulla",
	"Plochingen",
	"Omegna",
	"Fullerton",
	"Itajibá",
	"Bengonbeyene",
	"Juma Shahri",
	"Niederkrüchten",
	"Totteridge",
	"Kalocsa",
	"Kempsey",
	"Kafr Nubl",
	"Sterling",
	"Água Clara",
	"Acajutiba",
	"Tiverton",
	"Sakhā",
	"White",
	"Guasca",
	"Abadiânia",
	"Fos-sur-Mer",
	"Cassina de’ Pecchi",
	"Molinella",
	"Saboeiro",
	"Punta Umbría",
	"Powder Springs",
	"Ershui",
	"Flowing Wells",
	"Jiaojiazhuang",
	"Wenwu",
	"Itamonte",
	"San Miguel",
	"Clark",
	"Voss",
	"San Giorgio Ionico",
	"Purcellville",
	"Nova Olinda",
	"Si Satchanalai",
	"La Paz",
	"Pornic",
	"Greenwood Village",
	"Hanover",
	"Milford",
	"Spittal an der Drau",
	"Finale Emilia",
	"Despujols",
	"Ulverstone",
	"Middle Smithfield",
	"Riposto",
	"Terrace",
	"Sabinópolis",
	"Warren",
	"Yasnogorsk",
	"Paola",
	"Tīrān",
	"Farmingville",
	"Andrews",
	"Sumidouro",
	"Castelnuovo Rangone",
	"Kysucké Nové Mesto",
	"Tiszaújváros",
	"Crépy-en-Valois",
	"Ostroh",
	"Newberry",
	"Erlensee",
	"Parma",
	"Zhirnovsk",
	"Varnsdorf",
	"Blytheville",
	"Ben Taieb",
	"Winkfield",
	"Einsiedeln",
	"Corbera de Llobregat",
	"Kuusamo",
	"Midar",
	"Shively",
	"Brühl",
	"Suffield",
	"Drensteinfurt",
	"Featherstone",
	"Lagoa dos Gatos",
	"Mitchell",
	"Opera",
	"Rakhiv",
	"Ipanguaçu",
	"Kasagi",
	"Aç-çahrij",
	"Valeggio sul Mincio",
	"Casalmaggiore",
	"Schönefeld",
	"Szarvas",
	"West Norriton",
	"Mortara",
	"Tōbetsu",
	"Helensburgh",
	"Atacames",
	"Gaillac",
	"Graham",
	"Rio do Antônio",
	"Bad Bentheim",
	"Lindås",
	"Dolo",
	"Česká Třebová",
	"Tanque Verde",
	"Bolotnoye",
	"Serpa",
	"Baia-Sprie",
	"Alvinópolis",
	"Miguelturra",
	"Saint-Omer",
	"Elkton",
	"Kinna",
	"Santa Úrsula",
	"Shizukuishi",
	"Three Lakes",
	"South Fayette",
	"Mangqu",
	"Jędrzejów",
	"Loncoche",
	"Espumoso",
	"Bohodukhiv",
	"Corridonia",
	"Ágios Athanásios",
	"Caudry",
	"At-Bashy",
	"Kuala Lipis",
	"Fate",
	"Ipuã",
	"Poço Branco",
	"Ban Na Kham",
	"Borborema",
	"Najasa",
	"Bunnik",
	"Soliera",
	"Mandal",
	"Künzelsau",
	"Hille",
	"Riverdale",
	"Fort Mohave",
	"Ban Duea",
	"Alcantara",
	"Castellarano",
	"Bonito",
	"Chubbuck",
	"Siemiatycze",
	"Dionísio Cerqueira",
	"Campos Altos",
	"Dastgerd",
	"Urziceni",
	"Weston",
	"North Reading",
	"Newton",
	"Banqiao",
	"La Grange",
	"Antrim",
	"Svetogorsk",
	"Hemsworth",
	"Burbage",
	"Privolzhsk",
	"Miami",
	"Chiaravalle",
	"McMinnville",
	"La Roda",
	"Potsdam",
	"Khamir",
	"Parvomay",
	"Frei Miguelinho",
	"Longwood",
	"Ipecaetá",
	"Baiceng",
	"Sayō",
	"Wawizaght",
	"Schriesheim",
	"Luduş",
	"Castiglione del Lago",
	"Stahnsdorf",
	"Rahden",
	"Kawasaki",
	"Overland",
	"Dunn",
	"Sovetsk",
	"Alvarães",
	"Khao Yoi",
	"Houghton",
	"Uusikaupunki",
	"Cambuci",
	"Ladson",
	"Daigo",
	"Nova Timboteua",
	"Jurema",
	"La Solana",
	"Zelenogradsk",
	"Abaza",
	"Vieiro",
	"Fiorenzuola d’Arda",
	"Itanhandu",
	"Boppard",
	"Limanowa",
	"Blackfoot",
	"Ifrane",
	"Buford",
	"Tasquillo",
	"Lake Geneva",
	"Harrison",
	"Ascheberg",
	"Simmerath",
	"La Grande",
	"Genappe",
	"Balassagyarmat",
	"Senlis",
	"Barwon Heads",
	"Cruzília",
	"Neustadt bei Coburg",
	"Panorama",
	"Passo de Camarajibe",
	"Chiva",
	"Narragansett",
	"Broxbourne",
	"Dom Feliciano",
	"East Highland Park",
	"Linguère",
	"Fontainebleau",
	"Piquet Carneiro",
	"Hilvarenbeek",
	"Ober-Ramstadt",
	"Castanet-Tolosan",
	"Australind",
	"Outreau",
	"Mirandiba",
	"Salinas da Margarida",
	"Pontivy",
	"Mainburg",
	"Glen Allen",
	"Riegelsberg",
	"Ratekau",
	"Seven Oaks",
	"Taphan Hin",
	"Santa",
	"Jelcz-Laskowice",
	"Groves",
	"Pargas",
	"Flers",
	"Mastic",
	"Galanta",
	"Hofgeismar",
	"Hermitage",
	"Madagh",
	"Salitre",
	"Forks",
	"Churumuco de Morelos",
	"Petersfield",
	"Sulat",
	"Hampton",
	"Sale",
	"Torelló",
	"Cloverly",
	"Bouc-Bel-Air",
	"San Rafael Pie de la Cuesta",
	"Ezzhiliga",
	"Talladega",
	"Anazzou",
	"Erjie",
	"Langenau",
	"Coremas",
	"Hartford",
	"Felixlândia",
	"Holly Springs",
	"Quezon",
	"Belvedere Park",
	"Seekonk",
	"Głuchołazy",
	"Kérouané",
	"Hayden",
	"Moul El Bergui",
	"Middleburg Heights",
	"Ait Yazza",
	"Rellingen",
	"Mount Vernon",
	"Konārka",
	"Klimavichy",
	"Hilltown",
	"Huohua",
	"Grenzach-Wyhlen",
	"Kanegasaki",
	"Rubiera",
	"Jacaraci",
	"Edingen-Neckarhausen",
	"Landázuri",
	"Amatitán",
	"Daisen",
	"Villa del Rosario",
	"Conguaco",
	"Cumru",
	"Entre Rios de Minas",
	"Barro Alto",
	"Mendrisio",
	"Nyunzu",
	"Bakun",
	"Pulaski",
	"Mian Sahib",
	"Saint-Rambert",
	"Küsnacht",
	"Calolziocorte",
	"Tarascon",
	"Julita",
	"Waikanae",
	"Odenthal",
	"Campina da Lagoa",
	"Gräfelfing",
	"Northview",
	"Hohenstein-Ernstthal",
	"South Middleton",
	"Thoen",
	"Rochedale",
	"Ashtead",
	"Olonne-sur-Mer",
	"Tendrara",
	"Moreau",
	"Bom Jesus do Galho",
	"Feucht",
	"Fraga",
	"Itapagipe",
	"Agidel",
	"Fameck",
	"Santa Catalina",
	"Serra Preta",
	"Castro Daire",
	"Chaltyr",
	"Münster",
	"Bingawan",
	"Targuist",
	"Pedara",
	"Saloá",
	"Blaydon",
	"Silvino Lobos",
	"Bailleul",
	"Swallownest",
	"Lower Salford",
	"Den Ham",
	"Wakuya",
	"Oulad Bou Rahmoun",
	"Krasnovishersk",
	"Hückeswagen",
	"Washington",
	"Bar-le-Duc",
	"Brake",
	"Batuan",
	"Stony Point",
	"Italva",
	"Horw",
	"Chilcuautla",
	"Lahoysk",
	"Poynton",
	"Eldorado",
	"Ambaguio",
	"Alta",
	"Liangwancun",
	"Springfield",
	"Nazaré",
	"Chīpurupalle",
	"Grosse Pointe Woods",
	"Junín",
	"Loviisa",
	"Capitão Enéas",
	"Castellammare del Golfo",
	"Belokurikha",
	"Villa Paranacito",
	"Azpeitia",
	"Kabayan",
	"Libagon",
	"Triunfo",
	"Morlaix",
	"Zevio",
	"Hueytown",
	"Penacova",
	"Aïn Jemaa",
	"Kawaminami",
	"Port Pirie",
	"Aldine",
	"Tazert",
	"Guastalla",
	"Chitila",
	"Bradley",
	"Southchase",
	"Poronaysk",
	"Ibipitanga",
	"Sannicandro Garganico",
	"Santa Luzia",
	"San Vito al Tagliamento",
	"Markgröningen",
	"Tapolca",
	"Pendências",
	"Blomberg",
	"Tulle",
	"Pineto",
	"Wittenheim",
	"Sárvár",
	"Selb",
	"Mackworth",
	"Santa Maria a Vico",
	"Chellaston",
	"IJsselmuiden",
	"Wentorf bei Hamburg",
	"Ribeiro do Amparo",
	"Ribnitz-Damgarten",
	"Witzenhausen",
	"Mexborough",
	"Kerman",
	"Grefrath",
	"Xihuangni",
	"Pestovo",
	"Città Sant’Angelo",
	"Támesis",
	"Yinhua",
	"Mindelheim",
	"Hugo",
	"Tankal",
	"Laterza",
	"São João do Triunfo",
	"Sabbah",
	"Fray Luis A. Beltrán",
	"São Lourenço da Serra",
	"Paralímni",
	"Boksitogorsk",
	"Humayingcun",
	"Jayaque",
	"Büttelborn",
	"Kingersheim",
	"Damascus",
	"Bilopillya",
	"Groß-Zimmern",
	"Méru",
	"Guiratinga",
	"Beckingen",
	"Brejões",
	"Campodarsego",
	"São Félix",
	"Motiong",
	"Uchiko",
	"Berlare",
	"Primavera",
	"Nampicuan",
	"Salyan",
	"La Carolina",
	"Areado",
	"Vergara",
	"San Carlos de la Rápita",
	"Teolândia",
	"Andoain",
	"Santa Adélia",
	"Curiúva",
	"Nakanojōmachi",
	"Masty",
	"Bitburg",
	"Skalica",
	"Tupi Paulista",
	"San Antonio La Paz",
	"Tartarugalzinho",
	"Tokoroa",
	"Mineral Wells",
	"Monte Alegre de Sergipe",
	"Penrith",
	"Neutraubling",
	"Majiagoucha",
	"Gehrden",
	"Samobor",
	"Itajobi",
	"Huasca de Ocampo",
	"Choszczno",
	"Laxou",
	"Bay Village",
	"Huchuan",
	"Tazarine",
	"Candelaria",
	"Pequannock",
	"Arnedo",
	"San Juan",
	"Gardabani",
	"Richterswil",
	"Niedernhausen",
	"Moura",
	"Kutchan",
	"Garrel",
	"San Pedro Tapanatepec",
	"Orocó",
	"Ilha de Moçambique",
	"Guipavas",
	"Mata Roma",
	"Landsberg",
	"North Hykeham",
	"Meilen",
	"Sullivan",
	"Elk Plain",
	"Weston",
	"Cadereyta",
	"Sommacampagna",
	"Hereford",
	"Barra de Santa Rosa",
	"Gaspé",
	"Galion",
	"Cossato",
	"College Park",
	"El Arenal",
	"Dentsville",
	"Grovetown",
	"Amlash",
	"Rosarno",
	"Cirò Marina",
	"Pleasanton",
	"Driouch",
	"Nopala de Villagran",
	"Great Bend",
	"Provadia",
	"Luino",
	"Bela Vista do Paraíso",
	"Gualdo Tadino",
	"Gantt",
	"Ratzeburg",
	"Oued Jdida",
	"Ritterhude",
	"Rāmechhāp",
	"Trepuzzi",
	"Canutama",
	"Porteiras",
	"Burshtyn",
	"Teignmouth",
	"Bhimphedi",
	"Rossville",
	"San Miguel",
	"Fortuna",
	"Santa Brígida",
	"Ban Bang Muang",
	"Râşnov",
	"Kulunda",
	"Sultandağı",
	"Plainfield",
	"Teixeira",
	"La Flèche",
	"Graça",
	"Pont-à-Mousson",
	"Tulchyn",
	"Kochkor-Ata",
	"Bennington",
	"Burbach",
	"Jiquiriçá",
	"Kenora",
	"Rosà",
	"Englewood",
	"Kök-Janggak",
	"Qantīr",
	"Hilchenbach",
	"Mering",
	"Meco",
	"Punata",
	"Skipton",
	"Vil’nyans’k",
	"Thouars",
	"Jamestown",
	"Horta",
	"Exeter",
	"Cowdenbeath",
	"Leninsk",
	"West Richland",
	"Alexándreia",
	"Vişeu de Sus",
	"Şalkhad",
	"Chépica",
	"Ylivieska",
	"Park City",
	"Oldsmar",
	"Spitak",
	"Lohfelden",
	"Washington Court House",
	"Sasso Marconi",
	"Northborough",
	"Coto de Caza",
	"Whitman",
	"Clifton",
	"Mizil",
	"Canteleu",
	"Obando",
	"Tamarana",
	"Pontinia",
	"Sorso",
	"Carmignano",
	"Esbiaat",
	"Zapolyarnyy",
	"Breukelen",
	"Morwell",
	"Uhingen",
	"Tierra Blanca",
	"Berkovitsa",
	"Bludenz",
	"Turnov",
	"Douar Souk L‘qolla",
	"Neu-Anspach",
	"Upper Southampton",
	"Qiaoyang",
	"Bad Bramstedt",
	"Bonanza",
	"Massalubrense",
	"San Juan",
	"Depew",
	"Susanville",
	"Chaguarama",
	"Mariestad",
	"Aghbal",
	"Belchertown",
	"Mittweida",
	"Swampscott",
	"Iharan̈a",
	"Vandalia",
	"Auriflama",
	"Botelhos",
	"Carmo de Minas",
	"Tabant",
	"Willow Grove",
	"Shlisselburg",
	"Jardim de Piranhas",
	"Brasil Novo",
	"Iporã",
	"Drahichyn",
	"Araceli",
	"Coribe",
	"Człuchów",
	"Malo",
	"Jinta",
	"Rebouças",
	"Verdal",
	"Andrésy",
	"Detva",
	"Tsumeb",
	"Pudtol",
	"Serhetabat",
	"Gourrama",
	"Cold Lake",
	"Saint-Fargeau",
	"Sarmīn",
	"Woippy",
	"Novi Pazar",
	"Quinapundan",
	"Bni Darkoul",
	"Cuarte de Huerva",
	"Avrillé",
	"Fort Carson",
	"Tall Abyaḑ",
	"Pallasovka",
	"Saint-Jean-de-Luz",
	"Nobres",
	"Mantua",
	"Beech Grove",
	"Hewitt",
	"Nastola",
	"Thompson",
	"Levoča",
	"Magione",
	"Cassano delle Murge",
	"Cunit",
	"Tehachapi",
	"Buhuşi",
	"Meerane",
	"Cabañas",
	"Nyzhnia Krynka",
	"Sisian",
	"Nerchinsk",
	"Pugachev",
	"Gryazovets",
	"Sukhinichi",
	"Pedro Velho",
	"Vinci",
	"Impruneta",
	"Radomir",
	"Busto Garolfo",
	"Astoria",
	"Shuichecun",
	"Fritzlar",
	"Lons",
	"Candeias",
	"Alamo",
	"Paianía",
	"Santa Croce sull’ Arno",
	"Gīdolē",
	"Kondrovo",
	"Kizel",
	"Zmiiv",
	"Gondomar",
	"Poděbrady",
	"Jisr ez Zarqā",
	"Mascote",
	"Taft",
	"Amersham",
	"Ferndale",
	"Victoria",
	"Namanga",
	"Livingston",
	"Whitewater",
	"Clarksdale",
	"Romilly-sur-Seine",
	"West Lealman",
	"Mondolfo",
	"Vrbovec",
	"Arona",
	"Warragul",
	"Franklin Park",
	"Ramsey",
	"Palmital",
	"Collecchio",
	"New Freedom",
	"Alpine",
	"Victor",
	"San Policarpo",
	"Gafanha da Nazaré",
	"Château-d’Olonne",
	"Phra Samut Chedi",
	"Great Falls",
	"Yankton",
	"Nzalat Laadam",
	"London",
	"Fujisaki",
	"Oftringen",
	"Rotenburg an der Fulda",
	"Audincourt",
	"Markt Schwaben",
	"Ternitz",
	"Chegdomyn",
	"Pattensen",
	"Nioro",
	"Salem Lakes",
	"Bayeux",
	"Petrolândia",
	"Brooklyn Park",
	"Saint-Brevin-les-Pins",
	"Navashino",
	"Hampton Bays",
	"Mions",
	"Spenge",
	"Grodzisk Wielkopolski",
	"Kontiolahti",
	"Betsukai",
	"Huasuo",
	"Ighrem n’Ougdal",
	"Jackson",
	"Sai Buri",
	"Uryzhar",
	"Nevel",
	"Summerside",
	"Löbau",
	"Comox",
	"Bom Lugar",
	"Karabanovo",
	"Fortim",
	"Wombourn",
	"Montelupo Fiorentino",
	"Amay",
	"Maglie",
	"El Paisnal",
	"Cehegín",
	"Katyr-Yurt",
	"Nova Trento",
	"Yazoo City",
	"Mount Washington",
	"Upanema",
	"Sylvan Lake",
	"Jacksonville",
	"California",
	"Vennesla",
	"Orbetello",
	"Anao-aon",
	"Guilford",
	"Ivanić-Grad",
	"Bissendorf",
	"Grimes",
	"Andorinha",
	"Tabuse",
	"Buritirama",
	"Presidente Dutra",
	"Bangui",
	"Huizúcar",
	"Tepetzintla",
	"Bellair-Meadowbrook Terrace",
	"Obanazawa",
	"Lamont",
	"Castel San Giorgio",
	"Lebanon",
	"Dorfen",
	"Roncade",
	"Lalībela",
	"Morpeth",
	"Aït Yaïch",
	"Ekenäs",
	"Rixheim",
	"Villa San Giovanni",
	"Correia Pinto",
	"Shimubi",
	"Cidelândia",
	"Lauchhammer",
	"Saint Joseph",
	"Sidmant al Jabal",
	"Tubaran",
	"Bhalil",
	"Ambérieu-en-Bugey",
	"Novi Iskar",
	"Piano di Sorrento",
	"Maydolong",
	"Rokycany",
	"Hot Springs Village",
	"St. Simons",
	"Berettyóújfalu",
	"Campbellsville",
	"Muri",
	"Kushimoto",
	"Xexéu",
	"Nishinoomote",
	"Usingen",
	"Malsch",
	"Broome",
	"Roquebrune-sur-Argens",
	"Tsivilsk",
	"Ledyard",
	"Danilov",
	"Maqat",
	"Vargem da Roça",
	"Monteroni di Lecce",
	"San Ramón",
	"Çiçekdağı",
	"Bedford",
	"Oulad Driss",
	"Trinitapoli",
	"Nocatee",
	"Tonawanda",
	"Soledade",
	"Lope de Vega",
	"Bang Racham",
	"Cambridge",
	"Angical",
	"Bad Wurzach",
	"Governador Celso Ramos",
	"Pilisvörösvár",
	"Wymondham",
	"Arzgir",
	"Xincun",
	"Kinogitan",
	"Ashiya",
	"Holliston",
	"Montataire",
	"Wschowa",
	"Great Baddow",
	"Berck-sur-Mer",
	"Calera",
	"Ochakiv",
	"Budakeszi",
	"Travagliato",
	"Sanjiang Nongchang",
	"Massaranduba",
	"Tolland",
	"Stein bei Nürnberg",
	"Patnanungan",
	"Askim",
	"Raamsdonk",
	"Port Augusta",
	"Carmen de Areco",
	"Rehlingen-Siersburg",
	"Lubbeek",
	"Youngsville",
	"Tayum",
	"Candiba",
	"Royse City",
	"Brazópolis",
	"Ardestān",
	"Farnborough",
	"Azalea Park",
	"Versoix",
	"Hulyaypole",
	"Pomorie",
	"Lauda-Königshofen",
	"Bischofsheim",
	"Hartsville",
	"Worthington",
	"Zontecomatlán de López y Fuentes",
	"Penrith",
	"North Fayette",
	"Gland",
	"Ihtiman",
	"‘Alavīcheh",
	"Troon",
	"Mascali",
	"Nyagatare",
	"Cafelândia",
	"Saint-Jacques-de-la-Lande",
	"Condoto",
	"Brad",
	"Bollullos par del Condado",
	"General MacArthur",
	"Ottweiler",
	"Sanderstead",
	"Baiersbronn",
	"Plérin",
	"Abong Mbang",
	"Latiano",
	"Zollikon",
	"Simões",
	"Las Vegas",
	"Ban Kaeng",
	"Alfredo Chaves",
	"Bicas",
	"Noya",
	"Southern Pines",
	"Gouré",
	"Cicciano",
	"Lopik",
	"Djanet",
	"Forster",
	"Hershey",
	"Tepecoyo",
	"Berea",
	"Saraland",
	"Ipauçu",
	"Kuurne",
	"El Dorado",
	"Darłowo",
	"Manor",
	"Williamstown",
	"Matias Barbosa",
	"Chelsfield",
	"Jever",
	"Eberbach",
	"Wilbraham",
	"Bacuag",
	"Paradise Valley",
	"Radomyshl",
	"Santa Maria do Suaçuí",
	"Alzano Lombardo",
	"Pastores",
	"Klášterec nad Ohří",
	"Pewaukee",
	"Lapua",
	"Washington",
	"Náfplio",
	"Heswall",
	"Roncq",
	"Urbino",
	"Ayt ’Attou ou L’Arbi",
	"Priverno",
	"Mocha",
	"Langwedel",
	"Warwick",
	"Olsberg",
	"Windham",
	"Cadaval",
	"Néa Alikarnassós",
	"Oschatz",
	"Villa Yapacaní",
	"Río Branco",
	"Majia",
	"Bezou",
	"Boninal",
	"Ústí nad Orlicí",
	"Saint-Genis-Pouilly",
	"Boula’wane",
	"Gonzalez",
	"Curuá",
	"Port Orchard",
	"Yanhewan",
	"San Julian",
	"Boa Nova",
	"Guaraniaçu",
	"Forest City",
	"Apuiarés",
	"Qazyqurt",
	"Pucioasa",
	"Zemamra",
	"Santa Branca",
	"Breza",
	"Tibau do Sul",
	"Bosobolo",
	"Douarnenez",
	"Santa Filomena",
	"Muscle Shoals",
	"Jennings",
	"Tlayacapan",
	"Henderson",
	"Stanytsia Luhanska",
	"Boa Esperança do Sul",
	"Leno",
	"Connersville",
	"Royal Kunia",
	"Neustadt an der Donau",
	"Maxcanú",
	"Caldas",
	"Much",
	"Goonellabah",
	"Antequera",
	"Tsimlyansk",
	"Ust’-Ordynskiy",
	"Metuchen",
	"Barra do Mendes",
	"Água Boa",
	"Lieusaint",
	"Vatra Dornei",
	"Kearsley",
	"Alterosa",
	"Grain Valley",
	"Milnrow",
	"Bad Camberg",
	"Villaviciosa",
	"Iflissen",
	"Tubod",
	"Sopetrán",
	"Pinhalzinho",
	"Mount Dora",
	"Cefalù",
	"Barroquinha",
	"Skippack",
	"Stezzano",
	"Langenselbold",
	"Wörgl",
	"Cuevas del Almanzora",
	"Uibaí",
	"Oleggio",
	"San Juan",
	"Scheemda",
	"Orikhiv",
	"Lotte",
	"Abony",
	"Ceres",
	"Hola Prystan",
	"Squinzano",
	"Progreso",
	"West Lincoln",
	"Dryden",
	"Liedekerke",
	"Krasnyy Kut",
	"Pé de Serra",
	"Manoel Vitorino",
	"Dueville",
	"Alausí",
	"Wangjiaxian",
	"Bo’ness",
	"Schneeberg",
	"Torrijos",
	"Fiesole",
	"Argentan",
	"Glasgow",
	"Locorotondo",
	"Little Falls",
	"Casinhas",
	"Branchburg",
	"Fraser",
	"Riedisheim",
	"Ourtzagh",
	"Rovinj",
	"Sun Lakes",
	"Sidi Azzouz",
	"Wilsdruff",
	"Corinth",
	"Ulchin",
	"Gernsbach",
	"Dimiao",
	"Mahinog",
	"Hartselle",
	"East Bridgewater",
	"Ollioules",
	"Matane",
	"Arroio dos Ratos",
	"Shamalgan",
	"Thonotosassa",
	"Hanover",
	"Tagalft",
	"Münsingen",
	"Zemrane",
	"Hlučín",
	"Fostoria",
	"Tenafly",
	"Brooks",
	"Taggia",
	"Amantea",
	"Tornesch",
	"Immenstadt im Allgäu",
	"Gargždai",
	"Maisach",
	"Caselle Torinese",
	"Beekman",
	"Yamada",
	"Sassenberg",
	"Zaouiat Moulay Bouchta El Khammar",
	"North Strabane",
	"Chaparral",
	"Gorssel",
	"Polyarnyye Zori",
	"Scottsboro",
	"Cliftonville",
	"Ascensión",
	"Shirebrook",
	"Leverano",
	"Dippoldiswalde",
	"Lockhart",
	"Middlewich",
	"Geldagana",
	"Haaren",
	"Thibodaux",
	"Marmeleiro",
	"Marostica",
	"Igrapiúna",
	"Sanpozhen",
	"Sainte-Anne-des-Plaines",
	"Washington",
	"Bom Princípio",
	"Itinga",
	"Ilhota",
	"Paranhos",
	"San Andrés Timilpan",
	"Sainte-Maxime",
	"Port Hedland",
	"Fisciano",
	"Cotegipe",
	"Peumo",
	"Taglio",
	"Chudovo",
	"Hettstedt",
	"Beasain",
	"Pazar",
	"Alginet",
	"Bradley Gardens",
	"Schiffdorf",
	"Hanover",
	"Det Udom",
	"Lagangilang",
	"San Cristóbal",
	"Greenlawn",
	"Wulong",
	"Robbinsdale",
	"Valday",
	"Pak Thong Chai",
	"Carlópolis",
	"Yanshuiguan",
	"Eching",
	"Tafrant",
	"Clewiston",
	"Feldkirchen",
	"Zolote",
	"Tounfit",
	"Furukawamen",
	"Markdorf",
	"Rio Piracicaba",
	"Abadla",
	"Warren",
	"Chippewa Falls",
	"Lakinsk",
	"Robbinsville",
	"Artena",
	"West Nipissing / Nipissing Ouest",
	"Maurilândia",
	"Uslar",
	"Ocean View",
	"Astolfo Dutra",
	"Ciénega de Flores",
	"Glasgow",
	"Langerwehe",
	"Cervignano del Friuli",
	"Ostwald",
	"Tsukawaki",
	"Bridgetown",
	"Warrington",
	"Carballino",
	"Temsamane",
	"Yakhroma",
	"Midlothian",
	"Estremoz",
	"Denzlingen",
	"Summerfield",
	"Medvezhyegorsk",
	"Koundara",
	"Tenerife",
	"Eski Īkan",
	"Coronel Bogado",
	"El Barrio de la Soledad",
	"Bua Yai",
	"Cisnădie",
	"Hollins",
	"Schwabmünchen",
	"Lebedyn",
	"Ban Mae Kaluang",
	"Tarumirim",
	"Rezzato",
	"Takerbouzt",
	"Cristópolis",
	"Mountain Home",
	"Alexander City",
	"Korneuburg",
	"Svetlogorsk",
	"Wang’anzhen",
	"Marechal Floriano",
	"Sines",
	"Santo Antônio dos Lopes",
	"Cambados",
	"Frankenberg",
	"Camden",
	"Kashin",
	"Tergnier",
	"San Marcos",
	"Bondeno",
	"Matiçan",
	"Dayr al Barshā",
	"Otopeni",
	"Rosemère",
	"Corat",
	"Hooksett",
	"Maria da Fé",
	"Lubsko",
	"Toppenish",
	"Ozark",
	"Kouroussa",
	"Douglas",
	"Magdiwang",
	"Raydah",
	"Kawaii",
	"Almoloya de Alquisiras",
	"Matungao",
	"Eastham",
	"Santiago Ixtayutla",
	"Malone",
	"Santa Juliana",
	"Guapé",
	"Vinto",
	"Sankt Leon-Rot",
	"North Auburn",
	"Hard",
	"Juan de Acosta",
	"Douar Hammadi",
	"Germasógeia",
	"Shichinohe",
	"Mill Valley",
	"La Carlota",
	"Chalfont Saint Peter",
	"Wennigsen",
	"Niederzier",
	"Pilas",
	"Quakenbrück",
	"Tamaqua",
	"Tarumã",
	"Bickley",
	"Mistassini",
	"Bou Izakarn",
	"Valença",
	"Hatten",
	"Destin",
	"Amriswil",
	"Ochër",
	"Kivertsi",
	"Maridi",
	"El Porvenir de Velasco Suárez",
	"Zherdevka",
	"Hayashima",
	"Wittstock",
	"Venëv",
	"Murrhardt",
	"West Boldon",
	"Aḑ Ḑab‘ah",
	"Püspökladány",
	"Salida",
	"Torquay",
	"Lewisville",
	"Sakaki",
	"Kings Park West",
	"Nilo Peçanha",
	"Belison",
	"Malvik",
	"Guia",
	"Kaoma",
	"Caln",
	"Mirinzal",
	"Bad Ischl",
	"Torre del Campo",
	"Eppstein",
	"East Wenatchee",
	"Golub-Dobrzyń",
	"Lindenhurst",
	"Phillipsburg",
	"Biatorbágy",
	"Rio Casca",
	"Covington",
	"Jefferson Valley-Yorktown",
	"Piquete",
	"Rincon",
	"Baianópolis",
	"California City",
	"Maasbracht",
	"Rizal",
	"Taos",
	"Raynham",
	"Neuenkirchen",
	"Marchtrenk",
	"Titiribí",
	"North Branford",
	"Porecatu",
	"Beilen",
	"Hopatcong",
	"Puerto Deseado",
	"Náfpaktos",
	"Hidalgo",
	"Isny im Allgäu",
	"Kadena",
	"Nannestad",
	"Fokino",
	"Cambridge",
	"Abensberg",
	"Gardendale",
	"Mucambo",
	"Antioch",
	"Uitgeest",
	"Veglie",
	"Engenheiro Paulo de Frontin",
	"Bou Fekrane",
	"Grand Falls",
	"Rheinfelden",
	"Olgiate Olona",
	"Kerben",
	"Lakoucun",
	"Nioaque",
	"Ipiranga",
	"Zimnicea",
	"Kakunodatemachi",
	"Grünstadt",
	"Santa Luzia do Itanhy",
	"San Ferdinando di Puglia",
	"Knottingley",
	"Sátoraljaújhely",
	"New Paltz",
	"Astravyets",
	"Johnson City",
	"Itaguaçu",
	"Novoulyanovsk",
	"Kisanuki",
	"Balangiga",
	"Narvik",
	"Fara in Sabina",
	"Hendersonville",
	"Clearview",
	"Seabrook",
	"Nelas",
	"Mashpee",
	"Eynesil",
	"Crowley",
	"Sidi Abdelkarim",
	"Sauk Rapids",
	"Mapanas",
	"Boutilimit",
	"Vetralla",
	"Greendale",
	"Bedford",
	"Mayrtup",
	"Lübben (Spreewald)",
	"Añatuya",
	"Emerald",
	"Canals",
	"Lockhart",
	"New Franklin",
	"Tamm",
	"Vöhringen",
	"Leuna",
	"Chelsea",
	"Larena",
	"Crissiumal",
	"Ionia",
	"Pineville",
	"Grafing bei München",
	"Sülüktü",
	"Lukoyanov",
	"Törökbálint",
	"Aldenhoven",
	"Cheadle",
	"Tostedt",
	"Tempio Pausania",
	"Iona",
	"Ystrad Mynach",
	"Patrocínio Paulista",
	"Villalba",
	"Castelsarrasin",
	"Cleveland",
	"Cabo Verde",
	"Greensburg",
	"Bebra",
	"Middleburg",
	"Santiago de Anaya",
	"Bamble",
	"Zhipingxiang",
	"Montabaur",
	"Aracatu",
	"Harrison",
	"Villacidro",
	"Bergschenhoek",
	"Gouveia",
	"Paraíso do Norte",
	"Sarezzo",
	"Mjölby",
	"Chesterton",
	"Maria",
	"Steamboat Springs",
	"St. Clair",
	"Talwat",
	"Holzgerlingen",
	"Sweden",
	"Black Forest",
	"Lomas de Sargentillo",
	"Fort Payne",
	"Casatenovo",
	"Moudjbara",
	"Schmölln",
	"Oberhaching",
	"Montepulciano",
	"Castel San Giovanni",
	"Aleksandrów Kujawski",
	"Cherry Hill Mall",
	"Fruitville",
	"Mór",
	"Wanding",
	"Lakewood Park",
	"Río de Oro",
	"El Carmen de Atrato",
	"São João do Araguaia",
	"Guazacapán",
	"Galmi",
	"Callaway",
	"Oatfield",
	"Phalaborwa",
	"Kalkar",
	"Bēylul",
	"Múggia",
	"Pomfret",
	"Córdoba",
	"Waunakee",
	"Fāmenīn",
	"Wixom",
	"Luling",
	"Canton",
	"La Puebla",
	"Chumpak",
	"Sanlúcar la Mayor",
	"Eggenfelden",
	"Genthin",
	"Pell City",
	"Lariano",
	"Belm",
	"Pecos",
	"Sampaloc",
	"Taiyong",
	"Peabiru",
	"Bay St. Louis",
	"Biscarrosse",
	"Huangyadong",
	"Gisborne",
	"La Entrada",
	"Vieste",
	"Mittegroßefehn",
	"Belle Chasse",
	"Trubchevsk",
	"Thala",
	"Havre de Grace",
	"Montesarchio",
	"Taishi",
	"Monroe",
	"East Norriton",
	"Ovidiu",
	"Statte",
	"D'Iberville",
	"Ibiraci",
	"Cayce",
	"Chodov",
	"Effingham",
	"Santaquin",
	"Guapó",
	"Hobe Sound",
	"Misano Adriatico",
	"Dubovka",
	"Santo Augusto",
	"Alto Alegre dos Parecis",
	"Zeven",
	"Acala",
	"Tako",
	"Uxbridge",
	"Wauchula",
	"Colesville",
	"Canmore",
	"Vianópolis",
	"Goshen",
	"Ivaí",
	"Dayton",
	"Reidsville",
	"Ban Bang Non",
	"Engenheiro Beltrão",
	"Hutchinson",
	"Beesel",
	"Dallas",
	"Plumstead",
	"Mamborê",
	"Wanzleben",
	"Spiesen-Elversberg",
	"Oxford",
	"Noyon",
	"Topliţa",
	"Pacaembu",
	"Aßlar",
	"Kiskőrös",
	"Beacon",
	"Primeira Cruz",
	"Ipswich",
	"Dumas",
	"Raymondville",
	"Anapurus",
	"Frederick",
	"Novellara",
	"Artesia",
	"Valbonne",
	"Polotitlán de la Ilustración",
	"New Fairfield",
	"Ban Bang Sai",
	"Sóller",
	"Fagnano Olona",
	"Ping’anbao",
	"North Mankato",
	"Tucacas",
	"Ielmo Marinho",
	"Lithgow",
	"Meckenbeuren",
	"Shawangunk",
	"Mar de Ajó",
	"Maracaí",
	"Dennis",
	"Glenpool",
	"Oil City",
	"Clinton",
	"Brejo do Cruz",
	"Gien",
	"Hammonton",
	"Orange",
	"New Kingman-Butler",
	"Tracunhaém",
	"Newhaven",
	"Nidzica",
	"Dalkeith",
	"Kamigōri",
	"Diego de Almagro",
	"Deming",
	"Oulad Chikh",
	"Big Rapids",
	"Zhovkva",
	"Miami Springs",
	"San Prisco",
	"Itapetim",
	"Pirallahı",
	"Fulshear",
	"Miraíma",
	"Cromwell",
	"South Venice",
	"Khmis Sidi al ’Aydi",
	"Ataléia",
	"Demerval Lobão",
	"Chaoyangdicun",
	"Găeşti",
	"Douar Lehgagcha",
	"Allouez",
	"Princeton",
	"Valburg",
	"Baraboo",
	"Gura Humorului",
	"Zhashkiv",
	"Lanuvio",
	"Cavarzere",
	"Jericho",
	"North Battleford",
	"Salisbury",
	"Kauhajoki",
	"Wanze",
	"Philippsburg",
	"Erbach",
	"Rustington",
	"Pembroke",
	"Jinmingsi",
	"El Sobrante",
	"Schwaz",
	"Cahokia",
	"Niquinohomo",
	"Springdale",
	"Oak Island",
	"Saint-Gilles",
	"Melsungen",
	"Gramercy",
	"Atlantic Beach",
	"Zábřeh",
	"Haßfurt",
	"Guapí",
	"Teays Valley",
	"Ephrata",
	"Sant’Angelo Lodigiano",
	"Oosterzele",
	"Oak Park",
	"Uruana",
	"Ban Tha Luang Lang",
	"Arcozelo",
	"Santa Cruz de Bezana",
	"Couvin",
	"Zacualpan",
	"Saddle Brook",
	"Horodyshche",
	"San Pietro Vernotico",
	"Apostolove",
	"Richmond Hill",
	"Forlimpopoli",
	"Peri-Mirim",
	"Granville",
	"Brandermill",
	"São José do Cedro",
	"Joaquim Pires",
	"Machados",
	"Latisana",
	"Yamanobe",
	"Berre-l’Étang",
	"Alagoinha",
	"Jupi",
	"Lamas",
	"Sedriano",
	"Ak-Dovurak",
	"Lich",
	"Great Driffield",
	"Rutherford",
	"Llanera",
	"Calanogas",
	"Forrest City",
	"Sun Village",
	"Vilanova del Camí",
	"Santana do Matos",
	"San Agustín de Guadalix",
	"Arzachena",
	"Crispiano",
	"Jardín",
	"Eichstätt",
	"Bokod",
	"Moorestown-Lenola",
	"Melenki",
	"Mwinilunga",
	"Heliópolis",
	"Wayland",
	"Neckargemünd",
	"Zarghūn Shahr",
	"Bovenden",
	"Jindayris",
	"Marienheide",
	"Lauterbach",
	"Pelham",
	"Fergus Falls",
	"Passa e Fica",
	"Włodawa",
	"Verín",
	"Spaichingen",
	"Dores do Indaiá",
	"Angermünde",
	"Ampthill",
	"Selston",
	"Feliz",
	"Aurora",
	"Kupino",
	"Pondūru",
	"Chiran",
	"Pedro Afonso",
	"Mont-Laurier",
	"Münchenstein",
	"Ahrensfelde",
	"Forres",
	"Gołdap",
	"Hilpoltstein",
	"San Vicente",
	"La Quiaca",
	"Conneaut",
	"Nether Providence",
	"Mansidão",
	"Biedenkopf",
	"Châlette-sur-Loing",
	"São Ludgero",
	"Tenente Portela",
	"Bollène",
	"Thomaston",
	"Otley",
	"Strathmore",
	"Virgem da Lapa",
	"Abre Campo",
	"Levelland",
	"Bandarbeyla",
	"Filandia",
	"Mbulu",
	"Grünberg",
	"Jaguapitã",
	"Palmetto",
	"Neunkirchen",
	"Puxinanã",
	"Piney Green",
	"Caledon",
	"Xireg",
	"Fort Campbell North",
	"Schoonhoven",
	"Camponogara",
	"Millbury",
	"Mangha",
	"Andaraí",
	"Motupe",
	"Cumnock",
	"Cobh",
	"Kuleshovka",
	"Altos del Rosario",
	"Pierrelatte",
	"Jimbolia",
	"Alleroy",
	"Schermbeck",
	"Bergen",
	"Conceição do Rio Verde",
	"Massaranduba",
	"Blegny",
	"Torredonjimeno",
	"Tamsaout",
	"San Pedro Pochutla",
	"Vedi",
	"Saugeen Shores",
	"Camapuã",
	"Quatipuru",
	"Margraten",
	"Wells",
	"Hickory Hills",
	"Quartucciu",
	"Khomām",
	"Meruoca",
	"Quatá",
	"Robinson",
	"Hinabangan",
	"Beach Park",
	"Burglengenfeld",
	"Bully-les-Mines",
	"General Carneiro",
	"Winsum",
	"East Milton",
	"Satuba",
	"Grammichele",
	"Hünxe",
	"Tomiño",
	"Fontenay-le-Comte",
	"Weißenhorn",
	"Dishāshah",
	"Sharonville",
	"Lappersdorf",
	"Santa Marcela",
	"Cacequi",
	"West Freehold",
	"Middlesex",
	"Thompson",
	"Lagoa da Confusão",
	"Quierschied",
	"San Jorge",
	"Castelfranco di Sotto",
	"Spring Creek",
	"St. James",
	"Vigodarzere",
	"Nikolayevsk",
	"Maumee",
	"Caldeirão Grande",
	"Alto Paraná",
	"Lawrenceburg",
	"Brevard",
	"Saint-Paul-lès-Dax",
	"Erbach",
	"São Pedro do Piauí",
	"Bad Laasphe",
	"Salzano",
	"Šternberk",
	"Beyne-Heusay",
	"Ad Duraykīsh",
	"Rio Vermelho",
	"Lavaltrie",
	"Bowral",
	"Lengede",
	"Rottingdean",
	"Głubczyce",
	"Ixcatepec",
	"Nicosia",
	"Gran",
	"Arcadia",
	"Sunjiayan",
	"Lorsch",
	"Bridport",
	"Hösbach",
	"San Martino di Lupari",
	"Santa Luzia",
	"Adams",
	"Talne",
	"San Marco in Lamis",
	"Cajabamba",
	"Mirabela",
	"Trogir",
	"Crevalcore",
	"Kirchheim bei München",
	"Afir",
	"Rio de Contas",
	"Broxburn",
	"Montefiascone",
	"Zelzate",
	"Vicovu de Sus",
	"Šamorín",
	"Beverstedt",
	"Saint-Martin-de-Crau",
	"Kastoriá",
	"Hersham",
	"Soddy-Daisy",
	"Melilli",
	"Terra Boa",
	"Moberly",
	"Plougastel-Daoulas",
	"Bacup",
	"Port Clinton",
	"Zhukovo",
	"Höchstadt an der Aisch",
	"Canton",
	"Agua Blanca",
	"San Javier",
	"Águas Vermelhas",
	"Grez-Doiceau",
	"San Gennaro Vesuviano",
	"Waianae",
	"Qingxicun",
	"Vadnais Heights",
	"Wood Dale",
	"Panukulan",
	"Montespertoli",
	"Haaren",
	"Brecksville",
	"Wolsztyn",
	"Frías",
	"Bad Soden-Salmünster",
	"Eichenau",
	"Saarwellingen",
	"Aghbala",
	"Dakit",
	"New Providence",
	"Maribondo",
	"Radnevo",
	"Betanzos",
	"Quatis",
	"Krumbach",
	"Ettenheim",
	"Alexandria",
	"Kaharlyk",
	"El Barco de Valdeorras",
	"Shiloh",
	"Pfullendorf",
	"Shangping",
	"Rantoul",
	"High River",
	"Colonial Park",
	"Serra Branca",
	"Tomar do Geru",
	"Bagulin",
	"Drøbak",
	"Manerbio",
	"Tianguistengo",
	"Spiez",
	"Broadlands",
	"Marly",
	"Shepshed",
	"Barra do Ribeiro",
	"Presidente Bernardes",
	"Boston",
	"Chã da Alegria",
	"Mqam at Tolba",
	"Feldbach",
	"Genas",
	"Lourdes",
	"Mario Campos",
	"Kingston",
	"Talanga",
	"Washington",
	"Hazel Crest",
	"Price",
	"Vitry-le-François",
	"LaBelle",
	"Arruda dos Vinhos",
	"Reeuwijk",
	"Trebur",
	"Creutzwald",
	"Nesebar",
	"Pānchgani",
	"Vernouillet",
	"Santa Coloma de Farnés",
	"Eloxochitlán",
	"Charlton",
	"Landau an der Isar",
	"Carluke",
	"Urupês",
	"Ilamatlán",
	"Chañaral",
	"Calderara di Reno",
	"Alpena",
	"Halsteren",
	"Knittelfeld",
	"Flint",
	"Châteaudun",
	"Roudnice nad Labem",
	"Löningen",
	"Ketsch",
	"Manuel Ribas",
	"Tezze sul Brenta",
	"Patti",
	"Ribeirão do Pinhal",
	"Buşrá al Ḩarīr",
	"Küssnacht",
	"Dok Kham Tai",
	"Gricignano d’Aversa",
	"Conway",
	"Kettering",
	"Woudenberg",
	"Llanes",
	"Mayenne",
	"Manatí",
	"Rocky Point",
	"Ainsdale",
	"Kissane Ltouqi",
	"Castelnuovo di Verona",
	"Loreto",
	"Sant Sadurní d’Anoia",
	"Casale sul Sile",
	"Neunkirchen",
	"Pervomaysk",
	"Lavello",
	"Elizabethton",
	"Ficarazzi",
	"Miranorte",
	"Wauconda",
	"Lake Forest Park",
	"Aconibe",
	"Quattro Castella",
	"Gex",
	"Ban Chorakhe Samphan",
	"Nules",
	"Vicuña",
	"Schüttorf",
	"Brunn am Gebirge",
	"Zehdenick",
	"Avenal",
	"Buchloe",
	"Fereydūnshahr",
	"Rogers",
	"Formello",
	"Maasbree",
	"Kaspi",
	"Marshall",
	"Mechtras",
	"Rionero in Vulture",
	"Champasak",
	"Standish",
	"Várzea Nova",
	"Saint-Orens-de-Gameville",
	"Jensen Beach",
	"Jimboomba",
	"Uttoxeter",
	"Fruita",
	"Homosassa Springs",
	"Severn",
	"Paripueira",
	"Mutterstadt",
	"Friedland",
	"Middlesborough",
	"Bad Dürrheim",
	"Tahannawt",
	"Harnes",
	"Costessey",
	"Itaguara",
	"Les Ponts-de-Cé",
	"Wake",
	"Wehr",
	"Red Oak",
	"Olivehurst",
	"Big Bear City",
	"Aguilar",
	"Martinho Campos",
	"Pont-Sainte-Maxence",
	"Brig-Glis",
	"Marion",
	"Wernau",
	"Muisne",
	"Rommerskirchen",
	"San Jacinto del Cauca",
	"Alexandria",
	"Khoyniki",
	"Fortuna",
	"Santa Fe",
	"North Valley",
	"Chiampo",
	"Schinnen",
	"Ruza",
	"Giporlos",
	"Tagoloan",
	"Lindale",
	"Oboyan",
	"Verona",
	"Capaci",
	"Akhty",
	"Keerbergen",
	"Englewood",
	"Lakeland South",
	"Washington",
	"Le Relecq-Kerhuon",
	"Jasper",
	"Golyshmanovo",
	"Balchik",
	"Short Hills",
	"Calcinaia",
	"Huron",
	"Arroio do Tigre",
	"Amilly",
	"Itapuí",
	"Tauberbischofsheim",
	"El Tabo",
	"Sault Ste. Marie",
	"Nemours",
	"Bollnäs",
	"Te Awamutu",
	"Saka",
	"Serrolândia",
	"Espita",
	"Qigexingcun",
	"Plattling",
	"Colac",
	"Mijdrecht",
	"Paso de los Toros",
	"Andover",
	"Rosas",
	"Santa Maria a Monte",
	"Buchs",
	"Luís Antônio",
	"Dossenheim",
	"Gmunden",
	"Zelenodolsk",
	"Matsushima",
	"Brookside",
	"Sison",
	"Veyrier",
	"South Park Township",
	"South Park",
	"Arara",
	"Tachov",
	"Mountain Park",
	"Carpenedolo",
	"Ubstadt-Weiher",
	"Varazze",
	"Gourock",
	"Shanyincun",
	"Carpinteria",
	"Grimstad",
	"Guéret",
	"Mogeiro",
	"Joaquín V. González",
	"San José de Maipo",
	"Besigheim",
	"Glenn Heights",
	"Pegnitz",
	"Ribeira Brava",
	"Powell",
	"Sainte-Sophie",
	"Itagi",
	"Palombara Sabina",
	"Cavallino",
	"Autun",
	"Plouzané",
	"Friesenheim",
	"Rödental",
	"Wasserburg am Inn",
	"Lyndhurst",
	"North Walsham",
	"Český Krumlov",
	"Artondale",
	"Yakage",
	"Glenn Dale",
	"Western Springs",
	"Okmulgee",
	"Lüderitz",
	"Campo de Criptana",
	"DeRidder",
	"Sarria",
	"Azle",
	"Newtown",
	"Bugugoucun",
	"Vidalia",
	"Swansea",
	"Moss Point",
	"Nordestina",
	"Fort Morgan",
	"Moissac",
	"Itiruçu",
	"Dardenne Prairie",
	"Sudbury",
	"Tanagura",
	"Castiglion Fiorentino",
	"Bischwiller",
	"Bad Salzdetfurth",
	"Moorreesburg",
	"Porto Recanati",
	"Jitaúna",
	"Aš",
	"Ubach over Worms",
	"Trebaseleghe",
	"River Ridge",
	"Neustadt",
	"Kasumkent",
	"Newark",
	"Cremlingen",
	"Dinklage",
	"Aubergenville",
	"Van Buren",
	"Sítio do Mato",
	"Retirolândia",
	"Puerto San Martín",
	"Buenos Aires",
	"Nong Khae",
	"Tocumbo",
	"Medway",
	"Reggada",
	"Mounds View",
	"Dalby",
	"Boudenib",
	"Chiroqchi",
	"Imbaú",
	"Jefferson",
	"Saint-Charles-Borromée",
	"Zella-Mehlis",
	"Kerepestarcsa",
	"Capo d’Orlando",
	"Hüllhorst",
	"Velen",
	"Carros",
	"Nosivka",
	"Berkeley Heights",
	"Portage La Prairie",
	"Cocotitlán",
	"Rüti",
	"Wiesmoor",
	"Santo Niño",
	"Usumatlán",
	"Rochester",
	"Solana Beach",
	"Borodyanka",
	"Kiiminki",
	"Sendenhorst",
	"Nesconset",
	"Richton Park",
	"Franconia",
	"Seravezza",
	"Mogocha",
	"Montegranaro",
	"Slobozhanske",
	"Beyla",
	"Calcinato",
	"Svirsk",
	"Trzebnica",
	"Comines",
	"Cairo Montenotte",
	"Herbrechtingen",
	"Mahao",
	"Palos Verdes Estates",
	"Pontecorvo",
	"Manching",
	"Chulumani",
	"Ulvila",
	"Novouzensk",
	"Esneux",
	"Gvardeysk",
	"El Kansera",
	"Richland",
	"San Biagio di Callalta",
	"Woodinville",
	"Serra Azul",
	"Leonforte",
	"Bergen",
	"Sillamäe",
	"Östringen",
	"Agdangan",
	"Manorville",
	"Damaishan",
	"Tamahú",
	"Apricena",
	"Beverungen",
	"Bellefontaine",
	"Andkhōy",
	"Lapinig",
	"Timberlake",
	"Kent",
	"Morbegno",
	"Chigwell",
	"Sabinov",
	"Ban Tat",
	"Al Muzayrīb",
	"Picaña",
	"Channahon",
	"Port Royal",
	"Verona",
	"Ceyu",
	"Plaza Huincul",
	"Bensville",
	"Ocna Mureş",
	"Bolintin Vale",
	"Roßdorf",
	"Clayton",
	"East Islip",
	"Affoltern am Albis",
	"Odoorn",
	"Razlog",
	"Raffadali",
	"Vila Pouca de Aguiar",
	"Weilburg",
	"Vasylivka",
	"Uropá",
	"Stamboliyski",
	"Asjen",
	"New Ulm",
	"La Queue-en-Brie",
	"Farmington",
	"Harrow on the Hill",
	"Nova Londrina",
	"Monteux",
	"Somain",
	"Barsbüttel",
	"Ansião",
	"Bridgnorth",
	"Miranda do Corvo",
	"Barrafranca",
	"Balatonfüred",
	"Oncativo",
	"Tatarikan",
	"Juruá",
	"Huguan Nongchang",
	"Yzeure",
	"Belëv",
	"Newington",
	"Spilamberto",
	"Baker",
	"Mazatlán Villa de Flores",
	"Thames Centre",
	"Alora",
	"Presidente Jânio Quadros",
	"Krasnozavodsk",
	"Ceuti",
	"Yvetot",
	"Monte Belo",
	"Cedar Lake",
	"Singuilucan",
	"Globe",
	"Newton",
	"Uyar",
	"Wolfhagen",
	"Sankt Georgen im Schwarzwald",
	"Campiglia Marittima",
	"Warrenville",
	"Baños",
	"Barentin",
	"Abrera",
	"Lower Moreland",
	"Terrasini Favarotta",
	"Mississippi Mills",
	"Bonyhád",
	"Schleiden",
	"Spreitenbach",
	"Monteprandone",
	"Zabaykalsk",
	"Luís Alves",
	"Hockessin",
	"Powell River",
	"Sete Barras",
	"Waltershausen",
	"Velykodolynske",
	"Petushki",
	"L’Union",
	"Rendon",
	"South Glengarry",
	"Adygeysk",
	"Noeux-les-Mines",
	"Sidi Yahia",
	"Hajdúsámson",
	"Balneário do Rincão",
	"Adi Keyh",
	"Sandpoint",
	"Loveland",
	"Missões",
	"Bedburg",
	"Yavoriv",
	"Raymond Terrace",
	"Bolsover",
	"Avigliana",
	"Trenton",
	"Sali",
	"Izium",
	"Tapiratiba",
	"Qulan",
	"Abarán",
	"Strunino",
	"Coolidge",
	"North Perth",
	"Kaspiyskiy",
	"North Palm Beach",
	"Piratininga",
	"Schodack",
	"Balsa Nova",
	"Seclin",
	"Pine",
	"Mercier",
	"Stoughton",
	"Mount Vernon",
	"Milhã",
	"South Stormont",
	"Aubenas",
	"Warrensville Heights",
	"Nossa Senhora do Livramento",
	"Olen",
	"Scheeßel",
	"Barßel",
	"Worthington",
	"Plover",
	"Villecresnes",
	"Goiatins",
	"Arcachon",
	"Martinsville",
	"Ksar Sghir",
	"Honggu",
	"Chos Malal",
	"Wickede",
	"Song Phi Nong",
	"Ciudad Insurgentes",
	"Mahomet",
	"Oftersheim",
	"Olfen",
	"Lagoa Dourada",
	"Tonoshō",
	"Governador Dix-Sept Rosado",
	"Superior",
	"Oxford",
	"Sarrebourg",
	"Antônio Prado",
	"Cusset",
	"Noceto",
	"Lone Tree",
	"Montgomeryville",
	"Bagnolo Mella",
	"East Greenwich",
	"South Daytona",
	"Saint-Colomban",
	"Macará",
	"Claye-Souilly",
	"Riverdale",
	"Pingtiancun",
	"Lumberton",
	"Villas",
	"York",
	"Brenes",
	"Herzberg am Harz",
	"Sebt Bni Garfett",
	"Čelákovice",
	"Treuchtlingen",
	"Moody",
	"Chernigovka",
	"Notodden",
	"Frecheirinha",
	"McPherson",
	"Stolin",
	"Alaverdi",
	"Tetiiv",
	"Hersbruck",
	"Auriol",
	"Vieira do Minho",
	"Röthenbach an der Pegnitz",
	"Lacombe",
	"Honiton",
	"Alcácer do Sal",
	"Fort Lewis",
	"Davidson",
	"Edgewood",
	"Kaji",
	"Suamico",
	"Borgo San Dalmazzo",
	"La Chapelle-Saint-Luc",
	"San Marino",
	"Spring Garden",
	"Senador José Porfírio",
	"Esperance",
	"Turvo",
	"Santo Stino di Livenza",
	"Parigi",
	"Muswellbrook",
	"Carnaubeira da Penha",
	"Capul",
	"Tabocas do Brejo Velho",
	"Gärtringen",
	"Solofra",
	"Trentham",
	"Portland",
	"Argentona",
	"Bou Adel",
	"Maktar",
	"Shiwan",
	"Anolaima",
	"Strand",
	"Pallejá",
	"Morungaba",
	"Huai Yot",
	"Fort Drum",
	"Bonneville",
	"Colesberg",
	"Trezzo sull’Adda",
	"West Bradford",
	"Graulhet",
	"Bni Khloug",
	"Wanderley",
	"Teror",
	"Chatham",
	"Divnoye",
	"Oulad Rahmoun",
	"Amity",
	"Chaiyo",
	"Amboise",
	"Flexeiras",
	"Kanel",
	"Lakeland Village",
	"Sooke",
	"Bruay-sur-l’Escaut",
	"Borgaro Torinese",
	"Mariánské Lázně",
	"Bellefonte",
	"Kaufungen",
	"Rozhyshche",
	"Haselünne",
	"Wolverton",
	"Teutschenthal",
	"Brzeg Dolny",
	"Mercogliano",
	"Fene",
	"Tapilula",
	"Forest Hill",
	"Piketberg",
	"Borgosesia",
	"Itayanagi",
	"Gornyak",
	"Franeker",
	"Montecorvino Rovella",
	"Vail",
	"Licínio de Almeida",
	"Peißenberg",
	"Pola de Laviana",
	"Pianiga",
	"Cypress Lake",
	"Dawson Creek",
	"Gladeview",
	"Schongau",
	"Maravilla Tenejapa",
	"Tarnos",
	"Arrigorriaga",
	"Tashi",
	"Ulubey",
	"Arluno",
	"Triel-sur-Seine",
	"Byala Slatina",
	"Monte San Giovanni Campano",
	"Baras",
	"Borger",
	"Grave",
	"Monfort Heights",
	"Lipari",
	"Braselton",
	"Syston",
	"Lapeer",
	"Sulingen",
	"Yuza",
	"Herk-de-Stad",
	"Oulad Ouchchih",
	"Dunmore",
	"Crayford",
	"Cristais",
	"Georgetown",
	"Flitwick",
	"Kittanning",
	"Lucena",
	"Kūshk",
	"Nova Crixás",
	"Santa Margarita",
	"Clinton",
	"Castellbisbal",
	"Vöcklabruck",
	"Ibaretama",
	"Ribeirão Bonito",
	"Pualas",
	"Mar de Espanha",
	"Kapelle",
	"De Haan",
	"Vinings",
	"Sharon",
	"Piên",
	"Claremont",
	"Castel Goffredo",
	"El Qâa",
	"Gerpinnes",
	"Batuan",
	"Rivarolo Canavese",
	"Oakland",
	"Zhongling",
	"Fallsburg",
	"Brzeziny",
	"Lake Country",
	"Veľký Krtíš",
	"Pfarrkirchen",
	"São José do Campestre",
	"Chilca",
	"Governador Lindenberg",
	"Sim",
	"Tinglayan",
	"Staryye Atagi",
	"Sagay",
	"Dorog",
	"San Clemente",
	"Michendorf",
	"Avrig",
	"Ergolding",
	"Chiatura",
	"Frutillar",
	"Ban Laem",
	"Libertad",
	"Verde Village",
	"Uruoca",
	"Malaryta",
	"Trent Hills",
	"Möckern",
	"Sidmouth",
	"Lubaczów",
	"Kaeng Khoi",
	"Le Chambon-Feugerolles",
	"Purén",
	"Vyazemskiy",
	"Lynnfield",
	"Irondale",
	"Maglód",
	"Tizgane",
	"Weinsberg",
	"Tierra Amarilla",
	"Sainte-Marie",
	"Dorking",
	"Surkhakhi",
	"Kasba Tanora",
	"Lake Wylie",
	"Agutaya",
	"Sturgis",
	"Loganville",
	"Brugg",
	"Bassersdorf",
	"Hemsbach",
	"San Bartolo",
	"Novhorod-Siverskyi",
	"Jequeri",
	"Salobreña",
	"Lawrenceburg",
	"Sacacoyo",
	"Choró",
	"Chiquimulilla",
	"Lakeland North",
	"Grumo Appula",
	"Cambuquira",
	"West Carrollton",
	"Itaipé",
	"Kilindoni",
	"Chorleywood",
	"Oxapampa",
	"Costa de Caparica",
	"Finneytown",
	"Camabatela",
	"Ridge",
	"Guelph/Eramosa",
	"Mostardas",
	"Méricourt",
	"Glen Carbon",
	"Arhribs",
	"Sala Consilina",
	"Mutia",
	"Locri",
	"Neuhausen auf den Fildern",
	"Bretzfeld",
	"Cuisnahuat",
	"Ifs",
	"Kriftel",
	"Bershad",
	"Raismes",
	"New Hanover",
	"Marshall",
	"Medfield",
	"Berwick-Upon-Tweed",
	"Jaroměř",
	"Syasstroy",
	"Lake Tapps",
	"Samashki",
	"Spearfish",
	"Silago",
	"Mora",
	"Limburgerhof",
	"Francofonte",
	"Plan-de-Cuques",
	"East Hampton",
	"Újfehértó",
	"Gyomaendrőd",
	"Los Lagos",
	"Brignais",
	"Truro",
	"Steinheim",
	"Amos",
	"Figline Valdarno",
	"Lawaan",
	"Souakene",
	"Cedartown",
	"Ban Tom Klang",
	"Diébougou",
	"Perehinske",
	"Dubak",
	"Krupka",
	"Pecica",
	"Zawiat Moulay Brahim",
	"Teroual",
	"Provins",
	"Gorokhovets",
	"Hajdúhadház",
	"Cloquet",
	"Weigelstown",
	"Rosario del Tala",
	"Betma",
	"Lilburn",
	"South Sioux City",
	"Simeria",
	"Valverde del Camino",
	"Maryville",
	"The Nation / La Nation",
	"Taldom",
	"Bohmte",
	"Franklin",
	"Churchdown",
	"Cidade Gaúcha",
	"Olintla",
	"Wawarsing",
	"Pichilemu",
	"Mikashevichy",
	"Bamber Bridge",
	"Brunswick",
	"Villafranca de los Barros",
	"Iferhounene",
	"Sankt Veit an der Glan",
	"Maizières-lès-Metz",
	"College",
	"Fontanafredda",
	"Ascot",
	"Ashibetsu",
	"Wächtersbach",
	"L’Isle-Adam",
	"Clinton",
	"Pinheiro Machado",
	"Linnich",
	"Kalyazin",
	"Hadamar",
	"Santa Ana de Yacuma",
	"Laamarna",
	"Areal",
	"São Sebastião do Uatumã",
	"Burj al ‘Arab",
	"Lahaina",
	"Gibraleón",
	"San Francisco de Mostazal",
	"Rognac",
	"Santiago Amoltepec",
	"Peligros",
	"Saint-Jean",
	"Glendale",
	"Si Mustapha",
	"Florange",
	"Lauria Inferiore",
	"Warsop",
	"Saúde",
	"Zag",
	"Terryville",
	"Othello",
	"Santa Cruz da Baixa Verde",
	"Mineiros do Tietê",
	"Joanópolis",
	"Sant’Agata di Militello",
	"Gulf Shores",
	"Ingersoll",
	"Dorridge",
	"Avanhandava",
	"Khasanya",
	"Simplício Mendes",
	"Yulee",
	"Athens",
	"Jupiter Farms",
	"Tarboro",
	"Steinheim am der Murr",
	"San Salvador",
	"Brunsbüttel",
	"Wickliffe",
	"Lamballe",
	"Ban Muang Ngam",
	"Jilava",
	"Snezhnogorsk",
	"Qiaotouba",
	"Librazhd-Qendër",
	"Vrchlabí",
	"Kuçovë",
	"Elgin",
	"Sinalunga",
	"Altavilla Vicentina",
	"Canet-en-Roussillon",
	"Croxley Green",
	"Mercerville",
	"Tacaimbó",
	"La Homa",
	"Paullo",
	"Dover",
	"Spondon",
	"Dongyuancun",
	"Greenville",
	"Neuenburg am Rhein",
	"Borger",
	"Ələt",
	"Mato Verde",
	"Zofingen",
	"Bonita",
	"Derventa",
	"Albertirsa",
	"Charlotte",
	"Chok Chai",
	"Gangelt",
	"Andernos-les-Bains",
	"Broussard",
	"Saint-Estève",
	"Los Santos",
	"Bad Urach",
	"Winterberg",
	"Beaconsfield",
	"Dorado",
	"Olgiate Comasco",
	"Leibnitz",
	"Horndean",
	"Pieve di Soligo",
	"Aït Youssef Ou Ali",
	"Gaimersheim",
	"Herrin",
	"Hungen",
	"Milovice",
	"Kamianka-Dniprovska",
	"Blaubeuren",
	"Orzinuovi",
	"San Miguel de Salcedo",
	"Amesbury",
	"Hattem",
	"Pionerskiy",
	"Kasaishi",
	"Choctaw",
	"Tepetzintla",
	"Vejer de la Frontera",
	"Alpen",
	"Manvel",
	"Windsor Locks",
	"Ronchi dei Legionari",
	"Shilka",
	"Fonsorbes",
	"Chaval",
	"Kantang",
	"Los Alamos",
	"Oregon",
	"Candelaria",
	"Albatera",
	"Barbosa Ferraz",
	"Commerce",
	"Winkler",
	"Fehmarnsund",
	"Chauny",
	"Taylorville",
	"Ibicuitinga",
	"Riviera Beach",
	"Porto",
	"Port Neches",
	"Wetaskiwin",
	"Shchastia",
	"Jataìzinho",
	"Emiliano Zapata",
	"Ounagha",
	"Prineville",
	"Oissel",
	"Anzola dell’Emilia",
	"El Adjiba",
	"São Gonçalo do Pará",
	"Baykalsk",
	"Lakeland",
	"Peru",
	"Lauenburg",
	"Vakhrusheve",
	"Maxaranguape",
	"Dalmatovo",
	"White House",
	"Niefern-Öschelbronn",
	"Santa Bárbara de Pinto",
	"Keokuk",
	"Kalajoki",
	"Xiaping",
	"Greetland",
	"Yemva",
	"Junín de los Andes",
	"São Geraldo",
	"St. Ann",
	"Monte Compatri",
	"Jaciara",
	"Mablethorpe",
	"Lana",
	"Hirschaid",
	"Durmersheim",
	"Cardoso Moreira",
	"Oshikango",
	"Wadhraf",
	"Newtown",
	"Itiquira",
	"Brandfort",
	"Berja",
	"Seringueiras",
	"Cinisi",
	"Darfield",
	"San Mauro Pascoli",
	"Alcudia de Carlet",
	"Lavagna",
	"Wieringerwerf",
	"Duartina",
	"Cananéia",
	"Calanasan",
	"Yuzha",
	"Camposampiero",
	"Beniel",
	"Moreira Sales",
	"Tranent",
	"Galashiels",
	"Goražde",
	"Mastic Beach",
	"Rong Kwang",
	"Santol",
	"Justice",
	"Spresiano",
	"Central Elgin",
	"Tiszavasvári",
	"Graben-Neudorf",
	"San Pietro in Casale",
	"Gavardo",
	"Taguaí",
	"Anklam",
	"German Flatts",
	"Saint-Germain-lès-Arpajon",
	"Cumpăna",
	"Elfers",
	"Lewisboro",
	"Fuldatal",
	"Fulton",
	"Minneola",
	"Anna Regina",
	"Valu lui Traian",
	"Oskaloosa",
	"Buchach",
	"Santa Ana Maya",
	"Lienz",
	"Urbana",
	"Mecatlán",
	"Benifayó",
	"Grand Terrace",
	"Rielasingen-Worblingen",
	"Panama City Beach",
	"Woodland Park",
	"Teixeira Soares",
	"Menfi",
	"Rosbach vor der Höhe",
	"Beydağ",
	"Richmond",
	"Nelson Bay",
	"Jackson",
	"Russi",
	"Altenstadt",
	"Kataysk",
	"Quimperlé",
	"Saint-André-de-Cubzac",
	"Pennsville",
	"Elland",
	"El’khotovo",
	"Rottofreno",
	"Fredericksburg",
	"Neerijnen",
	"Le Haillan",
	"Carmiano",
	"Mira",
	"Revúca",
	"Muscoy",
	"Mende",
	"Lila",
	"Vysoké Mýto",
	"Estepa",
	"Ubaporanga",
	"Belmont",
	"Koriukivka",
	"Twistringen",
	"Petrov Val",
	"Edemissen",
	"Frohburg",
	"Mwingi",
	"Bolbec",
	"Florence",
	"Bernalda",
	"Annapolis Neck",
	"Lachute",
	"Xavantes",
	"Reshuijie",
	"Woolwich",
	"Oberwil",
	"La Carlota",
	"Nanzhai",
	"Jacksonville",
	"Culfa",
	"Brzeszcze",
	"Feuchtwangen",
	"Currimao",
	"Bad Windsheim",
	"Ebersberg",
	"Sheerness",
	"Ugento",
	"Astley",
	"São Romão",
	"Laguna Paiva",
	"Alkhan-Kala",
	"Endicott",
	"Tezoatlán de Segura y Luna",
	"Grünwald",
	"Bad Abbach",
	"Motta Sant’Anastasia",
	"Culcheth",
	"Niel",
	"Erkner",
	"Kamin-Kashyrskyi",
	"Jaidte Lbatma",
	"Kameshkovo",
	"Hopewell",
	"Cheney",
	"Valmadrera",
	"Lindenberg im Allgäu",
	"Stainland",
	"Ocaña",
	"San Severino Marche",
	"Palos Heights",
	"Andrelândia",
	"West Grey",
	"Kozloduy",
	"Dom Basílio",
	"Beelitz",
	"Goor",
	"Charmahīn",
	"Cedar Grove",
	"Xinyingheyan",
	"Parksville",
	"Boumalne",
	"Taviano",
	"Boone",
	"Nurmo",
	"Rainhill",
	"North St. Paul",
	"Sátão",
	"Dokkum",
	"Nobeji",
	"Wadersloh",
	"Möglingen",
	"Bickenhill",
	"Burgos",
	"Ocean Pines",
	"Valentim Gentil",
	"Le Pont-de-Claix",
	"Shichuanxiang",
	"Mortugaba",
	"Arroyo Seco",
	"Axel",
	"Culaba",
	"Gundelfingen",
	"Cornedo Vicentino",
	"Zunil",
	"Hinundayan",
	"Cowansville",
	"Rocca Priora",
	"Nina Rodrigues",
	"Paris",
	"Xinchangcun",
	"Irmo",
	"Shuangxianxiang",
	"Hnivan",
	"Bottesford",
	"Cecil",
	"Plan-les-Ouates",
	"Velten",
	"Ilicínia",
	"Sulz am Neckar",
	"Epping",
	"San Anselmo",
	"Marysville",
	"Hessisch Lichtenau",
	"Araçás",
	"Edéia",
	"Tsuruta",
	"Ulster",
	"Thame",
	"Oulad Fares",
	"Santañy",
	"Bayona",
	"Eyvānekey",
	"Oulad Aïssa",
	"Cortês",
	"Woodhaven",
	"Preußisch Oldendorf",
	"Bni Drar",
	"Brejetuba",
	"Novalukoml’",
	"Bad Freienwalde",
	"Bedford",
	"Sablé-sur-Sarthe",
	"Cêrro Grande",
	"Sever do Vouga",
	"Trophy Club",
	"Xinbocun",
	"Beryslav",
	"Romanshorn",
	"Reẕvānshahr",
	"Cornwall",
	"Riverton",
	"Santa Mariana",
	"Niepołomice",
	"Magsaysay",
	"Shepherdsville",
	"Teotepeque",
	"Teano",
	"Kálymnos",
	"Bécancour",
	"Murnau am Staffelsee",
	"Moldova Nouă",
	"Maqu",
	"Terranuova Bracciolini",
	"Iaciara",
	"São Luís do Curu",
	"Coventry",
	"Plymouth",
	"Manchester",
	"Pidigan",
	"Gladenbach",
	"Nagykáta",
	"Jardim do Seridó",
	"Waconia",
	"Stony Brook",
	"Mesker-Yurt",
	"Vardenis",
	"Saboyá",
	"Taiynsha",
	"Pfäffikon",
	"Caapiranga",
	"St. Peter",
	"Pößneck",
	"Kingsbury",
	"Saint Ives",
	"Lymm",
	"Kelso",
	"Betton",
	"Nevele",
	"Villeneuve-lès-Avignon",
	"Cuichapa",
	"North Smithfield",
	"Juvignac",
	"Bom Retiro do Sul",
	"Titisee-Neustadt",
	"Gharbia",
	"Eraclea",
	"Khvalynsk",
	"São Sebastião da Grama",
	"Elkhorn",
	"Ongwediva",
	"Lizzanello",
	"Sânnicolau Mare",
	"Setubinha",
	"Gatesville",
	"Vobkent Shahri",
	"Kavarna",
	"Las Parejas",
	"Rankweil",
	"Monte Sant’Angelo",
	"Ludwigslust",
	"Lom Sak",
	"Ried im Innkreis",
	"Oulad Amrane el Mekki",
	"Perez",
	"Goldenrod",
	"Budel",
	"Granarolo del l’Emilia",
	"Villa Literno",
	"Virginia",
	"Jüterbog",
	"Capdepera",
	"Logan",
	"Jork",
	"Lontras",
	"Monteforte Irpino",
	"Nkheila",
	"Chino Valley",
	"Inverell",
	"Gisors",
	"Lakeside",
	"Newington Forest",
	"Blue Ash",
	"Alto do Rodrigues",
	"Châteaubriant",
	"Lewisburg",
	"Wilmington",
	"Egelsbach",
	"Occhiobello",
	"Tyngsborough",
	"Teningen",
	"Bitetto",
	"Mèze",
	"Harrison",
	"Yakouren",
	"Holly Hill",
	"Heddesheim",
	"Jardim Alegre",
	"Lansdowne",
	"Foča",
	"Hatillo de Loba",
	"Ain Kansara",
	"Rushall",
	"Pont-du-Château",
	"Diósd",
	"New Baltimore",
	"Rio Paranaíba",
	"Palmares Paulista",
	"Chambray-lès-Tours",
	"Gulfport",
	"Forestville",
	"Vidauban",
	"Derby",
	"Kewanee",
	"Bueu",
	"Orange City",
	"Sawādah",
	"Malloussa",
	"Acalá del Río",
	"Heythuysen",
	"Lakeland Highlands",
	"Jacinto",
	"La Motte-Servolex",
	"Matina",
	"Farciennes",
	"Ulverston",
	"Bastrop",
	"Anamã",
	"Županja",
	"Nordstemmen",
	"Sernovodsk",
	"Gladstone",
	"Khem Karan",
	"Cookstown",
	"Uren",
	"Wietmarschen",
	"Berilo",
	"Sant’Ambrogio di Valpolicella",
	"Spilimbergo",
	"Mirante da Serra",
	"Bibbiena",
	"Hagenow",
	"Bodaybo",
	"Teotlaltzingo",
	"Winnetka",
	"Markham",
	"Mixtla de Altamirano",
	"Gravenhurst",
	"Sākib",
	"Marion",
	"Torri di Quartesolo",
	"Aj Jourf",
	"‘Anadān",
	"Leon Valley",
	"Joppatowne",
	"West Plains",
	"Lehre",
	"Delhi",
	"Oosterbroek",
	"Leutenbach",
	"Winchester",
	"New Mills",
	"Enns",
	"Marumori",
	"Linkenheim-Hochstetten",
	"Saint-Saulve",
	"Hexham",
	"Dărmăneşti",
	"Monóvar",
	"Newcastle",
	"New Kensington",
	"Maraial",
	"Sakawa",
	"Nembro",
	"Córdoba",
	"Stradella",
	"Weinfelden",
	"Beatrice",
	"Tzintzuntzán",
	"Zeuthen",
	"Brieselang",
	"Prieska",
	"Poquoson",
	"Itanhomi",
	"Tortoreto",
	"Tholey",
	"Arth",
	"Taurisano",
	"Antônio Cardoso",
	"Clayton",
	"Rovinari",
	"Piripá",
	"Casièr",
	"Iguaraci",
	"Perth East",
	"Vecchiano",
	"Gaildorf",
	"St. Marys",
	"Velddrif",
	"Bad Sassendorf",
	"Citlaltépec",
	"Götzis",
	"Na Wa",
	"Ottawa",
	"Larkspur",
	"Kampenhout",
	"Cottonwood",
	"Mays Chapel",
	"Shady Hills",
	"Vulcăneşti",
	"Vilsbiburg",
	"Vălenii de Munte",
	"Palestina",
	"Bankya",
	"Węgorzewo",
	"Boloso",
	"Lincolnwood",
	"Biri",
	"Atripalda",
	"Burladingen",
	"Botlikh",
	"Axixá",
	"Arkansas City",
	"Dăbuleni",
	"Sapna",
	"Saint-Avé",
	"Saubara",
	"Paulista",
	"Querência do Norte",
	"North Castle",
	"Elon",
	"Palmer",
	"Lumberton",
	"Emstek",
	"Perleberg",
	"Massena",
	"Aleksandrovsk",
	"West Monroe",
	"Parker",
	"Château-Gontier",
	"Antônio Gonçalves",
	"Fourmies",
	"Mulungu do Morro",
	"Wolgast",
	"Ebéjico",
	"Parkes",
	"Gatumba",
	"Ahram",
	"Krasnyy Yar",
	"Pedras de Maria da Cruz",
	"Batesville",
	"Prince Rupert",
	"Amherst",
	"Grenada",
	"Mineral del Monte",
	"Balbalan",
	"Loreto",
	"Kibungo",
	"Canápolis",
	"Ōsako",
	"Carmo da Cachoeira",
	"Mettlach",
	"Neuenrade",
	"Villamartín",
	"Portoferraio",
	"Castelnaudary",
	"Bellegarde-sur-Valserine",
	"Suchanino",
	"North Union",
	"Isola del Liri",
	"Ishpeming",
	"Jesup",
	"Ringwood",
	"Zhengdong",
	"Üllő",
	"Neustadt",
	"Oberschleißheim",
	"Overlea",
	"Speedway",
	"Lagoa do Ouro",
	"Montegrotto Terme",
	"Santa Fé",
	"Enumclaw",
	"Lonate Pozzolo",
	"Rijkevorsel",
	"Santa Lucía",
	"Uzyn",
	"Roding",
	"Mettingen",
	"Ilmajoki",
	"Zwönitz",
	"Unterföhring",
	"Ban Bo Luang",
	"Nový Bor",
	"Neosho",
	"Wells Branch",
	"North Greenbush",
	"Leominster",
	"Hirao",
	"Rehoboth",
	"Laichingen",
	"Lincoln City",
	"Prévost",
	"Essenbach",
	"Planegg",
	"Liperi",
	"Highlands",
	"Pacé",
	"Steha",
	"Northlake",
	"Guebwiller",
	"Nußloch",
	"Eatontown",
	"Bolaños de Calatrava",
	"Belém de Maria",
	"Issum",
	"Newport",
	"Albox",
	"Lauffen am Neckar",
	"New Garden",
	"Nova Gradiška",
	"Colares",
	"São Francisco do Maranhão",
	"Bad Vöslau",
	"Eura",
	"Schwieberdingen",
	"Boskovice",
	"Cesson",
	"Harwich",
	"Solliès-Pont",
	"Ladenburg",
	"Aubière",
	"Harrislee",
	"Mayate",
	"Bajiao",
	"Falmouth",
	"Congonhal",
	"Cangas de Narcea",
	"Tabapuã",
	"Vehkalahti",
	"Sainte-Adèle",
	"Capela do Alto Alegre",
	"Freeport",
	"Webb City",
	"Pulsano",
	"Auchel",
	"Tábua",
	"Islamey",
	"San Valentino Torio",
	"Capela de Santana",
	"Cherlak",
	"Rosdorf",
	"Leingarten",
	"Ichinomiya",
	"Lebon Régis",
	"Saint-Cyr-sur-Mer",
	"Vedène",
	"Sassenage",
	"Nová Dubnica",
	"Curití",
	"Woodward",
	"Knowsley",
	"Bicske",
	"Lower Pottsgrove",
	"Melissa",
	"Brock Hall",
	"Drouin",
	"Uacu Cungo",
	"Weston",
	"Rogerstone",
	"Socuéllamos",
	"James Island",
	"Kingsburg",
	"Oulad Daoud",
	"Palmácia",
	"Caimito",
	"San Carlos Yautepec",
	"Oiba",
	"Pepperell",
	"Brwinów",
	"Liuba",
	"Ventania",
	"Comarnic",
	"Xichú",
	"Denkendorf",
	"Guimarães",
	"Margherita di Savoia",
	"Vosselaar",
	"Mestrino",
	"Platteville",
	"Nonoai",
	"Susegana",
	"Rio do Pires",
	"Laren",
	"Eureka",
	"Lovington",
	"Ban Bang Yai",
	"Son Servera",
	"Jacala",
	"Priolo Gargallo",
	"Kentville",
	"Baohe",
	"Weiz",
	"Baxiangshan",
	"Chevigny-Saint-Sauveur",
	"Gambettola",
	"Little Chute",
	"Bainbridge",
	"Tiszakécske",
	"Saint-Martin-Boulogne",
	"Welver",
	"Erfelek",
	"Wang Saphung",
	"San Andrés de Llevaneras",
	"San Antonio Palopó",
	"Xincheng",
	"Sedro-Woolley",
	"Minden",
	"Flores de Goiás",
	"Ardanuç",
	"Oestrich-Winkel",
	"Novoīshīmskīy",
	"Alfonsine",
	"Grantsville",
	"Somerville",
	"Middle Valley",
	"São Miguel das Matas",
	"Oswaldtwistle",
	"Alavus",
	"Castelginest",
	"Fiume Veneto",
	"Loano",
	"Opmeer",
	"Ait Amar",
	"Toropets",
	"Ipaumirim",
	"Dário Meira",
	"Union Park",
	"Havixbeck",
	"Don Sak",
	"Joaquim Távora",
	"Martuni",
	"South Charleston",
	"Fairfax Station",
	"Souq Jamaa Fdalate",
	"Sowerby Bridge",
	"São Pedro da Água Branca",
	"Huité",
	"Corbas",
	"Thiers",
	"Lanuza",
	"Al Fayd",
	"Leeds",
	"Holiday City-Berkeley",
	"Beniaján",
	"Bad Fallingbostel",
	"East Bethel",
	"Richland",
	"Villefranche-de-Rouergue",
	"Southwick",
	"Festus",
	"Aperibé",
	"Giffoni Valle Piana",
	"Valentigney",
	"Riverview",
	"Villepreux",
	"Altstätten",
	"Bath",
	"Saint-Gaudens",
	"Meitingen",
	"Nong Bua",
	"Gainza",
	"Smithfield",
	"Comasagua",
	"Kőszeg",
	"Swan Hill",
	"Blaricum",
	"Korb",
	"Cave",
	"Galvarino",
	"Mainvilliers",
	"Weatherford",
	"Miller Place",
	"Bilovodsk",
	"Lichtenstein",
	"Longuenesse",
	"Sajószentpéter",
	"Ponteland",
	"Betânia",
	"El Arba Des Bir Lenni",
	"Rapho",
	"Beauharnois",
	"Anao",
	"Ain Beida",
	"Les Îles-de-la-Madeleine",
	"Zlaté Moravce",
	"Issoudun",
	"Josefina",
	"Nyírbátor",
	"Doesburg",
	"Princetown",
	"Kissing",
	"Volosovo",
	"Campo Alegre",
	"Spring Lake",
	"Bømlo",
	"Poranga",
	"Holyhead",
	"Krasnousol’skiy",
	"Patu",
	"Folkston",
	"Sárbogárd",
	"Macedonia",
	"Casteldaccia",
	"Hildburghausen",
	"Tlacolulan",
	"Carnot-Moon",
	"Jerome",
	"Kasaji",
	"Pritzwalk",
	"Geisenheim",
	"Prairie Ridge",
	"Matmata",
	"Union",
	"Montrose",
	"Hamilton Square",
	"Erbaocun",
	"Abergele",
	"Moncks Corner",
	"Ghabāghib",
	"Goulds",
	"Killarney",
	"Dinkelsbühl",
	"Alijó",
	"Caldogno",
	"North Lebanon",
	"Palos de la Frontera",
	"Santa Flavia",
	"Bátonyterenye",
	"Mykhailivka",
	"Chaumont-Gistoux",
	"Santoña",
	"Newton",
	"Cedro",
	"Janów Lubelski",
	"Maltby",
	"Jiajin",
	"Gardone Val Trompia",
	"Cogolin",
	"Villebon-sur-Yvette",
	"Garden City",
	"Somersworth",
	"Serravalle Pistoiese",
	"Bordentown",
	"Diez",
	"Norton",
	"Bad Dürrenberg",
	"Tigzirt",
	"Ellwood City",
	"Glenwood Springs",
	"Great Wyrley",
	"Greensburg",
	"Tucson Estates",
	"Pavlikeni",
	"Negreşti-Oaş",
	"Macclenny",
	"Echelon",
	"East Grand Rapids",
	"Drolshagen",
	"Newberry",
	"Cernay",
	"Sidi Amer El Hadi",
	"Villeneuve-Tolosane",
	"La Puebla del Río",
	"Chuquicamata",
	"Arenzano",
	"Çüngüş",
	"Myjava",
	"Sandy",
	"Onet Village",
	"Lansing",
	"Tizi Nisly",
	"Campobello di Mazara",
	"Mondaí",
	"Brookhaven",
	"Nowe Miasto Lubawskie",
	"Winfield",
	"Finale Ligure",
	"Olivenza",
	"Norwalk",
	"Nambour",
	"West Deer",
	"Sassenburg",
	"Camp Pendleton South",
	"Hudson",
	"Clermont",
	"Qazmalar",
	"Belp",
	"Vaux-le-Pénil",
	"Spencer",
	"Pozharan",
	"Robinson",
	"San Michele al Tagliamento",
	"Newport East",
	"Pilis",
	"Portales",
	"Castenedolo",
	"Bechloul",
	"Miesbach",
	"Amelia",
	"Downham Market",
	"Maniago",
	"Meghraj",
	"Wellington North",
	"Ploufragan",
	"St. Andrews",
	"Veurne",
	"Torton",
	"São Sebastião de Lagoa de Roça",
	"Port Washington",
	"Somerville",
	"Wootton",
	"Villa Aldama",
	"Maxhütte-Haidhof",
	"Waimea",
	"Lendinara",
	"Eijsden",
	"Arkadak",
	"Green River",
	"Tremonton",
	"Ibirá",
	"Terra Alta",
	"Bitritto",
	"Osterhofen",
	"Carleton Place",
	"Vendas Novas",
	"Santa Cruz Itundujia",
	"Ivybridge",
	"Palmilla",
	"Ban Phan Don",
	"Cottage Grove",
	"Pellezzano",
	"Ojuelos de Jalisco",
	"Ban Bang Lamung",
	"Neuville-en-Ferrain",
	"Inhangapi",
	"Upper",
	"Büdelsdorf",
	"Sárospatak",
	"Macerata Campania",
	"Ukrainsk",
	"Novaya Lyalya",
	"Mazzarino",
	"Guidel",
	"Valley Falls",
	"Stafford",
	"El Palmar",
	"Ban Bang Toei",
	"Bopfingen",
	"Berkley",
	"Nacozari Viejo",
	"Peixe",
	"Alamedin",
	"Robertsville",
	"Matino",
	"Saint-Pierre-du-Perray",
	"Delavan",
	"Langgöns",
	"Horodnia",
	"Abasolo",
	"Sandown",
	"Kingston",
	"Patterson",
	"Vineyard",
	"Elgóibar",
	"Sogndal",
	"Zetel",
	"Coswig",
	"Mülheim-Kärlich",
	"Kuchinarai",
	"Ovidiopol",
	"Isperih",
	"Richfield",
	"Port Lavaca",
	"Whistler",
	"Macajuba",
	"Moulay Driss Zerhoun",
	"Otsego",
	"Malvern",
	"Cricova",
	"Monschau",
	"Cadillac",
	"Holešov",
	"Malargüe",
	"Worb",
	"Inzago",
	"Santiago",
	"Lake Station",
	"Healdsburg",
	"Brighton",
	"São Félix do Araguaia",
	"Tolcayuca",
	"Sandy",
	"Bocaina",
	"East Renton Highlands",
	"East Whiteland",
	"Rodenbach",
	"Casino",
	"Scorniceşti",
	"Red Bank",
	"Brown Deer",
	"Ban Wiang Phan",
	"Azcoitia",
	"Crestwood",
	"Grants",
	"Wormer",
	"Gouvêa",
	"Opatija",
	"Moldava nad Bodvou",
	"Rio dos Cedros",
	"Céu Azul",
	"Dolo Bay",
	"Caetanópolis",
	"Neuhausen am Rheinfall",
	"Chaplygin",
	"Wrentham",
	"Prudhoe",
	"Überherrn",
	"Haradok",
	"Malibu",
	"Porto-Vecchio",
	"Pornichet",
	"Alto Rio Doce",
	"Castano Primo",
	"Smyrna",
	"Conceição dos Ouros",
	"Mudgee",
	"Tifni",
	"Codigoro",
	"Óbidos",
	"Mareeba",
	"Campagnano di Roma",
	"Cisneros",
	"Halásztelek",
	"Benedito Novo",
	"Baranivka",
	"Market Drayton",
	"Labin",
	"Venosa",
	"Bucyrus",
	"Mömbris",
	"Zayukovo",
	"Santo Antônio do Jacinto",
	"La Roche-sur-Foron",
	"Cafayate",
	"Medina Sidonia",
	"Valencia West",
	"Viera East",
	"Phon",
	"Lommedalen",
	"San Pedro",
	"Guspini",
	"Târgu Lăpuş",
	"Obernai",
	"Hauzenberg",
	"Cuers",
	"Sainte-Savine",
	"Tiny",
	"Irupi",
	"Norfolk",
	"Kankaanpää",
	"Sítio do Quinto",
	"Riolândia",
	"Trofarello",
	"Cologno al Serio",
	"Tring",
	"Tomball",
	"Gänserndorf",
	"Hawera",
	"Lieksa",
	"Dolinsk",
	"Jaqueira",
	"Vlašim",
	"Padiham",
	"Florida City",
	"Étaples",
	"Arataca",
	"Cisternino",
	"Courrières",
	"Zero Branco",
	"Vasylkivka",
	"Wanaque",
	"Englefield Green",
	"Hollabrunn",
	"Scotts Valley",
	"Góra",
	"Jarābulus",
	"São João do Manhuaçu",
	"Stokke",
	"José María Morelos",
	"Darnétal",
	"Puente Nacional",
	"Robstown",
	"Mezdra",
	"Georgetown",
	"Sunninghill",
	"Krujë",
	"Rurrenabaque",
	"Apen",
	"Lake Los Angeles",
	"Aesch",
	"Choppington",
	"Sinzheim",
	"Wolnzach",
	"Wolmirstedt",
	"Ross on Wye",
	"Carbonera",
	"Raubling",
	"North Tidworth",
	"Arlington",
	"Wanderlândia",
	"Magdagachi",
	"Teixeiras",
	"Al M’aziz",
	"Vauvert",
	"Somerset",
	"Pimenteiras",
	"Bonito de Santa Fé",
	"Nemyriv",
	"Río Colorado",
	"Velké Meziříčí",
	"Matca",
	"Elma",
	"Excelsior Springs",
	"Diamond Springs",
	"Lundazi",
	"Gerstetten",
	"Kalaoa",
	"Deutschlandsberg",
	"Gräfenhainichen",
	"Sint Anthonis",
	"Laredo",
	"Saverne",
	"Dudley",
	"Poggio a Caiano",
	"Portomaggiore",
	"Angicos",
	"Carver",
	"Cordele",
	"Orchha",
	"Musile di Piave",
	"Mendota",
	"Grafton",
	"Tegueste",
	"Möhnesee",
	"Tamezmout",
	"Athol",
	"Jódar",
	"Plymouth",
	"Pacoti",
	"Isola della Scala",
	"Eufaula",
	"Frenštát pod Radhoštěm",
	"Glen Rock",
	"Cocoa Beach",
	"Altdorf",
	"Prymorsk",
	"Ravanusa",
	"Aniche",
	"Taormina",
	"Benaguacil",
	"Motegi",
	"Nossa Senhora dos Milagres",
	"Mena",
	"Ubaí",
	"Bagamanoc",
	"Oliva",
	"Campo do Meio",
	"Bullas",
	"Billerbeck",
	"Nyazepetrovsk",
	"Ouro Branco",
	"Mitai",
	"Clute",
	"Rodeio",
	"Tamalpais-Homestead Valley",
	"Gander",
	"Allonnes",
	"Ovada",
	"Port St. John",
	"Eningen unter Achalm",
	"Caorle",
	"Chantilly",
	"Hinwil",
	"Hazerswoude-Dorp",
	"Southborough",
	"Isaszeg",
	"Septèmes-les-Vallons",
	"Serra do Salitre",
	"Endwell",
	"Udachnyy",
	"Guaiçara",
	"Xiaoba",
	"Salaya",
	"Redentora",
	"Iver",
	"Imaruí",
	"Lyuban’",
	"Diao’ecun",
	"Sant Joan de Vilatorrada",
	"Esquipulas Palo Gordo",
	"Tabontabon",
	"Plano",
	"Madison Heights",
	"Port Morant",
	"Huandacareo",
	"Kuřim",
	"Rudersberg",
	"Guthrie",
	"Dranesville",
	"Royal Wootton Bassett",
	"Rothesay",
	"Pilar",
	"Burgthann",
	"Schwaigern",
	"El Campo",
	"Varzaneh",
	"Fortaleza dos Nogueiras",
	"Trecastagni",
	"Videle",
	"Whitburn",
	"Annoeullin",
	"Mala Vyska",
	"Bonate di Sopra",
	"Oulad Friha",
	"Panajachel",
	"Drawsko Pomorskie",
	"Budakalász",
	"Accokeek",
	"Mistelbach",
	"Gravatal",
	"Svidník",
	"Saint Helena Bay",
	"Rangsdorf",
	"Krynica",
	"Doornspijk",
	"Kalmiuske",
	"Brock",
	"Kirchhundem",
	"Evanston",
	"Stupava",
	"Nzeto",
	"Tudela",
	"Dautphe",
	"Eccleston",
	"Randaberg",
	"Anatolí",
	"Hipperholme",
	"Vernouillet",
	"Itabirinha de Mantena",
	"Möhlin",
	"Sidi Moussa Ben Ali",
	"Bad Nenndorf",
	"Teulada",
	"Walldürn",
	"Schöningen",
	"Bytča",
	"Calheta",
	"Pīr Bakrān",
	"Oignies",
	"Gravelines",
	"Belmonte Mezzagno",
	"Camisano Vicentino",
	"Derdara",
	"Chegem Vtoroy",
	"Pontiac",
	"Griswold",
	"Dingman",
	"Stonehaven",
	"Lenvik",
	"Cachoeira de Minas",
	"Sidi Kasem",
	"La Unión",
	"Paranatama",
	"Summerland",
	"Bradwell",
	"Kuruman",
	"Franklin",
	"Gold Canyon",
	"Selsey",
	"Batemans Bay",
	"Likhoslavl",
	"Den Chai",
	"Firminópolis",
	"Boscotrecase",
	"Kem",
	"Antônio Carlos",
	"Dzouz",
	"Ulricehamn",
	"Shengping",
	"Oñate",
	"Diepenveen",
	"Cedarburg",
	"Săcueni",
	"Claiborne",
	"Temamatla",
	"Kenzhe",
	"Sursee",
	"’Aïn Roua",
	"Putnam Valley",
	"La Riche",
	"Waltikon",
	"De Panne",
	"Langelsheim",
	"North Middleton",
	"Stollberg",
	"Werther",
	"Ézanville",
	"Ghinda’e",
	"Peiting",
	"Beachwood",
	"Seven Hills",
	"Siniscola",
	"Upper Montclair",
	"Rutesheim",
	"Epazoyucan",
	"Höhenkirchen-Siegertsbrunn",
	"Tadley",
	"Ciechocinek",
	"Harsum",
	"Martinsville",
	"Sesto Calende",
	"Cranleigh",
	"Recke",
	"Val-des-Monts",
	"Paranacity",
	"Jucati",
	"Cerrillos",
	"Elk City",
	"Bouabout",
	"Satuek",
	"Tawnza",
	"Andraitx",
	"Riesi",
	"Holywood",
	"Mulungu",
	"Leatherhead",
	"Yuasa",
	"Scottdale",
	"Taché",
	"Severnyy",
	"Grobbendonk",
	"Sablan",
	"Raesfeld",
	"Prinsenbeek",
	"Silver City",
	"Upper Uwchlan",
	"Wambrechies",
	"Duas Barras",
	"Resende Costa",
	"Melito di Porto Salvo",
	"Agareb",
	"Douar Trougout",
	"Glückstadt",
	"Ferreiros",
	"Wakefield",
	"Wittingen",
	"Esil",
	"Dolores",
	"Whitchurch",
	"Montanhas",
	"Drezna",
	"Rheinau",
	"Omaruru",
	"Kin",
	"Mitchellville",
	"Lézignan-Corbières",
	"Cholpon-Ata",
	"Carmo da Mata",
	"Bannewitz",
	"Suyo",
	"Roncador",
	"Plankstadt",
	"Alto Garças",
	"Sântana",
	"Sebastião Laranjeiras",
	"Normandia",
	"Târgu Ocna",
	"Saint-Cyprien",
	"Kuriyama",
	"Castelvetro di Modena",
	"Spirit Lake",
	"Woodland Park",
	"Barbadanes",
	"Masanasa",
	"Largs",
	"Chorrochó",
	"Tagta",
	"Roca Sales",
	"Scharbeutz",
	"Brattleboro",
	"Padre Burgos",
	"Bom Jesus",
	"Creazzo",
	"Xambioá",
	"Panazol",
	"Zvenigovo",
	"Valdez",
	"Bridgeton",
	"Lower Gwynedd",
	"Lokhvytsya",
	"Montopoli in Val d’Arno",
	"Nova Laranjeiras",
	"Mexico",
	"Guayabal",
	"Myronivka",
	"Uničov",
	"Blundellsands",
	"Santa Maria",
	"Archdale",
	"Balve",
	"Rothenburg ob der Tauber",
	"Coronel Dorrego",
	"Scalea",
	"Ostrhauderfehn",
	"Zierikzee",
	"Amnéville",
	"Yakymivka",
	"Divinolândia",
	"Marktheidenfeld",
	"Lurate Caccivio",
	"Utiel",
	"Crikvenica",
	"Lutry",
	"Domažlice",
	"Bogalusa",
	"Center",
	"Paceco",
	"Lenzburg",
	"Poldasht",
	"Buriti do Tocantins",
	"El Khemis des Beni Chegdal",
	"Ain Legdah",
	"Newport",
	"Hanover",
	"Pfastatt",
	"Elwood",
	"Muquém de São Francisco",
	"Cerese",
	"Ochsenfurt",
	"Santiago del Teide",
	"Laracha",
	"Aginskoye",
	"Florham Park",
	"Florennes",
	"Ahlaf",
	"Trinity",
	"Mack",
	"Geisenfeld",
	"Klyetsk",
	"Lahfayr",
	"Montmagny",
	"Kostinbrod",
	"Glenfield",
	"Cornate d’Adda",
	"Jaguari",
	"Roma",
	"Acuítzio del Canje",
	"Mountain Top",
	"Chkalovsk",
	"Port Salerno",
	"Uraí",
	"Schipluiden",
	"Castelli Calepio",
	"Avigliano",
	"Alto Paraguai",
	"Briançon",
	"Castel Mella",
	"Kyjov",
	"Magalia",
	"Sales Oliveira",
	"Nieuwenhagen",
	"Retie",
	"Ḑurumā",
	"Gerasdorf bei Wien",
	"Karlsdorf-Neuthard",
	"Welzheim",
	"Maxéville",
	"Phanat Nikhom",
	"Naliya",
	"Egersund",
	"Gelves",
	"Hanover",
	"Magnolia",
	"Emmaus",
	"Holíč",
	"Moita Bonita",
	"Amdel",
	"Capitão de Campos",
	"Bad Schwalbach",
	"Racale",
	"Gracemere",
	"Piazzola sul Brenta",
	"Jackson",
	"Ban Bo Phlap",
	"Nanakuli",
	"Byerazino",
	"Hejiaji",
	"Boldeşti-Scăeni",
	"Resende",
	"Écaussinnes-Lalaing",
	"Saint-Junien",
	"Lençóis",
	"Mamakating",
	"Webster",
	"Riorges",
	"Venafro",
	"Benisa",
	"Oraviţa",
	"La Maddalena",
	"Körmend",
	"Fagundes",
	"Richland",
	"Hasbergen",
	"Douar Oulad Naoual",
	"Laqraqra",
	"Eidsberg",
	"Elizabethtown",
	"East Windsor",
	"Mancha Real",
	"Botticino Sera",
	"Stelle",
	"Dinard",
	"Show Low",
	"Nikel",
	"Trostberg an der Alz",
	"Erin",
	"Jimaní",
	"Aratuba",
	"Duque Bacelar",
	"Wildau",
	"Acate",
	"Cadolzburg",
	"Kall",
	"Flawil",
	"Lamsabih",
	"Estiva",
	"Douchy-les-Mines",
	"Douar Sgarta",
	"Woods Cross",
	"Curimatá",
	"Baldock",
	"Belén",
	"Byram",
	"North Bellport",
	"Chapantongo",
	"Fort Bliss",
	"Gentio do Ouro",
	"Sorsk",
	"Highland",
	"Yuryuzan",
	"Timahdit",
	"Kastav",
	"Kennebunk",
	"Lyndon",
	"Adendorf",
	"Cazzago San Martino",
	"Coveñas",
	"Eckington",
	"Abaiara",
	"Waidhofen an der Ybbs",
	"Alassio",
	"Salisbury",
	"Horbury",
	"Hailey",
	"Snovsk",
	"Jamaat Shaim",
	"Scottsburg",
	"Saint-Hilaire-de-Riez",
	"Suhr",
	"Chassieu",
	"Portes-lès-Valence",
	"Sant’Ilario d’Enza",
	"Schönaich",
	"Kingsnorth",
	"Urbana",
	"Lijiacha",
	"Schiller Park",
	"Fenton",
	"Louvres",
	"Lajosmizse",
	"Alcântaras",
	"Lunenburg",
	"Bayou Blue",
	"Chekmagush",
	"Los Alamitos",
	"Destrehan",
	"Salcedo",
	"Shevington",
	"Minooka",
	"Cividale del Friuli",
	"Burrel",
	"Terra de Areia",
	"Gardere",
	"Talaigua Nuevo",
	"Călan",
	"Tilmi",
	"Kiáto",
	"Monte Quemado",
	"Cascades",
	"Kincardine",
	"Pôrto Firme",
	"Hillsborough",
	"Lansing",
	"El Ghourdane",
	"Onoto",
	"Tsqaltubo",
	"Gárdony",
	"Gardnerville Ranchos",
	"Sankt Johann im Pongau",
	"Gornozavodsk",
	"Collegedale",
	"Herbolzheim",
	"Summerfield",
	"Great Harwood",
	"Nueva Helvecia",
	"Agcogon",
	"Humahuaca",
	"Vila Franca do Campo",
	"Oxted",
	"Lang Suan",
	"Yangirabot",
	"Caotan",
	"Lakeville",
	"Cepagatti",
	"Soham",
	"Snyder",
	"Anguera",
	"Tarrytown",
	"South Normanton",
	"Viera West",
	"Bang Ban",
	"Wyandanch",
	"Fairfield",
	"Eugenópolis",
	"Mount Sterling",
	"Poção",
	"Belén",
	"Granby",
	"Ravenna",
	"Catskill",
	"Ipaporanga",
	"Bellmawr",
	"Makoua",
	"Touama",
	"Campos del Puerto",
	"Grigny",
	"Bojacá",
	"Ouaklim Oukider",
	"Tortolì",
	"Sidi Abdellah Ben Taazizt",
	"Lardero",
	"San Giustino",
	"Angola",
	"Alatyr",
	"São Jerônimo da Serra",
	"Charouine",
	"Usmate Velate",
	"Aramari",
	"Coshocton",
	"Floresta Azul",
	"Rumburk",
	"La Chapelle-Saint-Mesmin",
	"Campo Redondo",
	"Tarare",
	"Neede",
	"Passy",
	"Mendota Heights",
	"Palmares do Sul",
	"Iuiú",
	"Bleiswijk",
	"Groß-Enzersdorf",
	"Wantage",
	"Haysville",
	"Nauheim",
	"Mülsen",
	"New Britain",
	"Avelino Lopes",
	"Tega Cay",
	"Zelhem",
	"Ichinohe",
	"Amherst",
	"Dammartin-en-Goële",
	"Dent",
	"Paramoti",
	"Chantepie",
	"Søgne",
	"Cislago",
	"Krosno Odrzańskie",
	"Sant’Agata de’ Goti",
	"Eisenberg",
	"Imuris",
	"Villerupt",
	"Hucclecote",
	"Etropole",
	"Angelim",
	"Maryport",
	"Burtonwood",
	"Honmachi",
	"Bailin",
	"Haddonfield",
	"Abjīj",
	"São Domingos",
	"Iazizatene",
	"Groton",
	"Fiľakovo",
	"Westerkappeln",
	"Illintsi",
	"Vieux-Condé",
	"Beachwood",
	"Bradford",
	"Prien am Chiemsee",
	"North Vernon",
	"Samford",
	"Kaset Wisai",
	"Nhandeara",
	"Irineópolis",
	"Štúrovo",
	"Leicester",
	"Getulina",
	"Travilah",
	"Sairé",
	"Le Pradet",
	"Kaneohe Station",
	"Winton",
	"Boguchar",
	"Măgurele",
	"Presidente Vargas",
	"Kleinblittersdorf",
	"Celina",
	"Urubici",
	"Hartsville",
	"Tha Chang",
	"Canyon Lake",
	"Sant’Antìoco",
	"North Dundas",
	"Aulla",
	"El Playón",
	"Marcali",
	"Oberderdingen",
	"Križevci",
	"Drochtersen",
	"Pirangi",
	"Piranhas",
	"Ulrum",
	"Burkburnett",
	"Lighthouse Point",
	"Eunice",
	"Monmouth",
	"Tetyushi",
	"Sonseca",
	"Joquicingo",
	"Mineral de Angangueo",
	"Montlouis-sur-Loire",
	"Matias Cardoso",
	"Hallbergmoos",
	"Jeseník",
	"Santa Cruz",
	"Edd",
	"Douar Jwalla",
	"Saint-Jean-de-Védas",
	"Greentree",
	"Engenheiro Caldas",
	"Beaumont-sur-Oise",
	"Drākshārāma",
	"Sagada",
	"Zaouiet Says",
	"Friedrichsthal",
	"Saltcoats",
	"Wellesley",
	"Capilla del Monte",
	"Apt",
	"Estevan",
	"Le Luc",
	"Polistena",
	"Portland",
	"Barton upon Humber",
	"Therwil",
	"North Saanich",
	"Lauingen",
	"Plaridel",
	"Westview",
	"Otar",
	"San Francisco",
	"Bewdley",
	"Sanjiaocheng",
	"Héricourt",
	"Majdal Shams",
	"Balingoan",
	"DuBois",
	"Lamesa",
	"Vire",
	"Korablino",
	"North Logan",
	"Berlin",
	"Goldach",
	"Morazán",
	"Cerreto Guidi",
	"Boguchany",
	"Boujediane",
	"Waupun",
	"Jeumont",
	"Kursavka",
	"Týrnavos",
	"Fino Mornasco",
	"Florestópolis",
	"Big Lake",
	"Hillsdale",
	"Divisópolis",
	"Calçado",
	"Broomall",
	"San José de Aerocuar",
	"Santa Croce Camerina",
	"Jamapa",
	"Eichenzell",
	"Gloucester City",
	"Chulym",
	"Sansare",
	"Armstrong",
	"Cabestany",
	"Qaşr-e Shīrīn",
	"Herrsching am Ammersee",
	"Shahmīrzād",
	"Grand Rapids",
	"Alto Parnaíba",
	"Staryya Darohi",
	"Emsworth",
	"Santa Ana Huista",
	"Rychnov nad Kněžnou",
	"Bondoufle",
	"Santa Inês",
	"Braunfels",
	"Valkeala",
	"Lorch",
	"Xintianfeng",
	"Montechiarugolo",
	"Gengenbach",
	"Trofaiach",
	"Hawaiian Paradise Park",
	"Rianxo",
	"Arizona City",
	"Tagoloan",
	"Barros Cassal",
	"Kirchseeon",
	"Sušice",
	"Centenário do Sul",
	"Ebreichsdorf",
	"Seymour",
	"Belzig",
	"Kariat Ben Aouda",
	"Hormigueros",
	"Congaz",
	"Humpolec",
	"Camp Verde",
	"Ilfracombe",
	"Handewitt",
	"Weddington",
	"Si Wilai",
	"Cameri",
	"Areiópolis",
	"Corleone",
	"Hârlău",
	"Ogose",
	"Ridgefield",
	"Vorë",
	"Regen",
	"Cunha Porã",
	"Knezha",
	"Poulsbo",
	"Nantucket",
	"Miastko",
	"Zakamensk",
	"Stayton",
	"Springdale",
	"Enebakk",
	"Trevignano",
	"Capena",
	"Varre-Sai",
	"Byelaazyorsk",
	"Tinchlik",
	"Peschiera del Garda",
	"Brookings",
	"Xinyaoshang",
	"Damascus",
	"Três Cachoeiras",
	"Aizoáin",
	"Osterwieck",
	"Betzdorf",
	"Baley",
	"Red Hook",
	"Shepperton",
	"Flöha",
	"Niles",
	"Wisła",
	"Khlung",
	"Takad Sahel",
	"Longtaixiang",
	"Buxerolles",
	"Şā’īn Qal‘eh",
	"Fuller Heights",
	"Bir Ghbalou",
	"Gucheng",
	"Tolna",
	"Leers",
	"Hikawadai",
	"Kirensk",
	"Acari",
	"Potengi",
	"Bertinoro",
	"Mango",
	"Primeiro de Maio",
	"Fitzgerald",
	"Barra do Sul",
	"Parthenay",
	"Huntingdon",
	"Hagen im Bremischen",
	"Inácio Martins",
	"Selma",
	"Warka",
	"Fronteiras",
	"Campolongo Maggiore",
	"Satellite Beach",
	"Wangjiabian",
	"Wen’anyi",
	"Mawu",
	"Agua de Dios",
	"El Águila",
	"Hammelburg",
	"Charlton Kings",
	"Campinorte",
	"Mayfield",
	"Aydıncık",
	"Lanham",
	"Sosnivka",
	"Franklin Lakes",
	"Bischofswerda",
	"Gulf Gate Estates",
	"De Pinte",
	"Chicureo Abajo",
	"Skelton",
	"Montale",
	"Veselí nad Moravou",
	"Yorktown",
	"Town and Country",
	"Gottmadingen",
	"Monte San Pietro",
	"Miyato",
	"Nazyvayevsk",
	"Parapuã",
	"Ostbevern",
	"Bollullos de la Mitación",
	"Fao Rai",
	"Beiuş",
	"Panguipulli",
	"Tournon-sur-Rhône",
	"Giardini",
	"Fulton",
	"Mount Sinai",
	"Princess Anne",
	"Jefferson Hills",
	"Marly",
	"Pedernales",
	"Lindon",
	"Stannington",
	"Al ‘Amādīyah",
	"Pedersöre",
	"Hamilton",
	"Induno Olona",
	"Vilyuysk",
	"Canton",
	"Feldkirchen-Westerham",
	"Maysville",
	"São José do Jacuípe",
	"South Yarmouth",
	"Campton Hills",
	"Tysvær",
	"Woodbury",
	"Rifle",
	"Motta di Livenza",
	"Narimanov",
	"Iacanga",
	"Bennettsville",
	"Stropkov",
	"Walden",
	"Grove City",
	"Fervedouro",
	"Burgstädt",
	"San Nicolas Buenos Aires",
	"Tepetlán",
	"Lexington Park",
	"Canandaigua",
	"Merriam",
	"Jandaíra",
	"São Pedro do Ivaí",
	"Andacollo",
	"Olbernhau",
	"Guilherand",
	"Altamira do Maranhão",
	"Westwood",
	"Lower Burrell",
	"Iapu",
	"Valley Center",
	"Nova Ubiratã",
	"Brotas de Macaúbas",
	"East Hanover",
	"Sigatoka",
	"Silverton",
	"Villa La Angostura",
	"Wahpeton",
	"Bolivar",
	"Storm Lake",
	"Costeşti",
	"Onna",
	"Thouaré-sur-Loire",
	"Gemona del Friuli",
	"Biganos",
	"Rostraver",
	"Whitehall",
	"Galimuyod",
	"Sosnovka",
	"Obuse",
	"Santana do Mundaú",
	"Eṭ Ṭīra",
	"Urdorf",
	"Pingtouchuanxiang",
	"Lake Grove",
	"Itzer",
	"Igaratinga",
	"Moss Bluff",
	"Nieder-Olm",
	"Young",
	"Norwell",
	"Shalushka",
	"Ponte Serrada",
	"Grosse Pointe Park",
	"São Gonçalo do Rio Abaixo",
	"Four Corners",
	"Afonso Bezerra",
	"Schleusingen",
	"Grand Haven",
	"Snodland",
	"Borovsk",
	"Holbrook",
	"Igarapé Grande",
	"Dek’emhāre",
	"Hakone",
	"Vera Cruz",
	"Two Rivers",
	"Botuporã",
	"Gleisdorf",
	"Martinengo",
	"Methil",
	"Siano",
	"Jafra",
	"Lawrenceburg",
	"Mossley",
	"Drăgăneşti-Olt",
	"Clinton",
	"Zhydachiv",
	"Dobbs Ferry",
	"Rüthen",
	"Locate di Triulzi",
	"Völkermarkt",
	"Chiltiupán",
	"Grezzana",
	"Bernex",
	"Bueno Brandão",
	"Zasechnoye",
	"Cori",
	"Estiva Gerbi",
	"Warman",
	"Karabash",
	"Kingaroy",
	"Kiskunmajsa",
	"Celano",
	"Zumaia",
	"Xudat",
	"Akyaka",
	"Grove",
	"Montecchio Emilia",
	"San Felice sul Panaro",
	"Knowle",
	"Verkhniy Tagil",
	"Shiloh",
	"Barberino di Mugello",
	"Munhall",
	"Wiener Neudorf",
	"Texcatepec",
	"Somers",
	"Los Corrales de Buelna",
	"Ma’ai",
	"Altensteig",
	"Solymár",
	"Lake Morton-Berrydale",
	"La Tuque",
	"Norwich",
	"Bocaiúva do Sul",
	"Florence",
	"Loyalsock",
	"Shagonar",
	"Browns Mills",
	"Cristuru Secuiesc",
	"Guymon",
	"Alberobello",
	"Phibun Mangsahan",
	"Meaford",
	"La Puebla de Cazalla",
	"Al Atārib",
	"Pike Creek Valley",
	"Süßen",
	"Caracol",
	"Oued Amlil",
	"Estanzuela",
	"Marck",
	"Mondeville",
	"Pompton Lakes",
	"Millsboro",
	"Pedro de Toledo",
	"General Salgado",
	"Phrai Bueng",
	"Kings Mountain",
	"Prachatice",
	"Sussex",
	"El Tarra",
	"São Tiago",
	"Schkopau",
	"Rokytne",
	"La Palma del Condado",
	"Saint-Laurent-de-la-Salanque",
	"Rio do Fogo",
	"Lomazzo",
	"Keila",
	"Ballston",
	"Adjala-Tosorontio",
	"Pearsall",
	"Medesano",
	"Barmstedt",
	"Ocean City",
	"Astorga",
	"Matsuda-sōryō",
	"Chambly",
	"Punitaqui",
	"Pontchâteau",
	"Wooburn",
	"Neuhof",
	"Largo",
	"Hurzuf",
	"Neman",
	"Salò",
	"Pont-Saint-Esprit",
	"Canelli",
	"Springbok",
	"West Point",
	"Gonzales",
	"Sultanhanı",
	"Sidi Namane",
	"Liberty Lake",
	"Bad Iburg",
	"Earlestown",
	"Ardrossan",
	"Spencer",
	"Chalco",
	"Herxheim",
	"Aylesford",
	"Zwettl",
	"Donzdorf",
	"Boizenburg",
	"Arceburgo",
	"Ripoll",
	"Elhovo",
	"Engen",
	"Gerzat",
	"Pélissanne",
	"Willistown",
	"Desborough",
	"Saddlebrooke",
	"Decatur",
	"Isselburg",
	"Heath",
	"Hamilton Township",
	"Lagoa do Mato",
	"Kandry",
	"Concepcion",
	"Kaufering",
	"Seridó",
	"Villers-la-Ville",
	"Piraúba",
	"Trabia",
	"Cacimbinhas",
	"Guantingzhan",
	"Cadelbosco di Sopra",
	"Alella",
	"Sanjiangkou",
	"Seberi",
	"New Albany",
	"Ilvesheim",
	"Villers-Cotterêts",
	"Massa Lombarda",
	"Alburquerque",
	"Malente",
	"Casaluce",
	"Montignoso",
	"Figuig",
	"Vermillion",
	"Weinböhla",
	"Stephanskirchen",
	"Wantage",
	"Westtown",
	"Novgorodskoye",
	"Hagondange",
	"Kisújszállás",
	"Pau Brasil",
	"Prudente de Morais",
	"Manchester",
	"Kamen’-Rybolov",
	"Bobrovytsia",
	"Itarana",
	"Bobrynets",
	"Sagarejo",
	"Erstein",
	"Ville-la-Grand",
	"Barano d’Ischia",
	"Alberique",
	"Le Crès",
	"Pocono",
	"Ban Lueak",
	"Goldbach",
	"Yungay",
	"Braine-le-Château",
	"São Bento do Sapucaí",
	"Cusseta",
	"San Maurizio Canavese",
	"Borgloon",
	"Surazh",
	"Tenango del Aire",
	"Felton",
	"Walker Mill",
	"Feyzin",
	"Dourdan",
	"Tenente Ananias",
	"Ramacca",
	"Davos",
	"Elkins",
	"Halfway",
	"Pua",
	"Nivala",
	"Bofete",
	"San Juanito de Escobedo",
	"Langenzenn",
	"Boekel",
	"Court-Saint-Étienne",
	"Dayr as Sanqūrīyah",
	"Itapebi",
	"Mesyagutovo",
	"Krzeszowice",
	"Carnaubais",
	"Kangaroo Flat",
	"Beni Abbès",
	"Kuršėnai",
	"Le Locle",
	"Kranuan",
	"Soquel",
	"Mogotes",
	"Milton",
	"Lollar",
	"Scartho",
	"Koronowo",
	"Sprendlingen",
	"Tosagua",
	"Amioûn",
	"Holbeach",
	"St. Clements",
	"Khorol",
	"Santa Maria de Itabira",
	"Omurtag",
	"Fort Meade",
	"Sam Ko",
	"La Escala",
	"Montgomery",
	"Serris",
	"Apiúna",
	"Saint-Amable",
	"Weyburn",
	"Strasshof an der Nordbahn",
	"Lauterach",
	"Itri",
	"Primavera",
	"Hanover",
	"Tacima",
	"Beclean",
	"Lake Monticello",
	"Jordânia",
	"Lowes Island",
	"Sarmiento",
	"Manabo",
	"Kearney",
	"West Caldwell",
	"Bacliff",
	"Erlenbach am Main",
	"Rosario",
	"Essex Junction",
	"Grigiškės",
	"Reguengos de Monsaraz",
	"Raeren",
	"Manevychi",
	"Amaturá",
	"Celldömölk",
	"Ngorongoro",
	"Ferros",
	"Ban Ko",
	"Hrebinka",
	"Kitee",
	"Bestwig",
	"Winchendon",
	"Lavaur",
	"Pleasant View",
	"Weingarten",
	"Wasilla",
	"Ørsta",
	"Sharg‘un",
	"Xiaozui",
	"Forest",
	"Cantillana",
	"Baryshivka",
	"Maur",
	"Oak Grove",
	"Gaohucun",
	"South Dundas",
	"Aberbargoed",
	"Weeze",
	"Kingsteignton",
	"Rincão",
	"Fiuggi",
	"Pirapetinga",
	"Itapitanga",
	"Sevilla",
	"Diavatá",
	"Imi Mokorn",
	"Zhatay",
	"Parnaguá",
	"Mae Ai",
	"Braunsbedra",
	"Khrystynivka",
	"River Forest",
	"Randazzo",
	"Ogano",
	"Fontaine-lès-Dijon",
	"Soyaniquilpan",
	"Wang Sombun",
	"Tecoh",
	"Kolno",
	"Pont-Audemer",
	"La Bañeza",
	"Villiersdorp",
	"Haigerloch",
	"Cheval",
	"Ararendá",
	"The Pinery",
	"Poiana Mare",
	"Tiszaföldvár",
	"Juripiranga",
	"Tezontepec",
	"Dáli",
	"Uzda",
	"Panthersville",
	"Santo Domingo",
	"Tiszafüred",
	"Fürth",
	"Smithville",
	"Mount Kisco",
	"Guaraci",
	"Orlu",
	"Douar Bouchfaa",
	"Sierra Madre",
	"Bananal",
	"Lower Saucon",
	"Totowa",
	"Zuchwil",
	"Meßstetten",
	"Totolapan",
	"Blandford Forum",
	"Mercês",
	"Bleicherode",
	"Mărăşeşti",
	"Demmin",
	"Hochdorf",
	"Abram",
	"Méry-sur-Oise",
	"Le Taillan-Médoc",
	"Oloron-Sainte-Marie",
	"Diedorf",
	"Urlaţi",
	"Wald-Michelbach",
	"San Germán",
	"Magny-le-Hongre",
	"Pencoed",
	"Castleton",
	"Wittelsheim",
	"Hanson",
	"Orono",
	"Yeghvard",
	"Birkenfeld",
	"Highland Park",
	"Vigonovo",
	"Foum Jam’a",
	"Tanant",
	"Ōtsuchi",
	"Köngen",
	"Yuzhno-Sukhokumsk",
	"Ráckeve",
	"Kranenburg",
	"Tlachichilco",
	"North Branch",
	"Rebola",
	"Lerici",
	"Winnemucca",
	"Castle Pines",
	"Iwai",
	"Pintadas",
	"Shannon",
	"Arbaa Sahel",
	"Goianápolis",
	"Nizza Monferrato",
	"Taouloukoult",
	"Burr Ridge",
	"Galena Park",
	"L’Île-Perrot",
	"Notre-Dame-de-l'Île-Perrot",
	"Felsberg",
	"Tangerhütte",
	"Maynard",
	"Myrtle Grove",
	"Williams Lake",
	"Bischofshofen",
	"Quincy-sous-Sénart",
	"Ludington",
	"Santa Catarina Ayotzingo",
	"Camden",
	"Vanzago",
	"São Luís do Paraitinga",
	"Aigle",
	"Nadezhda",
	"Coriano",
	"Orthez",
	"Bellmead",
	"Mount Evelyn",
	"Čáslav",
	"Southwater",
	"Elliot Lake",
	"Aguadilla",
	"Praskoveya",
	"Fontoura Xavier",
	"Bourg-de-Péage",
	"Muskegon Heights",
	"Pola de Lena",
	"Balderton",
	"Cedral",
	"São Brás de Alportel",
	"Mapleton",
	"Fruitland Park",
	"Winchester",
	"Murtosa",
	"Juruaia",
	"Tafalla",
	"Ichnia",
	"Peñarroya-Pueblonuevo",
	"Al Musayfirah",
	"Dießen am Ammersee",
	"Tamanar",
	"Arkadelphia",
	"Zavitinsk",
	"San Pablo Huixtepec",
	"Bondues",
	"Léognan",
	"Waiuku",
	"Rochelle",
	"Abadou",
	"Gignac-la-Nerthe",
	"Gig Harbor",
	"Poirino",
	"Bentley",
	"Frodsham",
	"San Ricardo",
	"Sidi Bousber",
	"Nakayama",
	"Garrucha",
	"Villa Purificación",
	"Umarizal",
	"Dinagat",
	"Crestwood",
	"Salemi",
	"Farmersville",
	"Brejolândia",
	"Waterford",
	"Smithfield",
	"Gateway",
	"Chenango",
	"Silea",
	"Cantley",
	"Torre Santa Susanna",
	"Had Laaounate",
	"Raceland",
	"Jibou",
	"Gilbués",
	"Sweet Home",
	"Ribeirão Claro",
	"Inkerman",
	"Ickenham",
	"Paricônia",
	"Nova Olinda",
	"DeForest",
	"Riedlingen",
	"Cleveland",
	"Avon Park",
	"Hämeenkyrö",
	"Agdz",
	"Cowra",
	"Lichtenau",
	"Rhosllanerchrugog",
	"Burgkirchen an der Alz",
	"Szigetvár",
	"Nossen",
	"Gernsheim",
	"Fondettes",
	"Itaueira",
	"Aver-o-Mar",
	"Branquinha",
	"Darling",
	"Merchweiler",
	"Villacarrillo",
	"Hetane",
	"Van Wert",
	"Almoloya",
	"Atessa",
	"Oţelu Roşu",
	"Rombas",
	"Recco",
	"Canelinha",
	"Orşova",
	"Baraçlândia",
	"Amtar",
	"Faxinal dos Guedes",
	"Chuqung",
	"Garden City",
	"Zawyat Ahançal",
	"Newburn",
	"Roccapiemonte",
	"São Sebastião do Maranhão",
	"Borculo",
	"Waldkirchen",
	"Paimio",
	"Nelson",
	"Knin",
	"Weilheim an der Teck",
	"Tubará",
	"Querfurt",
	"Hornell",
	"Pacific",
	"Huétor-Tájar",
	"Trajano de Morais",
	"Castrolibero",
	"Altötting",
	"Mara Rosa",
	"La Trinité",
	"Sosenskiy",
	"Newcastle",
	"Tnine Sidi Lyamani",
	"Vysokovsk",
	"Bargas",
	"Ketchikan",
	"Alamosa",
	"Gudofredo Viana",
	"Kuiyibagecun",
	"Castelletto sopra Ticino",
	"Trets",
	"Toccoa",
	"Arraias",
	"Brooklyn",
	"Souq Sebt Says",
	"Saks",
	"Vittuone",
	"Conselve",
	"Kolárovo",
	"Mouans-Sartoux",
	"Sucupira do Norte",
	"Corcuera",
	"Millington",
	"Iretama",
	"Cassá de la Selva",
	"Murata",
	"Lázaro Cárdenas",
	"Birchington",
	"Urrugne",
	"Georgetown",
	"Ogdensburg",
	"Bobenheim-Roxheim",
	"Aïn Zora",
	"Geneseo",
	"Wyomissing",
	"Scituate",
	"Jacinto Machado",
	"Petal",
	"Lambton Shores",
	"Anderson",
	"Santiago Suchilquitongo",
	"Isola Vicentina",
	"Villeneuve-lès-Maguelone",
	"São João",
	"Toundout",
	"Mokrisset",
	"Höchberg",
	"Mussomeli",
	"Monte Alegre do Piauí",
	"Monroe",
	"Bovingdon",
	"Easttown",
	"Nörvenich",
	"Monnickendam",
	"Ozieri",
	"Jablanica",
	"Tourza",
	"Atchison",
	"Benito Juárez",
	"Escaudain",
	"Schalksmühle",
	"Kimpese",
	"Aerzen",
	"Peschanokopskoye",
	"Khilok",
	"Biskupiec",
	"Gommern",
	"Mold",
	"Malaya Vishera",
	"Cajapió",
	"Bibbiano",
	"Pinto",
	"Strehaia",
	"Songo",
	"Highland City",
	"Aït Hani",
	"Saint-Gély-du-Fesc",
	"Mildenhall",
	"Tarazona de Aragón",
	"Santa Teresinha",
	"Imst",
	"Virginópolis",
	"Rignano Flaminio",
	"Santa Margarita de Mombúy",
	"Pôrto Xavier",
	"Ludlow",
	"Lavínia",
	"Markt Indersdorf",
	"Králŭv Dvŭr",
	"Canteras",
	"Baran’",
	"Pirpirituba",
	"Böhl-Iggelheim",
	"Scherpenzeel",
	"Barcs",
	"Serra Caiada",
	"Bangshang",
	"Bom Repouso",
	"Vorsma",
	"Morbach",
	"Castelnovo ne’ Monti",
	"Badia Polesine",
	"Kapaa",
	"Bom Jesus da Serra",
	"Doorn",
	"Urgnano",
	"Waterloo",
	"Wyndham",
	"Montividiu",
	"Naganuma",
	"San Vicente",
	"Ruppichteroth",
	"Sortland",
	"Borjomi",
	"San Pedro La Laguna",
	"Norton",
	"Ban Bang Phlap",
	"Fairfield",
	"Tilougguit",
	"Jesenice",
	"Greencastle",
	"Covington",
	"Santa Teresa di Riva",
	"Three Rivers",
	"Dombasle-sur-Meurthe",
	"Hilter",
	"Armanāz",
	"Timonium",
	"Châteaugiron",
	"Wilkau-Haßlau",
	"East Cocalico",
	"Castalla",
	"Grevesmühlen",
	"Veitshöchheim",
	"Partanna",
	"Frontera",
	"Campi Salentina",
	"Litomyšl",
	"Grossos",
	"Torotoro",
	"Kamifurano",
	"Aragoiânia",
	"Ben N’Choud",
	"Morro Bay",
	"Argelès-sur-Mer",
	"Bordj Okhriss",
	"Lowell",
	"Trescore Balneario",
	"Cutro",
	"Zmeinogorsk",
	"Ban Thung Khao Phuang",
	"La Ferté-sous-Jouarre",
	"Paris",
	"Dnestrovsc",
	"Ironton",
	"Star",
	"Fairview",
	"Kauniainen",
	"Pasadena Hills",
	"Ibiassucê",
	"Acushnet",
	"Concordia Sagittaria",
	"Arlesheim",
	"Bagnara Calabra",
	"Alcaudete",
	"Mapleton",
	"Sidi Abdallah",
	"Sotkamo",
	"Alcora",
	"Sinaia",
	"Appenweier",
	"Sangão",
	"Shumanay",
	"Villanueva de Arosa",
	"Prakhon Chai",
	"Milton",
	"Sandy",
	"Topoloveni",
	"Albert",
	"La Londe-les-Maures",
	"Höchst im Odenwald",
	"Bad Lauterberg",
	"Regidor",
	"Vaals",
	"San Giorgio del Sannio",
	"La Guardia",
	"Lloyd",
	"San Diego Country Estates",
	"Valdobbiadene",
	"Columbia",
	"Phai Sali",
	"Senhora dos Remédios",
	"Bolekhiv",
	"Hockley",
	"Altenholz",
	"Roscoe",
	"Târgu Frumos",
	"Schwaikheim",
	"Guadalupe",
	"Altofonte",
	"Douglass",
	"Natuba",
	"Maria Enzersdorf",
	"Nova Europa",
	"Tiztoutine",
	"Jefferson",
	"Little Canada",
	"Rosaryville",
	"Wapakoneta",
	"Cavriago",
	"Hambühren",
	"Alpine",
	"Pérols",
	"Lahstedt",
	"San Vendemiano",
	"Fuveau",
	"Reiskirchen",
	"Iramaia",
	"Huittinen",
	"Lyuboml’",
	"Langhirano",
	"Farmington",
	"Ivangorod",
	"Douar Echbanat",
	"Olëkminsk",
	"Volda",
	"Doctor Phillips",
	"East Greenwich",
	"Warren",
	"Costeşti",
	"Beneditinos",
	"Karkamış",
	"Wittenbach",
	"Exeter",
	"Martin",
	"Pitkyaranta",
	"Gossau",
	"Gifford",
	"Georgian Bluffs",
	"Wendeburg",
	"Bernalillo",
	"Oelsnitz",
	"Kangning",
	"Olevsk",
	"Jaltocan",
	"Great Missenden",
	"Minobu",
	"Aradeo",
	"Pine Castle",
	"Mios",
	"Point Pleasant",
	"La Farlède",
	"Tomblaine",
	"Cape Canaveral",
	"Bogen",
	"Sweetwater",
	"Brikcha",
	"Neuenhof",
	"Fairview Shores",
	"Worth",
	"Pulpí",
	"Rio Acima",
	"Portage",
	"Shenjiaba",
	"Tanaina",
	"Vigasio",
	"Salanso",
	"Àrvorezinha",
	"Fort William",
	"Sudogda",
	"Haikoucun",
	"Santiago",
	"Bedford Heights",
	"Bujari",
	"Altenberge",
	"Candás",
	"Zuyevka",
	"Hull",
	"Nanfang",
	"Miribel",
	"Sylva",
	"Bogué",
	"Mörlenbach",
	"Phon Charoen",
	"Oulad Slim",
	"Fox Lake",
	"Cittanova",
	"Pembroke Dock",
	"Sovata",
	"Ivankiv",
	"Cristino Castro",
	"Oak Hills",
	"San Juan La Laguna",
	"Millstone",
	"Tiddas",
	"Linthicum",
	"Schwaig",
	"Fountain Inn",
	"Cabañaquinta",
	"New Baltimore",
	"Großburgwedel",
	"Paranã",
	"Wellington",
	"Beni Oulid",
	"Awfouss",
	"Quinto di Treviso",
	"Nevelsk",
	"Kendallville",
	"Krasnoilsk",
	"Bomporto",
	"Coroneo",
	"Raymond",
	"Molango",
	"Panaon",
	"Sumner",
	"Volpago del Montello",
	"Felling",
	"Hughenden",
	"Tangermünde",
	"Limoux",
	"Roseira",
	"Ban Klang",
	"Werlte",
	"Corral de Bustos",
	"Pine Hill",
	"Planura",
	"Rawdon",
	"Riachuelo",
	"Forestville",
	"Tha Muang",
	"Anröchte",
	"Hudson",
	"Shasta Lake",
	"Kragerø",
	"Bordighera",
	"Carmen",
	"Hamilton",
	"Lehigh",
	"Campbellton",
	"Appleton",
	"Dayton",
	"Belaya Kholunitsa",
	"Rodeo",
	"View Royal",
	"Luanco",
	"Te Puke",
	"Worcester",
	"Piraí do Norte",
	"West Point",
	"Vidor",
	"Leones",
	"Tutrakan",
	"Weil im Schönbuch",
	"Terralba",
	"Jaguaribara",
	"Cajobi",
	"Pasewalk",
	"Ianca",
	"Jaqma",
	"New Port Richey East",
	"Bellefontaine Neighbors",
	"Portet-sur-Garonne",
	"Salinas",
	"Dar El Kebdani",
	"Vermilion",
	"Calatrava",
	"Lyngdal",
	"South Union",
	"Itamogi",
	"Puerto Quijarro",
	"Morsbach",
	"El Realejo",
	"Quétigny",
	"Dolhasca",
	"Lipova",
	"Catunda",
	"Harfleur",
	"Vaprio d’Adda",
	"Roche-la-Molière",
	"Kapuvár",
	"Bonham",
	"Kapyl’",
	"Bad Frankenhausen",
	"Bowen",
	"Frýdlant nad Ostravicí",
	"Kennett",
	"Tököl",
	"Verucchio",
	"Heves",
	"Prestonpans",
	"Verkhnodniprovsk",
	"Pilar",
	"Qiushanxiang",
	"Troy",
	"Fort Bragg",
	"Madridejos",
	"Köflach",
	"Biot",
	"Steinau an der Straße",
	"Klipphausen",
	"Borgosatollo",
	"Lyons",
	"Sylva",
	"Corumbá de Goiás",
	"Amurrio",
	"Werneck",
	"María Pinto",
	"Kut Chap",
	"Neuenstadt am Kocher",
	"Bounaamane",
	"Ardino",
	"Urucânia",
	"Wells",
	"Bernay",
	"Miami Shores",
	"Letychiv",
	"Shamva",
	"Murfatlar",
	"Münchberg",
	"Vaiano",
	"Bethanie",
	"Levanger",
	"Kenzingen",
	"San Felice Circeo",
	"Førde",
	"Kirkel",
	"Capriolo",
	"Essey-lès-Nancy",
	"Taksimo",
	"Buenópolis",
	"Fortuna",
	"Korolevo",
	"Aydarken",
	"Nagyatád",
	"Bela Vista de Minas",
	"Aulendorf",
	"Columbia",
	"Oostzaan",
	"Lukovit",
	"Payerne",
	"Vignate",
	"Beverly Hills",
	"West Hanover",
	"Conceição da Aparecida",
	"Sobral de Monte Agraço",
	"Goito",
	"Königsbach-Stein",
	"Lexington",
	"Neuenhaus",
	"Teocuitatlán de Corona",
	"Richmond Heights",
	"Buzdyak",
	"Ban Bong Tai",
	"Harrison",
	"Csorna",
	"Mariluz",
	"Marpingen",
	"Sedona",
	"San Vicente de Castellet",
	"Ksar Lmajaz",
	"Ben Nasseur",
	"Biandanshan",
	"Ninheira",
	"Santa Teresa",
	"Lenguazaque",
	"Santa Maria Madalena",
	"Ottendorf-Okrilla",
	"Summerville",
	"Volterra",
	"Crosia",
	"Januário Cicco",
	"Saint-Grégoire",
	"Bieber",
	"Francisco Badaró",
	"Dettingen an der Erms",
	"Vytegra",
	"Chavinda",
	"Welkenraedt",
	"Union City",
	"Aghbalou n’Kerdous",
	"Vernon",
	"South Huntington",
	"Carmaux",
	"Fort Madison",
	"Aich",
	"Nor Hachn",
	"Santa Cruz do Arari",
	"Campos Lindos",
	"Tyukalinsk",
	"Banská Štiavnica",
	"Frouzins",
	"Montemarciano",
	"Coldstream",
	"Mandello del Lario",
	"Natonin",
	"Bowdon",
	"Nanticoke",
	"Bad Ems",
	"Santa Terezinha de Goiás",
	"Blairgowrie",
	"Cermenate",
	"Chester",
	"Sgamna",
	"Argayash",
	"Brumath",
	"Teteven",
	"Santo Stefano di Magra",
	"Hillsdale",
	"Queens",
	"Kambarka",
	"Bilenke",
	"Comstock Park",
	"Burgau",
	"El Álamo",
	"Dores de Campos",
	"Fredonia",
	"Rāyen",
	"Petrolina de Goiás",
	"Novi di Modena",
	"Soyaux",
	"Sermoneta",
	"Rouvroy",
	"Helena-West Helena",
	"Chatham",
	"Park Forest Village",
	"Nehoiu",
	"Forest Acres",
	"Oakengates",
	"Coal",
	"Tha Mai",
	"Reading",
	"Zaozërnyy",
	"Sixaola",
	"Groaíras",
	"Hecelchakán",
	"Morrovalle",
	"London Colney",
	"Santa Clara La Laguna",
	"Vashon",
	"Grand-Couronne",
	"Nieuw-Lekkerland",
	"Canton",
	"Alvorada do Sul",
	"Coroaci",
	"Weston",
	"San José de Feliciano",
	"Fiumefreddo di Sicilia",
	"Miradouro",
	"Kilkunda",
	"Augustdorf",
	"Sant’Egidio alla Vibrata",
	"Corfe Mullen",
	"Pliezhausen",
	"Ertil",
	"Chivhu",
	"Arden Hills",
	"Sidi Rahhal",
	"Lillers",
	"Pine Ridge",
	"Selkirk",
	"Saint-Félicien",
	"Haelen",
	"Marano Vicentino",
	"Medulla",
	"São Francisco",
	"Lanškroun",
	"Slatina",
	"Independence",
	"Liphook",
	"Rubim",
	"Doraville",
	"Oster",
	"Rum",
	"Busca",
	"Steinen",
	"Caldera",
	"Hawkesbury",
	"Soverato Marina",
	"La’tamna",
	"São João d’Aliança",
	"Moimenta da Beira",
	"Santa Isabel Cholula",
	"Birkenau",
	"Coronel Freitas",
	"Nordkirchen",
	"Plattekill",
	"Lonquimay",
	"Meuselwitz",
	"Cypress Gardens",
	"Itapé",
	"Taufkirchen",
	"Eumseong",
	"Lūgovoy",
	"Pebble Creek",
	"Martinópole",
	"Mucugê",
	"Melvindale",
	"Fanzhao",
	"Wildberg",
	"Elmas",
	"Sauk Village",
	"Bisignano",
	"Ronda Alta",
	"Marawī",
	"Pescaria Brava",
	"Clearfield",
	"Andergrove",
	"Radyvyliv",
	"Pella",
	"Charalá",
	"Luza",
	"Terra Nova",
	"Calasparra",
	"Tarashcha",
	"Cuorgnè",
	"Oppeano",
	"Vétraz-Monthoux",
	"Wingles",
	"Sedico",
	"Blumberg",
	"Bad Orb",
	"Pires Ferreira",
	"Sparta",
	"Flămânzi",
	"Box Elder",
	"Middle Island",
	"Friedeburg",
	"Roberval",
	"Bad Wildbad",
	"Borzna",
	"Brandizzo",
	"Jemaat Oulad Mhamed",
	"Shyroke",
	"Sniatyn",
	"Sainte-Agathe-des-Monts",
	"Qovlar",
	"Pithiviers",
	"Steinfeld",
	"Emsbüren",
	"Catanduvas",
	"Teplodar",
	"Kenafif",
	"Mstsislaw",
	"Barrington",
	"Launaguet",
	"Oggiono",
	"North Dumfries",
	"Carqueiranne",
	"Medina",
	"Tutzing",
	"Houthulst",
	"Rehburg-Loccum",
	"Pechea",
	"Csömör",
	"Governador Archer",
	"Ainring",
	"Fort Lupton",
	"Nong Ki",
	"Simbach am Inn",
	"Rideau Lakes",
	"Luís Gomes",
	"Tolmezzo",
	"Asola",
	"Victoria",
	"Aleşd",
	"Göytəpə",
	"Wahlstedt",
	"Niebüll",
	"Castellamonte",
	"Za’roura",
	"Gunbarrel",
	"Bluffton",
	"Lizzano",
	"Kushijima",
	"Kirchzarten",
	"Sechelt",
	"Cetraro",
	"Santa María de Palautordera",
	"Přelouč",
	"Waverly",
	"Pullach im Isartal",
	"Altusried",
	"Aldenham",
	"Tissaf",
	"Molsheim",
	"Jocoro",
	"Looc",
	"Mikhaylov",
	"Skidal’",
	"Beccles",
	"Souama",
	"Cingoli",
	"Takahama",
	"Alto Piquiri",
	"Fife",
	"Lehman",
	"Phak Hai",
	"Sinimbu",
	"Caatiba",
	"Almoloya del Río",
	"Le Passage",
	"Ouistreham",
	"Kokoszki",
	"Kawara",
	"Assendelft",
	"Kampong Tunah Jambu",
	"Sofiivka",
	"Covasna",
	"Somers Point",
	"Balangkayan",
	"Novo Horizonte",
	"Woodmere",
	"Naters",
	"Granada",
	"Alguazas",
	"Mazamet",
	"Seyé",
	"Pernes-les-Fontaines",
	"Nové Město na Moravě",
	"Yermolino",
	"Moree",
	"Ban Bu Sung",
	"Cachoeira dos Índios",
	"Willstätt",
	"Racconigi",
	"Byarozawka",
	"Pogliano Milanese",
	"Laakirchen",
	"Canápolis",
	"Calçoene",
	"Bucine",
	"Magstadt",
	"Tocina",
	"Caém",
	"Sung Noen",
	"Gages Lake",
	"Embrach",
	"Haxby",
	"Asbury Lake",
	"Saint-Loubès",
	"Pike Road",
	"Mainaschaff",
	"Mengibar",
	"Ozimek",
	"Opochka",
	"Jucuruçu",
	"Havre",
	"Gavirate",
	"Snohomish",
	"Southampton",
	"Heubach",
	"Sîngera",
	"La Misión",
	"Ipuiúna",
	"Salvatierra de Miño",
	"Welwyn",
	"Paranaiguana",
	"Yaotsu",
	"Choele Choel",
	"Argelato",
	"Ech Chaïbat",
	"Wald",
	"Al Wafrah",
	"Bruino",
	"Waynesville",
	"Glocester",
	"Rinópolis",
	"Auterive",
	"Clarion",
	"Kutztown",
	"Zavolzhsk",
	"Iwashita",
	"Schotten",
	"Tolbazy",
	"Steinhaus",
	"Binéfar",
	"Água Branca",
	"Salaverry",
	"Sovicille",
	"Conway",
	"Danville",
	"Southport",
	"Keuruu",
	"Vienna",
	"Ipupiara",
	"Großhansdorf",
	"Manville",
	"Southborough",
	"Bel Air",
	"Warr Acres",
	"Franklin",
	"Lysá nad Labem",
	"Rüdesheim am Rhein",
	"Judenburg",
	"Illora",
	"Foz",
	"Tipp City",
	"Sulzbach",
	"Aruanã",
	"Klötze",
	"Struthers",
	"Lincoln Park",
	"Chapel en le Frith",
	"North Glengarry",
	"Kurort Steinbach-Hallenberg",
	"Satipo",
	"Puentes de García Rodríguez",
	"Zaggota",
	"Kamalasai",
	"Digora",
	"Waldwick",
	"Gambolò",
	"Melendugno",
	"Condé-sur-l’Escaut",
	"Shawano",
	"Poienile de sub Munte",
	"Fairview",
	"Red Bank",
	"Mountsorrel",
	"Meldola",
	"Cabriès",
	"Maiquinique",
	"Lescar",
	"College",
	"Macomer",
	"Alfredo Wagner",
	"Krivodanovka",
	"Nova Veneza",
	"Oberhausen-Rheinhausen",
	"Nogliki",
	"Nideggen",
	"South Huron",
	"Dylym",
	"Mengen",
	"Brembate",
	"Progress",
	"Marieville",
	"Wiang Sa",
	"Fairmount",
	"Ontario",
	"Pintuyan",
	"Mori",
	"Wölfersheim",
	"Bou Nouh",
	"Roßtal",
	"Old Saybrook",
	"’Aïn Leuh",
	"Kirkland",
	"Arques",
	"Hongliuwan",
	"Cedar Hills",
	"Hobart",
	"Dallgow-Döberitz",
	"Le Mars",
	"Radišani",
	"Caudete",
	"Crigglestone",
	"Açucena",
	"Cresson",
	"Standish",
	"Harrisonville",
	"Makale",
	"Sannicandro di Bari",
	"Divonne-les-Bains",
	"Kümmersbruck",
	"Aşağı Ayıblı",
	"Clinton",
	"San Agustín de las Juntas",
	"Kösching",
	"Brandon",
	"Metsamor",
	"Littleton",
	"Carneirinho",
	"Ikryanoye",
	"Tsunō",
	"Lenggries",
	"Aire-sur-la-Lys",
	"Taghbalt",
	"Milford",
	"Tiana",
	"Tárnok",
	"Le Beausset",
	"Nolensville",
	"Kosh-Agach",
	"Inverigo",
	"Ivančice",
	"Kargopol",
	"San Pancrazio Salentino",
	"Figeac",
	"Garlasco",
	"Rödinghausen",
	"Isabela",
	"Wood River",
	"Friendly",
	"Ban Ratchakrut",
	"Hoek van Holland",
	"Ollerton",
	"Barga",
	"Kubrat",
	"Sleepy Hollow",
	"Caspe",
	"Euxton",
	"Colindres",
	"Sankt Andrä",
	"Šurany",
	"Fair Oaks Ranch",
	"Roboré",
	"Sipacate",
	"Heteren",
	"Bou’nane",
	"North Versailles",
	"Nohfelden",
	"Valea lui Mihai",
	"Monteriggioni",
	"Lentvaris",
	"Merzenich",
	"Moba",
	"Holmen",
	"Croydon",
	"San Pedro Huamelula",
	"Santa María Ajoloapan",
	"Tay",
	"Santa Margherita Ligure",
	"Coweta",
	"Suaita",
	"Dubove",
	"Villamarchante",
	"Ohrdruf",
	"Fairmont",
	"Coycoyan de las Flores",
	"Valozhyn",
	"Moravská Třebová",
	"Warrenton",
	"Volodarsk",
	"Gassino Torinese",
	"Caraíbas",
	"Wielsbeke",
	"Purkersdorf",
	"Kami-kawabe",
	"Tafersit",
	"San Juan",
	"Progress Village",
	"Nogent-le-Rotrou",
	"Pleasant Hill",
	"Galleh Dār",
	"Taltal",
	"Vuktyl",
	"Senanga",
	"Maisenhausen",
	"Waggaman",
	"Alajärvi",
	"Aveley",
	"Kirchlinteln",
	"Non Sung",
	"Buckie",
	"Hiranai",
	"Jerez",
	"Laurens",
	"Hoeselt",
	"Uchoa",
	"Midland",
	"Bull Mountain",
	"Flat Rock",
	"Saint-Amand-Montrond",
	"Quartz Hill",
	"Niederhasli",
	"Molalla",
	"Gar",
	"Bonfinópolis",
	"Bude",
	"Hlinsko",
	"Scotchtown",
	"Pyetrykaw",
	"Giesen",
	"Alden",
	"River Vale",
	"Fort Oglethorpe",
	"Pinos Puente",
	"Visbek",
	"Ratne",
	"Saint-Pierre-du-Mont",
	"Lapinlahti",
	"Alkhan-Yurt",
	"Tarhjicht",
	"Darabani",
	"Ruffano",
	"Magnago",
	"Campobello di Licata",
	"Boves",
	"Alcoa",
	"Vlist",
	"Boudinar",
	"Fernán-Núñez",
	"Lake Arbor",
	"Rute",
	"Ii",
	"Garden Acres",
	"Töging am Inn",
	"Mīāndasht",
	"Bad König",
	"Pasian di Prato",
	"Oued Laou",
	"Mont-Saint-Martin",
	"Blain",
	"Pereshchepyne",
	"Anísio de Abreu",
	"Daruvar",
	"Santa Cruz Atizapán",
	"Dongcha",
	"Goldenstedt",
	"Liesveld",
	"Orland",
	"Cuicatlan",
	"Fouesnant",
	"Itatiaiuçu",
	"Helotes",
	"Saint-Jean-le-Blanc",
	"Sorkheh",
	"Leppävirta",
	"Mareno di Piave",
	"Aytré",
	"Bee Ridge",
	"Gessate",
	"Ploërmel",
	"Haddington",
	"Constantina",
	"Frickenhausen",
	"Monte Escobedo",
	"Amatenango del Valle",
	"Sande",
	"Merville",
	"Liminka",
	"Estavayer-le-Lac",
	"Key Largo",
	"Masquefa",
	"Kourimat",
	"Ribadeo",
	"Lambesc",
	"Piedras Blancas",
	"Boucau",
	"Saint-Doulchard",
	"Néa Moudaniá",
	"Outa Bouabane",
	"Westphalia",
	"Worth",
	"Umburetama",
	"Conselice",
	"Foix",
	"Wabash",
	"Pokrovske",
	"Saint-Rémy-de-Provence",
	"Zell am See",
	"Gunnedah",
	"Zaozërsk",
	"Itapeva",
	"East Bradford",
	"Novyye Atagi",
	"Bad Breisig",
	"Saint-Lys",
	"Montescaglioso",
	"Garliava",
	"Bad Gandersheim",
	"Deruta",
	"Winterville",
	"Capim Branco",
	"Ilsfeld",
	"Pia",
	"Bogandé",
	"Urzhum",
	"Byalynichy",
	"Ramsey",
	"Castel Bolognese",
	"Pórto Ráfti",
	"Cosne sur Loire",
	"Jilotlán de los Dolores",
	"Vargem",
	"Clay",
	"Celebration",
	"Saarijärvi",
	"Arteaga",
	"Temiskaming Shores",
	"Wilnecote",
	"Carregal do Sal",
	"Towcester",
	"Caldas de Reyes",
	"St. Albans",
	"Altmünster",
	"Talachyn",
	"Berd",
	"Poggio Renatico",
	"Fällanden",
	"Mirante",
	"Mission",
	"Margarita",
	"Monticello Conte Otto",
	"Osthofen",
	"Pântano Grande",
	"Trancoso",
	"Oulunsalo",
	"Berwick",
	"Großröhrsdorf",
	"Lago Ranco",
	"Nong Wua So",
	"Alachua",
	"Magny-les-Hameaux",
	"Aarburg",
	"San Giovanni in Marignano",
	"Néa Artáki",
	"Kinnelon",
	"Worsborough",
	"Ventnor City",
	"Colts Neck",
	"Fuente Palmera",
	"Rockcreek",
	"Bellinzago Novarese",
	"Qiziltepa",
	"Mezőberény",
	"Resana",
	"Chimay",
	"Rothrist",
	"Emmett",
	"Brandis",
	"Sugar Grove",
	"Kushnarënkovo",
	"Beuvry",
	"Bockenem",
	"River Grove",
	"Toudja",
	"Revel",
	"Hinton",
	"Zinkiv",
	"Saint-Sauveur",
	"Great Billing",
	"Titu",
	"Luçon",
	"Quesnel",
	"Pilar",
	"South Lebanon",
	"Torrinha",
	"Mora",
	"Varna",
	"Ruoqiang",
	"Pierrelaye",
	"Ashukino",
	"Norosí",
	"Dodworth",
	"Pingree Grove",
	"Ellon",
	"Middleton",
	"Fort Irwin",
	"Sherborne",
	"Ananás",
	"Vries",
	"Northampton",
	"Matadepera",
	"Serafimovskiy",
	"Nueva Guadalupe",
	"Bueng Khong Long",
	"Kobeliaky",
	"Beilngries",
	"Berezivka",
	"Gudensberg",
	"Makariv",
	"Sarapuí",
	"Belomorsk",
	"Broni",
	"Ellisville",
	"Piombino Dese",
	"Muurame",
	"Litovel",
	"La Salvetat-Saint-Gilles",
	"Madera Acres",
	"Bad Liebenzell",
	"Roztoky",
	"Gloucester Point",
	"Elizabethtown-Kitley",
	"Photharam",
	"Burgos",
	"Plüderhausen",
	"Brandywine",
	"Morinville",
	"Chom Thong",
	"Höhr-Grenzhausen",
	"La Tour-du-Pin",
	"Mikun",
	"Haţeg",
	"Veauche",
	"Brewster",
	"T’q’ibuli",
	"Artigues-près-Bordeaux",
	"Oldenburg in Holstein",
	"Nersingen",
	"Saint-Barthélemy-d’Anjou",
	"Matamata",
	"Carroll",
	"Carlton Colville",
	"Schlitz",
	"Zorneding",
	"Port Townsend",
	"Ban Krot",
	"Coffeyville",
	"Suntar",
	"Heilsbronn",
	"Osterburg",
	"Chillicothe",
	"Lake Barcroft",
	"Burtonsville",
	"Skopin",
	"Wamel",
	"Franklin",
	"Robertsdale",
	"Halikko",
	"Triuggio",
	"Aizenay",
	"Almusafes",
	"Dolianova",
	"Mozzate",
	"Masku",
	"Baker City",
	"Dorogobuzh",
	"Tišnov",
	"Totma",
	"Sorbolo",
	"Everswinkel",
	"Vardenik",
	"Florânia",
	"Cobham",
	"Chiang Klang",
	"Itasca",
	"Grey Highlands",
	"Atherstone",
	"Sidi Bou Othmane",
	"Ware",
	"Margny-lès-Compiègne",
	"Jennings",
	"Folignano",
	"Axixá do Tocantins",
	"Thap Khlo",
	"Guntramsdorf",
	"Villarrubia de los Ojos",
	"Tadla",
	"Woodbury",
	"Leeton",
	"Mentone",
	"Little River",
	"Alcarraz",
	"Mittagong",
	"Biei",
	"Spelle",
	"Vega Alta",
	"Cody",
	"Mooresville",
	"Nyurba",
	"Conning Towers Nautilus Park",
	"Albano Sant’Alessandro",
	"Mae Rim",
	"Ostercappeln",
	"Treillières",
	"Matelica",
	"Aklim",
	"Flero",
	"Sorisole",
	"Souffelweyersheim",
	"Aulnoye-Aymeries",
	"Aghbalou Aqourar",
	"Suzdal",
	"Kemberg",
	"George Mason",
	"Caturama",
	"Hostotipaquillo",
	"Fort Salonga",
	"Studénka",
	"Russellville",
	"Tweed Heads",
	"Annan",
	"Podu Iloaiei",
	"Hattula",
	"Nordwalde",
	"Mendicino",
	"Sevilla La Nueva",
	"North Bend",
	"Clarksville",
	"Grimmen",
	"Loria",
	"Eisenberg",
	"Redon",
	"Basse-Goulaine",
	"Neustadt",
	"Wagner",
	"Sarkad",
	"Philipsburg",
	"Denham Springs",
	"Cardeal da Silva",
	"Glencoe",
	"Scaggsville",
	"Corte Madera",
	"Umbrete",
	"Douar Lehouifrat",
	"Oberstdorf",
	"Akhuryan",
	"Jewett City",
	"Cuero",
	"Butler",
	"Fauske",
	"Argostóli",
	"Grado",
	"Felpham",
	"Nopaltepec",
	"Sângeorz-Băi",
	"Ussel",
	"Sidi Ahmed El Khadir",
	"Budd Lake",
	"La Ravoire",
	"San Pedro",
	"Vesele",
	"Pizzo",
	"Nakao",
	"Columbia City",
	"Aleksandrov Gay",
	"Punta del Este",
	"Voitsberg",
	"Yalagüina",
	"Zafferana Etnea",
	"Kittery",
	"Nolinsk",
	"Ingré",
	"Liteni",
	"Blacklick Estates",
	"Alsbach-Hähnlein",
	"Huaniqueo de Morales",
	"Idanha-a-Nova",
	"Bearsted",
	"Farsund",
	"Halen",
	"Bni Boufrah",
	"East Aurora",
	"Southwick",
	"Nové Město nad Metují",
	"Loudéac",
	"Heiligenhafen",
	"Shchuchye",
	"Akabira",
	"Rio Vista",
	"Imías",
	"Dungarvan",
	"Herkimer",
	"Philipstown",
	"Alexandria",
	"Wehrheim",
	"Eidson Road",
	"Dushanovë",
	"Bisingen",
	"Ehningen",
	"Léguevin",
	"San Marzano di San Giuseppe",
	"Saint-Aubin-lès-Elbeuf",
	"Stratford",
	"Lavis",
	"Prospect",
	"Plains",
	"Boaz",
	"Cuéllar",
	"Kawanishichō",
	"Benalla",
	"Pleasant Valley",
	"Foiano della Chiana",
	"Chesapeake Ranch Estates",
	"La Matanza de Acentejo",
	"Countryside",
	"Porto Tolle",
	"Matlock",
	"Cavriglia",
	"Maserada sul Piave",
	"St. Francis",
	"Awans",
	"Holywell",
	"Alhendín",
	"Pasil",
	"Schmitten",
	"Kola",
	"Hohenmölsen",
	"Seysses",
	"Formoso",
	"Tzitzio",
	"Visselhövede",
	"Dassel",
	"Holualoa",
	"Immingham",
	"Bersted",
	"Swanage",
	"East Rutherford",
	"Pechory",
	"Williston",
	"Frei Inocêncio",
	"Valerik",
	"Fort Valley",
	"Joigny",
	"Castelleone",
	"Laubach",
	"Commerce",
	"Alfred and Plantagenet",
	"West Tawakoni",
	"Irigny",
	"Ilsenburg",
	"Tryavna",
	"Olivares",
	"Schönwalde-Siedlung",
	"Monte Porzio Catone",
	"Khodoriv",
	"Antônio Dias",
	"Warrenton",
	"Marysville",
	"Marlton",
	"Greenville",
	"Attnang-Puchheim",
	"Ledbury",
	"Krasnogvardeyskoye",
	"Ayntap",
	"Nepi",
	"Fiesso d’Artico",
	"Douar Messassa",
	"Lesquin",
	"Alfaro",
	"Stary Sącz",
	"Burlington",
	"Candiota",
	"Rio del Mar",
	"La Victoria de Acentejo",
	"Brand-Erbisdorf",
	"Hârşova",
	"Couzeix",
	"Solsona",
	"Oulad Imloul",
	"Picture Rocks",
	"Águia Branca",
	"Penn",
	"Besozzo",
	"Zwiesel",
	"Cameron",
	"Waterford",
	"Memphis",
	"Pinhel",
	"Traversetolo",
	"Maserà di Padova",
	"Mont-Tremblant",
	"Martensville",
	"Barleben",
	"Fort Stewart",
	"Hartford",
	"Costa Volpino",
	"Port Wentworth",
	"Yorkshire",
	"Uracoa",
	"Scionzier",
	"Hermantown",
	"Zadonsk",
	"Irthlingborough",
	"Winfield",
	"Striano",
	"Twist",
	"Alcanar",
	"Oxford",
	"Manduri",
	"Coleford",
	"Cold Springs",
	"Golbey",
	"Powdersville",
	"La Plata",
	"Merrill",
	"Luckau",
	"Oststeinbek",
	"Bithlo",
	"Penn Forest",
	"Rondon",
	"Gatteo",
	"Landivisiau",
	"Santa Lucia di Piave",
	"Chevy Chase",
	"Borogani",
	"Wadebridge",
	"San Juan del Puerto",
	"Paula Cândido",
	"Worpswede",
	"Elsenfeld",
	"Zschopau",
	"Trebbin",
	"Valsequillo de Gran Canaria",
	"Sierning",
	"Wilmore",
	"Saint-Raymond",
	"Bilozerka",
	"San Pedro Ixtlahuaca",
	"Audley",
	"Saint-Sulpice-la-Pointe",
	"Rainbow City",
	"Levokumskoye",
	"Saint-Pierre-lès-Elbeuf",
	"Brugnera",
	"Mizhhiria",
	"Velyka Dymerka",
	"Miajadas",
	"Santiponce",
	"Santa María",
	"Howell",
	"Pleasant Grove",
	"Blackhawk",
	"Palmeira d’Oeste",
	"Libercourt",
	"Emu Plains",
	"Ubbergen",
	"Hope",
	"Roxboro",
	"Cébazat",
	"Sarahs",
	"Bishop",
	"Petersberg",
	"Kanchanadit",
	"Breuillet",
	"Middletown",
	"Greenville",
	"Tatoufet",
	"Pizarra",
	"Puebla del Caramiñal",
	"Čepin",
	"Obersiggenthal",
	"Valréas",
	"Sankt Johann in Tirol",
	"St. Pete Beach",
	"Rice Lake",
	"Lototla",
	"Hayle",
	"Zawyat Sidi Ben Hamdoun",
	"Bang Khla",
	"Bakaly",
	"Buñol",
	"La Grande-Motte",
	"Manuel Urbano",
	"North Gates",
	"Bezliudivka",
	"Vareš",
	"Reinfeld",
	"Gorē",
	"Altlandsberg",
	"Alderwood Manor",
	"Thurso",
	"Ingenbohl",
	"Niesky",
	"Lwówek Śląski",
	"Capriate San Gervasio",
	"Bni Gmil",
	"Paray-le-Monial",
	"Sugbongkogon",
	"El Campo",
	"Ehringshausen",
	"Yamakita",
	"Casorate Primo",
	"Tombos",
	"Ecorse",
	"Ouroeste",
	"Harleysville",
	"Schübelbach",
	"Fairview",
	"Reichenbach an der Fils",
	"Tanudan",
	"Treorchy",
	"The Village",
	"Mount Holly",
	"Oudenburg",
	"Woodbury",
	"Langnau",
	"Fürstenau",
	"Japurá",
	"Senmanat",
	"Burela de Cabo",
	"Chita",
	"Edelény",
	"Eagle Point",
	"Alayor",
	"Craig",
	"Willoughby Hills",
	"Nalbach",
	"Voreppe",
	"Castel Gandolfo",
	"Pohrebyshche",
	"Walterboro",
	"Hazle",
	"Amherst",
	"Arenys de Munt",
	"Legnaro",
	"White Meadow Lake",
	"Sankt Valentin",
	"Istrana",
	"Mount Vista",
	"Lowell",
	"Lüchow",
	"Fossombrone",
	"El Amim",
	"Neuried",
	"Rehau",
	"Ecatzingo",
	"Ban Tha Phra",
	"Silsbee",
	"Tadworth",
	"Sturbridge",
	"Sebnitz",
	"Viagrande",
	"Orchies",
	"Burayevo",
	"Aragona",
	"Usworth",
	"Coulaines",
	"Harvard",
	"Pagqên",
	"Villa Castelli",
	"Hostivice",
	"Marnate",
	"La Loggia",
	"Central City",
	"Elne",
	"Shanklin",
	"Obernkirchen",
	"Tell City",
	"Belley",
	"Odobeşti",
	"Oberentfelden",
	"Sawbridgeworth",
	"Aschheim",
	"Bansko",
	"Neu Bleckede",
	"Nueva Era",
	"Lambertville",
	"San Fausto de Campcentellas",
	"Ikeda",
	"Palomares del Río",
	"Roxborough Park",
	"Colmenarejo",
	"Rye Brook",
	"Reedsburg",
	"Gomaringen",
	"Cervera",
	"Agudos do Sul",
	"Laurel",
	"Fair Oaks",
	"Saßnitz",
	"Gonfreville-l’Orcher",
	"Zacualpan de Amilpas",
	"DuPont",
	"Runkel",
	"São Domingos",
	"Schaafheim",
	"Hebron",
	"Miltenberg",
	"Nyzhnohirskyi",
	"Mound",
	"Jessup",
	"Mosciano Sant’Angelo",
	"Boiling Springs",
	"Nakoushi",
	"Aleksandrovsk-Sakhalinskiy",
	"Wallerfangen",
	"Puerto Morelos",
	"Wakefield-Peacedale",
	"Erwin",
	"Savignano sul Panaro",
	"Huedin",
	"Waltenhofen",
	"Rigby",
	"Rudnya",
	"Aniskino",
	"Martano",
	"Carácuaro",
	"Fort Stockton",
	"Buriti Alegre",
	"Gaggiano",
	"Onnaing",
	"Iseo",
	"Le Mesnil-Esnard",
	"Dietlikon",
	"Lesnoy Gorodok",
	"Belvedere Marittimo",
	"Ramara",
	"Aadorf",
	"Lamarão",
	"Airway Heights",
	"Minano",
	"Síndos",
	"Riacho dos Machados",
	"Planaltino",
	"Jūraqān",
	"Vettweiß",
	"Olst",
	"Yutsa",
	"Santanópolis",
	"Castellabate",
	"Wustermark",
	"Tanfield",
	"Parsons",
	"Parabita",
	"Rauenberg",
	"Zwenkau",
	"Ortuella",
	"Wertingen",
	"Fayetteville",
	"Townsend",
	"Larmor-Plage",
	"El Molar",
	"Polinyá",
	"Gooik",
	"Meadowbrook",
	"Sidi El Hattab",
	"Olho d’Água do Casado",
	"Chiasso",
	"Lititz",
	"Marcy",
	"Leeds and the Thousand Islands",
	"Siler City",
	"Buchs",
	"Filottrano",
	"Várzea do Poço",
	"Platte City",
	"Lantana",
	"Carignan",
	"La Chapelle d’Armentières",
	"Dunblane",
	"Brockton",
	"Bezhetsk",
	"Woodfield",
	"Codsall",
	"Mount Airy",
	"Mhâjâr",
	"Fársala",
	"Carignano",
	"Ter Aar",
	"Budenheim",
	"Kirs",
	"André Fernandes",
	"Mohelnice",
	"Missaglia",
	"Cervelló",
	"Shira",
	"Südlohn",
	"Maravilha",
	"Aniva",
	"Dubrovytsya",
	"Carencro",
	"Au",
	"Lillebonne",
	"Lovosice",
	"Asolo",
	"Sabangan",
	"Sutton",
	"La Ferté-Bernard",
	"Winslow",
	"Ruidoso",
	"Laghzawna",
	"Pandino",
	"Gramsh",
	"South Strabane",
	"Araújos",
	"Ócsa",
	"Tissint",
	"Hunduan",
	"Hondo",
	"Miles City",
	"Grigoriopol",
	"Trebisacce",
	"Harqalah",
	"Brownsville",
	"Wallan",
	"Maidencreek",
	"Granada",
	"Cúllar-Vega",
	"Manistee",
	"Teisendorf",
	"Rayne",
	"Sauzal",
	"Wunsiedel",
	"Dielheim",
	"Bad Laer",
	"Castelli",
	"Augusta",
	"Carbonita",
	"Le Muy",
	"Gammasa",
	"Orivesi",
	"Varzedo",
	"Spáta",
	"Krasnoslobodsk",
	"Ichenhausen",
	"New Richmond",
	"Greenwood",
	"Ameskroud",
	"Laufenburg (Baden)",
	"Gouvieux",
	"São Sebastião do Alto",
	"Krichim",
	"Lidzbark",
	"Tenabo",
	"Noventa Vicentina",
	"Monticello",
	"Treia",
	"St. Stephens",
	"Santa Comba",
	"Little Falls",
	"Panganiban",
	"Great Dunmow",
	"Braslaw",
	"Pirambu",
	"Verkhneyarkeyevo",
	"Kandel",
	"Bryan",
	"La Bruyère",
	"Groenlo",
	"Amityville",
	"Lamorlaye",
	"Porcari",
	"Tomah",
	"Närpes",
	"Tysmenytsia",
	"Séné",
	"Reinach",
	"Douar Ezzerarda",
	"San Antonio",
	"Cavalcante",
	"Brunswick",
	"Le Mont-sur-Lausanne",
	"Usakos",
	"Bovalino Marina",
	"Morières-lès-Avignon",
	"Campo Erê",
	"Navoloki",
	"Sompting",
	"Unieux",
	"Roanoke",
	"Argoncilhe",
	"Francisville",
	"Hickam Housing",
	"Laurentian Valley",
	"Muro del Alcoy",
	"Navasota",
	"Le Rheu",
	"Erdőkertes",
	"Calusco d’Adda",
	"Eemnes",
	"Elsloo",
	"Coccaglio",
	"Schlangen",
	"Bandol",
	"Irshava",
	"Igaratá",
	"Livron-sur-Drôme",
	"Flowood",
	"Pryor Creek",
	"Ouled Rached",
	"Hingyon",
	"Parempuyre",
	"Kargat",
	"Néa Péramos",
	"Currumbin",
	"Bardmoor",
	"East St. Paul",
	"Crissier",
	"Périgny",
	"Santa Coloma de Cervelló",
	"Ifigha",
	"Bloomfield",
	"Cairo",
	"Tonneins",
	"Rafelbuñol",
	"Castegnato",
	"Tayr Ma‘lah",
	"Aidlingen",
	"Rio das Flores",
	"Dayton",
	"Siteía",
	"Kyritz",
	"Boonville",
	"Les Sorinières",
	"Richterich",
	"Putnam",
	"Bli Bli",
	"Louisville",
	"Beverly Hills",
	"Salisbury",
	"Baía Formosa",
	"Nová Paka",
	"Nieuwleusen",
	"Brownfield",
	"Tvrdošín",
	"Clay Cross",
	"Caputira",
	"Cappelle-la-Grande",
	"Santa María de Cayón",
	"Hackettstown",
	"Pesca",
	"Stuarts Draft",
	"Bay Minette",
	"Isbergues",
	"Monteforte d’Alpone",
	"Lorraine",
	"Khotyn",
	"Golden Hills",
	"Thompson",
	"Verkhneuralsk",
	"Podenzano",
	"Edwards",
	"Nagykálló",
	"Ariranha",
	"Loreto",
	"Masandra",
	"Woodmoor",
	"Liberty",
	"Ipiranga do Piauí",
	"Pfedelbach",
	"Vendin-le-Vieil",
	"Segni",
	"Saline",
	"Catral",
	"Villars",
	"Courcelles-les-Lens",
	"St. Augustine Shores",
	"Madras",
	"Ineu",
	"San Sebastián de la Gomera",
	"Pietra Ligure",
	"Catuípe",
	"Caluco",
	"Kaleybar",
	"Vallet",
	"Cherasco",
	"Vallendar",
	"Clinton",
	"Porangaba",
	"Banārūyeh",
	"Hohenhameln",
	"Ngaruawahia",
	"Lacchiarella",
	"Le Petit-Couronne",
	"Heath",
	"Sainte-Julienne",
	"Claymont",
	"Tirano",
	"Zanica",
	"Chaponost",
	"Shimizu",
	"Blackfalds",
	"Stansbury Park",
	"Rotonda",
	"Marlboro Village",
	"Luzzi",
	"Belousovo",
	"Lenoir City",
	"Reggiolo",
	"Birch Bay",
	"Portland",
	"Edingerhof",
	"Smilde",
	"Coqueiral",
	"Hartland",
	"Nakhon Thai",
	"Waihee-Waiehu",
	"Landquart",
	"Sint-Martens-Lennik",
	"Moore",
	"College Place",
	"Svit",
	"Liberty",
	"Inveruno",
	"Trumpington",
	"Bad Bevensen",
	"Stevenston",
	"Montoro",
	"Veľké Kapušany",
	"Nong Kung Si",
	"Teolo",
	"Holten",
	"Velykyi Bychkiv",
	"Capbreton",
	"Cistérniga",
	"Hombrechtikon",
	"Zimmerman",
	"Hemau",
	"Freetown",
	"Hohenbrunn",
	"Biblis",
	"Zörbig",
	"Newfane",
	"Dardilly",
	"Wesley Chapel",
	"Burscough",
	"Malahide",
	"Shijak",
	"Urbach",
	"Suwannaphum",
	"Didymóteicho",
	"Seddouk Oufella",
	"Detroit Lakes",
	"Gerstungen",
	"La Fare-les-Oliviers",
	"Gonzaga",
	"Hünenberg",
	"Fultondale",
	"Tlahuiltepa",
	"North College Hill",
	"São José do Cerrito",
	"Herculândia",
	"Torgelow",
	"Gnarrenburg",
	"Pozo Almonte",
	"Tanakoub",
	"Westerland",
	"Castelões de Cepeda",
	"Horodenka",
	"Cape Elizabeth",
	"Tecklenburg",
	"Cross Lanes",
	"Lo Miranda",
	"Benner",
	"Fuldabrück",
	"Zavyalovo",
	"Wolfurt",
	"Oulad Cherif",
	"Santa María del Tule",
	"Chotěboř",
	"Marathon",
	"Westwood",
	"Sidi Lahsene",
	"Mendig",
	"Valky",
	"Altenbeken",
	"Salto Grande",
	"Biberist",
	"Kolkwitz",
	"Lexington",
	"Blackstone",
	"Bohemia",
	"Hernando",
	"Dumont",
	"Hawkinge",
	"Pokrovsk",
	"Troina",
	"Malalbergo",
	"Nizhniye Sergi",
	"Bad Liebenwerda",
	"Neshannock",
	"Natividade",
	"Currie",
	"Kalanchak",
	"Melgaço",
	"Hastings",
	"Cheat Lake",
	"Harahan",
	"Pásztó",
	"Sredets",
	"Le Thor",
	"Washington Terrace",
	"Salzhemmendorf",
	"Sultan",
	"Wendover",
	"Pong Nam Ron",
	"Muncy",
	"Madeira",
	"Colorno",
	"Princes Risborough",
	"Saidia",
	"Abaíra",
	"Valldoreix",
	"Carneiros",
	"Anthony",
	"Obukhivka",
	"Rosário do Catete",
	"Clermont-l’Hérault",
	"Lempdes",
	"Gridley",
	"Washington",
	"Saint-Paul-Trois-Châteaux",
	"Heroldsberg",
	"San Fructuoso de Bagés",
	"Ziębice",
	"Veliki Preslav",
	"Kendall Park",
	"Douar Snada",
	"Haram",
	"Manchester",
	"Saint-Claude",
	"Skovorodino",
	"Mittenwalde",
	"Roquevaire",
	"Furth im Wald",
	"Walworth",
	"Kamień Pomorski",
	"Soresina",
	"Girard",
	"Montagnana",
	"Coronel Murta",
	"Hirayama",
	"Moss Vale",
	"I-n-Amenas",
	"Hillview",
	"Burlington",
	"Stryzhavka",
	"Almargem",
	"Baluntaicun",
	"Oromocto",
	"La Cruz",
	"Ponte Buggianese",
	"Steger",
	"Sans Souci",
	"Serramanna",
	"Tarcento",
	"Tineo",
	"White Marsh",
	"Yaxley",
	"Sidi Ouassay",
	"Spanish Fort",
	"Krompachy",
	"Savenay",
	"Longvic",
	"Windisch",
	"Wanaka",
	"Notre-Dame-de-Gravenchon",
	"Chtiba",
	"Lake Elmo",
	"Bethalto",
	"Dallas",
	"Obyachevo",
	"Brewood",
	"Trittau",
	"Bir Tam Tam",
	"Cloverdale",
	"Copceac",
	"Achicourt",
	"Borgoricco",
	"Independent Hill",
	"Mendon",
	"Crawford",
	"Malzéville",
	"Furtwangen im Schwarzwald",
	"Sabiñánigo",
	"Gretz-Armainvilliers",
	"Unterägeri",
	"Santo Domingo Petapa",
	"Abdurahmoni Jomí",
	"Good Hope",
	"Luzzara",
	"Chécy",
	"Lynwood",
	"Basalt",
	"Ivins",
	"Egg",
	"Neuhaus am Rennweg",
	"Tibaná",
	"Fujisawachō-niinuma",
	"Suances",
	"Curno",
	"Wagoner",
	"Elsfleth",
	"Nagaoki",
	"Olds",
	"Piriápolis",
	"Verdello",
	"Plainville",
	"Żychlin",
	"Brimington",
	"Segorbe",
	"Bethel",
	"Mwaline al Oued",
	"Roussillon",
	"St. Marys",
	"Elwood",
	"Zogno",
	"Waldheim",
	"Horokhiv",
	"San Cesario di Lecce",
	"Aratuípe",
	"Karis",
	"Salcea",
	"Timmendorfer Strand",
	"Sítio Novo de Goiás",
	"Redding",
	"Neuötting",
	"Bloomingdale",
	"Parakar",
	"Sullivan",
	"Rensselaer",
	"Clifton Springs",
	"Tournan-en-Brie",
	"Tinajdad",
	"Ortenberg",
	"Petilia Policastro",
	"Mikhaylovka",
	"Hallstadt",
	"Woltersdorf",
	"Memmelsdorf",
	"Akat Amnuai",
	"Bingham",
	"Abalessa",
	"Perkiomen",
	"Audubon",
	"Verdejante",
	"Aviano",
	"Calimesa",
	"Dunn Loring",
	"Modra",
	"Plön",
	"Monteroni d’Arbia",
	"Flossmoor",
	"Beckett Ridge",
	"Plymouth",
	"Ridgefield",
	"Bruchhausen-Vilsen",
	"Shaftesbury",
	"Hooper",
	"Castagneto Carducci",
	"Tombolo",
	"Minyar",
	"Rathdrum",
	"Bichura",
	"Villa Chalcatongo de Hidalgo",
	"Thief River Falls",
	"Kressbronn am Bodensee",
	"Canillá",
	"Thorigné-Fouillard",
	"San Zenón",
	"Fairview",
	"Devnya",
	"Saint-Philbert-de-Grand-Lieu",
	"Pā’īn Chāf",
	"Oudover",
	"Palmeiras",
	"Katoomba",
	"Ystradgynlais",
	"Terra Roxa",
	"Urânia",
	"Chadan",
	"Iijima",
	"Whitnash",
	"Fairview",
	"Kißlegg",
	"Lorgues",
	"Estanzuelas",
	"Anaconda",
	"Seini",
	"Duchcov",
	"Waziers",
	"Nangis",
	"Huron East",
	"Castelnuovo Berardenga",
	"Murrells Inlet",
	"Kaeng Khro",
	"Oberriet",
	"Tulbagh",
	"Gtarna",
	"Borgholzhausen",
	"Cockermouth",
	"Grabels",
	"Velyki Luchky",
	"Binasco",
	"Union",
	"Boukhralfa",
	"Mühlhausen",
	"El Espinar",
	"Iguidiy",
	"Coutances",
	"Lachen",
	"Woodburn",
	"Le Teil",
	"Challapata",
	"Elze",
	"Schulzendorf",
	"Civitella in Val di Chiana",
	"Freystadt",
	"Guardiagrele",
	"Monett",
	"Cogoleto",
	"Doberlug-Kirchhain",
	"Tarusa",
	"Velden am Wörthersee",
	"Bolokhovo",
	"Embalse",
	"Sângeorgiu de Mureş",
	"Almagro",
	"Mnichovo Hradiště",
	"Grinnell",
	"Jersey Shore",
	"Cutrofiano",
	"Grosse Pointe Farms",
	"Rapid Valley",
	"Thung Sai",
	"Tiachiv",
	"Kosum Phisai",
	"Linganore",
	"Edgewater",
	"Vergiate",
	"Nittenau",
	"São Jorge d’Oeste",
	"Farra di Soligo",
	"Stará Turá",
	"Atmore",
	"Somero",
	"Roccastrada",
	"Trissino",
	"Whitwick",
	"Felino",
	"Guananico",
	"Kharovsk",
	"Dzhalka",
	"West Caln",
	"Waldenbuch",
	"Loeches",
	"Rada Tilly",
	"Economy",
	"Kratovo",
	"Émerainville",
	"Villa Morelos",
	"Plymouth",
	"Burton",
	"Chiţcani",
	"Berthoud",
	"Bellerive-sur-Allier",
	"Epanomí",
	"Whitestown",
	"Galbiate",
	"Portlethen",
	"Quéven",
	"Breckerfeld",
	"San Elizario",
	"Dillon",
	"L’Isle-Jourdain",
	"Herzberg",
	"Siklós",
	"Clinton",
	"Tha Luang",
	"Livno",
	"Casca",
	"Tiburon",
	"Monmouth",
	"Silves",
	"Highland Park",
	"Queensferry",
	"Sugarmill Woods",
	"Grenade",
	"Dörverden",
	"Vinhais",
	"Golden Valley",
	"Verkhnyaya Tura",
	"Northwest Harborcreek",
	"Lançon-Provence",
	"Hoevelaken",
	"Erzhausen",
	"Kuvshinovo",
	"Lunner",
	"Chrysoúpoli",
	"Muhos",
	"Waldfeucht",
	"Jeannette",
	"Polička",
	"Rafard",
	"Bellheim",
	"Marlton",
	"Werneuchen",
	"Guerrero",
	"Armadale",
	"Swalmen",
	"Windermere",
	"Sutherlin",
	"Had Dra",
	"Bolnisi",
	"Abcoude",
	"Khoni",
	"Wollert",
	"Picnic Point",
	"Villafranca Tirrena",
	"Obernburg am Main",
	"Barrington",
	"Newmarket",
	"Villa Guardia",
	"Hirson",
	"Rivesaltes",
	"Seaside",
	"Nort-sur-Erdre",
	"Sint-Martens-Latem",
	"Kelso",
	"Subiaco",
	"Ceprano",
	"Campogalliano",
	"Merrimac",
	"Laveno-Mombello",
	"Marshall",
	"Privas",
	"North Codorus",
	"Volvera",
	"Binisalem",
	"Livingston",
	"Morlupo",
	"Neves Paulista",
	"Entraigues-sur-la-Sorgue",
	"Fort Polk South",
	"Cherry Hinton",
	"Micco",
	"Hornsea",
	"Santa Rita de Caldas",
	"Flekkefjord",
	"Kozova",
	"Puig",
	"Ballenstedt",
	"Chanute",
	"Beecher",
	"Linselles",
	"Rakitovo",
	"Libonik",
	"Ayr",
	"Stanley",
	"Monistrol-sur-Loire",
	"Indianola",
	"San Giorgio di Piano",
	"Ferryhill",
	"Uspenka",
	"Brewer",
	"Sande",
	"Candeal",
	"Albinea",
	"Corozal",
	"La Grange",
	"McFarland",
	"Silver Springs Shores",
	"Shelby",
	"Weare",
	"Rhymney",
	"Pineville",
	"Wervershoof",
	"Thap Than",
	"Trégueux",
	"Montalto di Castro",
	"Sint-Amands",
	"Cavenago di Brianza",
	"La Ricamarie",
	"Woodway",
	"Queimada Nova",
	"Oakbrook",
	"Valley Cottage",
	"Lindstrom",
	"Littleport",
	"Pomichna",
	"Shiprock",
	"Zawyat Sidi al Mekki",
	"Cinderford",
	"Babadag",
	"St. Anthony",
	"Buşteni",
	"Peymeinade",
	"Jauru",
	"Koekelare",
	"Uricani",
	"Presque Isle",
	"East Haddam",
	"Manzanares el Real",
	"Worplesdon",
	"Volkach",
	"Karkkila",
	"Puerto Octay",
	"Engerwitzdorf",
	"Ochsenhausen",
	"Hamlin",
	"Santa Venerina",
	"San Francisco Ixhuatan",
	"Saint-Germain-lès-Corbeil",
	"Manteno",
	"Yelnya",
	"Vera Cruz do Oeste",
	"Pocrane",
	"Lake Mohawk",
	"Crest",
	"Wenzenbach",
	"Mbamba Bay",
	"Dexter",
	"Diss",
	"Delta",
	"Szentgotthárd",
	"Foum Zguid",
	"Smimou",
	"Dobříš",
	"Chamical",
	"Chop",
	"Fully",
	"Piovene Rocchette",
	"Mechanicsburg",
	"Albion",
	"Chyhyryn",
	"Merstham",
	"Springfield",
	"Domérat",
	"Tepechitlán",
	"Banak",
	"Haiku-Pauwela",
	"Veľký Meder",
	"Skidaway Island",
	"LaSalle",
	"Fairfield Glade",
	"Belleville",
	"Waseca",
	"Fehrbellin",
	"Breganze",
	"Psyzh",
	"Choceň",
	"Sarlat-la-Canéda",
	"Great Cornard",
	"Terville",
	"Potiraguá",
	"Monastyryshche",
	"Marguerittes",
	"Garching an der Alz",
	"Murphysboro",
	"Santo Tomás de los Plátanos",
	"East Nottingham",
	"Bumbeşti-Jiu",
	"Florstadt",
	"Nuquí",
	"Noisy-le-Roi",
	"Morpará",
	"Fallston",
	"Triangle",
	"Saint-Vallier",
	"San Pablo Villa de Mitla",
	"Kui Buri",
	"Kiskunlacháza",
	"Borgo",
	"Chester",
	"Săbăoani",
	"Horden",
	"Vicopisano",
	"Minerbio",
	"Parque del Plata",
	"Derby",
	"Penetanguishene",
	"Byfleet",
	"Ala",
	"Schleiz",
	"Lacombe",
	"Povarovo",
	"Felida",
	"Dashouping",
	"Velaux",
	"Tlumach",
	"Macedon",
	"Itapiranga",
	"Naunhof",
	"Entre Ijuís",
	"Airola",
	"Kusterdingen",
	"Thames",
	"Rain",
	"Sekimachi",
	"Eslohe",
	"Hermsdorf",
	"Summit",
	"Clinton",
	"Salach",
	"Auerbach",
	"Qualicum Beach",
	"Tepetitlan",
	"Florø",
	"Neuville-sur-Saône",
	"San Ramón",
	"Almagro",
	"Sautron",
	"Hanko",
	"Lake Arrowhead",
	"Clusone",
	"Paulo de Faria",
	"Iskourane",
	"Oakwood",
	"Bockhorn",
	"Sturgeon Bay",
	"Mikhaylovsk",
	"Deutsch-Wagram",
	"Harrisburg",
	"Liman",
	"Eceabat",
	"Independence",
	"Meine",
	"Quibala",
	"Taxkorgan",
	"Jouy-en-Josas",
	"Lequile",
	"Forestdale",
	"San Giuseppe Iato",
	"Capri",
	"Achacachi",
	"Orte",
	"Suoyarvi",
	"Nordheim",
	"Serafimovich",
	"Hilzingen",
	"Boonton",
	"Penig",
	"Camenca",
	"Ilarionove",
	"Oak Grove",
	"Harrodsburg",
	"Sheffield Lake",
	"Saint-Chamas",
	"Pedrinhas",
	"Morada Nova de Minas",
	"Silleda",
	"Tarrafas",
	"Topchikha",
	"Malhada de Pedras",
	"Szerencs",
	"Baía da Traição",
	"Cedar Hills",
	"Belsh",
	"Upper Leacock",
	"Ararat",
	"Kozlovka",
	"Aït Ouaoumana",
	"Aledo",
	"Makhambet",
	"Sellersburg",
	"Cordisburgo",
	"Charters Towers",
	"Stadtilm",
	"Blanchard",
	"Wattwil",
	"San José de Gracia",
	"Takua Pa",
	"Lisbon",
	"Southside",
	"Whitehouse",
	"Sadanga",
	"Merrydale",
	"Połaniec",
	"South Abington",
	"Castelnuovo di Magra",
	"Alvarado",
	"Ait Ikkou",
	"Caister-on-Sea",
	"Sheffield",
	"St. Albans",
	"Brejão",
	"Msemrir",
	"Pudozh",
	"Minervino Murge",
	"Pittsgrove",
	"São Sebastião da Amoreira",
	"Langenzersdorf",
	"Rossano Veneto",
	"Ouriçangas",
	"Rainworth",
	"Koror",
	"Septemvri",
	"Rye",
	"Santa María Xadani",
	"Bad Schussenried",
	"Landstuhl",
	"O'Hara",
	"Oakland",
	"San Isidro",
	"West Haven-Sylvan",
	"Innsbrook",
	"Rodelas",
	"Imilchil",
	"Marilândia do Sul",
	"Palhano",
	"Bad Lauchstädt",
	"Anaurilândia",
	"Evergreen",
	"Kolbuszowa",
	"Vif",
	"San Joaquín",
	"Bad Bergzabern",
	"Logan",
	"Vráble",
	"Gilgit",
	"Nova Bassano",
	"Ak’ordat",
	"El Chol",
	"Nierstein",
	"Rignano sull’Arno",
	"Piliscsaba",
	"Ciudad Cerralvo",
	"Lenox",
	"Gresham Park",
	"Föritz",
	"Oulad Rahal",
	"Loenen",
	"Bang Mun Nak",
	"Bom Jardim de Goiás",
	"Congonhinhas",
	"Bussy",
	"Bouhlou",
	"Buda-Kashalyova",
	"Neya",
	"Barth",
	"Iaboutene",
	"Notre-Dame-des-Prairies",
	"Polesworth",
	"Vallecrosia",
	"Weilmünster",
	"Hamilton",
	"Brecon",
	"Hemmoor",
	"Erchie",
	"West Perth",
	"Vestignè",
	"Poteau",
	"Tona",
	"Ponta do Sol",
	"Old Orchard Beach",
	"Taftanāz",
	"Moe",
	"Roetgen",
	"Cabo Rojo",
	"Agadir Melloul",
	"Douglas",
	"New Prague",
	"Cheste",
	"Lajedo do Tabocal",
	"Vaujours",
	"São José da Bela Vista",
	"Rrëshen",
	"Pa Sang",
	"Taragi",
	"Jackson",
	"Soeda",
	"Icaraíma",
	"Woodbury",
	"Kodiak",
	"Kirk of Shotts",
	"Schwarzenbruck",
	"Cordeiros",
	"San Lorenzo de Descardazar",
	"Pratápolis",
	"Guichen",
	"Deniliquin",
	"Pibrac",
	"Cuggiono",
	"Guamiranga",
	"Altenerding",
	"Castelnuovo di Porto",
	"Aliquippa",
	"Valle de Guadalupe",
	"Bridgeport",
	"Großbottwar",
	"Crolles",
	"Berkeley",
	"Dinxperlo",
	"Efringen-Kirchen",
	"Santa Barbara do Sul",
	"Riverdale",
	"Phimai",
	"Darley",
	"Lower Swatara",
	"Coutras",
	"Amarzgane",
	"Shopokov",
	"Pianella",
	"Uhldingen-Mühlhofen",
	"Zoeterwoude",
	"Covington",
	"Barlin",
	"Hořice",
	"Dornstadt",
	"Mkazi",
	"Sonsbeck",
	"Bálsamo",
	"Castelbuono",
	"El Marmouta",
	"Rhinelander",
	"Seabrook",
	"San Jacinto",
	"Ōtaki",
	"Chartres-de-Bretagne",
	"Ville Platte",
	"Cavan Monaghan",
	"Olmsted Falls",
	"Boville Ernica",
	"Woodbridge",
	"Mansfeld",
	"Glencoe",
	"Lyaskovets",
	"Kryzhopil",
	"Poisy",
	"Orange Park",
	"Agnita",
	"Mürzzuschlag",
	"Kumphawapi",
	"Semily",
	"Krumovgrad",
	"Guajeru",
	"Canal Winchester",
	"Absecon",
	"Kappeln",
	"Yacimiento Río Turbio",
	"Yang Talat",
	"Mazingarbe",
	"Glens Falls North",
	"Palm Beach",
	"Origgio",
	"Venelles",
	"Casarsa della Delizia",
	"Soltsy",
	"Caimanera",
	"West Donegal",
	"Gorodovikovsk",
	"Ban Na",
	"Balatonalmádi",
	"Rochford",
	"Nam Som",
	"Molbergen",
	"Old Colwyn",
	"Cedral",
	"Dentergem",
	"Topsham",
	"Tiqqi",
	"Armazém",
	"Příbor",
	"Saint-Jean-de-Monts",
	"Nanzhangcheng",
	"Kuhmo",
	"Sidi Dahbi",
	"Heek",
	"Tordesillas",
	"North Sarasota",
	"Tomazina",
	"Hürtgenwald",
	"Hassi Berkane",
	"Iles",
	"Flieden",
	"Franklin",
	"Richmond Heights",
	"Redenção do Gurguéia",
	"Oakville",
	"Kukrahill",
	"Santa Bárbara",
	"Arnprior",
	"Clanton",
	"Borsdorf",
	"Conthey",
	"Prata di Pordenone",
	"Bad Sooden-Allendorf",
	"Heel",
	"Padul",
	"Roznov",
	"Mariinskiy Posad",
	"Tirschenreuth",
	"Kuppenheim",
	"Belozërsk",
	"Saint-Marcellin",
	"Araguaçu",
	"Iernut",
	"Zapatoca",
	"Airmont",
	"Quakertown",
	"Walmer",
	"Hausham",
	"Tleta Taghramt",
	"Kleinostheim",
	"Sodankylä",
	"Jacou",
	"Les Angles",
	"Esopus",
	"Castiglione Olona",
	"Smiths Falls",
	"Monson",
	"Citrus Springs",
	"Villamediana de Iregua",
	"San Esteban",
	"Miyada",
	"Seebad Heringsdorf",
	"Bad Rothenfelde",
	"Jarny",
	"Monte San Savino",
	"Azandarīān",
	"Lubuagan",
	"Redován",
	"Mücheln",
	"Villas",
	"Falaise",
	"Jesús Nazareno",
	"Berching",
	"Tagapul-an",
	"Frankfort Square",
	"Monsenhor Paulo",
	"Santa Isabel do Ivaí",
	"Upholland",
	"Pont-l’Abbé",
	"Wharton",
	"Redlands",
	"Dionísio",
	"Stillwater",
	"Hajdúdorog",
	"South Kensington",
	"Calbe",
	"Tashir",
	"Llagostera",
	"Fara Gera d’Adda",
	"Canonsburg",
	"Sandrigo",
	"Edgewood",
	"Seyssins",
	"Graham",
	"Tifra",
	"Solarino",
	"Hainichen",
	"Serra dos Aimorés",
	"New Albany",
	"Clarendon Hills",
	"Needles",
	"Hausjärvi",
	"Uzès",
	"Forst",
	"Saint-Jean-d’Illac",
	"Porkhov",
	"Amalou",
	"Vásárosnamény",
	"Pino Troinese",
	"Norton Canes",
	"Nossa Senhora dos Remédios",
	"Roverbella",
	"Stainz",
	"Hernani",
	"Ibirapuã",
	"Junín",
	"Obertraubling",
	"Ripponden",
	"Santa Lúcia",
	"Štětí",
	"Volchansk",
	"Belo Pole",
	"Lincoln",
	"Gremyachinsk",
	"Hemmingen",
	"Pitman",
	"Rock Falls",
	"Baraolt",
	"São Vicente do Sul",
	"Motta Visconti",
	"Heikendorf",
	"Palazzolo Acreide",
	"Perkasie",
	"Clayton",
	"Moranbah",
	"Forbes",
	"Guiricema",
	"Cortez",
	"Sileby",
	"Bembibre",
	"Calvisano",
	"Évron",
	"Princeton",
	"Saint-Renan",
	"Rava-Rus’ka",
	"Olney",
	"Sanger",
	"Barntrup",
	"Hadleigh",
	"Ban Buak Khang",
	"Cape St. Claire",
	"Reichelsheim",
	"Steinheim am Albuch",
	"Modelu",
	"Kościelisko",
	"Solotvyno",
	"Paray-Vieille-Poste",
	"Seneca Falls",
	"Kirby",
	"Pont-Rouge",
	"Staufenberg",
	"El Corrillo",
	"Chamonix-Mont-Blanc",
	"Vado Ligure",
	"Tăşnad",
	"Szeghalom",
	"Fürstenfeld",
	"Tlanalapa",
	"Smižany",
	"Neuenkirchen",
	"Glenwood",
	"Maryborough",
	"Dumbarton",
	"Grünheide",
	"Garden City",
	"Alvorada do Norte",
	"Kirchen",
	"Baillargues",
	"Monte Urano",
	"Crestline",
	"Gyümai",
	"Jerseyville",
	"Corella",
	"Piranguinho",
	"Bessancourt",
	"Champlain",
	"Perg",
	"Chiusi",
	"Citrusdal",
	"New Scotland",
	"Spello",
	"Weida",
	"Ulstein",
	"Thompsonville",
	"Bersenbrück",
	"Castelnovo di Sotto",
	"Pégomas",
	"Feldkirchen",
	"Mrakovo",
	"Sečovce",
	"Ronciglione",
	"Băbeni",
	"Nevada",
	"Bonnybridge",
	"Tuneiras do Oeste",
	"Coaticook",
	"Des Peres",
	"Raunds",
	"Tangará",
	"Perryville",
	"Corrales",
	"London Grove",
	"Tibro",
	"São Miguel",
	"Kortessem",
	"Fort Knox",
	"Greenville",
	"Georgetown",
	"Nakhon Chai Si",
	"Park Ridge",
	"Fern Park",
	"Uglegorsk",
	"San Gavino Monreale",
	"Edgewater Park",
	"Santana do Manhuaçu",
	"Toro",
	"Villanueva de Córdoba",
	"Chikhli",
	"Ueckermünde",
	"Altavilla Milicia",
	"La Colle-sur-Loup",
	"Virgínia",
	"Pickering",
	"Waltham Cross",
	"Kirn",
	"Monte Alegre de Goiás",
	"Nazareno",
	"Rutland",
	"Montesano",
	"Zomergem",
	"Bicaz",
	"Gubden",
	"Pleasant Hill",
	"Vallo della Lucania",
	"Mels",
	"Mata Verde",
	"Aureilhan",
	"Perl",
	"Andalusia",
	"Zoutleeuw",
	"Capitólio",
	"Ballsh",
	"El Espinal",
	"Červený Kostelec",
	"Busk",
	"Polegate",
	"Großräschen",
	"Barvynkove",
	"Fermignano",
	"Nogara",
	"Newstead",
	"Scott",
	"Nádudvar",
	"Harlington",
	"Westampton",
	"Minto",
	"Dunaföldvár",
	"Zoppola",
	"Binfield",
	"Incline Village",
	"Glenshaw",
	"Mount Pleasant",
	"Cresskill",
	"Earlimart",
	"Morden",
	"Hakubachō",
	"Hawthorn Woods",
	"Cologna Veneta",
	"Unterwellenborn",
	"Ferreira do Zêzere",
	"La Libertad",
	"Lakhzazra",
	"Caerano di San Marco",
	"Reocín",
	"Bientina",
	"Reginópolis",
	"Middlebury",
	"Nishiōwa",
	"Ţihnā al Jabal",
	"Marlborough",
	"Vigliano Biellese",
	"Marcoussis",
	"Klingenthal",
	"Comendador Levy Gasparian",
	"Santhià",
	"Bushtyno",
	"Hoquiam",
	"Lambarkiyine",
	"Sortino",
	"Antônio Carlos",
	"Penkridge",
	"Brigantine",
	"Teterow",
	"Elimäki",
	"Dobrada",
	"Le Poiré-sur-Vie",
	"Fishersville",
	"Kings Grant",
	"Concordia sulla Secchia",
	"Kennedale",
	"Brightlingsea",
	"Biederitz",
	"Usiacurí",
	"Groß Kreutz",
	"Lienen",
	"Andrushivka",
	"San Sebastián",
	"Matawan",
	"Premnitz",
	"Monahans",
	"Elz",
	"Skewen",
	"Großenlüder",
	"Bigastro",
	"San Sperate",
	"Iksane",
	"Dorgali",
	"Santa Eulalia",
	"Plabennec",
	"Springwood",
	"Ferrandina",
	"Nefasīt",
	"Langueux",
	"Adelsdorf",
	"North Madison",
	"Bang Len",
	"Laishevo",
	"Sarzeau",
	"Oppenheim",
	"Ustyuzhna",
	"Prairie du Sac",
	"Yasinia",
	"Nazária",
	"Lützen",
	"La Belleza",
	"York",
	"Oulad Khallouf",
	"Amapá",
	"Hampstead",
	"Salem",
	"Highfields",
	"Paddock Wood",
	"Canford Cliffs",
	"Byureghavan",
	"Colméia",
	"Saint-Rémy-lès-Chevreuse",
	"Gavorrano",
	"Hyrum",
	"Lipki",
	"Kahl am Main",
	"Richlands",
	"Ittiri",
	"Schieder-Schwalenberg",
	"Vorden",
	"Ladue",
	"Rockland",
	"Caerleon",
	"Verkhoturye",
	"Simmern",
	"Kosiv",
	"Takiéta",
	"Alamo Heights",
	"Leporano",
	"Sawang Daen Din",
	"Le Grau-du-Roi",
	"Schuylkill",
	"Reserve",
	"Takaharu",
	"Osternienburg",
	"Lauta",
	"Camutanga",
	"Orting",
	"Ponte di Piave",
	"Tuba City",
	"Mono",
	"Quirino",
	"Rülzheim",
	"Untergruppenbach",
	"Wattens",
	"Francisco Santos",
	"Cabezón de la Sal",
	"Kiuruvesi",
	"Martins Soares",
	"Muzo",
	"Signal Mountain",
	"Fairmount",
	"Goundam",
	"Nonnweiler",
	"Riverside",
	"Corning",
	"Mauléon",
	"Geroskípou",
	"Orrville",
	"Niederwerrn",
	"Aigues-Mortes",
	"East Brandywine",
	"Foyos",
	"Itauçu",
	"Riacho dos Cavalos",
	"Elsmere",
	"Aksakovo",
	"Farmville",
	"Schnaittach",
	"Wissen",
	"Bovezzo",
	"Bompas",
	"Tanquinho",
	"Feurs",
	"Saint-Maixent-l’École",
	"Long Hill",
	"Sheridan",
	"Innisfail",
	"Hengelo",
	"Torre de Moncorvo",
	"Sanatoga",
	"Bad Endorf",
	"Tuparendi",
	"Borgentreich",
	"Richmond",
	"Tasso Fragoso",
	"Cagli",
	"Birkenwerder",
	"Simeonovgrad",
	"Kalifornsky",
	"Strathaven",
	"Willowbrook",
	"Isaías Coelho",
	"Itamari",
	"Ollería",
	"Whitworth",
	"Verkhniy Mamon",
	"Springfield",
	"Green Cove Springs",
	"Solebury",
	"Caprino Veronese",
	"White Horse",
	"Meltham",
	"Perry Heights",
	"Kellinghusen",
	"Pöytyä",
	"Rhôs-on-Sea",
	"Üröm",
	"Lake Villa",
	"Jóia",
	"Colditz",
	"Guntersville",
	"Krupki",
	"Großkrotzenburg",
	"Oualidia",
	"Karmaskaly",
	"Montvale",
	"Rümlang",
	"Aspen",
	"Tunga",
	"Ione",
	"Hoeven",
	"Joane",
	"Litchfield",
	"Paverama",
	"Sanluri",
	"Brooksville",
	"Bühlertal",
	"Riverside",
	"Wyoming",
	"Staufen im Breisgau",
	"West Frankfort",
	"Yasnohirka",
	"Novoselitskoye",
	"Non Sang",
	"Mamurras",
	"Iwaizumi",
	"Pochinok",
	"Indian Harbour Beach",
	"Dzhebel",
	"Helmbrechts",
	"Lake Park",
	"Sala Bolognese",
	"Murten",
	"Marcolândia",
	"Lanco",
	"Kildare",
	"Lucas",
	"Japaratinga",
	"Araponga",
	"Piedmont",
	"Dubovskoye",
	"Minusio",
	"Franklin",
	"Itaquara",
	"Talnique",
	"Talambote",
	"Bad Grund",
	"Chumsaeng",
	"Canet de Berenguer",
	"Habo",
	"Moordrecht",
	"Großbeeren",
	"Obluchye",
	"Conover",
	"Mourniés",
	"Gingelom",
	"Dulovo",
	"Padrón",
	"Wetumpka",
	"Port-Saint-Louis-du-Rhône",
	"Lößnitz",
	"Vietri sul Mare",
	"Ladysmith",
	"Conkal",
	"Laitila",
	"Divjakë",
	"East Grand Forks",
	"Mansfield",
	"Bridgewater",
	"Ottobeuren",
	"Decimomannu",
	"Janzé",
	"Agua Blanca Iturbide",
	"Guaraçaí",
	"Park Hills",
	"Yarmouth",
	"Nova Glória",
	"Meßkirch",
	"Libertad",
	"Laurel",
	"Edgemere",
	"Cullompton",
	"Ilha das Flores",
	"Freistadt",
	"Southampton",
	"Berg",
	"Laanoussar",
	"Ergué-Gabéric",
	"Mooroopna",
	"Dirksland",
	"San Damiano d’Asti",
	"Morrisville",
	"Rawlins",
	"Tizoual",
	"Legazpia",
	"Negreşti",
	"Saint-Gilles-Croix-de-Vie",
	"Narrabri",
	"Succasunna",
	"Ait Ali Mimoune",
	"Ouderkerk aan de Amstel",
	"Plumsted",
	"Perryton",
	"Yatton",
	"Bordesholm",
	"Closter",
	"Le Loroux-Bottereau",
	"Barra de São Miguel",
	"St. Johns",
	"Trooz",
	"Terrace Heights",
	"Siegsdorf",
	"Wörrstadt",
	"Frostburg",
	"Kingscliff",
	"Moritzburg",
	"Moulay Bou ’azza",
	"Lypovets",
	"Dunavarsány",
	"Boured",
	"Le Soler",
	"Rolesville",
	"Stra",
	"Sorbiers",
	"Biryusinsk",
	"Bo‘ston",
	"Mina Clavero",
	"Viechtach",
	"Heuchelheim",
	"Túrkeve",
	"Neuenbürg",
	"Booneville",
	"Sallisaw",
	"Fusignano",
	"Vohburg an der Donau",
	"East York",
	"Cabrils",
	"Ōwani",
	"Herencia",
	"Fabrica di Roma",
	"Sitka",
	"Verolanuova",
	"Campo Maior",
	"Stuttgart",
	"Bolgar",
	"Visp",
	"Namsos",
	"Billingshurst",
	"Pocklington",
	"Bouaye",
	"Coxsackie",
	"Guatapé",
	"Derecske",
	"Concord",
	"Mystic Island",
	"Waterford",
	"Cohasset",
	"Titz",
	"Géri",
	"Shinfield",
	"Trevi",
	"Kecel",
	"Steenwijkerwold",
	"Willow Street",
	"Trignac",
	"Lansdowne",
	"Allersberg",
	"Elísio Medrado",
	"Hillsboro",
	"Summit View",
	"Vedano Olona",
	"San Ildefonso",
	"Corona de Tucson",
	"Habay-la-Vieille",
	"Novoli",
	"Cacalchen",
	"Carnoux-en-Provence",
	"Bushkill",
	"Middleton",
	"Estrêla d’Oeste",
	"Lure",
	"General Câmara",
	"L’Aigle",
	"Douar Mezaoura",
	"Oude Pekela",
	"Lège-Cap-Ferret",
	"Toritto",
	"Orocué",
	"San Pablo Huitzo",
	"Brętowo",
	"Moyeuvre-Grande",
	"Tuscumbia",
	"Vetluga",
	"Nzalat Bni Amar",
	"Tobyhanna",
	"As Sallūm",
	"Mason",
	"Ghassat",
	"Vern-sur-Seiche",
	"Nörten-Hardenberg",
	"Dauphin",
	"Houplines",
	"Humboldt",
	"Baiersdorf",
	"Barton",
	"Chantonnay",
	"Stockstadt am Main",
	"Navia",
	"Lesmurdie",
	"Fontaniva",
	"Deliatyn",
	"North Londonderry",
	"Monforte del Cid",
	"Kirchberg",
	"Otterburn Park",
	"Barby",
	"Bremgarten",
	"Helena Valley Southeast",
	"Limena",
	"Sunset Hills",
	"Ibiaí",
	"Lanivtsi",
	"Sand Lake",
	"Tha Maka",
	"Aughton",
	"Imoulas",
	"University of Virginia",
	"Muri",
	"Zāzerān",
	"Gravellona Toce",
	"Neulengbach",
	"Cariati",
	"Freeport",
	"Bad Feilnbach",
	"Zwingenberg",
	"Audenge",
	"Vyetka",
	"Būlaevo",
	"Prien",
	"Guabito",
	"Onalaska",
	"Santa Rosa",
	"Noyelles-sous-Lens",
	"Măcin",
	"Bystřice pod Hostýnem",
	"Campillos",
	"Tumbiscatío de Ruiz",
	"Lontra",
	"Marcação",
	"Taber",
	"U Thong",
	"Pine Lake Park",
	"Kerrouchen",
	"West Manheim",
	"Mrizig",
	"Phayakkhaphum Phisai",
	"Pézenas",
	"Los Altos Hills",
	"Witley",
	"Tomeşti",
	"Yadrin",
	"Jászapáti",
	"Meximieux",
	"Magtymguly",
	"Aspach",
	"Santa Clara",
	"Upper Makefield",
	"Uedem",
	"Oranienbaum",
	"Monticello",
	"South Bruce Peninsula",
	"Vyshkovo",
	"Kitzbühel",
	"Liancourt",
	"Gigmoto",
	"Edson",
	"Neunburg vorm Wald",
	"Bönnigheim",
	"Seaton",
	"Gewanē",
	"Tuscania",
	"Brejo Grande",
	"Ogden",
	"Guilers",
	"Rivolta d’Adda",
	"Oberstenfeld",
	"Damnoen Saduak",
	"Wollerau",
	"Lake of the Woods",
	"Congers",
	"Cairate",
	"La Verpillière",
	"Ashland",
	"Ağdərə",
	"Makaha",
	"Villard-Bonnot",
	"Spring Valley Lake",
	"Alvorada",
	"San Juan Talpa",
	"Reichertshofen",
	"Bayport",
	"Keminmaa",
	"Wietze",
	"Savoy",
	"Yuryevets",
	"Pfronten",
	"Jánoshalma",
	"Fair Lakes",
	"Kahrīz Sang",
	"Tecumseh",
	"Möser",
	"Dover",
	"Soriano nel Cimino",
	"North Haledon",
	"Leforest",
	"Coxhoe",
	"Dornstetten",
	"Paris",
	"La Bassée",
	"Sirnach",
	"Serramazzoni",
	"Ponte nell’Alpi",
	"Höchst",
	"Salice Salentino",
	"El Porvenir",
	"Gaylord",
	"Aransas Pass",
	"East Cowes",
	"Grafenau",
	"Champagnole",
	"Ostseebad Kühlungsborn",
	"Ellicott",
	"Carbondale",
	"Arab",
	"Monte San Giusto",
	"Kandern",
	"Long Branch",
	"Châteauneuf-sur-Loire",
	"Quarto d’Altino",
	"Peebles",
	"San Miguel del Puerto",
	"Neusiedl am See",
	"Fairless Hills",
	"Milevsko",
	"Althengstett",
	"São Pedro dos Ferros",
	"Ahrensbök",
	"Venegono Superiore",
	"Taglio di Po",
	"Khanu Woralaksaburi",
	"San Sebastián Tecomaxtlahuaca",
	"Ventanas",
	"Lugau",
	"Bolano",
	"Saint-Nicolas-de-Port",
	"Highland Heights",
	"Recaş",
	"Hassocks",
	"Narowlya",
	"Kamyanyets",
	"Somers",
	"Charlestown",
	"Santa Luzia D’Oeste",
	"Fletcher",
	"Blankenheim",
	"Domat/Ems",
	"Louveciennes",
	"Macururé",
	"Santiago Yosondúa",
	"Wettringen",
	"Tubajon",
	"Monserrat",
	"Arealva",
	"Warsash",
	"Langnau am Albis",
	"Kilmore",
	"Druid Hills",
	"Baxter",
	"Puget-sur-Argens",
	"Peasedown Saint John",
	"Sauerlach",
	"Leisnig",
	"Vert-Saint-Denis",
	"Seffner",
	"Petrosino",
	"Vila Viçosa",
	"Ouro Verde",
	"Marcelino Vieira",
	"Ballan-Miré",
	"Vernon",
	"Millersville",
	"Rottenburg an der Laaber",
	"Wimauma",
	"Figueira",
	"Hirslen",
	"Brasileira",
	"Miramar Beach",
	"Appiano Gentile",
	"Hempstead",
	"Gargenville",
	"Garrni",
	"Chinon",
	"Wognum",
	"Reignier",
	"San Vicente",
	"Knittlingen",
	"Anahawan",
	"Hohberg",
	"Gulf Hills",
	"Buckhannon",
	"Saint-Jean-de-Maurienne",
	"Texmelucan",
	"Ransbach-Baumbach",
	"Kulpsville",
	"Socorro",
	"Akhalkalaki",
	"West Livingston",
	"Toton",
	"Tallulah",
	"Talalora",
	"Northam",
	"Messancy",
	"Ikata-chō",
	"None",
	"Heiden",
	"Orono",
	"Thann",
	"Bath",
	"Highworth",
	"Oostvoorne",
	"Suomussalmi",
	"Rodeiro",
	"Mala Danylivka",
	"Bailly-Romainvilliers",
	"Proença-a-Nova",
	"Kalampáka",
	"Clover",
	"Zell am Harmersbach",
	"Petoskey",
	"Boiano",
	"Gettorf",
	"Beaver Falls",
	"Aït Tagalla",
	"Nova Itarana",
	"Farnham",
	"Cristalândia do Piauí",
	"Chāboksar",
	"Lauchringen",
	"L’Arbresle",
	"Tillamook",
	"Saint-Apollinaire",
	"Dietmannsried",
	"Könnern",
	"Oak Hills Place",
	"Westwego",
	"Ketovo",
	"Bulqizë",
	"Aratoca",
	"Flowery Branch",
	"Montezuma",
	"Falkenstein",
	"Dalton in Furness",
	"Durrington",
	"Houdain",
	"St. Joseph",
	"Massa Marittima",
	"Yarrawonga",
	"Holly",
	"Quincy",
	"Bodenheim",
	"Larkfield-Wikiup",
	"Frostproof",
	"Egremont",
	"Bohorodchany",
	"West Earl",
	"Giánnouli",
	"Comercinho",
	"Myskhako",
	"Dalhart",
	"East Donegal",
	"Rorschacherberg",
	"Strullendorf",
	"Garrison",
	"Niāla Kondapalle",
	"Dannenberg",
	"Bormes-les-Mimosas",
	"Gonzales",
	"Wroughton",
	"Zirl",
	"San Francisco",
	"Agua Caliente",
	"Chonnabot",
	"Meinersen",
	"Cleator Moor",
	"Orosi",
	"Runnemede",
	"Bel Aire",
	"Aracena",
	"Le Teich",
	"Milton",
	"Cervaro",
	"Tāzeh Shahr",
	"Aurora",
	"Dodji",
	"Silsden",
	"Firebaugh",
	"Kunszentmiklós",
	"Whitefish",
	"Lopatcong",
	"Goffs Oak",
	"Eckbolsheim",
	"Mahtomedi",
	"Löwenberg",
	"Altrip",
	"Wavrin",
	"West Vero Corridor",
	"Kapuskasing",
	"Les Andelys",
	"Floris",
	"Aparecida",
	"Coleshill",
	"Bargara",
	"Tolstoy-Yurt",
	"Prabuty",
	"Le Coteau",
	"Uramita",
	"Savannah",
	"Großrosseln",
	"Nájera",
	"Deizisau",
	"Brighton",
	"Wachtendonk",
	"Hongwansi",
	"Bismark",
	"Seiersberg",
	"Tonnay-Charente",
	"Boxford",
	"Guapuã",
	"Ron Phibun",
	"Liezen",
	"Montague",
	"Grottaminarda",
	"Paliano",
	"Mount Joy",
	"McRae-Helena",
	"Trinidad",
	"Kenton",
	"Campo Florido",
	"Bahnemīr",
	"Auby",
	"Racalmuto",
	"Carl Junction",
	"Bad Schmiedeberg",
	"Rakkestad",
	"Carnaúba dos Dantas",
	"Agira",
	"Southeast Arcadia",
	"Sausset-les-Pins",
	"Kinderhook",
	"La Malbaie",
	"Thedinghausen",
	"Boulemane",
	"Paw Paw",
	"Cachoeira Dourada",
	"Kiunga",
	"Tidaholm",
	"Lake City",
	"Buriti dos Montes",
	"Ballancourt",
	"Ribeirão do Largo",
	"Mataraca",
	"Radcliffe on Trent",
	"Vobarno",
	"San Sebastian",
	"Lanark",
	"Skowhegan",
	"Fürstenzell",
	"Moengo",
	"Erwin",
	"Pudasjärvi",
	"Courtry",
	"Krailling",
	"Colorado City",
	"Oak Grove",
	"Karumai",
	"Biguglia",
	"La Penne-sur-Huveaune",
	"Pawling",
	"Romagnat",
	"Seuzach",
	"Kosterëvo",
	"Santa Bárbara",
	"Kiama",
	"Shëngjin",
	"Dürnten",
	"Souk Khmis Bni Arouss",
	"Cintruénigo",
	"Moundsville",
	"Campoformido",
	"Villa di Serio",
	"Pereiras",
	"Claix",
	"Cumberland Hill",
	"Santana da Boa Vista",
	"Hanawa",
	"Sawang Wirawong",
	"Schierling",
	"Warlingham",
	"San Giovanni Gemini",
	"Montecito",
	"Denison",
	"Almirante",
	"Pena Forte",
	"Sirmione",
	"Locust Grove",
	"Buxton",
	"Half Moon",
	"Lugoff",
	"Vicchio",
	"Fuscaldo",
	"Costabissara",
	"Fort Mitchell",
	"Conewago",
	"Palmeirina",
	"Möckmühl",
	"Manhattan",
	"Honey Brook",
	"Sibilia",
	"Brooklyn",
	"North Weeki Wachee",
	"Crowthorne",
	"Mammoth Lakes",
	"Akranes",
	"Bongaree",
	"Meggen",
	"Bayabas",
	"Millis",
	"Třeboň",
	"Ruurlo",
	"Oberkochen",
	"Johnstown",
	"Šlapanice",
	"Le Mesnil-le-Roi",
	"Spotswood",
	"Sychëvka",
	"Oulad Chbana",
	"Haddam",
	"Waynesburg",
	"Langenargen",
	"Shankoucun",
	"Kennett",
	"Irapuru",
	"Thomson",
	"Gattinara",
	"Irondale",
	"Meghraoua",
	"Prim Decembrie",
	"Renfrew",
	"Neustadt",
	"Hongmao",
	"Guénange",
	"Bruck an der Leitha",
	"Düdingen",
	"Stornoway",
	"Novaya Ladoga",
	"Westlake Village",
	"Hasselt",
	"San Daniele del Friuli",
	"Mirleft",
	"Passa Tempo",
	"Harmelen",
	"Castle Shannon",
	"Catarina",
	"North Elba",
	"Coaldale",
	"Rýmařov",
	"Zhänibek",
	"Zavodske",
	"Chester",
	"Lipník nad Bečvou",
	"Archidona",
	"Landeck",
	"Endirey",
	"Pagsanghan",
	"Napoleon",
	"Vau i Dejës",
	"Schömberg",
	"Willebadessen",
	"Komsomolsk",
	"Novi Sanzhary",
	"Upper Saddle River",
	"Chinique",
	"Melqa el Ouidane",
	"Donges",
	"Chantada",
	"Mahopac",
	"Tarfaya",
	"Temperance",
	"Maxdorf",
	"Corella",
	"Oberlin",
	"Thaon-les-Vosges",
	"Taourga",
	"High Ackworth",
	"Arnstein",
	"Motavita",
	"Černošice",
	"Reusel",
	"Vineuil",
	"Twin Lakes",
	"Lake Norman of Catawba",
	"Jefferson City",
	"Country Club Estates",
	"Lysianka",
	"Kirawsk",
	"Kvasyliv",
	"Harefield",
	"Rhoon",
	"Kenilworth",
	"Éguilles",
	"Carboneras",
	"Chiaramonte Gulfi",
	"Bad Herrenalb",
	"Ercsi",
	"Loppi",
	"Albarradas",
	"Krasnopillya",
	"Beauzelle",
	"Fifi",
	"Jesteburg",
	"Corumbaíba",
	"Bedekovčina",
	"Gleizé",
	"Schroeppel",
	"Thuir",
	"Caccamo",
	"Gränichen",
	"Porto de Pedras",
	"Uribe",
	"Burgos",
	"Mikasa",
	"Cortlandville",
	"Ellsworth",
	"Wedgefield",
	"Luisant",
	"Campomarino",
	"Itamarati",
	"Ergoldsbach",
	"Clanwilliam",
	"Vrútky",
	"Nova Fátima",
	"Vetschau/Spreewald",
	"Teuchern",
	"Monona",
	"Grumello del Monte",
	"South Londonderry",
	"Verkhnyaya Khava",
	"West Thurrock",
	"Manāli",
	"Kef el Rhar",
	"Fresnes-sur-Escaut",
	"Major Vieira",
	"Center Line",
	"Venice Gardens",
	"Domneşti",
	"Pismo Beach",
	"Beebe",
	"Bramcote",
	"Isle",
	"Milliken",
	"Montorio al Vomano",
	"Black Mountain",
	"Cenes de la Vega",
	"Hettange-Grande",
	"Charnay-lès-Mâcon",
	"Dzidzantun",
	"Siret",
	"San Miguel Quetzaltepec",
	"Kerikeri",
	"Pasching",
	"Arico el Nuevo",
	"Belene",
	"Crestwood Village",
	"Todos Santos",
	"Cellole",
	"Imi n-Tlit",
	"El Divisadero",
	"Neuves-Maisons",
	"Venhuizen",
	"Niagara",
	"Tavullia",
	"Los Santos de Maimona",
	"Watton",
	"Meadow Lakes",
	"Selters",
	"Sögel",
	"Pedreguer",
	"Center Moriches",
	"Litchfield",
	"Portugal Cove-St. Philip's",
	"Vale do Paraíso",
	"Beeskow",
	"Tarutyne",
	"Port Jefferson",
	"Middleport",
	"Eaton",
	"Heino",
	"Pisogne",
	"Beloslav",
	"Guarani das Missões",
	"Tuskegee",
	"Quinchao",
	"Breda di Piave",
	"Bonsecours",
	"Roquetas",
	"Zorra",
	"Daun",
	"Soustons",
	"Ephrata",
	"Rockwood",
	"Kindberg",
	"Canton",
	"Nagykovácsi",
	"Fehérgyarmat",
	"Neuendettelsau",
	"Bystřice nad Pernštejnem",
	"Vorselaar",
	"Leesville",
	"Cosalá",
	"Péronne",
	"Oradell",
	"Kitimat",
	"Minnetrista",
	"Templeton",
	"Merrow",
	"Tarouca",
	"Gering",
	"Manhush",
	"Sanctuary Point",
	"Levico Terme",
	"Kyabram",
	"Bad Endbach",
	"Guimarânia",
	"Sidi ’Ali Bou Aqba",
	"Shelburne",
	"Adams",
	"Bagnes",
	"Serra Riccò",
	"Bassens",
	"Villanueva del Arzobispo",
	"Gongogi",
	"Iraí",
	"Monts",
	"Leão",
	"Tricesimo",
	"Alnwick",
	"Harrison",
	"Healesville",
	"Medyn",
	"Gratkorn",
	"Bad Lausick",
	"Kennedy",
	"Medebach",
	"Remiremont",
	"Hazard",
	"Summit Park",
	"Ponte Alta do Tocantins",
	"Gray",
	"Vidreras",
	"Nor Kharberd",
	"Cherry Valley",
	"Untersiggenthal",
	"Omak",
	"Vandenberg Village",
	"Vityazevo",
	"West Dundee",
	"Loreggia",
	"Totnes",
	"Blandon",
	"San Esteban Sasroviras",
	"El Meghassine",
	"Winterbach",
	"Borgonovo Val Tidone",
	"Duvall",
	"Serra Negra do Norte",
	"Saint-Affrique",
	"Yaremche",
	"Laihia",
	"Collier",
	"Ayer",
	"Oederan",
	"Mezguitem",
	"Solosuchiapa",
	"Ananiv",
	"Vinton",
	"Gurjaani",
	"Olvera",
	"Kawerau",
	"Notre-Dame-de-Bondeville",
	"Wissous",
	"Joshua",
	"Livádia",
	"Melton",
	"Peixe-Boi",
	"Clausthal",
	"Kirchheimbolanden",
	"Oak Hill",
	"Santa Cruz do Monte Castelo",
	"Burpengary",
	"Herrieden",
	"Hadfield",
	"Monument",
	"Hopkinton",
	"Reilingen",
	"Turbigo",
	"Bösel",
	"Monachil",
	"Orizânia",
	"Algermissen",
	"Croton-on-Hudson",
	"Zapadnaya Dvina",
	"Saint-Julien-les-Villas",
	"Bellevue",
	"Briarcliff Manor",
	"Sodus",
	"Olonets",
	"Târgu Cărbuneşti",
	"Carmignano di Brenta",
	"Alliance",
	"Wickenburg",
	"Heimberg",
	"Sterling",
	"Allegheny",
	"Reppenstedt",
	"Kunszentmárton",
	"Moraújo",
	"Teteringen",
	"Pinoso",
	"Trévoux",
	"Valencina de la Concepción",
	"Selah",
	"Tafraoutane",
	"Écouen",
	"Vista Alegre do Alto",
	"Kushuhum",
	"Hammerfest",
	"Pedraza",
	"Shorewood",
	"Languidic",
	"Bruchmühlbach-Miesau",
	"Agerola",
	"Saint-Hippolyte",
	"Califórnia",
	"Postbauer-Heng",
	"Helena Valley West Central",
	"Águas da Prata",
	"Applewood",
	"Jackson",
	"Aguelhok",
	"Cologne",
	"Soisy-sur-Seine",
	"Yungay",
	"Kronenwetter",
	"Schweich",
	"Publier",
	"Dzyatlava",
	"Maravilhas",
	"Saint-Priest-en-Jarez",
	"Gilberts",
	"Bargersville",
	"Avella",
	"Colico",
	"Treuen",
	"Sacrofano",
	"Hampton",
	"Sudden Valley",
	"Freisen",
	"Campo do Tenente",
	"Hollymead",
	"Rockmart",
	"Týn nad Vltavou",
	"Sankt Andrä vor dem Hagenthale",
	"Valmaseda",
	"Monte do Carmo",
	"Schellerten",
	"Lake Carmel",
	"Lockwood",
	"Wyoming",
	"Montreuil-Juigné",
	"Semenivka",
	"Jockgrim",
	"Beynes",
	"Lake Dallas",
	"Banayoyo",
	"Sampedor",
	"Pravia",
	"Bloomingdale",
	"Schoonebeek",
	"Rothenburg",
	"Arévalo",
	"Manziana",
	"Ramstein-Miesenbach",
	"Maracalagonis",
	"Rolling Hills Estates",
	"Tapiraí",
	"El Metline",
	"Moirans",
	"Tamási",
	"Uckange",
	"Lisses",
	"Bestensee",
	"South Cleveland",
	"Casteltermini",
	"Willits",
	"Clinton",
	"Kottingbrunn",
	"Chişineu Criş",
	"Clisson",
	"Willerby",
	"Námestovo",
	"Melnikovo",
	"Skiatook",
	"Alpestre",
	"Decorah",
	"Itanagra",
	"Elsterwerda",
	"Iepê",
	"Ascurra",
	"Clydach",
	"Brookhaven",
	"Macaubal",
	"Delligsen",
	"Diamondhead",
	"Åkrehamn",
	"Candelo",
	"Floresville",
	"Bedminster",
	"Ripley",
	"Wintzenheim",
	"Chardon",
	"Lancaster",
	"Morehead",
	"Langres",
	"Arazane",
	"Küps",
	"Boston",
	"Arcugnano",
	"Iguatama",
	"Angmering",
	"Artá",
	"Ripon",
	"Dubí",
	"Rheinsberg",
	"Taseyevo",
	"Castlegar",
	"Mihăileşti",
	"Titchfield",
	"Krasnokholmskiy",
	"Anacapri",
	"Bad Düben",
	"Odessa",
	"Saint-Victoret",
	"Jermuk",
	"Saint-Vincent-de-Tyrosse",
	"Treze Tílias",
	"Villaverde del Río",
	"Mayo",
	"Hamilton",
	"Northfield",
	"Lodi Vecchio",
	"Tropea",
	"Altomünster",
	"Menominee",
	"Brion",
	"Lagoa Alegre",
	"La Couronne",
	"Berango",
	"Heyin",
	"Céret",
	"Svoge",
	"Pidvolochysk",
	"Sezimovo Ústí",
	"Pleasant Hills",
	"Pahokee",
	"Moulay Abdelkader",
	"Insar",
	"Ahuimanu",
	"Crete",
	"Saint-Prix",
	"Lagkadás",
	"Topolobampo",
	"Mamonovo",
	"Loudoun Valley Estates",
	"Chestnut Ridge",
	"Laurel Hill",
	"Island Lake",
	"Icém",
	"Estrêla do Sul",
	"Seasalter",
	"Puentedeume",
	"Church Point",
	"Lesneven",
	"Lochgelly",
	"Rychvald",
	"Lagord",
	"Puerto Parra",
	"Ida Ou Azza",
	"Oak Brook",
	"Santana de Pirapama",
	"Penalva do Castelo",
	"Kozhevnikovo",
	"Zeltweg",
	"Seaford",
	"Palagianello",
	"Neresheim",
	"Jequitaí",
	"Narre Warren North",
	"Rommelshausen",
	"Cruzeta",
	"Tara",
	"Caldas de Malavella",
	"Antoing",
	"Centellas",
	"Millom",
	"Loftus",
	"Pedra Lavrada",
	"Crediton",
	"Cumiana",
	"Evergreen",
	"Pershore",
	"Nurmes",
	"Digoin",
	"Helvecia",
	"Nikolsk",
	"Berchtesgaden",
	"Cumberland",
	"Vizille",
	"Bützow",
	"Cartoceto",
	"Clementina",
	"Mascoutah",
	"Almyrós",
	"Brazil",
	"Chiavenna",
	"Vertentes do Lério",
	"Illingen",
	"Goring by Sea",
	"Iaras",
	"Bosa",
	"Lanchyn",
	"North Coventry",
	"Aberdeen",
	"Jennings Lodge",
	"Tullins",
	"Kozelets",
	"Saint-Denis-en-Val",
	"Jefferson",
	"Manhasset",
	"Green",
	"Santa Maria di Licodia",
	"Torrile",
	"Mineral del Chico",
	"Lillesand",
	"Lampasas",
	"Drumheller",
	"La Montagne",
	"Kirkland Lake",
	"Fīrūraq",
	"Krasnyy Yar",
	"Village Green-Green Ridge",
	"Liebenburg",
	"Krupina",
	"Almodóvar del Río",
	"Rizziconi",
	"Marmirolo",
	"Byram",
	"Hengersberg",
	"Dabnou",
	"Koufália",
	"Kensington",
	"Toscolano",
	"Polgár",
	"Wittem",
	"Willisau",
	"Artemivsk",
	"Tuparetama",
	"Domène",
	"Fort Belvoir",
	"West Boylston",
	"Brentwood",
	"Cambira",
	"Chalford",
	"Laughlin",
	"San Felipe Tejalapan",
	"Muniz Ferreira",
	"Mozzecane",
	"Abatskoye",
	"Chartiers",
	"Aztec",
	"St. Martin",
	"La Junta",
	"Kinchil",
	"Altenberg",
	"Alfonso Castañeda",
	"Murphy",
	"Brockworth",
	"Gières",
	"Leck",
	"Yahiko",
	"Denny",
	"Gélida",
	"Bosanski Petrovac",
	"Southwest Ranches",
	"Quincy",
	"Numansdorp",
	"Tukan",
	"Timbó Grande",
	"Tonila",
	"San Luis de Palenque",
	"Richland Hills",
	"Dannstadt-Schauernheim",
	"Hellenthal",
	"Mogoşoaia",
	"Maryville",
	"Geispolsheim",
	"Söhlde",
	"Santa Eulalia de Ronsaná",
	"Dornach",
	"Meta",
	"Cavalaire-sur-Mer",
	"Bad Liebenstein",
	"Fouquières-lès-Lens",
	"Gérardmer",
	"Baltimore Highlands",
	"Dolores",
	"Berg",
	"Porterville",
	"Mrkonjić Grad",
	"São João do Oriente",
	"Tignieu",
	"Herzogenburg",
	"Nejdek",
	"Saint-Péray",
	"Liptovský Hrádok",
	"Slawharad",
	"Villmergen",
	"Völs",
	"Newport-On-Tay",
	"Leegebruch",
	"Marseillan",
	"Higham Ferrers",
	"Hillside",
	"Bous",
	"Hüfingen",
	"Anse",
	"Columbiana",
	"Marchamalo",
	"Galliera Veneta",
	"Logan",
	"Batesville",
	"Herzogenbuchsee",
	"Glastonbury Center",
	"Vorzel",
	"Malemort-sur-Corrèze",
	"Trigueros",
	"São João do Jaguaribe",
	"Arkhipo-Osipovka",
	"Chaiya",
	"Našice",
	"Baldim",
	"Rethel",
	"Sheboygan Falls",
	"Ruelle-sur-Touvre",
	"Dumbrăviţa",
	"Cherykaw",
	"Lyubimets",
	"Cambiago",
	"Salzbergen",
	"Uetikon am See",
	"Wytheville",
	"Viladecaballs",
	"Teggiano",
	"Hipadpad",
	"Hollis",
	"St. Francis",
	"Peresecina",
	"Degache",
	"Sidi Ahmed Ben Aissa",
	"Hitchcock",
	"La Puebla de Montalbán",
	"Colombelles",
	"Had Kourt",
	"Nieppe",
	"Bollington",
	"Langon",
	"Toulouges",
	"Mandurah",
	"Catalina",
	"Gorodishche",
	"Sontra",
	"Villa Cortese",
	"Villanueva del Ariscal",
	"Talat-n-Ya’qoub",
	"Olsztyn",
	"Dryanovo",
	"Annfield Plain",
	"Grasberg",
	"Durandé",
	"Bonner Springs",
	"Pasiano",
	"Wachtebeke",
	"Senador Firmino",
	"Long Grove",
	"Camp Hill",
	"Mansfield Center",
	"São João da Pesqueira",
	"Hrádek nad Nisou",
	"Itaiçaba",
	"Verê",
	"Wilmington Manor",
	"Malagón",
	"Olginate",
	"Borrazópolis",
	"Nazário",
	"Xiaoshengcun",
	"Doué-la-Fontaine",
	"Topanga",
	"Tura",
	"Erval Sêco",
	"Bergkirchen",
	"Argyle",
	"Torbay",
	"Leopoldo de Bulhões",
	"Downingtown",
	"Grand Ledge",
	"Aldingen",
	"Nong Phai",
	"Stony Stratford",
	"Saltinho",
	"Sándorfalva",
	"San Julián",
	"Jalasjärvi",
	"Meland",
	"Old Forge",
	"Upton",
	"Tenampa",
	"Pelago",
	"Lincolnshire",
	"Kinmel",
	"Cormontreuil",
	"Blair",
	"Naila",
	"Jersey Village",
	"Lexington",
	"Tavernelle in Val di Pesa",
	"Richfield",
	"Itobi",
	"Ancenis",
	"Liffré",
	"Centerville",
	"Bouffémont",
	"Kyneton",
	"West Long Branch",
	"Dilolo",
	"Flushing",
	"Trzemeszno",
	"Middletown",
	"Vratimov",
	"São Miguel dos Milagres",
	"Dörentrup",
	"Ashland",
	"Guaraqueçaba",
	"Juarez Távora",
	"Mably",
	"Fruitvale",
	"Mynämäki",
	"Petřvald",
	"Burley in Wharfedale",
	"Temax",
	"Flein",
	"Seminole",
	"Altoona",
	"Grand Blanc",
	"Abadiano Celayeta",
	"Schonungen",
	"Zingem",
	"Abingdon",
	"Ide",
	"Wilmington",
	"Kelty",
	"Willesborough",
	"Lésigny",
	"La Pêche",
	"Edenbridge",
	"Gendt",
	"Bridge City",
	"Bow",
	"Crescentino",
	"Madeira",
	"Altach",
	"Weissach",
	"Thung Saliam",
	"Myory",
	"Carpaneto Piacentino",
	"Bol’sheust’ikinskoye",
	"Rimpar",
	"Khondāb",
	"Woodlake",
	"Timezgadiouine",
	"Hastings-on-Hudson",
	"Fieni",
	"Fremantle",
	"Bex",
	"Pantelleria",
	"Pivdenne",
	"Pontcharra",
	"Boostedt",
	"Hardyston",
	"Rohatyn",
	"Stříbro",
	"Bryans Road",
	"Banff",
	"Innisfail",
	"York",
	"Waterboro",
	"Cobeña",
	"Paratebueno",
	"Courdimanche",
	"Lalla Takerkoust",
	"Ashland",
	"Halifax",
	"San Colombano al Lambro",
	"Princeton",
	"Sangaree",
	"La Talaudière",
	"Questembert",
	"Bosteri",
	"Itapirapuã",
	"Templeton",
	"North Cornwall",
	"Spring",
	"Fakenham",
	"Frenkendorf",
	"Los Fresnos",
	"South Weber",
	"Woodstock",
	"Barra do Turvo",
	"Mazapa",
	"Watford City",
	"Crookston",
	"São Vicente de Minas",
	"Chastre-Villeroux-Blanmont",
	"Talmont-Saint-Hilaire",
	"Madison Park",
	"Fohnsdorf",
	"Farington",
	"Covington",
	"Oberstaufen",
	"Cox",
	"Khon Buri",
	"Nemishayeve",
	"San Gimignano",
	"Latrobe",
	"Lamporecchio",
	"Nicolet",
	"Saint-Yrieix-sur-Charente",
	"Soeng Sang",
	"Nuevo Cuscatlán",
	"Rrogozhinë",
	"DeFuniak Springs",
	"Williamsburg",
	"Rockwood",
	"Olivette",
	"Baldwinsville",
	"Sound Beach",
	"Savièse",
	"Kráľovský Chlmec",
	"Aibonito",
	"Winters",
	"Rüschlikon",
	"Breckenridge",
	"Interlaken",
	"Southgate",
	"Soncino",
	"Sarandí del Yi",
	"Calau",
	"Makushino",
	"Medina",
	"Strathmore",
	"Bubikon",
	"Greenville",
	"Curtarolo",
	"Apuarema",
	"Ban Phue",
	"Curral de Dentro",
	"Seville",
	"Reisbach",
	"Rainford",
	"Contes",
	"Zavāreh",
	"Umm Walad",
	"Ibirajuba",
	"Budeşti",
	"Collie",
	"Villa General Belgrano",
	"San Zenone",
	"Benningen am Neckar",
	"Kenai",
	"Santana",
	"Carnegie",
	"Aubrey",
	"Monte Alegre do Sul",
	"Carnago",
	"Irapuã",
	"Oberglatt",
	"Sarandí Grande",
	"Hook",
	"Castro del Río",
	"Pittston",
	"Marcellina",
	"Coulounieix",
	"Benahavís",
	"Saint-Benoît",
	"Kinvere",
	"Santana",
	"Santa Ana",
	"Castellalto",
	"Charlestown",
	"Santa María del Oro",
	"Holly Springs",
	"Abatiá",
	"Déols",
	"Pyhäselkä",
	"Allstedt",
	"Rochefort-du-Gard",
	"Waltham",
	"Auxonne",
	"Behren-lès-Forbach",
	"Mirabella Eclano",
	"Gerolstein",
	"Bornos",
	"Malaya Purga",
	"Vergato",
	"Barge",
	"Reinsdorf",
	"Harzgerode",
	"Paraí",
	"Eisfeld",
	"Jászárokszállás",
	"Pratola Peligna",
	"San Jose de Buan",
	"Withamsville",
	"Benátky nad Jizerou",
	"Mattighofen",
	"Kaufman",
	"Panciu",
	"Saarburg",
	"Araguapaz",
	"Claro dos Poções",
	"Campbell",
	"Neuhofen",
	"Saint-Didier-au-Mont-d’Or",
	"Mhlume",
	"Chester",
	"Ouirgane",
	"Alpiarça",
	"Martignas-sur-Jalle",
	"Dongping",
	"Jouamaa",
	"Escalquens",
	"Lodève",
	"Peñíscola",
	"Providence",
	"Oberwart",
	"Rubelita",
	"Chobham",
	"Arneiroz",
	"Milan",
	"Crewkerne",
	"Q’vareli",
	"Turzovka",
	"Ertis",
	"Drummond/North Elmsley",
	"Vigarano Mainarda",
	"Fivizzano",
	"Rhinebeck",
	"Oroville East",
	"Estes Park",
	"Gerbrunn",
	"Santa Rosa de Río Primero",
	"Vega de San Mateo",
	"Kemijärvi",
	"Waite Park",
	"Reszel",
	"Beaugency",
	"Antigo",
	"Jämsänkoski",
	"Rosporden",
	"Westminster",
	"Park City",
	"Coronel Bicaco",
	"Zelo Buon Persico",
	"Löffingen",
	"Greenfield",
	"Azuaga",
	"Binuangan",
	"Longridge",
	"Saint-Thibault-des-Vignes",
	"Forsyth",
	"San Diego",
	"Jericó",
	"McGregor",
	"Tenterden",
	"San Giórgio di Nogaro",
	"Vitulazio",
	"Al Orjane",
	"Cheraw",
	"Swainsboro",
	"Onil",
	"Ebensee",
	"Nyíradony",
	"Ondara",
	"Città della Pieve",
	"Moutier",
	"Paulo Jacinto",
	"Ruisbroek",
	"Jasper",
	"Wynne",
	"Dryden",
	"Altbach",
	"Kryve Ozero",
	"Round Lake Park",
	"Neufchâteau",
	"Villers-Saint-Paul",
	"Pulsnitz",
	"Gallneukirchen",
	"Lekkerkerk",
	"Korkmaskala",
	"Pium",
	"Vico del Gargano",
	"Quarryville",
	"Zlatograd",
	"Belo Vale",
	"Pedro Gomes",
	"Salida",
	"South Boston",
	"Callosa de Ensarriá",
	"Citrus Hills",
	"Middlebury",
	"Fort Frances",
	"Bad Wimpfen",
	"Zinat",
	"Albion",
	"Bang Pa-in",
	"Roßwein",
	"Khoroshiv",
	"Al Kafr",
	"Carneys Point",
	"Gerena",
	"East Huntingdon",
	"Wading River",
	"Tobarra",
	"Fossò",
	"Boqueirão do Leão",
	"San Marco Evangelista",
	"Bilac",
	"Barvikha",
	"Salem",
	"Dighton",
	"Sisteron",
	"Brăneşti",
	"Dolgoderevenskoye",
	"Lorquí",
	"Ensisheim",
	"Pluvigner",
	"Payette",
	"Vlăhiţa",
	"North Tamborine",
	"Grove",
	"Bunde",
	"Celorico da Beira",
	"Gettysburg",
	"Sotomayor",
	"Montecristo",
	"Langenlois",
	"Trostianets",
	"Linas",
	"Highlands",
	"Chirnogi",
	"Welcome",
	"La Sarre",
	"Wick",
	"Vezzano Ligure",
	"Valley",
	"Upper Milford",
	"Solesino",
	"Câmpeni",
	"Shelburne",
	"Corte Franca",
	"Kasson",
	"Frýdlant",
	"Granite Falls",
	"Creston",
	"Kingsbridge",
	"Clear Lake",
	"Jacuí",
	"Bad Füssing",
	"Aussonne",
	"Murwillumbah",
	"Trail",
	"Senador Sá",
	"Belinskiy",
	"Nicolosi",
	"Quiévrechain",
	"Serrania",
	"Dittelbrunn",
	"Steele Creek",
	"Omer",
	"San Filippo della Mela",
	"Oundle",
	"Chandler",
	"Pignan",
	"Stone Mills",
	"Villa d’Almè",
	"Thornbury",
	"São José da Lagoa Tapada",
	"Spinetoli",
	"Novoselytsia",
	"Melissano",
	"Fort Scott",
	"Baienfurt",
	"Pukalani",
	"Călimăneşti",
	"Cee",
	"Hornsby Bend",
	"Dermbach",
	"Løten",
	"Kremmen",
	"Markneukirchen",
	"Kingsbury",
	"Sarnico",
	"Cotignola",
	"Cootamundra",
	"Chapultenango",
	"Alto Paraíso de Goiás",
	"Cônego Marinho",
	"Oulad Hassoune",
	"Dykanka",
	"Plaistow",
	"Hanover",
	"Lamarque",
	"Münnerstadt",
	"Calden",
	"Pressbaum",
	"Kahla",
	"Treherbert",
	"Polk",
	"Caluso",
	"Las Lomitas",
	"Chari̇̄koṭ",
	"Robbiate",
	"São Miguel das Missões",
	"Jati",
	"Boultham",
	"Migennes",
	"Carbondale",
	"Robinwood",
	"Staritsa",
	"Xavantina",
	"Sant’Angelo di Piove di Sacco",
	"Berwick",
	"Teresva",
	"Lavras do Sul",
	"Palmeirópolis",
	"El Paso",
	"Sonnino",
	"Manazuru",
	"Hardegsen",
	"Jassans-Riottier",
	"Ankum",
	"Perry",
	"San Miguel Sigüilá",
	"Hopsten",
	"Wissembourg",
	"Hongliuyuan",
	"Sebastopol",
	"Center",
	"Santa Giustina in Colle",
	"Coromoro",
	"Upper Hanover",
	"Ilminster",
	"Mezzolombardo",
	"Carosino",
	"Webster City",
	"Treharris",
	"Wivenhoe",
	"Hastings",
	"Adro",
	"Plain City",
	"Indiantown",
	"Staranzano",
	"Fort Dix",
	"Gali",
	"Páty",
	"Hořovice",
	"Southwell",
	"Coimbra",
	"Collinsville",
	"South-West Oxford",
	"Acton Vale",
	"Úvaly",
	"Saltara",
	"Inakadate",
	"Juranda",
	"Villamayor",
	"Tervel",
	"Cognin",
	"Wellington",
	"Boulazac",
	"Piñon Hills",
	"Jrvezh",
	"Honfleur",
	"Balaruc-les-Bains",
	"Auburn",
	"Lagoa Salgada",
	"Thurmont",
	"Old Jefferson",
	"Donje Žabare",
	"Groitzsch",
	"Borgetto",
	"Beaupréau",
	"North Patchogue",
	"Vösendorf",
	"Brighton",
	"Oakdale",
	"Homécourt",
	"San Antonio",
	"Lamar",
	"Catiguá",
	"Butler",
	"Chehalis",
	"Roccasecca",
	"Al Mazra‘ah",
	"Vapniarka",
	"Richmond",
	"Etowah",
	"Villa Díaz Ordaz",
	"Întorsura Buzăului",
	"Solone",
	"Ipumirim",
	"Sieghartskirchen",
	"Bromont",
	"Guisona",
	"Sykkylven",
	"Seefeld",
	"Waller",
	"São José dos Brasílios",
	"Askarovo",
	"Mikulov",
	"Chippewa",
	"Windsor",
	"Orange",
	"Beckwith",
	"Ban Duea",
	"Flintbek",
	"Inocência",
	"Scotia",
	"Big Flats",
	"Ferno",
	"East Manchester",
	"Tecuanipan",
	"Poreč",
	"Őrbottyán",
	"Lindóia",
	"Marshfield",
	"Oroso",
	"Égly",
	"Derendingen",
	"Calimera",
	"Saint-Alban",
	"Sahuaripa",
	"Shirley",
	"Vorchdorf",
	"Eden",
	"Devils Lake",
	"River Oaks",
	"Mapello",
	"Mitsamiouli",
	"Bairnsdale",
	"Beni Aïssi",
	"Saint-Aubin-de-Médoc",
	"Goderich",
	"Inden",
	"Waupaca",
	"Wapato",
	"Sappemeer",
	"Chërnyy Yar",
	"Saint Agnes",
	"Dzilam González",
	"South Beloit",
	"Povegliano Veronese",
	"Ollon",
	"Oulainen",
	"Pederobba",
	"Kula",
	"Yenotayevka",
	"Tocantínia",
	"Broadview",
	"Brisighella",
	"Naro",
	"Cushing",
	"Astigarraga",
	"La Tronche",
	"Divion",
	"Pike Creek",
	"Milan",
	"Allegany",
	"Pedrão",
	"Teublitz",
	"Gaoual",
	"Senatobia",
	"Mattersburg",
	"Lajeado Novo",
	"Pleinfeld",
	"Kokemäki",
	"Tarso",
	"Sioux Center",
	"Mordelles",
	"Winslow",
	"Lagoa de Pedra",
	"Escalon",
	"Abira",
	"Hnúšťa",
	"Strömstad",
	"Türkheim",
	"Lake Shore",
	"Thomaston",
	"Broumov",
	"Omitlán de Juárez",
	"Farmington",
	"Pulaski",
	"Emerson",
	"Dolni Chiflik",
	"Mglin",
	"Hriňová",
	"Ambalema",
	"Bernardsville",
	"Hurbanovo",
	"Beni Oual Sehira",
	"Caribou",
	"Tuttle",
	"Sulzbach am Main",
	"Uthumphon Phisai",
	"Velykyy Bereznyy",
	"Mhamid el Rhozlane",
	"Cavaria",
	"Großkarolinenfeld",
	"Renchen",
	"Stolzenau",
	"Hang Dong",
	"Bílovec",
	"Palmyra",
	"LaFayette",
	"Romaniv",
	"Amerongen",
	"Almas",
	"Jenbach",
	"Palmyra",
	"Strasswalchen",
	"Castello di Godego",
	"Paulo Lopes",
	"Denham",
	"Livonia",
	"La Rambla",
	"Castel Madama",
	"Ait Hammou",
	"Ipuaçu",
	"Joshua Tree",
	"Pakefield",
	"Passirano",
	"Ras Kebdana",
	"Burton Latimer",
	"Camerano",
	"Grödig",
	"São Pedro do Turvo",
	"Kotake",
	"Saint-Berthevin",
	"Aït Hadi",
	"Leola",
	"Dayton",
	"Plympton-Wyoming",
	"Central Huron",
	"Le Mesnil-Saint-Denis",
	"Inwood",
	"Nykarleby",
	"Inimutaba",
	"Petite-Rosselle",
	"Umari",
	"Plumas Lake",
	"Elorrio",
	"Banchory",
	"Füzesabony",
	"Florestal",
	"Kunhegyes",
	"Råde",
	"Barr",
	"San Nicolás",
	"Nakata",
	"Gaillon",
	"Bad Sachsa",
	"Hales Corners",
	"Orbe",
	"Schaghticoke",
	"Rigaud",
	"Hughson",
	"Kleinmond",
	"Scappoose",
	"Perros-Guirec",
	"Inami",
	"Lawrence",
	"Urraween",
	"McCook",
	"East Fallowfield",
	"La Alianza",
	"Campofelice di Roccella",
	"Brejinho",
	"Dairago",
	"Schutterwald",
	"Sinzing",
	"Moema",
	"Cormòns",
	"Lago Vista",
	"Columbia Falls",
	"Khoshk Bījār",
	"Curtici",
	"Sant’Agata Bolognese",
	"Dry Run",
	"Granito",
	"Rio d’Oeste",
	"Jackson",
	"Durach",
	"Shiranuka",
	"New Hanover",
	"Marshall",
	"Rizal",
	"Sa Bot",
	"Kruhlaye",
	"Nettersheim",
	"Lower Windsor",
	"Quatiguá",
	"Meldorf",
	"Easton",
	"Market Deeping",
	"Saujon",
	"Montrose",
	"Llandudno Junction",
	"Albertville",
	"Porters Neck",
	"Gold River",
	"Assis Brasil",
	"Sidi al Ghandour",
	"Magnanville",
	"Mondim de Basto",
	"Anina",
	"Barão do Triunfo",
	"Berlin",
	"Delafield",
	"Loßburg",
	"Néa Kallikráteia",
	"Burkardroth",
	"Dno",
	"Montecosaro",
	"Mount Horeb",
	"Big Stone Gap",
	"Gonzales",
	"Royalton",
	"Zanè",
	"Brecknock",
	"Pikeville",
	"Teteles de Avila Castillo",
	"Fehring",
	"Himberg",
	"Chauray",
	"Baurci",
	"Haddon Heights",
	"Page",
	"Maripí",
	"Bindlach",
	"Saint-André-de-la-Roche",
	"Ortenburg",
	"North Hanover",
	"Phu Kradueng",
	"La Ferté-Saint-Aubin",
	"Fairfax",
	"Dummerstorf",
	"Abtsgmünd",
	"Dumbrăveni",
	"Königsee",
	"Longpont-sur-Orge",
	"Albbruck",
	"Zlatna",
	"Drvar",
	"Corte",
	"Yreka",
	"Benton",
	"Norwich",
	"Peseux",
	"Marengo",
	"Louiseville",
	"Laje de Muriaé",
	"Douar Brarba",
	"Felizburgo",
	"Slavkov u Brna",
	"Bad Goisern",
	"Stonehouse",
	"Fairlawn",
	"Williamstown",
	"Goioxim",
	"Bischofswiesen",
	"Robinson",
	"São João Batista do Glória",
	"Pieve di Cento",
	"El Centenario",
	"Bernolákovo",
	"Adassil",
	"Saintry-sur-Seine",
	"Crozon",
	"Trombudo Central",
	"Tyrone",
	"Chesterfield",
	"Castlemaine",
	"Chibougamau",
	"Alpercata",
	"Swaffham",
	"North Star",
	"Nole",
	"Purcell",
	"Lowell",
	"Lambsheim",
	"Landen",
	"San Isidro",
	"Canicattini Bagni",
	"Sparanise",
	"Vohenstrauß",
	"Tazoult",
	"Saint-Ismier",
	"Miranda do Douro",
	"Birkenfeld",
	"Villarejo de Salvanés",
	"Curarrehue",
	"Longiano",
	"Maracajá",
	"Haslach im Kinzigtal",
	"Dos Palos",
	"Saint-Juéry",
	"Châteaubourg",
	"Loreto Aprutino",
	"Vimperk",
	"Šahy",
	"Valverde de la Virgen",
	"Aylmer",
	"Irajuba",
	"Louvroil",
	"Campo Belo do Sul",
	"Peqin",
	"Tafetachte",
	"Park Street",
	"Oceano",
	"Kirgiz-Miyaki",
	"Napajedla",
	"Fairfield",
	"Großmehring",
	"Krasnokutsk",
	"Volta Mantovana",
	"Nová Baňa",
	"Piikkiö",
	"Asbach",
	"Homberg",
	"Sappington",
	"Maurice River",
	"Torregrotta",
	"Serranópolis",
	"Breña Alta",
	"Paimpol",
	"Malchin",
	"Blaxland",
	"Killī",
	"Askawn",
	"Chōnan",
	"Bilisht",
	"São José da Barra",
	"Brugine",
	"Taznakht",
	"McCordsville",
	"Librazhd",
	"Sovetsk",
	"Le Péage-de-Roussillon",
	"Jurançon",
	"Silvis",
	"Kampong Mata Mata",
	"Bilton",
	"Newport",
	"Anna Paulowna",
	"Southwood Acres",
	"Friedrichroda",
	"Mount Shasta",
	"San Marco Argentano",
	"Salair",
	"Kirillov",
	"Buttapietra",
	"West Brandywine",
	"Ojai",
	"South Berwick",
	"Albuñol",
	"Križ",
	"Monticello",
	"Lennox Head",
	"Alstahaug",
	"Douar Oulad Boussaken",
	"Francisco León",
	"Guatapará",
	"Chã Preta",
	"Nisa",
	"Ra-ngae",
	"Lahouarta",
	"Smithfield",
	"Delson",
	"Villa Hills",
	"South Heidelberg",
	"Radeburg",
	"Montblanch",
	"St. Gabriel",
	"Píllaro",
	"San Emilio",
	"Bidart",
	"Almodôvar",
	"Tito",
	"Lander",
	"Trstená",
	"Palmer",
	"Wabern",
	"Sheringham",
	"Itacajá",
	"Dock Junction",
	"Otterndorf",
	"Nădlac",
	"Pelinia",
	"Bomaderry",
	"Poviglio",
	"Thung Fon",
	"Ten Boer",
	"Moravské Budějovice",
	"Sevenum",
	"Techirghiol",
	"Amara",
	"Kottmarsdorf",
	"Keystone Heights",
	"Balestrate",
	"West Cocalico",
	"Haapajärvi",
	"Ceriano Laghetto",
	"Benacazón",
	"Lenti",
	"Begas",
	"Braunton",
	"Tsarychanka",
	"Altaneira",
	"Bain-de-Bretagne",
	"Legden",
	"Sidi ’Allal al Mçader",
	"Pordic",
	"Douar Tassift",
	"Gundelsheim",
	"Carhaix-Plouguer",
	"Erlenbach",
	"White Oak",
	"Rockton",
	"Tha Ruea",
	"Cheadle",
	"Treuenbrietzen",
	"Yūbari",
	"Gedern",
	"Markelo",
	"Nyergesújfalu",
	"Lagnieu",
	"Geisenhausen",
	"Holdorf",
	"Rhoose",
	"Ashland",
	"Muribeca",
	"Eibenstock",
	"Bernkastel-Kues",
	"Bellegarde",
	"Arconate",
	"Malmyzh",
	"Leipheim",
	"Wingerworth",
	"Florence",
	"Coracora",
	"Sissach",
	"Old Town",
	"Edgerton",
	"Lautern",
	"New London",
	"Kuchl",
	"Shibecha",
	"Audun-le-Tiche",
	"Altendorf",
	"Talayuela",
	"Stratham",
	"Kingswood",
	"Saint-Étienne-de-Montluc",
	"Oulad Cherki",
	"Isyangulovo",
	"Emlichheim",
	"Pfaffenhofen an der Roth",
	"Waldstetten",
	"Oued El Makhazine",
	"Shenfield",
	"Kimberley",
	"Český Brod",
	"Rufina",
	"Kargasok",
	"Weikersheim",
	"Laguna Carapã",
	"Mutlangen",
	"North Bend",
	"Norg",
	"Shenstone",
	"Soave",
	"Hiawatha",
	"Mansôa",
	"Sennori",
	"Collado Mediano",
	"Hubbard",
	"River Rouge",
	"Joviânia",
	"Kaplice",
	"Sandwich",
	"Oebisfelde",
	"Maripipi",
	"Belen",
	"Kalsdorf bei Graz",
	"Marion",
	"Inverness",
	"Waitara",
	"Elassóna",
	"Great Bookham",
	"Cotati",
	"Wauseon",
	"Soltvadkert",
	"Beauvechain",
	"Cranves-Sales",
	"Atherton",
	"Ouro",
	"Inconfidentes",
	"Paulo Frontin",
	"Victoria",
	"Cercedilla",
	"Mills River",
	"Salete",
	"Untermeitingen",
	"Calverton",
	"Saranac Lake",
	"Rzhyshchiv",
	"Sanibel",
	"Bou Iferda",
	"Torrita di Siena",
	"Teyá",
	"Auvers-sur-Oise",
	"Mezhova",
	"Farmington",
	"Suonenjoki",
	"Blandford-Blenheim",
	"Simacota",
	"Santa Terezinha",
	"Soldotna",
	"Simitli",
	"Pirdop",
	"Trimbach",
	"Dévaványa",
	"Andorra",
	"Port Jefferson Station",
	"Monsenhor Hipólito",
	"Old Lyme",
	"Bayham",
	"Ivolginsk",
	"Tain-l’Hermitage",
	"Reiden",
	"Greenville",
	"Pătârlagele",
	"Loos-en-Gohelle",
	"Murr",
	"West Concord",
	"New Bremen",
	"Bétheny",
	"Neugersdorf",
	"Mittenwald",
	"Lignano Sabbiadoro",
	"Buchrain",
	"Mentor-on-the-Lake",
	"Saint-Christol-lez-Alès",
	"Aiquile",
	"Villa del Río",
	"Waterloo",
	"East Marlborough",
	"Arbaa Ayacha",
	"Ancient Oaks",
	"Tuncurry",
	"Farr West",
	"Odry",
	"Morteau",
	"Syanno",
	"Homun",
	"Livramento",
	"South Apopka",
	"Pembroke",
	"Komījān",
	"Nobitz",
	"Robeson",
	"Upper Deerfield",
	"Bujalance",
	"Militello in Val di Catania",
	"Ibirarema",
	"Grömitz",
	"Tornaľa",
	"Reyes",
	"Sericita",
	"Canóvanas",
	"Providence Village",
	"Buchs",
	"Esashi",
	"Murg",
	"Cazorla",
	"Inverness",
	"Le Roy",
	"Gypsum",
	"Clarkston",
	"Segré",
	"Arraiolos",
	"Bruzual",
	"Canova",
	"Tolleson",
	"Gröditz",
	"Luserna San Giovanni",
	"Carinola",
	"New Roads",
	"San Diego de Alejandría",
	"Fabrègues",
	"Ponchatoula",
	"La Concordia",
	"Strathalbyn",
	"Mapimí",
	"Slivnitsa",
	"Poiares",
	"Camp Pendleton North",
	"Santa Marta de Penaguião",
	"Antônio Olinto",
	"Humberston",
	"Roggiano Gravina",
	"Smithville",
	"East Pikeland",
	"Eden Isle",
	"Ban Chan",
	"Bannockburn",
	"Wolkersdorf im Weinviertel",
	"Pomona",
	"Uyskoye",
	"Pontevico",
	"Houston",
	"Cabeceiras",
	"Nazarèzinho",
	"Itagimirim",
	"Stegaurach",
	"Itaju do Colônia",
	"Derio",
	"Charles City",
	"Dettelbach",
	"Herne",
	"Ulmeni",
	"Longueau",
	"Villemandeur",
	"Paraíso do Sul",
	"Bourbourg",
	"Turceni",
	"Nakhon Luang",
	"Pedro Muñoz",
	"Rednitzhembach",
	"Passo do Sertão",
	"Harjavalta",
	"Allensbach",
	"Stanwood",
	"Si Chiang Mai",
	"Noventa di Piave",
	"Augusta",
	"Hampden",
	"St. Rose",
	"Saint-Pierre",
	"St. Joseph",
	"Borova",
	"Caiçara",
	"Abersychan",
	"San Fior di Sopra",
	"Lallaing",
	"Arechavaleta",
	"Sergeevka",
	"Crumlin",
	"Hudsonville",
	"Saint-Julien-de-Concelles",
	"Chemillé",
	"Ebern",
	"Indaiabira",
	"San Antonino Monteverde",
	"Claygate",
	"Tall Tamr",
	"Spring Hill",
	"Philadelphia",
	"Chalástra",
	"Monteirópolis",
	"Bellbrook",
	"Mexia",
	"Frankfort",
	"Kenwood",
	"Middleborough Center",
	"Montoir-de-Bretagne",
	"Iwye",
	"Mantua",
	"Zbąszyń",
	"Portalegre",
	"Mourenx",
	"Makawao",
	"Frankstown",
	"Seymour",
	"Gibsonville",
	"Parsberg",
	"Elzach",
	"Dade City",
	"Fresia",
	"Duba-Yurt",
	"Cuincy",
	"Caraá",
	"Nortorf",
	"La Gaude",
	"Puslinch",
	"Scheßlitz",
	"Forte dei Marmi",
	"Middlesex",
	"Red Chute",
	"Yssingeaux",
	"Pogoanele",
	"Lávrio",
	"Village St. George",
	"Winooski",
	"Alvaiázere",
	"Morciano di Romagna",
	"Rudno",
	"Belém do Brejo do Cruz",
	"Whaley Bridge",
	"Kuklen",
	"Bourg-Saint-Andéol",
	"Wriezen",
	"Matuguinao",
	"Maxatawny",
	"Zapotitlán",
	"Coeymans",
	"Manciano",
	"Sturry",
	"Rabaul",
	"Nazaré do Piauí",
	"Přeštice",
	"Campo Largo",
	"Emmering",
	"Santa Luzia do Norte",
	"Valley Center",
	"Tyrvää",
	"Castiglione della Pescaia",
	"Luisiana",
	"Vila Nova da Barquinha",
	"Menziken",
	"Cannington",
	"Enkenbach-Alsenborn",
	"Santo Domingo de Guzmán",
	"Umatilla",
	"Cehu Silvaniei",
	"Oak Grove",
	"Strasburg",
	"Wauchope",
	"Vanrhynsdorp",
	"Geneva",
	"Klichaw",
	"Menucourt",
	"Namborn",
	"Bardowick",
	"Immenhausen",
	"Gau-Algesheim",
	"Whiteville",
	"Novolakskoye",
	"Derry",
	"Whitchurch",
	"Freyung",
	"Manuel Viana",
	"Vila Nova de Foz Côa",
	"University of California-Davis",
	"Belmont",
	"Sérignan",
	"Nailsworth",
	"Soatá",
	"Farnham Royal",
	"Ephraim",
	"Buffalo",
	"Campagna Lupia",
	"Karpenísi",
	"Santo Antônio do Retiro",
	"Čavle",
	"Ruddington",
	"Heringen",
	"Lochbuie",
	"North Springfield",
	"Faringdon",
	"Torreperogil",
	"Téteghem",
	"Pfalzgrafenweiler",
	"Dačice",
	"Rosales",
	"Briton Ferry",
	"Lakhdenpokhya",
	"Soultz-Haut-Rhin",
	"Uddingston",
	"Longboat Key",
	"Saint-Bonnet-de-Mure",
	"Taga",
	"Tséri",
	"Buena Vista",
	"Dahlonega",
	"Cavezzo",
	"Bourg-Saint-Maurice",
	"Montecassiano",
	"Nebraska City",
	"Cordignano",
	"Hiraizumi",
	"Lymanske",
	"Königsbronn",
	"Hardeeville",
	"Connellsville",
	"Novafeltria",
	"Torrington",
	"Castro Verde",
	"Cassis",
	"Quesnoy-sur-Deûle",
	"Helensburgh",
	"New Cumberland",
	"Putzbrunn",
	"Nýřany",
	"Saint-Macaire-en-Mauges",
	"Pompey",
	"Pinehurst",
	"Altofts",
	"Cristalândia",
	"Huron",
	"Beauport",
	"Petropavlovka",
	"Yass",
	"Quiliano",
	"Alta Sierra",
	"Hitzendorf",
	"Mértola",
	"Wagenfeld",
	"Terras de Bouro",
	"Northridge",
	"Moab",
	"Głuszyca",
	"Ajuricaba",
	"Arpino",
	"Wildwood",
	"New Sewickley",
	"Nuporanga",
	"Baywood",
	"Cupcini",
	"Destêrro de Entre Rios",
	"Soběslav",
	"Cotgrave",
	"Northport",
	"Ralston",
	"Santana da Vargem",
	"Sędziszów Małopolski",
	"Bardolino",
	"Finestrat",
	"Tăuţii Măgheruş",
	"Madison",
	"Tanglewilde",
	"Lengenfeld",
	"Salles",
	"Montelabbate",
	"Ormond-by-the-Sea",
	"Wawayanda",
	"Jaraguari",
	"Delphos",
	"Stevensville",
	"Lihue",
	"Gatton",
	"North Berwick",
	"Hasparren",
	"Murillo",
	"Uznach",
	"Frielendorf",
	"Wißmar",
	"Hinte",
	"Cernobbio",
	"St. Louis",
	"Saint-Rémi",
	"St. Marys",
	"Valognes",
	"Gerolzhofen",
	"Naka",
	"Muxton",
	"Hobart",
	"Douar Drissiine",
	"Montalbano Ionico",
	"Tona",
	"Starokucherganovka",
	"Pleasantville",
	"Saerbeck",
	"Neptune Beach",
	"Annweiler am Trifels",
	"Sucé-sur-Erdre",
	"Ash Vale",
	"Shyriaieve",
	"Almeida",
	"Othis",
	"Crystal City",
	"Antonina do Norte",
	"Gioiosa Ionica",
	"Saint-Rémy",
	"Tuntenhausen",
	"Koshekhabl",
	"Saint-Jean-d’Angély",
	"Engenheiro Navarro",
	"Colipa",
	"Guidoval",
	"Union Grove",
	"Thal",
	"Lichtenwalde",
	"Absam",
	"San Vicente de Mont-Alt",
	"Reduto",
	"Busti",
	"Cubati",
	"Kapoeta",
	"Grove",
	"Jussiape",
	"Chianciano Terme",
	"Elin Pelin",
	"Ouardana",
	"Bernau am Chiemsee",
	"Fordingbridge",
	"Richland",
	"Bamnet Narong",
	"Bedford",
	"Belle Isle",
	"Poninka",
	"Immenstaad am Bodensee",
	"Woodstock",
	"Vazzola",
	"Franklin",
	"Cagnano Varano",
	"Collinsville",
	"Willow Oak",
	"Lith",
	"Chipping Ongar",
	"Emmitsburg",
	"Drayton Valley",
	"São José de Ubá",
	"Willows",
	"Parkville",
	"Roosevelt",
	"East Hills",
	"Pontremoli",
	"Tomatlán",
	"Regalbuto",
	"Acton",
	"Rockport",
	"Saint-Pathus",
	"Dingelstädt",
	"La Feria",
	"Washington",
	"Darganata",
	"Wolfschlugen",
	"Kurri Kurri",
	"Rovello Porro",
	"Stavelot",
	"Cornebarrieu",
	"Ponoka",
	"West Byfleet",
	"Steinenbronn",
	"Krasnogvardeyskoye",
	"Rivergaro",
	"Varallo",
	"Tappan",
	"Roccadaspide",
	"Pottendorf",
	"Cochituate",
	"Ferrol",
	"Amarkantak",
	"St. Johnsbury",
	"Saint-Just-sur-Loire",
	"Tlalnepantla",
	"Yakshur-Bod’ya",
	"Pelluhue",
	"Zacualpan",
	"Massanet de la Selva",
	"Durham",
	"Whitinsville",
	"Labrador City",
	"Feignies",
	"Batesville",
	"Altavilla Silentina",
	"Neckartenzlingen",
	"Lake Lorraine",
	"Chichimila",
	"Coundon",
	"Ikaalinen",
	"Spino d’Adda",
	"Seward",
	"Midland Park",
	"Lebanon",
	"Beaumont",
	"Moapa Valley",
	"Middletown",
	"Algarrobo",
	"Faverges",
	"Ensdorf",
	"Huéscar",
	"Rives",
	"Åsnes",
	"West Mersea",
	"Camerota",
	"Campli",
	"Lemwerder",
	"Klawer",
	"Fukaura",
	"Gioiosa Marea",
	"Dúrcal",
	"Tarabaí",
	"Corbin",
	"Ferlach",
	"Marathónas",
	"Arnoldstein",
	"Skaneateles",
	"Cooma",
	"Jackson",
	"Riverdale Park",
	"Shenango",
	"Donnacona",
	"Würenlos",
	"Fuentes de Andalucía",
	"Ugine",
	"Mimizan",
	"Knoxville",
	"Swanzey",
	"Saint-Jean-Bonnefonds",
	"Hedon",
	"Polýkastro",
	"West Bridgewater",
	"Grabs",
	"Pegognaga",
	"Hanga Roa",
	"Cappeln",
	"Roda de Bará",
	"Feldkirchen bei Graz",
	"Cassolnovo",
	"Unionville",
	"Gunnison",
	"Ibiraiaras",
	"Wiesendangen",
	"Church Gresley",
	"K’ajaran",
	"Spezzano Albanese",
	"Carhué",
	"Merzhausen",
	"Conewago",
	"Santi Cosma e Damiano",
	"Cisano Bergamasco",
	"San Vicente Pacaya",
	"La Grandeza",
	"Souk el Had",
	"Morrow",
	"Gueugnon",
	"Maasland",
	"Atlántida",
	"Abel Figueiredo",
	"Semënovka",
	"Grazzanise",
	"Southgate",
	"Bełżyce",
	"Downside",
	"Sapri",
	"Alfdorf",
	"Sandston",
	"Cles",
	"Vodňany",
	"Socha",
	"Pembroke",
	"Káto Achaḯa",
	"Earl",
	"Trino",
	"Roccarainola",
	"Belle Plaine",
	"Baricella",
	"Santiago",
	"Antônio Martins",
	"Sobótka",
	"Nuevo",
	"Wimereux",
	"Enoch",
	"Caulonia",
	"Gerbstedt",
	"Nuriootpa",
	"Rodalben",
	"Cajvana",
	"Pedro Avelino",
	"Trégunc",
	"Pacific",
	"McNab/Braeside",
	"Dronero",
	"Puilboreau",
	"Vernole",
	"Grubbenvorst",
	"Utuado",
	"Canfield",
	"Eugendorf",
	"Brioude",
	"Independence",
	"Rouadi",
	"Elsa",
	"Haapavesi",
	"Assoul",
	"Centerville",
	"Commerce",
	"Murgeni",
	"Scorrano",
	"General Toshevo",
	"Austell",
	"Faro",
	"Moncófar",
	"Montfort-sur-Meu",
	"Jettingen-Scheppach",
	"Jacuípe",
	"Urbania",
	"Vipiteno",
	"Centallo",
	"Esher",
	"Grasbrunn",
	"Venegono Inferiore",
	"Huautepec",
	"Fontanellato",
	"Hartha",
	"Cërrik",
	"Dois Irmãos do Tocantins",
	"Les Arcs",
	"Parkville",
	"East Vincent",
	"Macdonald",
	"Durgeli",
	"Schwarzenbach an der Saale",
	"Hillsborough",
	"Myadzyel",
	"Bagnères-de-Bigorre",
	"Capellades",
	"Crozet",
	"Chuarrancho",
	"Navarcles",
	"Cava Manara",
	"Araguacema",
	"Sénas",
	"Champagne-sur-Seine",
	"Pollock Pines",
	"Sassoferrato",
	"São Miguel de Taipu",
	"Outokumpu",
	"Gilford",
	"Magaramkent",
	"Laurentino",
	"Zibido San Giacomo",
	"Tyrone",
	"Larose",
	"Lanton",
	"Le Perray-en-Yvelines",
	"Lawrenceville",
	"Fundulea",
	"Murston",
	"Eu",
	"Waging am See",
	"Brook Highland",
	"Saint-Mandrier-sur-Mer",
	"Baie-Saint-Paul",
	"Onishi",
	"Ayotzintepec",
	"Bo Phloi",
	"Cottage Grove",
	"Pleurtuit",
	"Sains-en-Gohelle",
	"De Soto",
	"San Benedetto Po",
	"Palmyra",
	"São Tomé das Letras",
	"Bouchemaine",
	"Nappanee",
	"Merritt",
	"Treze de Maio",
	"Atherton",
	"Bellante",
	"Stryn",
	"Nienhagen",
	"Delfinópolis",
	"Saint-Rambert-d’Albon",
	"Bluewater",
	"Krasnyy Klyuch",
	"Nibley",
	"Dalcahue",
	"Kingsland",
	"Walled Lake",
	"Santana dos Garrotes",
	"Stow",
	"Machacalis",
	"Manduel",
	"Buttigliera Alta",
	"East Zorra-Tavistock",
	"Pozzuolo del Friuli",
	"Bedminster",
	"Museros",
	"Orosei",
	"San José de Cusmapa",
	"Porrentruy",
	"Pinhoe",
	"Holle",
	"Bednodem’yanovsk",
	"Akhmeta",
	"Boavita",
	"Pantigliate",
	"Eno",
	"Salento",
	"Létavértes",
	"Winsted",
	"Ko Pha-Ngan",
	"Isliam-Terek",
	"Nový Bydžov",
	"Trélissac",
	"São Tomás de Aquino",
	"Chauvigny",
	"Bonne Terre",
	"Live Oak",
	"Attica",
	"Săveni",
	"Rockville",
	"Châtel-Saint-Denis",
	"Étréchy",
	"Calipatria",
	"Mead",
	"Green Brook",
	"Novosokolniki",
	"Montellano",
	"Campomorone",
	"Salzweg",
	"Parkwood",
	"Capetinga",
	"Deeping Saint James",
	"Colonia Venustiano Carranza",
	"Sedlčany",
	"Ebermannstadt",
	"Oued Naanaa",
	"Timlilt",
	"Wallersdorf",
	"Washington",
	"Russellville",
	"Asciano",
	"Saint-Georges",
	"New Romney",
	"Krasnyy Chikoy",
	"Southam",
	"Eiterfeld",
	"Assebbab",
	"Naque",
	"Pinson",
	"Stoneham-et-Tewkesbury",
	"Rishton",
	"Ruhpolding",
	"Gryfów Śląski",
	"Palmitinho",
	"Douar Oulad Amer",
	"Novo Areal",
	"Katlenburg-Lindau",
	"Benalup de Sidonia",
	"Selma",
	"Serino",
	"Bammental",
	"Sidi Yahia Sawad",
	"Tlilapan",
	"Kupiansk",
	"Schönkirchen",
	"Amaral Ferrador",
	"Marble Falls",
	"Llissá de Vall",
	"El Triunfo",
	"Monte San Vito",
	"Okhansk",
	"Bridge of Allan",
	"Montluel",
	"Erndtebrück",
	"Manoppello",
	"Ellerau",
	"Mänttä",
	"Asbestos",
	"Junco do Seridó",
	"Herscheid",
	"Lavrinhas",
	"Decatur",
	"Fort Myers Beach",
	"Theix",
	"Oberndorf bei Salzburg",
	"Dunbar",
	"Mogyoród",
	"Nambu",
	"Roncoferraro",
	"Casteggio",
	"Cologny",
	"Serra Redonda",
	"Neukirchen",
	"Borgo a Mozzano",
	"Hensies",
	"Stadtroda",
	"Bom Sucesso",
	"Trith-Saint-Léger",
	"Buziaş",
	"Genadendal",
	"Byron Center",
	"Homestead Meadows South",
	"Portage Lakes",
	"Meridianville",
	"Wiernsheim",
	"Homeland",
	"Osceola",
	"Syracuse",
	"Eberdingen",
	"Clarkson",
	"Neustadt-Glewe",
	"Andreapol",
	"Borgo",
	"Chiang Kham",
	"Central de Minas",
	"Besao",
	"Guia Lopes",
	"Marshfield",
	"Flatwoods",
	"Sharhorod",
	"Santa Filomena do Maranhão",
	"Ruinen",
	"Petropavlivka",
	"Segarcea",
	"Quiévrain",
	"Quarrington",
	"Fair Plain",
	"Gateway",
	"Shibayama",
	"Náquera",
	"Mount Ivy",
	"Nova Ibiá",
	"Sommatino",
	"Milton-Freewater",
	"Ceyrat",
	"Buttstädt",
	"Dietenheim",
	"Itteville",
	"Frouard",
	"Ichemrarn",
	"Hörbranz",
	"Coppenbrügge",
	"Nyons",
	"River Park",
	"Marne",
	"Säkylä",
	"Novska",
	"Huron-Kinloss",
	"Lazise",
	"Brunstatt",
	"Sausalito",
	"Alecrim",
	"Martignacco",
	"Senise",
	"Periquito",
	"South Lockport",
	"Chādegān",
	"Highland Heights",
	"Woodlake",
	"Barnesville",
	"Edenkoben",
	"Bomlitz",
	"Rungis",
	"Tlapanaloya",
	"Delaware",
	"Robecco sul Naviglio",
	"Altmannstein",
	"Laneuveville-devant-Nancy",
	"Ittre",
	"Kirkleatham",
	"Oliena",
	"Newbury",
	"Berrien Springs",
	"Mzefroune",
	"St. Helena",
	"Polch",
	"Tismana",
	"Molochansk",
	"Hudson Falls",
	"Ciboure",
	"Saulx-les-Chartreux",
	"Veitsbronn",
	"Sainte-Pazanne",
	"Ungheni",
	"Noble",
	"Gulf Park Estates",
	"Caudan",
	"Lastrup",
	"Fehraltorf",
	"Sotuta",
	"Settimo San Pietro",
	"Redondo",
	"Pâncota",
	"Oak Ridge",
	"São Brás",
	"Iraí de Minas",
	"Honley",
	"Rochester",
	"Mallersdorf-Pfaffenberg",
	"In Guezzam",
	"Acigné",
	"Bothwell",
	"Albisola Marina",
	"Tumut",
	"Diepoldsau",
	"Orange City",
	"Coteau-du-Lac",
	"Jussara",
	"Aígina",
	"Carnarvon",
	"Hecklingen",
	"Kaitaia",
	"Grassau",
	"Shinmachi",
	"Seminole",
	"Moringen",
	"San Clemente",
	"Sunndalsøra",
	"Piskivka",
	"Sant’Agostino",
	"Meleiro",
	"Groesbeck",
	"Campestre",
	"Litóchoro",
	"Chabeuil",
	"Springs",
	"Marksville",
	"Truth or Consequences",
	"Mondelange",
	"Les Essarts-le-Roi",
	"Nanteuil-lès-Meaux",
	"Polessk",
	"Surgères",
	"Summit",
	"Laurium",
	"Sărmaşu",
	"Pignola",
	"Blythebridge",
	"Archbald",
	"Busseto",
	"Neukieritzsch",
	"Providence",
	"Puerto Serrano",
	"Schliersee",
	"Pinotepa de Don Luis",
	"Willis",
	"Algrange",
	"Néo Karlovási",
	"Cannonvale",
	"Zaouiat Sidi Hammou Ben Hmida",
	"Krásno nad Kysucou",
	"Gradisca d’Isonzo",
	"Herbignac",
	"Eyguières",
	"Ustaritz",
	"Formigueiro",
	"Rancho Calaveras",
	"Tenamaxtlán",
	"The Blue Mountains",
	"Meopham",
	"Aridaía",
	"Tuzamapan",
	"Cerano",
	"Eston",
	"Abercynon",
	"Yarkovo",
	"Kentfield",
	"Labico",
	"Salto da Divisa",
	"Limours",
	"Kiel",
	"Gouverneur",
	"Răcari",
	"Tubinskiy",
	"Kistelek",
	"Villasor",
	"Frutigen",
	"Wigton",
	"Carlton",
	"Wellsville",
	"Mount Carmel",
	"Grand-Charmont",
	"Chaek",
	"Newbridge",
	"Schwerzenbach",
	"Reichelsheim",
	"Carnforth",
	"Geithain",
	"Sapucaí-Mirim",
	"Bonndorf im Schwarzwald",
	"Melesse",
	"Polgárdi",
	"Lutterbach",
	"Fuente el Saz",
	"Marina di Gioiosa Ionica",
	"Marcallo con Casone",
	"Camerino",
	"Épône",
	"Whitewater Region",
	"Chessy",
	"Virrat",
	"Ruswil",
	"Preesall",
	"Crete",
	"Meraux",
	"Point Vernon",
	"Kiefersfelden",
	"Vorozhba",
	"Garden Home-Whitford",
	"Kravaře",
	"Mezhdūrechenskoye",
	"Lützelbach",
	"St. Augustine Beach",
	"Oppdal",
	"Sosnytsia",
	"Velika Kladuša",
	"Kouango",
	"Warnemünde",
	"Rosny-sur-Seine",
	"Praia a Mare",
	"Piława Górna",
	"Solyanka",
	"Cazenovia",
	"Zuni Pueblo",
	"Ovacık",
	"Vimianzo",
	"Vacarisas",
	"Luxeuil-les-Bains",
	"Ponto Belo",
	"Pryvillya",
	"Xochicoatlán",
	"Lipany",
	"Olevano sul Tusciano",
	"Torrisholme",
	"Cabeceira Grande",
	"Nambucca Heads",
	"Mendota",
	"Saint-Pée-sur-Nivelle",
	"Laer",
	"Labenne",
	"Guatavita",
	"Cos Cob",
	"Xaçmaz",
	"Herrliberg",
	"São Miguel do Anta",
	"Tixpehual",
	"Adel",
	"Wynyard",
	"Champigneulles",
	"Cangyanshanzhen",
	"Vendargues",
	"Zirc",
	"Heathrow",
	"Calverton",
	"Galliano",
	"Alliste",
	"Faina",
	"Eagle",
	"Vétroz",
	"Caussade",
	"Hillsboro",
	"Oakdale",
	"Tomakivka",
	"Schwarzenburg",
	"Paulicéia",
	"Barberton",
	"Kieta",
	"Bad Hofgastein",
	"Želiezovce",
	"Conquista",
	"Shevchenkove",
	"Abadia dos Dourados",
	"Lozen",
	"Keyport",
	"Whitianga",
	"Buñola",
	"Beacon Square",
	"North Mackay",
	"Wüstenrot",
	"Ammanford",
	"Vilshany",
	"Schelklingen",
	"Heillecourt",
	"Jarovnice",
	"Laudenbach",
	"Villadossola",
	"Brier",
	"Givet",
	"Trumann",
	"Santa Giustina",
	"La Ametlla de Mar",
	"Milltown",
	"Vyerkhnyadzvinsk",
	"Água Doce",
	"Pleidelsheim",
	"Epping",
	"Almacellas",
	"Chynadiyovo",
	"Arosio",
	"Hamilton",
	"Dubrowna",
	"Le Pian-Médoc",
	"Leitchfield",
	"Confins",
	"Calamonte",
	"Galiléia",
	"Burgebrach",
	"Carapelle",
	"Indianópolis",
	"Böhlen",
	"Assais",
	"Creswell",
	"Edwardsburgh/Cardinal",
	"Hartberg",
	"Nérac",
	"Bondurant",
	"San Buenaventura",
	"Charoen Sin",
	"Freckleton",
	"Haworth",
	"Argamasilla de Alba",
	"Stratford",
	"Araporã",
	"Prairie View",
	"Catoosa",
	"Borgo Val di Taro",
	"Paintsville",
	"Atkinson",
	"Dielsdorf",
	"Néoi Epivátes",
	"Taliwine",
	"Ipeúna",
	"Artern",
	"Akyar",
	"Texhuacán",
	"San Cipriano Picentino",
	"Ogunimachi",
	"Plédran",
	"Plymouth Meeting",
	"Ixtenco",
	"Cliza",
	"Palmópolis",
	"Cölbe",
	"Divinópolis do Tocantins",
	"Teshikaga",
	"Bremen",
	"Marzabotto",
	"Caraglio",
	"Lercara Friddi",
	"Enderby",
	"Birmensdorf",
	"Ocean City",
	"São José do Ouro",
	"Samahil",
	"Dobroslav",
	"Upper Freehold",
	"Roost-Warendin",
	"Selfoss",
	"Azgour",
	"Aya",
	"Nova Siri",
	"Holice",
	"Cojuşna",
	"Kórnik",
	"Zolakar",
	"Franklin",
	"Roskovec",
	"Baarle-Nassau",
	"Höchstädt an der Donau",
	"Vandalia",
	"Tommot",
	"Edgemoor",
	"Southampton",
	"Westmere",
	"Sainte-Anne-des-Monts",
	"Fort Meade",
	"Vengerovo",
	"Mileto",
	"Osprey",
	"Gniew",
	"Thyez",
	"Farmington",
	"Newton Grange",
	"Limasawa",
	"Franklin",
	"Waddington",
	"San Luis",
	"Geiselhöring",
	"Sabáudia",
	"Peru",
	"Askino",
	"Roma",
	"Divisa Alegre",
	"Casares",
	"Fort Pierce North",
	"Römhild",
	"San José del Progreso",
	"Ploeren",
	"Salgareda",
	"Burguillos",
	"Canohès",
	"Felsőzsolca",
	"Rodenberg",
	"Sturgis",
	"São Carlos do Ivaí",
	"Aguiarnópolis",
	"Sêraitang",
	"Neufchâteau",
	"West Samoset",
	"Bern",
	"Treasure Island",
	"Iitti",
	"Estaires",
	"Tălmaciu",
	"Čegrane",
	"Branston",
	"Tizi Ouzli",
	"Schöppingen",
	"Spring Lake Park",
	"Paradas",
	"Olevano Romano",
	"Black Jack",
	"Camposano",
	"Lewisburg",
	"Susa",
	"Güicán",
	"Maquiné",
	"Carrigtohill",
	"Heber Springs",
	"Senafe",
	"Müncheberg",
	"Saue",
	"Gália",
	"Conemaugh",
	"West Slope",
	"Villmar",
	"Guareña",
	"Glorinha",
	"El Maader El Kabir",
	"Woodland",
	"Ostiglia",
	"Devin",
	"Vésztő",
	"Tiros",
	"Old Chelsea",
	"Tiefenbach",
	"Moëlan-sur-Mer",
	"Saḩam al Jawlān",
	"Mercato Saraceno",
	"Oberrieden",
	"Volkmarsen",
	"Erp",
	"Westbrook",
	"Cambo-les-Bains",
	"Chasse-sur-Rhône",
	"Ferreira Gomes",
	"Sikatuna",
	"Orgeval",
	"Interlaken",
	"Zakan-Yurt",
	"Garabogaz",
	"Great Barrington",
	"Nandy",
	"Gulf Breeze",
	"Infiesto",
	"Boa Vista",
	"Güeñes",
	"Viola",
	"Freeland",
	"Noyelles-Godault",
	"Schoorl",
	"Bandjoun",
	"Avon",
	"Lake Mills",
	"Byron",
	"Limeira d’Oeste",
	"Hulín",
	"Latimer",
	"Loison-sous-Lens",
	"Loudun",
	"Thermalito",
	"Whitemarsh Island",
	"Colusa",
	"Union",
	"Junction City",
	"Taradell",
	"Santiago Yaveo",
	"Ait Said",
	"Merrimac",
	"Ben Lomond",
	"Stawell",
	"Fabbrico",
	"Bossoroca",
	"Kampong Beribi",
	"Marilena",
	"Staré Město",
	"University Park",
	"Ban Haet",
	"Yeniceoba",
	"Akka",
	"Beerwah",
	"Sury-le-Comtal",
	"Gleneagle",
	"Mountainside",
	"Saint-Pol-de-Léon",
	"Ludres",
	"Lienden",
	"Jerichow",
	"Jevnaker",
	"Brendola",
	"Bonfim",
	"Battle",
	"Moores Mill",
	"East Buffalo",
	"Vitorino",
	"Constantí",
	"São Sebastião do Curral",
	"Péronnas",
	"Mambaí",
	"Perry",
	"Aydemir",
	"Chestertown",
	"Saint-Alban-Leysse",
	"Notaresco",
	"Cheddleton",
	"Almenno San Salvatore",
	"Labarthe-sur-Lèze",
	"Brindas",
	"Pietraperzia",
	"Saanen",
	"North Stormont",
	"Mojácar",
	"St. Martinville",
	"Harrisville",
	"Pöttmes",
	"Fishtoft",
	"Oulad Sbih",
	"Cuitegi",
	"Assays",
	"Beaufort-en-Vallée",
	"Oakley",
	"Huron",
	"Mechanicstown",
	"Alnwick/Haldimand",
	"Rosice",
	"Newbiggin-by-the-Sea",
	"Oberuzwil",
	"Loomis",
	"Nervesa della Battaglia",
	"Northam",
	"Baloži",
	"Sawtry",
	"Jackson",
	"Montegiorgio",
	"Thorpe",
	"Bergondo",
	"Montaigu",
	"Schwendi",
	"Dozza",
	"Wutöschingen",
	"Great Chart",
	"Nagatoro",
	"King",
	"Breidenbach",
	"West Glens Falls",
	"Leinburg",
	"Herdorf",
	"Youngtown",
	"Mesola",
	"Phelps",
	"Upper Nazareth",
	"Lemoore Station",
	"Ererê",
	"Lutherville",
	"Floresta",
	"Milford",
	"Spišská Belá",
	"Upper Mount Bethel",
	"Greenbrier",
	"Letovice",
	"Southbourne",
	"Tabernacle",
	"Venta de Baños",
	"Horncastle",
	"Nizhnyaya Tavda",
	"Georgensgmünd",
	"Saint-Pierre-d’Oléron",
	"Meung-sur-Loire",
	"Maulbronn",
	"Negreira",
	"Lecanto",
	"Curinga",
	"Ribeiro Gonçalves",
	"Montebello Vicentino",
	"Sandycroft",
	"Mount Cotton",
	"Risør",
	"Eldridge",
	"Teiuş",
	"East Earl",
	"Hulha Negra",
	"Dobruška",
	"Old Greenwich",
	"Malvern Link",
	"Idabel",
	"Mehun-sur-Yèvre",
	"Piedimonte San Germano",
	"South Patrick Shores",
	"Peace River",
	"Isenbüttel",
	"Sunnyvale",
	"Bee Cave",
	"Haag in Oberbayern",
	"Lovejoy",
	"Gigean",
	"Clay",
	"Torgiano",
	"Sallent",
	"Garsten",
	"Chugqênsumdo",
	"Sekigahara",
	"Ács",
	"Almenno San Bartolomeo",
	"Loriol-sur-Drôme",
	"Machern",
	"Fircrest",
	"Landore",
	"Annezin",
	"Altenkirchen",
	"Gelterkinden",
	"Ladbergen",
	"Dunbar",
	"Bietigheim",
	"Hainburg an der Donau",
	"Bălan",
	"Tagliacozzo",
	"Puchezh",
	"Alstonville",
	"Ivanka pri Dunaji",
	"Brixworth",
	"Livada",
	"Lincoln",
	"Careaçu",
	"Klundert",
	"Cacimbas",
	"Renascença",
	"Milo",
	"San Vincenzo",
	"Mozonte",
	"Huntertown",
	"Saint-André-des-Eaux",
	"Oberhausbergen",
	"Mejji",
	"Lagoa de São Francisco",
	"Ocampo",
	"Bilmak",
	"Saint Blazey",
	"Crossett",
	"Vidigulfo",
	"Puerto Nariño",
	"Waldeck",
	"Kropp",
	"Ostrach",
	"Knyaginino",
	"José Pedro Varela",
	"Baboua",
	"Herval",
	"Santo Antônio do Pinhal",
	"Margaret River",
	"Belo Monte",
	"Taneytown",
	"Mount Vernon",
	"Glen Innes",
	"Kostenets",
	"Roteiro",
	"San Francisco del Norte",
	"Peñarrubia",
	"Angical do Piauí",
	"Cittaducale",
	"Puerto Aventuras",
	"Fort Myers Shores",
	"Bovenkarspel",
	"Offenbach an der Queich",
	"Vizinga",
	"Lossiemouth",
	"Kirtland",
	"Bishops Waltham",
	"Hornostaivka",
	"Castello d’Argile",
	"Lakes of the Four Seasons",
	"Na Duang",
	"Verneuil-sur-Avre",
	"Rheurdt",
	"Loanhead",
	"Tyrnävä",
	"Gilwala",
	"Znada",
	"Bolzano Vicentino",
	"Oensingen",
	"Făget",
	"Turís",
	"Yamba",
	"Nagaya",
	"Šenov",
	"Inari",
	"Marion",
	"Kristinestad",
	"Chuí",
	"San Martin",
	"Arran-Elderslie",
	"Ban Bang Pakong",
	"Kimberly",
	"Taylor Mill",
	"Marmolejo",
	"Yardville",
	"East Shoreham",
	"Bani Bangou",
	"Nachrodt-Wiblingwerde",
	"Ouvidor",
	"Argés",
	"Cromwell",
	"South Hanover",
	"Ytyk-Kyuyël’",
	"Mutzig",
	"Velizh",
	"Bienenbüttel",
	"Valley Park",
	"General Fernández Oro",
	"Saint-Yrieix-la-Perche",
	"Waldsassen",
	"Waldmünchen",
	"Svelvik",
	"Natividade da Serra",
	"Thousand Palms",
	"Amory",
	"Pérola do Oeste",
	"Brech",
	"Columbus",
	"Celeiroz",
	"Waterford",
	"Lake Mathews",
	"Marvin",
	"Itambaracá",
	"Heitersheim",
	"Fowler",
	"Mellingen",
	"Le Cendre",
	"Kervignac",
	"Malaunay",
	"Fishhook",
	"Kraslice",
	"Ampfing",
	"San Felíu de Codinas",
	"Courtepin",
	"Landriano",
	"Hoosick",
	"Almese",
	"Castel di Sangro",
	"Hersin-Coupigny",
	"Hombourg-Haut",
	"Breinigsville",
	"Marles-les-Mines",
	"Douar el Caïd el Gueddari",
	"Gordonvale",
	"Terezinha",
	"Lincoln",
	"Charagua",
	"Mimoň",
	"Kishkeneköl",
	"Groveland",
	"Poggio Rusco",
	"Melenci",
	"Tumiritinga",
	"Untergrombach",
	"Dovolnoye",
	"Blainville-sur-Orne",
	"Santa Cruz da Vitória",
	"Fukuyama",
	"Myers Corner",
	"Ispringen",
	"Mede",
	"Plouguerneau",
	"Malchow",
	"Luftkurort Arendsee",
	"‘Ayn ‘Īsá",
	"Trappenkamp",
	"Ghafsaï",
	"Colombier",
	"Boa Vista do Burica",
	"Parkano",
	"Zerkat",
	"Saint-Zotique",
	"Greifenstein",
	"Cellamare",
	"La Canonja",
	"Ríudoms",
	"Quixaba",
	"Reutte",
	"Oleksandrivsk",
	"Danbury",
	"Castro Marim",
	"Millersville",
	"Ciserano",
	"Glashütte",
	"Cornuda",
	"Cortland",
	"Crisólita",
	"Serra San Bruno",
	"Eggersdorf bei Graz",
	"Cazaclia",
	"Sofiivka",
	"Mitterteich",
	"Montelepre",
	"Guánica",
	"San Donaci",
	"Châtelaillon-Plage",
	"Annaburg",
	"Santo Antônio da Alegria",
	"Irshansk",
	"East Coventry",
	"Oedheim",
	"Hardheim",
	"Santa Clara do Sul",
	"Ibrány",
	"Grand-Fort-Philippe",
	"Williamson",
	"Avallon",
	"Larino",
	"Plymouth",
	"Desert Palms",
	"Açuã",
	"Lathen",
	"Indian River Estates",
	"Cedeira",
	"Ghisalba",
	"Yetkul",
	"Kamihonbetsu",
	"Sutri",
	"Asten",
	"Odolena Voda",
	"Cranbrook",
	"Fuente del Maestre",
	"Medzilaborce",
	"Alilem",
	"Gémenos",
	"Petershausen",
	"Kōchi",
	"La Bouilladisse",
	"Santiago Maravatío",
	"Pembroke Park",
	"Vezin-le-Coquet",
	"Horbourg",
	"Washington",
	"Sobrance",
	"Lake Hallie",
	"Ellettsville",
	"Diano Marina",
	"Arapoema",
	"Sapopema",
	"Saint-Florent-sur-Cher",
	"Ambert",
	"Güglingen",
	"Saint-Girons",
	"Sankt Margrethen",
	"Moraleja",
	"Kremsmünster",
	"Gūged",
	"Prairie Grove",
	"Tvarditsa",
	"Timoulilt",
	"Napili-Honokowai",
	"Buia",
	"Khmis Sidi Yahia",
	"Gorom-Gorom",
	"Montevallo",
	"Sinn",
	"Kimberley",
	"Marineo",
	"Countesthorpe",
	"Beromünster",
	"Mount Healthy",
	"Changé",
	"Enying",
	"Clover Creek",
	"Allendale",
	"Luislândia",
	"Marange-Silvange",
	"Bois-le-Roi",
	"Marignier",
	"Rupert",
	"Snowflake",
	"Bayville",
	"Ijoukak",
	"Puebla de Alfindén",
	"Perechyn",
	"Douar Oulad Mkoudou",
	"Mount Hope",
	"Litchfield",
	"Muggensturm",
	"Berkley",
	"Boxberg",
	"Schildow",
	"Cubará",
	"Verzuolo",
	"Glandorf",
	"Jimena de la Frontera",
	"Weisendorf",
	"Maple Glen",
	"Eatonton",
	"Tuxcueca",
	"Kamennogorsk",
	"Kiama Downs",
	"Catugi",
	"Bulkington",
	"Thônes",
	"Bellwood",
	"Vörå",
	"Obdam",
	"Alvechurch",
	"Aleksandrovskoye",
	"Lacy-Lakeview",
	"Bad Brückenau",
	"Saint-Marcel-lès-Valence",
	"Kosciusko",
	"Tunkhannock",
	"Hinojosa del Duque",
	"Rajamäki",
	"Orchard Mesa",
	"Roaring Spring",
	"Calonne-Ricouart",
	"San Martino Siccomario",
	"Woodfin",
	"Eggolsheim",
	"Pollenza",
	"Saint-Marcel",
	"Rumson",
	"Pedra Bonita",
	"Saint-Prex",
	"Bāzargān",
	"Letohrad",
	"Broadway",
	"Medina",
	"San Juan Mixtepec",
	"Orio",
	"Kaiseraugst",
	"Val-Shefford",
	"Harrisburg",
	"Et Tnine des Beni Ammart",
	"Velden",
	"Douro-Dummer",
	"Schwabhausen",
	"Frohnleiten",
	"Yaring",
	"Ilshofen",
	"Phanom Sarakham",
	"Santa Lucía Monte Verde",
	"Gamovo",
	"Bouillargues",
	"Kampong Mulaut",
	"Pottsville",
	"Bermuda Dunes",
	"New Hartford",
	"Cellino San Marco",
	"Hermeskeil",
	"Dußlingen",
	"Villers-le-Bouillet",
	"Pavilly",
	"Losone",
	"New Alresford",
	"Viriat",
	"Wilhelmsburg",
	"Pendleton",
	"Bol’shaya Chernigovka",
	"Küttigen",
	"Bučovice",
	"Gochsheim",
	"Olney",
	"That Phanom",
	"Bullskin",
	"Horstmar",
	"San Cesario sul Panaro",
	"Cinquefrondi",
	"Castelnau-d’Estrétefonds",
	"Chalonnes-sur-Loire",
	"Sottrum",
	"Phan Thong",
	"Villabé",
	"Lityn",
	"Corbie",
	"Röhrmoos",
	"Staryi Sambir",
	"Wescosville",
	"Chinchilla",
	"Blatná",
	"Avetrana",
	"Rinconada",
	"Ōmachi",
	"Karagay",
	"Kronoby",
	"Pontenure",
	"Hage",
	"Trezzano Rosa",
	"Ashoro",
	"Gramsbergen",
	"Roeland Park",
	"Plessisville",
	"Pälkäne",
	"Hanover",
	"Horn",
	"Dayton",
	"Lucas Valley-Marinwood",
	"Zevenhuizen",
	"Arbucias",
	"Sawley",
	"Faxinal do Soturno",
	"Montenero di Bisaccia",
	"Tafingoult",
	"Louhans",
	"Cherryville",
	"Pecquencourt",
	"Belfast",
	"Ritchot",
	"Putnok",
	"Padre Marcos",
	"Alcalá de Chivert",
	"Fishguard",
	"Nevada",
	"Lichtetanne",
	"Gouesnou",
	"Sevsk",
	"Alpicat",
	"Lysterfield",
	"Saint-Maurice-l’Exil",
	"Teufen",
	"Sallapadan",
	"Calci",
	"Ijaci",
	"Sumirago",
	"Montauroux",
	"Viitasaari",
	"Eppertshausen",
	"Svesa",
	"Mindszent",
	"Arnstedt",
	"Otonabee-South Monaghan",
	"Pinhão",
	"Schladming",
	"Las Pedroñeras",
	"Zierenberg",
	"Cwmafan",
	"Neuenstein",
	"Misinto",
	"Dunlap",
	"South Molton",
	"Sainte-Livrade-sur-Lot",
	"Pizzighettone",
	"Nong Chik",
	"Knetzgau",
	"Guarantã",
	"Montdidier",
	"Vairano Patenora",
	"Albizzate",
	"Ar Rawḑah",
	"Marcaria",
	"Church Hill",
	"Exeter",
	"Potomac Mills",
	"Shediac",
	"Tatatila",
	"Bickenbach",
	"Briviesca",
	"Verkhniye Tatyshly",
	"Tangstedt",
	"San Antonio de Vilamajor",
	"Neuffen",
	"Cooranbong",
	"Illogan",
	"Straldzha",
	"Hopefield",
	"Eichendorf",
	"Lachendorf",
	"Linwood",
	"Ashville",
	"Barro Duro",
	"Wirksworth",
	"Ventnor",
	"Kizilskoye",
	"Pilsting",
	"Valley Falls",
	"Sandersville",
	"Colnbrook",
	"Rissa",
	"Chapelhall",
	"Blairsville",
	"Fegersheim",
	"Douvaine",
	"Bridgeport",
	"Gualtieri",
	"Diekholzen",
	"Ascona",
	"Hunters Quay",
	"Sturtevant",
	"Nyírtelek",
	"Eaunes",
	"Slave Lake",
	"Port-Cartier",
	"Castronno",
	"Litchfield",
	"Sammichele di Bari",
	"San Adrián",
	"Verkhniye Kigi",
	"Cherry Hills Village",
	"Soúda",
	"Grandes Rios",
	"Barrington",
	"Douar El Gouzal",
	"Barão de Cotegipe",
	"Kozármisleny",
	"Pocahontas",
	"Morrilton",
	"La Moncada",
	"Saint-Genest-Lerpt",
	"Osterburken",
	"Corjeuţi",
	"Blanca",
	"Châtenoy-le-Royal",
	"Bellingwolde",
	"Barrington",
	"Fislisbach",
	"Shikama",
	"Mariópolis",
	"Belle Haven",
	"Bolligen",
	"Tanvald",
	"Capannoli",
	"Zavetnoye",
	"Independência",
	"Juana Díaz",
	"Tecumseh",
	"Châtel-Guyon",
	"Mecca",
	"Rocky Mountain House",
	"Flers-en-Escrebieux",
	"Poggio Mirteto",
	"Mira Monte",
	"Gournay-en-Bray",
	"Einhausen",
	"Kounoupidianá",
	"Creel",
	"Pea Ridge",
	"Belmont",
	"Palma",
	"Yemilchyne",
	"Tsiolkovskiy",
	"Green Knoll",
	"Ban Hua Saphan",
	"Villamuriel de Cerrato",
	"Chatsworth",
	"Trysil",
	"Lloseta",
	"Dona Eusébia",
	"Veneta",
	"North Syracuse",
	"Kirchberg",
	"Uffenheim",
	"Jiaotanzhuang",
	"Anta",
	"Amga",
	"Ghadāmis",
	"Monte Águila",
	"Bellona",
	"Stephenville",
	"Schlangenbad",
	"Le Palais-sur-Vienne",
	"Cadaujac",
	"Cornetu",
	"Battlefield",
	"Grézieu-la-Varenne",
	"East Leake",
	"North Caldwell",
	"Beaver Dam",
	"Prades",
	"Metsavan",
	"Roccella Ionica",
	"Guinisiliban",
	"Ladera Heights",
	"Stewarton",
	"Bezopasnoye",
	"Winthrop Harbor",
	"Whitmore Lake",
	"Neuhofen an der Krems",
	"Verkhneye Kazanishche",
	"Market Weighton",
	"Hillsboro",
	"Valdoviño",
	"L’Hôpital",
	"Arquata Scrivia",
	"Diamante",
	"Grão Pará",
	"Brewton",
	"Tamanredjo",
	"Limbricht",
	"Anávyssos",
	"Orlov",
	"Kermit",
	"Belišće",
	"Demopolis",
	"Thalheim",
	"Veigné",
	"Awjilah",
	"São José do Piauí",
	"Santa Cruz",
	"Dalton",
	"Burgkunstadt",
	"Guairaçá",
	"Labruguière",
	"Saint-Pryvé-Saint-Mesmin",
	"Talent",
	"Carral",
	"Dubňany",
	"Sudova Vyshnya",
	"Almensilla",
	"Pan de Azúcar",
	"Jaraiz de la Vera",
	"Hamilton",
	"Trinity",
	"Ploče",
	"Oberding",
	"Uthai",
	"Normandy Park",
	"Peterborough",
	"Essex",
	"Montesano sulla Marcellana",
	"Santa Fé do Araguaia",
	"Kerkwijk",
	"Bridge City",
	"Echallens",
	"Ambridge",
	"Amite City",
	"Saint-Flour",
	"Catasauqua",
	"Ivanychi",
	"Passo do Sobrado",
	"Lydd",
	"Kirchheim am Neckar",
	"Afonso Cunha",
	"Rodewisch",
	"Cocoa West",
	"Pryazovske",
	"Tetecala",
	"Lat Yao",
	"Tüddern",
	"Strongoli",
	"Mainleus",
	"Alessano",
	"Strensall",
	"Dingwall",
	"Tsuman",
	"Slatyne",
	"Nikhom Kham Soi",
	"Nishiharadai",
	"Győrújbarát",
	"Forestbrook",
	"Thannhausen",
	"Gallicano nel Lazio",
	"Cipotânea",
	"Siziano",
	"Kerekegyháza",
	"Eliot",
	"Treforest",
	"Templeuve",
	"Morro Reuter",
	"Bad Blankenburg",
	"Capranica",
	"Bethel",
	"Nova Aliança",
	"Tuiuti",
	"Lavelanet",
	"Aratiba",
	"Buc",
	"Meolo",
	"Northgate",
	"Sretensk",
	"Gonnosfanàdiga",
	"Makaryev",
	"Světlá nad Sázavou",
	"Turčianske Teplice",
	"Havelberg",
	"Ishqoshim",
	"Varshets",
	"Isen",
	"Calatafimi",
	"Águas Mornas",
	"Livigno",
	"Roseţi",
	"Devon",
	"Lehliu-Gară",
	"Arcole",
	"Solbiate Olona",
	"Oneonta",
	"Mamonas",
	"Phu Khiao",
	"Bua Chet",
	"Condom",
	"Lezo",
	"Khok Samrong",
	"Ribeira de Pena",
	"Agapovka",
	"Mont Belvieu",
	"Bilokurakyne",
	"Téglás",
	"Challes-les-Eaux",
	"Guarda Mor",
	"Großalmerode",
	"Porter",
	"São José do Jacuri",
	"Cold Spring",
	"Makarov",
	"San Giorgio in Bosco",
	"Vélez Rubio",
	"Scriba",
	"Harvard",
	"Fossacesia",
	"Keene",
	"Walnut Ridge",
	"Topsfield",
	"Krimpen aan de Lek",
	"Thompson's Station",
	"Condor",
	"Schöffengrund",
	"Stickney",
	"Aszód",
	"Ohio",
	"Haparanda",
	"Burwell",
	"Spassk-Ryazanskiy",
	"Wentworth Falls",
	"Ploudalmézeau",
	"Quinzano d’Oglio",
	"Sertão de Santana",
	"Green Hill",
	"Raon-l’Étape",
	"Seeboden",
	"Rossford",
	"Blackwell",
	"Águas de Chapecó",
	"Serzhen’-Yurt",
	"Outjo",
	"Cândido Godói",
	"Bad Marienberg",
	"Wathlingen",
	"Însurăţei",
	"Nemšová",
	"Hiroo",
	"Andijk",
	"Scone",
	"Blonay",
	"Ottersweier",
	"Fairfield",
	"Lawrence",
	"Codevigo",
	"Barrow upon Soar",
	"Juva",
	"Ras Ijerri",
	"Roslyn Heights",
	"Allegheny",
	"Albanella",
	"Gignac",
	"South Hill",
	"Spinazzola",
	"Vestnes",
	"Gata de Gorgos",
	"Earlswood",
	"Quirihue",
	"Waki",
	"Waikoloa Village",
	"Veselí nad Lužnicí",
	"Sehma",
	"Keyes",
	"Cumming",
	"Crégy-lès-Meaux",
	"Zaratán",
	"Dettingen unter Teck",
	"Katikati",
	"Portage",
	"Linz am Rhein",
	"South Elmsall",
	"Fox Point",
	"Castelgomberto",
	"Porto do Mangue",
	"Rosolina",
	"Bruguières",
	"Beaudesert",
	"Slippery Rock",
	"Clairton",
	"Oakmont",
	"São Francisco de Oliveira",
	"Fairview",
	"Melhus",
	"Plaquemine",
	"Agriá",
	"Elkin",
	"Nova Palma",
	"Beyram",
	"Bischofszell",
	"Plattsmouth",
	"De Queen",
	"Swanton",
	"Hapeville",
	"Laurence Harbor",
	"Montech",
	"Plaidt",
	"L’Isle-d’Espagnac",
	"Marisópolis",
	"Zellingen",
	"Beaurains",
	"Pa Daet",
	"Bursledon",
	"Lurago d’Erba",
	"Pedda Nandipādu",
	"Olula del Río",
	"Eibiswald",
	"São José da Boa Vista",
	"Moudon",
	"Villaverla",
	"Atlantic",
	"Blaby",
	"Ramillies-Offus",
	"Jowzdān",
	"Parentis-en-Born",
	"Sesto al Reghena",
	"Cagayancillo",
	"Granby",
	"Friedland",
	"Horneburg",
	"Sant’Arcangelo",
	"Dinkelscherben",
	"Brolo",
	"Torrellas de Llobregat",
	"Orindiúva",
	"Boudry",
	"Morro do Chapéu",
	"Yarmouth",
	"Maturé",
	"Dan Khun Thot",
	"Altlußheim",
	"Russells Point",
	"Martins Ferry",
	"Bom Jardim de Minas",
	"Vargem Alegre",
	"Port Mathurin",
	"Calstock",
	"Dromana",
	"Douar Tassila Imassouane",
	"Milford",
	"Dobrotvir",
	"Bispingen",
	"Weaverham",
	"Kingston",
	"Bagrationovsk",
	"Schallstadt",
	"Bácsalmás",
	"Air Force Academy",
	"De Soto",
	"Blankenhain",
	"Novo Oriente",
	"Avon",
	"Luís Domingues",
	"Silacayoápam",
	"Offanengo",
	"Barzanò",
	"Casorate Sempione",
	"Castries",
	"New Inn",
	"Nilsiä",
	"Salem",
	"Lèves",
	"Chrastava",
	"Metelen",
	"Heathcote",
	"Jaunay-Clan",
	"Balsthal",
	"Rittman",
	"Bangor Base",
	"Saint-Cyr-au-Mont-d’Or",
	"Brignano Gera d’Adda",
	"Lassance",
	"Anadarko",
	"Slavičín",
	"Barnstorf",
	"Bahman",
	"Dourges",
	"Botumirim",
	"Weiskirchen",
	"Laudun-l’Ardoise",
	"Zell im Wiesental",
	"Schriever",
	"Sommariva del Bosco",
	"Harrah",
	"Monticello",
	"Obergünzburg",
	"Podbořany",
	"Williamston",
	"Homeacre-Lyndora",
	"Ōishida",
	"Brannenburg",
	"Doutor Severiano",
	"Bakum",
	"Nossa Senhora de Lourdes",
	"Pratt",
	"Basehor",
	"Dudenhofen",
	"Geneseo",
	"Benfeld",
	"Millingen aan de Rijn",
	"Severance",
	"Ocean Shores",
	"San Michele Salentino",
	"Kondor",
	"Sciez",
	"La Habra Heights",
	"Gangkofen",
	"San Ramón",
	"Caneva",
	"East Rochester",
	"Cournonterral",
	"Dragalina",
	"Rio Sono",
	"Mulvane",
	"Beerfelden",
	"Tiradentes",
	"Looe",
	"Bungay",
	"Camilla",
	"Lynchburg",
	"Hillandale",
	"Yar-Sale",
	"Žarnovica",
	"Schwarzenfeld",
	"Grayvoron",
	"Taksony",
	"Weiler-Simmerberg",
	"Medolla",
	"Iacri",
	"Stratmoor",
	"Saint-Jean-de-Boiseau",
	"Jonage",
	"Granville",
	"Mestigmer",
	"Bon-Encontre",
	"Sernaglia della Battaglia",
	"São Martinho",
	"Pimonte",
	"Brejo dos Santos",
	"Arès",
	"Abercarn",
	"Asiago",
	"Juti",
	"Drabiv",
	"Pont-Saint-Martin",
	"Buena Vista",
	"Lagoão",
	"Sardarapat",
	"Ida Ou Gaïlal",
	"Duanesburg",
	"Prairie du Chien",
	"Winterlingen",
	"Wiset Chaichan",
	"Drayton",
	"Silver Lake",
	"Shalya",
	"Maiolati Spontini",
	"Laage",
	"Miraflores de la Sierra",
	"Laranjal",
	"Sarriá de Ter",
	"Monzuno",
	"Irvington",
	"Prestonsburg",
	"Uetendorf",
	"Gómez Farías",
	"Hamilton",
	"Rosenfeld",
	"Nagyecsed",
	"Selvíria",
	"Zusmarshausen",
	"Neumarkt am Wallersee",
	"Lentilly",
	"Sidley",
	"Fockbek",
	"Marsillargues",
	"Moraine",
	"Castle Donnington",
	"Penn Yan",
	"Scenic Oaks",
	"Lanchkhuti",
	"Ras Tabouda",
	"Górliz-Elexalde",
	"Columbia",
	"Moyá",
	"Villanuova sul clisi",
	"Mendes Pimentel",
	"Seloncourt",
	"Genoa",
	"Otis Orchards-East Farms",
	"Sidney",
	"Fagagna",
	"Herrera",
	"Boischatel",
	"Loutolim",
	"Dalaba",
	"Baykalovo",
	"Bauvin",
	"South Grafton",
	"Kölleda",
	"Loches",
	"Berdigestyakh",
	"Darnestown",
	"Urnieta",
	"Collins",
	"Hebron",
	"Essingen",
	"Glendive",
	"Cuddington",
	"Épernon",
	"Yorketown",
	"Guichón",
	"Bizhbulyak",
	"Oficina María Elena",
	"Barra do Rocha",
	"Wem",
	"Santo Antônio de Lisboa",
	"Umkirch",
	"Liss",
	"Ingeniero Guillermo N. Juárez",
	"Nufringen",
	"Uzzano",
	"Lake Murray of Richland",
	"Encs",
	"Subbiano",
	"Pont-Sainte-Marie",
	"Pereiro de Aguiar",
	"Commentry",
	"Belgioioso",
	"Lübz",
	"Klingenberg am Main",
	"Marolles-en-Hurepoix",
	"Beni Sidal Louta",
	"Tiyghmi",
	"Porto Cesareo",
	"Girvan",
	"Saïdat",
	"Swanscombe",
	"Ironwood",
	"Macambira",
	"Burghaun",
	"Halych",
	"Bréal-sous-Montfort",
	"Tancherfi",
	"Collepasso",
	"Trochtelfingen",
	"Sealy",
	"Iscar",
	"Falkenberg",
	"Aparan",
	"Deta",
	"Strambino",
	"Saint-Just-en-Chaussée",
	"San Marcello Pistoiese",
	"Beelen",
	"La Grand-Croix",
	"Torreglia",
	"Dmitriyev-L’govskiy",
	"Emporia",
	"Bekkevoort",
	"Córrego Fundo",
	"Carrollton",
	"Wervicq-Sud",
	"Quintana",
	"Mundo Novo",
	"Ligonier",
	"Inverness Highlands South",
	"East Falmouth",
	"Beilstein",
	"Upper Sandusky",
	"Mer",
	"Gammertingen",
	"Burkhardtsdorf",
	"Vine Grove",
	"Portel",
	"Wrestedt",
	"Padre Carvalho",
	"Kununurra",
	"Meersburg",
	"Martfů",
	"Litchfield Park",
	"Devine",
	"Dunningen",
	"Si Prachan",
	"Malesherbes",
	"Orange Lake",
	"Mornant",
	"Rumelange",
	"Cambiano",
	"Gersheim",
	"Oelwein",
	"Santo Domingo de la Calzada",
	"Zouar",
	"Abbadia San Salvatore",
	"Santa Margherita di Belice",
	"Wheathampstead",
	"Rosal",
	"Ixtapan del Oro",
	"Carthage",
	"Crystal Lake",
	"Großbreitenbach",
	"Hurricane",
	"Cottenham",
	"Machecoul",
	"Corinth",
	"North Riverside",
	"Le Blanc",
	"San Miguel de Salinas",
	"Cheverly",
	"Savage",
	"Sils",
	"Yermolayevo",
	"Aussillon",
	"Hjo",
	"Sengiley",
	"Hüttlingen",
	"Bridgeport",
	"São Francisco do Piauí",
	"Kostelec nad Orlicí",
	"Mesoraca",
	"Valley City",
	"Cairo",
	"Francisco Alves",
	"Salem",
	"Douglas",
	"Tafraout",
	"Cacaulândia",
	"Greenville",
	"Pegau",
	"Kittilä",
	"Santes",
	"Gogolin",
	"Melut",
	"Orlovista",
	"Eudora",
	"Walkersville",
	"Bischberg",
	"Usúrbil",
	"Garrett",
	"Zimmern ob Rottweil",
	"Bowleys Quarters",
	"Naco",
	"Recoaro Terme",
	"Higashikyōshin",
	"San Canzian d’lsonzo",
	"Horgoš",
	"Prathai",
	"Littlestown",
	"Lee",
	"Rio Saliceto",
	"Parry Sound",
	"Anchieta",
	"Embrun",
	"Kappelrodeck",
	"Capim",
	"Sickte",
	"Szentlőrinc",
	"Sardoá",
	"Burnet",
	"Comandante Luis Piedra Buena",
	"Kerns",
	"Lincroft",
	"Monte San Biagio",
	"Mszczonów",
	"Lezoux",
	"Tōma",
	"Jordan",
	"Talmaza",
	"Alpirsbach",
	"Sabrosa",
	"Gójar",
	"Hampstead",
	"Ceggia",
	"Aurec-sur-Loire",
	"Tasco",
	"Carolina Beach",
	"Mount Vernon",
	"Ridgeway",
	"Lionville",
	"Tabuaço",
	"Oberboihingen",
	"Shamong",
	"Pointe-Calumet",
	"Jussari",
	"Carrazeda de Anciães",
	"La Glacerie",
	"Blaenavon",
	"Papudo",
	"Grybów",
	"Ramtown",
	"Bethoncourt",
	"Terrasson-Lavilledieu",
	"São Julião",
	"Grafenwöhr",
	"San Giovanni al Natisone",
	"Santa María Yucuhiti",
	"Killingworth",
	"Wittenburg",
	"Waldsee",
	"Čaňa",
	"Meredith",
	"Rockford",
	"Fenain",
	"Le Barcarès",
	"Douar Oulad Jaber",
	"Steilacoom",
	"Opp",
	"Abilene",
	"Warkworth",
	"Nackenheim",
	"Fossalta di Portogruaro",
	"Sandown",
	"Valdoie",
	"Wadomari",
	"Jegenstorf",
	"Rhome",
	"Aptos",
	"Lesina",
	"Povoação",
	"Zell",
	"Belpre",
	"Mudersbach",
	"Crockett",
	"Žamberk",
	"Ellenville",
	"East End",
	"Oranzherei",
	"Alexandra",
	"Yazykovo",
	"Rushville",
	"Cavaion Veronese",
	"Saint-Genis-les-Ollières",
	"International Falls",
	"Calcinate",
	"Luba",
	"Atengo",
	"Përmet",
	"Piatra Olt",
	"Saint-Arnoult-en-Yvelines",
	"Naracoorte",
	"Adrianópolis",
	"Villennes-sur-Seine",
	"Corowa",
	"Rogersville",
	"Prosser",
	"Groot-Valkenisse",
	"West Point",
	"Carroll",
	"Nephi",
	"Richboro",
	"Pulianas",
	"Cullowhee",
	"Baud",
	"Adelebsen",
	"Arenas de San Pedro",
	"Headley",
	"Warmond",
	"Arpajon-sur-Cère",
	"Beaubassin East / Beaubassin-est",
	"Woolgoolga",
	"Chervonohryhorivka",
	"Saint-Martin-le-Vinoux",
	"Kizhinga",
	"Mims",
	"Village of Oak Creek",
	"Pieksämäen Maalaiskunta",
	"Newport",
	"Jasper",
	"Bela Vista do Tôldo",
	"Gebenstorf",
	"Stavyshche",
	"Santa Maria do Erval",
	"Arandu",
	"Nitro",
	"Nicotera",
	"Saint-André-de-Sangonis",
	"Rowley",
	"Wainfleet",
	"Shimonita",
	"Usolye",
	"Okehampton",
	"San Carlos",
	"Rudiano",
	"Kronau",
	"Petropavlivs’ka Borshchahivka",
	"Wharton",
	"Serravalle Scrivia",
	"Traismauer",
	"Rupperswil",
	"Johnsburg",
	"Fort Riley",
	"Marlborough",
	"Vinkivtsi",
	"Pirajuba",
	"Sunbury",
	"Laa an der Thaya",
	"Turbiv",
	"Bujor",
	"Hörsching",
	"Wollochet",
	"Stockstadt am Rhein",
	"Chirsova",
	"Grandchamps-des-Fontaines",
	"Feytiat",
	"Goondiwindi",
	"Villa Cura Brochero",
	"Pulnoy",
	"Cogorno",
	"Keyser",
	"Dohna",
	"Holmes Chapel",
	"Ichu",
	"Chizu",
	"Itirapuã",
	"Beith",
	"Velyki Mosty",
	"Tanque d’Arca",
	"Dussen",
	"Charbonnières-les-Bains",
	"Trevelín",
	"Dadaab",
	"Vanzaghello",
	"Kathleen",
	"Aït Bou Madhi",
	"Combee Settlement",
	"Henderson",
	"Mahdalynivka",
	"Cynthiana",
	"Tabor City",
	"North Fort Lewis",
	"Cramahe",
	"Fenton",
	"Beauceville",
	"Pinhalão",
	"Ihringen",
	"Buq‘ātā",
	"West Hattiesburg",
	"North Middlesex",
	"Karmir Gyukh",
	"Armamar",
	"Hronov",
	"Allegan",
	"Dacula",
	"Saint-Quentin-Fallavier",
	"Matarnia",
	"Canéjan",
	"Schuyler",
	"Tipton",
	"Clarkston Heights-Vineland",
	"Tortorici",
	"Vidal Ramos",
	"Galax",
	"Messíni",
	"Ravarino",
	"San Polo d’Enza in Caviano",
	"Palosco",
	"Mattinata",
	"Baixio",
	"Jan Phyl Village",
	"Swarthmore",
	"Krk",
	"Izumizaki",
	"Neumarkt-Sankt Veit",
	"Chipping Norton",
	"Carmel Hamlet",
	"Kalefeld",
	"Bondorf",
	"Dobřany",
	"Immendingen",
	"Toledo",
	"Lazcano",
	"Hachenburg",
	"Walluf",
	"Maarn",
	"Staraya Mayna",
	"Arrinj",
	"Gladewater",
	"Zalaszentgrót",
	"Litzendorf",
	"Iowa Park",
	"Migné",
	"Yaphank",
	"St. John",
	"Inhaúma",
	"Peñón Blanco",
	"Amaporã",
	"Mattapoisett",
	"Crocetta del Montello",
	"Svislach",
	"Theodore",
	"Souq at Tlata des Loulad",
	"Fegyvernek",
	"Karasburg",
	"Paraíso",
	"Mercedes",
	"Pepper Pike",
	"Red Lion",
	"Vrbové",
	"Verkhnevilyuysk",
	"Echaporã",
	"Deißlingen",
	"Hamlet",
	"Olival",
	"Fazenda Nova",
	"Pentling",
	"Spirano",
	"Carry-le-Rouet",
	"El Maïn",
	"Mýrina",
	"Granby",
	"White Oak",
	"Amqui",
	"Axams",
	"Norderney",
	"Dötlingen",
	"Wolfeboro",
	"Chambourcy",
	"Kiş",
	"Sainte-Catherine-de-la-Jacques-Cartier",
	"Trasacco",
	"Vila Boa",
	"Georges",
	"Comstock Northwest",
	"Allariz",
	"Florián",
	"Enrique Villanueva",
	"Mesøy",
	"Islamorada, Village of Islands",
	"Horezu",
	"Astrakhan",
	"Markt Sankt Florian",
	"Teplyk",
	"San Benigno Canavese",
	"Anhembi",
	"Mascotte",
	"Mníšek pod Brdy",
	"Bátaszék",
	"Roveredo in Piano",
	"Marly-la-Ville",
	"Cobleskill",
	"Newarthill",
	"Loon-Plage",
	"Almenara",
	"Wagna",
	"Jedlicze",
	"Farébersviller",
	"Saint-Denis-lès-Bourg",
	"Bonchamp-lès-Laval",
	"Sertão",
	"Zubtsov",
	"Jouars-Pontchartrain",
	"Pass Christian",
	"Lochau",
	"Balkány",
	"Changé",
	"Neustadt",
	"Cobram",
	"Hutthurm",
	"Jeanerette",
	"Delano",
	"Waveland",
	"Itarumã",
	"Besnate",
	"Remanzacco",
	"North Hobbs",
	"Luisburgo",
	"Fronton",
	"Mallemort",
	"Nattheim",
	"Forest Hills",
	"Stonewood",
	"Lubizhdë",
	"Marsh Harbour",
	"El Rio",
	"Tarleton",
	"Nenzing",
	"Altkirch",
	"Delle",
	"Cumberland",
	"Lely Resort",
	"Carloforte",
	"Marton",
	"Poggiardo",
	"Iḩsim",
	"East Porterville",
	"Clarenville",
	"Kuchen",
	"Bonstetten",
	"Munhoz",
	"Ranchettes",
	"Mazan",
	"Montebello Ionico",
	"Nouzonville",
	"Sotnikovo",
	"Boiling Spring Lakes",
	"Wannweil",
	"Geisingen",
	"Lioni",
	"Nogent-sur-Seine",
	"Port Saint John’s",
	"Rio Grande do Piauí",
	"Mikhaylovka",
	"Phanna Nikhom",
	"Obererli",
	"São Francisco de Goiás",
	"Oberlungwitz",
	"Godega di Sant’Urbano",
	"Nor Geghi",
	"Guidizzolo",
	"Stone Mountain",
	"Ashburnham",
	"Mont-Joli",
	"Campo Limpo",
	"Gaiarine",
	"Octeville-sur-Mer",
	"Casorezzo",
	"Tammela",
	"Hohenlockstedt",
	"São Francisco de Sales",
	"Sursk",
	"Glücksburg",
	"Železný Brod",
	"Kupferzell",
	"Covedale",
	"California",
	"Ardud",
	"Unterpremstätten",
	"Oberthal",
	"St. Robert",
	"New Castle",
	"Gildersome",
	"Rötha",
	"Oakland",
	"Rechberghausen",
	"Mortagne-sur-Sèvre",
	"Dąbrowa Białostocka",
	"Plonéour-Lanvern",
	"Figueira de Castelo Rodrigo",
	"Perniö",
	"Saleilles",
	"Ghidighici",
	"Lebanon",
	"Wainwright",
	"Noyal-sur-Vilaine",
	"Ivankovo",
	"Birstein",
	"Elven",
	"Burntisland",
	"Howland Center",
	"Tortora",
	"Silvianópolis",
	"Mamers",
	"Donji Miholjac",
	"São Gonçalo do Abaeté",
	"São Jose de Gaiana",
	"Pigeon Forge",
	"Gmund am Tegernsee",
	"Ārādān",
	"Huntington Woods",
	"Lower Nazareth",
	"Rong Kham",
	"North East",
	"Progresso",
	"Tazouta",
	"Taiaçu",
	"Hooge Zwaluwe",
	"Blahovishchenske",
	"Viator",
	"Maffra",
	"Richmond",
	"Antis",
	"Ingeniero Jacobacci",
	"Pontal do Araguaia",
	"Fairwood",
	"Kingston",
	"Tahmoor",
	"East Glenville",
	"Guer",
	"Santa Cruz do Piauí",
	"Lake Alfred",
	"Nong Song Hong",
	"Plescop",
	"Zeitlarn",
	"Santa Filomena",
	"Izon",
	"Snyderville",
	"Morris Plains",
	"Lambert’s Bay",
	"Pinxton",
	"Powell",
	"Glyncorrwg",
	"Contrecoeur",
	"Piana degli Albanesi",
	"Héric",
	"Hampshire",
	"Enola",
	"Ingeniero Luis A. Huergo",
	"Sainghin-en-Weppes",
	"Sarrians",
	"Berghem",
	"Salton City",
	"Blue Bell",
	"Corcoran",
	"Blanzy",
	"Collecorvino",
	"Ocean City",
	"Dives-sur-Mer",
	"General Sampaio",
	"Walker",
	"St. James",
	"Spangenberg",
	"Bir Anzarane",
	"Kirchbichl",
	"Ebersdorf bei Coburg",
	"Woodend",
	"Puebla de la Calzada",
	"Hvardiys’ke",
	"Geghahovit",
	"Inaciolândia",
	"Camp Swift",
	"South Run",
	"Pecan Plantation",
	"Nieul-sur-Mer",
	"Vernaison",
	"Tápiószecső",
	"Cadeo",
	"Elva",
	"New Whiteland",
	"La Roquette-sur-Siagne",
	"Sylvester",
	"Balabyne",
	"Boonville",
	"Inverloch",
	"Pouso Alto",
	"Boothwyn",
	"Moergestel",
	"Orange Beach",
	"Silandro",
	"La Ville-aux-Dames",
	"Serraria",
	"Buena Vista",
	"Solvay",
	"Whittlesea",
	"Champion Heights",
	"East Foothills",
	"South Gate Ridge",
	"Bodelshausen",
	"Newberry",
	"Breña Baja",
	"Amrharas",
	"Niedergörsdorf",
	"Noormarkku",
	"São João do Sabugi",
	"Cammarata",
	"Marnaz",
	"Mayfield",
	"Carentan",
	"Lardy",
	"Esvres",
	"Glenwood",
	"Vesyegonsk",
	"Homer",
	"Aire-sur-l’Adour",
	"Rolde",
	"Zhur",
	"West Greenwich",
	"Trans-en-Provence",
	"Kenedy",
	"Pimmit Hills",
	"Casalbordino",
	"Edinboro",
	"Murashi",
	"Domusnovas",
	"Fruit Heights",
	"Addis",
	"Montgomery",
	"Soledade de Minas",
	"Nikolo-Berëzovka",
	"Colômbia",
	"Swanwick",
	"Mórahalom",
	"Gloggnitz",
	"Biasca",
	"Demidov",
	"São João da Ponta",
	"Rohrbach",
	"Wailea",
	"Nicolás Flores",
	"Maule",
	"Khūr",
	"Hustopeče",
	"Sittensen",
	"Motta San Giovanni",
	"Outes",
	"Poppi",
	"Barão",
	"Tarazona de la Mancha",
	"Nabburg",
	"Tsarevo",
	"Saint-Zacharie",
	"Curcani",
	"Bad Hönningen",
	"Săvineşti",
	"Hethersett",
	"Liverdun",
	"Saint-Symphorien-d’Ozon",
	"Verona",
	"Chelan",
	"Saint-Jory",
	"Pea Ridge",
	"Americano Brasil",
	"La Primaube",
	"Blakely",
	"Saint-Sébastien-de-Morsent",
	"Prades-le-Lez",
	"Grayson Valley",
	"Vigneux-de-Bretagne",
	"Vouneuil-sous-Biard",
	"Corry",
	"Rio das Antas",
	"Siuntio",
	"Villabona",
	"Fontenilles",
	"Partenit",
	"Gelnica",
	"Takhtamukay",
	"Twin Lakes",
	"Dennis",
	"Wilkins",
	"East Calder",
	"Gunskirchen",
	"Schäftlarn",
	"Avra Valley",
	"Büchen",
	"Sermide",
	"Everman",
	"Parecis",
	"Luco dei Marsi",
	"Suria",
	"Rowlands Gill",
	"Union Gap",
	"Seichamps",
	"Bellach",
	"Ternay",
	"Stowe",
	"Kirchardt",
	"Faßberg",
	"Sachkhere",
	"Sant’Elia Fiumerapido",
	"Revin",
	"Saint-Joseph-du-Lac",
	"Plainfield",
	"Belfeld",
	"Porcuna",
	"Heliconia",
	"Campo San Martino",
	"Satsuma",
	"Prinzapolka",
	"Tuakau",
	"Kirton",
	"Temnikov",
	"Thoiry",
	"Lütjenburg",
	"Vilseck",
	"Riviersonderend",
	"Dyce",
	"Ascoli Satriano",
	"Slănic",
	"Caracol",
	"Lendelede",
	"Berceni",
	"Vendenheim",
	"Sheridan",
	"Le Lavandou",
	"Truccazzano",
	"Leonardtown",
	"Poussan",
	"Westhausen",
	"Steyning",
	"Parmain",
	"Hope",
	"Gimli",
	"Eastman",
	"Kusatsu",
	"Yeranos",
	"Dusheti",
	"Asakawa",
	"San Cristóbal Acasaguastlán",
	"Darcinópolis",
	"High Springs",
	"Doullens",
	"Königsfeld im Schwarzwald",
	"Orsett",
	"University at Buffalo",
	"Olhos d’Água",
	"Vaison-la-Romaine",
	"Au in der Hallertau",
	"Silveiras",
	"Pembury",
	"Ibiracatu",
	"Jandaia",
	"Loimaan Kunta",
	"Solt",
	"Untergriesbach",
	"Huntingburg",
	"Almadina",
	"Dainville",
	"Cocal dos Alves",
	"Nieheim",
	"Pontotoc",
	"Pierrefeu-du-Var",
	"Lingenfeld",
	"Emerald",
	"Treffurt",
	"Monterenzio",
	"Beacon Falls",
	"East Farmingdale",
	"Richmond",
	"Dartmouth",
	"Clyde",
	"Týniště nad Orlicí",
	"Itumirim",
	"Mäntyharju",
	"Yoshinoyama",
	"Eagar",
	"Ida Ou Moumene",
	"Senador Elói de Souza",
	"São João da Serra",
	"Gyangzê",
	"Branford Center",
	"Rib Mountain",
	"Adligenswil",
	"Kuybyshevo",
	"Henryetta",
	"Unterseen",
	"Magny-en-Vexin",
	"Montgomery",
	"Fort Edward",
	"Barra Longa",
	"Polýgyros",
	"Szabadszállás",
	"Rusko",
	"San Gerardo",
	"Odesskoye",
	"Erdweg",
	"Jemna",
	"Hemhofen",
	"Simsbury Center",
	"Chanceford",
	"Wassertrüdingen",
	"Kojetín",
	"Sainte-Sigolène",
	"Monteiasi",
	"Kulary",
	"Clinton",
	"Evansville",
	"Bonares",
	"San Pol de Mar",
	"Pardinho",
	"Saxon",
	"Lanett",
	"Quinto Vicentino",
	"Veintiocho de Noviembre",
	"Pignataro Maggiore",
	"Nürensdorf",
	"Ares",
	"Újszász",
	"Ballingry",
	"Raleigh Hills",
	"Naintré",
	"Bridgewater",
	"Kleinwallstadt",
	"Lausen",
	"Southampton",
	"Northfield",
	"Graça Aranha",
	"Vernio",
	"Santorso",
	"Glenarden",
	"East Pasadena",
	"Kechoulah",
	"Sint Pancras",
	"Trunovskoye",
	"Maiano",
	"Saint-Jorioz",
	"Hofbieber",
	"Ronco all’Adige",
	"Potiretama",
	"New Norfolk",
	"Gurinhatã",
	"Pelican Bay",
	"Dornhan",
	"Tequesta",
	"Montbonnot-Saint-Martin",
	"Clapiers",
	"Alpnach",
	"Broughton",
	"Elm Grove",
	"Borso del Grappa",
	"Genazzano",
	"Varniţa",
	"Sidi Mbark",
	"Welshpool",
	"Lom Kao",
	"Carlstadt",
	"Vaugneray",
	"Sümeg",
	"Torrebelvicino",
	"North Windham",
	"Cochran",
	"Sedgefield",
	"Riantec",
	"Eucalyptus Hills",
	"Arlington Heights",
	"Brownville",
	"Sosnovo-Ozerskoye",
	"Helmshore",
	"Castelsardo",
	"Emskirchen",
	"Novokhopërsk",
	"Jambeiro",
	"Shikhany",
	"Bø",
	"Independence",
	"Lebanon",
	"Laurel Bay",
	"Braunlage",
	"Garrettsville",
	"Abanilla",
	"Pöllau",
	"Douvrin",
	"Fundeni",
	"Frick",
	"Kirchentellinsfurt",
	"Gardnerville",
	"Gnas",
	"Candiolo",
	"Fleurance",
	"Löchgau",
	"Isanti",
	"Taciba",
	"Vizzini",
	"Mainhardt",
	"Otaci",
	"Monroe",
	"Noreña",
	"Lannemezan",
	"Punxsutawney",
	"Country Homes",
	"Wailua Homesteads",
	"Hergiswil",
	"Kehychivka",
	"Haute-Goulaine",
	"Chiquilistlán",
	"Brigg",
	"Sidney",
	"Mendon",
	"Princeton",
	"Fahrland",
	"Aznalcóllar",
	"Moulay Bouzarqtoune",
	"Spydeberg",
	"Bartonville",
	"Ixcateopan de Cuauhtémoc",
	"Chena Ridge",
	"Pauls Valley",
	"Saint-Apollinaire",
	"Wanon Niwat",
	"Tazemmourt",
	"Munford",
	"Tarrant",
	"Los Chaves",
	"Woodville",
	"Fornovo di Taro",
	"Wildon",
	"Wilhering",
	"Santo Antônio do Jardim",
	"Los Ranchos de Albuquerque",
	"Megalópoli",
	"Prrenjas",
	"San Antonio Cañada",
	"Edgewood",
	"Windsbach",
	"Aquia Harbour",
	"Nereto",
	"Indian Hills",
	"Bresje",
	"Coursan",
	"Klæbu",
	"El Granada",
	"Pai Pedro",
	"Villefranche-sur-Mer",
	"Concepción de Buenos Aires",
	"San Giovanni Suèrgiu",
	"Serradifalco",
	"Alkoven",
	"Acque Dolci",
	"Thelepte",
	"Chalfont Saint Giles",
	"Tillicoultry",
	"Anta Gorda",
	"Alcudia de Crespins",
	"Gauchy",
	"Niedereschach",
	"Pueai Noi",
	"Rio Espera",
	"Lucciana",
	"Rosate",
	"Stewartville",
	"Filiatrá",
	"Pagham",
	"Navás",
	"Jánossomorja",
	"Oulad Amghar",
	"Bensley",
	"Hordle",
	"Mesquita",
	"Shrewsbury",
	"Adlington",
	"Madalena",
	"Riedenburg",
	"Villemur-sur-Tarn",
	"Alpine",
	"Lindau",
	"Mériel",
	"Harlan",
	"Ginoza",
	"Los Villares",
	"Hindon Hill",
	"East Ballina",
	"Dinguiraye",
	"Krumë",
	"Willard",
	"Puttershoek",
	"Roquefort-la-Bédoule",
	"Biloela",
	"Hastière-par-delà",
	"Ontario",
	"Artés",
	"Meyreuil",
	"Aubignan",
	"Pedra Bela",
	"Wheelersburg",
	"Cheshire Village",
	"Dol-de-Bretagne",
	"Albaida",
	"Marcellus",
	"Hurley",
	"Les Cèdres",
	"Steinfort",
	"Meijel",
	"Worragee",
	"Montauban-de-Bretagne",
	"Vilaboa",
	"Sannicola",
	"Noves",
	"Roye",
	"La Broquerie",
	"Saint-Mitre-les-Remparts",
	"Ähtäri",
	"Aixe-sur-Vienne",
	"Colney Heath",
	"Labuništa",
	"Alezio",
	"Ostricourt",
	"Verkhniy Avzyan",
	"Williams",
	"Neftenbach",
	"Clifton Springs",
	"Santa Catarina",
	"Butjadingen",
	"Ceriale",
	"Nova Olinda",
	"Bad Königshofen im Grabfeld",
	"Breitenfurth bei Wien",
	"Sebiş",
	"Merville",
	"Mrgashat",
	"Guadasuar",
	"Sandnessjøen",
	"Gozzano",
	"Verkh-Tula",
	"Chorvátský Grob",
	"South Williamsport",
	"Westmoreland",
	"La Wantzenau",
	"Chevreuse",
	"Allershausen",
	"Kent",
	"Baltmannsweiler",
	"Vilafant",
	"Itueta",
	"Carcare",
	"Münchwilen",
	"Uchaly",
	"Altafulla",
	"Geislingen",
	"Ostellato",
	"Pacujá",
	"Saint-Georges-d’Orques",
	"Bagnolo Piemonte",
	"Campo Alegre de Goiás",
	"Ulëty",
	"San Lucido",
	"Çıralı",
	"Waihi",
	"Albán",
	"Colosó",
	"Monchique",
	"Donzère",
	"Maurik",
	"Bechhofen",
	"Bagnolo San Vito",
	"Saint-Hilaire-du-Harcouët",
	"São Vicente",
	"Moosinning",
	"Zumikon",
	"Thalgau",
	"Rindge",
	"Sersheim",
	"Divisa Nova",
	"Rhuthun",
	"Fort Ann",
	"Arnemuiden",
	"Lindfield",
	"Askern",
	"Ouro Verde de Minas",
	"Shihoro",
	"Colônia do Gurguéia",
	"Nelsonville",
	"Writtle",
	"Kastellaun",
	"Obfelden",
	"Magdalena Tequisistlán",
	"Childress",
	"Brezoi",
	"Lonate Ceppino",
	"Staplehurst",
	"Bni Abdellah",
	"Kotel",
	"Kabansk",
	"Oraison",
	"Deschutes River Woods",
	"Dover",
	"Tweed",
	"Laufen",
	"Brookhaven",
	"Carrizo Springs",
	"Tótkomlós",
	"Maintirano",
	"Sanitz",
	"Beluša",
	"Combourg",
	"Wasselonne",
	"Lossa",
	"Riverside",
	"Mel",
	"Itaguatins",
	"Pau D’Arco",
	"Mishkino",
	"Barbourville",
	"Buttenwiesen",
	"Dubăsarii Vechi",
	"Calvisson",
	"Dacono",
	"Union",
	"Tapa",
	"Chapéu",
	"Bagni di Lucca",
	"Cartes",
	"Saint-Galmier",
	"Wilna",
	"Tea",
	"Hambrücken",
	"Milhaud",
	"Rohrdorf",
	"Rensselaer",
	"Saint-Félix-de-Valois",
	"Xambrê",
	"Krasavino",
	"Khlevnoye",
	"Corsano",
	"Penela",
	"Bottanuco",
	"Beaucourt",
	"Derýneia",
	"Gualdo Cattaneo",
	"Great Warley Street",
	"Schlüsselfeld",
	"La Ferté-Macé",
	"Volodarka",
	"Billigheim",
	"Chepes",
	"Timelkam",
	"Nové Strašecí",
	"Ban Chet Samian",
	"Sales",
	"Pinehurst",
	"Finley",
	"Betânia do Piauí",
	"Mügeln",
	"Darjazīn",
	"Stoke Mandeville",
	"Felipe Guerra",
	"Chatham",
	"Parabel’",
	"Seaton Carew",
	"Pluak Daeng",
	"Pluneret",
	"Nerokoúros",
	"Cumberland",
	"Montmorillon",
	"Douar Tazrout",
	"Ra’s al Khashūfah",
	"Querqueville",
	"Azovo",
	"Aiuruoca",
	"Chillicothe",
	"Bay Roberts",
	"La Gorgue",
	"Crofton",
	"Ascea",
	"Hainesport",
	"Fort Shawnee",
	"Metropolis",
	"Oshakan",
	"Maquoketa",
	"Watchung",
	"Davenport",
	"Zhongcha",
	"Zeeland",
	"Los Reyes",
	"Godstone",
	"Portland",
	"Williamsburg",
	"St. Thomas",
	"Herrnhut",
	"Matsuzaki",
	"Soligalich",
	"Du Quoin",
	"Rajec",
	"Anglés",
	"Tessalit",
	"Baldwin",
	"Villanova Mondovì",
	"Burghfield",
	"Bons-en-Chablais",
	"Ibiara",
	"Karlshuld",
	"Blean",
	"Amalfi",
	"Elburn",
	"Ashton-Sandy Spring",
	"Belyayevka",
	"José da Penha",
	"Tarp",
	"Hittfeld",
	"Hillsborough",
	"Yukhnov",
	"Kingston",
	"Kvinesdal",
	"Santa Albertina",
	"Porvenir",
	"Coristanco",
	"Castelnuovo di Garfagnana",
	"Windsor",
	"Melfort",
	"San Dorligo della Valle",
	"Villa Bartolomea",
	"Coulogne",
	"Pinkafeld",
	"Úpice",
	"Cristália",
	"Bathmen",
	"Eastampton",
	"Wingham",
	"Bramley",
	"Třebechovice pod Orebem",
	"Stadtoldendorf",
	"Almodóvar del Campo",
	"Homeland Park",
	"Karaidel",
	"Lewistown",
	"Durham",
	"Winfield",
	"Alnashi",
	"Bredstedt",
	"Wemding",
	"Tegernheim",
	"Cut Off",
	"Tápiószele",
	"Murça",
	"Membrilla",
	"Louisville",
	"Winthrop",
	"Gannat",
	"Bussoleno",
	"Northern Cambria",
	"Camalaú",
	"Costigliole d’Asti",
	"Balatonboglár",
	"Romentino",
	"Rousínov",
	"Bédarieux",
	"Pevely",
	"São Pedro de Alcântara",
	"South Monroe",
	"Bourne End",
	"Douar Oulad Bou Krae El Fouqani",
	"Hungerford",
	"Bonnyville",
	"Sutamarchán",
	"Stornara",
	"Fayence",
	"Hellam",
	"Westerburg",
	"Velešta",
	"Kisanpur",
	"Ettingen",
	"Robbio",
	"Corigliano d’Otranto",
	"Lagodekhi",
	"Gieten",
	"Micheldorf in Oberösterreich",
	"Kiviõli",
	"Chinnor",
	"Kazimierza Wielka",
	"Berhida",
	"Jablunkov",
	"North Hills",
	"Andebu",
	"Carbonne",
	"Mûrs-Erigné",
	"Novobelokatay",
	"Bad Saarow-Pieskow",
	"Valga",
	"Lakemoor",
	"Karstädt",
	"Guardo",
	"Américo de Campos",
	"Quincy-Voisins",
	"Isen",
	"Fatezh",
	"Lower Heidelberg",
	"Trooper",
	"Vail",
	"Echzell",
	"Zaraysk",
	"Lengnau",
	"Inniswold",
	"Peypin",
	"Axminster",
	"Girifalco",
	"Steelton",
	"Lesparre-Médoc",
	"Le Poinçonnet",
	"São João de Manteninha",
	"Haiterbach",
	"Maria Helena",
	"Santa Bárbara do Rio Pardo",
	"Pusztaszabolcs",
	"Northville",
	"Johnson Lane",
	"Lucerne Valley",
	"Kao Liao",
	"St. Clair",
	"Ao Luek",
	"East Alton",
	"La Crèche",
	"Rocky Mount",
	"Dedoplists’q’aro",
	"San Miguel Panixtlahuaca",
	"Desselgem",
	"Benigánim",
	"Stettler",
	"Diónysos",
	"Münzenberg",
	"Rochlitz",
	"Kungsör",
	"Vidigueira",
	"Sireţi",
	"Andover",
	"Eberndorf",
	"Modřice",
	"Kōzaki",
	"Barnhart",
	"Fruta de Leite",
	"Villeneuve",
	"Eurajoki",
	"Mila Doce",
	"Hopedale",
	"Tashtyp",
	"Fernandes Pinheiro",
	"Waldbrunn",
	"New London",
	"Gowanda",
	"Shields",
	"Sharan",
	"Shatsk",
	"Progreso",
	"Gray",
	"Picerno",
	"Frasin",
	"Westville",
	"Studley",
	"Sterlibashevo",
	"Callington",
	"Davyd-Haradok",
	"Itaú",
	"Charlestown",
	"Déchy",
	"Yoakum",
	"Darlington",
	"Kaba",
	"Canale",
	"Rodeio Bonito",
	"Tinzart",
	"Ubarana",
	"Waakirchen",
	"Ornago",
	"Montalcino",
	"Wolfach",
	"East Preston",
	"Raton",
	"Mojen",
	"Al Quţaylibīyah",
	"Shefar‘am",
	"Certosa di Pavia",
	"Goodrich",
	"East Hanover",
	"Psachná",
	"Fontenay-Trésigny",
	"Ararica",
	"Freshwater",
	"Morshyn",
	"Leongatha",
	"Saint-Calixte",
	"Longare",
	"Campina do Monte Alegre",
	"Countryside",
	"Furiani",
	"Xylókastro",
	"Sullivan City",
	"Lac-Mégantic",
	"Tucunduva",
	"Tremp",
	"Perth",
	"Étalle",
	"Mucuchíes",
	"Loro Ciuffenna",
	"Pyrbaum",
	"Keswick",
	"Paradise",
	"Leun",
	"Hausach",
	"Boé",
	"Havelte",
	"Leiston",
	"Blaichach",
	"Pagosa Springs",
	"Santa Maria da Serra",
	"Mullica",
	"Woodcreek",
	"Surnadal",
	"Santa Helena",
	"Dettenhausen",
	"Logan",
	"Paula Freitas",
	"Dumfries",
	"Homer",
	"Oliver Paipoonge",
	"Bräunlingen",
	"Týnec nad Sázavou",
	"Gransee",
	"Wesley Hills",
	"Mineola",
	"Finderne",
	"San Donato di Lecce",
	"Coqueiro Sêco",
	"Marennes",
	"Zebulon",
	"Banatski Karlovac",
	"Pöcking",
	"Cresaptown",
	"Granville",
	"Akbarābād",
	"Kaltennordheim",
	"Littleton",
	"Barra de São Miguel",
	"São João do Caiuá",
	"Ospedaletto Euganeo",
	"Palmwoods",
	"Staunton",
	"Taylor",
	"Roanoke",
	"Groton",
	"Vias",
	"Greenacres",
	"Bazarnyye Mataki",
	"Chambarak",
	"Dunsborough",
	"Rühen",
	"Schwarmstedt",
	"Le Valdahon",
	"Wilnis",
	"Kipfenberg",
	"El Arba Bouzemmour",
	"Alzamay",
	"Ubinskoye",
	"Luzerne",
	"Koreiz",
	"Ceva",
	"Bracigliano",
	"Fontanelle",
	"Trèbes",
	"Saint-Memmie",
	"Horst",
	"Rio Manso",
	"Waibstadt",
	"Maiori",
	"Buynī Qarah",
	"Uelsen",
	"Turuntayevo",
	"Eagleton Village",
	"Aljezur",
	"Selinsgrove",
	"Tournus",
	"Murovani Kurylivtsi",
	"Bandeira do Sul",
	"Hampstead",
	"Gătaia",
	"Schönenberg-Kübelberg",
	"Borabue",
	"Naşrābād",
	"Bagno di Romagna",
	"Ozerne",
	"Courthézon",
	"Aimargues",
	"Radenthein",
	"Višegrad",
	"Rogliano",
	"Hermon",
	"Sweetwater",
	"Huai Mek",
	"Sambuca di Sicilia",
	"Old Tappan",
	"Hebertshausen",
	"Willington",
	"Ober-Mörlen",
	"Aït Ouakrim",
	"Viggiù",
	"Mengerskirchen",
	"Drighlington",
	"Fismes",
	"Naturno",
	"Lannilis",
	"Willard",
	"Gaurama",
	"Los Yébenes",
	"Ybbs an der Donau",
	"Simiane-Collongue",
	"Madisonville",
	"Loudon",
	"Slaton",
	"Buti",
	"Frei Gaspar",
	"Mouroux",
	"Flines-lès-Raches",
	"Chamberlayne",
	"Irymple",
	"Uttenreuth",
	"Aragominas",
	"Santa Luz",
	"Weischlitz",
	"Montezuma",
	"Dulliken",
	"Vårgårda",
	"Biesenthal",
	"Solliès-Toucas",
	"Port-la-Nouvelle",
	"Massanetta Springs",
	"Lovere",
	"Briey",
	"Bryukhovychi",
	"Gustine",
	"Wellesbourne Hastings",
	"Erquinghem-Lys",
	"Alhama de Granada",
	"Gattatico",
	"Korocha",
	"Moeiwadi",
	"Poltár",
	"Mezőkovácsháza",
	"San Juan Ñumí",
	"Nolanville",
	"Tittmoning",
	"Mindyak",
	"Dierdorf",
	"Speichersdorf",
	"Boulay-Moselle",
	"Pliening",
	"Rottach-Egern",
	"Poloni",
	"Constantina",
	"Argamasilla de Calatrava",
	"Windcrest",
	"Spring Ridge",
	"Phachi",
	"Paternion",
	"Otranto",
	"Brookville",
	"Abtenau",
	"Marlow Heights",
	"Ighil",
	"Wormhout",
	"Hagenbach",
	"Al Mulayḩah al Gharbīyah",
	"Campagnola Emilia",
	"Busalla",
	"Turpin Hills",
	"Wirges",
	"Castelverde",
	"Luling",
	"Humboldt",
	"Camporosso",
	"Mereni",
	"Ernée",
	"Mikomeseng",
	"Le Puy-Sainte-Réparade",
	"Tahitófalu",
	"Azatan",
	"Amersid",
	"Vilppula",
	"Martlesham",
	"Glendale",
	"Hutchins",
	"Chester",
	"Fürstenberg",
	"Cortina d’Ampezzo",
	"Vilanova de la Roca",
	"Belton",
	"Margate City",
	"Posen",
	"Delvinë",
	"Dowagiac",
	"Alma",
	"Dompierre-sur-Mer",
	"La Chevrolière",
	"‘Assāl al Ward",
	"Nieuwe Pekela",
	"Chlumec nad Cidlinou",
	"Las Flores",
	"Birnbach",
	"Vereide",
	"Grebenstein",
	"Franklin Center",
	"Angera",
	"Sobrália",
	"Saint-Denis-de-Pile",
	"Rosendale",
	"Algaida",
	"Viarmes",
	"Helsa",
	"Ḑawrān ad Daydah",
	"Weidenberg",
	"Cilavegna",
	"La Chapelle-Basse-Mer",
	"Paulsboro",
	"Alandroal",
	"Bohain-en-Vermandois",
	"Martonvásár",
	"New Martinsville",
	"Breese",
	"Verkhovyna",
	"Red Cliffs",
	"Mount Arlington",
	"Neubulach",
	"Botley",
	"Old Fig Garden",
	"Pontiac",
	"Silver Lakes",
	"Arnage",
	"Gracho Cardoso",
	"Tacherting",
	"Ross",
	"Šluknov",
	"Calvi",
	"Cumnor",
	"Harris Hill",
	"Carterville",
	"Orchard Homes",
	"Celrá",
	"Buochs",
	"Haimhausen",
	"Kartal",
	"Saint-Georges-de-Didonne",
	"Esquivias",
	"Yakoruda",
	"La Selva",
	"Littlefield",
	"Paterna de Rivera",
	"Sidi Harazem",
	"Willow Park",
	"Dello",
	"Hartwell",
	"Villa del Conte",
	"Saint-Pierre-d’Irube",
	"Shintoku",
	"Hambergen",
	"Woodcliff Lake",
	"Rijsbergen",
	"Cave Creek",
	"Seon",
	"Chelsea",
	"Wusterhausen",
	"San Secondo Parmense",
	"Roseland",
	"Babenhausen",
	"Glina",
	"Wallers",
	"Aroazes",
	"Ōhira",
	"Langquaid",
	"Brescello",
	"Gossolengo",
	"Sauvian",
	"Grotte",
	"Saint-Cannat",
	"Greenwood",
	"Yeles",
	"Jászfényszaru",
	"Fílyro",
	"Moosic",
	"Tancoco",
	"Guînes",
	"Potcoava",
	"Melicucco",
	"Les Avenières",
	"Alfacar",
	"Fontevivo",
	"Hellertown",
	"South Hooksett",
	"Cancello",
	"Aragatsavan",
	"Guagnano",
	"Berne",
	"West Clarkston-Highland",
	"Houlton",
	"Bni Hadifa",
	"Chagny",
	"Órjiva",
	"St. Paul",
	"Chernihivka",
	"Arkhangelskoye",
	"Palmanova",
	"Fenouillet",
	"Dasing",
	"Tuglie",
	"Eschau",
	"Poshekhonye",
	"Baar-Ebenhausen",
	"Thalheim bei Wels",
	"Sibbesse",
	"Tatsugō",
	"Eldorado at Santa Fe",
	"Firmino Alves",
	"Vayk",
	"Villanova d’Asti",
	"Schwarzheide",
	"Ferrière-la-Grande",
	"Kremastí",
	"Kameno",
	"Ouderkerk aan den IJssel",
	"Senhora de Oliveira",
	"Mattydale",
	"Wilson",
	"Sertaneja",
	"Itaverava",
	"Füzesgyarmat",
	"Szécsény",
	"Harvest",
	"Audruicq",
	"Balerno",
	"Aylsham",
	"Lebanon",
	"Non Sila",
	"Chatuzange-le-Goubet",
	"Phayuha Khiri",
	"Brookshire",
	"Bright",
	"Gray",
	"Chauchina",
	"Sausheim",
	"Arce",
	"Montrond-les-Bains",
	"Volo",
	"Ramblewood",
	"Tewksbury",
	"Plouay",
	"Arroyo de la Luz",
	"Serra de São Bento",
	"Kippenheim",
	"Riolo Terme",
	"Wittichenau",
	"Leopoldsdorf",
	"Lake Mohegan",
	"Orleans",
	"Jilemnice",
	"Rocca di Neto",
	"Fischamend",
	"Cambria",
	"Beardstown",
	"Manasquan",
	"Sala Baganza",
	"Nagyhalász",
	"Woodstock",
	"Liberato Salzano",
	"Veselynove",
	"San Paolo di Civitate",
	"Walenstadt",
	"Aschau im Chiemgau",
	"Marianna",
	"Třešť",
	"Moravský Krumlov",
	"Schliengen",
	"Markfield",
	"Pont-Évêque",
	"Britânia",
	"Séméac",
	"West Bountiful",
	"Fellsmere",
	"Mondorf-les-Bains",
	"Lyndon",
	"Wernberg",
	"Oščadnica",
	"Gorebridge",
	"Castuera",
	"Svätý Jur",
	"Hrebinky",
	"Selkirk",
	"Tomislavgrad",
	"Ban Bang Tabun",
	"Schrozberg",
	"Mount Zion",
	"Piedmont",
	"Casaleone",
	"Fernando Prestes",
	"Türi",
	"Norwood",
	"China",
	"Riachos",
	"Santa Bárbara",
	"Patterson",
	"Dosríus",
	"Debesy",
	"Villa Park",
	"Novaya Mayna",
	"Dobrovelychkivka",
	"Tupper Lake",
	"Sveio",
	"Belogradchik",
	"Severiano Melo",
	"San Giorgio Piacentino",
	"Vadsø",
	"Lyubeshiv",
	"Mouy",
	"Rudná",
	"Highgrove",
	"The Village of Indian Hill",
	"Solidão",
	"Sudzha",
	"Wangen bei Olten",
	"Turner",
	"Markt Erlbach",
	"Conley",
	"Little Silver",
	"Jahnsdorf",
	"Slobozia Mare",
	"Edna",
	"Peoria Heights",
	"Dunboyne",
	"Llerena",
	"Cestica",
	"Montehermoso",
	"Hilton",
	"Sewickley",
	"Eschede",
	"Dyshne-Vedeno",
	"Meymeh",
	"Granite Shoals",
	"Palmview",
	"Fountainhead-Orchard Hills",
	"La Guancha",
	"Stavern",
	"Calumbi",
	"Eaton",
	"Cambria",
	"Vetovo",
	"Dömsöd",
	"Gas City",
	"Brownfields",
	"San Hilario Sacalm",
	"Trivero",
	"Attalla",
	"Belding",
	"Bannockburn",
	"Le Boulou",
	"Crafton",
	"Harris",
	"Washingtonville",
	"Suolahti",
	"Brush",
	"Chiaravalle Centrale",
	"Jaramatáia",
	"Patrocínio do Muriaé",
	"Ajdir",
	"Huelma",
	"Wartenberg",
	"Tamsweg",
	"Dobšiná",
	"Piacatu",
	"Aït el Farsi",
	"Trenton",
	"Fort Lee",
	"Oak Point",
	"West Modesto",
	"Kampong Rimba",
	"Torreblanca",
	"Dickson City",
	"Medina de Pomar",
	"Romont",
	"Coroados",
	"El Aargub",
	"Vergèze",
	"Oulad Zarrad",
	"Arafo",
	"Viana do Alentejo",
	"Arienzo",
	"Großschirma",
	"Takamori",
	"Våler",
	"Nègrepelisse",
	"Três Fronteiras",
	"Saint-Martin-de-Seignanx",
	"Elena",
	"Ribadesella",
	"Hilmar-Irwin",
	"Violet",
	"Mendham",
	"Lansdowne",
	"Szada",
	"Bodenwerder",
	"Brunnthal",
	"Davoli",
	"Rabastens",
	"White Rock",
	"São Domingos das Dores",
	"Flanders",
	"Findlay",
	"Fischbachau",
	"Schattdorf",
	"Tiefenbronn",
	"Bagshot",
	"Onagawa",
	"Hanstedt",
	"Sansom Park",
	"Nocera Umbra",
	"North Manchester",
	"Asbury",
	"Riedering",
	"Bascharage",
	"Fort Wright",
	"Marsannay-la-Côte",
	"Sâncraiu de Mureş",
	"Traben-Trarbach",
	"Melk",
	"Rancho Murieta",
	"Niederorschel",
	"Hosszúpályi",
	"Tichla",
	"Bergheim",
	"Pong",
	"Lee",
	"Petrolia",
	"Alkhazurovo",
	"Ebbs",
	"Elbridge",
	"West Yarmouth",
	"Ensuès-la-Redonne",
	"Aldeamayor de San Martín",
	"Eglisau",
	"Lomnice nad Popelkou",
	"Alaró",
	"Warrenton",
	"Coublevie",
	"São Nicolau",
	"Decazeville",
	"Sinton",
	"New Gloucester",
	"Pyrford",
	"Geltendorf",
	"Kapustin Yar",
	"East Rockhill",
	"Bannalec",
	"Veredinha",
	"Fair Haven",
	"Fabens",
	"Erdmannhausen",
	"Wernberg-Köblitz",
	"Pavia di Udine",
	"Lee Acres",
	"Moulins-lès-Metz",
	"Zsámbék",
	"Dagmersellen",
	"Oleiros",
	"Pomarance",
	"Naples Park",
	"Luzerna",
	"Uncia",
	"Benton",
	"Blairstown",
	"Roquemaure",
	"Zubří",
	"Bötzingen",
	"Wasungen",
	"Diamante",
	"Tát",
	"Nord-Fron",
	"Fort Washington",
	"Celanova",
	"Ambazac",
	"Crosspointe",
	"Thayngen",
	"Sennwald",
	"Pato Bragado",
	"Volney",
	"Chalé",
	"Piding",
	"Gugark",
	"Longswamp",
	"Ait Ban",
	"Lőrinci",
	"Santa Ernestina",
	"Chitaraque",
	"Southwest Middlesex",
	"Montpon-Ménestérol",
	"Bol’shaya Kudara",
	"Guaimbê",
	"Vítkov",
	"Oceanport",
	"Hjärup",
	"Lammi",
	"South Haven",
	"Paola",
	"Rumford",
	"Velence",
	"Weßling",
	"Monroeville",
	"Cividate al Piano",
	"Gergebil",
	"Zapata",
	"San Andrés Huayápam",
	"Briec",
	"Mezőcsát",
	"Genzano di Lucania",
	"Vladimiro-Aleksandrovskoye",
	"Union",
	"Grafton",
	"Gabicce",
	"Rio Pinar",
	"Tubo",
	"Passignano sul Trasimeno",
	"La Cadière-d’Azur",
	"Hopfgarten im Brixental",
	"Grabow",
	"Trevignano Romano",
	"Ulysses",
	"New Boston",
	"Ilava",
	"Primorsk",
	"Mengjiazhuang",
	"Ondres",
	"Front of Yonge",
	"Montepaone",
	"Kunovice",
	"Saratoga",
	"Santa Rita do Itueto",
	"Sillingy",
	"Genlis",
	"Malta",
	"Claverack",
	"Dripping Springs",
	"Vegreville",
	"Saint-Laurent-de-Mure",
	"Wareham",
	"Grafton",
	"Highland",
	"Douglass Hills",
	"Bystřice",
	"Lamspringe",
	"Jones",
	"Spring Grove",
	"Woods Creek",
	"Casalserugo",
	"Gan",
	"Reichshoffen",
	"Hirschau",
	"Prado del Rey",
	"Laie",
	"Barguzin",
	"Cedro de São João",
	"Kembs",
	"Belle Fourche",
	"Chickasaw",
	"Nazareth",
	"Allendorf",
	"Pouzauges",
	"Barabash",
	"Sontheim an der Brenz",
	"Mugnano del Cardinale",
	"Turiys’k",
	"Elmwood",
	"Munderkingen",
	"Ylistaro",
	"Sainte-Brigitte-de-Laval",
	"Saint-Pierre-lès-Nemours",
	"Konolfingen Dorf",
	"Großschönau",
	"Divilaca",
	"Mezzocorona",
	"Shatrovo",
	"Penamacor",
	"Dniprovske",
	"Babək",
	"La Cenia",
	"Verchères",
	"Apice",
	"Oleksandriiske",
	"Hopkinton",
	"Bobov Dol",
	"Marchwood",
	"Westenschouwen",
	"The Pas",
	"Duffield",
	"Wrightsville",
	"Linglestown",
	"Sigean",
	"Hamanaka-sakuraminami",
	"Dalen",
	"Saint-Césaire",
	"Nuits-Saint-Georges",
	"Les Essarts",
	"Satteldorf",
	"Rottendorf",
	"Ruhla",
	"Caldeirão Grande",
	"Leutershausen",
	"Igersheim",
	"Nakkila",
	"Cocalinho",
	"Glenbrook",
	"San Tammaro",
	"Bacurituba",
	"Virgolândia",
	"Saint-Gervais-les-Bains",
	"Panicale",
	"Marsico Vetere",
	"Prüm",
	"Grangetown",
	"Topolovgrad",
	"Princeton",
	"Tafadna",
	"Gavrilov Posad",
	"Nünchritz",
	"Bordères-sur-l’Échez",
	"Aliança do Tocantins",
	"Ebensfeld",
	"Quistello",
	"Hollidaysburg",
	"Loughman",
	"Manchester",
	"Dove Valley",
	"Manz̧arīyeh",
	"Sigayevo",
	"Bad Boll",
	"Tellico Village",
	"La Ronge",
	"Narjeh",
	"Belin-Béliet",
	"Kelso",
	"Casoli",
	"Himmelpforten",
	"Arradon",
	"Stolpen",
	"Cupra Marittima",
	"Perobal",
	"Saint-Martin-d’Uriage",
	"São José do Goiabal",
	"Zermatt",
	"Estherville",
	"Philomath",
	"Greenbrier",
	"Wilton",
	"Tay Valley",
	"Jonquières",
	"Mechra-Hommadi",
	"Butgenbach",
	"Mathis",
	"Vadul lui Vodă",
	"Villa Huidobro",
	"Salteras",
	"Dietenhofen",
	"Hartford City",
	"Wayne",
	"Santa Marta de Ortigueira",
	"Zeithain",
	"Moglia",
	"Sasbach",
	"Springfield",
	"Barrolândia",
	"Paradise",
	"Montefalco",
	"Myshkin",
	"Oberammergau",
	"Eastwood",
	"Bruckberg",
	"Sarcedo",
	"La Roque-d’Anthéron",
	"Sannazzaro de’ Burgondi",
	"Subkhankulovo",
	"Miercurea Nirajului",
	"Richmond",
	"Gardiner",
	"Wilmington",
	"Pranchita",
	"Puhoi",
	"Vöhl",
	"Paoli",
	"Schwertberg",
	"Custonaci",
	"Elsbethen",
	"Zipacón",
	"Povegliano",
	"Haag",
	"Jemaat Moul Blad",
	"Domburg",
	"Longton",
	"Neuenkirchen",
	"Chūbar",
	"Taiuva",
	"Telgate",
	"Garéoult",
	"Nederhorst den Berg",
	"San Sebastián del Oeste",
	"Starke",
	"East Flat Rock",
	"Dandridge",
	"Cambria",
	"Lambres-lès-Douai",
	"Gadebusch",
	"Corydon",
	"Chonchi",
	"Nueva-Carteya",
	"Huejúcar",
	"Aiffres",
	"Kolitzheim",
	"Upper Pottsgrove",
	"Peveragno",
	"Poncarale",
	"Couto de Magalhães",
	"Fulnek",
	"Strážnice",
	"Pontoon Beach",
	"Ban Krang",
	"Porreras",
	"Boretto",
	"Adams",
	"Oye-Plage",
	"Borgo San Giacomo",
	"Raková",
	"Ventabren",
	"Champlain",
	"Byron",
	"South Bruce",
	"Wyndmoor",
	"Caiazzo",
	"Samsula-Spruce Creek",
	"Zephyrhills South",
	"Unkel",
	"Böhmenkirch",
	"Goiandira",
	"Étoile-sur-Rhône",
	"Kangasniemi",
	"Bang Sai",
	"Lakehills",
	"Kosmonosy",
	"Elstree",
	"Dymer",
	"Taquarivaí",
	"Beni Ounif",
	"Alva",
	"Atarjea",
	"Hotton",
	"Campinas do Piauí",
	"Milton",
	"Onrus",
	"Spielberg bei Knittelfeld",
	"Oreland",
	"Abenberg",
	"Corguinho",
	"Mellrichstadt",
	"Mugardos",
	"Ellrich",
	"Root",
	"Františkovy Lázně",
	"Wright",
	"Riga",
	"Groveport",
	"Olivarez",
	"Willow Springs",
	"Bentivoglio",
	"Waidhofen an der Thaya",
	"Chazelles-sur-Lyon",
	"Cana Verde",
	"Gardiner",
	"Forino",
	"Neuville-de-Poitou",
	"Hradec nad Moravici",
	"Chudniv",
	"Strazhitsa",
	"Izsák",
	"Giebelstadt",
	"Neilston",
	"Beratzhausen",
	"Attert",
	"McMasterville",
	"Botricello",
	"Sovere",
	"Veľký Šariš",
	"São Cristovão do Sul",
	"West Pennsboro",
	"Ochtendung",
	"Copşa Mică",
	"Savyntsi",
	"Broshniv-Osada",
	"Schöppenstedt",
	"Pittstown",
	"Klyuchevka",
	"Starobaltachevo",
	"Ostseebad Binz",
	"Jonesborough",
	"Glattfelden",
	"Harburg",
	"Lenzing",
	"Kingstree",
	"Anna",
	"Lake Delton",
	"Dayton",
	"Cottleville",
	"Bowling Green",
	"Le Barp",
	"Ille-sur-Têt",
	"Ispra",
	"Gévezé",
	"Blaine",
	"Guenfouda",
	"Wyke Regis",
	"Dicomano",
	"Oberkotzau",
	"Lackland AFB",
	"Elverta",
	"Kemnath",
	"Macael",
	"Cavour",
	"Mansfield",
	"San Cipirello",
	"Rusk",
	"Kirkwood",
	"Kibale",
	"Novoyegor’yevskoye",
	"Leacock",
	"Valence",
	"Soleto",
	"Redcliff",
	"Barra do Chapéu",
	"Smithfield Heights",
	"Affing",
	"Castiglione dei Pepoli",
	"Ingelfingen",
	"Galliera",
	"Hohenwestedt",
	"Dhar Souk",
	"Puente Caldelas",
	"Linton",
	"Aquino",
	"Richland",
	"Alambari",
	"Hartford",
	"Cravolândia",
	"Korishë",
	"Semmes",
	"General Carneiro",
	"Pannal",
	"Caiana",
	"Xoxocotla",
	"Glashütten",
	"Benejúzar",
	"Waldmohr",
	"Wilhermsdorf",
	"Gmünd",
	"Palmview South",
	"Honesdale",
	"Poland",
	"São Pedro do Suaçuí",
	"Zilair",
	"Onguday",
	"Warren",
	"Contulmo",
	"Torchiarolo",
	"Lipcani",
	"Aginskoye",
	"Codognè",
	"Pedro Vicente Maldonado",
	"Poysdorf",
	"Amphawa",
	"Tonganoxie",
	"Saint-Chéron",
	"Bergrheinfeld",
	"Souppes-sur-Loing",
	"Dargaville",
	"Maralik",
	"Marlin",
	"Oxford",
	"Tynset",
	"Saint-Astier",
	"Rochester",
	"San Vito Chietino",
	"Islip Terrace",
	"Anghiari",
	"Bessemer City",
	"Aguiar",
	"Drebkau",
	"Romelândia",
	"Gandino",
	"Mulsanne",
	"Siesta Key",
	"Beaucouzé",
	"Barbing",
	"Wegscheid",
	"Zákamenné",
	"Nueil-les-Aubiers",
	"Tomashpil",
	"New Carlisle",
	"Kumhausen",
	"Povoletto",
	"Aubigny-sur-Nère",
	"Deba",
	"Deggingen",
	"Ploumagoar",
	"Slinger",
	"Aceuchal",
	"Bowling",
	"Dayr al Bukht",
	"Mali",
	"Schönau am Königssee",
	"Cochem",
	"Kerimäki",
	"Feusisberg",
	"Stochov",
	"Korop",
	"Lake Bluff",
	"Waterford",
	"Boxborough",
	"Caiuá",
	"Cavallermaggiore",
	"Presidente Bernardes",
	"Frankenmuth",
	"Eureka",
	"Balsfjord",
	"Guxhagen",
	"Nova Borova",
	"Stroudsburg",
	"Duquesne",
	"Preston",
	"Malax",
	"Otterberg",
	"Villanueva",
	"Ertingen",
	"Ingå",
	"Tracyton",
	"Champagne-sur-Oise",
	"Conceição do Pará",
	"Cancale",
	"San Nicolás Tolentino",
	"Nashville",
	"Leadville",
	"Battonya",
	"Brébières",
	"Seelow",
	"Vila do Porto",
	"Darkūsh",
	"Heiningen",
	"Commercy",
	"Dabancheng",
	"Japi",
	"Egling",
	"Ceiba",
	"Espenau",
	"Meckesheim",
	"Berryville",
	"Vinita",
	"Arjona",
	"Neviano",
	"Saint-Privat-des-Vieux",
	"Algodonales",
	"Hubynykha",
	"Altenkunstadt",
	"Tidenham",
	"Siátista",
	"Otyniia",
	"São Jorge do Ivaí",
	"Connell",
	"Buckner",
	"Itaara",
	"Sarina",
	"Taylorsville",
	"Glencoe",
	"Wappingers Falls",
	"Lake Park",
	"Ada",
	"Bento Fernandes",
	"Renswoude",
	"Drusenheim",
	"Lohra",
	"Exeter",
	"Grand-Champ",
	"Rondinha",
	"Shalazhi",
	"Hidden Valley Lake",
	"Barre",
	"Waterville",
	"Piolenc",
	"Canzo",
	"Kae Dam",
	"Waimanalo",
	"Lendak",
	"Anamosa",
	"Golegã",
	"Zeeland",
	"Forest",
	"Favria",
	"Cotronei",
	"Loudon",
	"Pelham Manor",
	"Monticello",
	"Nedryhayliv",
	"Coronel Ezequiel",
	"Guaraci",
	"Amagi",
	"Colorado City",
	"Weldon Spring",
	"Montemiletto",
	"Jestetten",
	"Stara Syniava",
	"San Ciprián de Viñas",
	"Kannus",
	"Casariche",
	"Hidden Valley",
	"Hachirougata",
	"Cap Malheureux",
	"Chíquiza",
	"Lieboch",
	"Falcon Heights",
	"Ore",
	"Féres",
	"Lichk",
	"Campinas do Sul",
	"Strawberry",
	"South Huntingdon",
	"Incourt",
	"Machadinho",
	"Estenfeld",
	"Atlanta",
	"Obrigheim",
	"Saint Légier-La Chiésaz",
	"Zschorlau",
	"Horní Slavkov",
	"Warthausen",
	"Weiser",
	"Yosemite Lakes",
	"Amblève",
	"Erlenbach",
	"Kirchdorf am Inn",
	"Barnard Castle",
	"Harrietstown",
	"Hopewell",
	"Nassogne",
	"San Benito",
	"Trilport",
	"Brejinho de Nazaré",
	"Clare",
	"Byron Bay",
	"Horgenzell",
	"Esterwegen",
	"San Quintin",
	"Sabino",
	"Wool",
	"Bom Jesus do Amparo",
	"Gersfeld",
	"Highland Lakes",
	"Germantown",
	"Wangen",
	"Plouhinec",
	"Nong Muang",
	"Pequizeiro",
	"Luisiânia",
	"Hamptonburgh",
	"Châtillon-sur-Seine",
	"Dietramszell",
	"Broşteni",
	"Bagan",
	"Piranguçu",
	"Baxley",
	"Santa Teresa",
	"Boa Ventura",
	"Carlinville",
	"Śmigiel",
	"Montanaro",
	"Bernhardswald",
	"Aramina",
	"Bethany",
	"Sherwood Manor",
	"Bīdokht",
	"Meaño",
	"Kham Sakae Saeng",
	"Elberton",
	"Kayenta",
	"Bertolínia",
	"Vechigen",
	"Ebstorf",
	"Dolní Lutyně",
	"Winston",
	"Hluboká nad Vltavou",
	"Rivanazzano",
	"Sollenau",
	"Almazán",
	"Pyhäjärvi",
	"Guitiriz",
	"Nachalovo",
	"Faulquemont",
	"Hägendorf",
	"Wellston",
	"San Pietro di Feletto",
	"Blackrod",
	"Rockdale",
	"Stornarella",
	"Villapiana",
	"Presicce",
	"Viry",
	"Jubbah",
	"Penn",
	"Holdenville",
	"Swartz Creek",
	"Mutriku",
	"Aying",
	"Ladyville",
	"Tympáki",
	"Viiala",
	"Grävenwiesbach",
	"Aguiar da Beira",
	"Phanom Thuan",
	"Lanzo Torinese",
	"Rangendingen",
	"Feldkirchen an der Donau",
	"Mirecourt",
	"Marbletown",
	"Makhkety",
	"Carroll",
	"Glabbeek-Zuurbemde",
	"Saint-Philippe",
	"Jamestown",
	"Gabčíkovo",
	"Archidona",
	"Caparaó",
	"Schrems",
	"Teuva",
	"Imigdal",
	"Dzierzgoń",
	"Riltsi",
	"Lagonegro",
	"Mena",
	"Casal Velino",
	"Sambaíba",
	"Stokesdale",
	"Caprarola",
	"Lanusei",
	"Fort Plain",
	"Kalaheo",
	"Corlăteni",
	"Wolfforth",
	"Fairview",
	"General Conesa",
	"Jászladány",
	"Borriol",
	"Vaileka",
	"Perranzabuloe",
	"Pregarten",
	"Castelvetro Piacentino",
	"Vila de Porto Santo",
	"Tvardiţa",
	"Bol’shoye Nagatkino",
	"Laufach",
	"Saint-Étienne-au-Mont",
	"Kremnica",
	"San Antonio de los Cobres",
	"Manteo",
	"Acquapendente",
	"Greenwich",
	"Kuzumaki",
	"Kouli Kouara",
	"Lekala",
	"Agramunt",
	"Cucq",
	"Peculiar",
	"Muldrow",
	"Breckenridge",
	"Vic-le-Comte",
	"Somerdale",
	"Desaguadero",
	"Sparta",
	"Tharandt",
	"Beekmantown",
	"Dulles Town Center",
	"Pommelsbrunn",
	"Calatabiano",
	"Sulzbach an der Murr",
	"Nandlstadt",
	"Caramanta",
	"Oryahovo",
	"Sant’Omero",
	"Macachín",
	"Niedenstein",
	"Hartley",
	"Limana",
	"Planá",
	"Condat-sur-Vienne",
	"Indian Wells",
	"Quincy",
	"Bar Harbor",
	"Alameda",
	"Bine Al Widane",
	"Borgo Ticino",
	"Benedito Leite",
	"Basiliano",
	"Zhizdra",
	"Pervomayskoye",
	"Williams",
	"Alcantil",
	"Telč",
	"Colle Umberto",
	"Charleston",
	"Richelieu",
	"Notre-Dame-du-Mont-Carmel",
	"Rockenhausen",
	"Kurumkan",
	"Rye",
	"Fragagnano",
	"Campo Novo",
	"Cameron",
	"New Holland",
	"L'Ange-Gardien",
	"Pana",
	"Arzakan",
	"Cabaceiras",
	"Sládkovičovo",
	"Abegondo",
	"Caxingá",
	"Sainte-Martine",
	"Ḩisyā’",
	"Rot am See",
	"Niederaula",
	"Itaguaru",
	"Chinchón",
	"Komádi",
	"Woodside",
	"Braúna",
	"Giavera del Montello",
	"Hügelsheim",
	"Padula",
	"Missillac",
	"Wesendorf",
	"Harwinton",
	"Colibaşi",
	"Penmarch",
	"Taiki",
	"Bédarrides",
	"Jokioinen",
	"San Simón de Guerrero",
	"Nursling",
	"Adel",
	"Vámospércs",
	"Barton on Sea",
	"Novaci-Străini",
	"Sam Sung",
	"Pfreimd",
	"Batinci",
	"Ahlerstedt",
	"Illasi",
	"Starý Plzenec",
	"Lyons",
	"Zistersdorf",
	"Earls Barton",
	"Bad Gleichenberg",
	"Morristown",
	"Glonn",
	"Independence",
	"Baindt",
	"Vrontádos",
	"Jim Thorpe",
	"Poretskoye",
	"Joanésia",
	"Valle de Juárez",
	"Bilton",
	"Terrell Hills",
	"Dörpen",
	"Longuyon",
	"Auburn",
	"Carlisle",
	"Piterka",
	"Caseara",
	"Colby",
	"Centerville",
	"Jamul",
	"East Moriches",
	"Sălişte",
	"Villaviciosa",
	"Velas",
	"Springhill",
	"Catas Altas",
	"Monte Libretti",
	"Castrignano del Capo",
	"Santa Teresa Gallura",
	"Schermerhorn",
	"Imouzzer des Ida ou Tanane",
	"Gazzo Veronese",
	"Marquise",
	"Entre-Folhas",
	"Jampruca",
	"Malpica",
	"La Grand-Combe",
	"South Pittsburg",
	"Eaton Rapids",
	"Datas",
	"Gizzeria",
	"Châteaulin",
	"Pulyny",
	"Gilbertsville",
	"Robbins",
	"Saint-Pie",
	"Marabá Paulista",
	"Pôrto Lucena",
	"Heroldsbach",
	"Northwood",
	"Cardenden",
	"Silvanópolis",
	"Eltmann",
	"Mouguerre",
	"Paranga",
	"St. Augustine South",
	"Sidney",
	"Archángelos",
	"Black River Falls",
	"Sankt Ruprecht an der Raab",
	"Großpösna",
	"West Vincent",
	"Grieskirchen",
	"Maslog",
	"Skýdra",
	"Quorndon",
	"Honeoye Falls",
	"Peachland",
	"Fruitland",
	"Neudenau",
	"Linguaglossa",
	"Rockwood",
	"Teojomulco",
	"Debeljača",
	"Coraopolis",
	"Schärding",
	"Ofterdingen",
	"Szikszó",
	"Lüdersdorf",
	"Vernon",
	"Shady Side",
	"Třemošná",
	"Iffezheim",
	"Esine",
	"Waynesboro",
	"Ashfield-Colborne-Wawanosh",
	"Wackersdorf",
	"Monaca",
	"Dalry",
	"Mittersill",
	"Bad Hall",
	"Bléré",
	"El Limón",
	"Librilla",
	"Itacambira",
	"Santo Inácio",
	"Thum",
	"Benkovski",
	"Jackson",
	"Jeceaba",
	"Rheinzabern",
	"Nova Esperança",
	"Columbia",
	"Lafayette",
	"Hobøl",
	"Weitnau",
	"Cigales",
	"Ripi",
	"Angier",
	"Batesburg-Leesville",
	"North Sewickley",
	"Gemmingen",
	"Denver City",
	"Chadron",
	"Ferriday",
	"Kisbér",
	"Ixtapangajoya",
	"Büchenbach",
	"New Hempstead",
	"Gazzaniga",
	"Coal City",
	"San Miguel Panán",
	"Kusel",
	"Vitorchiano",
	"Canal Fulton",
	"Holdrege",
	"Sebezh",
	"Baia de Aramă",
	"Sully-sur-Loire",
	"Altentreptow",
	"East Port Orchard",
	"Skalité",
	"Zdvinsk",
	"Battenberg",
	"Gagliano del Capo",
	"Basdorf",
	"Qamīnis",
	"Takae",
	"Centuripe",
	"Tota",
	"Rákóczifalva",
	"Salamanca",
	"Monticelli d’Ongina",
	"Valparaiso",
	"Rosheim",
	"Talavera La Real",
	"Valatie",
	"Château-Arnoux-Saint-Auban",
	"Rodigo",
	"Los Montesinos",
	"Laaber",
	"Villanueva del Trabuco",
	"Pagnacco",
	"Ourique",
	"Algona",
	"Mocksville",
	"Trent Lakes",
	"Léglise",
	"La Ferrière",
	"Senador Amaral",
	"San Vicente de Alcántara",
	"Ohlsdorf",
	"Giussago",
	"Rocbaron",
	"Palermo",
	"Floral City",
	"Centerport",
	"Jacaré dos Homens",
	"Pinckneyville",
	"Northern Rockies",
	"New Castle",
	"Kinross",
	"Purulhá",
	"Čelopek",
	"Smiths Station",
	"Simancas",
	"Scaër",
	"Warm Mineral Springs",
	"Manitou Springs",
	"Kale",
	"Stadl-Paura",
	"Rambervillers",
	"General Bravo",
	"Heimsheim",
	"Komsomolskoye",
	"Muro Lucano",
	"Bidford-on-Avon",
	"Northfield",
	"Zlynka",
	"Berlin",
	"Central",
	"Hitzkirch",
	"Stühlingen",
	"Osterrönfeld",
	"La Grange",
	"Pechenizhyn",
	"Winterset",
	"Manchester-by-the-Sea",
	"Lemmon Valley",
	"Polla",
	"Fraureuth",
	"Exton",
	"Bridgton",
	"Valencia de Alcántara",
	"Entrambasaguas",
	"Nagbukel",
	"Vienna",
	"Bellows Falls",
	"Oppido Mamertina",
	"Darda",
	"Trhové Sviny",
	"Saint-Michel-Chef-Chef",
	"Measham",
	"Wonthaggi",
	"Marilla",
	"Neuhausen",
	"Totontepec Villa de Morelos",
	"Acula",
	"Decize",
	"Orwigsburg",
	"Citrus Park",
	"Albaredo d’Adige",
	"Newmains",
	"Caldwell",
	"São Tomé",
	"Trøgstad",
	"Pohořelice",
	"Cleveland",
	"Lisbon",
	"Odzun",
	"Momignies",
	"Turvelândia",
	"Polson",
	"Cavalero",
	"Carsoli",
	"Ḩannā",
	"Ciacova",
	"Hoshcha",
	"Bodenkirchen",
	"Rapolano Terme",
	"Pettenbach",
	"Estacada",
	"Chackbay",
	"West St. Paul",
	"Vânju-Mare",
	"Bellpuig",
	"Lindenfels",
	"Manchester",
	"Windsor",
	"Mahlberg",
	"Clarinda",
	"Ribadumia",
	"Santa Cristina de Aro",
	"Pytalovo",
	"Sunset",
	"Wind Lake",
	"Porto dos Gaúchos",
	"Penn Estates",
	"Valencia de Don Juan",
	"Janduís",
	"Horodnytsia",
	"Alness",
	"Dickinson",
	"St. Paul Park",
	"Simonton Lake",
	"Redbourn",
	"Krieglach",
	"Veronella",
	"Gottolengo",
	"Sarroch",
	"Noyemberyan",
	"Le Rove",
	"Liberdade",
	"Ward",
	"Gualchos",
	"Parres",
	"Pishchanka",
	"Roundway",
	"Hockinson",
	"Velden",
	"Ühlingen-Birkendorf",
	"Pabradė",
	"Englewood Cliffs",
	"James City",
	"Oberaudorf",
	"Feira Nova",
	"Anthony",
	"Redstone",
	"Shepherdstown",
	"North Oaks",
	"Creston",
	"Smithers",
	"Breitenbrunn",
	"Bajram Curri",
	"Mattawa",
	"Bederkesa",
	"Caruthersville",
	"Port LaBelle",
	"Brookline",
	"Cornwall",
	"Castellucchio",
	"Maruggio",
	"San Pedro de Atacama",
	"Campegine",
	"Ligné",
	"Williamson",
	"Mollina",
	"Travelers Rest",
	"Newport",
	"Rincon Valley",
	"Kyparissía",
	"Meadow Lake",
	"Balatonlelle",
	"East Quogue",
	"Figueirópolis",
	"Shatsk",
	"Velburg",
	"Alsónémedi",
	"Blaufelden",
	"Jaboti",
	"Macon",
	"Schweitenkirchen",
	"Kunmadaras",
	"Frankford",
	"Tassamert",
	"Botuverá",
	"Rupea",
	"Saint-Gilles",
	"Spas-Klepiki",
	"Polná",
	"Pyhtää",
	"Lanark Highlands",
	"Ilomantsi",
	"Velká Bíteš",
	"Chiusa",
	"Morganfield",
	"Apple Valley",
	"Palmerton",
	"San Leon",
	"Chions",
	"Horažďovice",
	"Montefrío",
	"Doutor Maurício Cardoso",
	"Jonesboro",
	"Morris",
	"Terrujem",
	"Tickhill",
	"La Balme-de-Sillingy",
	"Bakov nad Jizerou",
	"Puchuncaví",
	"Nava",
	"McGregor",
	"Sackville",
	"Triftern",
	"Viadutos",
	"Bratske",
	"Česká Kamenice",
	"Westernport",
	"Muravera",
	"Mazé",
	"Geoagiu",
	"Mapire",
	"Holýšov",
	"Saint-Pourçain-sur-Sioule",
	"Wake Village",
	"Canino",
	"Grand Falls",
	"Kdyně",
	"Tiszalök",
	"Santa Caterina Villarmosa",
	"Mourmelon-le-Grand",
	"Briare",
	"San Ildefonso",
	"Avondale",
	"Cochrane",
	"Bol’shoye Sorokino",
	"New Ipswich",
	"Hawaiian Beaches",
	"Bilibino",
	"Hadley",
	"Coreglia Antelminelli",
	"Sedelnikovo",
	"Gommiswald",
	"Ruokolahti",
	"Opishnya",
	"Le Pellerin",
	"Correzzola",
	"Marystown",
	"Kolosovka",
	"Ocean Shores",
	"McKenzie",
	"Bom Princípio",
	"Uhrichsville",
	"Saint-Germain-du-Puy",
	"Lehighton",
	"Vicente Dutra",
	"Minot AFB",
	"Großheubach",
	"Fort Rucker",
	"Bandar-e Rīg",
	"Żołynia",
	"Andorf",
	"Lohsa",
	"Camariñas",
	"Fruitland",
	"Locmaria-Plouzané",
	"Villeneuve-sur-Yonne",
	"Taliouîne",
	"Plessé",
	"Castelnuovo Scrivia",
	"Molchanovo",
	"Gweta",
	"Savannah",
	"Jefferson",
	"Lampazos de Naranjo",
	"Khatassy",
	"Tha Wang Pha",
	"Wiedemar",
	"Česká Skalice",
	"Zovuni",
	"Hîrbovăţ",
	"Córdoba",
	"Avdon",
	"Grans",
	"Fairport",
	"Union Beach",
	"Pawcatuck",
	"Bessan",
	"Berri",
	"Hightstown",
	"East Granby",
	"Suvorovo",
	"Rochedo",
	"Conches-en-Ouche",
	"Alton",
	"Brady",
	"Brent",
	"Eagleville",
	"Ovelgönne",
	"Kelly",
	"Henfield",
	"Alburquerque",
	"Krivosheino",
	"Camogli",
	"Bulverde",
	"Alto del Carmen",
	"Waeng Yai",
	"Pacy-sur-Eure",
	"Bolton",
	"Kirchberg in Tirol",
	"Guglionesi",
	"Hareid",
	"Pottenstein",
	"Znamenskoye",
	"Komyshuvakha",
	"Longarone",
	"Dom Cavati",
	"Odelzhausen",
	"Santa Maria do Salto",
	"Paxtonia",
	"Ochamchire",
	"Waynesville",
	"Ayora",
	"Naie",
	"Kappel-Grafenhausen",
	"Cerda",
	"Mount Carmel",
	"Waldems",
	"Borkum",
	"Parazinho",
	"Oettingen in Bayern",
	"Kings Point",
	"Bear Valley Springs",
	"San José de Pare",
	"Benjamín Hill",
	"Maser",
	"Orange",
	"Kahaluu-Keauhou",
	"Vacha",
	"Carroll",
	"Cannobio",
	"Ronneburg",
	"Vigone",
	"Wanroij",
	"Polop",
	"Iola",
	"Tizagzawine",
	"Pechenihy",
	"Vila do Bispo",
	"Landri Sales",
	"Zephyrhills West",
	"Malles Venosta",
	"Volta Grande",
	"San Giovanni Ilarione",
	"Garnet",
	"Avaí",
	"Igensdorf",
	"Lake Fenton",
	"Novohuivynske",
	"Kyshtovka",
	"Baliza",
	"Old Bethpage",
	"South Sarasota",
	"Jaffrey",
	"Corropoli",
	"Laces",
	"North Dansville",
	"Marano sul Panaro",
	"Nalbandyan",
	"Chepelare",
	"Midway",
	"La Campana",
	"Cuges-les-Pins",
	"Big Bear Lake",
	"Titusville",
	"St. Clair",
	"Austevoll",
	"Bratslav",
	"Grayson",
	"Stara Vyzhivka",
	"Red Oak",
	"Cave Springs",
	"Peal de Becerro",
	"Acorizal",
	"Wadesboro",
	"Frei",
	"Arcabuco",
	"Edwinstowe",
	"Ripley",
	"Drebach",
	"Altenstadt",
	"Sioux Lookout",
	"Zarat",
	"Divina Pastora",
	"Peyrolles-en-Provence",
	"Sevelen",
	"Arzberg",
	"Ardore",
	"Hawkinsville",
	"Forsyth",
	"Santana do Itararé",
	"Csákvár",
	"Pourrières",
	"Le Bourget-du-Lac",
	"Sechenovo",
	"Didsbury",
	"Yamanaka",
	"Thalmässing",
	"Rothschild",
	"Lomovka",
	"Fraubrunnen",
	"Delhi Hills",
	"Ban Bang Pramung",
	"Großaitingen",
	"Champniers",
	"Belvedere",
	"El Cocuy",
	"Shaykh al Ḩadīd",
	"Centola",
	"Wermsdorf",
	"Bucchianico",
	"Angelina",
	"Lagundo",
	"Pecan Acres",
	"Timzguida Ouftas",
	"Dom Silvério",
	"Villers-le-Lac",
	"Néa Anchiálos",
	"Esporlas",
	"Rottenmann",
	"Wilhelmsdorf",
	"Syumsi",
	"Mansuè",
	"Marianópolis do Tocantins",
	"Guémené-Penfao",
	"Howard Springs",
	"Alcover",
	"Villette-d’Anthon",
	"Francinópolis",
	"Novomykolayivka",
	"Parigné-l’Évêque",
	"Giddings",
	"Beya",
	"Houffalize",
	"Bäretswil",
	"Servian",
	"Abrud",
	"Venus",
	"Orchomenós",
	"Jílové",
	"Castelbellino",
	"Turangi",
	"Wörthsee",
	"Fernie",
	"Deer Lake",
	"Luynes",
	"Hankasalmi",
	"Tvrdošovce",
	"Otero de Rey",
	"Sankt Johann",
	"Perry",
	"Carlyss",
	"Tápiószentmárton",
	"Doksy",
	"Calberlah",
	"Calistoga",
	"Perushtitsa",
	"Odessa",
	"Kalynivka",
	"Chemerivtsi",
	"Francisco Dumont",
	"Steyerberg",
	"Brensbach",
	"Holmfirth",
	"Poolesville",
	"Pedrera",
	"Eckersdorf",
	"Livingston",
	"Walkertown",
	"Osceola",
	"Yarmouth Port",
	"Parikkala",
	"Sachseln",
	"Gbely",
	"Briar",
	"Wenham",
	"Ranson",
	"Capistrello",
	"Portoscuso",
	"Gouvy",
	"Alcalá de los Gazules",
	"Dores de Guanhães",
	"Holytown",
	"Santana de Mangueira",
	"Oderwitz",
	"Moral de Calatrava",
	"Salem",
	"Sanom",
	"Prroshyan",
	"Birkenes",
	"Neda",
	"Seubersdorf",
	"Buje",
	"Lake Wildwood",
	"West Rockhill",
	"Washington",
	"Samassi",
	"La Souterraine",
	"Tricarico",
	"Ruskington",
	"Luhačovice",
	"Sasykoli",
	"Gramastetten",
	"Xibaipo",
	"Rădoaia",
	"Hendron",
	"Bangor",
	"Londonderry",
	"Bethel",
	"Woodstock",
	"Rio do Prado",
	"Laillé",
	"Hollywood",
	"Ses Salines",
	"Byron",
	"Ballston Spa",
	"Nong Na Kham",
	"Bisbee",
	"Vaglia",
	"Springfield",
	"Chisago City",
	"Carlisle",
	"Sariegos",
	"Santiago Zacatepec",
	"Union City",
	"North Stonington",
	"Laureana di Borrello",
	"Myrtletown",
	"Almadén",
	"Victor Meireles",
	"Chiny",
	"Kut Bak",
	"Gilmer",
	"Williamsville",
	"Martic",
	"Guaramiranga",
	"Bugyi",
	"Jequitibá",
	"Jinzhong",
	"Vereya",
	"San José del Golfo",
	"Manoel Emídio",
	"Layhill",
	"Elmsford",
	"Center",
	"Farmers Loop",
	"Warren",
	"Amöneburg",
	"Kyren",
	"Halsbrücke",
	"Quesada",
	"Jicalapa",
	"Driedorf",
	"Ribadavia",
	"Selty",
	"São Francisco do Glória",
	"Tunari",
	"Val-David",
	"Akarma",
	"Röbel",
	"Montrose",
	"Pinardville",
	"Rodniki",
	"Tiouli",
	"Frensdorf",
	"Jászkisér",
	"Bulboaca",
	"Baystonhill",
	"Vila Nova de Paiva",
	"Gresford",
	"Rabča",
	"Aberdeen",
	"Lagoa do Sítio",
	"Sankt Peter in der Au Markt",
	"Konstantinovka",
	"Diboll",
	"Hutchinson Island South",
	"Salto do Itararé",
	"Izmorene",
	"Wiggensbach",
	"Williamston",
	"Ravena",
	"Skuteč",
	"In Buri",
	"San Cristóbal Amatlán",
	"Ettrick",
	"Tannum Sands",
	"Thatcher",
	"South Bay",
	"Hofheim in Unterfranken",
	"Grassano",
	"Ocean Bluff-Brant Rock",
	"Böheimkirchen",
	"Baselga di Pinè",
	"Springfield",
	"Timber Pines",
	"Llangefni",
	"West Wendover",
	"Larsmo",
	"Obetz",
	"Mühlhausen",
	"Cristiano Otoni",
	"Chipping Sodbury",
	"Staroye Shaygovo",
	"Douar Oulad Amer Leqliaa",
	"Florensac",
	"DeWitt",
	"Ranstadt",
	"Übersee",
	"Forchtenberg",
	"Aghbar",
	"Sawmills",
	"Paulden",
	"Newfield",
	"Shebalino",
	"Maetinga",
	"Malishka",
	"Los Menucos",
	"Forcalquier",
	"Attica",
	"Flin Flon",
	"Külsheim",
	"Sainte-Marie-aux-Mines",
	"Celle Ligure",
	"Mount Olive",
	"Mae Chai",
	"Nesvady",
	"Boulder Creek",
	"Weisenberg",
	"Salmourão",
	"Kings Langley",
	"Marquina-Jemein",
	"Erval Grande",
	"Weitramsdorf",
	"Tichigan",
	"Conklin",
	"Walton",
	"Severnoye",
	"Hampden",
	"Parker",
	"Pound Ridge",
	"Tiszalúc",
	"Abūzeydābād",
	"Agricolândia",
	"Dunlap",
	"Doffing",
	"Vedeno",
	"Bad Wiessee",
	"Fahrenzhausen",
	"Vomp",
	"Volovets",
	"Collegeville",
	"Zlatitsa",
	"Saint-Mars-du-Désert",
	"West Pleasant View",
	"Obersontheim",
	"Ninotsminda",
	"Bedford",
	"Villadose",
	"Grandwood Park",
	"La Cañiza",
	"Creve Coeur",
	"Sterling",
	"Muzillac",
	"Broadalbin",
	"Pfeffenhausen",
	"North Salem",
	"Čierny Balog",
	"Rehfelde",
	"Sângeorgiu de Pădure",
	"Monheim",
	"Zapfendorf",
	"Hudson",
	"Crawfordville",
	"Bigoudine",
	"Dvory nad Žitavou",
	"Ibiquera",
	"Maratea",
	"Victor Harbor",
	"Berdyuzhye",
	"Plouigneau",
	"Tamboara",
	"Lake City",
	"Erbendorf",
	"La Garnache",
	"Pornainen",
	"Gruissan",
	"Gananoque",
	"Fezouane",
	"Rackwitz",
	"Oulad Messaoud",
	"River Ridge",
	"Grigor’evka",
	"Ochi",
	"Peize",
	"Marcelino Ramos",
	"Felício dos Santos",
	"Erwin",
	"Crihana Veche",
	"Grosse Pointe",
	"Oerlenbach",
	"Cazouls-lès-Béziers",
	"Zyryanskoye",
	"Schwaan",
	"Blauvelt",
	"Maine",
	"Greene",
	"Alcalá del Valle",
	"Brochier",
	"Hanamaulu",
	"Bliss Corner",
	"Grambling",
	"San Dionisio del Mar",
	"Milişăuţi",
	"Vila Propício",
	"Bitche",
	"Krasnyy Kholm",
	"Ottawa",
	"Ebnat-Kappel",
	"Las Navas del Marqués",
	"Birdsboro",
	"Upper Yoder",
	"Bristol",
	"Gameleiras",
	"Rafael Fernandes",
	"St. Clairsville",
	"Waterbury",
	"Ayden",
	"Russkiy Kameshkir",
	"Turriff",
	"Combarbalá",
	"Colebrookdale",
	"Idrinskoye",
	"Margaret",
	"Condofuri",
	"Iznalloz",
	"Äetsä",
	"Assoro",
	"Lenzkirch",
	"Colville",
	"Bierutów",
	"Mukhorshibir",
	"Tourves",
	"Holland",
	"Beas de Segura",
	"Goianorte",
	"Ghent",
	"Marion",
	"Shibetsu",
	"Sumiswald",
	"Sievi",
	"Primera",
	"Chester",
	"Oshamambe",
	"Humboldt",
	"Askola",
	"Sergeant Bluff",
	"Homestead Meadows North",
	"Lonsee",
	"Caturaí",
	"Freren",
	"Japurá",
	"Spring Valley",
	"Oberthulba",
	"Flagler Beach",
	"Canutillo",
	"Rainsville",
	"Khatukay",
	"Brokenhead",
	"Saint-Paul",
	"Reçani",
	"Lone Grove",
	"La Puebla de Almoradiel",
	"Peñafiel",
	"Oliva de la Frontera",
	"Silver Hill",
	"Colona",
	"Alfândega da Fé",
	"Catuti",
	"Madre de Deus de Minas",
	"Egyek",
	"Krauchenwies",
	"Negru Vodă",
	"Halaç",
	"Joroinen",
	"Bab Boudir",
	"Valverde",
	"Montecastrilli",
	"Battlement Mesa",
	"Post",
	"Dmitrovsk-Orlovskiy",
	"Bowie",
	"Northumberland",
	"Spalt",
	"McGuire AFB",
	"Guzolândia",
	"Montbard",
	"Serra do Navio",
	"Carmi",
	"Paris",
	"Oberviechtach",
	"Lesignano de’ Bagni",
	"Ilok",
	"Kings Park",
	"Quantico Base",
	"Mineo",
	"Thung Yai",
	"Øyer",
	"Stara Moravica",
	"Gargaliánoi",
	"Coco",
	"Lake",
	"Salbris",
	"Labranzagrande",
	"Utica",
	"Schuylkill Haven",
	"Lacanau",
	"Hollfeld",
	"Korpilahti",
	"Scott City",
	"Portland",
	"Poplar Grove",
	"Tineg",
	"São José da Varginha",
	"Naumburg",
	"Phanom",
	"Bozhurishte",
	"Bevagna",
	"Buellton",
	"Daleville",
	"Süsel",
	"Castro de Rey",
	"Belleview",
	"Schuyler Falls",
	"Denair",
	"Westlock",
	"Bocaina de Minas",
	"Cacique Doble",
	"Ostrov",
	"Highland Lakes",
	"Johnstown",
	"Caiçara",
	"Témiscouata-sur-le-Lac",
	"Pojo",
	"Dickinson",
	"Klavdiyevo-Tarasove",
	"Turvolândia",
	"Sousel",
	"South Haven",
	"Hugo",
	"Tice",
	"Jalcomulco",
	"Mexico",
	"Town and Country",
	"Quinta do Sol",
	"Lakeview",
	"Morganville",
	"New Burlington",
	"Mockrehna",
	"Bol’shoye Boldino",
	"Shannon",
	"Osoyoos",
	"Crucilândia",
	"Holbrook",
	"Four Corners",
	"Rio Hondo",
	"Sheldon",
	"Lyubim",
	"North Fond du Lac",
	"Shelby",
	"Crestwood",
	"Middle Paxton",
	"Whiston",
	"Moulay Driss Aghbal",
	"Isaccea",
	"José Raydam",
	"Middleton-on-Sea",
	"Fox Chapel",
	"Vinton",
	"Palestina",
	"De Motte",
	"Silver Springs",
	"Maplewood",
	"Laraquete",
	"Chaitén",
	"Glencoe",
	"Kolochava",
	"Hearst",
	"West Mead",
	"Canterbury",
	"Adams",
	"Shanor-Northvue",
	"Dachi",
	"Domeikava",
	"Tiszaalpár",
	"Shipston on Stour",
	"Itajá",
	"Bella",
	"São Gonçalo do Piauí",
	"Massac",
	"Lower Oxford",
	"Naganohara",
	"Álvaro de Carvalho",
	"Munroe Falls",
	"Saltillo",
	"Monee",
	"Imagane",
	"Vedra",
	"Agnone",
	"São Pedro da União",
	"Iowa Falls",
	"Roseburg North",
	"Ipiguá",
	"Congonhas do Norte",
	"Buckley",
	"Nottingham",
	"Scott",
	"Gretna",
	"Candor",
	"Stewartstown",
	"Hitra",
	"Z·hurivka",
	"Bohdanivka",
	"Băile Herculane",
	"Floraí",
	"West Hills",
	"Glen Head",
	"Cerceda",
	"Descoberto",
	"Azrar",
	"Clifton",
	"Pratânia",
	"Candeleda",
	"Offutt AFB",
	"Berkeley",
	"Wayne",
	"Wakefield",
	"Carver",
	"Dundee",
	"North Scituate",
	"Braúnas",
	"Montevideo",
	"Sulphur",
	"Summit",
	"Ibertioga",
	"Springfield",
	"Colesville",
	"Amelia",
	"Palmeira",
	"Juuka",
	"Vargem Grande do Rio Pardo",
	"Barling",
	"Krum",
	"Tolfa",
	"Alfred",
	"Bom Sucesso",
	"San Jerónimo Coatlán",
	"Uvat",
	"Rockingham",
	"Jericho",
	"Terryville",
	"La Crescent",
	"Piedade dos Gerais",
	"Cuparaque",
	"Admont",
	"Sand",
	"Worland",
	"Olyphant",
	"Stafford Springs",
	"Whiskey Creek",
	"Doney Park",
	"Glennville",
	"Sea Cliff",
	"Lackawaxen",
	"José Boiteux",
	"Abbeville",
	"Muleshoe",
	"Charlestown",
	"Chukhloma",
	"Deerfield",
	"Granville",
	"West Salem",
	"Mossâmedes",
	"Guerneville",
	"Dinapigue",
	"Bom Jesus do Tocantins",
	"Hawaiian Ocean View",
	"West Sayville",
	"Mullins",
	"Peach Bottom",
	"Waldoboro",
	"Swoyersville",
	"Hoopeston",
	"Chechelnyk",
	"Greenfield",
	"Hudson",
	"Ridgeland",
	"Dwight",
	"Oak Park Heights",
	"Gillespie",
	"Midfield",
	"Antigonish",
	"Igarka",
	"Ingham",
	"Oranjemund",
	"Manjimup",
	"Nata",
	"Carnarvon",
	"Turukhansk",
	"Susuman",
	"Bagdarin",
	"Smithton",
	"Svolvær",
	"Narrogin",
	"Westport",
	"Perito Moreno",
	"Newman",
	"Otavi",
	"Tepelenë",
	"Camargo",
	"Gobernador Gregores",
	"Abra Pampa",
	"Pofadder",
	"Victorica",
	"La Paz",
	"Kyaukpyu",
	"Sokolo",
	"Merimbula",
	"Manica",
	"Katanning",
	"Dehiba",
	"Comandante Fontana",
	"Ersekë",
	"Weipa",
	"Wallaroo",
	"Renwick",
	"Mitzic",
	"Brus Laguna",
	"Çorovodë",
	"Pevek",
	"El Maitén",
	"Central Coast",
	"Karmah an Nuzul",
	"Mayumba",
	"La Paloma",
	"Nautla",
	"Finnsnes",
	"Sapouy",
	"Sicasica",
	"Teseney",
	"Ulaan-Uul",
	"Yomou",
	"Lavumisa",
	"Proserpine",
	"Port Douglas",
	"Baltasar Brum",
	"Clare",
	"Tom Price",
	"Hokitika",
	"Kirkenes",
	"Te Anau",
	"Santa Bárbara",
	"Srednekolymsk",
	"Magdalena",
	"Zhigansk",
	"Charleville",
	"Sebba",
	"Mopipi",
	"Donegal",
	"Mezen",
	"Caballococha",
	"Teeli",
	"Sinnamary",
	"Cloncurry",
	"Bordertown",
	"Aiguá",
	"Mkokotoni",
	"Buur Gaabo",
	"Karungu",
	"Mangbwalu",
	"I-n-Amguel",
	"Longreach",
	"Merredin",
	"Samaipata",
	"Padilla",
	"Pampa del Infierno",
	"Kailu",
	"Urubamba",
	"Hlatikulu",
	"Río Mayo",
	"Cochrane",
	"Puerto Villamil",
	"Saint-Georges",
	"Scottsdale",
	"Rørvik",
	"Ísafjörður",
	"Bourke",
	"Chumbicha",
	"Huasco",
	"Severo-Kuril’sk",
	"Nauta",
	"Exmouth",
	"Queenstown",
	"Tarabuco",
	"Baures",
	"Al ‘Alamayn",
	"El Dorado",
	"Höfn",
	"Boffa",
	"Dondo",
	"Juradó",
	"Coroico",
	"Egilsstaðir",
	"Mount Barker",
	"Saskylakh",
	"Lehututu",
	"Roura",
	"Sorata",
	"Plumtree",
	"Kaikoura",
	"Katwe",
	"Alexander Bay",
	"Mejillones",
	"Wagin",
	"Tasiilaq",
	"Kingston South East",
	"Tumby Bay",
	"Borgarnes",
	"Qasigiannguit",
	"Puerto Williams",
	"Novyy Port",
	"Kalbarri",
	"Nokaneng",
	"Port Denison",
	"Penola",
	"Artëmovsk",
	"Streaky Bay",
	"Cuevo",
	"Qaanaaq",
	"Halls Creek",
	"Trancas",
	"Uummannaq",
	"Peterborough",
	"Alto Río Senguer",
	"Sierra Colorada",
	"Iracoubo",
	"Ouyen",
	"Chibemba",
	"Tajarhī",
	"Katherine",
	"Lokwabe",
	"Chibia",
	"Paamiut",
	"Barcaldine",
	"Tsau",
	"Tarutung",
	"Nakhodka",
	"Jaqué",
	"Ourense",
	"Lavrentiya",
	"Cowell",
	"Príncipe da Beira",
	"Meningie",
	"Las Lajas",
	"Upernavik",
	"Hughenden",
	"Verkhoyansk",
	"Meekatharra",
	"Uad Damran",
	"Yulara",
	"Wyndham",
	"Susques",
	"Chumikan",
	"Roebourne",
	"Yélimané",
	"Kaupanger",
	"Tunduru",
	"Bicheno",
	"Kullorsuaq",
	"Winton",
	"Oatlands",
	"Godhavn",
	"Leonora",
	"Gingin",
	"Ayan",
	"Wilcannia",
	"Onslow",
	"Laverton",
	"Morawa",
	"Southern Cross",
	"Omolon",
	"Linxi",
	"Comallo",
	"Norseman",
	"Ravensthorpe",
	"Eidsvold",
	"Pannawonica",
	"Rodeo",
	"Ubombo",
	"Kimba",
	"Peterborough",
	"Mount Magnet",
	"Three Springs",
	"Uelen",
	"Theodore",
	"Gastre",
	"Tinogasta",
	"Halfmoon Bay",
	"Lemsid",
	"Mikhalkino",
	"Lasserre",
	"Scoresbysund",
	"Telsen",
	"Karumba",
	"Richmond",
	"Andamooka",
	"Georgetown",
	"Xangongo",
	"Boulia",
	"Espungabera",
	"Adelaide River",
	"Burketown",
	"Progress",
	"Kairaki",
	"Ivanhoe",
	"Nimule",
	"Thargomindah",
	"Pine Creek",
	"Ikela",
	"Cazombo",
	"Shoyna",
	"Enurmino",
	"Timbedgha",
	"Greytown",
	"Camooweal",
	"Vilankulo",
	"Quipungo",
	"Sayḩūt",
	"Korf",
	"La Esmeralda",
	"Birdsville",
	"Bedourie",
	"Windorah",
	"Punta Prieta",
	"Al ‘Uqaylah",
	"Ituni",
	"Southend",
	"Kovda",
	"Kingoonya",
	"Hödrögö",
	"Tiyerbes",
	"Ust’-Nyukzha",
	"Chegga",
	"Zhilinda",
	"Ust’-Olenëk",
	"Olenëk",
	"Ambarchik",
	"Logashkino",
	"Bolsward",
	"Mazoe",
	"Al Qurayyāt",
	"Yueyaquan",
	"Gamba",
	"Jixian",
	"Ksar Oulad Azzouz",
	"Qarabalyq",
	"Mandritsara",
	"Lakha Nëvre",
	"Olmos",
	"Dangcheng",
	"Xiba",
	"Shiyuan",
	"Zengjiaba",
	"Aqadyr",
	"Sémbé",
	"Tounfafi",
	"Xiada",
	"Bou Zemou",
	"Sultan-Yangiyurt",
	"Dombarovskiy",
	"Novogagatli",
	"Igrim",
	"Soldato-Aleksandrovskoye",
	"Zhangping",
	"Pedro Luro",
	"Benbutucun",
	"Douar Oulad Bouziane",
	"Chechen-Aul",
	"Weerselo",
	"Moisei",
	"Ban Kham Pom",
	"Douar El Mellaliyine",
	"Bandio",
	"Mikhaylovskoye",
	"Saūmalköl",
	"Xiangping",
	"Monte Cristo",
	"Kawara",
	"Leichi",
	"Sholaqqorghan",
	"Podstepki",
	"Ust’-Nera",
	"Dazhuangzi",
	"Vinsady",
	"Jumlā",
	"Coatetelco",
	"Ixtapa Zihuatanejo",
	"Shar",
	"Mandīshah",
	"Lieşti",
	"Qashyr",
	"Daping",
	"Yanchep",
	"Zhangaözen",
	"Toulou",
	"Marrupa",
	"Verkhniye Achaluki",
	"Gidan Idèr",
	"Al Quway‘īyah",
	"Marginea",
	"Nerubaiske",
	"Chermen",
	"Vícam Pueblo",
	"Zhujiagua",
	"Corman Park No. 344",
	"Aqsū",
	"Justiniano Posse",
	"Elin-Yurt",
	"Ban Tham",
	"Ban Pa Lu Ru",
	"Alougoum",
	"Mihail Kogălniceanu",
	"Masindi Port",
	"Hukou",
	"Ivanivka",
	"Olovyannaya",
	"Baili",
	"Qarqaraly",
	"Khomutovo",
	"Sarmakovo",
	"Putina",
	"Happy Valley",
	"Borcea",
	"Oldeani",
	"Ban Banlang",
	"Ban Huai Mai",
	"Grabovo",
	"Afumaţi",
	"Guazhoucun",
	"Qusmuryn",
	"Staraya Sunzha",
	"Kontcha",
	"Matarka",
	"Baksanenok",
	"Ain Bida",
	"Lumina",
	"Ch’osan-ŭp",
	"Ban Pao",
	"Benoy-Yurt",
	"Iqaluit",
	"Kalabo",
	"Velykyi Dalnyk",
	"Bansang",
	"Baguéy",
	"Bayghanīn",
	"Gucheng",
	"Holmestrand",
	"Kabardinka",
	"Senillosa",
	"Mórfou",
	"Ivanovskoye",
	"Birnin Kazoé",
	"Tranqueras",
	"Sipoteni",
	"Neiafu",
	"Laguna Larga",
	"Yaama",
	"Luân Châu",
	"Sierra Grande",
	"Mzizal",
	"Kurush",
	"Basing",
	"Osakarovka",
	"Bossembele",
	"Dalakovo",
	"Peterculter",
	"Nkob",
	"Ban Bua Ngam",
	"Hafendorf",
	"Bestöbe",
	"Tobyl",
	"Dumbrăveni",
	"Okondja",
	"Santa Rosa",
	"Berdakel",
	"Sichon",
	"Brownsburg",
	"Dafawa",
	"Ban Karaket",
	"Datian",
	"Taza",
	"Zambezi",
	"Dosey",
	"Guanzhuang",
	"Angoul Dénya",
	"Melekeok",
	"Río Primero",
	"La Punta",
	"Quellón",
	"Staryy Cherek",
	"Bghaghza",
	"Azitan",
	"Nicoadala",
	"Sidi Bettach",
	"Yangjiagetai",
	"Ban Kamphuan",
	"Dovhe",
	"Psygansu",
	"Karibib",
	"Toubout",
	"Ban Wang Kalang",
	"Zholymbet",
	"Tuzla",
	"Nova Maiachka",
	"Supsekh",
	"Outerbat",
	"Velyki Komiaty",
	"Laba",
	"Levokumka",
	"Moussadèye",
	"Golfito",
	"Khandyga",
	"Taouz",
	"Beni Khaled",
	"Rozsoshentsi",
	"Saddina",
	"Sadova",
	"Lozuvatka",
	"Kurumoch",
	"Bakin Birji I",
	"Dagou",
	"Domna",
	"Voronovytsia",
	"Saint-Lambert-de-Lauzon",
	"Ban Phon Yai",
	"Tyourout",
	"Sokolé",
	"Ban Tai",
	"Diosig",
	"Kubei",
	"Muskoka Falls",
	"Qazaly",
	"Bile",
	"Shechengtan",
	"Nasarawa",
	"Gilău",
	"Vladimirescu",
	"Cobadin",
	"Adis",
	"Vozdvizhenka",
	"Bingzhongluo",
	"Baloteşti",
	"Akka Irene",
	"Jiyek",
	"Bosanci",
	"Divnomorskoye",
	"Fangjiata",
	"Tatarka",
	"Peretu",
	"Madéta II",
	"Garswood",
	"Cudalbi",
	"San Matías",
	"Lydiate",
	"Fontanka",
	"Bélbéji",
	"Aouloumal",
	"Velikovechnoye",
	"Strugi-Krasnyye",
	"Tamchen",
	"Strejnicu",
	"Oum Azza",
	"Dysart et al",
	"Khurba",
	"Kushnytsia",
	"Kudryashovskiy",
	"Tadighoust",
	"Lakkha Nëvre",
	"Beresford",
	"Ban Dong Mafai",
	"Remetea",
	"Baciu",
	"Hamdallay",
	"Pattijoki",
	"Ndendé",
	"El Palqui",
	"Chemodanovka",
	"Ban Chiang",
	"Douglas",
	"Sândominic",
	"Asoudié",
	"Ivanovka",
	"Umba",
	"Ahrarne",
	"Sutysky",
	"Hollington",
	"Sabon Birni",
	"Apahida",
	"Vicovu de Jos",
	"Lea Town",
	"Lozova",
	"Dindi",
	"Bărbuleşti",
	"Plosca",
	"Chapaev",
	"Dan Kori",
	"Tazovskiy",
	"Achikulak",
	"General Zaragoza",
	"Ban Talat Ko Ta Ba Ru",
	"Masalata",
	"Shemordan",
	"Toudouni",
	"Urvan",
	"Garadoumé",
	"Oulad Aïssa",
	"Kélénté",
	"Mundybash",
	"Zandak",
	"Djibalé",
	"Sokilnyky",
	"Ibstock",
	"Aiyomojok",
	"Feldru",
	"Giroc",
	"Periş",
	"Piggs Peak",
	"Spasskoye",
	"Sokur",
	"Kamennomostskoye",
	"Bouti",
	"Kaora Abdou",
	"Chernolesskoye",
	"Koundoumawa",
	"Fdérik",
	"Soubdou",
	"Krym",
	"Saal",
	"Horonda",
	"Presnovka",
	"Kazminskoye",
	"General Levalle",
	"Tiksi",
	"Princeville",
	"Perugorría",
	"Macea",
	"Ruscova",
	"Jinshan",
	"Oituz",
	"Jiajiaping",
	"Prigorodnoye",
	"Rucăr",
	"Rodna",
	"Kasempa",
	"Altud",
	"Riadi Dan Bizo",
	"Kugulta",
	"Crowsnest Pass",
	"Vitomiricë",
	"Gugeşti",
	"Vossevangen",
	"Benoy",
	"Okhotsk",
	"Stanthorpe",
	"Zoria",
	"Souloulou",
	"Tsibanobalka",
	"Horenka",
	"Bullsbrook",
	"Rivne",
	"Stepanivka",
	"Bialet Massé",
	"Tash-Döbö",
	"Mouléla",
	"Renzhuangcun",
	"Cairima",
	"Zawyat Sidi Hamza",
	"Hejiachuan",
	"Witu",
	"Nakoni",
	"Moţăţei",
	"Sollom",
	"Aït Athmane",
	"Tura",
	"Prejmer",
	"San Quintín",
	"Ouadda",
	"Yarkovo",
	"Gandasamou",
	"Linda",
	"Sanmucha",
	"Sabonkafi",
	"Taoyan",
	"Russko-Vysotskoye",
	"Homocea",
	"Cookshire",
	"Măieru",
	"Jambriji",
	"Pavlodarskoe",
	"Zyazikov",
	"Mykolayivka",
	"Timoulay Izder",
	"Arbore",
	"Baia",
	"Hohenau",
	"Komsomol’skoye",
	"Verkhnerusskiy",
	"Damjan",
	"Bogoslovka",
	"Giarmata",
	"Tufeşti",
	"Jingping",
	"Kuba",
	"Corund",
	"Inkeroinen",
	"Torghay",
	"Ez Zinat",
	"Tafetchna",
	"Otrado-Ol’ginskoye",
	"Saint-Honoré",
	"Ban Chiang Klom",
	"Baryshevo",
	"Malgorou",
	"Dan Gona",
	"Ban Nam Phong",
	"Horodnicu de Sus",
	"Fort-Shevchenko",
	"Xiangkhoang",
	"San Francisco",
	"Straja",
	"Yalta",
	"Greci",
	"Tudora",
	"Yablanovo",
	"Souk el Had-des Beni-Batao",
	"Algeti",
	"Na‘jān",
	"Răducăneni",
	"Kyrnasivka",
	"Chernyshevskiy",
	"Ban San",
	"Villa del Rosario",
	"Răşinari",
	"Dayi",
	"Burton",
	"Păuneşti",
	"McMinns Lagoon",
	"Spallumcheen",
	"Konstantinovskoye",
	"Biancang",
	"Lesnikovo",
	"Diambala",
	"Draginovo",
	"Qaşr al Farāfirah",
	"Nikolo-Pavlovskoye",
	"Saint-Henri",
	"Ste. Anne",
	"Puerto Casado",
	"Ust’-Kamchatsk",
	"Betanzos",
	"Sangar",
	"Khatanga",
	"Brownsweg",
	"Al Qaşr",
	"Saryshaghan",
	"Bekily",
	"Batagay",
	"San Javier",
	"Omsukchan",
	"Apolo",
	"Novyy Uoyan",
	"Bongandanga",
	"Quime",
	"Araouane",
	"Vergara",
	"Mbé",
	"Shongzhy",
	"Ciudad Cortés",
	"P’ungsan",
	"Vitim",
	"Pukë",
	"Palana",
	"Cherskiy",
	"Zyryanka",
	"De-Kastri",
	"Ligonha",
	"Darregueira",
	"Bukachacha",
	"Ugol’nyye Kopi",
	"Lukulu",
	"Krasnogorsk",
	"Mékambo",
	"Arroyos y Esteros",
	"Ust’-Maya",
	"Abaí",
	"Taoudenni",
	"San Lorenzo",
	"Saranpaul",
	"Villalonga",
	"Villa Ygatimí",
	"Entre Ríos",
	"Sauðárkrókur",
	"Provideniya",
	"Chokurdakh",
	"Marādah",
	"Mariscal José Félix Estigarribia",
	"José Batlle y Ordóñez",
	"Uspallata",
	"Sohano",
	"Ceduna",
	"Maltahöhe",
	"Muconda",
	"Toltén",
	"Mwenga",
	"Egvekinot",
	"El Manteco",
	"Pozo Colorado",
	"Evensk",
	"Konza",
	"Altata",
	"Dawwah",
	"Taedong",
	"Abunã",
	"Corocoro",
	"Beringovskiy",
	"Nasir",
	"Labutta",
	"Al Jaghbūb",
	"Omboué",
	"Kipili",
	"Manja",
	"Ust’-Kuyga",
	"Eldikan",
	"Qardho",
	"Nyimba",
	"Fulacunda",
	"Lubutu",
	"Cangamba",
	"Regedor Quissico",
	"Villa Rumipal",
	"Ñacunday",
	"Çeleken",
	"Buluko",
	"San Rafael",
	"Capitán Pablo Lagerenza",
	"Puerto Acosta",
	"Los Blancos",
	"Mirbāţ",
	"Klyuchi",
	"Besalampy",
	"Cacolo",
	"General Eugenio A. Garay",
	"Daraj",
	"Bávaro",
	"Luanza",
	"Hoskins",
	"Calulo",
	"Muhembo",
	"Yerëma",
	"Satadougou",
	"Dibaya",
	"Serebryansk",
	"Zhaltyr",
	"Manily",
	"Calatrava",
	"Massangena",
	"Panda",
	"Ypejhú",
	"Quilpie",
	"Sabaya",
	"Chiramba",
	"Mereeg",
	"Llica",
	"Calenga",
	"Dikson",
	"Caluula",
	"Tournavista",
	"Puerto Pinasco",
	"Tchitado",
	"Yakossi",
	"Tmassah",
	"Manyoni",
	"Woomera",
	"Tasiusaq",
	"Sherlovaya Gora",
	"Tsavo",
	"Nizhneyansk",
	"Toconao",
	"Balsadero Río Verde",
	"Kanyato",
	"Kulusuk",
	"Umm al ‘Abīd",
	"Bugrino",
	"Put’ Lenina",
	"Yaupi",
	"Amderma",
	"Kangersuatsiaq",
	"Villa O’Higgins",
	"Amau",
	"Kalima",
	"Al Qunfudhah",
	"Lusanga",
	"Kraulshavn",
	"Androka",
	"Charaña",
	"Nichicun",
	"Hurdiyo",
	"Buton",
	"Narsarsuaq",
	"Bafwasende",
	"Bifoun",
	"Il’pyrskiy",
	"Sharbaqty",
	"Ağdam",
	"Savissivik",
	"Cuya",
	"Villa Martín Colchak",
	"Gyda",
	"Güeppí",
	"Puerto Heath",
	"Bîr Mogreïn",
	"Yessey",
	"Burubaytal",
	"Mukhomornoye",
	"Vorontsovo",
	"Grytviken",
	"Piso Firme",
	"As Sidrah",
	"Rocafuerte",
	"Peregrebnoye",
	"Laryak",
	"Lagunas",
	"Andoas",
	"Puca Urco",
	"Zillah",
	"Ulkan",
	"Barnīs",
	"Soldado Bartra",
	"Strelka",
	"Bol’sheretsk",
	"Karamken",
	"Djado",
	"Omchak",
	"Shalaurova",
	"Khorgo",
	"Komsa",
	"Pakhachi",
	"Indiga",
	"Sagastyr",
	"Chagda",
	"Tunguskhaya",
	"Podkamennaya Tunguska",
	"Siglan",
	"Utkholok",
	"Varnek",
	"Trofimovsk",
	"Matochkin Shar",
	"Menkerya",
	"Khakhar",
	"Zvëzdnyy",
	"Zemlya Bunge",
	"Starorybnoye",
	"Agapa",
	"Tukchi",
	"Numto",
	"Nord",
	"Timmiarmiut",
	"Nordvik",
];

export default cities;
